// testimonials.ts

export interface Testimonial {
  id: number
  name: string
  role: string
  content: string
}

export const testimonials: Testimonial[] = [
  {
    id: 1,
    name: 'Shatiso Pharo',
    role: 'Tractor Service Provider in Masunga',
    content:
      'I am a tractor service provider in Masunga District . Last year (2023) i used Temo Letlotlo programme to provide tractor services to the Masunga cluster farmers. What impressed me the most is the fact that i was paid on time.'
  },
  {
    id: 2,
    name: 'Thebeesile Ramakatane',
    role: 'Micro Scale Farmer in Mabutsane District.',
    content:
      'Last year (2023)  I managed to fence my 16ha ploughing field through Temo Letlotlo programme. Now i  am happy that animals no longer traspass my field.'
  },
  {
    id: 3,
    name: 'Mothibi Mampane',
    role: 'Farmer in Kgatleng District',
    content:
      'I acquired a tractor and implements through Temo Letlotlo programme. This has really helped him. I am now able to plough my field on time. I also provide the service to other farmers at a fee.'
  },
  {
    id: 4,
    name: 'Samuel Mogorosi',
    role: 'Small Scale Farmer in Masunga Distric',
    content:
      ' I applied for Temo Letlotlo fencing subsidy and I am happy to say i managed to fence my 8ha ploughing field.'
  }
]
