// farmServicesData.ts

export interface TemoService {
  id: string
  title: string
  description: string
  backgroundColor: string
  fontColor: string
  gradientFrom: string
  gradientTo: string
}

export const temoServices = (t: (key: string) => string): TemoService[] => [
  {
    id: 'farm-management',
    title: t('farm.farm'),
    description: t('farm.farmS'),
    backgroundColor: 'bg-blue-100',
    fontColor: 'text-gray-600',
    gradientFrom: 'blue-100',
    gradientTo: 'blue-200'
  },
  {
    id: 'loan-applications',
    title: t('farm.loan'),
    description: t('farm.loanS'),
    backgroundColor: 'bg-blue-200',
    fontColor: 'text-gray-600',
    gradientFrom: 'blue-200',
    gradientTo: 'blue-300'
  },
  {
    id: 'leasing-inventory',
    title: t('farm.inv'),
    description: t('farm.invS'),
    backgroundColor: 'bg-blue-400',
    fontColor: 'text-white',
    gradientFrom: 'blue-400',
    gradientTo: 'blue-500'
  },
  {
    id: 'sustainability-support',
    title: t('farm.sus'),
    description: t('farm.susS'),
    backgroundColor: 'bg-blue-500',
    fontColor: 'text-white',
    gradientFrom: 'blue-500',
    gradientTo: 'blue-600'
  }
]