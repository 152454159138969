import { create } from 'zustand'

interface UsePloughSeasonStore {
  ploughSeason: string
  setPloughSeason: (ploughSeason: string) => void
}

export const usePloughSeasonStore = create<UsePloughSeasonStore>((set) => ({
  ploughSeason: '',
  setPloughSeason: (ploughSeason: string) => {
    set({ ploughSeason })
  }
}))
