import { type Farmer, type Merchant } from 'types'
import { create } from 'zustand'

interface UseTransferStore {
  userType: string | null
  userId: number | null
  setUserId: (userId: number | null) => void
  setUserType: (userType: string | null) => void
  userDetails: Merchant | Farmer | null
  setUserDetails: (userDetails: Merchant | Farmer | null) => void
}

export const useTransferStore = create<UseTransferStore>()((set) => ({
  userType: null,
  userDetails: null,
  userId: null,
  setUserId: (userId: number | null) => {
    set((state) => ({ ...state, userId }))
  },
  setUserType: (userType: string | null) => {
    set((state) => ({ ...state, userType }))
  },
  setUserDetails: (userDetails: Merchant | Farmer | null) => {
    set((state) => ({ ...state, userDetails }))
  }
}))
