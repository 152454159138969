import type React from 'react'
import { type ReactElement, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { RegisterTractors } from '../components/RegisterTractor'
import { RegisterPoweredImplements } from '../components/RegisterPoweredImplements'
import { RegisterNonPoweredImplements } from '../components/RegisterNonPoweredImplements'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from '../../../../../components/ui'
import { useMerchantStore } from '../../../../../stores/useMerchantStore'

export const InventoryForm: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { merchant } = useMerchantStore()
  useEffect(() => {
    if (
      params.inventoryType == null ||
      !['pwrd-implements', 'trd-implements', 'tractor'].includes(params.inventoryType)
    ) {
      navigate('/service-provider/inventory')
    }
  }, [params.inventoryType, navigate])

  const renderContent = (): ReactElement | null => {
    switch (params.inventoryType) {
      case 'pwrd-implements':
        return <RegisterPoweredImplements isView={false} isMerchant={false} />
      case 'trd-implements':
        return <RegisterNonPoweredImplements isView={false} isMerchant={false} />

      default:
        return <RegisterTractors isView={false} isMerchant={false} />
    }
  }

  return (
    <>
      <div className={'flex justify-between items-center border-b border-border p-4 mb-10'}>
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link
                  className={'text-lg'}
                  to={`${merchant != null ? '/service-provider/inventory' : 'inventory'}`}
                >
                  Inventory
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage className={'text-lg'}>
                {` ${
                  params.inventoryType === 'pwrd-implements'
                    ? 'Register Powered Implement'
                    : params.inventoryType === 'trd-implements'
                    ? 'Register Non-Powered Implement'
                    : 'Register Tractor'
                }`}
              </BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      {renderContent()}
    </>
  )
}
