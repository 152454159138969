import type { ColumnDef } from '@tanstack/react-table'
import { DataTableColumnHeader } from '../../../../components/Data-Table/datatable-header'
import { Checkbox } from '../../../../components/ui/checkbox'
import { type Seeds } from '../../../../types/static'

export const seedsColumns = (t: (key: string) => string): Array<ColumnDef<Seeds>> => [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllRowsSelected() || (table.getIsSomeRowsSelected() && 'indeterminate')}
        onCheckedChange={(value) => {
          table.toggleAllRowsSelected(!!value)
        }}
        aria-label='Select all'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => {
          row.toggleSelected(!!value)
        }}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false
  },
  {
    id: 'crop',
    accessorFn: (row) => row.crop_name,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('crop')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('seed.crop')} />
  },
  {
    id: 'trade name',
    accessorFn: (row) => row.trade_name,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('trade name')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('seed.trade')} />
  },
  {
    id: 'variety',
    accessorFn: (row) => row.variety,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('variety')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('seed.variety')} />
  },
  {
    id: 'type',
    accessorFn: (row) => row.seed_type,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('type')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('seed.type')} />
  },
  {
    id: 'sowing rate',
    accessorFn: (row) => row.sowing_rate,
    filterFn: 'includesString',
    enableHiding: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('sowing rate')}</div>,
    header: `${t('seed.sow')} (kg/h)`
  },
  {
    id: 'yield',
    accessorFn: (row) => row.yield,
    filterFn: 'includesString',
    enableHiding: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('yield')}</div>,
    header: `${t('seed.yield')} (ton/ha)`
  },
  {
    id: 'days to maturity',
    accessorFn: (row) => row.days_to_maturity,
    filterFn: 'includesString',
    enableHiding: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('days to maturity')}</div>,
    header: t('seed.days')
  },
  {
    id: 'price',
    accessorFn: (row) => row.price_per_kg,
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => {
      const amountString = String(row.getValue('price')).replace(/,/g, '')
      const amount = parseFloat(amountString)
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'BWP'
      }).format(amount)

      return <div className='font-medium uppercase'>{formatted}</div>
    },
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title={`${t('seed.price')} (BWP)/Kg`} />
    )
  }
]
