import type React from 'react'
import { Outlet } from 'react-router-dom'
import Session from 'supertokens-auth-react/recipe/session'
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles'
import Spinner from '../../../components/ui/spinner'
import { clearLoginAttemptInfo } from 'supertokens-web-js/lib/build/recipe/passwordless'
import { Footer } from '../components/SubComponents/Generic/Footer'
import { NavLandingPage } from '../components'
import Sorghum from '../../../assets/landing/impact_in_action/1.jpeg'
import PandaFarms from '../../../assets/landing/impact_in_action/3.jpeg'
import Four from '../../../assets/landing/impact_in_action/4.jpeg'
import Two from '../../../assets/landing/impact_in_action/2.jpeg'
import Drone from '../../../assets/landing/impact_in_action/5.jpeg'
import Farm from '../../../assets/landing/impact_in_action/6.jpeg'
import Tractor from '../../../assets/landing/impact_in_action/7.jpeg'
import TractorTwo from '../../../assets/landing/impact_in_action/10.jpeg'
import SafflowerPetalTea from '../../../assets/landing/impact_in_action/11.jpeg'
import FarmTwo from '../../../assets/landing/impact_in_action/12.jpeg'
import TractorThree from '../../../assets/landing/impact_in_action/13.jpeg'
import TractorFour from '../../../assets/landing/impact_in_action/14.jpeg'
import ExhibitionSelibi from '../../../assets/landing/impact_in_action/8.jpeg'
import SupplementaryIrrigation from '../../../assets/landing/impact_in_action/17.jpeg'
import StakeholderEngagement from '../../../assets/landing/impact_in_action/21.jpeg'
import CookingOil from '../../../assets/landing/impact_in_action/20.jpeg'
import CMSDemo from '../../../assets/landing/impact_in_action/22.jpeg'
import WheatFarmer from '../../../assets/landing/impact_in_action/23.jpeg'
import Mediaengagement from '../../../assets/landing/impact_in_action/24.jpeg'
import TractorShow from '../../../assets/landing/impact_in_action/25.jpeg'
import Totolo from '../../../assets/landing/impact_in_action/26.jpeg'
import Maupong from '../../../assets/landing/impact_in_action/27.jpeg'
import PermanentSecretary from '../../../assets/landing/impact_in_action/28.jpeg'
import { ImageGallery } from 'react-image-grid-gallery'
import { motion } from 'framer-motion'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

export const Gallery: React.FC = () => {
  const { t } = useTranslation('landing')
  const claimValue = Session.useClaimValue(UserRoleClaim)

  if (claimValue.loading) {
    return (
      <div className='flex items-center justify-center w-screen h-screen'>
        <Spinner />
      </div>
    )
  }

  const roles = claimValue.value

  const handleNavigateToDashboard = (): string => {
    if (Array.isArray(roles) && roles.includes('FARMER')) {
      return '/farmer'
    } else if (Array.isArray(roles) && roles.includes('EXTENSION_OFFICER')) {
      return '/officer'
    } else if (Array.isArray(roles) && roles.includes('SOIL_SAMPLE_LAB')) {
      return '/soil-lab'
    } else if (Array.isArray(roles) && roles.includes('MECHANISATION_OFFICER')) {
      return '/inventory'
    } else if (Array.isArray(roles) && roles.includes('SEED_DEALER')) {
      return '/seed-dealer'
    } else if (Array.isArray(roles) && roles.includes('SERVICE_PROVIDER')) {
      return '/service-provider'
    } else {
      void clearLoginAttemptInfo()
      return '/login/phone'
    }
  }

  const imagesArray = [
    {
      alt: Sorghum,
      caption: "Image1's description",
      src: Sorghum
    },
    {
      alt: 'Pandamatenga farms',
      caption: 'Pandamatenga farms',
      src: PandaFarms
    },
    {
      alt: 'Wheat production in Pandamatenga',
      caption: 'Wheat production in Pandamatenga',
      src: Four
    },
    {
      alt: 'Temo Letlotlo participate at the Ghanzi Agricultural show',
      caption: 'Temo Letlotlo participate at the Ghanzi Agricultural show',
      src: Two
    },
    {
      alt: 'Sorgum Production in Pandamatenga',
      caption: 'Sorgum Production in Pandamatenga',
      src: Drone
    },
    {
      alt: 'Planter',
      caption: 'Planter',
      src: Tractor
    },
    {
      alt: 'Planter used by farmers',
      caption: 'Planter used by farmers',
      src: Farm
    },
    {
      alt: 'Walk behind tractor demonstration at Voorslag show',
      caption: 'Walk behind tractor demonstration at Voorslag show',
      src: TractorTwo
    },
    {
      alt: 'Safflower by-productsd exhibited at Mookane field day',
      caption: 'Safflower by-productsd exhibited at Mookane field day',
      src: SafflowerPetalTea
    },
    {
      alt: 'Wheat irrigation in Pandamatenga',
      caption: 'Wheat irrigation in Pandamatenga',
      src: FarmTwo
    },
    {
      alt: 'Tractor with planter',
      caption: 'Tractor with planter',
      src: TractorThree
    },
    {
      alt: 'Tractor with harvesting equipment',
      caption: 'Tractor with harvesting equipment',
      src: TractorFour
    },
    {
      alt: 'Temo Letlotlo priority crops exhibted at Selebi Trade fair',
      caption: 'Temo Letlotlo priority crops exhibted at Selebi Trade fair',
      src: ExhibitionSelibi
    },
    {
      alt: 'Supplementary irrigation demonstration at the national agricultural show',
      caption: 'Supplementary irrigation demonstration at the national agricultural show',
      src: SupplementaryIrrigation
    },
    {
      alt: 'Temo Letlotlo stakeholder Engagement meeting',
      caption: 'Temo Letlotlo stakeholder Engagement meeting',
      src: StakeholderEngagement
    },
    {
      alt: 'Some of Temo Letlotlo products spaces displayed during chobe agricultural show',
      caption: 'Some of Temo Letlotlo products spaces displayed during chobe agricultural show',
      src: CookingOil
    },
    {
      alt: 'Wheat farmer in Pandamatenga',
      caption: 'Wheat farmer in Pandamatenga',
      src: WheatFarmer
    },
    {
      alt: 'Media engagement',
      caption: 'Media engagement',
      src: Mediaengagement
    },

    {
      alt: 'Some of the exhibit during the national agricultural show',
      caption: 'Some of the exhibit during the national agricultural show',
      src: TractorShow
    },
    {
      alt: 'BIUST Vice Chancellor Prof. Otlogetswe Totolo  and Ministry of Agriculture during the national agricultural show',
      caption: 'Some of the exhibit during the national agricultural show',
      src: Totolo
    },
    {
      alt: 'Stakeholder Engagement Dr. Maupong (BIUST)',
      caption: 'Stakeholder Engagement Dr. Maupong (BIUST)',
      src: Maupong
    },
    {
      alt: 'Permanent Secretary appreciating Temo Letlotlo stall during the 2024 National agricultural show',
      caption:
        'Permanent Secretary appreciating Temo Letlotlo stall during the 2024 National agricultural show',
      src: PermanentSecretary
    },
    {
      alt: 'A demo of the Temo Letlotlo CMS by the Temo Letlotlo team and Farmers',
      caption: "A demo of the Temo Letlotlo CMS by the Temo Letlotlo team and Farmers'",
      src: CMSDemo
    }
  ]

  return (
    <main className='landing-page'>
      {/* SEO Optimization */}
      <Helmet>
        <title>{t('gal.gal')}</title>
        <meta
          name='description'
          content='Explore our gallery to see how Temo Letlotlo has transformed farming in Botswana. View success stories and event highlights.'
        />
        <meta
          name='keywords'
          content='Temo Letlotlo gallery, Botswana agriculture gallery, farming success stories, Botswana farmers'
        />

        {/* Open Graph / Facebook */}
        <meta property='og:type' content='website' />
        <meta
          property='og:title'
          content="Temo Letlotlo Gallery - Showcasing Botswana's Agricultural Success"
        />
        <meta
          property='og:description'
          content='Discover the agricultural impact of Temo Letlotlo through our gallery of success stories and project highlights.'
        />
        <meta property='og:image' content='https://www.temoletlotlo.com/gallery-image.jpg' />
        <meta property='og:url' content='https://www.temoletlotlo.com/gallery' />

        {/* Twitter */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:title'
          content="Temo Letlotlo Gallery - Showcasing Botswana's Agricultural Success"
        />
        <meta
          name='twitter:description'
          content="Explore our gallery of projects and success stories from Botswana's farmers empowered by Temo Letlotlo."
        />
        <meta
          name='twitter:image'
          content='https://www.temoletlotlo.com/gallery-twitter-image.jpg'
        />
      </Helmet>

      {/* Page Components */}
      <NavLandingPage
        goToDashboard={handleNavigateToDashboard}
        isAuthenticated={claimValue.value != null}
      />

      <div className='flex flex-col pt-40 bg-white '>
        <div className='container mx-auto px-10 flex flex-col justify-center w-screen md:w-full space-y-8'>
          <motion.div
            className='flex flex-col items-center mb-12'
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className='text-4xl md:text-5xl lg:text-6xl font-bold text-gray-700 landing-page'>
              {t('gal.imp')}
            </h2>
          </motion.div>

          {/* Gallery Container */}
          <motion.div
            className='flex flex-col justify-center pb-16'
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <ImageGallery
              imagesInfoArray={imagesArray}
              columnCount={'auto'}
              columnWidth={300}
              gapSize={24}
            />
          </motion.div>
        </div>
      </div>
      <Outlet />
      <Footer />
    </main>
  )
}