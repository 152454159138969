import type React from 'react'
import FarmManagementCard from './FarmFeatures'

export const EmphasisSection: React.FC = () => {
  return (
    <div className='bg-white w-full '>
      <div className='container mx-auto flex flex-col  py-20  space-y-12  justify-center w-full '>
        <FarmManagementCard />
      </div>
    </div>
  )
}
