import type React from 'react'
import { useEffect } from 'react'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Label,
  Loader,
  RadioGroup,
  RadioGroupItem,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text,
  Textarea,
  toast
} from '../../../../../components/ui'
import type { GetInventoryRes, Tractor } from '../../../../farmer/inventory/types'
import { tractors } from '../../types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { type PostTractorChecklist, postTractorChecklist } from '../../api/postTractorChecklist'
import z, { array, boolean, object, string } from 'zod'

import { useOfficerStore } from '../../../../../stores/useOfficerStore'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { AxiosError, type AxiosResponse } from 'axios'
import { type ApiResponse } from '../../../../../types'
import { mapCmsErrorToMessage } from '../../../../../utils/apiErrors'
import { useGetTractorChecklist } from '../../api/getTractorChecklist'
import { Box, TabView } from '../../../../../components/App/Layout'
import { useNavigate } from 'react-router-dom'
import { useInventoryItem } from '../../../../merchant/service-provider/inventory/hooks/useInventoryItem'
import { useAuthenticationStore } from '../../../../../stores/useAuthenticationStore'
import { useTranslation } from 'react-i18next'

interface ChecklistProps {
  inventoryData: GetInventoryRes | undefined
  inventoryItem: Tractor | undefined
  isEdit?: boolean
  isView?: boolean
}

const tractorItemSchema = z.object({
  score: z.union([
    z.coerce.number().min(1, 'Field is required'),
    z.string().min(1, 'Field is required'),
    z.boolean()
  ])
})

const tractorChecklistSchema = object({
  tractorsState: array(tractorItemSchema),
  comments: string().min(1, 'Field is Required'),
  result: boolean().or(string())
})

export const TractorChecklist: React.FC<ChecklistProps> = ({
  inventoryData,
  isEdit,
  isView = false
}) => {
  const { role } = useAuthenticationStore()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { officer } = useOfficerStore()
  const { t } = useTranslation('inventory')
  const { data: data1, isLoading } = useGetTractorChecklist(Number(inventoryData?.inventory_id))
  const defaultValues = tractors.map(() => {
    return { score: '' }
  })

  const { reassessment, isLoading: isInventoryItemLoading } = useInventoryItem()
  const form = useForm<z.infer<typeof tractorChecklistSchema>>({
    resolver: zodResolver(tractorChecklistSchema),
    defaultValues: {
      tractorsState: defaultValues,
      comments: '',
      result: undefined
    }
  })

  const { fields } = useFieldArray({
    control: form.control,
    name: 'tractorsState'
  })

  const resultValue = useWatch({
    control: form.control,
    name: 'result'
  })

  useEffect(() => {
    if (isEdit === true && data1 !== undefined) {
      const latestData = data1.data[data1.data.length - 1]

      form.reset({
        tractorsState: [
          { score: latestData.lights },
          { score: latestData.starting },
          { score: latestData.gauges },
          { score: latestData.cooling_leakages },
          { score: latestData.fuel_leakages },
          { score: latestData.lubrication_leakages },
          { score: latestData.hydraulic_oil_leakages },
          { score: latestData.transmission_leakages },
          { score: latestData.smoke_color },
          { score: latestData.oil_check },
          { score: latestData.greasing },
          { score: latestData.pto },
          { score: latestData.gear_shifting },
          { score: latestData.lifting_and_releasing },
          { score: latestData.hitch_pins },
          { score: latestData.bearings },
          { score: latestData.tyres },
          { score: latestData.mirrors },
          { score: latestData.functional_gps },
          { score: latestData.engine_stalling },
          { score: latestData.positioning_and_draft_control },
          { score: latestData.breaking_system },
          { score: latestData.steering_system },
          { score: latestData.diff_loc }
        ],
        comments: latestData.comments,
        result: latestData.result
      })
    }
  }, [isEdit, data1, form])

  const mutation = useMutation({
    mutationFn: async (params: PostTractorChecklist) => {
      return postTractorChecklist(params)
    }
  })

  const handleReassessment = (): void => {
    reassessment(Number(inventoryData?.inventory_id))
  }

  const onSubmit = (data: z.infer<typeof tractorChecklistSchema>, status: number): void => {
    const result = data.result === 'true'
    mutation.mutate(
      {
        inventory_id: Number(inventoryData?.inventory_id),
        lights: Number(data.tractorsState[0].score),
        starting: Number(data.tractorsState[1].score),
        gauges: Number(data.tractorsState[2].score),
        cooling_leakages: Number(data.tractorsState[3].score),
        fuel_leakages: Number(data.tractorsState[4].score),
        lubrication_leakages: Number(data.tractorsState[5].score),
        hydraulic_oil_leakages: Number(data.tractorsState[6].score),
        transmission_leakages: Number(data.tractorsState[7].score),
        smoke_color: Number(data.tractorsState[8].score),
        oil_check: Number(data.tractorsState[9].score),
        greasing: Number(data.tractorsState[10].score),
        pto: Number(data.tractorsState[11].score),
        gear_shifting: Number(data.tractorsState[12].score),
        lifting_and_releasing: Number(data.tractorsState[13].score),
        hitch_pins: Number(data.tractorsState[14].score),
        bearings: Number(data.tractorsState[15].score),
        tyres: Number(data.tractorsState[16].score),
        mirrors: Number(data.tractorsState[17].score),
        functional_gps: Number(data.tractorsState[18].score),
        engine_stalling: Boolean(data.tractorsState[19].score),
        positioning_and_draft_control: Boolean(data.tractorsState[20].score),
        breaking_system: Boolean(data.tractorsState[21].score),
        steering_system: Boolean(data.tractorsState[22].score),
        diff_loc: Boolean(data.tractorsState[23].score),
        last_update_by: Number(officer?.user_id),
        result,
        item_verification_status: status,
        comments: data.comments
      },
      {
        onSuccess: (res) => {
          if (res.status === 201 && res.success) {
            void queryClient.invalidateQueries({ queryKey: [`inventories`] })
            toast({
              variant: 'success',
              title: `Inventory item processed successfully.`
            })
            navigate('/inventory')
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response as AxiosResponse<ApiResponse<[]>>

            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          }
        }
      }
    )
  }

  if (isLoading) {
    return (
      <div className={'h-full grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  return (
    <Box>
      <TabView className={'max-w-[900px]'}>
        <Form {...form}>
          <form className={'flex flex-col gap-8'}>
            <Text size={'medium'}>{t('mech.tc')} </Text>
            <FormField
              control={form.control}
              name={`tractorsState`}
              render={() => (
                <FormItem className='grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] items-start gap-4'>
                  {fields.map((arr, index) => (
                    <div key={index} className={'flex flex-col'}>
                      <FormField
                        key={arr.id}
                        control={form.control}
                        name={`tractorsState.${index}.score`}
                        render={({ field: scoreField }) => (
                          <FormItem>
                            <FormLabel>{tractors[index]}</FormLabel>
                            <FormControl>
                              {index >= 19 ? (
                                <RadioGroup
                                  className={'flex flex-row items-center gap-4'}
                                  onValueChange={scoreField.onChange}
                                  value={scoreField.value?.toString() ?? ''}
                                  disabled={isView}
                                >
                                  <div className='flex items-center space-x-2'>
                                    <RadioGroupItem value='true' id={`pass-${index}`} />
                                    <Label htmlFor={`pass-${index}`}>
                                      <Text size={'medium'}>{t('mech.pass')}</Text>
                                    </Label>
                                  </div>
                                  <div className='flex items-center space-x-2'>
                                    <RadioGroupItem value='false' id={`fail-${index}`} />
                                    <Label htmlFor={`fail-${index}`}>
                                      <Text size={'medium'}>{t('mech.fail')}</Text>
                                    </Label>
                                  </div>
                                </RadioGroup>
                              ) : (
                                <Select
                                  onValueChange={scoreField.onChange}
                                  value={isEdit === true ? scoreField.value?.toString() : undefined}
                                  disabled={isView}
                                >
                                  <SelectTrigger>
                                    <SelectValue placeholder='Select Score' />
                                  </SelectTrigger>
                                  <SelectContent className={'z-[9999]'}>
                                    <SelectItem value='1'>{t('mech.good')}</SelectItem>
                                    {index !== 18 && (
                                      <SelectItem value='2'>{t('mech.fair')}</SelectItem>
                                    )}
                                    <SelectItem value='3'>{t('mech.bad')}</SelectItem>
                                    <SelectItem value='4'>N/A</SelectItem>
                                  </SelectContent>
                                </Select>
                              )}
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                  ))}
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name={`comments`}
              defaultValue={data1?.data[data1.data.length - 1]?.comments}
              render={({ field: comment }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel>{t('mech.comm')}</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder={t('mech.tych')}
                      id='message'
                      className={'resize-none'}
                      {...comment}
                      disabled={isView}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name={`result`}
              render={({ field: result }) => (
                <FormItem className='flex flex-col gap-2'>
                  <FormLabel>
                    <Text size={'medium'}>{t('mech.or')}</Text>
                  </FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={result.onChange}
                      className={'ml-4 flex flex-row items-center gap-4'}
                      defaultValue={
                        result.value?.toString() ??
                        (isEdit === true && data1 != null
                          ? data1.data[data1.data.length - 1]?.result?.toString()
                          : '')
                      }
                      disabled={isView}
                    >
                      <div className='flex items-center space-x-2'>
                        <RadioGroupItem value='true' id={`pass`} />
                        <Label htmlFor={`pass`}>
                          <Text size={'medium'}>{t('mech.pass')}</Text>
                        </Label>
                      </div>
                      <div className='flex items-center space-x-2'>
                        <RadioGroupItem value='false' id={`fail`} />
                        <Label htmlFor={`fail`}>
                          <Text size={'medium'}>{t('mech.fail')}</Text>
                        </Label>
                      </div>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className={'flex flex-col mt-4'}>
              {isView ? (
                <div className={'flex flex-row justify-between gap-20'}>
                  <Button
                    className='w-full sm:w-[18rem]'
                    variant='secondary'
                    onClick={() => {
                      navigate(-1)
                    }}
                  >
                    {t('mech.cancel')}
                  </Button>
                  {inventoryData?.item_verification_status === 'TO BE REASSESSED' &&
                    officer === null && (
                      <Button
                        className='w-full sm:w-[18rem]'
                        variant='destructive'
                        disabled={isInventoryItemLoading}
                        loading={isInventoryItemLoading}
                        onClick={handleReassessment}
                      >
                        {t('mech.rra')}
                      </Button>
                    )}
                  <Button
                    className='w-full sm:w-[18rem]'
                    onClick={() => {
                      if (role === 1000) {
                        navigate('/farmer/inventory')
                      } else if (officer != null) {
                        navigate(`${role === 1001 ? '/officer' : ''}/inventory`)
                      } else if (role === 2001) {
                        navigate(`/service-provider/inventory`)
                      }
                    }}
                  >
                    {t('mech.okay')}
                  </Button>
                </div>
              ) : (
                <>
                  {' '}
                  {resultValue !== undefined && (
                    <div className={'flex flex-row justify-between gap-20'}>
                      <Button
                        onClick={form.handleSubmit((data) => {
                          const result =
                            resultValue === 'true' ||
                            (isEdit === true && data1?.data[0].result === true)
                              ? 2
                              : 3
                          onSubmit(data, result)
                        })}
                        variant={
                          resultValue === 'true' ||
                          (isEdit === true && data1?.data[0].result === true)
                            ? 'success'
                            : 'destructive'
                        }
                        size='xs'
                        color={
                          resultValue === 'true' ||
                          (isEdit === true && data1?.data[0].result === true)
                            ? 'success'
                            : 'error'
                        }
                        type='submit'
                        loading={mutation.isLoading}
                        disabled={
                          mutation.isLoading || (isEdit === true && !form.formState.isDirty)
                        }
                      >
                        {resultValue === 'true' ||
                        (isEdit === true && data1?.data[0].result === true)
                          ? t('mech.acc')
                          : t('mech.rej')}
                      </Button>

                      {resultValue === 'false' && (
                        <Button
                          onClick={form.handleSubmit((data) => {
                            onSubmit(data, 4)
                          })}
                          variant='destructive'
                          type='button'
                          size='xs'
                          color='error'
                          loading={mutation.isLoading}
                          disabled={
                            mutation.isLoading || (isEdit === true && !form.formState.isDirty)
                          }
                        >
                          {t('mech.ra')}
                        </Button>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </form>
        </Form>
      </TabView>
    </Box>
  )
}