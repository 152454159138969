import type React from 'react'
import { useEffect, useState } from 'react'
import { Button, Text } from 'components/ui'

interface NoInternetProps {
  children?: React.ReactNode
}
export const NoInternetPage: React.FC<NoInternetProps> = ({ children }) => {
  const [isOnline, setOnline] = useState(true)

  useEffect(() => {
    setOnline(navigator.onLine)
  }, [])

  window.addEventListener('online', () => {
    setOnline(true)
  })

  window.addEventListener('offline', () => {
    setOnline(false)
  })

  if (isOnline) {
    return children
  } else {
    return (
      <main className={'h-screen grid place-items-center'}>
        <div className={'flex flex-col gap-4 justify-center items-center'}>
          <Text className={'text-center'} size={'medium'}>
            No Internet Connection. Check your internet connection and try again.
          </Text>
          <div>
            <Button
              onClick={() => {
                window.location.reload()
              }}
              variant={'ghost'}
              size={'xs'}
            >
              Try Again
            </Button>
          </div>
        </div>
      </main>
    )
  }
}
