import type React from 'react'
import { object, z } from 'zod'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  RadioGroup,
  RadioGroupItem,
  useStepper
} from '../../../../../../components/ui'
import { StepperFooter } from '../Footer'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCroppingPlanStore } from '../../../stores/useCroppingPlanStore'
import { useCroppingPlanWeeding } from '../../../hooks/useCroppingPlanWeeding'

export const manualSchema = object({
  technique: z.enum(['Manual', 'Chemical'], {
    required_error: 'You need to select a Weeding technique'
  })
})

interface ManualWeedingProps {
  back: () => void
  onChemical: React.Dispatch<React.SetStateAction<boolean>>
}

// TODO manual weeding implementation
export const ManualWeedingPlan: React.FC<ManualWeedingProps> = ({ onChemical, back }) => {
  const { nextStep } = useStepper()
  const { weeding } = useCroppingPlanStore()
  const { createChemicalWeeding, updateChemicalWeeding, isLoading } =
    useCroppingPlanWeeding(nextStep)

  const form = useForm<z.infer<typeof manualSchema>>({
    resolver: zodResolver(manualSchema),
    defaultValues: {
      technique: weeding != null ? weeding.type : undefined
    }
  })

  const onSubmit = (data: z.infer<typeof manualSchema>): void => {
    if (data.technique === 'Chemical') {
      onChemical(true)
    } else {
      if (weeding == null) {
        createChemicalWeeding({ methods: 0 }, true)
      } else {
        updateChemicalWeeding({ methods: 0 }, true)
      }
    }
  }

  return (
    <Form {...form}>
      <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
        <div
          className={
            'flex flex-col p-4 gap-4 w-full sm:max-w-[60%] lg:max-w-[60%]' + ' xl:max-w-[40%]'
          }
        >
          <FormField
            control={form.control}
            name='technique'
            render={({ field }) => (
              <FormItem className='space-y-3'>
                <FormLabel>Weeding Technique</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className='flex flex-col space-y-1'
                  >
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem value={`Manual`} />
                      </FormControl>
                      <FormLabel className='font-normal'>Manual</FormLabel>
                    </FormItem>
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem value={`Chemical`} />
                      </FormControl>
                      <FormLabel className='font-normal'>Chemical</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <StepperFooter back={back} disabled={isLoading} isLoading={isLoading} />
      </form>
    </Form>
  )
}
