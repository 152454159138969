import {
  type ColumnDef,
  type ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type RowSelectionState,
  type SortingState,
  useReactTable
} from '@tanstack/react-table'
import type React from 'react'
import { useState } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  useToast
} from 'components/ui'
import { DataTablePagination } from 'components/Data-Table/datatable-pagination'
import { type SoilCorrection } from '../../../../../types/soilCorrection'
import { useGetSoilCorrection } from '../../hooks/useSoilCorrection'
import { type SoilCorrectionRecommendation } from '../../types'
import { SoilRecommendationDetails } from '../SoilRecommendationDetails'
import { useTranslation } from 'react-i18next'

export interface FarmInfo {
  farmId: number | null | undefined
  leaseId: number | null | undefined
  ssrId: number | null | undefined
}

interface DataTableProps<TData, TValue> {
  columns: Array<ColumnDef<TData, TValue>>
  data: TData[]
  farmInfo: FarmInfo
  correction?: SoilCorrection | undefined
  onClose: () => void
}

const getRecommendationId = (recommendation: SoilCorrectionRecommendation): string => {
  return `${recommendation?.ph_correction_recommendation?.reg_no ?? ''}${
    recommendation?.calcium_correction_recommendation?.reg_no ?? ''
  }${recommendation?.magnesium_correction_recommendation?.reg_no ?? ''}${
    recommendation?.sodium_correction_recommendation?.reg_no ?? ''
  }`
}

const getSelectedRecommendationId = (selected: SoilCorrection): string => {
  return `${selected?.ph_correction_recommendation?.reg_no ?? ''}${
    selected?.calcium_correction_recommendation?.reg_no ?? ''
  }${selected?.magnesium_correction_recommendation?.reg_no ?? ''}${
    selected?.sodium_correction_recommendation?.reg_no ?? ''
  }`
}

export function SoilCorrectionRecommendationTable<TData, TValue>({
  columns,
  data,
  farmInfo,
  correction,
  onClose
}: DataTableProps<TData, TValue>): React.ReactNode {
  const { toast } = useToast()
  const { createSoilCorrection, updateSavedSoilCorrection, isLoading } = useGetSoilCorrection()
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    correction != null
      ? {
          [getSelectedRecommendationId(correction)]: true
        }
      : {}
  )
  const [open, setOpen] = useState(false)
  const [clickedData, setClickedData] = useState<SoilCorrectionRecommendation | null>(null)
  const { t } = useTranslation('landManagement')

  const table = useReactTable({
    data,
    columns,
    enableMultiRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => getRecommendationId(row as SoilCorrectionRecommendation),
    state: {
      sorting,
      columnFilters,
      rowSelection
    }
  })

  const openDialog = (): void => {
    setOpen(true)
  }

  const closeDialog = (): void => {
    setOpen(false)
  }

  const tableDataChanged = (): boolean => {
    if (correction == null) {
      return false
    }

    const selectedRecommendation = table
      .getSelectedRowModel()
      .rows.map((row) => row.original as SoilCorrectionRecommendation)

    return (
      getRecommendationId(selectedRecommendation[0]) !== getSelectedRecommendationId(correction)
    )
  }

  const onSubmit = (): void => {
    if (table.getIsSomeRowsSelected()) {
      const selectedRecommendation = table
        .getSelectedRowModel()
        .rows.map((row) => row.original as SoilCorrectionRecommendation)

      if (correction == null) {
        createSoilCorrection(
          selectedRecommendation[0],
          { ssrId: farmInfo?.ssrId, farmId: farmInfo?.farmId, leaseId: farmInfo?.leaseId },
          onClose
        )
      } else if (tableDataChanged()) {
        updateSavedSoilCorrection(
          correction,
          selectedRecommendation[0],
          {
            ssrId: correction.ssr_id,
            scrId: correction.scr_id,
            farmId: farmInfo?.farmId,
            leaseId: farmInfo?.leaseId
          },
          onClose
        )
      } else {
        onClose()
      }
    } else {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: 'Please select a seed from the table'
      })
    }
  }

  return (
    <div className={'flex flex-col gap-4'}>
      <div className='rounded-md border'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length !== 0 ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} className={'hover:cursor-pointer hover:bg-muted-hover'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      onClick={() => {
                        if (cell.column.id === 'select') {
                          row.toggleSelected()
                        } else {
                          const recommendation = row.original as SoilCorrectionRecommendation
                          setClickedData(recommendation)

                          if (clickedData != null) {
                            openDialog()
                          }
                        }
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className='h-24 text-center'>
                  {t('soilReco.no')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} selectable />

      <Dialog open={open} defaultOpen={false} modal onOpenChange={closeDialog}>
        <DialogContent
          className={
            'max-w-[80%] md:max-w-[60%] lg:max-w-[50%] overflow-scroll max-h-[90svh] md:max-h-[75svh] lg:max-h-[65svh]'
          }
        >
          {clickedData != null ? <SoilRecommendationDetails recommendation={clickedData} /> : null}
        </DialogContent>
      </Dialog>

      <div className='w-full flex justify-end gap-2'>
        <Button
          disabled={
            isLoading || !table.getIsSomeRowsSelected() || !table.getIsSomePageRowsSelected()
          }
          loading={isLoading}
          onClick={onSubmit}
          size={'sm'}
          variant={'primary'}
        >
          {t('soilReco.save')}
        </Button>
      </div>
    </div>
  )
}