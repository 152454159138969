import { Officer } from 'components/Auth/Officer'
import { CroppingPlanView } from 'features/officer/cropping-plan/routes/CroppingPlanView'
import { Farmers, FarmersFarmView, FarmersView } from 'features/officer/farmers/routes'
import { LeasedFarmView } from 'features/officer/farmers/routes/LeasedFarmView'
import { GrantsView } from 'features/officer/grants-management/routes/grantsView'
import { Navigate } from 'react-router-dom'
import { OfficerProfile } from '../features/profile/routes/Officer'
import { SearchFarms } from '../features/officer/land-management/routes/SearchFarms'
import { AllCroppingPlans } from '../features/officer/cropping-plan/routes/AllCroppingPlans'
import { SearchCroppingPlan } from '../features/officer/cropping-plan/routes/SearchCroppingPlan'
import { SearchLease } from '../features/officer/lease-management/routes/SearchLease'
import { ChangePassword } from 'components/Change-Password/ChangePassword'
import { PasswordProtectedRoute } from 'components/Change-Password/PasswordProtectedRoute'
import { EOInventories } from '../features/officer/inventory/routes/EOInventories'
import { ViewAllInventories } from '../features/officer/inventory/routes/ViewAllInventories'
import { ModifyInventoryWrapper } from './mechanization'
import { Checklist } from '../features/mechanisationOfficer/inventory/components/checklist/Checklist'

export const officerRoutes = {
  path: '/officer',
  element: <Officer />,
  children: [
    // { index: true, element: <OfficerHome /> },
    { index: true, element: <PasswordProtectedRoute children={<Farmers />} /> },
    { path: '/officer/change-password', element: <ChangePassword /> },
    { path: '/officer/grants', element: <PasswordProtectedRoute children={<GrantsView />} /> },
    {
      path: '/officer/verify/:farmerId',
      element: <PasswordProtectedRoute children={<SearchFarms />} />
    },
    {
      path: '/officer/verify-lease/:farmerId',
      element: <PasswordProtectedRoute children={<SearchLease />} />
    },
    {
      path: '/officer/farmers/:farmerId',
      element: <PasswordProtectedRoute children={<FarmersView />} />
    },
    {
      path: '/officer/farmers/:farmerId/:farmId',
      element: <PasswordProtectedRoute children={<FarmersFarmView />} />
    },
    {
      path: '/officer/farmers/lease/:destinationFarmerId/:leaseId',
      element: <PasswordProtectedRoute children={<LeasedFarmView />} />
    },
    {
      path: '/officer/farmers/lease/:destinationFarmerId/:leaseId',
      element: <PasswordProtectedRoute children={<LeasedFarmView />} />
    },
    {
      path: '/officer/cropping-plan',
      element: <PasswordProtectedRoute children={<AllCroppingPlans />} />
    },
    {
      path: '/officer/verify-cp/:farmerId',
      element: <PasswordProtectedRoute children={<SearchCroppingPlan />} />
    },
    {
      path: '/officer/cropping-plan/:farmerId/:cpId',
      element: <PasswordProtectedRoute children={<CroppingPlanView />} />
    },
    { path: '/officer/profile', element: <PasswordProtectedRoute children={<OfficerProfile />} /> },
    { path: '/officer/*', element: <PasswordProtectedRoute children={<Navigate to={'.'} />} /> },

    //   Inventory
    {
      path: '/officer/inventory',
      element: <PasswordProtectedRoute children={<EOInventories />} />
    },
    {
      path: '/officer/inventory/view/:userType/:Id',
      element: <PasswordProtectedRoute children={<ViewAllInventories />} />
    },
    {
      path: '/officer/inventory/view-inventory/:userType/:inventoryId',
      element: <PasswordProtectedRoute children={<ModifyInventoryWrapper />} />
    },
    {
      path: '/officer/inventory/assessment/:inventoryId',
      element: <PasswordProtectedRoute children={<Checklist />} />
    },

    { path: '*', element: <PasswordProtectedRoute children={<Navigate to={'.'} />} /> }
  ]
}
