import type React from 'react'
import { motion } from 'framer-motion'
import Planter from 'assets/landing/impact_in_action/5.jpeg'
import SingleBenefit from './SingleBenefit'
import { useTranslation } from 'react-i18next'

export const TemoBenefits: React.FC = () => {
  const { t } = useTranslation('landing')
  return (
    <div className='bg-blue-50 w-full '>
      <div className='container mx-auto flex flex-col  py-20 px-10 space-y-12  justify-center w-full '>
        {/* What is Temo */}
        <motion.div
          className='flex flex-col md:flex-row space-y-6'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {/* First Part of the row */}
          <div className='basis-full md:basis-1/2 flex flex-col space-y-16 md:justify-center md:items-center'>
            <h1 className='text-4xl md:text-5xl lg:text-6xl gap-12 font-bold text-gray-700 landing-page '>
              {t('home.why')}
            </h1>
            <img
              src={Planter}
              alt='Man operating drone in a sorghum field'
              className='w-full md:w-80 lg:w-full  h-auto max-w-sm md:max-w-md lg:max-w-lg rounded-xl'
            />
          </div>
          {/* Second Part of the row */}
          <div className='basis-full md:basis-1/2 flex justify-center items-center'>
            <SingleBenefit />
          </div>
        </motion.div>
      </div>
    </div>
  )
}