// cropData.ts
import Maize from 'assets/landing/target-yields/MAIZE.webp'
import Millet from 'assets/landing/target-yields/millet.jpg'
import Sorghum from 'assets/landing/target-yields/sorghum.jpeg'
import Rice from 'assets/landing/target-yields/rice.jpeg'
import Wheat from 'assets/landing/target-yields/wheat.jpeg'
import MungBean from 'assets/landing/target-yields/mung_bean.webp'
import Cowpeas from 'assets/landing/target-yields/cowpeas.webp'
import SugarBeans from 'assets/landing/target-yields/sugar_beans.jpeg'
import Soya from 'assets/landing/target-yields/soya_beans.jpg'
import Groundnuts from 'assets/landing/target-yields/groundnuts.jpeg'
import Sunflower from 'assets/landing/target-yields/sunflower.jpg'
import Safflower from 'assets/landing/target-yields/safflower.jpeg'
import Fodder from 'assets/landing/target-yields/fodder.jpeg'

export interface CropData {
  name: string
  image: string
  tableData: {
    headers: string[]
    rows: Array<Array<string | number>>
  }
}

export const crops = (t: (key: string) => string): CropData[] => [
  {
    name: 'Millet',
    image: Millet,
    tableData: {
      headers: [t('about.cat'), '2024/25', '2025/26', '2026/27', '2027/28', '2028/29'],
      rows: [
        [t('types.micro'), 0.7, 1, 1.5, 2, 2.5],
        [t('types.small'), 2.5, 2.7, 3, 3.5, 4],
        [t('types.med'), 2.7, 3, 3.5, 4, 4.5],
        [t('types.large'), 3, 3.5, 4, 4.5, 5]
      ]
    }
  },
  {
    name: 'Maize',
    image: Maize,
    tableData: {
      headers: [t('about.cat'), '2024/25', '2025/26', '2026/27', '2027/28', '2028/29'],
      rows: [
        [t('types.micro'), 0.9, 1.5, 1.7, 2, 2.5],
        [t('types.small'), 2.5, 2.7, 3, 3.5, 4],
        [t('types.med'), 2.7, 3, 3.5, 4, 4.5],
        [t('types.large'), 3, 3.5, 4, 4.5, 5]
      ]
    }
  },
  {
    name: 'Sorghum',
    image: Sorghum,
    tableData: {
      headers: [t('about.cat'), '2024/25', '2025/26', '2026/27', '2027/28', '2028/29'],
      rows: [
        [t('types.micro'), 0.95, 1.5, 1.7, 2, 2.5],
        [t('types.small'), 2.5, 2.7, 3, 3.5, 4],
        [t('types.med'), 2.7, 3, 3.5, 4, 4.5],
        [t('types.large'), 3, 3.5, 4, 4.5, 5]
      ]
    }
  },
  {
    name: 'Rice',
    image: Rice,
    tableData: {
      headers: [t('about.cat'), '2024/25', '2025/26', '2026/27', '2027/28', '2028/29'],
      rows: [
        [t('types.micro'), 0.6, 0.8, 1.2, 1.5, 2],
        [t('types.small'), 1.2, 1.5, 2, 2.5, 3],
        [t('types.med'), 1.8, 2, 2.5, 3, 3.5],
        [t('types.large'), 2.5, 2.8, 3, 3.5, 4]
      ]
    }
  },
  {
    name: 'Wheat',
    image: Wheat,
    tableData: {
      headers: [t('about.cat'), '2024/25', '2025/26', '2026/27', '2027/28', '2028/29'],
      rows: [
        [t('types.micro'), 0.6, 0.8, 1.2, 1.5, 2],
        [t('types.small'), 1.2, 1.5, 2, 2.5, 3],
        [t('types.med'), 1.8, 2, 2.5, 3, 3.5],
        [t('types.large'), 2.5, 2.8, 3, 3.5, 4]
      ]
    }
  },
  {
    name: 'Mung bean',
    image: MungBean,
    tableData: {
      headers: [t('about.cat'), '2024/25', '2025/26', '2026/27', '2027/28', '2028/29'],
      rows: [
        [t('types.micro'), 0.8, 1, 1.3, 1.6, 2],
        [t('types.small'), 1.5, 2, 2.3, 2.6, 3],
        [t('types.med'), 2, 2.3, 2.6, 3, 3.5],
        [t('types.large'), 2.6, 3, 3.5, 3.8, 4]
      ]
    }
  },
  {
    name: 'Cowpeas',
    image: Cowpeas,
    tableData: {
      headers: [t('about.cat'), '2024/25', '2025/26', '2026/27', '2027/28', '2028/29'],
      rows: [
        [t('types.micro'), 0.8, 1, 1.5, 1.8, 2.2],
        [t('types.small'), 1.5, 1.8, 2.2, 2.6, 3],
        [t('types.med'), 2, 2.6, 3, 3.3, 3.6],
        [t('types.large'), 2.5, 3, 3.3, 3.6, 4]
      ]
    }
  },
  {
    name: 'Sugar beans',
    image: SugarBeans,
    tableData: {
      headers: [t('about.cat'), '2024/25', '2025/26', '2026/27', '2027/28', '2028/29'],
      rows: [
        [t('types.micro'), 0.8, 1.2, 1.5, 1.8, 2.1],
        [t('types.small'), 1.5, 1.8, 2.1, 2.5, 3],
        [t('types.med'), 2, 2.5, 2.9, 3.3, 3.7],
        [t('types.large'), 2.5, 3.3, 3.7, 3.9, 4.2]
      ]
    }
  },
  {
    name: 'Soya bean',
    image: Soya,
    tableData: {
      headers: [t('about.cat'), '2024/25', '2025/26', '2026/27', '2027/28', '2028/29'],
      rows: [
        [t('types.micro'), 0.8, 1, 1.5, 2, 2.5],
        [t('types.small'), 1.5, 2, 2.5, 3, 3.5],
        [t('types.med'), 2, 2.5, 3, 3.5, 4],
        [t('types.large'), 2.5, 3, 3.5, 4, 4.5]
      ]
    }
  },
  {
    name: 'Groundnuts',
    image: Groundnuts,
    tableData: {
      headers: [t('about.cat'), '2024/25', '2025/26', '2026/27', '2027/28', '2028/29'],
      rows: [
        [t('types.micro'), 0.8, 1, 1.3, 1.6, 2],
        [t('types.small'), 1.6, 2, 2.5, 3, 3.5],
        [t('types.med'), 2, 2.3, 2.6, 3, 3],
        [t('types.large'), 2.5, 3, 3.5, 4, 4.5]
      ]
    }
  },
  {
    name: 'Sunflower',
    image: Sunflower,
    tableData: {
      headers: [t('about.cat'), '2024/25', '2025/26', '2026/27', '2027/28', '2028/29'],
      rows: [
        [t('types.micro'), 0.8, 1, 1.3, 1.6, 2],
        [t('types.small'), 1.6, 1.9, 2.3, 2.7, 3],
        [t('types.med'), 2, 2.3, 2.7, 3, 3.5],
        [t('types.large'), 2.5, 3, 3.5, 4, 4.5]
      ]
    }
  },
  {
    name: 'Safflower',
    image: Safflower,
    tableData: {
      headers: [t('about.cat'), '2024/25', '2025/26', '2026/27', '2027/28', '2028/29'],
      rows: [
        [t('types.micro'), 0.8, 1, 1.3, 1.6, 2],
        [t('types.small'), 1.6, 1.9, 2.3, 2.7, 3],
        [t('types.med'), 2, 2.3, 2.7, 3, 3.5],
        [t('types.large'), 2.5, 3, 3.5, 4, 4.5]
      ]
    }
  },
  {
    name: 'Fodder',
    image: Fodder,
    tableData: {
      headers: [t('about.cat'), '2024/25', '2025/26', '2026/27', '2027/28', '2028/29'],
      rows: [
        [t('types.micro'), 0.6, 1, 1.5, 2, 2.5],
        [t('types.small'), 1.2, 1.5, 2, 2.5, 3],
        [t('types.med'), 1.8, 2, 2.5, 3, 3.5],
        [t('types.large'), 2.5, 2.8, 3, 3.5, 4]
      ]
    }
  }
]