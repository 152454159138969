import type React from 'react'
import { Text } from 'components/ui'
import { useTranslation } from 'react-i18next'
interface VerifiedStatusProps {
  status: 'verified' | 'pending' | 'rejected' | 'pending verification' | 'pending edit verification'
  acceptance_status?: 'accepted' | 'declined' | 'pending acceptance'
}

export const VerifiedStatus: React.FC<VerifiedStatusProps> = ({ status }) => {
  const { t } = useTranslation('landManagement')
  if (status === 'pending') {
    return (
      <Text size={'medium'} variant={'warning'} className={'font-normal flex gap-2 items-center'}>
        <p className='material-symbols-outlined'>pending_actions</p> {t('homePage.pendingCard')}
      </Text>
    )
  }

  if (status === 'rejected') {
    return (
      <Text size={'medium'} variant={'error'} className={'font-normal flex gap-2 items-center'}>
        <p className='fa-solid fa-circle-xmark' /> {t('homePage.rejected')}
      </Text>
    )
  }

  if (status === 'pending edit verification') {
    return (
      <Text size={'medium'} variant={'warning'} className={'font-normal flex gap-2 items-center'}>
        <p className='material-symbols-outlined'>pending_actions</p> {t('homePage.pendingEdit')}
      </Text>
    )
  }

  return (
    <div className={'flex justify-start gap-2 items-center w-full'}>
      {status === 'verified' && (
        <Text size={'medium'} variant={'success'} className={'font-normal flex gap-2 items-center'}>
          <p className='fa-solid fa-circle-check' />
          {t('homePage.verifiedCard')}
        </Text>
      )}
    </div>
  )
}
