import type React from 'react'
import backgroundImage from '../../../../../assets/landing/impact_in_action/HomepageImage.jpg'
import { useNavigate } from 'react-router-dom'
import { CTAButton } from '../Generic/CTAButton'
import { useTranslation } from 'react-i18next'

/**
 * HeaderHome component
 *
 * This component renders a header section with a background image, an overlay, and text content.
 * It uses Tailwind CSS classes for styling and layout.
 *
 * @returns {JSX.Element} The rendered header section
 */
export const HeaderHome: React.FC = () => {
  const { t } = useTranslation('landing')
  const navigate = useNavigate()
  return (
    <div className='bg-blue-50 w-full relative h-fit-content'>
      <div className='container mx-auto flex flex-col md:flex-row py-10 md:py-40 lg:py-8 justify-center w-full'>
        {/* Background Image */}
        <div
          className='absolute inset-0 bg-cover bg-center'
          style={{
            backgroundImage: `url(${backgroundImage})`
          }}
        ></div>

        {/* Overlay */}
        <div className='absolute inset-0 bg-black opacity-60'></div>

        {/* Text */}
        <div className='relative flex flex-col justify-center items-center h-screen text-white text-center px-4 md:px-8 lg:px-16 space-y-4 md:space-y-8 max-w-screen-lg mx-auto'>
          <h1 className='text-4xl md:text-5xl lg:text-6xl font-bold mb-4 text-white  leading-relaxed tracking-normal md:tracking-wide landing-page'>
            {t('land.boost')}
          </h1>
          <p className='text-lg md:text-2xl xl:text-3xl text-regular leading-relaxed tracking-normal md:tracking-wide landing-page'>
            {t('land.temo')}
          </p>

          {/* Call to action Button routing to registration page */}
          <CTAButton label={t('land.join')} to='/registration' />
          <div className='bg-black bg-opacity-35 py-4 px-1 rounded-md'>
            <h6 className='text-xs md:text-base font-semibold px-2 md:px-10 tracking-wide landing-page'>
              {t('land.moa')}
            </h6>
          </div>
        </div>
      </div>
    </div>
  )
}