import type React from 'react'
import { Button, Text, useToast } from 'components/ui'
import { XCircle } from 'lucide-react'
import { useTranslation } from 'react-i18next'

interface RejectedFarmProps {
  reference: string
  onComplete: () => void
}

export const RejectedFarm: React.FC<RejectedFarmProps> = ({ onComplete, reference }) => {
  const { t } = useTranslation('extensionOfficer')
  const { toast } = useToast()

  return (
    <div className={'flex flex-col justify-center items-center gap-8'}>
      <div className={'w-full flex flex-col items-center gap-4'}>
        <Text size={'medium'} variant={'error'} className={'flex gap-2 items-center'}>
          <XCircle />
          {t('land.frej')}
        </Text>
      </div>
      <div className={'text-center w-4/5 rounded p-2'}>
        <Text>
          {t('land.store')}: <b className={'text-destructive'}>{reference}</b>
        </Text>
      </div>
      <Button
        variant={'destructive'}
        onClick={() => {
          onComplete()
          toast({
            variant: 'success',
            description: t('land.frej')
          })
        }}
        size={'lg'}
        color={'destructive'}
      >
        {t('land.com')}
      </Button>
    </div>
  )
}