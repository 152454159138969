// farmFeatures.ts
import inventoriesImage from '../../../../../../assets/landing/dashboard_snippets/my_inventory.png'
import loanImage from '../../../../../../assets/landing/dashboard_snippets/loan_management.png'
import overViewImage from '../../../../../../assets/landing/dashboard_snippets/my_farms.png'
import leaseImage from '../../../../../../assets/landing/dashboard_snippets/leased_farm.png'
import FarmOverview from 'assets/landing/icons/tree_cropping_plan.svg'
import LandOverview from 'assets/landing/icons/grain.svg'
import LoanIcon from 'assets/landing/icons/loan_tab.svg'
import Tractorinventory from 'assets/landing/icons/tractor2.svg'

export interface FarmFeature {
  id: number
  icon: string
  title: string
  description: string
  image: string
}

export const farmFeatures = (t: (key: string) => string): FarmFeature[] => {
  return [
    {
      id: 1,
      icon: FarmOverview,
      title: t('cms.ft1'),
      description: t('cms.fd1'),
      image: overViewImage
    },
    {
      id: 2,
      icon: LandOverview,
      title: t('cms.ft2'),
      description: t('cms.fd2'),
      image: leaseImage
    },
    {
      id: 3,
      icon: LoanIcon,
      title: t('cms.ft3'),
      description: t('cms.fd3'),
      image: loanImage
    },
    {
      id: 4,
      icon: Tractorinventory,
      title: t('cms.ft4'),
      description: t('cms.fd4'),
      image: inventoriesImage
    }
  ]
}
