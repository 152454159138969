import { useIndividualRegistrationStore } from 'features/authentication/stores/useIndividualRegistrationStore'
import type React from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import type z from 'zod'
import { literal, object, optional, string, type TypeOf } from 'zod'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input
} from '../../../../components/ui'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'

const schema = object({
  postalAddress: string().min(1, 'Postal Address is required'),
  physicalAddress: string().min(1, 'Physical Address is required'),
  email: optional(string().email('Email is invalid')).or(literal(''))
})

export type AddressInputValues = TypeOf<typeof schema>

interface AddressesFormProps {
  className?: string
  onNext: () => void
  onBack: () => void
}

export const AddressesForm: React.FC<AddressesFormProps> = ({
  className,
  onBack,
  onNext
}: AddressesFormProps) => {
  const { contactDetails, setContactDetails } = useIndividualRegistrationStore()

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      postalAddress: contactDetails?.postalAddress ?? '',
      physicalAddress: contactDetails?.physicalAddress ?? '',
      email: contactDetails?.email != null ? contactDetails.email : undefined
    }
  })

  const onSubmit: SubmitHandler<AddressInputValues> = (data: AddressInputValues) => {
    setContactDetails(
      contactDetails === null
        ? {
            number: '',
            email: data.email,
            physicalAddress: data.physicalAddress,
            postalAddress: data.postalAddress
          }
        : {
            ...contactDetails,
            email: data.email,
            physicalAddress: data.physicalAddress,
            postalAddress: data.postalAddress
          }
    )
    onNext()
  }

  const { t } = useTranslation('authenticationTranslation')

  return (
    <Form {...form}>
      <form className={'w-full flex flex-col gap-4'} onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name={'postalAddress'}
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel>{t('contactDetails.postal')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('contactDetails.postal')}
                  classLabel={'bg-background-dark sm:bg-card'}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        ></FormField>
        <FormField
          control={form.control}
          name={'physicalAddress'}
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel>{t('contactDetails.physical')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('contactDetails.physical')}
                  classLabel={'bg-background-dark sm:bg-card'}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        ></FormField>{' '}
        <FormField
          control={form.control}
          name={'email'}
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel>{t('contactDetails.email')}</FormLabel>
              <FormControl>
                <Input
                  placeholder={t('contactDetails.email')}
                  classLabel={'bg-background-dark sm:bg-card'}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        ></FormField>
        <div className={'flex flex-row justify-between'}>
          <Button className={'w-[46%]'} variant={'secondary'} onClick={onBack}>
            {t('contactDetails.back')}
          </Button>
          <Button className={'w-[46%]'} variant={'primary'} type={'submit'}>
            {t('personalDetails.next')}
          </Button>
        </div>
      </form>
    </Form>
  )
}
