import { type ColumnDef } from '@tanstack/react-table'
import { DataTableColumnHeader } from 'components/Data-Table/datatable-header'
import { type InventoryTransfer } from '../../types'
import moment from 'moment'

export const historyColumns = (t: (key: string) => string): Array<ColumnDef<InventoryTransfer>> => [
  {
    id: 'last_update_date',
    accessorFn: (row) => row.last_update_date,
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => {
      const formattedDate = moment(row.getValue('last_update_date')).format('YYYY/MM/DD hh:mm a')
      return <div>{formattedDate}</div>
    },
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('history.own')} />
  },
  {
    id: 'origin_merchant_name',
    accessorFn: (row) => {
      const originMerchantName = row.origin_merchant_name
      const originFarmerFirstName = row.origin_farmer_firstname ?? ''
      const originFarmerSurname = row?.origin_farmer_surname ?? ''

      return originMerchantName ?? `${originFarmerFirstName} ${originFarmerSurname}`.trim()
    },
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => {
      const displayName = row.getValue<string>('origin_merchant_name')
      return <div className='uppercase'>{displayName}</div>
    },
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('history.from')} />
  },
  {
    id: 'dest_farmer_id',
    accessorFn: (row) => {
      const destMerchantName = row.dest_merchant_name
      const destFarmerFirstName = row.dest_farmer_firstname ?? ''
      const destFarmerSurname = row.dest_farmer_surname ?? ''

      return destMerchantName ?? `${destFarmerFirstName} ${destFarmerSurname}`.trim()
    },
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => {
      const displayName = row.getValue<string>('dest_farmer_id')
      return <div className='uppercase'>{displayName}</div>
    },
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('history.to')} />
  },
  {
    id: 'status_desc',
    accessorFn: (row) => row.status_desc,
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => <div className='capitalize'>{row.getValue('status_desc')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('history.status')} />
  }
]
