import { create } from 'zustand'

interface AuthenticationState {
  userId: string | null | undefined
  role: number | null | undefined
  isFirstLogin: boolean | null | undefined
  isLogout: boolean | null | undefined
  setUserID: (userId: string | null | undefined) => void
  setRole: (role: number | null | undefined) => void
  setIsFirstLogin: (isFirstLogin: boolean | null | undefined) => void
  setIsLogout: (isLogout: boolean | null | undefined) => void
}

export const useAuthenticationStore = create<AuthenticationState>()((set) => ({
  isFirstLogin: null,
  role: null,
  userId: null,
  isLogout: null,
  setUserID: (userId: string | null | undefined) => {
    set((state) => ({ ...state, userId }))
  },
  setRole: (role: number | null | undefined) => {
    set((state) => ({ ...state, role }))
  },
  setIsFirstLogin: (bool: boolean | null | undefined) => {
    set((state) => ({ ...state, isFirstLogin: bool }))
  },
  setIsLogout: (bool: boolean | null | undefined) => {
    set((state) => ({ ...state, isLogout: bool }))
  }
}))
