import type React from 'react'
import { motion } from 'framer-motion'
import Planter from 'assets/landing/impact_in_action/20230119_9.jpg'
import SingleService from './SingleService'
import { CTAButton } from '../Generic/CTAButton'
import { useTranslation } from 'react-i18next'

export const TemoServices: React.FC = () => {
  const { t } = useTranslation('landing')

  return (
    <div className='bg-white w-full '>
      <div className='container mx-auto flex flex-col  py-20 px-10  space-y-12  justify-center w-full '>
        {/* What is Temo */}
        <motion.div
          className='flex flex-col-reverse md:flex-row space-y-6'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {/* First Part of the row */}
          <div className='basis-full md:basis-1/2 flex justify-center items-center mt-12 md:mt-0'>
            <img
              src={Planter}
              alt='Sorghum field'
              className='w-full md:w-80 lg:w-full h-full max-w-sm md:max-w-md lg:max-w-lg rounded-xl'
            />
          </div>
          {/* Second Part of the row */}
          <div className='basis-full md:basis-1/2 flex md:justify-center md:items-center'>
            <h1 className='text-4xl md:text-5xl lg:text-6xl gap-12 font-bold text-gray-700 landing-page '>
              {t('home.explore')}
            </h1>
          </div>
        </motion.div>

        {/*   Services Cards */}
        <SingleService />

        <div className='flex justify-center'>
          <CTAButton label={t('home.explore2')} to='about-temo-letlotlo' />
        </div>
      </div>
    </div>
  )
}