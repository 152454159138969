import { MainContainer } from 'features/loan-management/components/stepper'
import type React from 'react'
import { useState } from 'react'
import 'primereact/resources/themes/luna-blue/theme.css'
import './flags.css'
import { type PostToNDB } from '../../../types/individual'
import { useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import { useMutation } from '@tanstack/react-query'
import { AxiosError, type AxiosResponse } from 'axios'
import { type ApiResponse } from '../../../../../types'
import { mapCmsErrorToMessage } from '../../../../../utils/apiErrors'
import { postToNDB } from '../../../api/postToNDB'
import { applicationHeader } from '../stores/applicationHeader'
import { Button, Text, toast, useStepper } from '../../../../../components/ui'
import { RiUploadCloudFill } from 'react-icons/ri'
import { BsFilePdfFill } from 'react-icons/bs'
import { IoIosWarning } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import { Info } from 'lucide-react'

interface DocumentUpload {
  name: string
  uploadedFile: File | null
  uploadedFileName: string
  base64Content?: string
}

const formatDocumentName = (name: string): string => {
  return name
    .split('_')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const AttachmentRepo: React.FC = () => {
  const { prevStep, isLastStep } = useStepper()
  const initialDocuments: DocumentUpload[] = [
    { name: 'loan_application_form', uploadedFile: null, uploadedFileName: '' },
    { name: 'identity_documents', uploadedFile: null, uploadedFileName: '' },
    { name: 'tax_clearance_certificate', uploadedFile: null, uploadedFileName: '' },
    { name: 'acknowlegment_of_application', uploadedFile: null, uploadedFileName: '' },
    { name: 'soil_test', uploadedFile: null, uploadedFileName: '' },
    {
      name: 'confirmation_of_membership_grain_members_association',
      uploadedFile: null,
      uploadedFileName: ''
    },

    { name: 'marriage_certificate', uploadedFile: null, uploadedFileName: '' },
    { name: 'letter_of_consent', uploadedFile: null, uploadedFileName: '' },
    { name: 'proof_of_marriage_regime', uploadedFile: null, uploadedFileName: '' },
    { name: 'land_board_certificate', uploadedFile: null, uploadedFileName: '' },
    { name: 'title_deed', uploadedFile: null, uploadedFileName: '' },
    { name: 'lease_management', uploadedFile: null, uploadedFileName: '' },
    { name: 'loan_statement', uploadedFile: null, uploadedFileName: '' },
    { name: 'utility_bill', uploadedFile: null, uploadedFileName: '' },
    { name: 'confirmation_letter', uploadedFile: null, uploadedFileName: '' }
  ]
  const { t } = useTranslation('loanManagement')

  const [documents, setDocuments] = useState<DocumentUpload[]>(initialDocuments)
  const navigate = useNavigate()

  const handleFileChange = (index: number, event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0]

    if (file != null) {
      const uploadedFileName = file.name !== undefined ? file.name : ''
      const reader = new FileReader()
      reader.onload = () => {
        const result = reader.result as ArrayBuffer
        setDocuments((prevDocuments) => {
          const newDocuments = [...prevDocuments]
          newDocuments[index].uploadedFile = file
          newDocuments[index].uploadedFileName = uploadedFileName
          newDocuments[index].base64Content = btoa(
            new Uint8Array(result).reduce((data, byte) => data + String.fromCharCode(byte), '')
          )
          return newDocuments
        })
      }

      reader.readAsArrayBuffer(file)
    }
  }

  const handleDelete = (index: number): void => {
    setDocuments((prevDocuments) => {
      const newDocuments = [...prevDocuments]
      newDocuments[index].uploadedFile = null
      newDocuments[index].uploadedFileName = ''
      return newDocuments
    })
  }

  const mutation = useMutation({
    mutationFn: async (params: PostToNDB) => {
      return postToNDB(params)
    }
  })

  const [loanId] = useAtom(applicationHeader)
  const requiredDocuments = [
    'loan_application_form',
    'identity_documents',
    'tax_clearance_certificate',
    'acknowlegment_of_application',
    'soil_test'
  ]
  const onSubmit = (event: React.FormEvent): void => {
    event.preventDefault()
    const missingDocuments = requiredDocuments.filter((docName) => {
      const document = documents.find((d) => d.name === docName)
      return document == null || document.uploadedFile === null
    })

    if (missingDocuments.length > 0) {
      toast({
        variant: 'destructive',
        title: `Please upload the following required documents: ${missingDocuments.join(', ')}`
      })

      return
    }

    const loanApplication: PostToNDB = {
      loan_application_id: loanId?.loan_application_id as number,
      documents: {
        loan_application_form: '',
        confirmation_of_membership_grain_members_association: '',
        identity_documents: '',
        marriage_certificate: '',
        letter_of_consent: '',
        proof_of_marriage_regime: '',
        tax_clearance_certificate: '',
        acknowlegment_of_application: '',
        soil_test: '',
        land_board_certificate: '',
        title_deed: '',
        lease_management: '',
        loan_statement: '',
        utility_bill: '',
        confirmation_letter: ''
      }
    }

    documents.forEach((document) => {
      loanApplication.documents[document.name] =
        document.base64Content != null ? document.base64Content : 'empty'
    })

    mutation.mutate(loanApplication, {
      onSuccess: (result) => {
        if (result.status === 201) {
          toast({
            variant: 'success',
            title: 'Your loan is submitted to the NDB!'
          })
          navigate('/farmer/loans')
        }
      },
      onError: (err) => {
        if (err instanceof AxiosError) {
          const error = err.response as AxiosResponse<ApiResponse<[]>>

          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: mapCmsErrorToMessage(
              error.status.toString() ?? err.code ?? error.data.message ?? ''
            )
          })
        }
      }
    })
  }

  return (
    <MainContainer>
      {/* TODO Put info about red and yellow, yellow optional , red required */}
      <form onSubmit={onSubmit}>
        <div className='flex flex-col w-full items-center mb-18 space-y-6 py-12'>
          <div className='flex'>
            <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
            <Text variant='secondary' size='small' className=''>
              All the documents are required to be uploaded in PDF format. Please ensure that the
              documents are clear and legible. &nbsp;
              <span className='text-red-300'>Red indicates required documents </span> and{' '}
              <span className='text-blue-300'>blue indicates optional documents.</span>
            </Text>
          </div>
          <div className='flex flex-col w-full md:w-5/6  items-center'>
            {documents.map((document, index) => (
              <div
                className='flex flex-row w-full md:w-4/5 mb-8 hover:cursor-pointer items-center'
                key={index}
              >
                <div className='mr-2.5'>
                  <BsFilePdfFill className='w-8 md:w-20 h-6 md:h-12 text-red-400' />
                </div>
                <div className='flex flex-col'>
                  {document.uploadedFileName !== '' ? (
                    <Text size='body' variant='bodyTextLight'>
                      {formatDocumentName(document.uploadedFileName)}
                    </Text>
                  ) : (
                    <span className='flex flex-row space-x-2 items-center'>
                      <IoIosWarning
                        className={
                          requiredDocuments.includes(document.name)
                            ? 'text-red-300'
                            : 'text-blue-300'
                        }
                      />
                      <Text
                        size='body'
                        className={
                          requiredDocuments.includes(document.name)
                            ? 'text-red-300'
                            : 'text-blue-300'
                        }
                        // variant={requiredDocuments.includes(document.name) ? 'error' : 'warning'}
                      >
                        Upload {formatDocumentName(document.name)}
                      </Text>
                    </span>
                  )}
                  <Text size='medium'>{formatDocumentName(document.name)}</Text>
                </div>
                <div className='ml-auto'>
                  <label className='cursor-pointer'>
                    <RiUploadCloudFill className='w-5 md:w-6 h-5 md:h-6 text-gray-400' />
                    <input
                      type='file'
                      className='hidden'
                      onChange={(e) => {
                        handleFileChange(index, e)
                      }}
                    />
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>

        {isLastStep && (
          <div className='w-full flex justify-end gap-2 mt-2.5'>
            <Button size='sm' variant='secondary' onClick={prevStep} className='w-28'>
              {t('repo.back')}
            </Button>
            <Button
              size='sm'
              variant='primary'
              type='submit'
              className='w-28'
              disabled={mutation.isLoading}
              loading={mutation.isLoading}
            >
              {t('repo.finish')}
            </Button>
          </div>
        )}
      </form>
    </MainContainer>
  )
}
