interface Reward {
  year: string
  grade1: number
  grade2: number
}

interface Table {
  categoryName: string
  cultivatedArea: string
  rewards: Reward[]
}

interface Section {
  title: string
  description: string
  tables: Table[]
}

interface RewardPackagesData {
  sections: Section[]
}

// The mmokoRewardPackagesData function with the defined return type
export const mmokoRewardPackagesData: (t: (key: string) => string) => RewardPackagesData = (t) => ({
  sections: [
    {
      title: t('types.small'),
      description: t('mrp.small'),
      tables: [
        {
          categoryName: `${t('types.small')} - ${t('mrp.level')} 1`,
          cultivatedArea: '1-8 hectares',
          rewards: [
            { year: '2024/25', grade1: 10000.0, grade2: 7500.0 },
            { year: '2025/26', grade1: 15000.0, grade2: 11250.0 },
            { year: '2026/27', grade1: 20000.0, grade2: 15000.0 },
            { year: '2027/28', grade1: 25000.0, grade2: 18750.0 },
            { year: '2028/29', grade1: 30000.0, grade2: 22500.0 }
          ]
        },
        {
          categoryName: `${t('types.small')} - ${t('mrp.level')} 2`,
          cultivatedArea: '1-16 hectares',
          rewards: [
            { year: '2024/25', grade1: 30000.0, grade2: 22500.0 },
            { year: '2025/26', grade1: 35000.0, grade2: 26250.0 },
            { year: '2026/27', grade1: 40000.0, grade2: 30000.0 },
            { year: '2027/28', grade1: 45000.0, grade2: 33750.0 },
            { year: '2028/29', grade1: 50000.0, grade2: 37500.0 }
          ]
        }
      ]
    },
    {
      title: t('types.med'),
      description: t('mrp.med'),
      tables: [
        {
          categoryName: `${t('types.med')} - ${t('mrp.level')} 1`,
          cultivatedArea: '16.1-50 hectares',
          rewards: [
            { year: '2024/25', grade1: 60000.0, grade2: 45000.0 },
            { year: '2025/26', grade1: 67500.0, grade2: 50625.0 },
            { year: '2026/27', grade1: 75000.0, grade2: 56250.0 },
            { year: '2027/28', grade1: 82500.0, grade2: 61875.0 },
            { year: '2028/29', grade1: 90000.0, grade2: 67500.0 }
          ]
        },
        {
          categoryName: `${t('types.med')} - ${t('mrp.level')} 2`,
          cultivatedArea: '50.1-100 hectares',
          rewards: [
            { year: '2024/25', grade1: 90000.0, grade2: 67500.0 },
            { year: '2025/26', grade1: 97500.0, grade2: 73125.0 },
            { year: '2026/27', grade1: 105000.0, grade2: 78750.0 },
            { year: '2027/28', grade1: 112500.0, grade2: 84375.0 },
            { year: '2028/29', grade1: 120000.0, grade2: 90000.0 }
          ]
        },
        {
          categoryName: `${t('types.med')} - ${t('mrp.level')} 3`,
          cultivatedArea: '100.1 - 150 hectares',
          rewards: [
            { year: '2024/25', grade1: 120000.0, grade2: 90000.0 },
            { year: '2025/26', grade1: 127500.0, grade2: 95250.0 },
            { year: '2026/27', grade1: 135000.0, grade2: 101250.0 },
            { year: '2027/28', grade1: 142500.0, grade2: 106875.0 },
            { year: '2028/29', grade1: 150000.0, grade2: 112500.0 }
          ]
        }
      ]
    },
    {
      title: t('types.large'),
      description: t('mrp.large'),
      tables: [
        {
          categoryName: `${t('types.large')} - ${t('mrp.level')} 1`,
          cultivatedArea: '150.1 - 250 Ha',
          rewards: [
            { year: '2024/25', grade1: 160000.0, grade2: 120000.0 },
            { year: '2025/26', grade1: 167500.0, grade2: 125625.0 },
            { year: '2026/27', grade1: 175000.0, grade2: 131250.0 },
            { year: '2027/28', grade1: 182500.0, grade2: 136875.0 },
            { year: '2028/29', grade1: 190000.0, grade2: 142500.0 }
          ]
        },
        {
          categoryName: `${t('types.large')} - ${t('mrp.level')} 2`,
          cultivatedArea: '250.1-350 hectares',
          rewards: [
            { year: '2024/25', grade1: 190000.0, grade2: 142500.0 },
            { year: '2025/26', grade1: 195000.0, grade2: 146250.0 },
            { year: '2026/27', grade1: 200000.0, grade2: 150000.0 },
            { year: '2027/28', grade1: 205000.0, grade2: 153750.0 },
            { year: '2028/29', grade1: 210000.0, grade2: 157500.0 }
          ]
        },
        {
          categoryName: `${t('types.large')} - ${t('mrp.level')} 3`,
          cultivatedArea: '350.1-500 hectares',
          rewards: [
            { year: '2024/25', grade1: 210000.0, grade2: 157500.0 },
            { year: '2025/26', grade1: 217500.0, grade2: 163125.0 },
            { year: '2026/27', grade1: 225000.0, grade2: 168750.0 },
            { year: '2027/28', grade1: 232500.0, grade2: 174375.0 },
            { year: '2028/29', grade1: 240000.0, grade2: 180000.0 }
          ]
        }
      ]
    }
  ]
})