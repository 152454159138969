import { type Implement, type PoweredImplement, type Tractor } from '../types'

interface InventoryData {
  inventory_type_id: number
  resource_item: number
}

export const findMatchingItem = (
  inventoryData: InventoryData | undefined,
  tractors: Tractor[],
  nonPoweredImplements: Implement[],
  poweredImplements: PoweredImplement[]
): Tractor | PoweredImplement | Implement | undefined => {
  switch (inventoryData?.inventory_type_id) {
    case 1003:
      return tractors?.find((item) => item.model_id === inventoryData.resource_item)
    case 1002:
      return nonPoweredImplements?.find((item) => item.model_id === inventoryData.resource_item)
    case 1001:
      return poweredImplements?.find((item) => item.model_id === inventoryData.resource_item)
    default:
      return undefined
  }
}
