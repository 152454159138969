import { Autocomplete, TextField } from '@mui/material'
import type React from 'react'
import { type Control, Controller } from 'react-hook-form'

interface SearchFieldProps {
  options: string[]
  label?: string
  name: string
  placeholder?: string
  control?: Control<any, any>
  icon?: React.ReactNode
  error: boolean
  helperText: string
  defaultValue: string
}

export const SearchField: React.FC<SearchFieldProps> = ({
  label,
  options,
  placeholder,
  control,
  error,
  helperText,
  name,
  defaultValue
}: SearchFieldProps) => {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { ref, onChange, ...field } }) => (
        <Autocomplete
          freeSolo
          sx={{
            fontFamily: 'var(--font-family)',
            fontSize: '0.9rem',
            fontWeight: '400',
            color: 'var(--foreground)',
            '&:hover': {
              backgroundColor: 'var(--secondary-disabled)',
              color: 'var(--foreground)'
            },
            '&.Mui-selected': {
              backgroundColor: 'var(--secondary)',
              color: 'var(--secondary-foreground)'
            },
            '&.Mui-focusVisible': {
              backgroundColor: 'var(--secondary)',
              color: 'var(--secondary-foreground)'
            }
          }}
          options={options}
          defaultValue={defaultValue}
          onChange={(_, data) => {
            onChange(data?.toUpperCase() ?? '')
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              {...field}
              label={label}
              variant='outlined'
              size={'small'}
              placeholder={placeholder}
              error={error}
              onChange={(event) => {
                onChange(event?.target?.value?.toUpperCase() ?? '')
              }}
              helperText={helperText}
              fullWidth
              sx={{
                '& .MuiInputBase-input': {
                  color: 'var(--foreground)',
                  fontFamily: 'var(--font-family)'
                },
                '& .MuiInputLabel-root': {
                  color: 'var(--foreground)',
                  fontFamily: 'var(--font-family)',
                  fontsize: '0.9rem'
                },
                '& .Mui-disabled .MuiInputLabel-root': {
                  color: 'var(--secondary-disabled)'
                },
                '& .Mui-error .MuiInputLabel-root': {
                  color: 'var(--destructive)'
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'var(--input)',
                    transition: 'border-color 0.2s'
                  },

                  '&.Mui-focused fieldset': {
                    borderColor: 'var(--primary)'
                  },
                  '&.Mui-hover fieldset': {
                    borderColor: 'var(--primary)'
                  },

                  '&.Mui-error fieldset': {
                    borderColor: 'var(--destructive)'
                  },

                  '&.Mui-disabled fieldset': {
                    borderColor: 'var(--secondary-disabled)',
                    hover: 'none'
                  },
                  '&:hover fieldset': {
                    borderColor: 'var(--primary)'
                  }
                }
              }}
            />
          )}
          ListboxProps={{
            style: {
              border: '1px solid var(--input)',
              borderRadius: '4px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
              backgroundColor: 'var(--card)',
              color: 'var(--foreground)',
              fontFamily: 'var(--font-family)',
              fontSize: '0.9rem',
              fontWeight: '400',
              width: '100%',
              maxHeight: '200px',
              overflow: 'auto'
            }
          }}
        />
      )}
    />
  )
}
