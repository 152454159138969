import type React from 'react'
import { Outlet } from 'react-router-dom'
import Session from 'supertokens-auth-react/recipe/session'
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles'
import Spinner from '../../../components/ui/spinner'
import { clearLoginAttemptInfo } from 'supertokens-web-js/lib/build/recipe/passwordless'
import { Footer, NavLandingPage } from '../components'
import { HeaderTemo } from '../components/SubComponents/Temo-CMS/HeaderTemoCMS'
import { WhyChooseTemo } from '../components/SubComponents/Temo-CMS/WhyChooseTemo'
import { PowerfulFeatures } from '../components/SubComponents/Temo-CMS/PowerfulFeatures'
import { TestimonialsFarmers } from '../components/SubComponents/Generic/Testimonials'
import { FooterCTA } from '../components/SubComponents/About/FooterCTA'
import FooterImage from 'assets/landing/swiper/20230119_6/20230119_2.jpg'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

export const TemoCms: React.FC = () => {
  const { t } = useTranslation('landing')
  const claimValue = Session.useClaimValue(UserRoleClaim)

  if (claimValue.loading) {
    return (
      <div className='flex items-center justify-center w-screen h-screen'>
        <Spinner />
      </div>
    )
  }

  const roles = claimValue.value

  const handleNavigateToDashboard = (): string => {
    if (Array.isArray(roles) && roles.includes('FARMER')) {
      return '/farmer'
    } else if (Array.isArray(roles) && roles.includes('EXTENSION_OFFICER')) {
      return '/officer'
    } else if (Array.isArray(roles) && roles.includes('SOIL_SAMPLE_LAB')) {
      return '/soil-lab'
    } else if (Array.isArray(roles) && roles.includes('MECHANISATION_OFFICER')) {
      return '/inventory'
    } else if (Array.isArray(roles) && roles.includes('SEED_DEALER')) {
      return '/seed-dealer'
    } else if (Array.isArray(roles) && roles.includes('SERVICE_PROVIDER')) {
      return '/service-provider'
    } else {
      void clearLoginAttemptInfo()
      return '/login/phone'
    }
  }

  return (
    <main className='landing-page'>
      {/*  SEO Optmization */}
      <Helmet>
        <title>Temo Letlotlo CMS - Empowering Farmers with Digital Tools</title>
        <meta
          name='description'
          content='Temo Letlotlo CMS provides Botswana farmers with digital tools to manage crops, apply for loans, and access key resources.'
        />
        <meta
          name='keywords'
          content='Temo Letlotlo CMS, Botswana farmer tools, digital farming solutions, agriculture management system'
        />

        {/* Open Graph / Facebook */}
        <meta property='og:type' content='website' />
        <meta
          property='og:title'
          content='Temo Letlotlo CMS - Empowering Farmers with Digital Tools'
        />
        <meta
          property='og:description'
          content='The Temo Letlotlo CMS is designed to support farmers by offering comprehensive tools for farm management and financial aid.'
        />
        <meta property='og:image' content='https://www.temoletlotlo.com/cms-image.jpg' />
        <meta property='og:url' content='https://www.temoletlotlo.com/temo-cms' />
      </Helmet>

      {/* Page Components */}
      <NavLandingPage
        goToDashboard={handleNavigateToDashboard}
        isAuthenticated={claimValue.value != null}
      />
      <HeaderTemo />
      <WhyChooseTemo />
      <PowerfulFeatures />
      <TestimonialsFarmers bgColor='bg-blue-50' />
      <FooterCTA
        backgroundImage={FooterImage}
        headerText={t('about.join')}
        buttonText={t('home.ref')}
        navigationLink='/registration'
      />
      <Outlet />
      <Footer />
    </main>
  )
}
