import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { type ApiResponse } from '../../../../types'
import { type InitiateTransferDTO, type InventoryTransfer } from '../types'
import { acceptTransfer, initateTransfer, verifyTransfer } from '../api/initiateTransfer'
import { AxiosError, type AxiosResponse } from 'axios'
import { mapCmsErrorToMessage } from '../../../../utils/apiErrors'
import { toast } from '../../../../components/ui'
import { useFarmerStore } from '../../../../stores/useFarmerStore'
import { useTransferStore } from '../stores/transferStore'
import { getFarmer } from '../api/getFarmer'
import { getMerchant } from '../api/getMerchant'
import { useMerchantStore } from '../../../../stores/useMerchantStore'

interface UseTranserInventory {
  isLoading: boolean
  initiateNewTransfer: (transfer: InitiateTransferDTO, next: () => void) => void
  acceptTransferRequest: (params: InitiateTransferDTO, next: () => void) => void
  verifyTransferRequest: (params: InitiateTransferDTO, next: () => void) => void
  rejectTransferRequest: (params: InitiateTransferDTO, next: () => void) => void
  searchUser: (user: string, userId: string, next: () => void) => Promise<void>
}

export const useTransferInventory = (transferRequest?: InventoryTransfer): UseTranserInventory => {
  const queryClient = useQueryClient()
  const { userId, setUserDetails, userType, userDetails, setUserId, setUserType } =
    useTransferStore()
  const [isLoading, setIsLoading] = useState(false)
  const { farmer } = useFarmerStore()
  const { merchant } = useMerchantStore()

  const createInventoryTransferMutation = useMutation({
    mutationFn: async (transfer: InitiateTransferDTO) => {
      return initateTransfer(transfer)
    }
  })

  const acceptInventoryTransferMutation = useMutation({
    mutationFn: async (transfer: InitiateTransferDTO) => {
      return acceptTransfer(transfer)
    }
  })

  const verifyInventoryTransferMutation = useMutation({
    mutationFn: async (transfer: InitiateTransferDTO) => {
      return verifyTransfer(transfer)
    }
  })

  const initiateNewTransfer = (params: InitiateTransferDTO, next: () => void): void => {
    const transferData: InitiateTransferDTO = {
      inventory_id: params.inventory_id,
      message: 'Transfer Initiated',
      transaction_status: 1,
      last_update_by: merchant != null ? merchant?.user_id : farmer?.user_id,
      ...(userType === 'farmer' &&
        userDetails != null && {
          dest_farmer_id: userId
        }),
      ...(farmer != null && { origin_farmer_id: Number(farmer.farmer_id) }),
      ...(userType === 'merchant' &&
        userDetails != null && {
          dest_merchant_id: userId
        }),
      ...(merchant != null && { origin_merchant_id: Number(merchant.merchant_id) })
    }

    createInventoryTransferMutation.mutate(transferData, {
      onSuccess: (resp) => {
        if (resp.status === 201) {
          toast({
            variant: 'success',
            title: 'Transfer Initiated.',
            description: 'Await Verification from your Mechanization Officer'
          })
          void queryClient.invalidateQueries({
            queryKey: [
              `inventories-${transferData?.origin_farmer_id ?? ''}`,
              `inventories-${transferData?.origin_merchant_id ?? ''}`,
              'transfer-request'
            ]
          })
          next()
        }
      },
      onError: (err) => {
        if (err instanceof AxiosError) {
          const error = err.response as AxiosResponse<ApiResponse<[]>>

          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: mapCmsErrorToMessage(
              error.status.toString() ?? err.code ?? error.data.message ?? ''
            )
          })
        }
      }
    })
  }

  const searchUser = async (user: string, id: string, next: () => void): Promise<void> => {
    setUserDetails(null)
    setIsLoading(true)

    try {
      let response
      if (user === 'farmer') {
        response = await getFarmer(id)
      } else if (user === 'merchant') {
        response = await getMerchant(id)
      }

      if (response?.status === 200) {
        setUserType(user)
        setUserId(Number(id))
        setUserDetails(response?.data[0])
        next()
      } else {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: response?.message
        })
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const errorMsg = err?.response as AxiosResponse<ApiResponse<[]>>
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: mapCmsErrorToMessage(
            errorMsg.status.toString() ?? err.code ?? errorMsg.data.message ?? ''
          )
        })
      } else {
        const errorMsg = err as Error
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: mapCmsErrorToMessage(errorMsg.message)
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const acceptTransferRequest = (params: InitiateTransferDTO, next: () => void): void => {
    acceptInventoryTransferMutation.mutate(params, {
      onSuccess: (resp) => {
        if (resp.status === 201) {
          next()
          toast({
            variant: 'success',
            title: 'Transfer Request Accepted'
          })
          void queryClient.invalidateQueries({
            queryKey: [
              `inventories-${params?.origin_farmer_id ?? params?.origin_merchant_id ?? ''}`,
              'transfer-request'
            ]
          })
        }
      },
      onError: (err) => {
        if (err instanceof AxiosError) {
          const error = err.response as AxiosResponse<ApiResponse<[]>>

          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: mapCmsErrorToMessage(
              error.status.toString() ?? err.code ?? error.data.message ?? ''
            )
          })
        }
      }
    })
  }

  const verifyTransferRequest = (params: InitiateTransferDTO, next: () => void): void => {
    verifyInventoryTransferMutation.mutate(params, {
      onSuccess: (resp) => {
        if (resp.status === 201) {
          toast({
            variant: 'success',
            title: 'Transfer Request Accepted',
            description:
              params.transaction_status === 4
                ? `Destination ${
                    params.dest_farmer_id != null ? 'Farmer' : 'Merchant'
                  } can approve transfer request.`
                : 'You have successfully rejected the transfer'
          })
          void queryClient.invalidateQueries({
            queryKey: [
              `inventories-${params?.origin_farmer_id ?? params?.origin_merchant_id ?? ''}`,
              'transfer-request'
            ]
          })
          next()
        }
      },
      onError: (err) => {
        if (err instanceof AxiosError) {
          const error = err.response as AxiosResponse<ApiResponse<[]>>

          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: mapCmsErrorToMessage(
              error.status.toString() ?? err.code ?? error.data.message ?? ''
            )
          })
        }
      }
    })
  }

  const rejectTransferRequest = (params: InitiateTransferDTO, next: () => void): void => {
    acceptInventoryTransferMutation.mutate(params, {
      onSuccess: (resp) => {
        if (resp.status === 201) {
          next()
          toast({
            variant: 'success',
            title: 'Transfer Request Rejected'
          })
          void queryClient.invalidateQueries({
            queryKey: [
              `inventories-${params?.origin_farmer_id ?? params?.origin_merchant_id ?? ''}`,
              'transfer-request',
              'mo-inventories'
            ]
          })
        }
      },
      onError: (err) => {
        if (err instanceof AxiosError) {
          const error = err.response as AxiosResponse<ApiResponse<[]>>

          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: mapCmsErrorToMessage(
              error.status.toString() ?? err.code ?? error.data.message ?? ''
            )
          })
        }
      }
    })
  }

  return {
    searchUser,
    verifyTransferRequest,
    isLoading:
      isLoading ||
      createInventoryTransferMutation.isLoading ||
      acceptInventoryTransferMutation.isLoading ||
      verifyInventoryTransferMutation.isLoading,
    initiateNewTransfer,
    acceptTransferRequest,
    rejectTransferRequest
  }
}
