import { useState } from 'react'
import { AxiosError, type AxiosResponse } from 'axios'
import type { ApiResponse, ApiResponseWithObject } from '../../../../types'
import { mapCmsErrorToMessage } from '../../../../utils/apiErrors'
import { getFarmerContact } from '../../api/getFarmerContact'
import { useSearchParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { redeemSeedsVoucher, type SeedsVoucherDTO } from '../api/redeemSeedsVoucher'
import { useMerchantStore } from '../../../../stores/useMerchantStore'
import type { SelectedSeeds } from '../types'
import { useToast } from 'components/ui'
import { type SuccessfulVoucherRedemption } from 'features/merchant/types'
import { useOTP } from '../../../../hooks/useOTP'

interface UseRedeemSeedsVoucher {
  isLoading: boolean
  isOtpSent: boolean
  isVoucherRedeemed: boolean
  contact: string | null
  voucher: SuccessfulVoucherRedemption | null
  getContact: () => Promise<void>
  redeemVoucher: (selectedSeeds: SelectedSeeds[]) => void
}

export const useRedeemSeedsVoucher = (): UseRedeemSeedsVoucher => {
  const [searchParams] = useSearchParams()
  const omang = searchParams.get('omang')
  const queryClient = useQueryClient()
  const { toast } = useToast()
  const { merchant } = useMerchantStore()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isVoucherRedeemed, setIsVoucherRedeemed] = useState<boolean>(false)
  const [voucher, setVoucher] = useState<SuccessfulVoucherRedemption | null>(null)
  const [contact, setContact] = useState<string | null>(null)
  const { sendOtpMessage, loading: isOtpLoading, isOtpSent } = useOTP()

  const redeemVoucherMutation = useMutation({
    mutationFn: async (payload: SeedsVoucherDTO) => {
      return redeemSeedsVoucher(payload)
    }
  })

  const getContact = async (): Promise<void> => {
    setIsLoading(true)
    setContact(null)
    try {
      const response = await getFarmerContact(omang)
      if (response.status === 200) {
        setContact(response.data.contact)
        sendOtpMessage(response.data.contact ?? '')
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const errorMsg = err?.response as AxiosResponse<ApiResponseWithObject<[]>>
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: mapCmsErrorToMessage(
            errorMsg.status.toString() ?? err.code ?? errorMsg.data.message ?? ''
          )
        })
      } else {
        const errorMsg = err as Error
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: mapCmsErrorToMessage(errorMsg.message)
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const redeemVoucher = (selectedSeeds: SelectedSeeds[]): void => {
    setIsVoucherRedeemed(false)

    redeemVoucherMutation.mutate(
      {
        merchant_id: merchant?.merchant_id,
        omang,
        seeds: selectedSeeds
      },

      {
        onSuccess: (response) => {
          if (response.status === 201) {
            setIsVoucherRedeemed(true)
            setVoucher(response.data)
            toast({
              variant: 'success',
              description: 'Voucher successfully redeemed!'
            })
            void queryClient.invalidateQueries({ queryKey: ['voucher-eligibility', omang] })
          }
        },
        onError: (err: unknown) => {
          if (err instanceof AxiosError) {
            const errorMsg = err.response as AxiosResponse<ApiResponse<[]>>
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: errorMsg.data.message
            })
          }
        }
      }
    )
  }
  return {
    isOtpSent: isOtpSent != null ? isOtpSent : false,
    isVoucherRedeemed,
    isLoading: isLoading || isOtpLoading || redeemVoucherMutation.isLoading,
    contact,
    voucher,
    getContact,
    redeemVoucher
  }
}
