import type { ColumnDef } from '@tanstack/react-table'
import type { Seeds } from 'types/static'
import { Checkbox } from 'components/ui/checkbox'
import { DataTableColumnHeader } from 'components/Data-Table/datatable-header'

export const CroppingPlanSeedsColumns = (t: (key: string) => string): Array<ColumnDef<Seeds>> => {
  return [
    {
      id: 'select',
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllRowsSelected() || (table.getIsSomeRowsSelected() && 'indeterminate')
          }
          onCheckedChange={(value) => {
            if (table.getTotalSize() === 1) {
              table.toggleAllRowsSelected(!!value)
            } else {
              table.toggleAllRowsSelected(false)
            }
          }}
          type={'button'}
          aria-label='Select row'
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => {
            row.toggleSelected(!!value)
          }}
          type={'button'}
          aria-label='Select row'
        />
      ),
      enableSorting: false,
      enableHiding: false
    },
    {
      id: 'trade name',
      accessorFn: (row) => row.trade_name,
      filterFn: 'includesString',
      enableHiding: true,
      enableColumnFilter: true,
      cell: ({ row }) => <div className='uppercase'>{row.getValue('trade name')}</div>,
      header: ({ column }) => <DataTableColumnHeader column={column} title={t('seed.name')} />
    },
    {
      id: 'variety',
      accessorFn: (row) => row.variety,
      filterFn: 'includesString',
      enableHiding: true,
      enableColumnFilter: true,
      cell: ({ row }) => <div className='uppercase'>{row.getValue('variety')}</div>,
      header: ({ column }) => <DataTableColumnHeader column={column} title={t('seed.variety')} />
    },
    {
      id: 'sowing rate',
      accessorFn: (row) => row.sowing_rate,
      filterFn: 'includesString',
      enableHiding: true,
      cell: ({ row }) => <div className='uppercase'>{row.getValue('sowing rate')}</div>,
      header: `${t('seed.sow')} (kg/h)`
    },
    {
      id: 'yield',
      accessorFn: (row) => row.yield,
      filterFn: 'includesString',
      enableHiding: true,
      cell: ({ row }) => <div className='uppercase'>{row.getValue('yield')}</div>,
      header: `${t('seed.yield')} (ton/ha)`
    },
    {
      id: 'days to maturity',
      accessorFn: (row) => row.days_to_maturity,
      filterFn: 'includesString',
      enableHiding: true,
      cell: ({ row }) => <div className='uppercase'>{row.getValue('days to maturity')}</div>,
      header: `${t('seed.days')}`
    },
    {
      id: 'price',
      accessorFn: (row) => row.price_per_kg,
      filterFn: 'includesString',
      enableHiding: false,
      cell: ({ row }) => {
        const amount = parseFloat(row.getValue('price'))
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'BWP'
        }).format(amount)

        return <div className='font-medium uppercase'>{formatted}</div>
      },
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={`${t('seed.price')}' P/Kg`} />
      )
    }
  ]
}
