import type React from 'react'
import { useState } from 'react'
import { type Farm } from 'types'
import { Button, Dialog, DialogContent } from 'components/ui'
import { LeaseFarm } from '../new-lease/LeaseFarm'
import { useTranslation } from 'react-i18next'

interface ManageLeaseProps {
  farm: Farm
}

export const ManageLease: React.FC<ManageLeaseProps> = ({ farm }) => {
  const { t } = useTranslation('landManagement')
  const [open, setOpen] = useState(false)

  const openDialog = (): void => {
    setOpen(true)
  }

  const closeDialog = (): void => {
    setOpen(false)
  }

  return (
    <>
      <Button
        variant={'success'}
        size={'xs'}
        onClick={openDialog}
        disabled={farm.leased_size === farm.size}
      >
        {t('lease.lease')}
        <p className='fa-solid fa-right-left' />
      </Button>

      <Dialog
        open={open}
        defaultOpen={false}
        modal
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            return
          }
          setOpen(true)
        }}
      >
        <DialogContent
          className={
            'bg-card w-[90vw] md:w-[50%] lg:w-[35%] max-w-[430px] max-h-[80vh] overflow-y-auto'
          }
        >
          <LeaseFarm farm={farm} onClose={closeDialog} />
        </DialogContent>
      </Dialog>
    </>
  )
}
