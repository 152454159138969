import type React from 'react'
import { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '../../../../../components/ui'
import { type TemoService, temoServices } from './Data/ServicesData'
import { CTAButton } from '../Generic/CTAButton'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

const SingleService: React.FC = () => {
  const [selectedService, setSelectedService] = useState<TemoService | null>(null)
  const { t } = useTranslation('landing')

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 md:px-32 lg:px-48 gap-6'>
      {temoServices(t).map((service) => (
        <div key={service.id} className='group'>
          <motion.div
            className={`${service.backgroundColor} w-full h-full cursor-pointer rounded-xl flex flex-col space-y-16 p-8 lg:p-16 group-hover:bg-gradient-to-r group-hover:from-${service.gradientFrom} group-hover:to-${service.gradientTo}`}
            onClick={() => {
              setSelectedService(service)
            }}
            whileHover={{ scale: 1.02 }}
          >
            <h4
              className={`${service.fontColor} text-2xl md:text-3xl lg:text-4xl text-center font-bold landing-page text-wrap`}
            >
              {service.title}
            </h4>
            <div className={`${service.fontColor} text-sm font-light text-center`}>
              {t('home.click')}
            </div>
          </motion.div>

          <Dialog
            open={selectedService?.id === service.id}
            onOpenChange={() => {
              setSelectedService(null)
            }}
          >
            <DialogContent
              className={`${service.backgroundColor} w-full max-w-xs md:max-w-2xl lg:max-w-6xl p-4 md:p-8 lg:p-12`}
            >
              <DialogHeader className='flex flex-col space-y-12'>
                <DialogTitle>
                  <h4
                    className={`${service.fontColor} text-2xl md:text-3xl lg:text-4xl text-center font-bold landing-page`}
                  >
                    {service.title}
                  </h4>
                </DialogTitle>
                <DialogDescription>
                  <p
                    className={`${service.fontColor} text-sm md:text-base lg:text-lg text-center font-regular h-60 md:h-80 landing-page`}
                  >
                    {service.description}
                  </p>

                  <div className='flex items-center justify-center'>
                    <CTAButton label={t('home.explore2')} to='about-temo-letlotlo' />
                  </div>
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
        </div>
      ))}
    </div>
  )
}

export default SingleService