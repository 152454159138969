// CropInsurancePackageData.ts

/**
 * Interface representing the structure of coverage details.
 */
interface Coverage {
  title: string
  description: string
}

/**
 * Interface representing the structure of a benefit.
 */
interface Benefit {
  title: string
  description: string
}

/**
 * Interface representing the structure of the program packages.
 */
interface ProgramPackages {
  programName: string
  coverage: Coverage[]
  farmEquipmentInsurance: string
  benefits: Benefit[]
  eligibility: string
}

/**
 * Constant containing the details of the crop insurance package.
 */
const programPackages = (t: (key: string) => string): ProgramPackages => {
  return {
    programName: t('ci.temo'),

    coverage: [
      {
        title: t('ci.drought'),
        description: t('ci.droughtD')
      },
      {
        title: t('ci.er'),
        description: t('ci.erD')
      },
      {
        title: t('ci.flood'),
        description: t('ci.floodD')
      },
      {
        title: t('ci.heat'),
        description: t('ci.heatD')
      },
      {
        title: t('ci.pest'),
        description: t('ci.pestD')
      }
    ],

    farmEquipmentInsurance: t('ci.farm'),

    benefits: [
      {
        title: t('ci.bt1'),
        description: t('ci.bd1')
      },
      {
        title: t('ci.bt2'),
        description: t('ci.bd2')
      },
      {
        title: t('ci.bt3'),
        description: t('ci.bd3')
      }
    ],

    eligibility: t('ci.eli')
  }
}

export default programPackages