import React from 'react'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from 'components/ui'
import { type ServiceTransactionDetails } from '../../types'
import { useTranslation } from 'react-i18next'
import { useStaticDataStore } from '../../../../../stores/useStaticDataStore'

interface ServicesTableProps {
  clickedServices: ServiceTransactionDetails[]
}

export const ServicesTable: React.FC<ServicesTableProps> = ({ clickedServices }) => {
  const { services } = useStaticDataStore()
  const { t } = useTranslation('merchant')
  const getService = (serviceId: number): string => {
    const findService = services?.find((service) => service.service_details_id === serviceId)
    return findService != null ? findService.service_name : ''
  }

  return (
    <Table>
      <TableCaption>Transaction Details.</TableCaption>
      <TableHeader>
        <TableRow>
          <TableHead className='font-bold text-color-text'>{t('services.service')}</TableHead>
          <TableHead className='font-bold text-color-text'>{t('services.quan')}</TableHead>
          <TableHead className='font-bold text-color-text'>{t('services.price')}</TableHead>
          <TableHead className='font-bold text-color-text'>{t('services.cost')}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {clickedServices.map((service) => (
          <TableRow key={React.useId()} role={'listitem'}>
            <TableCell className='font-medium'>{getService(service.id)}</TableCell>
            <TableCell className='font-medium'>{service.qty}</TableCell>
            <TableCell className='font-medium'>BWP {service.price.toFixed(2)}</TableCell>
            <TableCell className='font-medium'>BWP {service.total.toFixed(2)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
