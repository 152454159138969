import type React from 'react'
import { type SoilSampleRequest } from 'types'
import { Text } from 'components/Elements/Text'
import { useTranslation } from 'react-i18next'

interface SSRVerificationStatusProps {
  ssr: SoilSampleRequest
  className?: string
}

export const SSRVerificationStatus: React.FC<SSRVerificationStatusProps> = ({ ssr, className }) => {
  const { t } = useTranslation('landManagement')

  if (ssr.status_name === 'COMPLETED') {
    return (
      <Text variant={'success'} className={className}>
        <p className='fa-solid fa-circle-check' /> {t('ssa.comp')}
      </Text>
    )
  }
  if (ssr.status_name === 'REJECTED') {
    return (
      <Text variant={'error'} className={className}>
        <p className='fa-solid fa-circle-check' /> {t('ssa.rej')}
      </Text>
    )
  }

  if (ssr.status_name === 'IN PROCESS') {
    return (
      <Text variant={'warning'} className={className}>
        <p className='material-symbols-outlined'>pending_actions</p>
        <p className={'md:hidden'}>{t('ssa.ass')}</p>
        <p className={'hidden md:flex'}>{t('ssa.aass')}</p>
      </Text>
    )
  }

  return (
    <Text variant={'primary'} className={className}>
      <p className='material-symbols-outlined'>pending_actions</p>
      <p className={'md:hidden'}>{t('ssa.rev')}</p>
      <p className={'hidden md:flex'}>{t('ssa.sRev')}</p>
    </Text>
  )
}