import type React from 'react'
import { Text } from '../ui'
import type { FeatureBaseProps, UserContext } from 'supertokens-auth-react/lib/build/types'

export const ChangePasswordNotFound = (
  prop?: FeatureBaseProps<{
    userContext?: UserContext
  }>
): React.ReactElement => {
  return (
    <main className={'h-screen w-full grid place-items-center'}>
      <div className={'flex flex-col justify-center items-center gap-4'}>
        <Text className={'text-center'} size={'large'} variant={'error'}>
          Page Not Found
        </Text>
        <Text className={'text-center'} size={'large'} variant={'bodyTextLight'}>
          Sorry the page you are looking for cannot be found.
        </Text>
      </div>
    </main>
  )
}
