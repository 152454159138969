import type React from 'react'
import { Card, Text } from '../../../../components/ui'
import { Loader } from '../../../../components/ui/loader'
import { ErrorPage } from '../../../../components/Errors/ErrorPage'
import { useMerchantStore } from '../../../../stores/useMerchantStore'
import { useGetSeedsReports } from '../api/seedReports'
import { SeedsReportsTable } from '../components/report/SeedsReportsTable'
import { reportsColumns } from '../../components/report/columns'
import { NoTransactions } from '../../components/NoTransactions'
import { useTranslation } from 'react-i18next'

export const SeedsReports: React.FC = () => {
  const { merchant } = useMerchantStore()
  const { t } = useTranslation('merchant')
  const { data: reports, isLoading, error } = useGetSeedsReports(merchant?.merchant_id)

  if (isLoading) {
    return (
      <div className={'grid place-items-center min-h-[30%] h-full flex-1'}>
        <Loader />
      </div>
    )
  }

  if (error != null) {
    if (error?.response?.status === 404) {
      return <NoTransactions />
    } else {
      return <ErrorPage />
    }
  }

  const transactions = reports?.data.filter((report) => report.seed_list.length >= 1)

  if (transactions.length === 0) {
    return <NoTransactions />
  }

  return (
    <div className={'max-w-[1700px] w-full flex-col gap-4  flex justify-center mx-auto px-0 py-4'}>
      <Text size={'large'}>{t('seed.tr')}</Text>
      <Card
        className={
          'w-full flex flex-col bg-card gap-4 md:gap-6 lg:gap-8 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
        }
      >
        <Text size={'medium'} variant={'bodyTextLight'}>
          {t('seed.trList')}
        </Text>
        <SeedsReportsTable columns={reportsColumns(t)} data={transactions} />
      </Card>
    </div>
  )
}
