// Define an interface for the structure
interface Statement {
  text: string
}

// Create the array of statements
const statements = (t: (key: string) => string): Statement[] => [
  {
    text: t('about.miss1')
  },
  {
    text: t('about.miss2')
  },
  {
    text: t('about.miss3')
  }
]

// Export the statements array
export default statements