/* eslint-disable */
import type React from 'react'
import { useState } from 'react'
import { type Lease } from 'types'
import { ProvidedInformation } from './accept-lease/ProvidedInformation'
import { LeaseInformation } from './LeaseInformation'

interface AcceptLeaseProps {
  lease: Lease
  className: string
  onClose: () => void
  onStatusChange: (leaseId: number) => void
}

export const AcceptLease: React.FC<AcceptLeaseProps> = ({
  lease,
  className,
  onClose,
  onStatusChange
}) => {
  const [showAcceptLease, setShowAcceptLease] = useState(false)
  const handleLeaseApprovalOrRejection = (): void => {
    onStatusChange(lease.lease_id)
    onClose()
  }

  if (showAcceptLease) {
    return (
      <div className={className}>
        <ProvidedInformation
          lease={lease}
          onApprove={handleLeaseApprovalOrRejection}
          onCancel={onClose}
        />
      </div>
    )
  }

  // if (showRejectLease) {
  //   return (
  //     <div className={className}>
  //       <RejectReasonForm
  //         lease={lease}
  //         onReject={() => {
  //           onStatusChange(lease.lease_id), onClose()
  //         }}
  //         onCancel={onClose}
  //       />
  //     </div>
  //   )
  // }

  return (
    <div className={className}>
      <LeaseInformation
        lease={lease}
        onApprove={() => {
          setShowAcceptLease(true)
        }}
      />
    </div>
  )
}
