import React, { type FC, useEffect } from 'react'
import { type Farm, type Lease } from 'types'
import { SoilCorrection } from '../../../soil-correction/routes/SoilCorrection'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { ComingSoon } from '../../../../../components/Layout/ComingSoon'

interface WithFarm {
  farm: Farm
  lease?: never
}

interface WithLease {
  farm?: never
  lease: Lease
}

type SoilCorrectionTabProps = WithFarm | WithLease
export const SoilCorrectionTab: FC<SoilCorrectionTabProps> = ({ farm, lease }) => {
  const flags = useFlags()
  const ldClient = useLDClient()

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  if (flags.soilCorrection === false) {
    return <ComingSoon />
  }

  if (farm != null) {
    return (
      <>
        <SoilCorrection farm={farm} />
      </>
    )
  }

  return (
    <>
      <SoilCorrection lease={lease} />
    </>
  )
}
