import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from 'components/ui'
import { type ChemicalList } from '../../types'
import type React from 'react'
import { useStaticDataStore } from '../../../../../stores/useStaticDataStore'

interface ChemicalsTableReportProps {
  transactions: ChemicalList[]
}

export const ChemicalsTableReport = ({
  transactions
}: ChemicalsTableReportProps): React.ReactNode => {
  const { pesticides, fungicides, herbicides, adjuvants } = useStaticDataStore()

  const findChemicalName = (type: string | undefined, id: number): string => {
    switch (type) {
      case 'Pesticide':
        return (
          pesticides?.find((item) => item.pesticides_price_detail_id === id)?.pesticide_name ??
          'Unknown Pesticide'
        )
      case 'Fungicide':
        return (
          fungicides?.find((item) => item.fungicide_price_detail_id === id)?.fungicide_name ??
          'Unknown Fungicide'
        )
      case 'Herbicide':
        return (
          herbicides?.find((item) => item.herbicide_price_detail_id === id)?.herbicide_name ??
          'Unknown Herbicide'
        )
      case 'Adjuvant':
        return (
          adjuvants?.find((item) => item.adjuvant_price_detail_id === id)?.adjuvant_name ??
          'Unknown Adjuvant'
        )
      default:
        return 'Unknown'
    }
  }

  return (
    <Table>
      <TableCaption>Transaction Details</TableCaption>
      <TableHeader>
        <TableRow>
          <TableHead>Name</TableHead>
          <TableHead>Type</TableHead>

          <TableHead>Quantity</TableHead>
          <TableHead>Price</TableHead>
          <TableHead>Total</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {transactions.map((chemical) => (
          <TableRow key={chemical.id}>
            <TableCell>{findChemicalName(chemical.type, chemical.id)}</TableCell>
            <TableCell>{chemical.type}</TableCell>

            <TableCell>{chemical.qty}</TableCell>
            <TableCell>BWP {chemical.price.toFixed(2)}</TableCell>
            <TableCell>BWP {chemical.total.toFixed(2)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
