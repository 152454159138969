import type React from 'react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import z, { coerce, object, string } from 'zod'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../../../../components/ui'
import { useFarmerStore } from 'stores/useFarmerStore'
import { uniqBy } from 'lodash'
import { YearPicker } from '../../../../components/Form/YearPicker'
import { Box, TabView } from '../../../../components/App/Layout'
import { useNavigate } from 'react-router-dom'
import { type GetInventoryRes, type Tractor } from '../types'
import dayjs from 'dayjs'
import { useInventoryItem } from '../../../merchant/service-provider/inventory/hooks/useInventoryItem'
import { type VerifiedFarm } from '../util'
import { isLease } from '../../../officer/farmers/utils/funcitons'
import { useOfficerStore } from '../../../../stores/useOfficerStore'
import { type Farm, type Lease } from '../../../../types'
import { useTranslation } from 'react-i18next'
import { useAuthenticationStore } from '../../../../stores/useAuthenticationStore'
import { useStaticDataStore } from '../../../../stores/useStaticDataStore'

const registrationNumberRegex = /^B \d{3} [A-Z]{3}$/

const schemaTractor = object({
  yom: coerce.date({
    required_error: 'Field is required',
    invalid_type_error: 'Invalid Date'
  }),
  model: coerce.number().min(1, 'Field is required'),
  farm: coerce.number().min(1, 'Field is required'),
  make: coerce.number().min(1, 'Field is required'),
  horsepower: coerce.number().min(1, 'Field is required').optional(),
  drive: string().optional().or(z.literal('')).default(''),
  reg_number: string().optional(),
  chassis_number: string().min(1, 'Chassis number is required'), // Chassis number always required
  tractor_type: string().min(1, 'Field is required')
}).superRefine((data, ctx) => {
  // For "CONVENTIONAL" tractors, both reg_number and chassis_number are required
  if (data.tractor_type === 'CONVENTIONAL') {
    if (data.reg_number == null) {
      ctx.addIssue({
        code: 'custom',
        path: ['reg_number'],
        message: 'Registration number is required for conventional tractors'
      })
    } else if (!registrationNumberRegex.test(data.reg_number)) {
      // Validate registration number format for conventional tractors
      ctx.addIssue({
        code: 'custom',
        path: ['reg_number'],
        message: 'Registration number must be in the format "B 123 CDE"'
      })
    }
  }
})

type TractorInput = z.infer<typeof schemaTractor>

interface RegisterTractorsProps {
  inventoryData?: GetInventoryRes
  inventoryItem?: Tractor | undefined
  isView?: boolean
  isMerchant?: boolean
  farmsList: VerifiedFarm[]
}

export const RegisterTractors: React.FC<RegisterTractorsProps> = ({
  inventoryData,
  inventoryItem,
  isView,
  isMerchant,
  farmsList
}) => {
  const navigate = useNavigate()
  const { farmer } = useFarmerStore()
  const { role } = useAuthenticationStore()
  const { tractors } = useStaticDataStore()

  const { createItem, updateItem, isLoading } = useInventoryItem()
  const { officer } = useOfficerStore()
  const [tractorType, setTractorType] = useState<string>(
    inventoryItem?.tractor_type?.toString() ?? ''
  )
  const [selectedMake, setSelectedMake] = useState<string | number>(
    inventoryItem?.make_id.toString() ?? ''
  )
  const [selectedModel, setSelectedModel] = useState<string | number>(
    inventoryItem?.model_id.toString() ?? ''
  )
  const [selectedFarm, setSelectedFarm] = useState<string | number>(
    inventoryData?.farm_id ?? inventoryData?.lease_id ?? ''
  )
  const [isFormChanged, setIsFormChanged] = useState(false)
  const [selectedItem, setSelectedItem] = useState<Tractor | undefined>()
  const [currentSelectedFarm, setCurrentSelectedFarm] = useState<Farm | Lease | undefined>()
  const { t } = useTranslation('inventory')

  const form = useForm<TractorInput>({
    resolver: zodResolver(schemaTractor),
    defaultValues: {
      model: Number(selectedModel) ?? Number(inventoryItem?.model_id) ?? '',
      make: Number(selectedMake) ?? Number(inventoryItem?.make_id) ?? '',
      horsepower: inventoryItem?.tractor_power_hp,
      drive: inventoryItem?.drive,
      reg_number: inventoryData?.reg_number ?? '',
      tractor_type: tractorType ?? inventoryItem?.tractor_type ?? '',
      chassis_number: inventoryData?.chassis_number ?? '',
      farm:
        Number(selectedFarm) ??
        Number(inventoryData?.farm_id) ??
        Number(inventoryData?.lease_id) ??
        ''
    }
  })

  const findSelectedItem = (): Tractor | undefined =>
    tractors?.find(
      (item) =>
        item.make_id.toString() === selectedMake &&
        item.model_id.toString() === selectedModel &&
        item?.tractor_type?.toString() === tractorType
    )

  const findSelectedFarm = (): Farm | Lease | undefined =>
    farmsList?.find(
      (item) =>
        item.farm_id?.toString() === selectedFarm?.toString() ||
        (item as Lease).lease_id?.toString() === selectedFarm?.toString()
    )

  useEffect(() => {
    if (!isFormChanged) {
      setSelectedMake(inventoryItem?.make_id.toString() ?? '')
      setSelectedModel(inventoryItem?.model_id.toString() ?? '')
      setSelectedFarm(inventoryData?.farm_id ?? inventoryData?.lease_id ?? '')
    }

    form.setValue('make', Number(selectedMake))
    form.setValue('model', Number(selectedModel))
    form.setValue('farm', Number(selectedFarm))
    setSelectedItem(findSelectedItem())
    setCurrentSelectedFarm(findSelectedFarm())
  }, [inventoryData, inventoryItem, selectedMake, selectedModel, selectedFarm, form, isFormChanged])

  useEffect(() => {
    const subscription = form.watch(() => {
      setIsFormChanged(true)
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [form])
  const types = uniqBy(tractors, (item) => {
    return item?.tractor_type?.toString() ?? ''
  })
  const makes = uniqBy(tractors, (item) => item.make_id)
    .filter((make) => make.tractor_type === tractorType && make.IsValid === true)
    .sort((a, b) => a.make.localeCompare(b.make))

  const models = uniqBy(tractors, (item) => {
    return item.model_id
  })
    .filter((sub) => sub.make_id.toString() === selectedMake.toString())
    .sort((a, b) => a.model.localeCompare(b.model))

  const onSubmit = (data: TractorInput): void => {
    const farmOrLeaseId = isLease(currentSelectedFarm)
      ? { lease_id: currentSelectedFarm?.lease_id }
      : { farm_id: currentSelectedFarm?.farm_id }

    const payload = {
      farmer_id: farmer?.farmer_id,
      yom: dayjs(String(data.yom)).format('YYYY'),

      resource_item: Number(selectedItem?.model_id),
      ...(data.tractor_type === 'CONVENTIONAL'
        ? {
            chassis_number: data.chassis_number,
            reg_number: data.reg_number
          }
        : { chassis_number: data.chassis_number }),

      inventory_item_verification_status_id: 'PENDING_VERIFICATION',
      last_updated_by: Number(farmer?.user_id),
      inventory_type_id: 1003,
      tractor_type: data.tractor_type,
      ...farmOrLeaseId
    }

    if (inventoryData != null) {
      updateItem({ ...payload, inventory_id: inventoryData.inventory_id })
    } else {
      createItem(payload)
    }
  }
  return (
    <Box>
      <TabView className={'max-w-[900px]'}>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className={'flex flex-col gap-8'}>
            <div className='grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] items-start gap-4 '>
              <FormField
                control={form.control}
                name='tractor_type'
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={(val) => {
                        setTractorType(val)
                        setSelectedMake('')
                        setSelectedModel('')
                        field.onChange(val)
                      }}
                      value={tractorType?.toString()}
                      disabled={isView === true || isMerchant}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={`${t('tractor.tractor_type')}`} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {types.map((disc) => (
                          <SelectItem
                            key={disc.tractor_type}
                            value={disc?.tractor_type?.toString() ?? ''}
                          >
                            {disc.tractor_type}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {form.watch('tractor_type') === 'CONVENTIONAL' && (
                <FormField
                  control={form.control}
                  name='reg_number'
                  render={({ field }) => (
                    <FormItem className='flex flex-col'>
                      <FormControl>
                        <Input
                          type='text'
                          {...field}
                          placeholder={`${t('tractor.reg')}`}
                          classLabel={'bg-card'}
                          label={`${t('tractor.reg')}`}
                          className={'mt-0'}
                          disabled={isView === true || isMerchant}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              {/* TODO Plug it for functionality */}
              <FormField
                control={form.control}
                name='chassis_number'
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormControl>
                      <Input
                        type='text'
                        {...field}
                        placeholder={`${t('tractor.cha')}`}
                        classLabel={'bg-card'}
                        label={`${t('tractor.cha')}`}
                        className={'mt-0'}
                        disabled={isView === true || isMerchant}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className={'w-full mt-2 h-full'}>
                <YearPicker
                  error={!(form.formState.errors.yom == null)}
                  helperText={form.formState.errors?.yom?.message ?? ''}
                  label={`${t('tractor.year')}`}
                  name={'yom'}
                  className={'w-full'}
                  control={form.control}
                  defaultValue={inventoryData !== null ? dayjs(inventoryData?.yom) : undefined}
                  disabled={isView === true || isMerchant}
                />
              </div>

              <FormField
                control={form.control}
                name='make'
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={(val) => {
                        setSelectedMake(val)
                        setSelectedModel('')
                        field.onChange(val)
                      }}
                      value={selectedMake?.toString()}
                      disabled={isView === true || tractorType === '' || isMerchant}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={`${t('tractor.make')}`} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {makes.map((disc) => (
                          <SelectItem key={disc.make_id} value={disc.make_id.toString()}>
                            {disc.make}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name='model'
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={(val) => {
                        setSelectedModel(val)
                        field.onChange(val)
                      }}
                      value={selectedModel?.toString()}
                      disabled={selectedMake === '' || isView === true || isMerchant}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={`${t('tractor.model')}`} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {models?.map((disc) => (
                          <SelectItem key={disc.model_id} value={disc.model_id.toString()}>
                            {disc.model}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name='horsepower'
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormControl>
                      <Input
                        type='text'
                        {...field}
                        label={`${t('tractor.hp')}`}
                        classLabel={'bg-card'}
                        placeholder={'123 hp'}
                        value={
                          selectedItem != null ? selectedItem?.tractor_power_hp?.toString() : 'N/A'
                        }
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name='drive'
                render={({ field }) => (
                  <FormItem>
                    <Input
                      type='text'
                      {...field}
                      label={`${t('tractor.drive')}`}
                      classLabel={'bg-card'}
                      placeholder={'e.g 4wd'}
                      value={selectedItem != null ? selectedItem.drive?.toString() : 'N/A'}
                      disabled
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name='farm'
                render={({ field }) => (
                  <FormItem>
                    {officer != null ? (
                      <Input
                        type='text'
                        label={`${t('tractor.farm')}`}
                        classLabel={'bg-card'}
                        placeholder={`${t('tractor.farm')}`}
                        value={inventoryData?.farm_name ?? ''}
                        disabled
                      />
                    ) : (
                      <Select
                        onValueChange={(val) => {
                          field.onChange(val)
                          setSelectedFarm(val)
                        }}
                        value={selectedFarm?.toString()}
                        disabled={isView === true || isMerchant === true}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder={`${t('tractor.selectFarm')}`} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {farmsList?.map((disc) => (
                            <SelectItem key={disc.farm_id} value={disc.farm_id.toString()}>
                              {disc.farm_name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    )}

                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className='flex w-full justify-between space-x-4'>
              <Button
                className='w-full sm:w-[18rem]'
                variant='secondary'
                onClick={() => {
                  navigate(-1)
                }}
              >
                {t('tractor.cancel')}
              </Button>
              {isView === true &&
                isMerchant === false &&
                inventoryData?.item_verification_status !== 'PENDING_VERIFICATION' && (
                  <Button
                    className='w-full sm:w-[18rem]'
                    onClick={() => {
                      navigate(
                        `${role === 1001 ? '/officer' : '/farmer'}/inventory/assessment/${Number(
                          inventoryData?.inventory_id
                        )}`
                      )
                    }}
                  >
                    {t('tractor.view')}
                  </Button>
                )}
              {isView === false && isMerchant === false && (
                <Button
                  className='w-full sm:w-[18rem]'
                  type='submit'
                  disabled={isLoading || (inventoryData != null && !isFormChanged)}
                  loading={isLoading}
                >
                  {inventoryData != null ? `${t('tractor.update')}` : `${t('tractor.submit')}`}
                </Button>
              )}
              {isMerchant === true && (
                <Button
                  className='w-full sm:w-[18rem]'
                  onClick={() => {
                    navigate(`/inventory/assessment/${Number(inventoryData?.inventory_id)}`)
                  }}
                >
                  {inventoryData?.item_verification_status === 'PENDING_VERIFICATION'
                    ? `${t('tractor.new')}`
                    : `${t('tractor.view')}`}
                </Button>
              )}
            </div>
          </form>
        </Form>
      </TabView>
    </Box>
  )
}
