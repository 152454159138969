import { CustomAccordion } from 'features/loan-management/components/stepper/index'
import type React from 'react'
import { object, string, type TypeOf } from 'zod'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useAtom, useAtomValue } from 'jotai'
import { farmerAtom } from 'stores/userStore'
import { Text } from '../../../../../components/Elements/Text/Text'
import { companyLoanStore } from '../stores'
import { type LoanStepperProps } from '../../../types'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  useStepper
} from '../../../../../components/ui'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '../../../../../components/App/Layout'
import { Info } from 'lucide-react'
import { LoanStepperFooter } from '../../generic/LoanStepperFooter'

const schema = object({
  background_info: string().min(1, 'Field is required'),
  process_info: string().min(1, 'Field is required'),
  key_assum: string().min(1, 'Field is required'),
  market_info: string().min(1, 'Field is required'),
  competitors: string().min(1, 'Field is required'),
  distribution: string().min(1, 'Field is required')
})

type SummarizedBusinessInformationCompanyDetailsInput = TypeOf<typeof schema>

export const SummarizedBusinessInformationCompany: React.FC<LoanStepperProps> = ({
  onComplete
}) => {
  const [loanData, setLoanData] = useAtom(companyLoanStore)
  const { nextStep } = useStepper()
  const farmer = useAtomValue(farmerAtom)
  const { t } = useTranslation('loanManagement')

  const form = useForm<SummarizedBusinessInformationCompanyDetailsInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      background_info: loanData?.summarized_business_information?.background_info,
      process_info: loanData?.summarized_business_information?.process_info,
      key_assum: loanData?.summarized_business_information?.key_assum,
      competitors: loanData?.summarized_business_information?.competitors,
      distribution: loanData?.summarized_business_information?.distribution,
      market_info: loanData?.summarized_business_information?.market_info
    }
  })

  const onSubmit: SubmitHandler<SummarizedBusinessInformationCompanyDetailsInput> = (
    data: SummarizedBusinessInformationCompanyDetailsInput
  ) => {
    setLoanData((prev) => ({
      ...prev!,
      summarized_business_information: {
        farmer_id: farmer?.farmer_id as number,
        background_info: data.background_info,
        process_info: data.process_info,
        key_assum: data.key_assum,
        market_info: data.market_info,
        competitors: data.competitors,
        distribution: data.distribution,
        last_update_by: farmer?.user_id as number
      }
    }))
    nextStep()
    if (onComplete != null) {
      onComplete(true)
    }
  }
  return (
    <Box>
      <Form {...form}>
        <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
          <div className='flex flex-col space-y-8 my-6 w-full'>
            <div className={'w-full flex flex-col gap-4'}>
              <div className='flex flex-col gap-3'>
                <Text size='medium' className=''>
                  {t('summ.summ')}
                </Text>
                <div className='flex items-center'>
                  <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
                  <Text variant='secondary' size='small' className=''>
                    Summarize your business by providing background information, production process,
                    key assumptions, market details, competitors, and distribution channels.
                  </Text>
                </div>
              </div>
              <div className={'w-full '}>
                <CustomAccordion
                  sections={[
                    {
                      label: `${t('summ.back')}`,
                      fields: ['background_info'],
                      fieldErrors: form.formState.errors,
                      children: [
                        <div className='p-2'>
                          <FormField
                            control={form.control}
                            name='background_info'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('summ.brief')}
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <textarea
                                    className='bg-background-dark border border-gray-400 sm:bg-card p-2 rounded-md resize-y w-full'
                                    placeholder={`${t('summ.summ')}`}
                                    {...field}
                                    rows={3}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                      ]
                    },
                    {
                      label: `${t('summ.prod')}`,
                      fields: ['process_info'],
                      fieldErrors: form.formState.errors,
                      children: [
                        <div className='p-2 '>
                          <FormField
                            control={form.control}
                            name='process_info'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className='text-left'>
                                    {t('summ.over')}
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <textarea
                                    className='bg-background-dark border border-gray-400 sm:bg-card p-2 rounded-md resize-y w-full'
                                    placeholder={`${t('summ.over')}`}
                                    {...field}
                                    rows={3}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                      ]
                    },
                    {
                      label: `${t('summ.list')}`,
                      fields: ['key_assum'],
                      fieldErrors: form.formState.errors,
                      children: [
                        <div className='p-2'>
                          <FormField
                            control={form.control}
                            name='key_assum'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('summ.sales')}
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <textarea
                                    className='bg-background-dark border border-gray-400  sm:bg-card p-2 rounded-md resize-y w-full'
                                    placeholder={`${t('summ.sales')}`}
                                    {...field}
                                    rows={3}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                      ]
                    },
                    {
                      label: `${t('summ.market')}`,
                      fields: ['market_info'],
                      fieldErrors: form.formState.errors,
                      children: [
                        <div className='p-2'>
                          <FormField
                            control={form.control}
                            name='market_info'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('summ.mInfo')}
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <textarea
                                    className='bg-background-dark border border-gray-400  sm:bg-card p-2 rounded-md resize-y w-full'
                                    placeholder={`${t('summ.mInfo')}`}
                                    {...field}
                                    rows={3}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                      ]
                    },
                    {
                      label: `${t('summ.comp')}`,
                      fields: ['competitors'],
                      fieldErrors: form.formState.errors,
                      children: [
                        <div className='p-2'>
                          <FormField
                            control={form.control}
                            name='competitors'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('summ.competitors')}
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <textarea
                                    className='bg-background-dark border border-gray-400  sm:bg-card p-2 rounded-md resize-y w-full'
                                    placeholder={`${t('summ.competitors')}`}
                                    {...field}
                                    rows={3}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                      ]
                    },
                    {
                      label: `${t('summ.channels')}`,
                      fields: ['distribution'],
                      fieldErrors: form.formState.errors,
                      children: [
                        <div className='p-2'>
                          <FormField
                            control={form.control}
                            name='distribution'
                            render={({ field }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className='font-redHat'>
                                  <Text size='body' className=''>
                                    {t('summ.dist')}
                                  </Text>
                                </FormLabel>
                                <FormControl>
                                  <textarea
                                    className='bg-background-dark border border-gray-400  sm:bg-card p-2 rounded-md resize-y w-full'
                                    placeholder={`${t('summ.dist')}`}
                                    {...field}
                                    rows={3}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>
                      ]
                    }
                  ]}
                />
              </div>
            </div>
          </div>
          <LoanStepperFooter />
        </form>
      </Form>
    </Box>
  )
}
