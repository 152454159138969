import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface PestChemicalsDTO {
  chemical_id: number | null | undefined
  chemical_type: 'fungicide' | 'insecticide'
  chemical: number
  adjuvant: number | null | undefined
  pest_id: number | null | undefined
  disease_id: number | null | undefined
}

export interface CreatePestControlDTO {
  cp_id: number | null | undefined
  farmer_id: number | null | undefined
  user_id: number | null | undefined
  type: 'Chemical' | 'Manual'
  chemical_method?: number | null
  chemicals?: PestChemicalsDTO[] | null
}

export const createPestControl = async (params: CreatePestControlDTO): Promise<ApiResponse<[]>> => {
  return axios.post('/production/pest', params)
}
