import type React from 'react'
import { motion } from 'framer-motion'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import TimelineOppositeContent, {
  timelineOppositeContentClasses
} from '@mui/lab/TimelineOppositeContent'
import timeline from './Data/TimelineData' // Import the timeline array
import { useTranslation } from 'react-i18next'

/**
 * FarmerTypes component renders a section that displays different types of farmers
 * in Temo Letlotlo using a timeline layout.
 *
 * @returns {JSX.Element} The FarmerTypes component.
 */
export const FarmerTypes: React.FC = () => {
  const { t } = useTranslation('landing')
  return (
    <div className=' bg-white w-full '>
      <div className='container mx-auto flex flex-col py-20 px-10 space-y-20 justify-center w-full '>
        <motion.div
          className='flex md:justify-center md:items-center'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className='text-4xl md:text-5xl lg:text-6xl font-bold text-gray-700 landing-page'>
            {t('about.typeFarmers')}
          </h2>
        </motion.div>
        <motion.div
          className='flex flex-col justify-center items-center'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses?.root}`]: {
                flex: 0.4
              }
            }}
          >
            {timeline(t).map((item, index) => (
              <TimelineItem key={index}>
                <TimelineOppositeContent color='textSecondary'>
                  <h4 className='text-xl md:text-2xl lg:text-3xl font-bold text-gray-700 landing-page'>
                    {item.title}
                  </h4>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <motion.div
                    initial={{ scale: 0.8 }}
                    whileInView={{ scale: [1, 1.2, 1] }}
                    transition={{ duration: 2.5, repeat: Infinity, repeatType: 'loop' }}
                  >
                    <TimelineDot className='size-8 md:size-10 lg:size-12 bg-blue-700' />
                  </motion.div>
                  {index < timeline.length - 1 && <TimelineConnector className='h-80 md:h-60' />}
                </TimelineSeparator>
                <TimelineContent>
                  {item.description.map((desc, descIndex) => (
                    <ul className='list-disc ml-4 md:ml-8'>
                      <li
                        className='text-base md:text-xl lg:text-2xl font-regular text-gray-600 my-2 landing-page leading-relaxed tracking-wide'
                        key={descIndex}
                      >
                        {desc}
                      </li>
                    </ul>
                  ))}
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </motion.div>
      </div>
    </div>
  )
}