import type React from 'react'
import { useState } from 'react'
import { Card, Tabs, TabsContent, TabsList, TabsTrigger, Text } from '../../../../components/ui'
import { Loader } from '../../../../components/ui/loader'
import { ErrorPage } from '../../../../components/Errors/ErrorPage'
import { useMerchantStore } from '../../../../stores/useMerchantStore'
import { reportsColumns } from '../../components/report/columns'
import { NoTransactions } from '../../components/NoTransactions'
import { useTranslation } from 'react-i18next'
import { SeedsReportsTable } from '../../seed-dealer/components/report/SeedsReportsTable'
import { useGetChemicalReports } from '../../chemical-dealer/api/chemicalReports'
import { useGetSeedsReports } from '../../seed-dealer/api/seedReports'
import { ChemicalsReportsTable } from '../../chemical-dealer/components/reports/ChemicalsReportsTable'
import { type ChemicalTransaction } from '../../chemical-dealer/types'
import { type SeedTransaction } from '../../seed-dealer/types'
import { TabView } from '../../../../components/App/Layout'

export const ChemicalSeedReport: React.FC = () => {
  const { merchant } = useMerchantStore()
  const { t } = useTranslation('merchant')
  const [activeTab, setActiveTab] = useState<string>('seeds')

  const {
    data: chemicalReports,
    isLoading: isLoadingChemicals,
    error: errorChemicals
  } = useGetChemicalReports(merchant?.merchant_id)

  const {
    data: seedReports,
    isLoading: isLoadingSeeds,
    error: errorSeeds
  } = useGetSeedsReports(merchant?.merchant_id)

  const isLoading = activeTab === 'chemicals' ? isLoadingChemicals : isLoadingSeeds
  const error = activeTab === 'chemicals' ? errorChemicals : errorSeeds
  const transactions =
    activeTab === 'chemicals'
      ? chemicalReports?.data.filter((report) => {
          return (
            report.pesticide_list.length >= 1 ||
            report.herbicide_list.length >= 1 ||
            report.fungicide_list.length >= 1 ||
            report.adjuvant_list.length >= 1
          )
        })
      : seedReports?.data.filter((report) => report.seed_list.length >= 1)

  if (isLoading || isLoadingSeeds || isLoadingChemicals) {
    return (
      <div className='grid place-items-center min-h-[30%] h-full flex-1'>
        <Loader />
      </div>
    )
  }

  if (error != null) {
    return error.response?.status === 404 ? <NoTransactions /> : <ErrorPage />
  }

  if (transactions?.length === 0) {
    return <NoTransactions />
  }

  return (
    <TabView>
      <Tabs
        value={activeTab}
        onValueChange={(value) => {
          setActiveTab(value)
        }}
        className='mb-4'
      >
        <TabsList className='flex'>
          <TabsTrigger value='seeds' className={activeTab === 'seeds' ? 'active' : ''}>
            Seeds
          </TabsTrigger>
          <TabsTrigger value='chemicals' className={activeTab === 'chemicals' ? 'active' : ''}>
            Chemicals
          </TabsTrigger>
        </TabsList>
        <TabsContent value='seeds'>
          <Card className='w-full flex flex-col bg-card gap-4 md:gap-6 lg:gap-8 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'>
            <Text size='medium' variant='bodyTextLight'>
              Seeds List
            </Text>
            <SeedsReportsTable
              columns={reportsColumns(t)}
              data={transactions as SeedTransaction[]}
            />
          </Card>
        </TabsContent>
        <TabsContent value='chemicals'>
          <Card className='w-full flex flex-col bg-card gap-4 md:gap-6 lg:gap-8 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'>
            <Text size='medium' variant='bodyTextLight'>
              Chemicals List
            </Text>
            <ChemicalsReportsTable
              columns={reportsColumns(t)}
              data={transactions as ChemicalTransaction[]}
            />
          </Card>
        </TabsContent>
      </Tabs>
    </TabView>
  )
}
