import { RegistrationOtpForm } from 'features/authentication/components/individual/RegistrationOtpForm'
import { useIndividualRegistrationStore } from 'features/authentication/stores/useIndividualRegistrationStore'
import { PhoneNumberUtil } from 'google-libphonenumber'
import type React from 'react'
import { useState } from 'react'
import { Phone, Button } from 'components/ui'
import { useTranslation } from 'react-i18next'
import { useSuperTokens } from '../../hooks/useSuperTokens'

interface ContactDetailsFormProps {
  className?: string
  onBack: () => void
  onNext: () => void
}

const phoneUtil = PhoneNumberUtil.getInstance()

const usePhoneValidation = (phone: string): boolean => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
  } catch (error) {
    return false
  }
}

export const ContactDetailsForm: React.FC<ContactDetailsFormProps> = ({
  className,
  onBack,
  onNext
}: ContactDetailsFormProps) => {
  const { contactDetails, setContactDetails } = useIndividualRegistrationStore()
  const [number, setNumber] = useState<string>(contactDetails !== null ? contactDetails.number : '')
  const [showOtpForm, setShowOtpForm] = useState(false)
  const { sendOtpRegistration, isLoading } = useSuperTokens()
  const isPhoneValid = usePhoneValidation(number)

  const { t } = useTranslation('authenticationTranslation')

  const handleShowOtpForm = (): void => {
    setShowOtpForm(!showOtpForm)
  }

  const onSendOTP = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    event.preventDefault()
    setContactDetails({
      ...contactDetails!,
      number: number.replace(/\s/g, '')
    })
    await sendOtpRegistration(number, setShowOtpForm)
  }

  if (showOtpForm) {
    return (
      <RegistrationOtpForm
        className={'flex flex-col gap-4 sm:gap-6'}
        number={number}
        onBack={handleShowOtpForm}
        onNext={onNext}
      />
    )
  }

  return (
    <form className={'w-full flex flex-col gap-8'}>
      <Phone
        onChange={(num) => {
          setNumber(num)
        }}
        value={number}
      />
      <div className={'flex flex-row justify-between w-full'}>
        <Button variant={'ghost'} onClick={onBack} disabled={isLoading} className={'w-[40%]'}>
          {t('contactDetails.back')}
        </Button>
        <Button
          onClick={onSendOTP}
          type={'submit'}
          disabled={!isPhoneValid || isLoading}
          loading={isLoading}
          className={'w-[40%]'}
        >
          {t('contactDetails.finish')}
        </Button>
      </div>
    </form>
  )
}
