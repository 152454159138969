import type React from 'react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import type z from 'zod'
import { coerce, object, string } from 'zod'
import dayjs from 'dayjs'
import { type GetInventoryRes, type Tractor } from '../../../../farmer/inventory/types'
import { useNavigate } from 'react-router-dom'
import { uniqBy } from 'lodash'
import { Box, TabView } from '../../../../../components/App/Layout'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text
} from '../../../../../components/ui'
import { YearPicker } from '../../../../../components/Form/YearPicker'
import { useMerchantStore } from '../../../../../stores/useMerchantStore'
import { useInventoryItem } from '../hooks/useInventoryItem'
import { locations } from '../../../../farmer/land-management/utils'
import { type Village } from '../../../../../types'
import { useTranslation } from 'react-i18next'
import { useAuthenticationStore } from '../../../../../stores/useAuthenticationStore'
import { useStaticDataStore } from '../../../../../stores/useStaticDataStore'

const registrationNumberRegex = /^B \d{3} [A-Z]{3}$/

const schemaTractor = object({
  yom: coerce.date({
    required_error: 'Field is required',
    invalid_type_error: 'Invalid Date'
  }),
  model: coerce.number().min(1, 'Field is required'),
  district: coerce
    .number({
      required_error: 'District is required',
      invalid_type_error: 'field is required'
    })
    .min(1, 'District is required'),
  subDistrict: coerce
    .number({
      required_error: 'Sub District is required',
      invalid_type_error: 'Sub District is required'
    })
    .min(1, 'Sub District is required'),
  extension: coerce
    .number({
      required_error: 'Extension is required',
      invalid_type_error: 'field is required'
    })
    .min(1, 'Extension is required'),
  village: coerce.number().min(1, 'Field is required'),
  make: coerce.number().min(1, 'Field is required'),
  horsepower: coerce.number().min(1, 'Field is required').optional(),
  drive: string().min(1, 'Field is required').optional(),
  reg_number: string().optional(),
  chassis_number: string().min(1, 'Chassis number is required'), // Chassis number always required
  tractor_type: string().min(1, 'Field is required')
}).superRefine((data, ctx) => {
  // For "CONVENTIONAL" tractors, both reg_number and chassis_number are required
  if (data.tractor_type === 'CONVENTIONAL') {
    if (data.reg_number == null) {
      ctx.addIssue({
        code: 'custom',
        path: ['reg_number'],
        message: 'Registration number is required for conventional tractors'
      })
    } else if (!registrationNumberRegex.test(data.reg_number)) {
      // Validate registration number format for conventional tractors
      ctx.addIssue({
        code: 'custom',
        path: ['reg_number'],
        message: 'Registration number must be in the format "B 123 CDE"'
      })
    }
  }
})

type TractorInput = z.infer<typeof schemaTractor>

interface RegisterTractorsProps {
  inventoryData?: GetInventoryRes
  inventoryItem?: Tractor | undefined
  isView?: boolean
  isMerchant?: boolean
}

export const RegisterTractors: React.FC<RegisterTractorsProps> = ({
  inventoryData,
  inventoryItem,
  isView,
  isMerchant
}) => {
  const { role } = useAuthenticationStore()
  const navigate = useNavigate()
  const { merchant } = useMerchantStore()
  const { tractors } = useStaticDataStore()
  const { createItem, updateItem, isLoading } = useInventoryItem()
  const [tractorType, setTractorType] = useState<string>(
    inventoryItem?.tractor_type?.toString() ?? ''
  )
  const [selectedMake, setSelectedMake] = useState<string | number>(
    inventoryItem?.make_id.toString() ?? ''
  )
  const [selectedModel, setSelectedModel] = useState<string | number>(
    inventoryItem?.model_id.toString() ?? ''
  )
  const [isFormChanged, setIsFormChanged] = useState(false)
  const [village, setVillage] = useState<number | string>(
    inventoryData?.village_id?.toString() ?? ''
  )
  const [district, setDistrict] = useState<number | string>(
    inventoryData?.district_id?.toString() ?? ''
  )
  const [subDistrict, setSubDistrict] = useState<number | string>()
  const [extensionArea, setExtensionArea] = useState<number | string>()
  const [selectedItem, setSelectedItem] = useState<Tractor | undefined>()
  const { t } = useTranslation('inventory')
  const form = useForm<TractorInput>({
    resolver: zodResolver(schemaTractor),
    defaultValues: {
      model: Number(selectedModel),
      make: Number(selectedMake),
      horsepower: inventoryItem?.tractor_power_hp,
      drive: inventoryItem?.drive,
      reg_number: inventoryData?.reg_number ?? '',
      tractor_type: tractorType ?? inventoryItem?.tractor_type ?? '',
      chassis_number: inventoryData?.chassis_number ?? '',
      village: Number(village)
    }
  })

  const findSelectedItem = (): Tractor | undefined => {
    return tractors?.find(
      (item) =>
        item.make_id.toString() === selectedMake &&
        item.model_id.toString() === selectedModel &&
        item?.tractor_type?.toString() === tractorType
    )
  }

  const findVillage = (): Village | undefined => {
    const vil = locations.find((item) => item.village_id.toString() === village?.toString())

    if (vil != null) {
      setDistrict(vil.district_id.toString())
      setSubDistrict(vil.sub_district_id.toString())
      setExtensionArea(vil.extension_area_id.toString())
      setVillage(vil.village_id.toString())
      return vil
    }
    return vil
  }

  useEffect(() => {
    findVillage()

    if (!isFormChanged) {
      setSelectedMake(inventoryItem?.make_id.toString() ?? '')
      setSelectedModel(inventoryItem?.model_id.toString() ?? '')
    }

    setSelectedItem(findSelectedItem())

    form.setValue('make', Number(selectedMake))
    form.setValue('model', Number(selectedModel))
    form.setValue('district', Number(district))
    form.setValue('subDistrict', Number(subDistrict))
    form.setValue('extension', Number(extensionArea))
    form.setValue('village', Number(village))

    const subscription = form.watch(() => {
      setIsFormChanged(true)
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [
    inventoryItem,
    inventoryData,
    selectedMake,
    selectedModel,
    village,
    isFormChanged,
    form,
    district,
    subDistrict,
    extensionArea
  ])

  const types = uniqBy(tractors, (item) => {
    return item?.tractor_type?.toString() ?? ''
  })
  const makes = uniqBy(tractors, (item) => {
    return item.make_id
  })
    .filter((make) => make.tractor_type === tractorType && make.IsValid === true)
    .sort((a, b) => a.make.localeCompare(b.make))

  const models = uniqBy(tractors, (item) => {
    return item.model_id
  })
    .filter((sub) => sub.make_id.toString() === selectedMake.toString())
    .sort((a, b) => a.model.localeCompare(b.model))

  const districts = uniqBy(locations, (item) => item.district_id)
    .slice()
    .sort((a, b) => a.district_name.localeCompare(b.district_name))
  const subDistricts = uniqBy(
    locations.filter((sub) => sub.district_id.toString() === district?.toString()),
    (item) => item.sub_district_id
  )
    .slice()
    .sort((a, b) => a.sub_district_name.localeCompare(b.sub_district_name))
  const extensions = uniqBy(
    locations.filter((ext) => ext.sub_district_id.toString() === subDistrict?.toString()),
    (item) => item.extension_area_id
  )
    .slice()
    .sort((a, b) => a.extension_area_name.localeCompare(b.extension_area_name))
  const villages = uniqBy(
    locations.filter((vil) => vil.extension_area_id.toString() === extensionArea?.toString()),
    (item) => item.village_id
  )
    .slice()
    .sort((a, b) => a.village_name.localeCompare(b.village_name))

  const onSubmit = (data: z.infer<typeof schemaTractor>): void => {
    const payload = {
      merchant_id: merchant?.merchant_id,
      yom: dayjs(String(data.yom)).format('YYYY'),
      resource_item: Number(selectedItem?.model_id),
      ...(data.tractor_type === 'CONVENTIONAL'
        ? {
            chassis_number: data.chassis_number,
            reg_number: data.reg_number
          }
        : { chassis_number: data.chassis_number }),
      inventory_item_verification_status_id: 'PENDING_VERIFICATION',
      last_updated_by: Number(merchant?.user_id),
      inventory_type_id: 1003,
      tractor_type: data.tractor_type,
      village_id: Number(village)
    }

    if (inventoryData != null) {
      updateItem({ ...payload, inventory_id: inventoryData.inventory_id })
    } else {
      createItem(payload)
    }
  }

  return (
    <Box>
      <TabView className={'max-w-[900px]'}>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className={'flex flex-col gap-8'}>
            <div className='grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] items-start gap-4 '>
              <FormField
                control={form.control}
                name='tractor_type'
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={(val) => {
                        setTractorType(val)
                        setSelectedMake('')
                        setSelectedModel('')
                        field.onChange(val)
                      }}
                      value={tractorType?.toString()}
                      disabled={isView === true || isMerchant}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={`${t('tractor.tractor_type')}`} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {types.map((disc) => (
                          <SelectItem
                            key={disc.tractor_type}
                            value={disc?.tractor_type?.toString() ?? ''}
                          >
                            {disc.tractor_type}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {form.watch('tractor_type') === 'CONVENTIONAL' && (
                <FormField
                  control={form.control}
                  name='reg_number'
                  render={({ field }) => (
                    <FormItem className='flex flex-col'>
                      <FormControl>
                        <Input
                          type='text'
                          {...field}
                          placeholder={`${t('tractor.reg')}`}
                          classLabel={'bg-card'}
                          label={`${t('tractor.reg')}`}
                          className={'mt-0'}
                          disabled={isView === true || isMerchant}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              <FormField
                control={form.control}
                name='chassis_number'
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormControl>
                      <Input
                        type='text'
                        {...field}
                        placeholder={`${t('tractor.cha')}`}
                        classLabel={'bg-card'}
                        label={`${t('tractor.cha')}`}
                        className={'mt-0'}
                        disabled={isView === true || isMerchant}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className={'w-full mt-2 h-full'}>
                <YearPicker
                  error={!(form.formState.errors.yom == null)}
                  helperText={form.formState.errors?.yom?.message ?? ''}
                  label={'Manufacture Year'}
                  name={'yom'}
                  className={'w-full text-foreground'}
                  control={form.control}
                  defaultValue={inventoryData !== null ? dayjs(inventoryData?.yom) : undefined}
                  disabled={isView === true || isMerchant}
                />
              </div>

              <FormField
                control={form.control}
                name='make'
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={(val) => {
                        setSelectedMake(val)
                        setSelectedModel('')
                        field.onChange(val)
                      }}
                      value={selectedMake?.toString()}
                      disabled={isView === true || tractorType === '' || isMerchant}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder='Make' />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {makes.map((disc) => (
                          <SelectItem key={disc.make_id} value={disc.make_id.toString()}>
                            {disc.make}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name='model'
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={(val) => {
                        setSelectedModel(val)
                        field.onChange(val)
                      }}
                      value={selectedModel?.toString()}
                      disabled={selectedMake === '' || isView === true || isMerchant}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder='Model' />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {models?.map((disc) => (
                          <SelectItem key={disc.model_id} value={disc.model_id.toString()}>
                            {disc.model}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name='horsepower'
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormControl>
                      <Input
                        type='text'
                        {...field}
                        label={'Power(hp)'}
                        classLabel={'bg-card'}
                        placeholder={'123 hp'}
                        value={
                          selectedItem != null ? selectedItem?.tractor_power_hp?.toString() : 'N/A'
                        }
                        disabled
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name='drive'
                render={({ field }) => (
                  <FormItem>
                    <Input
                      type='text'
                      label={'Drive'}
                      classLabel={'bg-card'}
                      placeholder={'e.g 4wd'}
                      {...field}
                      value={selectedItem != null ? selectedItem?.drive?.toString() : 'N/A'}
                      disabled
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <Text className={'text-center'}>
              Select the location your inventory is primarily stored
            </Text>
            <div className='grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] items-start gap-4 '>
              <FormField
                control={form.control}
                name='district'
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={(val) => {
                        setDistrict(val)
                        setSubDistrict('')
                        setExtensionArea('')
                        setVillage('')
                        field.onChange(val)
                      }}
                      value={district.toString()}
                      disabled={isView === true || isMerchant === true}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={`District`} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {districts.map((disc) => (
                          <SelectItem key={disc.district_id} value={disc.district_id.toString()}>
                            {disc.district_name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='subDistrict'
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={(val) => {
                        setSubDistrict(val)
                        setExtensionArea('')
                        setVillage('')
                        field.onChange(val)
                      }}
                      value={subDistrict?.toString()}
                      disabled={isView === true || isMerchant === true || district === ''}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={`Sub-District`} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {subDistricts.map((sub) => (
                          <SelectItem
                            key={sub.sub_district_id}
                            value={sub.sub_district_id.toString()}
                          >
                            {sub.sub_district_name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='extension'
                disabled={subDistrict === ''}
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={(val) => {
                        setExtensionArea(val)
                        setVillage('')
                        field.onChange(val)
                      }}
                      value={extensionArea?.toString()}
                      disabled={isView === true || isMerchant === true || subDistrict === ''}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={`Extension Area`} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {extensions.map((ext) => (
                          <SelectItem
                            key={ext.extension_area_id}
                            value={ext.extension_area_id.toString()}
                          >
                            {ext.extension_area_name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='village'
                render={({ field }) => (
                  <FormItem>
                    <Select
                      onValueChange={(val) => {
                        setVillage(val)
                        field.onChange(val)
                      }}
                      value={village?.toString()}
                      disabled={isView === true || isMerchant}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder='village' />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {villages.map((vil) => (
                          <SelectItem key={vil.village_id} value={vil.village_id.toString()}>
                            {vil.village_name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className='flex w-full justify-between space-x-4'>
              <Button
                className='w-full sm:w-[18rem]'
                variant='secondary'
                onClick={() => {
                  navigate(-1)
                }}
              >
                Cancel
              </Button>
              {isView === true &&
                isMerchant === false &&
                inventoryData?.item_verification_status !== 'PENDING_VERIFICATION' && (
                  <Button
                    className='w-full sm:w-[18rem]'
                    onClick={() => {
                      navigate(
                        `${
                          role === 1001 ? '/officer' : '/service-provider'
                        }/inventory/assessment/${Number(inventoryData?.inventory_id)}`
                      )
                    }}
                  >
                    View Assessment
                  </Button>
                )}
              {isView === false && isMerchant === false && (
                <Button
                  className='w-1/2 max-w-[18rem]'
                  type='submit'
                  disabled={isLoading || (inventoryData != null && !isFormChanged)}
                  loading={isLoading}
                >
                  {inventoryData != null ? 'Update' : 'Submit'}
                </Button>
              )}
              {isMerchant === true && (
                <Button
                  className='w-1/2 max-w-[18rem]'
                  onClick={() => {
                    navigate(`/inventory/assessment/${Number(inventoryData?.inventory_id)}`)
                  }}
                >
                  {inventoryData?.item_verification_status === 'PENDING_VERIFICATION'
                    ? 'New Assessment'
                    : 'View Assessment'}
                </Button>
              )}
            </div>
          </form>
        </Form>
      </TabView>
    </Box>
  )
}
