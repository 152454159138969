import type React from 'react'
import { type SoilSampleAnalysis } from 'types'
import { ScrollArea, Text } from 'components/ui'
import { Separator } from 'components/ui/separator'
import { ChevronDown } from 'lucide-react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

interface ViewSoilSampleAnalysisProps {
  ssa: SoilSampleAnalysis
}

export const ViewSoilSampleAnalysis: React.FC<ViewSoilSampleAnalysisProps> = ({ ssa }) => {
  const { t } = useTranslation('landManagement')
  return (
    <div className='w-full flex flex-col p-6  '>
      {/* Heading Message */}
      <Text size='medium'>
        {/* {ssa.farm_name} */}
        {t('ssr.analysis')}
      </Text>
      <Separator />
      <ScrollArea className='h-[560px] w-full border-b p-4  mt-10 '>
        <div className={'flex flex-col gap-4'}>
          <Text size={'medium'} variant={'secondary'}>
            {t('ssr.general')}
          </Text>
          <div className={'flex'}>
            <div className={'flex flex-1 flex-col'}>
              <Text size={'small'}>{t('ssr.season')}</Text>
              <Text className={'font-semibold'}>{ssa.plough_season}</Text>
              {/* <p>{ssa.plough_season}</p> */}
            </div>
            <div className={'flex flex-1 flex-col'}>
              <Text size={'small'}>{t('ssr.ssrExpiry')}</Text>
              <Text className={'font-semibold'}>{dayjs(ssa.expiry_date).format('DD-MM-YYYY')}</Text>
              {/* <p>{moment(ssa.expiry_date).format('DD-MM-YYYY')}</p> */}
            </div>
          </div>
          <div className={'flex flex-1 flex-col'}>
            <Text size={'small'}>{t('ssr.lab')}</Text>
            <Text className={'font-semibold'}>{ssa.laboratory_name}</Text>
            {/* {ssa.laboratory_name} */}
          </div>
        </div>

        <div className={'flex flex-col gap-4 mt-8'}>
          <Text size={'medium'} variant={'secondary'}>
            {t('ssr.analysis')}
          </Text>
          <div className={'flex flex-wrap gap-8'}>
            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Electrical Conductivity, EC (mSm-1)</Text>
              <Text className={'font-semibold'}>{ssa.ec_comp}</Text>
            </div>

            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Magnesium, Mg (mg/kg)</Text>
              <Text className={'font-semibold'}>{ssa.mg_comp}</Text>
            </div>
            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Manganese,Mn (mg/kg)</Text>
              <Text className={'font-semibold'}>{ssa.mn_comp}</Text>
            </div>

            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Sodium, Na (mg/kg)</Text>
              <Text className={'font-semibold'}>{ssa.na_comp}</Text>
            </div>
            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Copper, Cu (mg/kg)</Text>
              <Text className={'font-semibold'}>{ssa.cu_comp}</Text>
            </div>

            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Potassium, K (mg/kg)</Text>
              <Text className={'font-semibold'}>{ssa.k_comp}</Text>
            </div>

            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Calcium, Ca (mg/kg)</Text>
              <Text className={'font-semibold'}>{ssa.ca_comp}</Text>
            </div>
            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Iron, Fe (mg/kg)</Text>
              <Text className={'font-semibold'}>{ssa.fe_comp}</Text>
            </div>
            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>pH</Text>
              <Text className={'font-semibold'}>{ssa.ph_comp}</Text>
            </div>
            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Zinc, Zn (mg/kg)</Text>
              <Text className={'font-semibold'}>{ssa.zn_comp}</Text>
            </div>
            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Phosphorus, P (mg/kg)</Text>
              <Text className={'font-semibold'}>{ssa.p_comp}</Text>
            </div>
            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Sulphur, S (mg/kg)</Text>
              <Text className={'font-semibold'}>{ssa.s_comp}</Text>
            </div>

            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Organic Carbon (%)</Text>
              <Text className={'font-semibold'}>{ssa.c_comp}</Text>
            </div>
            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Cation Exchange Capacity, CEC (mol/kg)</Text>
              <Text className={'font-semibold'}>{ssa.cec_comp}</Text>
            </div>
            <div className={'flex flex-[1_0_130px] flex-col'}>
              <Text size={'small'}>Bulk Density, (g/cm3)</Text>
              <Text className={'font-semibold'}>{ssa.bulk_density}</Text>
            </div>
          </div>
        </div>
      </ScrollArea>
      <ChevronDown className={'absolute bottom-[-25px]'} />
    </div>
  )
}
