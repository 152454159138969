import { useLeaseStore } from 'features/farmer/land-management/stores/leaseStore'
import type React from 'react'
import { Button, Text } from 'components/ui'
import { useTranslation } from 'react-i18next'

interface LesseeConfirmationProps {
  next: () => void
  back: () => void
}

export const LesseeConfirmation: React.FC<LesseeConfirmationProps> = ({ next, back }) => {
  const { t } = useTranslation('landManagement')
  const { lesseeDetails } = useLeaseStore()

  return (
    <div className={'w-full flex flex-col gap-4 md:gap-8'}>
      <Text size={'medium'} className={'text-center'} variant={'bodyText'}>
        {t('leaseForm.person')}
      </Text>
      <Text size={'large'} className={'text-center'} variant={'primaryLight'}>
        {lesseeDetails?.farmer_type === 2
          ? ` ${lesseeDetails.company_name ?? 'N/A'}`
          : `${lesseeDetails?.firstname?.trim().toUpperCase() ?? ''} ${
              lesseeDetails?.surname?.trim().toUpperCase() ?? ''
            }`}
      </Text>
      <div className={'w-full flex justify-between gap-4 md:gap-8'}>
        <Button variant={'secondary'} onClick={back} className={'w-1/2'}>
          {t('leaseForm.back')}
        </Button>
        <Button variant={'primary'} onClick={next} className={'w-1/2'}>
          {t('leaseForm.next')}
        </Button>
      </div>
    </div>
  )
}
