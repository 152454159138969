/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import type React from 'react'
import { useAtom } from 'jotai'
import { individualLoanItem } from '../stores/individualLoanItem'
import { Box } from '../../../../../components/App/Layout'
import { Card, Text, useStepper } from '../../../../../components/ui'
import { type LoanStepperProps } from '../../../types'
import { LoanStepperFooter } from '../../generic/LoanStepperFooter'
import { IoIosWarning } from 'react-icons/io'
import { useTranslation } from 'react-i18next'

export const SecurityOptional: React.FC<LoanStepperProps> = ({ onComplete }) => {
  const [, setLoanData] = useAtom(individualLoanItem)
  const { nextStep } = useStepper()
  const { t } = useTranslation('loanManagement')

  const onSubmit = (): void => {
    setLoanData((prev) => ({
      ...prev!,
      loan_security: {
        immovable_property: [
          {
            immovable_property_id: 0,
            age: 0,
            estimated_forces_sale_value: 0,
            original_cost: 0,
            market_value: 0,
            encumbrances_amount: 0
          }
        ],
        loan_machinery: {
          age: 0,
          original_cost: 0,
          market_value: 0,
          estimated_forces_sale_value: 0,
          encumbrances_amount: 0,
          machinery_id: 0
        },
        loan_motor_vehicle: {
          age: 0,
          original_cost: 0,
          market_value: 0,
          estimated_forces_sale_value: 0,
          encumbrances_amount: 0,
          vehicle_id: 0
        },
        loan_bank_guarantee: { guarantee_value: 0 },
        loan_other_guarantee: { guarantee_value: 0 },
        loan_insurance_policy: { sum_assured: 0 },
        loan_other_security: { fixed_deposit_balance: 0 },
        basis_of_estimation: {
          basis_of_estimation: '0'
        }
      }
    }))
    nextStep()
    if (onComplete != null) {
      onComplete(true)
    }
  }
  return (
    <Box>
      <div className='flex flex-col space-y-8 my-6 w-full'>
        <Text size={'medium'}>{t('sec.sec')}</Text>

        <Card
          className={
            'w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
          }
        >
          <div className='flex'>
            <IoIosWarning fontSize='20' className='text-amber-600 mr-4' />

            <div>
              <Text size='bodyBold' className=' mb-4'>{t('sec.not')}</Text>

              <Text size='body' className=''>
                <li> {t('sec.reason')}</li>
              </Text>
            </div>
          </div>
        </Card>
      </div>
      <LoanStepperFooter submit={onSubmit} />
    </Box>
  )
}