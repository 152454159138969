import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  createPestControl,
  type CreatePestControlDTO,
  type PestChemicalsDTO
} from 'features/farmer/cropping-plan/api/pest-control/createPestControl'
import {
  updatePestControl,
  type UpdatePestControlDTO
} from 'features/farmer/cropping-plan/api/pest-control/updatePestControl'
import { useCroppingPlanStore } from 'features/farmer/cropping-plan/stores/useCroppingPlanStore'
import { useFarmerStore } from 'stores/useFarmerStore'
import { type CroppingPlan, type PestControl } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { useToast } from '../../../../components/ui'
import { type SelectedPesticides, usePestAndDiseaseStore } from '../stores/usePestAndDiseaseStore'
import collect from 'collect.js'
import type { z } from 'zod'
import { type pestMethodSchema } from '../components/steps/pest/chemical/ApplicationMethod'

interface UseCroppingPlanPest {
  header: CroppingPlan | null
  pest: PestControl | null
  setPest: (pest: PestControl | null) => void
  isLoading: boolean
  createChemicalPest: (
    selected: SelectedPesticides[] | undefined,
    data: z.infer<typeof pestMethodSchema>
  ) => void
  updateChemicalPest: (
    selected: SelectedPesticides[] | undefined,
    data: z.infer<typeof pestMethodSchema>
  ) => void
}

export const useCroppingPlanPest = (next: () => void): UseCroppingPlanPest => {
  const { toast } = useToast()
  const { farmer } = useFarmerStore()
  const queryClient = useQueryClient()
  const { selectedFungicides, selectedPesticides } = usePestAndDiseaseStore()
  const { header, pest, setPest } = useCroppingPlanStore()

  const createPestMutation = useMutation({
    mutationFn: async (cpDto: CreatePestControlDTO) => {
      return createPestControl(cpDto)
    }
  })

  const updatePestMutation = useMutation({
    mutationFn: async (cpDto: UpdatePestControlDTO) => {
      return updatePestControl(cpDto)
    }
  })

  const createChemicalPest = (
    selected: SelectedPesticides[] | undefined,
    data: z.infer<typeof pestMethodSchema>
  ): void => {
    const fungicides: PestChemicalsDTO[] | undefined = selectedFungicides?.map((item) => {
      return {
        chemical_type: 'fungicide',
        chemical: item.chemical.fungicide_price_detail_id,
        adjuvant: item.adjuvant?.adjuvant_price_detail_id,
        disease_id: item.chemical.disease_id
      }
    }) as PestChemicalsDTO[]

    const pesticides: PestChemicalsDTO[] | undefined =
      (selected?.map((item) => {
        return {
          chemical_type: 'insecticide',
          chemical: item.chemical.pesticides_price_detail_id,
          adjuvant: item.adjuvant?.adjuvant_price_detail_id,
          pest_id: item.chemical?.pest_id
        }
      }) as PestChemicalsDTO[]) ??
      (selectedPesticides?.map((item) => {
        return {
          chemical_type: 'insecticide',
          chemical: item.chemical.pesticides_price_detail_id,
          adjuvant: item.adjuvant?.adjuvant_price_detail_id,
          pest_id: item.chemical?.pest_id
        }
      }) as PestChemicalsDTO[])

    const chemicals = collect(fungicides ?? [])
      .merge(pesticides ?? [])
      .all()

    const selectedChemicals = chemicals != null ? chemicals : []

    createPestMutation.mutate(
      {
        farmer_id: farmer?.farmer_id,
        cp_id: header?.cropping_plan_id,
        type: 'Chemical',
        user_id: farmer?.user_id,
        chemical_method: data.methods,
        chemicals: selectedChemicals
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Created Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['pest-control'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  const updateChemicalPest = (
    selected: SelectedPesticides[] | undefined,
    data: z.infer<typeof pestMethodSchema>
  ): void => {
    const fungicides: PestChemicalsDTO[] | undefined = selectedFungicides?.map((item) => {
      return {
        chemical_type: 'fungicide',
        chemical: item.chemical.fungicide_price_detail_id,
        adjuvant: item.adjuvant?.adjuvant_price_detail_id,
        disease_id: item.chemical.disease_id
      }
    }) as PestChemicalsDTO[]

    const pesticides: PestChemicalsDTO[] | undefined =
      (selected?.map((item) => {
        return {
          chemical_type: 'insecticide',
          chemical: item.chemical.pesticides_price_detail_id,
          adjuvant: item.adjuvant?.adjuvant_price_detail_id,
          pest_id: item.chemical?.pest_id
        }
      }) as PestChemicalsDTO[]) ??
      (selectedPesticides?.map((item) => {
        return {
          chemical_type: 'insecticide',
          chemical: item.chemical.pesticides_price_detail_id,
          adjuvant: item.adjuvant?.adjuvant_price_detail_id,
          pest_id: item.chemical?.pest_id
        }
      }) as PestChemicalsDTO[])

    const chemicals = collect(fungicides ?? [])
      .merge(pesticides ?? [])
      .all()

    const selectedChemicals = chemicals != null ? chemicals : []

    updatePestMutation.mutate(
      {
        farmer_id: farmer?.farmer_id,
        cp_id: header?.cropping_plan_id,
        type: 'Chemical',
        user_id: farmer?.user_id,
        chemical_method: data.methods,
        chemicals: selectedChemicals,
        cp_pest_disease_control_id: pest?.cp_pest_disease_control_id
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Updated Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['pest-control'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  return {
    header,
    pest,
    setPest,
    isLoading: createPestMutation.isLoading || updatePestMutation.isLoading,
    createChemicalPest,
    updateChemicalPest
  }
}
