import type { ApiResponse, Farm, Officer, Village } from '../../../../types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createFarm, type CreateFarmDTO } from '../api/createLand'
import { AxiosError, type AxiosResponse } from 'axios'
import { mapCmsErrorToMessage } from '../../../../utils/apiErrors'
import { useNewFarmStore } from '../stores/newFarm'
import { useFarmerStore } from '../../../../stores/useFarmerStore'
import { locations } from '../utils'
import { useGetOfficer } from '../api/getOfficer'
import { useToast } from '../../../../components/ui'
import { editFarm, type EditFarmDTO } from '../api/editFarm'
import { deleteFarm, type DeleteFarmDTO } from '../api/deleteFarm'

interface UseNewFarm {
  officer: Officer | undefined
  isLoading: boolean
  loading: boolean
  location: Village | undefined
  createNewFarm: (next: () => void) => void
  editNewFarm: (farmData: Farm, payload: EditFarmPayload, next: () => void) => void
  deleteNewFarm: (next: () => void) => void
}

interface EditFarmPayload {
  size: number
  farmName: string
}

export const useNewFarm = (farm?: Farm): UseNewFarm => {
  const { toast } = useToast()
  const { farmer } = useFarmerStore()
  const { farmDetails, locationDetails } = useNewFarmStore()
  const locationInfo = locations.find(
    (location) =>
      location.extension_area_id === locationDetails?.extensionAreaId &&
      location.district_id === locationDetails?.districtId &&
      location.sub_district_id === locationDetails?.subDistrictId &&
      location.village_id === locationDetails?.villageId
  )

  const { data: officer, isLoading } = useGetOfficer(locationInfo?.extension_area_id)
  const queryClient = useQueryClient()

  const createLandMutation = useMutation({
    mutationFn: async (payload: CreateFarmDTO) => {
      return createFarm(payload)
    }
  })

  const editFarmMutation = useMutation({
    mutationFn: async (editFarmDTO: EditFarmDTO) => {
      return editFarm(farm?.farm_id, editFarmDTO)
    }
  })

  const deleteFarmMutation = useMutation({
    mutationFn: async (deleteFarmDTO: DeleteFarmDTO) => {
      return deleteFarm(deleteFarmDTO)
    }
  })

  const createNewFarm = (next: () => void): void => {
    if (farmDetails != null && locationDetails != null) {
      createLandMutation.mutate(
        {
          FARM_NAME: farmDetails?.nickname,
          VILLAGE_ID: locationDetails?.villageId,
          DISTRICT_ID: locationDetails?.districtId,
          EXT_AREA_ID: locationDetails?.extensionAreaId,
          FARMER_ID: farmer?.farmer_id,
          LAST_UPDATED_BY: farmer?.user_id,
          NICKNAME: farmDetails?.nickname,
          PLOT_NO: 1,
          SIZE: parseFloat(farmDetails?.size?.toFixed(4)),
          SUB_DISTRICT_ID: locationDetails?.subDistrictId,
          UNIT_OF_MEASURE: 1
        },
        {
          onSuccess: (resp) => {
            if (resp.status === 201) {
              next()
              toast({
                variant: 'success',
                title: 'Farm successfully created.'
              })
              void queryClient.invalidateQueries({ queryKey: ['farms'] })
            }
          },
          onError: (err: unknown) => {
            if (err instanceof AxiosError) {
              const error = err.response as AxiosResponse<ApiResponse<[]>>

              toast({
                variant: 'destructive',
                title: 'Uh oh! Something went wrong.',
                description: mapCmsErrorToMessage(
                  error.status.toString() ?? err.code ?? error.data.message ?? ''
                )
              })
            }
          }
        }
      )
    }
  }

  const editNewFarm = (farmData: Farm, payload: EditFarmPayload, next: () => void): void => {
    editFarmMutation.mutate(
      {
        UNIT_OF_MEASURE: 1,
        PLOT_NO: farmData.plot_number,
        SUB_DISTRICT_ID: farmData.sub_district_id,
        LAST_UPDATED_BY: farmer?.user_id,
        NICKNAME: farmData.nickname,
        EXT_AREA_ID: farmData.ext_area_id,
        VILLAGE_ID: farmData.village_id,
        DISTRICT_ID: farmData.district_id,
        FARMER_ID: farmer?.farmer_id,
        SIZE: payload.size,
        FARM_NAME: payload.farmName
      },
      {
        onSuccess: (res) => {
          if (res.status === 201 && res.success) {
            next()
            toast({
              variant: 'success',
              title: 'Farm successfully edited.'
            })
            void queryClient.invalidateQueries({ queryKey: ['farms'] })
          } else {
            const error = res.message
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(error)
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err?.response as AxiosResponse<ApiResponse<[]>>
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          }
        }
      }
    )
  }

  const deleteNewFarm = (next: () => void): void => {
    deleteFarmMutation.mutate(
      {
        userId: farmer?.user_id,
        farmerId: farmer?.farmer_id,
        farmId: farm?.farm_id
      },
      {
        onSuccess: (res) => {
          if (res.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Farm successfully deleted.'
            })
            void queryClient.invalidateQueries({ queryKey: ['farms'] })
          } else {
            const error = res.message
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(error)
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err?.response as AxiosResponse<ApiResponse<[]>>
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          }
        }
      }
    )
  }

  return {
    createNewFarm,
    editNewFarm,
    deleteNewFarm,
    officer: officer?.data[0],
    location: locationInfo,
    isLoading,
    loading:
      createLandMutation.isLoading || editFarmMutation.isLoading || deleteFarmMutation.isLoading
  }
}
