import type React from 'react'
import { useState } from 'react'
import { Button, Dialog, DialogContent, Text } from 'components/ui'
import { useStepper } from 'components/ui/stepper'
import { useTranslation } from 'react-i18next'
import { useMedia } from 'react-use'

interface StepperFooterProps {
  isLoading?: boolean
  form?: string
  isFirstStep?: boolean
  notComplete?: boolean
  disabled?: boolean
  cancel?: () => void
  submit?: () => void
}

export const LoanStepperFooter: React.FC<StepperFooterProps> = ({
  isLoading = false,
  form,
  isFirstStep = false,
  notComplete = false,
  disabled = false,
  cancel,
  submit
}) => {
  const isDesktop = useMedia('(min-width: 900px)')
  const isMediumScreen = useMedia('(min-width: 768px)')
  const { prevStep, nextStep, isLastStep, isDisabledStep, isOptionalStep, currentStep } =
    useStepper()
  const { t } = useTranslation('croppingPlan')

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = (): void => {
    setIsModalOpen(true)
  }

  const handleCloseModal = (): void => {
    setIsModalOpen(false)
  }

  const handleConfirmCancel = (): void => {
    setIsModalOpen(false)
    if (cancel != null) {
      cancel()
    }
  }

  const handleClickPrev = (): void => {
    if (isFirstStep) {
      handleOpenModal()
    } else {
      prevStep()
    }
  }

  const handleClickSkip = (): void => {
    nextStep()
  }

  return (
    <div className='w-full flex justify-end gap-2 mt-2.5'>
      {isOptionalStep ? (
        <Button
          onPointerEnter={isDesktop ? undefined : handleClickSkip}
          disabled={isLoading}
          size={isMediumScreen ? 'sm' : 'xs'}
          variant={'outlined'}
          onClick={handleClickSkip}
          className={'flex justify-center items-center w-28'}
        >
          {t('footer.skip')}
        </Button>
      ) : null}
      <Button
        disabled={isLoading || (isDisabledStep && !isFirstStep)}
        onClick={handleClickPrev}
        size={isMediumScreen ? 'sm' : 'xs'}
        variant={'secondary'}
        className={'flex justify-center items-center w-28'}
      >
        {isFirstStep ? `${t('footer.cancel')}` : `${t('footer.prev')}`}
      </Button>
      <Button
        disabled={isLoading || notComplete || disabled}
        loading={isLoading}
        onClick={isLastStep ? nextStep : submit != null ? submit : undefined}
        size={isMediumScreen ? 'sm' : 'xs'}
        variant={'primary'}
        type={'submit'}
        form={form}
        className={'flex justify-center items-center w-28'}
      >
        {isLastStep ? `${t('footer.finish')}` : `${t('footer.next')}`}
      </Button>

      <Dialog open={isModalOpen} defaultOpen={false} modal onOpenChange={handleCloseModal}>
        <DialogContent className='bg-card pt-16 max-w-[90vw] md:max-w-[30vw] md:min-h-[10vh] max-h-[100vh] overflow-y-auto'>
          <div className='flex flex-col space-y-6'>
            <Text size='medium'>{t('footer.sure')}</Text>

            <Text size='body'>{t('footer.lose')}</Text>

            <div className='flex flex-row-reverse'>
              <Button
                variant='warning'
                size={isMediumScreen ? 'sm' : 'xs'}
                onClick={handleConfirmCancel}
                className='ml-4 w-16'
              >
                <Text variant='bodyText'> Yes </Text>
              </Button>
              <Button onClick={handleCloseModal} variant='secondary' size={'xs'} className='w-16 '>
                <Text>{t('footer.no')}</Text>
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}