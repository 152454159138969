import type React from 'react'
import { CustomDatePicker } from 'components/Form'
import { Text } from '../../../../../components/Elements/Text'
import type z from 'zod'
import { array, coerce, object, string, type TypeOf } from 'zod'
import { useFieldArray, useForm } from 'react-hook-form'
import { useAtom } from 'jotai/index'
import { companyLoanStore } from '../stores'
import { CropsList } from '../../../../farmer/cropping-plan/utils/crops'
import dayjs from 'dayjs'
import type { LoanStepperProps } from '../../../types'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  useStepper
} from '../../../../../components/ui'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { type ProductionScheduleInput } from '../individual'
import { Box } from '../../../../../components/App/Layout'
import { Info, Plus } from 'lucide-react'
import { LoanStepperFooter } from '../../generic/LoanStepperFooter'

const productionSchedule = object({
  crop_name: string().min(1, 'Field is required'),
  tonnage: string().min(1, 'Field is required'),
  production_year: coerce.date({
    required_error: 'Field is required',
    invalid_type_error: 'Invalid Date'
  })
})

const schemaPSI = object({
  productionSchedule: array(productionSchedule).nonempty('Field is required')
})

export type ProductionScheduleCompanyInput = TypeOf<typeof schemaPSI>

export const ProductionScheduleCompany: React.FC<LoanStepperProps> = ({ onComplete }) => {
  const [loanData, setLoanData] = useAtom(companyLoanStore)
  const { nextStep } = useStepper()
  const { t } = useTranslation('loanManagement')

  const form = useForm<z.infer<typeof schemaPSI>>({
    resolver: zodResolver(schemaPSI),
    defaultValues: {
      productionSchedule: loanData?.production_history?.map((item) => ({
        crop_name: item.crop_name,
        tonnage: item.tonnage,
        production_year: new Date(item.production_year)
      }))
    }
  })

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'productionSchedule'
  })

  const addNewProductionForm = (): void => {
    append({
      crop_name: '',
      production_year: new Date(),
      tonnage: ''
    })
  }
  const onSubmit = (data: ProductionScheduleInput): void => {
    setLoanData((prev) => ({
      ...prev!,
      production_history: data.productionSchedule
    }))
    nextStep()
    if (onComplete != null) {
      onComplete(true)
    }
  }

  return (
    <Box>
      <Form {...form}>
        <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
          <div className='flex flex-col space-y-8 my-6 w-full'>
            <div className={'flex flex-col md:flex-row space-y-4 md:space-y-0 justify-between'}>
              <div className='flex flex-col gap-3'>
                <Text size={'medium'} className={''}>
                  {t('prod.prod')}
                </Text>
                <div className='flex items-center'>
                  <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
                  <Text variant='secondary' size='small' className=''>
                    Provide information about the crops you have produced in the past.
                  </Text>
                </div>
              </div>
              <Button
                variant='secondary'
                size={'xs'}
                className='w-32'
                onClick={addNewProductionForm}
              >
                {t('prod.new')}
                <Plus className='ml-2 h-4 w-4' />
              </Button>
            </div>
            <FormField
              control={form.control}
              name={`productionSchedule`}
              render={() => (
                <FormItem className={'w-full mb-8'}>
                  {fields.map((field, index) => (
                    <div key={index} id={`history_${index + 1}`} className={'w-full mb-6'}>
                      <div className='flex items-center mb-4'>
                        <FormLabel>{`${t('prod.history')} ${index + 1}`}</FormLabel>
                      </div>

                      <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
                        <div>
                          <FormField
                            control={form.control}
                            name={`productionSchedule.${index}.crop_name`}
                            render={({ field: psField }) => (
                              <FormItem>
                                <FormLabel className='font-redHat'>{t('prod.crop')}</FormLabel>
                                <Select
                                  onValueChange={(val) => {
                                    psField.onChange(val)
                                  }}
                                  value={psField.value?.toString()}
                                >
                                  <FormControl>
                                    <SelectTrigger>
                                      <SelectValue placeholder={`${t('prod.sCrop')}`} />
                                    </SelectTrigger>
                                  </FormControl>
                                  <SelectContent>
                                    {CropsList.map((item) => (
                                      <SelectItem
                                        key={item.crop_id}
                                        value={item.crop_id.toString()}
                                      >
                                        <Text variant='colorText'>
                                          {item.crop_name.toUpperCase()}
                                        </Text>
                                      </SelectItem>
                                    ))}
                                  </SelectContent>
                                </Select>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>

                        <div className='mt-2'>
                          <FormField
                            control={form.control}
                            name={`productionSchedule.${index}.tonnage`}
                            render={({ field: lwoField }) => (
                              <FormItem className='flex flex-col'>
                                <FormLabel className={'font-redHat'}>{t('prod.ton')}</FormLabel>
                                <FormControl>
                                  <Input
                                    classLabel={'bg-background-dark sm:bg-card'}
                                    placeholder={`${t('prod.ton')}`}
                                    type={'number'}
                                    inputMode={'numeric'}
                                    {...lwoField}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </div>

                        <div className='mt-7'>
                          <CustomDatePicker
                            format={'YYYY'}
                            className='capitalize w-full mt-4'
                            label={`${t('prod.year')}`}
                            defaultValue={
                              loanData?.production_history?.[index]?.production_year === undefined
                                ? undefined
                                : dayjs(loanData?.production_history?.[index]?.production_year)
                            }
                            error={
                              !(
                                form.formState.errors.productionSchedule?.[index]
                                  ?.production_year == null
                              )
                            }
                            helperText={
                              form.formState.errors.productionSchedule?.[index]?.production_year
                                ?.message ?? ''
                            }
                            name={`production_year`}
                            control={form.control}
                          />
                        </div>
                      </div>
                      <div className='w-full flex justify-end mt-4'>
                        <Button
                          color='error'
                          variant={'secondary'}
                          size={'xs'}
                          onClick={() => {
                            remove(index)
                          }}
                          disabled={index === 0}
                        >
                          {t('prod.remove')}
                        </Button>
                      </div>
                    </div>
                  ))}
                </FormItem>
              )}
            />
          </div>
          <LoanStepperFooter />
        </form>
      </Form>
    </Box>
  )
}
