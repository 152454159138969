import type React from 'react'
import { motion } from 'framer-motion'
import programPackages from './Data/MmokoRewardDataOne'
import mmokoInfo from './Data/MmokoRewardDataTwo'
import programPackagesThree from './Data/MmokoRewardDataThree'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../../../components/ui'
import Tractor from '../../../../../assets/landing/icons/tractor_equipment.svg'
import Irrigation from '../../../../../assets/landing/icons/plantswatering.svg'
import FencingIcon from '../../../../../assets/landing/icons/loan_tab.svg'
import { Fencing } from './Enhancers/Fencing'
import { TractorandImplements } from './Enhancers/TractorandImplements'
import { SupplementaryIrrigationEnhancer } from './Enhancers/SupplementaryIrrigation'
import { useTranslation } from 'react-i18next'

export const TemoLetlotloEnhancers: React.FC = () => {
  const { t } = useTranslation('landing')

  return (
    <div className='bg-blue-50 w-full '>
      <div className='container mx-auto py-10 md:py-20 flex flex-col px-10 justify-center w-screen md:w-full space-y-8'>
        <motion.div
          className='flex flex-col md:justify-center md:items-center mb-12'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className='text-4xl md:text-5xl lg:text-6xl font-bold text-gray-700 landing-page'>
            {t('tle.tle')}
          </h2>
        </motion.div>

        <Tabs defaultValue='fencing' className=''>
          <TabsList className='flex bg-blue-50 overflow-x-auto h-12'>
            <TabsTrigger
              value='fencing'
              className='data-[state=active]:bg-blue-100 rounded-2xl min-w-[300px] md:w-1/2 lg:w-1/4'
            >
              <motion.div
                className='p-2 bg-blue-100 rounded-lg flex items-center mr-5'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <img src={FencingIcon} alt='Tree Icon' className='w-6 h-6' />
              </motion.div>
              <h3 className='sm:text-base md:text-lg lg:text-xl font-semibold text-gray-600 landing-page mr-5'>
                {t('tle.fen')}
              </h3>
            </TabsTrigger>
            <TabsTrigger
              value='tractor'
              className='data-[state=active]:bg-blue-100 rounded-2xl min-w-[300px] md:w-1/2 lg:w-1/4'
            >
              <motion.div
                className='p-2 bg-blue-100 rounded-lg flex items-center mr-5'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <img src={Tractor} alt='Tree Icon' className='w-6 h-6' />
              </motion.div>
              <h3 className='sm:text-base md:text-lg lg:text-xl text-gray-600 font-semibold landing-page'>
                {t('tle.tra')}
              </h3>
            </TabsTrigger>
            <TabsTrigger
              value='supplementary'
              className='data-[state=active]:bg-blue-100 rounded-2xl min-w-[300px] md:w-1/2 lg:w-1/4'
            >
              <motion.div
                className='p-2 bg-blue-100 rounded-lg flex items-center mr-5'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <img src={Irrigation} alt='Tree Icon' className='w-6 h-6' />
              </motion.div>
              <h3 className='sm:text-base md:text-lg lg:text-xl text-gray-600 font-semibold landing-page'>
                {t('tle.supp')}
              </h3>
            </TabsTrigger>
          </TabsList>
          <hr className='w-full border-gray-300 mt-4' />

          <TabsContent value='fencing'>
            <Fencing />
          </TabsContent>
          <TabsContent value='tractor'>
            <TractorandImplements />
          </TabsContent>
          <TabsContent value='supplementary'>
            <SupplementaryIrrigationEnhancer />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  )
}