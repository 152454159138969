// src/features/landing/components/SubComponents/Generic/Data/QuicklinksData.ts

export interface QuickLinksData {
  id: number
  title: string
  url: string
}

export const links = (t: (key: string) => string): QuickLinksData[] => [
  {
    id: 1,
    title: t('footer.home'),
    url: '/'
  },
  {
    id: 2,
    title: t('footer.about'),
    url: '/about-temo-letlotlo/'
  },
  {
    id: 3,
    title: t('footer.cms'),
    url: '/temo-cms'
  },
  {
    id: 4,
    title: t('footer.login'),
    url: '/login/phone'
  },
  {
    id: 4,
    title: t('footer.privacy'),
    url: ''
  },
  {
    id: 4,
    title: t('footer.terms'),
    url: ''
  },
  {
    id: 4,
    title: 'Facebook',
    url: 'https://web.facebook.com/AgrinewsMagazineMoa'
  }
]