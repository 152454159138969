import { type ApiResponse, type ApiResponseWithObject } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

interface Bank {
  banks: BankNames[]
  bank_information: BankInformation[]
}

export interface BankNames {
  bank_id: number
  bank_name: string
}

export interface BankInformation {
  bank_id: number
  bank_name: string
  bank_branch_id: number
  bank_branch_name: string
  bank_branch_location: string
  bank_branch_contact_number: string | null
  branch_code: number
}

export const getBanksStaticData = async (): Promise<ApiResponse<Bank>> => {
  return axios.get(`/staticdata/bank-information`)
}

export const useGetBanksStaticData = (): UseQueryResult<
  ApiResponseWithObject<Bank>,
  ApiResponse<[]>
> => {
  return useQuery({
    queryFn: async () => getBanksStaticData()
  })
}
