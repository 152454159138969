import envelopeIcon from 'assets/landing/icons/email_icon.svg'
import enquiriesIcon from 'assets/landing/icons/enquiries_icon.svg'
import phoneIcon from 'assets/landing/icons/phoneIconTwo.svg'
import mapIcon from 'assets/landing/icons/pin.svg'

interface CardData {
  icon: string
  title: string
  description: string
  action: {
    text: string
    type: 'email' | 'phone' | 'link'
  }
}

export const cardsData = (t: (key: string) => string): CardData[] => {
  return [
    // {
    //   icon: envelopeIcon,
    //   title: t('con.us'),
    //   description: t('con.let'),
    //   action: {
    //     text: 'moa@gov.bw',
    //     type: 'email'
    //   }
    // },
    {
      icon: enquiriesIcon,
      title: t('con.temo'),
      description: t('con.speak'),
      action: {
        text: 'No email yet',
        type: 'email'
      }
    },
    {
      icon: phoneIcon,
      title: t('con.call'),
      description: t('con.mon'),
      action: {
        text: t('con.view'),
        type: 'phone'
      }
    },
    {
      icon: mapIcon,
      title: 'Visit us',
      description: 'Visit our office HQ.',
      action: {
        text: t('con.visitUs'),
        type: 'link'
      }
    }
  ]
}
