import type React from 'react'

interface HomelayoutProp {
  className?: string
  children: React.ReactNode
  variant?: 'body' | 'container' | 'bodyMax'
}

export const Layout: React.FC<HomelayoutProp> = ({
  children,
  variant = 'container',
  className
}) => {
  const classes = {
    body: 'w-full relative',
    bodyMax: 'w-full min-h-screen bg-background-dark flex flex-col items-center justify-start',
    container: 'max-w-[1500px] w-full mx-auto my-0 '
  }
  const customClass = `${classes[variant]} ${className || ''}`
  return <div className={customClass}>{children}</div>
}

