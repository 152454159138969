import { LoginOtpForm } from 'features/authentication/components/phone/LoginOtpForm'
import type React from 'react'
import { useEffect, useState } from 'react'
import { PhoneLoginForm } from './PhoneLoginForm'
import { Text } from 'components/ui'
import { useTranslation } from 'react-i18next'
import { useSuperTokens } from '../../hooks/useSuperTokens'
import { getLoginAttemptInfo } from 'supertokens-auth-react/recipe/passwordless'
import { useOtpStore } from '../../../../stores/useOtpStore'

interface PhoneLoginProps {
  className?: string
  onRegister: () => void
  onEmail: () => void
}

export const PhoneLogin: React.FC<PhoneLoginProps> = ({ className, onRegister, onEmail }) => {
  const [number, setNumber] = useState<string>('')
  const { number: otpNumber } = useOtpStore()
  const [showOtpForm, setShowOtpForm] = useState(false)
  const { sendOtp, isLoading } = useSuperTokens()
  const { t } = useTranslation('authenticationTranslation')

  const onLogin = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    event.preventDefault()
    await sendOtp(number)
  }

  async function hasInitialOTPBeenSent(): Promise<boolean> {
    return (await getLoginAttemptInfo()) !== undefined
  }

  useEffect(() => {
    async function checkOtpState(): Promise<void> {
      await hasInitialOTPBeenSent().then((res) => {
        setShowOtpForm(res)
      })
    }

    void checkOtpState()
  }, [hasInitialOTPBeenSent])

  if (showOtpForm) {
    return (
      <LoginOtpForm clearLogin={setShowOtpForm} number={otpNumber != null ? otpNumber : number} />
    )
  }

  return (
    <div className={'w-full max-w-[450px] flex flex-col gap-8 md:gap-8 xl:gap-12'}>
      <div className={'w-full flex flex-col sm:text-start ml-2 sm:ml-0'}>
        <Text size={'large'}>{t('phoneLogin.welcome')}</Text>
        <Text>
          {t('phoneLogin.continue')}
          <span className={'text-primary hidden sm:inline-block'}>Temo Crop Management System</span>
          <span className={'text-primary inline-block sm:hidden'}>Temo CMS</span>
        </Text>
      </div>
      <PhoneLoginForm
        onEmail={onEmail}
        onChange={setNumber}
        className={'w-full flex flex-col gap-4 sm:gap-8'}
        number={number}
        onRegister={onRegister}
        onLogin={onLogin}
        loading={isLoading}
      />
    </div>
  )
}
