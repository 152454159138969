import { useIndividualRegistrationStore } from 'features/authentication/stores/useIndividualRegistrationStore'
import type React from 'react'
import { AuthLayout } from '../layout/AuthLayout'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Text } from '../../../../components/ui'
import { ArrowRight } from 'lucide-react'

export const SuccessfulRegistration: React.FC = () => {
  const navigate = useNavigate()
  const { setContactDetails, setOmangDetails } = useIndividualRegistrationStore()
  const handleRegistration = (): void => {
    setContactDetails(null)
    setOmangDetails(null)
    navigate('/login/phone')
  }

  const { t } = useTranslation('authenticationTranslation')

  return (
    <AuthLayout
      className={'flex flex-col justify-center items-center gap-4 py-20 px-14 min-w-[20rem]'}
    >
      <div className='flex flex-col'>
        <h1 className='text-center text-lg'>{t('successful.welcome')}</h1>
      </div>

      <Text size={'body'} variant={'bodyTextLight'}>
        {t('successful.reg')}
      </Text>

      <Button onClick={handleRegistration} endIcon={<ArrowRight size={16} />}>
        {t('successful.sign')}
      </Button>
    </AuthLayout>
  )
}