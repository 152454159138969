/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { CustomAccordion } from 'features/loan-management/components/stepper'
import type React from 'react'
import type z from 'zod'
import { array, coerce, object, string, type TypeOf } from 'zod'
import { useFieldArray, useForm } from 'react-hook-form'
import { useAtom } from 'jotai'
import { individualLoanItem } from '../stores/individualLoanItem'

import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Text,
  useStepper
} from '../../../../../components/ui'
import { Box } from '../../../../../components/App/Layout'
import { Info } from 'lucide-react'
import type { LoanStepperProps } from '../../../types'
import { LoanStepperFooter } from '../../generic/LoanStepperFooter'
import { useTranslation } from 'react-i18next'
import { scaleType } from './LoanApplicationForm'
import { farmerType } from '../stores'

const immmovableSchema = object({
  age: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  encumbrances_amount: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  estimated_forces_sale_value: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  immovable_property_id: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  market_value: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  original_cost: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  })
})

export const schemaS = object({
  immovableProperty: array(immmovableSchema).nonempty('Field is required'),
  machineryAge: coerce
    .number({
      required_error: 'field is required',
      invalid_type_error: 'field is required'
    })
    .min(1, 'Field is required')
    .refine((value) => value <= 5, {
      message: 'Age should be 5 years or less'
    }),
  machineryOriginalCost: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  machineryMarketValue: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  machineryVehicleEstimatedForcedSaleVal: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  machineryVehicleAmountEncumb: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  machineryId: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  motorVehicleAge: coerce
    .number({
      required_error: 'field is required',
      invalid_type_error: 'field is required'
    })
    .refine((value) => value <= 5, {
      message: 'Age should be 5 years or less'
    }),
  motorVehicleOriginalCost: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  motorVehicleMarketValue: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  motorVehicleEstimatedForcedSaleVal: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  motorVehicleAmountEncumb: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  motorvehicleId: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  valueOfGurantee: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  valueOfOtherGurantee: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  sumAssured: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  fixedDeposit: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  estimations: string().min(1, 'Field is required')
})

export type SecurityDetailsInput = TypeOf<typeof schemaS>

export const Security: React.FC<LoanStepperProps> = ({ onComplete }) => {
  const [loanData, setLoanData] = useAtom(individualLoanItem)
  const [famerType] = useAtom(farmerType)
  const { nextStep } = useStepper()
  const { t } = useTranslation('loanManagement')
  const form = useForm<z.infer<typeof schemaS>>({
    resolver: zodResolver(schemaS),
    defaultValues: {
      immovableProperty: loanData?.loan_security?.immovable_property?.map((i) => ({
        age: i.age,
        immovable_property_id: i.immovable_property_id,
        estimated_forces_sale_value: i.estimated_forces_sale_value,
        original_cost: i.original_cost,
        market_value: i.market_value,
        encumbrances_amount: i.encumbrances_amount
      })),
      estimations: loanData?.loan_security?.basis_of_estimation?.basis_of_estimation,
      fixedDeposit: loanData?.loan_security?.loan_other_security?.fixed_deposit_balance,
      machineryAge: loanData?.loan_security?.loan_machinery?.age,
      machineryId: loanData?.loan_security?.loan_machinery?.machinery_id,
      machineryMarketValue: loanData?.loan_security?.loan_machinery?.market_value,
      machineryOriginalCost: loanData?.loan_security?.loan_machinery?.original_cost,
      machineryVehicleAmountEncumb: loanData?.loan_security?.loan_machinery?.encumbrances_amount,
      machineryVehicleEstimatedForcedSaleVal:
        loanData?.loan_security?.loan_machinery?.estimated_forces_sale_value,
      motorVehicleAge: loanData?.loan_security?.loan_motor_vehicle.age,
      motorVehicleAmountEncumb: loanData?.loan_security?.loan_motor_vehicle?.encumbrances_amount,
      motorVehicleEstimatedForcedSaleVal:
        loanData?.loan_security?.loan_motor_vehicle?.estimated_forces_sale_value,
      motorvehicleId: loanData?.loan_security?.loan_motor_vehicle?.vehicle_id,
      motorVehicleMarketValue: loanData?.loan_security?.loan_motor_vehicle?.market_value,
      motorVehicleOriginalCost: loanData?.loan_security?.loan_motor_vehicle?.original_cost,
      valueOfGurantee: loanData?.loan_security?.loan_bank_guarantee?.guarantee_value,
      valueOfOtherGurantee: loanData?.loan_security?.loan_other_guarantee?.guarantee_value,
      sumAssured: loanData?.loan_security?.loan_insurance_policy?.sum_assured
    }
  })

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'immovableProperty'
  })
  const addNewProperty = (): void => {
    append({
      immovable_property_id: 0,
      age: 0,
      estimated_forces_sale_value: 0,
      original_cost: 0,
      market_value: 0,
      encumbrances_amount: 0
    })
  }

  const onSubmit = (data: SecurityDetailsInput): void => {
    setLoanData((prev) => ({
      ...prev!,
      loan_security: {
        immovable_property: data.immovableProperty,
        loan_machinery: {
          age: data.machineryAge,
          original_cost: data.machineryOriginalCost,
          market_value: data.machineryMarketValue,
          estimated_forces_sale_value: data.machineryVehicleEstimatedForcedSaleVal,
          encumbrances_amount: data.machineryVehicleAmountEncumb,
          machinery_id: data.machineryId
        },
        loan_motor_vehicle: {
          age: data.motorVehicleAge,
          original_cost: data.motorVehicleOriginalCost,
          market_value: data.motorVehicleMarketValue,
          estimated_forces_sale_value: data.motorVehicleEstimatedForcedSaleVal,
          encumbrances_amount: data.motorVehicleAmountEncumb,
          vehicle_id: data.motorvehicleId
        },
        loan_bank_guarantee: { guarantee_value: data.valueOfGurantee },
        loan_other_guarantee: { guarantee_value: data.valueOfOtherGurantee },
        loan_insurance_policy: { sum_assured: data.sumAssured },
        loan_other_security: { fixed_deposit_balance: data.fixedDeposit },
        basis_of_estimation: {
          basis_of_estimation: data.estimations
        }
      }
    }))
    nextStep()
    if (onComplete != null) {
      onComplete(true)
    }
  }

  return (
    <Box>
      <Form {...form}>
        <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
          <div className='flex flex-col space-y-8 my-6 w-full'>
            <FormLabel className={'font-redHat'}>
              <Text size='medium' className=''>
                {t('sec.sec')}
              </Text>

              <div className='flex my-2'>
                <Info size='18' color='orange' className='mr-2.5' />
                <Text size='small' className=''>
                  {/* TODO make it yellow and large if they qualify else make it red */}
                  {famerType?.farmerType === scaleType
                    ? 'You do not Qualify for Security, Skip this step'
                    : t('sec.ppd')}
                </Text>
              </div>
            </FormLabel>
            <div className={'w-full'}>
              <CustomAccordion
                sections={[
                  {
                    label: t('sec.imm'),
                    fields: ['immovableProperty'],
                    fieldErrors: form.formState.errors,
                    children: (
                      <>
                        <div className='flex justify-end flex-[1_0_230px] capitalize'>
                          <Button variant={'primary'} size={'xs'} onClick={addNewProperty}>
                            {t('sec.new')}
                          </Button>
                        </div>

                        <FormField
                          control={form.control}
                          name={`immovableProperty`}
                          render={() => (
                            <FormItem className={'w-full mb-8'}>
                              {fields.map((field, index) => (
                                <div className='w-full px-2.5'>
                                  <div className='flex items-center mb-5'>
                                    <FormLabel className='font-redHat'>
                                      <Text size='body' className=''>{`${t('sec.pr')} ${
                                        index + 1
                                      }`}</Text>
                                    </FormLabel>
                                  </div>
                                  <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8'>
                                    <FormField
                                      control={form.control}
                                      name={`immovableProperty.${index}.immovable_property_id`}
                                      render={({ field: lwoField }) => (
                                        <>
                                          <FormItem className='flex flex-col'>
                                            <FormLabel className={'font-redHat'}>
                                              <Text size='body' className=''>
                                                {t('sec.pl')}
                                              </Text>
                                            </FormLabel>
                                            <FormControl>
                                              <Input
                                                classLabel={'bg-background-dark sm:bg-card'}
                                                placeholder={t('sec.pl')}
                                                type={'number'}
                                                inputMode={'numeric'}
                                                {...lwoField}
                                              />
                                            </FormControl>
                                            <FormMessage />
                                          </FormItem>
                                        </>
                                      )}
                                    />
                                    <FormField
                                      control={form.control}
                                      name={`immovableProperty.${index}.age`}
                                      render={({ field: lwoField }) => (
                                        <>
                                          <FormItem className='flex flex-col'>
                                            <FormLabel className={'font-redHat'}>
                                              <Text size='body' className=''>
                                                {t('sec.age')}
                                              </Text>
                                            </FormLabel>
                                            <FormControl>
                                              <Input
                                                classLabel={'bg-background-dark sm:bg-card'}
                                                placeholder={t('sec.age')}
                                                type={'number'}
                                                inputMode={'numeric'}
                                                {...lwoField}
                                              />
                                            </FormControl>
                                            <FormMessage />
                                          </FormItem>
                                        </>
                                      )}
                                    />
                                    <FormField
                                      control={form.control}
                                      name={`immovableProperty.${index}.original_cost`}
                                      render={({ field: lwoField }) => (
                                        <>
                                          <FormItem className='flex flex-col'>
                                            <FormLabel className={'font-redHat'}>
                                              <Text size='body' className=''>
                                                {t('sec.oc')} (BWP)
                                              </Text>
                                            </FormLabel>
                                            <FormControl>
                                              <Input
                                                classLabel={'bg-background-dark sm:bg-card'}
                                                placeholder={t('sec.oc')}
                                                type={'number'}
                                                inputMode={'numeric'}
                                                {...lwoField}
                                              />
                                            </FormControl>
                                            <FormMessage />
                                          </FormItem>
                                        </>
                                      )}
                                    />
                                    <FormField
                                      control={form.control}
                                      name={`immovableProperty.${index}.market_value`}
                                      render={({ field: lwoField }) => (
                                        <>
                                          <FormItem className='flex flex-col'>
                                            <FormLabel className={'font-redHat'}>
                                              <Text size='body' className=''>
                                                {t('sec.mv')} (BWP)
                                              </Text>
                                            </FormLabel>
                                            <FormControl>
                                              <Input
                                                classLabel={'bg-background-dark sm:bg-card'}
                                                placeholder={'(BWP)'}
                                                type={'number'}
                                                inputMode={'numeric'}
                                                {...lwoField}
                                              />
                                            </FormControl>
                                            <FormMessage />
                                          </FormItem>
                                        </>
                                      )}
                                    />

                                    <FormField
                                      control={form.control}
                                      name={`immovableProperty.${index}.estimated_forces_sale_value`}
                                      render={({ field: lwoField }) => (
                                        <>
                                          <FormItem className='flex flex-col'>
                                            <FormLabel className={'font-redHat'}>
                                              <Text size='body' className=''>
                                                {t('sec.efs')} (BWP)
                                              </Text>
                                            </FormLabel>
                                            <FormControl>
                                              <Input
                                                classLabel={'bg-background-dark sm:bg-card'}
                                                placeholder={'(BWP)'}
                                                type={'number'}
                                                inputMode={'numeric'}
                                                {...lwoField}
                                              />
                                            </FormControl>
                                            <FormMessage />
                                          </FormItem>
                                        </>
                                      )}
                                    />

                                    <FormField
                                      control={form.control}
                                      name={`immovableProperty.${index}.encumbrances_amount`}
                                      render={({ field: lwoField }) => (
                                        <>
                                          <FormItem className='flex flex-col'>
                                            <FormLabel className={'font-redHat'}>
                                              <Text size='body' className=''>
                                                {t('sec.ae')}
                                              </Text>
                                            </FormLabel>
                                            <FormControl>
                                              <Input
                                                classLabel={'bg-background-dark sm:bg-card'}
                                                placeholder={t('sec.ae')}
                                                type={'number'}
                                                inputMode={'numeric'}
                                                {...lwoField}
                                              />
                                            </FormControl>
                                            <FormMessage />
                                          </FormItem>
                                        </>
                                      )}
                                    />
                                  </div>
                                  <div className='w-full flex justify-end mt-8'>
                                    <Button
                                      color='error'
                                      variant={'secondary'}
                                      size={'xs'}
                                      onClick={() => {
                                        remove(index)
                                      }}
                                      disabled={index === 0}
                                    >
                                      {t('sec.rp')}
                                    </Button>
                                  </div>
                                </div>
                              ))}
                            </FormItem>
                          )}
                        />
                      </>
                    )
                  },
                  {
                    label: t('sec.me'),
                    fields: [
                      'machineryVehicleAmountEncumb',
                      'machineryId',
                      'machineryAge',
                      'machineryOriginalCost',
                      'machineryMarketValue',
                      'machineryVehicleEstimatedForcedSaleVal'
                    ],
                    fieldErrors: form.formState.errors,
                    children: [
                      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-2.5'>
                        <FormField
                          control={form.control}
                          name='machineryId'
                          render={({ field }) => (
                            <FormItem className='flex flex-col'>
                              <FormLabel className='font-redHat'>
                                <Text size='body' className=''>
                                  {t('sec.mid')}
                                </Text>
                              </FormLabel>
                              <FormControl>
                                <Input
                                  classLabel='bg-background-dark sm:bg-card'
                                  placeholder={t('sec.mid')}
                                  {...field}
                                  type='number'
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name='machineryAge'
                          render={({ field }) => (
                            <FormItem className='flex flex-col'>
                              <FormLabel className='font-redHat'>
                                <Text size='body' className=''>
                                  {t('sec.ma')}
                                </Text>
                              </FormLabel>
                              <FormControl>
                                <Input
                                  classLabel='bg-background-dark sm:bg-card'
                                  placeholder={t('sec.ma')}
                                  {...field}
                                  type='number'
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />

                        <FormField
                          control={form.control}
                          name='machineryOriginalCost'
                          render={({ field }) => (
                            <FormItem className='flex flex-col'>
                              <FormLabel className='font-redHat'>
                                <Text size='body' className=''>
                                  {t('sec.oc')} (BWP)
                                </Text>
                              </FormLabel>
                              <FormControl>
                                <Input
                                  classLabel='bg-background-dark sm:bg-card'
                                  placeholder='(BWP)'
                                  {...field}
                                  type='number'
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />

                        <FormField
                          control={form.control}
                          name='machineryMarketValue'
                          render={({ field }) => (
                            <FormItem className='flex flex-col'>
                              <FormLabel className='font-redHat'>
                                <Text size='body' className=''>
                                  {t('sec.om')} (BWP)
                                </Text>
                              </FormLabel>
                              <FormControl>
                                <Input
                                  classLabel='bg-background-dark sm:bg-card'
                                  placeholder='(BWP)'
                                  {...field}
                                  type='number'
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name='machineryVehicleEstimatedForcedSaleVal'
                          render={({ field }) => (
                            <FormItem className='flex flex-col'>
                              <FormLabel className='font-redHat'>
                                <Text size='body' className=''>
                                  {t('sec.efs')}(BWP)
                                </Text>
                              </FormLabel>
                              <FormControl>
                                <Input
                                  classLabel='bg-background-dark sm:bg-card'
                                  placeholder='(BWP)'
                                  {...field}
                                  type='number'
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />

                        <FormField
                          control={form.control}
                          name='machineryVehicleAmountEncumb'
                          render={({ field }) => (
                            <FormItem className='flex flex-col'>
                              <FormLabel className='font-redHat'>
                                <Text size='body' className=''>
                                  {t('sec.ae')}
                                </Text>
                              </FormLabel>
                              <FormControl>
                                <Input
                                  classLabel='bg-background-dark sm:bg-card'
                                  placeholder={t('sec.ae')}
                                  {...field}
                                  type='number'
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    ]
                  },
                  {
                    label: t('sec.mvc'),
                    fields: [
                      'motorVehicleAmountEncumb',
                      'motorvehicleId',
                      'motorVehicleAge',
                      'motorVehicleOriginalCost',
                      'motorVehicleMarketValue',
                      'motorVehicleEstimatedForcedSaleVal'
                    ],
                    fieldErrors: form.formState.errors,
                    children: [
                      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-2.5'>
                        <FormField
                          control={form.control}
                          name='motorvehicleId'
                          render={({ field }) => (
                            <FormItem className='flex flex-col'>
                              <FormLabel className='font-redHat'>
                                <Text size='body' className=''>
                                  {t('sec.mvr')}
                                </Text>
                              </FormLabel>
                              <FormControl>
                                <Input
                                  classLabel='bg-background-dark sm:bg-card'
                                  placeholder={t('sec.mvr')}
                                  {...field}
                                  type='number'
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />

                        <FormField
                          control={form.control}
                          name='motorVehicleAge'
                          render={({ field }) => (
                            <FormItem className='flex flex-col'>
                              <FormLabel className='font-redHat'>
                                <Text size='body' className=''>
                                  {t('sec.mva')}
                                </Text>
                              </FormLabel>
                              <FormControl>
                                <Input
                                  classLabel='bg-background-dark sm:bg-card'
                                  placeholder={t('sec.mva')}
                                  {...field}
                                  inputMode={'numeric'}
                                  type='number'
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />

                        <FormField
                          control={form.control}
                          name='motorVehicleOriginalCost'
                          render={({ field }) => (
                            <FormItem className='flex flex-col'>
                              <FormLabel className='font-redHat'>
                                <Text size='body' className=''>
                                  {t('sec.oc')}(BWP)
                                </Text>
                              </FormLabel>
                              <FormControl>
                                <Input
                                  classLabel='bg-background-dark sm:bg-card'
                                  placeholder='(BWP)'
                                  {...field}
                                  inputMode={'numeric'}
                                  type='number'
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />

                        <FormField
                          control={form.control}
                          name='motorVehicleMarketValue'
                          render={({ field }) => (
                            <FormItem className='flex flex-col'>
                              <FormLabel className='font-redHat'>
                                <Text size='body' className=''>
                                  {t('sec.om')} (BWP)
                                </Text>
                              </FormLabel>
                              <FormControl>
                                <Input
                                  classLabel='bg-background-dark sm:bg-card'
                                  placeholder='(BWP)'
                                  {...field}
                                  inputMode={'numeric'}
                                  type='number'
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />

                        <FormField
                          control={form.control}
                          name='motorVehicleEstimatedForcedSaleVal'
                          render={({ field }) => (
                            <FormItem className='flex flex-col'>
                              <FormLabel className='font-redHat'>
                                <Text size='body' className=''>
                                  {t('sec.efs')}(BWP)
                                </Text>
                              </FormLabel>
                              <FormControl>
                                <Input
                                  classLabel='bg-background-dark sm:bg-card'
                                  placeholder='(BWP)'
                                  {...field}
                                  inputMode={'numeric'}
                                  type='number'
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />

                        <FormField
                          control={form.control}
                          name='motorVehicleAmountEncumb'
                          render={({ field }) => (
                            <FormItem className='flex flex-col'>
                              <FormLabel className='font-redHat'>
                                <Text size='body' className=''>
                                  {t('sec.ae')}
                                </Text>
                              </FormLabel>
                              <FormControl>
                                <Input
                                  classLabel='bg-background-dark sm:bg-card'
                                  placeholder={t('sec.ae')}
                                  {...field}
                                  inputMode={'numeric'}
                                  type='number'
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    ]
                  },
                  {
                    label: t('sec.bg'),
                    fields: ['valueOfGurantee'],
                    fieldErrors: form.formState.errors,
                    children: [
                      <div className='p-2'>
                        <FormField
                          control={form.control}
                          name='valueOfGurantee'
                          render={({ field }) => (
                            <FormItem className='flex flex-col'>
                              <FormLabel className='font-redHat'>
                                <Text size='body' className=''>
                                  {t('sec.vg')} (BWP)
                                </Text>
                              </FormLabel>
                              <FormControl>
                                <Input
                                  classLabel='bg-background-dark sm:bg-card'
                                  placeholder='(BWP)'
                                  {...field}
                                  inputMode={'numeric'}
                                  type='number'
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    ]
                  },
                  {
                    label: 'Any Other Guarantee',
                    fields: ['valueOfOtherGurantee'],
                    fieldErrors: form.formState.errors,
                    children: [
                      <div className='p-2'>
                        <FormField
                          control={form.control}
                          name='valueOfOtherGurantee'
                          render={({ field }) => (
                            <FormItem className='flex flex-col'>
                              <FormLabel className='font-redHat'>
                                <Text size='body' className=''>
                                  {t('sec.vg')} (BWP){' '}
                                </Text>
                              </FormLabel>
                              <FormControl>
                                <Input
                                  classLabel='bg-background-dark sm:bg-card'
                                  placeholder='(BWP)'
                                  {...field}
                                  inputMode={'numeric'}
                                  type='number'
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    ]
                  },
                  {
                    label: t('sec.lip'),
                    fields: ['sumAssured'],
                    fieldErrors: form.formState.errors,
                    children: [
                      <div className='p-2'>
                        <FormField
                          control={form.control}
                          name='sumAssured'
                          render={({ field }) => (
                            <FormItem className='flex flex-col'>
                              <FormLabel className='font-redHat'>
                                <Text size='body' className=''>
                                  {t('sec.sac')}(BWP)
                                </Text>
                              </FormLabel>
                              <FormControl>
                                <Input
                                  classLabel='bg-background-dark sm:bg-card'
                                  placeholder='(BWP)'
                                  {...field}
                                  inputMode={'numeric'}
                                  type='number'
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    ]
                  },
                  {
                    label: t('sec.ofd'),
                    fields: ['fixedDeposit'],
                    fieldErrors: form.formState.errors,
                    children: [
                      <div className='p-2'>
                        <FormField
                          control={form.control}
                          name='fixedDeposit'
                          render={({ field }) => (
                            <FormItem className='flex flex-col mb-3'>
                              <FormLabel className='font-redHat'>{t('sec.fd')} (BWP)</FormLabel>
                              <FormControl>
                                <Input
                                  classLabel='bg-background-dark sm:bg-card'
                                  placeholder='(BWP)'
                                  {...field}
                                  inputMode={'numeric'}
                                  type='number'
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    ]
                  },
                  {
                    label: 'Basis  Of Your Estimation(As per stated security above)',
                    fields: ['estimations'],
                    fieldErrors: form.formState.errors,
                    children: [
                      <div className='p-2'>
                        <FormField
                          control={form.control}
                          name='estimations'
                          render={({ field }) => (
                            <FormItem className='flex flex-col'>
                              <FormLabel className='font-redHat'>
                                <Text size='body' className=''>
                                  {t('sec.bas')}
                                </Text>
                              </FormLabel>
                              <FormControl>
                                <Input
                                  classLabel='bg-background-dark sm:bg-card'
                                  placeholder={t('sec.bas')}
                                  {...field}
                                  type='text'
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    ]
                  }
                ]}
              />
            </div>
          </div>
          <LoanStepperFooter />
        </form>
      </Form>
    </Box>
  )
}
