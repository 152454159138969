import type React from 'react'
import { Button, Text } from 'components/ui'
import { useTranslation } from 'react-i18next'

interface ApproveCroppingPlanProps {
  label: string
  onApprove: () => void
  onCancel: () => void
  isLoading: boolean
}

export const ApproveCroppingPlan: React.FC<ApproveCroppingPlanProps> = ({
  label,
  onApprove,
  isLoading,
  onCancel
}) => {
  const { t } = useTranslation('extensionOfficer')
  return (
    <div className={'max-w-[400px] w-full flex flex-col items-center gap-8'}>
      <div className={'w-full flex flex-col items-center text-center'}>
        <Text size={'medium'}>
          {t('cp.approve')} {label}
        </Text>
        <Text>
          {t('cp.stc1')}
          {label}
          {t('cp.stc2')}
        </Text>
      </div>

      <div className={'w-full flex items-center justify-between gap-4'}>
        <Button
          variant={'secondary'}
          className='w-1/2 max-w-[18rem]'
          onClick={onCancel}
          disabled={isLoading}
        >
          {t('cp.cancel')}
        </Button>
        <Button
          variant={'success'}
          onClick={onApprove}
          loading={isLoading}
          disabled={isLoading}
          className='w-1/2 max-w-[18rem]'
        >
          {t('cp.confirm')}
        </Button>
      </div>
    </div>
  )
}