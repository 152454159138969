import {
  type ColumnDef,
  type ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type SortingState,
  useReactTable
} from '@tanstack/react-table'

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table'
import { DataTablePagination } from 'components/Data-Table/datatable-pagination'
import React, { useState } from 'react'
import { DataTableColumnFilter } from 'components/Data-Table/datatable-filter'
import { DataTableViewOptions } from 'components/Data-Table/datatable-viewoptions'
import type { SeedTransactionDetails } from '../../../../merchant/seed-dealer/types'
import type { ServiceTransactionDetails } from '../../../../merchant/service-provider/types'
import { getMerchantType } from '../../../../../utils/getMerchantTypes'
import { type FarmerVoucherTransaction } from '../../../../../types'
import { Dialog, DialogContent } from '../../../../../components/ui'
import { SeedsTable } from '../../../../merchant/seed-dealer/components/report/SeedsTable'
import { ServicesTable } from '../../../../merchant/service-provider/components/report/ServicesTable'
import { ChemicalsTableReport } from '../../../../merchant/chemical-dealer/components/reports/ChemicalsTableReport'
import type { ChemicalList, ChemicalTransaction } from '../../../../merchant/chemical-dealer/types'

interface DataTableProps<TData, TValue> {
  columns: Array<ColumnDef<TData, TValue>>
  data: TData[]
}

export function TransactionHistoryTable<TData, TValue>({
  columns,
  data
}: DataTableProps<TData, TValue>): React.ReactNode {
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const [open, setOpen] = useState(false)
  const [clickedData, setClickedData] = useState<
    SeedTransactionDetails[] | ServiceTransactionDetails[] | ChemicalList[]
  >([])
  const [merchant, setMerchant] = useState<string | null>(null)
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnFilters
    }
  })

  const openDialog = (): void => {
    setOpen(true)
  }

  const closeDialog = (): void => {
    setOpen(false)
  }

  const handleChemicalRowClick = (transaction: ChemicalTransaction): ChemicalList[] => {
    return [
      ...transaction.pesticide_list.map((item) => ({ ...item, type: 'Pesticide' })),
      ...transaction.herbicide_list.map((item) => ({ ...item, type: 'Herbicide' })),
      ...transaction.fungicide_list.map((item) => ({ ...item, type: 'Fungicide' })),
      ...transaction.adjuvant_list.map((item) => ({ ...item, type: 'Adjuvant' }))
    ]
  }

  return (
    <div className={'flex flex-col gap-4'}>
      <div className={'flex flex-col sm:flex-row gap-4 justify-start'}>
        <DataTableColumnFilter
          columnId={'merchant'}
          table={table}
          placeHolder={'filter history by merchants'}
        />
        <DataTableViewOptions table={table} />
      </div>
      <div className='rounded-md border'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={`header-${headerGroup.id}`}>
                {headerGroup.headers.map((header) => (
                  <TableHead
                    key={`header-${headerGroup.id}-${header.id}`}
                    className='text-center font-bold'
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length !== 0 ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={`row-${row.id}`}
                  className={'hover:cursor-pointer hover:bg-muted-hover'}
                  onClick={() => {
                    const transaction = row.original as FarmerVoucherTransaction
                    const merchantType = getMerchantType(transaction.merchant_type_id)
                    setMerchant(merchantType)

                    if (merchantType === 'seed-dealer') {
                      const seedsList = transaction.seed_list as SeedTransactionDetails[]
                      setClickedData(seedsList)
                    } else if (merchantType === 'service-provider') {
                      const serviceList = transaction.service_list as ServiceTransactionDetails[]
                      setClickedData(serviceList)
                    } else if (merchantType === 'agro-chemical') {
                      const chemicalData = row.original as ChemicalTransaction
                      setClickedData(handleChemicalRowClick(chemicalData))
                    }

                    // open dialog only when array has data
                    if (clickedData.length > 0) {
                      openDialog()
                    }
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={`cell-${row.id}-${cell.id}`}
                      className='text-center font-medium'
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className='h-24 text-center'>
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />

      <Dialog open={open} defaultOpen={false} modal onOpenChange={closeDialog}>
        <DialogContent
          className={
            'max-w-[80%] md:max-w-[60%] lg:max-w-[50%] overflow-scroll max-h-[90svh] md:max-h-[75svh] lg:max-h-[65svh]'
          }
        >
          {merchant === 'seed-dealer' ? (
            <SeedsTable seeds={clickedData} />
          ) : merchant === 'service-provider' ? (
            <ServicesTable clickedServices={clickedData} />
          ) : (
            <ChemicalsTableReport transactions={clickedData} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}
