import type React from 'react'
import fencingEnhancer from '../Data/Enhancers/FencingEnhancerData'
import TreeIcon from '../../../../../../assets/landing/icons/tree.svg'
import { useTranslation } from 'react-i18next'

interface Detail {
  title: string
  description: string
}

interface FencingType {
  title: string
  description: string
}

interface FencingEnhancer {
  detail: Detail[]
  fencingType: FencingType[]
}

export const Fencing: React.FC = () => {
  const { t } = useTranslation('landing')
  const { detail, fencingType } = fencingEnhancer(t) as FencingEnhancer

  return (
    <div className='w-full mt-16'>
      {/* Details */}
      <div className='flex flex-col justify-center items-start my-8'>
        <h2 className='text-base md:text-xl lg:text-3xl font-bold text-gray-700 landing-page'>
          {t('tle.sub')}
        </h2>
        <ul className='mt-4'>
          {detail.map((item: Detail, index: number) => (
            <li key={index} className='text-base md:text-xl lg:text-2xl text-gray-600 my-6'>
              <div className='flex flex-row items-start'>
                <img src={TreeIcon} alt='Tree Icon' className='w-6 h-6 mr-5' />
                <div className='flex flex-col'>
                  <h4 className='text-base md:text-xl lg:text-2xl font-bold text-gray-700 mb-1 landing-page'>
                    {item.title}
                  </h4>
                  <p className='text-base md:text-xl lg:text-2xl text-gray-600 landing-page'>
                    {item.description}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {/* Fencing Types */}
      <div className='flex flex-col justify-center items-start my-8'>
        <h2 className='text-base md:text-xl lg:text-3xl font-bold text-gray-700 landing-page'>
          {t('tle.crit')}
        </h2>
        <ul className='mt-4'>
          {fencingType.map((item: FencingType, index: number) => (
            <li key={index} className='text-base md:text-xl lg:text-2xl text-gray-600 my-6'>
              <div className='flex flex-row items-start'>
                <img src={TreeIcon} alt='Tree Icon' className='w-6 h-6 mr-5' />
                <div className='flex flex-col'>
                  <h4 className='text-base md:text-xl lg:text-2xl font-bold text-gray-700 mb-1 landing-page'>
                    {item.title}
                  </h4>
                  <p className='text-base md:text-xl lg:text-2xl text-gray-600 landing-page'>
                    {item.description}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}