import type React from 'react'
import { CheckCircle, HighlightOff, PendingActions } from '@mui/icons-material'
import { Badge, Text } from 'components/ui'
import { type GetInventoryRes } from '../../../farmer/inventory/types'
import { useTranslation } from 'react-i18next'

interface LabInventoryStatusProps {
  inventory: GetInventoryRes
  className: string
}

export const LabInventoryStatus: React.FC<LabInventoryStatusProps> = ({ inventory, className }) => {
  const { t } = useTranslation('inventory')
  if (inventory.item_verification_status === 'VERIFIED') {
    return <Badge variant={'success'}>{t('inventory.approved')}</Badge>
  }

  if (inventory.item_verification_status === 'TO BE REASSESSED') {
    return <Badge variant={'warning'}>{t('inventory.pending')}</Badge>
  }

  if (inventory.item_verification_status === 'REJECTED') {
    return <Badge variant={'destructive'}>{t('inventory.rejected')}</Badge>
  }

  return <Badge variant={'warning'}>{t('inventory.await')}</Badge>
}