import { type Dispatch, type SetStateAction } from 'react'
import { Chip, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface FilterMenuProps {
  all: number
  verified: number
  pending: number
  rejected: number
  reassesment: number
  onTransfer: number
  filterBy:
    | 'all'
    | 'VERIFIED'
    | 'PENDING_VERIFICATION'
    | 'REJECTED'
    | 'Transfer initiated'
    | 'TO BE REASSESSED'
  setFilterBy: Dispatch<
    SetStateAction<
      | 'all'
      | 'VERIFIED'
      | 'PENDING_VERIFICATION'
      | 'REJECTED'
      | 'Transfer initiated'
      | 'TO BE REASSESSED'
    >
  >
}

export const InventoryFilterMenu: React.FC<FilterMenuProps> = ({
  all,
  verified,
  pending,
  rejected,
  onTransfer,
  reassesment,
  setFilterBy,
  filterBy
}) => {
  const handleFilter = (
    type:
      | 'all'
      | 'VERIFIED'
      | 'PENDING_VERIFICATION'
      | 'REJECTED'
      | 'Transfer initiated'
      | 'TO BE REASSESSED'
  ): void => {
    setFilterBy(type)
  }
  const isSmallScreen = useMediaQuery('(max-width:1400px)')
  const { t } = useTranslation('inventory')

  return (
    <div className={'w-full flex justify-start items-center overflow-scroll'}>
      <Chip
        label={isSmallScreen ? `${t('nav.all')} (${all})` : `${t('nav.all')} (${all})`}
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'all' ? 'var(--primary)' : 'transparent',
          '&:hover': {
            backgroundColor: filterBy === 'all' ? 'var(--primary-hover)' : 'transparent'
          },
          padding: { xs: '0.2rem 0rem', sm: '0.2rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'all' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color: filterBy === 'all' ? 'var(--primary-foreground)' : 'var(--foreground)',
            '&:hover': {
              color: filterBy === 'all' ? 'var(--primary-foreground)' : 'var(--primary)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '13px',
              md: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('all')
        }}
        clickable={true}
      />

      <Chip
        label={
          isSmallScreen
            ? `${t('nav.verified')} (${verified})`
            : `${t('nav.verified')} (${verified})`
        }
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'VERIFIED' ? 'var(--success)' : 'transparent',
          '&:hover': {
            backgroundColor: filterBy === 'VERIFIED' ? 'var(--success-hover)' : 'transparent'
          },
          padding: { xs: '0.2rem 0rem', sm: '0.2rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'VERIFIED' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color: filterBy === 'VERIFIED' ? 'var(--success-foreground)' : 'var(--foreground)',
            '&:hover': {
              color: filterBy === 'VERIFIED' ? 'var(--success-foreground)' : 'var(--success)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('VERIFIED')
        }}
        clickable={true}
      />
      <Chip
        label={isSmallScreen ? `${t('nav.await')} (${pending})` : `${t('nav.await')} (${pending})`}
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'PENDING_VERIFICATION' ? 'var(--warning)' : 'transparent',
          '&:hover': {
            backgroundColor:
              filterBy === 'PENDING_VERIFICATION' ? 'var(--warning-hover)' : 'transparent'
          },
          padding: { xs: '0.2rem 0rem', sm: '0.2rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'PENDING_VERIFICATION' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color:
              filterBy === 'PENDING_VERIFICATION'
                ? 'var(--warning-foreground)'
                : 'var(--foreground)',
            '&:hover': {
              color:
                filterBy === 'PENDING_VERIFICATION' ? 'var(--warning-foreground)' : 'var(--warning)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '13px',
              md: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('PENDING_VERIFICATION')
        }}
        clickable={true}
      />

      <Chip
        label={
          isSmallScreen
            ? `${t('nav.rejected')} (${rejected})`
            : `${t('nav.rejected')} (${rejected})`
        }
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'REJECTED' ? 'var(--destructive)' : 'transparent',
          '&:hover': {
            backgroundColor: filterBy === 'REJECTED' ? 'var(--destructive-hover)' : 'transparent'
          },
          padding: { xs: '0.2rem 0rem', sm: '0.2rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'REJECTED' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color: filterBy === 'REJECTED' ? 'var(--destructive-foreground)' : 'var(--foreground)',
            '&:hover': {
              color:
                filterBy === 'REJECTED' ? 'var(--destructive-foreground)' : 'var(--destructive)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '13px',
              md: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('REJECTED')
        }}
        clickable={true}
      />

      <Chip
        label={
          isSmallScreen
            ? `${t('nav.pending')} (${reassesment})`
            : `${t('nav.pending')} (${reassesment})`
        }
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'TO BE REASSESSED' ? 'var(--warning)' : 'transparent',
          '&:hover': {
            backgroundColor:
              filterBy === 'TO BE REASSESSED' ? 'var(--warning-hover)' : 'transparent'
          },
          padding: { xs: '0.2rem 0rem', sm: '0.2rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'TO BE REASSESSED' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color:
              filterBy === 'TO BE REASSESSED' ? 'var(--warning-foreground)' : 'var(--foreground)',
            '&:hover': {
              color:
                filterBy === 'TO BE REASSESSED' ? 'var(--warning-foreground)' : 'var(--warning)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '13px',
              md: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('TO BE REASSESSED')
        }}
        clickable={true}
      />

      <Chip
        label={
          isSmallScreen
            ? `${t('nav.transfer')} (${onTransfer})`
            : `${t('nav.transfer')} (${onTransfer})`
        }
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'Transfer initiated' ? 'var(--success)' : 'transparent',
          '&:hover': {
            backgroundColor:
              filterBy === 'Transfer initiated' ? 'var(--success-hover)' : 'transparent'
          },
          padding: { xs: '0.2rem 0rem', sm: '0.2rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'Transfer initiated' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color:
              filterBy === 'Transfer initiated' ? 'var(--success-foreground)' : 'var(--foreground)',
            '&:hover': {
              color:
                filterBy === 'Transfer initiated' ? 'var(--success-foreground)' : 'var(--success)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '13px',
              md: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('Transfer initiated')
        }}
        clickable={true}
      />
    </div>
  )
}
