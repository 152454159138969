// SeasonalLoansPackageData.ts

/**
 * Interface representing the structure of a feature.
 */
interface Feature {
  title: string
  description: string
}

/**
 * Interface representing the structure of a requirement.
 */
interface Requirement {
  title: string
  description: string
}

/**
 * Interface representing the structure of an obligation.
 */
interface Obligation {
  title: string
  description: string
}

/**
 * Interface representing the structure of the program packages.
 */
interface ProgramPackages {
  programName: string
  programDescription: string
  keyFeatures: Feature[]
  eligibilityRequirements: Requirement[]
  coveredItems: string[]
  obligations: Obligation[]
}

/**
 * Constant containing the details of the seasonal loan program packages.
 */
const programPackages = (t: (key: string) => string): ProgramPackages => {
  return {
    programName: t('sl.name'),

    programDescription: t('sl.desc'),

    keyFeatures: [
      {
        title: t('sl.kt1'),
        description: t('sl.kd2')
      },
      {
        title: t('sl.kt2'),
        description: t('sl.kd2')
      },
      {
        title: t('sl.kt3'),
        description: t('sl.kd3')
      },
      {
        title: t('sl.kt4'),
        description: t('sl.kd4')
      },
      {
        title: t('sl.kt5'),
        description: t('sl.kd5')
      }
    ],

    eligibilityRequirements: [
      {
        title: t('sl.et1'),
        description: t('sl.ed1')
      },
      {
        title: t('sl.et2'),
        description: t('sl.ed2')
      },
      {
        title: t('sl.et3'),
        description: t('sl.ed3')
      }
    ],

    coveredItems: [t('sl.ci1'), t('sl.ci2'), t('sl.ci3')],

    obligations: [
      {
        title: t('sl.ot1'),
        description: t('sl.od1')
      },
      {
        title: t('sl.ot2'),
        description: t('sl.od2')
      }
    ]
  }
}

export default programPackages