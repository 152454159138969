import dayjs from 'dayjs'
import React from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import type z from 'zod'
import { boolean, coerce, object, string, type TypeOf } from 'zod'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Calendar,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text
} from 'components/ui'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { cn } from '../../../../lib/utils'
import { format } from 'date-fns'
import { CalendarIcon } from 'lucide-react'
import { SearchField } from '../../../../components/Form'
import { useMerchantKyc } from '../hooks/useMerchantKyc'
import { villageList } from 'features/authentication/utils/villages'
import { motion } from 'framer-motion'
import { Checkbox } from '../../../../components/ui/checkbox'
import { useMerchantStore } from '../../../../stores/useMerchantStore'

const omangSchema = object({
  omang: string()
    .min(9, 'Invalid omang number')
    .max(9, 'Invalid omang number')
    .regex(/[0-9]/, 'Omang should only contain numbers')
    .trim(),
  expiryDate: coerce.date({
    required_error: 'Date is required',
    invalid_type_error: 'Invalid date'
  }),
  surname: string().min(1, 'Surname is required').trim(),
  forenames: string().min(1, 'Forenames are required').trim(),
  placeOfBirth: string().min(1, 'Place of birth is required').trim(),
  dateOfBirth: coerce.date({
    required_error: 'Date is required',
    invalid_type_error: 'Invalid date'
  }),
  gender: string().min(1, 'Gender is required'),
  postalAddress: string().min(1, 'Postal Address is required'),
  physicalAddress: string().min(1, 'Physical Address is required'),
  terms: boolean()
    .default(false)
    .refine((value) => value, {
      message: 'Option is required.'
    })
})

export type OmangDetailsInput = TypeOf<typeof omangSchema>

export const OmangDetailsForm: React.FC = () => {
  const { merchant } = useMerchantStore()
  const { isLoading, verifyOmangDetails } = useMerchantKyc()

  const form = useForm<z.infer<typeof omangSchema>>({
    resolver: zodResolver(omangSchema),
    defaultValues: {
      omang: '',
      dateOfBirth: undefined,
      expiryDate: undefined,
      placeOfBirth: '',
      forenames: '',
      gender: undefined,
      surname: '',
      physicalAddress: merchant?.physical_address ?? '',
      postalAddress: merchant?.postal_address ?? '',
      terms: false
    }
  })
  const onSubmit: SubmitHandler<OmangDetailsInput> = (data: OmangDetailsInput) => {
    verifyOmangDetails(data)
  }

  const { t } = useTranslation('authenticationTranslation')

  return (
    <Form {...form}>
      <form className={'w-full flex flex-col gap-4'} onSubmit={form.handleSubmit(onSubmit)}>
        <Text size={'large'}>Temoletlotlo Self KYC Registration</Text>
        <Text variant={'bodyTextLight'} size={'medium'}>
          This is a mandatory compliance requirement for all Service Providers in Temo CMS.
        </Text>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Accordion type='single' collapsible className='w-full'>
            <AccordionItem value={`omang`}>
              <AccordionTrigger className='text-base md:text-xl lg:text-3xl font-bold text-gray-700 landing-page'>
                Omang Details
              </AccordionTrigger>
              <AccordionContent className={'flex flex-col gap-4 w-full'}>
                <FormField
                  control={form.control}
                  name={'omang'}
                  render={({ field }) => (
                    <FormItem className='flex flex-col'>
                      <FormLabel>Omang</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={`Omang`}
                          type={'number'}
                          inputMode={'numeric'}
                          classLabel={'bg-background-dark sm:bg-card'}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                ></FormField>
                <FormField
                  control={form.control}
                  name='dateOfBirth'
                  render={({ field }) => (
                    <FormItem className='flex flex-col'>
                      <FormLabel>{t('personalDetails.dob')}</FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={'outlined'}
                              className={cn(
                                'text-left font-normal',
                                field.value != null && 'text-muted-foreground'
                              )}
                            >
                              {field.value != null ? (
                                format(field.value, 'PPP')
                              ) : (
                                <span>{t('personalDetails.dob')}</span>
                              )}
                              <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className='w-auto p-0' align='start'>
                          <Calendar
                            initialFocus
                            mode='single'
                            captionLayout={'dropdown-buttons'}
                            fromYear={dayjs(new Date(Date.now())).toDate().getFullYear() - 120}
                            toDate={
                              new Date(
                                new Date(Date.now()).setFullYear(
                                  new Date(Date.now()).getFullYear() - 18
                                )
                              )
                            }
                            selected={field.value}
                            onSelect={field.onChange}
                            disabled={(date) =>
                              date >=
                              new Date(
                                new Date(Date.now()).setFullYear(
                                  new Date(Date.now()).getFullYear() - 18
                                )
                              )
                            }
                          />
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='expiryDate'
                  render={({ field }) => (
                    <FormItem className='flex flex-col'>
                      <FormLabel>{t('personalDetails.expiry')}</FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant={'outlined'}
                              className={cn(
                                'text-left font-normal',
                                field.value != null && 'text-muted-foreground'
                              )}
                            >
                              {field.value != null ? (
                                format(field.value, 'PPP')
                              ) : (
                                <span>{t('personalDetails.expiry')}</span>
                              )}
                              <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className='w-auto p-0' align='start'>
                          <Calendar
                            mode='single'
                            captionLayout={'dropdown-buttons'}
                            fromDate={dayjs(new Date(Date.now())).toDate()}
                            toYear={dayjs(new Date(Date.now())).toDate().getFullYear() + 10}
                            selected={field.value}
                            onSelect={field.onChange}
                            initialFocus
                          />
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name={'forenames'}
                  render={({ field }) => (
                    <FormItem className='flex flex-col'>
                      <FormLabel>{t('personalDetails.forename')}</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t('personalDetails.forename')}
                          classLabel={'bg-background-dark sm:bg-card'}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                ></FormField>
                <FormField
                  control={form.control}
                  name={'surname'}
                  render={({ field }) => (
                    <FormItem className='flex flex-col'>
                      <FormLabel>{t('personalDetails.surname')}</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t('personalDetails.surname')}
                          classLabel={'bg-background-dark sm:bg-card'}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                ></FormField>
                <SearchField
                  options={villageList}
                  defaultValue={form.getValues('placeOfBirth')}
                  label={t('personalDetails.pob')}
                  name={'placeOfBirth'}
                  control={form.control}
                  error={!(form.formState.errors.placeOfBirth == null)}
                  helperText={form.formState.errors?.placeOfBirth?.message ?? ''}
                />
                <FormField
                  control={form.control}
                  name='gender'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className={'ml-2 font-redHat'}>
                        {t('personalDetails.gender')}
                      </FormLabel>
                      <Select
                        defaultValue={form.getValues('gender')}
                        onValueChange={(val) => {
                          field.onChange(val)
                        }}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder={t('personalDetails.gender')} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value='M'>
                            <Text className='capitalize'>{t('personalDetails.male')}</Text>
                          </SelectItem>
                          <SelectItem value='F'>
                            <Text className='capitalize'>{t('personalDetails.female')}</Text>
                          </SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Accordion type='single' collapsible className='w-full'>
            <AccordionItem value={`address`}>
              <AccordionTrigger className='text-base md:text-xl lg:text-3xl font-bold text-gray-700 landing-page'>
                Addresses
              </AccordionTrigger>
              <AccordionContent className={'flex flex-col gap-4 w-full'}>
                <FormField
                  control={form.control}
                  name={'postalAddress'}
                  render={({ field }) => (
                    <FormItem className='flex flex-col'>
                      <FormLabel>{t('contactDetails.postal')}</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t('contactDetails.postal')}
                          classLabel={'bg-background-dark sm:bg-card'}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                ></FormField>
                <FormField
                  control={form.control}
                  name={'physicalAddress'}
                  render={({ field }) => (
                    <FormItem className='flex flex-col'>
                      <FormLabel>{t('contactDetails.physical')}</FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t('contactDetails.physical')}
                          classLabel={'bg-background-dark sm:bg-card'}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                ></FormField>{' '}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </motion.div>

        <FormField
          key={React.useId()}
          control={form.control}
          name='terms'
          render={({ field }) => (
            <FormItem className='flex flex-row items-start space-x-3 space-y-0 p-2'>
              <FormControl>
                <Checkbox checked={field.value} onCheckedChange={field.onChange} />
              </FormControl>
              <div className='space-y-1 leading-none'>
                <FormLabel>I do hereby agree to the terms and conditions.</FormLabel>
              </div>
            </FormItem>
          )}
        />

        <Button
          className={'w-full'}
          variant={'primary'}
          disabled={isLoading}
          loading={isLoading}
          type={'submit'}
        >
          Submit
        </Button>
      </form>
    </Form>
  )
}
