'use client'

import { zodResolver } from '@hookform/resolvers/zod'
import { format } from 'date-fns'
import { CalendarIcon } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { cn } from 'lib/utils'
import { Button, Calendar } from 'components/ui'
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from 'components/ui/form'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import type React from 'react'
import { DayPicker } from 'react-day-picker'

const FormSchema = z.object({
  dob: z.date({
    required_error: 'A date of birth is required.'
  })
})

// datepicker example with form
export const DatePicker: React.FC = () => {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema)
  })

  return (
    <FormField
      control={form.control}
      name='dob'
      render={({ field }) => (
        <FormItem className='flex flex-col'>
          <FormLabel>Date of birth</FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant={'outlined'}
                  className={cn(
                    'w-[240px] pl-3 text-left font-normal',
                    field.value != null && 'text-muted-foreground'
                  )}
                >
                  {field.value != null ? format(field.value, 'PPP') : <span>Pick a date</span>}
                  <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className='w-auto p-0' align='start'>
              <Calendar
                mode='single'
                selected={field.value}
                onSelect={field.onChange}
                disabled={(date) => date > new Date() || date < new Date('1900-01-01')}
                initialFocus
              />
            </PopoverContent>
          </Popover>
          <FormDescription>Your date of birth is used to calculate your age.</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
