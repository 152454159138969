import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface TransferLandDTO {
  og_farmer_id: number | null | undefined
  dest_farmer_id: number | null | undefined
  farm_id: string | number | null | undefined
  last_updated_by: number | null | undefined
}

export const transferFarm = async (params: TransferLandDTO): Promise<ApiResponse<[]>> => {
  return axios.post('/farm/edit/transfer', params)
}
