import { axios } from 'lib/axios'
import { type ApiResponse, type Lease } from 'types'

export interface GetLeaseDTO {
  farmerId: string
  extAreaId: number | null | undefined
}

export const getLease = async (
  farmerId: string,
  extAreaId: number | null | undefined
): Promise<ApiResponse<Lease>> => {
  return axios.get(`/lease?ORIGIN_FARMER_ID=${farmerId}&EXT_AREA_ID=${extAreaId ?? ''}`)
}
