import type React from 'react'
import { type CroppingPlan, CroppingPlanHeaderStatus } from 'types'
import { Text } from 'components/ui'
import { useTranslation } from 'react-i18next'

interface CroppingPlanStatusesProps {
  croppingPlan: CroppingPlan
}

export const CroppingPlanStatuses: React.FC<CroppingPlanStatusesProps> = ({ croppingPlan }) => {
  const { t } = useTranslation('croppingPlan')

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.Approved) {
    return (
      <Text size={'small'} variant={'success'}>
        {t('status.app')}
      </Text>
    )
  }

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.Rejected_CP_Verification) {
    return (
      <Text size={'small'} variant={'error'}>
        {t('status.cp')}
      </Text>
    )
  }

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.Rejected_Farm_Assessment_Verification) {
    return (
      <Text size={'small'} variant={'error'}>
        {t('status.ar')}
      </Text>
    )
  }

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.Awaiting_Farm_Assessment) {
    return (
      <Text size={'small'} variant={'warning'}>
        {t('status.aa')}
      </Text>
    )
  }

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.RAI_Farm_Assessment) {
    return (
      <Text size={'small'} variant={'warning'}>
        {t('status.ir')}
      </Text>
    )
  }

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.Submitted_For_Review) {
    return (
      <Text size={'small'} variant={'warning'}>
        {t('status.submit')}
      </Text>
    )
  }

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.RAI_CP_Verification) {
    return (
      <Text size={'small'} variant={'primaryLight'}>
        {t('status.ir')}
      </Text>
    )
  }

  return (
    <Text size={'small'} variant={'primaryLight'}>
      {t('status.draft')}
    </Text>
  )
}