import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

type SetAppToken = (appToken: string | undefined) => void

interface TokenStore {
  mmzwpzemib: string | undefined
  setAppToken: SetAppToken
  clearAppToken: () => void
}

const useTokenStore = create(
  persist<TokenStore>(
    (set, get) => ({
      mmzwpzemib: undefined,
      setAppToken: (mmzwpzemib: string | undefined) => {
        set((state) => ({ ...state, mmzwpzemib }))
      },
      clearAppToken: () => {
        set((state) => ({ ...state, appToken: undefined }))
      }
    }),
    {
      name: 'f8CBDZUbo0U9tMhmYsOWifSMf',
      storage: createJSONStorage(() => localStorage)
    }
  )
)

export const appToken = (): string | undefined => useTokenStore.getState().mmzwpzemib
export const setAppToken = (): SetAppToken => useTokenStore.getState().setAppToken
export const clearAppToken = (): (() => void) => useTokenStore.getState().clearAppToken
