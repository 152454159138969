import { discriminatedUnion, literal, object, z } from 'zod'
import type React from 'react'
import { useEffect } from 'react'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Loader,
  RadioGroup,
  RadioGroupItem,
  Text,
  useStepper
} from 'components/ui'
import { Error } from 'components/Errors/Error'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { StepperFooter } from '../Footer'
import { useCroppingPlanLandPreparation } from '../../../hooks/useCroppingPlanLandPreparation'
import { useGetLandPreparation } from '../../../api/land-preparation/getLandPreparation'
import { useTranslation } from 'react-i18next'
import { useGetWeeding } from '../../../api/weeding/getWeeding'

const fromBoolean = (val: boolean): 'Yes' | 'No' => {
  if (!val) {
    return 'No'
  }
  return 'Yes'
}

export const toBoolean = (val: 'Yes' | 'No' | undefined): boolean => {
  return val !== 'No'
}

const base = object({
  harrowing: z
    .enum(['Yes', 'No'], {
      required_error: 'You need to select whether you are Harrowing'
    })
    .optional()
})

export const landPreparationSchema = discriminatedUnion('technique', [
  object({
    technique: z.enum(['Minimum', 'Conventional'], {
      required_error: 'You need to select a tillage method'
    })
  }).merge(
    object({
      harrowing: z.enum(['Yes', 'No'], {
        required_error: 'You need to select whether you are Harrowing'
      })
    })
  ),
  object({
    technique: literal('No Till')
  }).merge(base)
])

export const LandPreparation: React.FC = () => {
  const { nextStep } = useStepper()
  const {
    header,
    setLandPreparation,
    createLandPreparationPlan,
    updateLandPreparationPlan,
    isLoading
  } = useCroppingPlanLandPreparation(nextStep)

  const { t } = useTranslation('croppingPlan')
  const {
    data: apiLandPreparation,
    isInitialLoading: apiIsLoading,
    error: landPreparationError
  } = useGetLandPreparation(header?.farmer_id, header?.cropping_plan_id)

  const {
    data: apiWeeding,
    isInitialLoading: apiWeedingIsLoading,
    error: weedingError
  } = useGetWeeding(header?.farmer_id, header?.cropping_plan_id)

  const form = useForm<z.infer<typeof landPreparationSchema>>({
    resolver: zodResolver(landPreparationSchema),
    mode: 'all',
    defaultValues: {
      technique: apiLandPreparation != null ? apiLandPreparation.data.tillage_method : undefined,
      harrowing:
        apiLandPreparation != null && apiLandPreparation.data.tillage_method !== 'No Till'
          ? fromBoolean(apiLandPreparation.data.harrowing)
          : undefined
    }
  })

  const onSubmit = (data: z.infer<typeof landPreparationSchema>): void => {
    // create new plan
    if (apiLandPreparation?.data == null) {
      createLandPreparationPlan(data)
    } else if (
      apiLandPreparation?.data.tillage_method !== data.technique ||
      apiLandPreparation?.data.harrowing !== toBoolean(data?.harrowing)
    ) {
      updateLandPreparationPlan(data)
    } else {
      nextStep()
    }
  }

  useEffect(() => {
    if (apiLandPreparation != null) {
      setLandPreparation(apiLandPreparation.data)
      form.reset({
        technique: apiLandPreparation.data.tillage_method,
        harrowing:
          apiLandPreparation.data.tillage_method !== 'No Till'
            ? fromBoolean(apiLandPreparation.data.harrowing)
            : undefined
      })
    }
  }, [apiLandPreparation, form.reset])

  if (header == null) {
    return (
      <div className={'grid place-items-center'}>
        <Text variant={'error'} size={'medium'}>
          Crop & Hectarage Are Required!
        </Text>
      </div>
    )
  }

  if (apiIsLoading || apiWeedingIsLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (landPreparationError != null) {
    if (landPreparationError?.response?.status !== 404) {
      return <Error />
    }
  }

  if (weedingError != null) {
    if (weedingError?.response?.status !== 404) {
      return <Error />
    }
  }

  const isNoTill = form.watch('technique') === 'No Till'

  const hasWeedingPlan =
    apiWeeding?.data != null
      ? apiWeeding?.data.chemicals?.find(
          (chemical) => chemical.chemical_schedule === 'Pre Planting'
        ) != null
      : false

  return (
    <Form {...form}>
      <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
        <div
          className={
            'flex flex-col p-4 gap-4 w-full sm:max-w-[60%] lg:max-w-[60%]' + ' xl:max-w-[40%]'
          }
        >
          <FormField
            control={form.control}
            name='technique'
            render={({ field }) => (
              <FormItem className='space-y-3'>
                <FormLabel>{t('land.tillage')}</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className='flex flex-col space-y-1'
                  >
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem value={'Minimum'} />
                      </FormControl>
                      <FormLabel className='font-normal'>{t('land.min')}</FormLabel>
                    </FormItem>
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem disabled={hasWeedingPlan} value={`Conventional`} />
                      </FormControl>
                      <FormLabel className='font-normal'>{t('land.con')}</FormLabel>
                    </FormItem>
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem value={`No Till`} />
                      </FormControl>
                      <FormLabel className='font-normal'>{t('land.none')}</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='harrowing'
            render={({ field }) => (
              <FormItem className='space-y-3'>
                <FormLabel>{t('land.harrow')}</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    disabled={isNoTill}
                    className='flex flex-col space-y-1'
                  >
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem value={`Yes`} />
                      </FormControl>
                      <FormLabel className='font-normal'>{t('land.yes')}</FormLabel>
                    </FormItem>
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem value={`No`} />
                      </FormControl>
                      <FormLabel className='font-normal'>{t('land.no')}</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <StepperFooter isLoading={isLoading} />
      </form>
    </Form>
  )
}
