import React from 'react'
import { features } from './Data/Why'
import { Card, CardDescription, CardHeader, CardTitle } from '../../../../../components/ui'
import { useTranslation } from 'react-i18next'

/**
 * A React functional component that renders a list of feature cards.
 * Each card displays an icon, heading, and description of a feature.
 *
 * @returns {JSX.Element} The rendered component.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const FeatureCards = () => {
  const { t } = useTranslation('landing')
  return (
    <div className='grid grid-cols-1 md:grid-cols-3 gap-6'>
      {features(t).map((feature, index) => {
        // Get the corresponding icon component for the feature
        return (
          <Card
            key={index}
            className='w-full lg:w-[400px] shadow-xl border-gray-300 rounded-[20px] bg-white'
          >
            <CardHeader className='flex flex-col items-start justify-start space-y-4'>
              <div className='w-20 h-20 rounded-lg p-4 bg-blue-100 flex items-center justify-center mb-8'>
                <img src={feature.icon} className='h-8 md:h-12 w-8 md:w-12 text-blue-500' />
              </div>
              <CardTitle className='text-center'>
                <h2 className='text-base md:text-lg lg:text-xl text-gray-700 font-semibold landing-page'>
                  {feature.heading}
                </h2>
              </CardTitle>
              <CardDescription className=''>
                <p className='text-xs md:text-sm lg:text-base text-gray-600 font-regular landing-page'>
                  {feature.description}
                </p>
              </CardDescription>
            </CardHeader>
          </Card>
        )
      })}
    </div>
  )
}

export default FeatureCards
