import type React from 'react'
import { Footer, NavLandingPage } from '../../features/landing/components'
import Session from 'supertokens-auth-react/recipe/session'
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles'
import Spinner from '../ui/spinner'
import { clearLoginAttemptInfo } from 'supertokens-web-js/lib/build/recipe/passwordless'
import { Outlet } from 'react-router-dom'

import { Button } from '../ui'
import { motion } from 'framer-motion'
export const NotFound: React.FC = () => {
  const claimValue = Session.useClaimValue(UserRoleClaim)

  if (claimValue.loading) {
    return (
      <div className='flex items-center justify-center w-screen h-screen'>
        <Spinner />
      </div>
    )
  }

  const roles = claimValue.value

  const handleNavigateToDashboard = (): string => {
    if (Array.isArray(roles) && roles.includes('FARMER')) {
      return '/farmer'
    } else if (Array.isArray(roles) && roles.includes('EXTENSION_OFFICER')) {
      return '/officer'
    } else if (Array.isArray(roles) && roles.includes('SOIL_SAMPLE_LAB')) {
      return '/soil-lab'
    } else if (Array.isArray(roles) && roles.includes('MECHANISATION_OFFICER')) {
      return '/inventory'
    } else if (Array.isArray(roles) && roles.includes('SEED_DEALER')) {
      return '/seed-dealer'
    } else if (Array.isArray(roles) && roles.includes('SERVICE_PROVIDER')) {
      return '/service-provider'
    } else {
      void clearLoginAttemptInfo()
      return '/login/phone'
    }
  }

  return (
    <main className='landing-page' role='alert'>
      <NavLandingPage
        goToDashboard={handleNavigateToDashboard}
        isAuthenticated={claimValue.value != null}
      />
      <div className='flex flex-col py-36 bg-white '>
        <div className='container mx-auto px-10 flex flex-col justify-center items-center w-screen md:w-full space-y-8  '>
          <motion.div
            className='flex justify-center items-center'
            initial={{ scale: 0 }}
            animate={{ scale: 1, x: [0, -5, 5, -5, 5, 0] }}
            transition={{ duration: 0.6 }}
          >
            <h1 className='text-[100px] md:text-[200px] lg:text-[300px] font-extrabold text-green-600 landing-page flex flex-col items-center relative'>
              404
            </h1>
          </motion.div>
          <h2 className='text-4xl md:text-5xl lg:text-6xl font-bold text-gray-700 text-center landing-page'>
            Page not found!
          </h2>
          <p className='text-base md:text-xl lg:text-2xl font-regular text-center text-gray-600 md:px-[10vw] lg:px-[20vw] xl:px-0 landing-page'>
            The page you are looking for does not exist.
          </p>

          <div className='flex justify-center space-x-8'>
            <Button
              variant='secondary'
              size='lg'
              className='w-fit p-3 rounded-lg'
              onClick={() => {
                window.history.back()
              }}
            >
              <p className='text-xs md:text-sm lg:text-base font-bold text-gray-800'>Go Back</p>
            </Button>
            <Button
              variant='primary'
              size='lg'
              className='w-fit p-3 rounded-lg'
              onClick={() => (window.location.href = '/')}
            >
              <p className='text-xs md:text-sm lg:text-base font-bold text-white'>Go Home</p>
            </Button>
          </div>
        </div>
      </div>
      <Outlet />
      <Footer />
    </main>
  )
}
