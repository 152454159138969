import { Grow } from '@mui/material'
import { ErrorPage } from 'components/Errors/ErrorPage'
import { NotFound } from 'components/Errors/NotFound'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Loader,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from '../../../../components/ui'
import { FarmInformation } from 'features/farmer/land-management/components/farm-details/FarmInformation'
import { FarmerInformation } from 'features/officer/farmers/components/FarmViewTabs/FarmerInformation'
import { LeaseHistory } from 'features/officer/farmers/components/FarmViewTabs/LeaseHistory'
import { useFarmers } from 'features/officer/farmers/hooks/useFarmers'
import React, { useEffect } from 'react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { useOfficerStore } from 'stores/useOfficerStore'
import { Box } from '../../../../components/App/Layout'
import { CroppingPlans } from '../components/cropping-plan/CroppingPlans'
import { OfficerSoilCorrection } from '../components/soil-correction/routes/OfficerSoilCorrection'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { NotAvailable } from '../../../../components/Layout/NotAvailable'
import { useTranslation } from 'react-i18next'

const getTabValue = (tab: string | null, enabled = false): string => {
  if (tab === 'Leases') {
    return 'leases'
  } else if (tab === 'Farm Developments') {
    return 'developments'
  } else if (tab === 'Soil Samples') {
    return 'ssr'
  } else if (tab === 'Cropping Plans') {
    return 'cp'
  } else if (tab === 'Grants' && enabled) {
    return 'grants'
  } else if (tab === 'Soil Correction') {
    return 'correction'
  }
  return 'information'
}

const getTabString = (tab: string | null, enabled = false): string => {
  if (tab === 'leases') {
    return 'leases'
  } else if (tab === 'developments') {
    return 'developments'
  } else if (tab === 'ssr') {
    return 'ssr'
  } else if (tab === 'cp') {
    return 'cp'
  } else if (tab === 'grants' && enabled) {
    return 'grants'
  } else if (tab === 'correction') {
    return 'correction'
  }
  return 'information'
}

export const FarmersFarmView: React.FC = () => {
  const { officer } = useOfficerStore()
  const { t } = useTranslation('extensionOfficer')
  const { farmId } = useParams<{ farmId: string }>()
  const { farmerId } = useParams<{ farmerId: string }>()
  const { isLoading, farm, farmer, error } = useFarmers(officer?.ext_area_id, farmerId, farmId)
  const flags = useFlags()
  const ldClient = useLDClient()
  const [searchParams, setSearchParams] = useSearchParams()
  const query = searchParams.get('tab')
  const [value, setValue] = React.useState(
    getTabString(
      query,
      farmer?.class_id != null && farmer?.class_id !== 'TAXPAYER' && flags.grant === true
    )
  )

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  const handleChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    const currentTab = getTabValue(
      event.currentTarget.textContent,
      farmer?.class_id != null && farmer?.class_id !== 'TAXPAYER' && flags.grant === true
    )
    setValue(currentTab)
    setSearchParams({ tab: currentTab })
  }

  if (isLoading) {
    return (
      <div className={'grid place-items-center h-full flex-1'}>
        <Loader size={36} />
      </div>
    )
  }

  if (error) {
    return <NotFound />
  }

  return (
    <Box>
      <Breadcrumb className={'border-b border-border p-4'}>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to={'/officer'}>{t('farm.farmers')}</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to={`/officer/farmers/${farmerId ?? ''}`}>{farmerId}</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{farm?.farm_name}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      {farm !== undefined && farmer !== undefined ? (
        <Grow in>
          <Tabs defaultValue={value} className='flex flex-col gap-2'>
            <TabsList
              className={
                'flex justify-between md:justify-center items-center min-w-full overflow-x-scroll px-4 lg:px-8'
              }
            >
              <TabsTrigger onClick={handleChange} value='information'>
                {t('farmView.info')}
              </TabsTrigger>
              <TabsTrigger onClick={handleChange} value='leases'>
                {t('farmView.leases')}
              </TabsTrigger>
              <TabsTrigger onClick={handleChange} value='developments'>
                {t('farmView.dev')}
              </TabsTrigger>
              <TabsTrigger onClick={handleChange} value='ssr'>
                {t('farmView.soil')}
              </TabsTrigger>
              <TabsTrigger onClick={handleChange} value='correction'>
                {t('farmView.correct')}
              </TabsTrigger>
              <TabsTrigger onClick={handleChange} value='cp'>
                {t('farmView.cp')}
              </TabsTrigger>
              {farmer?.class_id != null &&
              farmer?.class_id !== 'TAXPAYER' &&
              flags.grant === true ? (
                <TabsTrigger onClick={handleChange} value='grants'>
                  {t('farmView.grants')}
                </TabsTrigger>
              ) : null}
            </TabsList>

            <TabsContent value='information'>
              <FarmInformation farm={farm} />
              <FarmerInformation farmer={farmer} />
            </TabsContent>
            <TabsContent value='leases'>
              <LeaseHistory />
            </TabsContent>
            <TabsContent value='developments'>
              <NotAvailable />
            </TabsContent>
            <TabsContent value='ssr'>
              <NotAvailable />
            </TabsContent>
            <TabsContent value='correction'>
              <OfficerSoilCorrection />
            </TabsContent>
            <TabsContent value='cp'>
              <CroppingPlans />
            </TabsContent>
            {farmer?.class_id != null && farmer?.class_id !== 'TAXPAYER' && flags.grant === true ? (
              <TabsContent value='grants'>
                <NotAvailable />
              </TabsContent>
            ) : null}
          </Tabs>
        </Grow>
      ) : (
        <ErrorPage />
      )}
    </Box>
  )
}
