import { NotFound } from 'components/Errors/NotFound'
import { Unauthorized } from 'components/Errors/Unauthorized'
import { PhoneLoginPage, Register } from 'features/authentication/routes'
import { CompanyRegistrationPage } from 'features/authentication/routes/CompanyRegistrationPage'
import { EmailLoginPage } from 'features/authentication/routes/EmailLoginPage'
import { IndividualRegistrationPage } from 'features/authentication/routes/IndividualRegistrationPage'
import { ManualNavigation, UserAccess } from 'features/landing/components'
import { Homepage, UserManuals } from 'features/landing/routes'
import { ProtectedRoute } from '../features/authentication/components/ProtectedRoute'
import { SessionExpired } from '../components/Errors/SessionExpired'
import { AboutTemoLetlotlo } from '../features/landing/routes/AboutTemoLetlotlo'
import { TemoCms } from '../features/landing/routes/temo-cms'
import { Gallery } from '../features/landing/routes/Gallery'
import { ContactUs } from '../features/landing/routes/Contact'

export const publicRoutes = [
  {
    path: '/',
    element: <Homepage />
  },
  {
    path: '/user-manuals/',
    element: <UserManuals />,
    children: [
      { path: '', element: <ManualNavigation /> },
      { path: 'user-access-basics', element: <UserAccess /> }
    ]
  },
  {
    path: '/about-temo-letlotlo/',
    element: <AboutTemoLetlotlo />
  },
  {
    path: '/temo-cms/',
    element: <TemoCms />
  },
  {
    path: '/gallery/',
    element: <Gallery />
  },
  {
    path: '/contact-us',
    element: <ContactUs />
  },
  {
    path: '/login/phone',
    element: <ProtectedRoute children={<PhoneLoginPage />} />
  },
  {
    path: '/login/email',
    element: <ProtectedRoute children={<EmailLoginPage />} />
  },
  {
    path: '/registration',
    element: <ProtectedRoute children={<Register />} />
  },
  {
    path: '/registration/individual',
    element: <ProtectedRoute children={<IndividualRegistrationPage />} />
  },
  {
    path: '/registration/company',
    element: <ProtectedRoute children={<CompanyRegistrationPage />} />
  },
  {
    path: '/unauthorized',
    element: <Unauthorized />
  },
  {
    path: '/session',
    element: <SessionExpired />
  },
  {
    path: '/*',
    element: <NotFound />
  }
]
