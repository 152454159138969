import type React from 'react'
import { useState } from 'react'
import { useStaticDataStore } from '../../../../../../../stores/useStaticDataStore'
import { Error } from '../../../../../../../components/Errors/Error'
import { AdjuvantsTable } from '../tables/AdjuvantTable'
import { type Herbicides } from '../../../../../../../types/static'
import { adjuvantColumns } from '../tables/adjuvant-columns'
import type { WeedControl } from '../../../../../../../types'

interface AdjuvantSelectionProps {
  herbicides: Herbicides[]
  weeding: WeedControl | undefined
  onCancel: () => void
  onNext: () => void
}

export const AdjuvantSelection: React.FC<AdjuvantSelectionProps> = ({
  herbicides,
  onCancel,
  onNext,
  weeding
}) => {
  const { adjuvants } = useStaticDataStore()
  const [currentHerbicide, setCurrentHerbicide] = useState(0)

  if (adjuvants == null) {
    return <Error />
  }

  const handleNext = (): void => {
    if (currentHerbicide === herbicides.length - 1) {
      onNext()
    } else {
      setCurrentHerbicide((prevState) => prevState + 1)
    }
  }

  const handleBack = (): void => {
    if (currentHerbicide === 0) {
      onCancel()
    } else {
      setCurrentHerbicide((prevState) => prevState - 1)
    }
  }

  const returnComponent = (index: number): React.ReactNode => {
    return (
      <AdjuvantsTable
        key={index}
        next={handleNext}
        back={handleBack}
        herbicide={herbicides[index]}
        data={adjuvants}
        weeding={weeding}
        columns={adjuvantColumns}
      />
    )
  }

  return returnComponent(currentHerbicide)
}
