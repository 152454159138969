import type React from 'react'
import { useState } from 'react'
import { useAtom } from 'jotai'
import { useFieldArray, useForm } from 'react-hook-form'
import type z from 'zod'
import { array, object, string, type TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Phone,
  Text,
  useStepper
} from '../../../../../components/ui'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { Box } from '../../../../../components/App/Layout'
import { Info } from 'lucide-react'
import type { LoanStepperProps } from '../../../types'
import { LoanStepperFooter } from '../../generic/LoanStepperFooter'
import { useTranslation } from 'react-i18next'
import { companyLoanStore } from '../stores'

const auditors = object({
  bica_registration: string().min(1, 'Field is required'),
  postal_address: string().min(1, 'Field is required'),
  physical_address: string().min(1, 'Field is required'),
  telephone: string().min(1, 'Field is required'),
  fax: string().min(1, 'Field is required'),
  cell: string().min(1, 'Field is required')
})

const schemaAuditors = object({
  loan_auditors: array(auditors).nonempty('Field is required')
})

export type CompanyAuditorsInput = TypeOf<typeof schemaAuditors>

export const CompanyAuditors: React.FC<LoanStepperProps> = ({ onComplete }) => {
  const [loanData, setLoanData] = useAtom(companyLoanStore)
  const { nextStep } = useStepper()
  const { t } = useTranslation('loanManagement')
  const form = useForm<z.infer<typeof schemaAuditors>>({
    resolver: zodResolver(schemaAuditors),
    defaultValues: {
      loan_auditors: loanData?.loan_auditors?.map((item) => ({
        bica_registration: item.bica_registration,
        postal_address: item.postal_address,
        physical_address: item.physical_address,
        telephone: item.telephone,
        fax: item.fax,
        cell: item.cell
      }))
    }
  })

  const [telephones, setTelephones] = useState<string[]>([''])
  const [faxes, setFaxes] = useState<string[]>([''])
  const [cells, setCells] = useState<string[]>([''])

  const { farmer } = useFarmerStore()
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'loan_auditors'
  })
  const addOtherAuditors = (): void => {
    append({
      bica_registration: '',
      postal_address: '',
      physical_address: '',
      telephone: '',
      fax: '',
      cell: ''
    })
  }

  const onSubmit = (data: CompanyAuditorsInput): void => {
    setLoanData((prev) => ({
      ...prev!,
      loan_auditors: data.loan_auditors
    }))

    nextStep()

    if (onComplete != null) {
      onComplete(true)
    }
  }

  return (
    <Box>
      <Form {...form}>
        <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
          <div className='flex flex-col space-y-8 my-6 w-full'>
            <div className={'w-full flex flex-col gap-8'}>
              <div className={'flex items-center justify-between'}>
                <div className='flex flex-col gap-3'>
                  <Text size='medium' className=''>
                    Loan Auditors
                  </Text>

                  <div className='flex items-center mb-4'>
                    <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
                    <Text variant='secondary' size='small' className=''>
                      Add Loan Auditor
                    </Text>
                  </div>
                </div>

                <Button variant='secondary' size={'xs'} className='w-32' onClick={addOtherAuditors}>
                  {t('loan.new')}
                </Button>
              </div>

              <FormField
                control={form.control}
                name={`loan_auditors`}
                render={() => (
                  <FormItem className={'w-full mb-8'}>
                    {fields.map((field, index) => (
                      <>
                        <div className='flex items-center mb-8'>
                          <FormLabel className={'font-redHat'}>
                            <Text size='bodyBold' className=''>
                              {'Auditor '}
                              {`${index + 1}`}
                            </Text>
                          </FormLabel>
                        </div>
                        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                          <FormField
                            control={form.control}
                            name={`loan_auditors.${index}.bica_registration`}
                            render={({ field: lwoField }) => (
                              <>
                                <FormItem className='flex flex-col'>
                                  <FormLabel className={'font-redHat'}>
                                    <Text size='body' className=''>
                                      Bica registration
                                    </Text>
                                  </FormLabel>
                                  <FormControl>
                                    <Input
                                      classLabel={'bg-background-dark sm:bg-card'}
                                      placeholder={`Bica registration`}
                                      type={'text'}
                                      inputMode={'text'}
                                      {...lwoField}
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              </>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name={`loan_auditors.${index}.postal_address`}
                            render={({ field: lwoField }) => (
                              <>
                                <FormItem className='flex flex-col'>
                                  <FormLabel className={'font-redHat'}>
                                    <Text size='body' className=''>
                                      Postal Address
                                    </Text>
                                  </FormLabel>
                                  <FormControl>
                                    <Input
                                      classLabel={'bg-background-dark sm:bg-card'}
                                      placeholder={`Postal Address`}
                                      type={'text'}
                                      inputMode={'text'}
                                      {...lwoField}
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              </>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name={`loan_auditors.${index}.physical_address`}
                            render={({ field: lwoField }) => (
                              <div className='pt-2'>
                                <FormItem className='flex flex-col'>
                                  <FormLabel className={'font-redHat'}>
                                    <Text size='body' className=''>
                                      Physical Address
                                    </Text>
                                  </FormLabel>
                                  <FormControl>
                                    <Input
                                      classLabel={'bg-background-dark sm:bg-card'}
                                      placeholder={`Physical Address`}
                                      type={'text'}
                                      inputMode={'text'}
                                      {...lwoField}
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              </div>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name={`loan_auditors.${index}.telephone`}
                            render={({ field: lwoField }) => (
                              <>
                                <FormItem className='flex flex-col'>
                                  <FormLabel className={'font-redHat'}>
                                    <Text size='body' className=''>
                                      Telephone
                                    </Text>
                                  </FormLabel>
                                  <FormControl>
                                    <div className='relative'>
                                      <Phone
                                        onChange={(num) => {
                                          form.setValue(`loan_auditors.${index}.telephone`, num)
                                          setTelephones((prevPhones) => {
                                            const newPhones = [...prevPhones]
                                            newPhones[index] = num
                                            return newPhones
                                          })
                                        }}
                                        value={field.telephone}
                                      />
                                    </div>
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              </>
                            )}
                          />
                          <FormField
                            control={form.control}
                            name={`loan_auditors.${index}.fax`}
                            render={({ field: lwoField }) => (
                              <>
                                <FormItem className='flex flex-col'>
                                  <FormLabel className={'font-redHat'}>
                                    <Text size='body' className=''>
                                      Fax{' '}
                                    </Text>
                                  </FormLabel>
                                  <FormControl>
                                    <div className='relative'>
                                      <Phone
                                        onChange={(num) => {
                                          form.setValue(`loan_auditors.${index}.fax`, num)
                                          setFaxes((prevFaxes) => {
                                            const newFaxes = [...prevFaxes]
                                            newFaxes[index] = num
                                            return newFaxes
                                          })
                                        }}
                                        value={field.fax}
                                      />
                                    </div>
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              </>
                            )}
                          />

                          <FormField
                            control={form.control}
                            name={`loan_auditors.${index}.cell`}
                            render={({ field: lwoField }) => (
                              <>
                                <FormItem className='flex flex-col'>
                                  <FormLabel className={'font-redHat'}>
                                    <Text size='body' className=''>
                                      Cell{' '}
                                    </Text>
                                  </FormLabel>
                                  <FormControl>
                                    <div className='relative'>
                                      <Phone
                                        onChange={(num) => {
                                          form.setValue(`loan_auditors.${index}.cell`, num)
                                          setCells((prevCells) => {
                                            const newCells = [...prevCells]
                                            newCells[index] = num
                                            return newCells
                                          })
                                        }}
                                        value={field.cell}
                                      />
                                    </div>
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              </>
                            )}
                          />
                        </div>
                        <div className='w-full flex justify-end '>
                          <Button
                            color='error'
                            variant={'secondary'}
                            size={'xs'}
                            onClick={() => {
                              remove(index)
                            }}
                            disabled={index === 0}
                          >
                            {t('loan.remove')}
                          </Button>
                        </div>
                      </>
                    ))}
                  </FormItem>
                )}
              />
            </div>
          </div>

          <LoanStepperFooter />
        </form>
      </Form>
    </Box>
  )
}
