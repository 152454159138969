import { Link } from 'react-router-dom'
import moa from 'assets/landing/logos/moalogo.svg'
import brand_botswana from 'assets/logos/brandbotswana.png'
import arechencheng from 'assets/logos/mindsetchange.svg'

import type React from 'react'
import { events } from 'features/landing/components/SubComponents/Generic/Data/eventsData'
import { links } from 'features/landing/components/SubComponents/Generic/Data/QuicklinksData'
import { Copyright, Lightbulb } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { motion } from 'framer-motion'

export const Footer: React.FC = () => {
  const { t } = useTranslation('landing')
  return (
    <footer className={'w-full bg-gray-800 '}>
      {/* <div className='flex flex-col md:flex-row p-8'> */}
      <div className='grid grid-cols-1 md:grid-cols-3 container mx-auto py-20 px-10 gap-12'>
        {/* Section with brands and addresses */}
        <div className='col-span-1 md:col-span-1 flex flex-col space-y-8 mb-8'>
          <div className='flex flex-row space-x-4'>
            <Link to='/' className=''>
              <img
                src={moa}
                alt={'ministry of agriculture logo'}
                className='w-[clamp(3.5rem,15vw,7rem)]'
              />
            </Link>
            <div className='flex flex-col space-y-4'>
              <p className='text-xs md:text-sm text-white font-regular landing-page'>
                Ministry of Agriculture
              </p>
              <p className='text-xs md:text-sm text-white font-regular landing-page'>
                P / Bag 003 Gaborone, Botswana
              </p>
              <p className='text-xs md:text-sm text-white font-regular landing-page'>
                (+267) 368 9000
              </p>
            </div>
          </div>
          <div className='flex flex-col h-full d '>

            <Link
              to='https://brandbotswana.co.bw/'
              target='_blank'
              className='h-24 w-36 md:h-20 md:w-40  '
            >
              <img src={brand_botswana} alt={'Brand Botswana logo'} className='w-full ' />
            </Link>
            <Link
              to='https://brandbotswana.co.bw/'
              target='_blank'
              className='h-24 w-36 md:h-24 md:w-40  '
            >
              <img src={arechencheng} alt={'Brand Botswana logo'} className='w-full ' />
            </Link>
          </div>
        </div>
        {/* Upcoming Events */}
        <div className='col-span-1 md:col-span-1 flex flex-col'>
          <h4 className='text-sm md:text-base lg:text-lg text-white font-bold landing-page mb-8'>
            {t('footer.up')}
          </h4>

          <div className='flex flex-col space-y-4'>
            {events.length === 0 ? (
              <motion.div
                className='bg-white rounded-full p-2 flex items-center'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                whileHover={{ scale: 1.05 }}
              >
                <Lightbulb size={24} color='red' />
                <h4 className='text-xs md:text-lg text-gray-600 font-bold text-wrap mx-4 landing-page'>
                  {t('footer.no')}
                </h4>
              </motion.div>
            ) : (
              events.map((event) => (
                <motion.div
                  key={event.id}
                  className='bg-white rounded-full p-2 flex items-center'
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                  whileHover={{ scale: 1.05 }}
                >
                  <img src={event.icon} alt='Event Icon' className='w-4 md:w-8 h-4 md:h-8' />
                  <p className='text-xs md:text-sm text-gray-600 font-regular text-wrap mx-4 landing-page'>
                    {event.title}
                  </p>
                  <p className='text-xs md:text-sm text-gray-600 font-bold ml-auto landing-page'>
                    {event.date}
                  </p>
                </motion.div>
              ))
            )}
          </div>
        </div>
        {/* Quick Links */}
        <div className='col-span-1 md:col-span-1 flex flex-col'>
          <h4 className='text-sm md:text-base lg:text-lg text-white font-bold landing-page mb-8'>
            {t('footer.quick')}
          </h4>
          <div className='flex flex-col space-y-4'>
            {links(t).map((link) => (
              <Link key={link.id} to={link.url} className=' '>
                <p className='text-xs md:text-sm text-white font-regular text-wrap mx-4 landing-page'>
                  {link.title}
                </p>
              </Link>
            ))}
          </div>
        </div>

        <div className='col-span-full flex justify-center items-center gap-4 w-full'>
          <Copyright color='white' />
          <h5 className='text-xs md-text-sm font-bold text-white landing-page'>
            {moment().year()} Ministry of Agriculture. All rights reserved.
          </h5>
        </div>
      </div>
    </footer>
  )
}
