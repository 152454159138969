import type React from 'react'
import { EmailLoginForm } from './EmailLoginForm'
import { Text } from 'components/ui'
import { useTranslation } from 'react-i18next'

interface EmailLoginProps {
  className?: string
  onRegister: () => void
  onPhone: () => void
}

export const EmailLogin: React.FC<EmailLoginProps> = ({ className, onPhone, onRegister }) => {
  const { t } = useTranslation('authenticationTranslation')

  return (
    <div className={'w-full max-w-[450px] flex flex-col gap-8 md:gap-8 xl:gap-12'}>
      <div className={'w-full flex flex-col sm:text-start ml-2 sm:ml-0'}>
        <Text size={'large'}>{t('emailLogin.welcome')}</Text>
        <Text>
          {t('emailLogin.continue')}{' '}
          <span className={'text-primary hidden sm:inline-block'}>
            Temo Letlotlo Crop Management System
          </span>
          <span className={'text-primary inline-block sm:hidden'}>Temo CMS</span>
        </Text>
      </div>
      <EmailLoginForm
        className={'w-full flex flex-col gap-4 sm:gap-8'}
        onPhone={onPhone}
        onRegister={onRegister}
      />
    </div>
  )
}
