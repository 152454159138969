import type React from 'react'
import { motion } from 'framer-motion'
import statements from './Data/MissionData'
import TreeIcon from '../../../../../assets/landing/icons/tree.svg'
import { useTranslation } from 'react-i18next'

/**
 * OurMission component
 *
 * This component renders the "Our Mission" section of the landing page.
 * It displays a title and a list of mission statements, each accompanied by an icon.
 * The component uses Tailwind CSS classes for styling and layout.
 *
 * @returns {JSX.Element} The rendered "Our Mission" section
 */
export const OurMission: React.FC = () => {
  const { t } = useTranslation('landing')
  return (
    <div className='bg-blue-50 w-full '>
      <div className='container mx-auto flex flex-col  md:flex-row py-20 px-10 space-y-8  justify-center w-full '>
        <motion.div
          className='flex md:justify-center md:items-center md:w-1/2 '
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className='text-4xl md:text-5xl lg:text-6xl font-bold text-gray-700 landing-page'>
            {t('about.oMission')}
          </h2>
        </motion.div>
        <motion.div
          className='flex flex-col  justify-center items-center md:w-1/2'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <ul className=''>
            {statements(t).map((statement, index) => (
              <li key={index} className='flex text-lg text-gray-600 my-10'>
                <img src={TreeIcon} alt='Tree Icon' className='w-6 h-6 mr-5' />
                <p className='text-base md:text-xl lg:text-2xl text-gray-600 text-regular leading-relaxed tracking-wide ml-8 landing-page'>
                  {statement.text}
                </p>
              </li>
            ))}
          </ul>
        </motion.div>
      </div>
    </div>
  )
}