import React from 'react'
import { Button, Dialog, DialogContent, Text } from '../../../../../../components/ui'
import { Separator } from '../../../../../../components/ui/separator'
import { FarmTransfer } from './transfer-farm/FarmTransfer'
import type { Farm } from '../../../../../../types'
import { useTranslation } from 'react-i18next'

interface TransferFarmProps {
  farm: Farm
}

export const TransferFarm: React.FC<TransferFarmProps> = ({ farm }) => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false)
  const { t } = useTranslation('landManagement')

  const handleOpenDialog = (): void => {
    setOpenDialog(true)
  }

  const handleCloseDialog = (): void => {
    setOpenDialog(false)
  }

  return (
    <>
      <div className={'w-full flex flex-col gap-4'}>
        <Text size='medium'>{t('ownership.transferFarm')}</Text>
        <Separator />
        <Text variant='bodyTextLight' size='body'>
          {t('ownership.transferPrompt')}
        </Text>
        <Button variant='destructive' size='sm' className='w-24' onClick={handleOpenDialog}>
          {t('ownership.transfer')}
        </Button>
      </div>

      <Dialog
        key={'renew-lease'}
        open={openDialog}
        defaultOpen={false}
        modal
        onOpenChange={handleCloseDialog}
      >
        <DialogContent className={'max-w-[80%] md:max-w-[50%] lg:max-w-[35%]'}>
          <FarmTransfer farm={farm} onClose={handleCloseDialog} />
        </DialogContent>
      </Dialog>
    </>
  )
}