import type React from 'react'
import { Button, Text } from 'components/ui'
import { useTransferStore } from '../../../../stores/transferStore'
import { useTranslation } from 'react-i18next'

interface SubdivideDestinationFarmerConfirmationProps {
  next: () => void
  back: () => void
}

export const SubdivideDestinationFarmerConfirmation: React.FC<
  SubdivideDestinationFarmerConfirmationProps
> = ({ next, back }) => {
  const { destinationDetails } = useTransferStore()
  const { t } = useTranslation('landManagement')

  return (
    <div className={'w-full flex flex-col gap-4 md:gap-8'}>
      <Text size={'medium'} className={'text-center'} variant={'bodyText'}>
        {t('sub.confirm')}
      </Text>
      <Text size={'large'} className={'text-center'} variant={'primaryLight'}>
        {destinationDetails?.farmer_type === 2
          ? ` ${destinationDetails.company_name ?? 'N/A'}`
          : `${destinationDetails?.firstname?.trim().toUpperCase() ?? ''} ${
              destinationDetails?.surname?.trim().toUpperCase() ?? ''
            }`}
      </Text>
      <div className={'w-full flex justify-between gap-4 md:gap-8'}>
        <Button variant={'secondary'} onClick={back} className={'w-1/2'}>
          {t('sub.back')}
        </Button>
        <Button variant={'primary'} onClick={next} className={'w-1/2'}>
          {t('sub.next')}
        </Button>
      </div>
    </div>
  )
}
