import { CheckCircle } from '@mui/icons-material'
import type React from 'react'
import { Text } from '../ui'

interface CheckListItemProps {
  label: string
  isValid: boolean
}

export const CheckListItem: React.FC<CheckListItemProps> = ({ isValid, label }) => {
  return (
    <div className={'flex gap-4'}>
      <CheckCircle color={isValid ? 'success' : 'disabled'} />
      <Text size={'medium'}>{label}</Text>
    </div>
  )
}
