import { Typography } from '@mui/material'
import type React from 'react'
import { Toaster } from 'react-hot-toast'
import { useMerchantStore } from 'stores/useMerchantStore'

export const ServiceProvidersProfile: React.FC = () => {
  const { merchant } = useMerchantStore()

  return (
    <div className={'p-5'}>
      {/* Header */}
      <Toaster />
      {/* General Information */}
      <div className={'flex justify-start pt-10'}>
        <h1 className={'text-center text-2xl text-bold'}>General Information</h1>
      </div>
      <div className={'grid grid-cols-2 pt-4 gap-6'}>
        {/* Company Registration Number */}
        <div className={'flex flex-col justify-center'}>
          <Typography sx={{ fontSize: { xs: '2vw', md: '0.9vw' } }} color={'info.main'}>
            Company Registration Number
          </Typography>
          <Typography sx={{ fontSize: { xs: '3vw', md: '1.5vw' } }}>{merchant?.uin}</Typography>
        </div>

        {/* Provider Name */}
        <div className={'flex flex-col justify-center'}>
          <Typography sx={{ fontSize: { xs: '2vw', md: '0.9vw' } }} color={'info.main'}>
            Name
          </Typography>
          <Typography sx={{ fontSize: { xs: '3vw', md: '1.5vw' } }}>
            {merchant?.merchant_name}
          </Typography>
        </div>
        {/* Trade Name */}
        <div className={'flex flex-col justify-center'}>
          <Typography sx={{ fontSize: { xs: '2vw', md: '0.9vw' } }} color={'info.main'}>
            Licence No.
          </Typography>
          <Typography sx={{ fontSize: { xs: '3vw', md: '1.5vw' } }}>
            {merchant?.license_no}
          </Typography>
        </div>
        {/* Licence  Number */}
        <div className={'flex flex-col justify-center'}>
          <Typography sx={{ fontSize: { xs: '2vw', md: '0.9vw' } }} color={'info.main'}>
            Trade Name
          </Typography>
          <Typography sx={{ fontSize: { xs: '3vw', md: '1.5vw' } }}>
            {merchant?.trade_name}
          </Typography>
        </div>
      </div>
      {/* Divider */}
      <hr className={'mt-8 pb-4'} />
      {/* Contact Details */}
      <div className={'flex justify-start pt-10'}>
        <h1 className={'text-center text-2xl text-bold'}>Contacts</h1>
      </div>
      <div className={'grid grid-cols-2 pt-4 gap-6'}>
        <div className={'flex flex-col justify-center'}>
          <Typography sx={{ fontSize: { xs: '2vw', md: '0.9vw' } }} color={'info.main'}>
            Email Address
          </Typography>
          <Typography sx={{ fontSize: { xs: '3vw', md: '1.5vw' } }}>{merchant?.email}</Typography>
        </div>
        {/* phone */}
        <div className={'flex flex-col justify-center'}>
          <Typography sx={{ fontSize: { xs: '2vw', md: '0.9vw' } }} color={'info.main'}>
            Phone
          </Typography>
          <Typography sx={{ fontSize: { xs: '3vw', md: '1.5vw' } }}>{merchant?.contact}</Typography>
        </div>
        {/* Fax */}
        <div className={'flex flex-col justify-center'}>
          <Typography sx={{ fontSize: { xs: '2vw', md: '0.9vw' } }} color={'info.main'}>
            Fax
          </Typography>
          <Typography sx={{ fontSize: { xs: '3vw', md: '1.5vw' } }}>{merchant?.contact}</Typography>
        </div>
        {/* Mobile */}
        <div className={'flex flex-col justify-center'}>
          <Typography sx={{ fontSize: { xs: '2vw', md: '0.9vw' } }} color={'info.main'}>
            Mobile
          </Typography>
          <Typography sx={{ fontSize: { xs: '3vw', md: '1.5vw' } }}>{merchant?.contact}</Typography>
        </div>
      </div>
      {/* Divider */}
      <hr className={'mt-8 pb-4'} />
      {/* address */}
      <div className={'flex justify-start pt-10'}>
        <h1 className={'text-center text-2xl text-bold'}>Addresses</h1>
      </div>
      <div className={'grid grid-cols-2 pt-4 gap-6'}>
        <div className={'flex flex-col justify-center'}>
          <Typography sx={{ fontSize: { xs: '2vw', md: '0.9vw' } }} color={'info.main'}>
            Postal Address
          </Typography>
          <Typography sx={{ fontSize: { xs: '3vw', md: '1.5vw' } }}>
            {merchant?.postal_address}
          </Typography>
        </div>

        <div className={'flex flex-col justify-center'}>
          <Typography sx={{ fontSize: { xs: '2vw', md: '0.9vw' } }} color={'info.main'}>
            Physical Address
          </Typography>
          <Typography sx={{ fontSize: { xs: '3vw', md: '1.5vw' } }}>
            {merchant?.physical_address}
          </Typography>{' '}
        </div>
      </div>
    </div>
  )
}
