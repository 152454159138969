import type { ApiResponse } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { type SoilCorrection } from 'types/soilCorrection'

export const getOfficerSoilCorrection = async (
  farmer_id: string | number | null | undefined,
  extAreaId: number | null | undefined,
  farmId?: string | number | null | undefined,
  leaseId?: string | number | null | undefined
): Promise<ApiResponse<SoilCorrection>> => {
  if (leaseId != null) {
    return axios.get(
      `soilcorrection?ext_area_id=${extAreaId ?? ''}&farmer_id=${farmer_id ?? ''}&lease_id=${
        leaseId ?? ''
      }`
    )
  }
  return axios.get(
    `soilcorrection?ext_area_id=${extAreaId ?? ''}&farmer_id=${farmer_id ?? ''}&farm_id=${
      farmId ?? ''
    }`
  )
}

export const useGetOfficerSoilCorrection = (
  farmer_id: string | number | null | undefined,
  extAreaId: number | null | undefined,
  farmId?: string | number | null | undefined,
  leaseId?: string | number | null | undefined
): UseQueryResult<ApiResponse<SoilCorrection>, AxiosError> => {
  return useQuery({
    queryKey: ['soil-correction', farmId, leaseId],
    queryFn: async () => getOfficerSoilCorrection(farmer_id, extAreaId, farmId, leaseId),
    enabled:
      (farmer_id != null && farmId != null && extAreaId != null) ||
      (farmer_id != null && leaseId != null && extAreaId != null)
  })
}
