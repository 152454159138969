import type React from 'react'
import { Card } from '../../../../../components/ui'

interface LogoCardProps {
  src: string
  alt: string
  link: string
}

export const LogoCard: React.FC<LogoCardProps> = ({ src, alt, link }) => {
  const handleClick = () => {
    window.open(link, '_blank', 'noopener,noreferrer')
  }

  return (
    <Card
      className='w-full max-w-md mx-auto p-6 flex items-center justify-center rounded-xl border-white cursor-pointer bg-white'
      onClick={handleClick}
    >
      <div className='flex items-center justify-center w-full h-28 lg:h-40'>
        <img
          className='max-w-full max-h-full object-contain rounded-lg h-fit lg:h-40'
          src={src}
          alt={alt}
        />
      </div>
    </Card>
  )
}
