import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError, type AxiosResponse } from 'axios'
import {
  type CreateInventoryItemDTO,
  type Reassessment,
  type UpdateInventoryItemDTO
} from '../../../../farmer/inventory/types'
import { createInventoryItem } from '../../../../farmer/inventory/api/createInventoryItem'
import { updateInventoryItem } from '../../../../farmer/inventory/api/updateInventoryItem'
import { toast } from '../../../../../components/ui'
import { type ApiResponse } from '../../../../../types'
import { mapCmsErrorToMessage } from '../../../../../utils/apiErrors'
import { useNavigate } from 'react-router-dom'
import { useMerchantStore } from '../../../../../stores/useMerchantStore'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { requestReassessment } from '../api/reassessment'

interface UseInventoryItem {
  isLoading: boolean
  createItem: (params: CreateInventoryItemDTO) => void
  updateItem: (params: UpdateInventoryItemDTO) => void
  reassessment: (inventoryId: number) => void
}

export const useInventoryItem = (): UseInventoryItem => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { merchant } = useMerchantStore()
  const { farmer } = useFarmerStore()

  const createItemMutation = useMutation({
    mutationFn: async (params: CreateInventoryItemDTO) => {
      return createInventoryItem(params)
    }
  })

  const updateItemMutation = useMutation({
    mutationFn: async (params: UpdateInventoryItemDTO) => {
      return updateInventoryItem(params)
    }
  })

  const reassessmentMutation = useMutation({
    mutationFn: async (params: Reassessment) => {
      return requestReassessment(params)
    }
  })

  const reassessment = (inventoryId: number): void => {
    reassessmentMutation.mutate(
      {
        inventory_id: inventoryId,
        ...(merchant != null
          ? { merchant_id: Number(merchant?.merchant_id) }
          : { farmer_id: Number(farmer?.farmer_id) }),
        last_updated_by: merchant != null ? merchant?.user_id : farmer?.user_id
      },
      {
        onSuccess: (resp) => {
          if (resp.success && resp.status === 201) {
            toast({
              variant: 'success',
              title: 'Re-Assessment successfully requested.'
            })
            void queryClient.invalidateQueries({
              queryKey: ['inventories', inventoryId != null]
            })
            if (merchant != null) {
              navigate('/service-provider/inventory')
            } else if (farmer != null) {
              navigate('/farmer/inventory')
            }
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response as AxiosResponse<ApiResponse<[]>>

            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          }
        }
      }
    )
  }

  const createItem = (params: CreateInventoryItemDTO): void => {
    createItemMutation.mutate(params, {
      onSuccess: (resp) => {
        if (resp.success && resp.status === 201) {
          toast({
            variant: 'success',
            title: 'Inventory item created successfully.'
          })
          void queryClient.invalidateQueries({
            queryKey: [
              `inventories-${
                params.farmer_id != null ? params.farmer_id : params.merchant_id ?? ''
              }`,
              'transfer-request'
            ]
          })
          if (merchant != null) {
            navigate('/service-provider/inventory')
          } else if (farmer != null) {
            navigate('/farmer/inventory')
          }
        }
      },
      onError: (err) => {
        if (err instanceof AxiosError) {
          const error = err.response as AxiosResponse<ApiResponse<[]>>

          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: mapCmsErrorToMessage(
              error.status.toString() ?? err.code ?? error.data.message ?? ''
            )
          })
        }
      }
    })
  }

  const updateItem = (params: UpdateInventoryItemDTO): void => {
    updateItemMutation.mutate(params, {
      onSuccess: (resp) => {
        if (resp.success && resp.status === 201) {
          toast({
            variant: 'success',
            title: 'Inventory item updated successfully.'
          })
          void queryClient.invalidateQueries({
            queryKey: [
              `inventories-${
                params.farmer_id != null ? params.farmer_id : params.merchant_id ?? ''
              }`,
              'transfer-request'
            ]
          })
          if (merchant != null) {
            navigate('/service-provider/inventory')
          } else if (farmer != null) {
            navigate('/farmer/inventory')
          }
        }
      },
      onError: (err) => {
        if (err instanceof AxiosError) {
          const error = err.response as AxiosResponse<ApiResponse<[]>>

          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: mapCmsErrorToMessage(
              error.status.toString() ?? err.code ?? error.data.message ?? ''
            )
          })
        }
      }
    })
  }

  return {
    isLoading:
      createItemMutation.isLoading ||
      updateItemMutation.isLoading ||
      reassessmentMutation.isLoading,
    createItem,
    updateItem,
    reassessment
  }
}
