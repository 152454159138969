import type React from 'react'
import { useState } from 'react'
import {
  Button,
  Card,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from 'components/ui'
import { districtContactsData } from './Data/DistrictsData'
import { cardsData } from './Data/ContactTypeData'
import { useTranslation } from 'react-i18next'

interface CardData {
  icon: string
  title: string
  description: string
  action: {
    text: string
    type: 'email' | 'phone' | 'link'
  }
}

export const ContactCard: React.FC<{ card: CardData }> = ({ card }): JSX.Element => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const getActionHref = (action: CardData['action']): string | (() => void) => {
    switch (action.type) {
      case 'email':
        return `mailto:${action.text}`
      case 'phone':
        return () => {
          setIsDialogOpen(true)
        }
      case 'link':
        return 'https://maps.app.goo.gl/ZJqNhw7AVv53QYaC9'
      default:
        return '#'
    }
  }

  return (
    <>
      <Card className='w-[300px] lg:w-[350px] shadow-xl border-gray-300 rounded-[20px] bg-white'>
        <CardHeader className='flex space-y-4'>
          <div className='w-20 h-20 rounded-lg p-4 bg-blue-100 flex items-center justify-center mb-8'>
            <img src={card.icon} className='h-8 md:h-12 w-8 md:w-12  text-blue-500' />
          </div>
          <CardTitle className=''>
            <h2 className='text-base md:text-lg lg:text-xl text-gray-700 font-semibold landing-page'>
              {card.title}
            </h2>
          </CardTitle>
          <CardDescription>
            <p className='text-xs md:text-sm lg:text-base text-gray-600 font-regular landing-page'>
              {card.description}
            </p>
          </CardDescription>
        </CardHeader>
        <CardFooter className='pl-4'>
          <Button variant='link' asChild>
            <a
              href={card.action.type === 'phone' ? '#' : (getActionHref(card.action) as string)}
              onClick={
                card.action.type === 'phone'
                  ? (getActionHref(card.action) as () => void)
                  : undefined
              }
            >
              <p className='text-xs md:text-sm lg:text-base text-blue-800 font-semibold landing-page'>
                {card.action.text}
              </p>
            </a>
          </Button>
        </CardFooter>
      </Card>

      <Dialog
        open={isDialogOpen}
        onOpenChange={() => {
          setIsDialogOpen(false)
        }}
      >
        <DialogContent className='bg-white w-full max-w-sm md:max-w-2xl lg:max-w-6xl p-4 md:p-8 lg:p-12 max-h-[60vh] overflow-y-auto'>
          <DialogHeader className='flex items-center text-center'>
            <DialogTitle>
              <h3 className='text-xl md:text-3xl lg:text-4xl font-bold text-gray-700 landing-page'>
                Phone Numbers by District
              </h3>
            </DialogTitle>
          </DialogHeader>
          <div className='p-4'>
            {districtContactsData.map((contact) => (
              <Card
                key={contact.district}
                className='w-full flex flex-row mb-4 border-gray-300 rounded-[20px] bg-white'
              >
                <div className='basis-1/2'>
                  <h4 className='text-base md:text-lg lg:text-xl text-gray-600 font-semibold'>
                    {contact.district}
                  </h4>
                </div>
                <div className='basis-1/2 text-center'>
                  <a
                    href={`tel:${contact.directLine}`}
                    className='text-base md:text-lg lg:text-xl font-regular text-gray-600'
                  >
                    {contact.directLine}
                  </a>
                </div>
              </Card>
            ))}
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

const ContactCardsList: React.FC = (): JSX.Element => {
  const { t } = useTranslation('landing')
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 '>
      {cardsData(t).map((card, index) => (
        <ContactCard key={index} card={card} />
      ))}
    </div>
  )
}

export default ContactCardsList
