import { CustomCheckBoxGroup } from 'features/loan-management/components/generic/form'
import type React from 'react'
import { useState } from 'react'
import { Text } from 'components/Elements/Text/Text'
import { type LoanStepperProps } from '../../../types'
import { Separator, useStepper } from '../../../../../components/ui'
import { Box } from '../../../../../components/App/Layout'
import { LoanStepperFooter } from '../../generic/LoanStepperFooter'

export const CompanyAttachments: React.FC<LoanStepperProps> = ({ onComplete }) => {
  const { nextStep } = useStepper()
  const listOfAttachments = [
    'Confirmation of membership of Grain producer’s association.',
    'One (1) certified copies of National Identity Cards (Omang), for all applicants, Shareholders & Directors.',
    'A copy of residence permits & passports (Non-citizens employees).',
    'If Applicable, a copy of Marriage Certificate, proof of Marriage Regime & Letter of consent.',
    'Provide Tax Clearance Certificate and TIN number, or Acknowledgment of Application (Letter provided pending certificate)',
    'Provide Soil Test results from accredited Laboratory',
    'Provide lease agreement / Title Deed where appropriate / Land Board Certification.',
    'Where applicant has loans with other financial institutions - provide 6 months loan statements.',
    'Proof of Physical Address, i.e. Utility Bills.'
  ]

  const [checkedCheckboxes, setCheckedCheckboxes] = useState<boolean[]>(
    new Array(listOfAttachments.length).fill(false)
  )
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false)

  const handleCheckboxChange = (index: number): void => {
    const updatedCheckboxes = [...checkedCheckboxes]
    updatedCheckboxes[index] = !updatedCheckboxes[index]
    setCheckedCheckboxes(updatedCheckboxes)

    // Update "Select All" checkbox status based on individual checkbox changes
    setIsSelectAllChecked(updatedCheckboxes.every((isChecked) => isChecked))
  }

  const handleSelectAllChange = (): void => {
    const newState = !isSelectAllChecked
    setIsSelectAllChecked(newState)
    setCheckedCheckboxes(new Array(listOfAttachments.length).fill(newState))
  }

  const isContinueButtonDisabled = !checkedCheckboxes.every(
    (isChecked: boolean, index: number) => Boolean(isChecked) || index === 3 || index === 7
  )

  const onSubmit = (): void => {
    nextStep()
    if (onComplete != null) {
      onComplete(true)
    }
  }

  return (
    <Box>
      <div className='my-6'>
        <Text size={'medium'} variant={'bodyTextLight'} className={'ml-8'}>
          Confirm the following list of attachments
        </Text>

        <div className={'w-full'}>
          <CustomCheckBoxGroup
            className={'flex flex-col gap-2'}
            labels={listOfAttachments}
            checkedCheckboxes={checkedCheckboxes}
            onCheckboxChange={handleCheckboxChange}
          />
          <Separator />
          <CustomCheckBoxGroup
            className={'flex flex-col gap-2'}
            labels={['I have read and understood all the requirements']}
            checkedCheckboxes={[isSelectAllChecked]}
            onCheckboxChange={handleSelectAllChange}
          />
        </div>

        <LoanStepperFooter disabled={isContinueButtonDisabled} submit={onSubmit} />
      </div>
    </Box>
  )
}
