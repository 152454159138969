import { Spinner } from 'components/Elements/Spinner'
import { NotFound } from 'components/Errors/NotFound'
import { AcceptCroppingPlan } from 'features/officer/cropping-plan/components/approve/AcceptCroppingPlan'
import { CroppingPlanInfo } from 'features/officer/cropping-plan/components/CroppingPlanInfo'
import { CroppingPlanStatuses } from 'features/officer/cropping-plan/components/CroppingPlanStatuses'
import { RejectCroppingPlanModal } from 'features/officer/cropping-plan/components/reject/RejectCroppingPlanModal'
import { RejectOrReturn } from 'features/officer/cropping-plan/components/RejectOrReturn'
import { ReturnCroppingPlanModal } from 'features/officer/cropping-plan/components/return/ReturnCroppingPlanModal'
import { useCroppingPlan } from 'features/officer/cropping-plan/hooks/useCroppingPlan'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import type React from 'react'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Button,
  Dialog,
  DialogContent,
  Text
} from 'components/ui'
import { Box, TabView } from 'components/App/Layout'
import { useTranslation } from 'react-i18next'

export const CroppingPlanView: React.FC = () => {
  const { cpId } = useParams<{ cpId: string }>()
  const { farmerId } = useParams<{ farmerId: string }>()
  const { t } = useTranslation('extensionOfficer')
  const [isRejectOrReturn, setIsRejectOrReturn] = useState(false)
  const [isReject, setIsReject] = useState(false)
  const [isReturn, setIsReturn] = useState(false)
  const [accept, setAccept] = useState(false)
  const navigate = useNavigate()
  const flags = useFlags()
  const ldClient = useLDClient()

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  const handleCloseAccept = (): void => {
    setAccept(false)
    setIsRejectOrReturn(false)
    setIsReturn(false)
    setIsReject(false)
  }

  const handleBack = (): void => {
    navigate('/officer/cropping-plan', { replace: true })
  }

  const {
    rejectCroppingPlan,
    returnCroppingPlan,
    approveCroppingPlan,
    acceptCroppingPlan,
    rejectFarm,
    returnFarm,
    isLoading,
    isLoadingApi,
    header,
    details
  } = useCroppingPlan(parseInt(cpId ?? ''), parseInt(farmerId ?? ''), handleCloseAccept, handleBack)

  const handleReject = (): void => {
    setIsRejectOrReturn(false)
    setIsReject(true)
  }
  const handleReturn = (): void => {
    setIsRejectOrReturn(false)
    setIsReturn(true)
  }
  const handleOpenAccept = (): void => {
    setAccept(true)
  }

  const handleOpenReject = (): void => {
    setAccept(true)
    setIsRejectOrReturn(true)
  }

  if (flags.croppingPlan === false) {
    return <NotFound />
  }

  if (isLoadingApi) {
    return (
      <div className={'w-full grid place-items-center h-full flex-1'}>
        <Spinner size={'sm'} />
      </div>
    )
  }

  if (header == null) {
    return <NotFound />
  }

  if (details == null) {
    return <NotFound />
  }

  return (
    <Box>
      <div className={'w-full flex items-center justify-between'}>
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link to={'/officer/cropping-plan'}>{t('cp.cp')}</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{farmerId}</BreadcrumbPage>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>{t('cp.rev')}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <CroppingPlanStatuses
          croppingPlan={header}
          className={'flex justify-center items-center gap-2'}
        />
      </div>

      <div
        className={
          'bg-card w-full flex justify-between gap-4 sm:gap-6 py-4 sm:px-6 md:px-8 lg:px-12 rounded-md shadow-md'
        }
      >
        <div className={'flex flex-col'}>
          <Text size={'small'}>{t('cp.cpid')}</Text>
          <Text variant={'primary'} size={'medium'}>
            {header?.cropping_plan_id}
          </Text>
        </div>

        <div className={'flex flex-col mr-8'}>
          <Text size={'small'}>{t('cp.fid')}</Text>
          <Text size={'medium'} variant={'primary'}>
            {farmerId}
          </Text>
        </div>
      </div>
      <TabView>
        {/* <Text size={'small'}>Farmer ID</Text> */}
        {/* <Text size={'medium'}>{farmerId}</Text> */}

        <CroppingPlanInfo croppingPlan={details} header={header} />

        <div className={'w-full flex items-center justify-between gap-8'}>
          <Button
            variant={'destructive'}
            className='w-1/2 max-w-[18rem]'
            onClick={handleOpenReject}
            disabled={
              header?.is_verified !== 'Awaiting Farm Assessment' &&
              header?.is_verified !== 'Submitted CP Verification'
            }
          >
            {t('cp.reject')}
          </Button>
          <Button
            variant={'success'}
            className='w-1/2 max-w-[18rem]'
            disabled={
              header?.is_verified !== 'Awaiting Farm Assessment' &&
              header?.is_verified !== 'Submitted CP Verification'
            }
            onClick={handleOpenAccept}
          >
            {t('cp.approve')}
          </Button>
        </div>

        <Dialog open={accept} defaultOpen={false} modal={true} onOpenChange={handleCloseAccept}>
          <DialogContent
            className={
              'bg-card w-[90vw] md:w-[50%] lg:w-[35%] max-w-[430px] max-h-[80vh] overflow-y-auto'
            }
          >
            {isRejectOrReturn ? (
              <RejectOrReturn reject={handleReject} returnCp={handleReturn} />
            ) : isReject ? (
              <RejectCroppingPlanModal
                header={header}
                onClose={handleCloseAccept}
                rejectCroppingPlan={rejectCroppingPlan}
                rejectFarm={rejectFarm}
                isLoading={isLoading}
                className={'flex flex-col gap-4 '}
              />
            ) : isReturn ? (
              <ReturnCroppingPlanModal
                header={header}
                onClose={handleCloseAccept}
                returnCroppingPlan={returnCroppingPlan}
                returnFarm={returnFarm}
                isLoading={isLoading}
                className={'flex flex-col gap-4'}
              />
            ) : (
              <AcceptCroppingPlan
                header={header}
                onClose={handleCloseAccept}
                acceptCroppingPlan={acceptCroppingPlan}
                approveCroppingPlan={approveCroppingPlan}
                isLoading={isLoading}
                className={'flex flex-col gap-4'}
              />
            )}
          </DialogContent>
        </Dialog>
      </TabView>
    </Box>
  )
}
