// farmFeaturesData.ts

import Loan from 'assets/landing/icons/loan.svg'
import Tractor from 'assets/landing/icons/tractor.svg'
import Sustainability from 'assets/landing/icons/plantswatering.svg'
import Grain from 'assets/landing/icons/grain.svg'

/**
 * Interface representing a farm feature.
 */
export interface FarmFeature {
  title: string
  description: string
  icon: string
}

/**
 * Array of farm features with their respective details.
 */

export const farmFeatures = (t: (key: string) => string): FarmFeature[] => [
  {
    title: t('farm.farm'),
    description: t('farm.farmD'),
    icon: Grain
  },
  {
    title: t('farm.loan'),
    description: t('farm.loanD'),
    icon: Loan
  },
  {
    title: t('farm.inv'),
    description: t('farm.invD'),
    icon: Tractor
  },
  {
    title: t('farm.sus'),
    description: t('farm.susD'),
    icon: Sustainability
  }
]