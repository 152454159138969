import { Farmer } from 'components/Auth/Farmer'
import { Inventories } from 'features/farmer/inventory/routes'
import { FarmView, LandManagement } from 'features/farmer/land-management/routes'
import { LeaseManagement, LeaseView } from 'features/farmer/lease-management/routes'
import { LoanApplication, LoanView } from 'features/loan-management/routes'
import { Profile } from 'features/profile/routes'
import { Navigate } from 'react-router-dom'
import { CompleteApplication } from '../features/loan-management/components/stepper/individual/CompleteAplication'
import { InventoryForm } from 'features/farmer/inventory/components'
import { ModifyInventory } from '../features/farmer/inventory/components/ModifyInventory'
import { Checklist } from '../features/mechanisationOfficer/inventory/components/checklist/Checklist'
import { ViewHistories } from '../features/farmer/inventory/components/History/ViewHistories'

export const farmerRoutes = {
  path: '/',
  element: <Farmer />,
  children: [
    { path: '/farmer/farms', element: <LandManagement /> },
    { path: '/farmer/profile', element: <Profile /> },
    { path: '/farmer/farms/:farmId', element: <FarmView /> },
    { path: '/farmer/lease', element: <LeaseManagement /> },
    { path: '/farmer/lease/:leaseId', element: <LeaseView /> },
    { path: '/farmer/loans', element: <LoanView /> },
    { path: '/farmer/loans/newloan', element: <LoanApplication /> },
    { path: '/farmer/loans/completeApplication', element: <CompleteApplication /> },
    { path: '/farmer/inventory', element: <Inventories /> },
    { path: '/farmer/inventory/history/:inventoryId', element: <ViewHistories /> },
    { path: '/farmer/inventory/register-new/:inventoryType', element: <InventoryForm /> },
    { path: '/farmer/inventory/:inventoryId', element: <ModifyInventory /> },
    { path: '/farmer/inventory/assessment/:inventoryId', element: <Checklist /> },
    { path: '/farmer/inventory/register-new/*', element: <InventoryForm /> },
    { path: '/farmer/*', element: <Navigate to={'/farmer/farms'} /> },
    { path: '*', element: <Navigate to={'.'} /> }
  ]
}
