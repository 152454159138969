import { useGetSSA } from 'features/farmer/ssr/api/getSSA'
import { useGetSSR } from 'features/farmer/ssr/api/getSSR'
import { SSRCard } from 'features/farmer/ssr/components/SSRCard'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFarmerStore } from 'stores/useFarmerStore'
import type { Farm, Lease } from 'types'
import { Button, Dialog, DialogContent, Loader, Text } from 'components/ui'
import { TabView } from 'components/App/Layout'
import { CircleOff, FileInput } from 'lucide-react'
import { NewSSR } from '../components/new-ssr/NewSSR'
import { Error } from '../../../../components/Errors/Error'
import { useTranslation } from 'react-i18next'
import { usePloughSeason } from '../../../../hooks/usePloughSeason'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { ComingSoon } from '../../../../components/Layout/ComingSoon'

interface WithFarm {
  farm: Farm
  lease?: never
}

interface WithLease {
  farm: Farm
  lease: Lease
}

type FarmerSoilResultsProps = WithFarm | WithLease

export const FarmerSoilResults: React.FC<FarmerSoilResultsProps> = ({ farm, lease }) => {
  const flags = useFlags()
  const ldClient = useLDClient()
  const [open, setOpen] = useState(false)
  const { farmId } = useParams<{ farmId: string }>()
  const { leaseId } = useParams<{ leaseId: string }>()
  const { farmer } = useFarmerStore()
  const { currentSeason, isCurrentSeason, isLoading: ploughSeasonIsLoading } = usePloughSeason()
  const { t } = useTranslation('landManagement')

  const handleOpenDialog = (): void => {
    setOpen(true)
  }
  const handleCloseDialog = (): void => {
    setOpen(false)
  }

  const {
    isLoading: isLoadingSSR,
    isInitialLoading,
    data: ssr,
    error: ssrError
  } = useGetSSR(
    lease?.farm_id !== undefined ? lease.farm_id.toString() : farmId,
    farmer?.farmer_id,
    leaseId
  )
  const {
    isInitialLoading: isLoadingSSA,
    data: ssa,
    error: ssaError
  } = useGetSSA(farmId, farmer?.farmer_id, ssr?.data[ssr?.data.length - 1].ssr_id)

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  if (flags.soilSampleRequest === false) {
    return <ComingSoon />
  }

  if (isLoadingSSR || isLoadingSSA || isInitialLoading || ploughSeasonIsLoading) {
    return (
      <TabView>
        <div className={'grid place-items-center'}>
          <Loader />
        </div>
      </TabView>
    )
  }

  if (ssr?.data == null || ssrError?.response?.status === 404) {
    return (
      <TabView>
        <div className={'flex justify-between items-center'}>
          <Text size={'medium'}>{t('ssr.info')}</Text>
          <Button
            variant={'warning'}
            size={'xs'}
            onClick={handleOpenDialog}
            disabled={farm.leased_size === farm.size}
          >
            {t('ssr.requestResults')}
            <FileInput size={'12'} />
          </Button>
        </div>
        <div className={'min-h-[300px] flex flex-col gap-4 items-center justify-center'}>
          <Text size={'xLarge'} className={'opacity-40'}>
            <CircleOff />
          </Text>
          <Text size={'medium'} variant={'bodyTextLight'}>
            {t('ssr.none')}
          </Text>
        </div>

        <Dialog
          key={'open-ssr'}
          open={open}
          defaultOpen={false}
          modal
          onOpenChange={handleCloseDialog}
        >
          <DialogContent key={'new-ssr'} className={'max-w-[80%] md:max-w-[40%] lg:max-w-[25%]'}>
            <NewSSR lease={lease} farm={farm} cancel={handleCloseDialog} />
          </DialogContent>
        </Dialog>
      </TabView>
    )
  }

  if (
    (ssrError != null && ssrError?.response?.status !== 404) ||
    (ssaError != null && ssaError?.response?.status !== 404)
  ) {
    return <Error />
  }

  const hasSSRThisSeason = ssr?.data.find((item) => item?.plough_season === currentSeason) != null

  if (
    !hasSSRThisSeason ||
    (ssr?.data.find((item) => item?.plough_season === currentSeason)?.status_name === 'REJECTED' &&
      hasSSRThisSeason &&
      ssr?.data.find(
        (item) => item?.plough_season === currentSeason && item?.status_name === 'SUBMITTED'
      ) == null)
  ) {
    return (
      <TabView>
        <div className={'flex justify-between items-center'}>
          <Text size={'medium'}>{t('ssr.ssr')}</Text>
          <Button
            variant={'warning'}
            size={'xs'}
            onClick={handleOpenDialog}
            disabled={farm.leased_size === farm.size}
          >
            {t('ssr.requestResults')}
            <FileInput size={'12'} />
          </Button>
        </div>
        <div className={`grid gap-6 lg:grid-cols-3`}>
          {ssr?.data.map((item) => {
            return (
              <SSRCard
                isPreviousSeason={!isCurrentSeason(item.plough_season)}
                key={item.ssr_id}
                ssr={item}
                ssa={ssa?.data.find((analysis) => analysis.ssr_id === item.ssr_id)}
              />
            )
          })}
        </div>
        <Dialog
          key={'open-ssr'}
          open={open}
          defaultOpen={false}
          modal
          onOpenChange={handleCloseDialog}
        >
          <DialogContent key={'new-ssr'} className={'max-w-[80%] md:max-w-[40%] lg:max-w-[25%]'}>
            <NewSSR lease={lease} farm={farm} cancel={handleCloseDialog} />
          </DialogContent>
        </Dialog>
      </TabView>
    )
  }

  return (
    <TabView>
      <Text size={'medium'}>{t('ssr.ssr')}</Text>
      <div className={`grid gap-6 lg:grid-cols-3`}>
        {ssr?.data.map((item) => {
          return (
            <SSRCard
              isPreviousSeason={!isCurrentSeason(item.plough_season)}
              key={item.ssr_id}
              ssr={item}
              ssa={ssa?.data.find((analysis) => analysis.ssr_id === item.ssr_id)}
            />
          )
        })}
      </div>
    </TabView>
  )
}
