import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  updateHeaderStatusEO,
  type UpdateHeaderStatusEODTO
} from 'features/farmer/cropping-plan/api/header/updateHeaderStatus'
import {
  useGetCroppingPlan,
  useGetCroppingPlanDetails
} from 'features/officer/cropping-plan/api/getCroppingPlan'
import { type RejectReasonInput } from 'features/officer/cropping-plan/components/reject/RejectCroppingPlan'
import { useOfficerStore } from 'stores/useOfficerStore'
import { type CroppingPlan, type CroppingPlanDetails } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { useToast } from '../../../../components/ui'

interface UseCroppingPlan {
  isLoadingApi: boolean
  isLoading: boolean
  header: CroppingPlan | null | undefined
  details: CroppingPlanDetails | null | undefined
  acceptCroppingPlan: () => void
  approveCroppingPlan: () => void
  returnCroppingPlan: (data: RejectReasonInput) => void
  returnFarm: (data: RejectReasonInput) => void
  rejectCroppingPlan: (data: RejectReasonInput) => void
  rejectFarm: (data: RejectReasonInput) => void
}

export const useCroppingPlan = (
  cpId: number | null | undefined,
  farmerId: number | null | undefined,
  onClose: () => void,
  onNext: () => void
): UseCroppingPlan => {
  const { toast } = useToast()
  const { officer } = useOfficerStore()
  const queryClient = useQueryClient()
  const { data: apiHeader, isInitialLoading: apiIsLoading } = useGetCroppingPlan(
    cpId?.toString(),
    farmerId?.toString()
  )
  const { data: details, isLoading } = useGetCroppingPlanDetails(
    cpId?.toString(),
    farmerId?.toString()
  )

  const updateCroppingPlanStatusMutation = useMutation({
    mutationFn: async (cpDto: UpdateHeaderStatusEODTO) => {
      return updateHeaderStatusEO(cpDto)
    }
  })

  const acceptCroppingPlan = (): void => {
    updateCroppingPlanStatusMutation.mutate(
      {
        farmer_id: farmerId,
        cp_id: cpId,
        user_id: officer?.user_id,
        status: 'Awaiting Farm Assessment',
        comments: ''
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            void queryClient.invalidateQueries({
              queryKey: ['cropping-plan-details']
            })
            toast({
              variant: 'success',
              title: 'Successfully Accepted Cropping Plan for farm assessment.'
            })
            onClose()
            onNext()
          } else {
            const err1 = result.message
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(mapCmsErrorToMessage(err1))
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  const approveCroppingPlan = (): void => {
    updateCroppingPlanStatusMutation.mutate(
      {
        farmer_id: farmerId,
        cp_id: cpId,
        user_id: officer?.user_id,
        status: 'Approved',
        comments: ''
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            void queryClient.invalidateQueries({
              queryKey: ['cropping-plan-details']
            })
            toast({
              variant: 'success',
              title: 'Successfully Approved Cropping Plan.'
            })
            onClose()
            onNext()
          } else {
            const err1 = result.message
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(mapCmsErrorToMessage(err1))
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  const returnCroppingPlan = (data: RejectReasonInput): void => {
    updateCroppingPlanStatusMutation.mutate(
      {
        farmer_id: farmerId,
        cp_id: cpId,
        user_id: officer?.user_id,
        status: 'RAI - CP Verification',
        comments: data?.reason
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            void queryClient.invalidateQueries({
              queryKey: ['cropping-plan-details']
            })
            toast({
              variant: 'success',
              title: 'Successfully returned cropping plan for re-evaluation.'
            })
            onClose()
            onNext()
          } else {
            const err1 = result.message
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(mapCmsErrorToMessage(err1))
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  const returnFarm = (data: RejectReasonInput): void => {
    updateCroppingPlanStatusMutation.mutate(
      {
        farmer_id: farmerId,
        cp_id: cpId,
        user_id: officer?.user_id,
        status: 'RAI - Farm Assessment',
        comments: data?.reason
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            void queryClient.invalidateQueries({
              queryKey: ['cropping-plan-details']
            })
            toast({
              variant: 'success',
              title: 'Successfully Returned Farm for assessment.'
            })
            onClose()
            onNext()
          } else {
            const err1 = result.message
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(mapCmsErrorToMessage(err1))
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  const rejectCroppingPlan = (data: RejectReasonInput): void => {
    updateCroppingPlanStatusMutation.mutate(
      {
        farmer_id: farmerId,
        cp_id: cpId,
        user_id: officer?.user_id,
        status: 'Rejected - CP Verification',
        comments: data?.reason
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            void queryClient.invalidateQueries({
              queryKey: ['cropping-plan-details']
            })
            toast({
              variant: 'success',
              title: 'Successfully Rejected Cropping Plan.'
            })
            onClose()
            onNext()
          } else {
            const err1 = result.message
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(mapCmsErrorToMessage(err1))
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  const rejectFarm = (data: RejectReasonInput): void => {
    updateCroppingPlanStatusMutation.mutate(
      {
        farmer_id: farmerId,
        cp_id: cpId,
        user_id: officer?.user_id,
        status: 'Rejected - Farm Assessment',
        comments: data?.reason
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            void queryClient.invalidateQueries({
              queryKey: ['cropping-plan-details']
            })
            toast({
              variant: 'success',
              title: 'Successfully Rejected Farm Assessment.'
            })
            onClose()
            onNext()
          } else {
            const err1 = result.message
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(mapCmsErrorToMessage(err1))
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  return {
    isLoadingApi: apiIsLoading || isLoading,
    isLoading: updateCroppingPlanStatusMutation.isLoading,
    header: apiHeader?.data[0],
    details: details?.data,
    acceptCroppingPlan,
    approveCroppingPlan,
    rejectCroppingPlan,
    returnCroppingPlan,
    rejectFarm,
    returnFarm
  }
}
