import type React from 'react'
import { useState } from 'react'
import { type FarmFeature, farmFeatures } from './Data/PowerfulFeaturesData'
import { Card, CardDescription, CardHeader, CardTitle } from '../../../../../components/ui'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

/**
 * A React functional component that renders a list of farm feature cards.
 * Each card displays an icon, title, and description of a farm feature.
 * The first feature is selected by default on page load.
 *
 * @returns {JSX.Element} The rendered component.
 */
const FarmFeatureCards: React.FC = () => {
  const { t } = useTranslation('landing')
  // State to keep track of the currently selected feature, initialized to the first feature
  const [selectedFeature, setSelectedFeature] = useState<FarmFeature | null>(farmFeatures(t)[0])

  /**
   * Handles the click event on a card to set the selected feature.
   *
   * @param {FarmFeature} feature - The feature to be selected.
   */
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleCardClick = (feature: FarmFeature) => {
    setSelectedFeature(feature)
  }

  return (
    <div className='flex flex-col pt-20 pb-20 bg-white space-y-8'>
      <div className='container mx-auto flex flex-col px-10 items-center space-y-24'>
        <div
          className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6'

        >
          {farmFeatures(t).map((feature) => {
            // Get the corresponding icon component for the feature
            return (
              <motion.Card
                key={feature.id}
                className='w-[300px] lg:w-[350px] shadow-xl border-gray-300 rounded-[20px] cursor-pointer bg-white'
                onClick={() => {
                  handleCardClick(feature)
                }}
                whileHover={{ scale: 1.01 }}
                whileTap={{ scale: 0.99 }}
              >
                <CardHeader className='flex space-y-4'>
                  <div className='w-20 h-20  rounded-lg p-4 bg-blue-100 flex items-center justify-center mb-8'>
                    <img src={feature.icon} className='h-8 md:h-12 w-8 md:w-12 text-blue-500' />
                  </div>
                  <CardTitle className=''>
                    <h2 className='text-base md:text-lg lg:text-xl text-gray-700 font-semibold mb-auto landing-page'>
                      {feature.title}
                    </h2>
                  </CardTitle>
                  <CardDescription>
                    <p className='text-xs md:text-sm lg:text-base text-gray-600 font-regular landing-page'>
                      {feature.description}
                    </p>
                  </CardDescription>
                </CardHeader>
              </motion.Card>
            )
          })}
        </div>
        {selectedFeature && (
          <div className=''>
            <img
              src={selectedFeature.image}
              alt={selectedFeature.title}
              className='w-full h-auto rounded-lg shadow-md'
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default FarmFeatureCards
