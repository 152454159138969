import type React from 'react'
import { useState } from 'react'
import { type Farm } from 'types'
import { DestinationFarmer } from './DestinationFarmer'
import { DestinationFarmerConfirmation } from './DestinationFarmerConfirmation'
import { FarmTransferDisclaimer } from './FarmTransferDisclaimer'
import { TransferFarmOtpForm } from './TransferFarmOtpForm'
import { useTranslation } from 'react-i18next'

interface FarmTransferProps {
  farm: Farm
  onClose: () => void
}

export const FarmTransfer: React.FC<FarmTransferProps> = ({ farm, onClose }) => {
  const { t } = useTranslation('landManagement')
  //    TODO: HERE
  const steps = [
    'Destination Farmer',
    'Confirm Destination Farmer',
    `${t('ownership.confirmTransfer')}`,
    `${t('ownership.otp')}`
  ]
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = (): void => {
    setActiveStep((prevState) => prevState + 1)
  }

  const handleBack = (): void => {
    setActiveStep((prevState) => prevState - 1)
  }

  if (activeStep === 1) {
    return (
      <div className={'flex flex-col items-center gap-8 p-4'}>
        <DestinationFarmerConfirmation next={handleNext} back={handleBack} />
      </div>
    )
  }

  if (activeStep === 2) {
    return (
      <div className={'flex flex-col items-center gap-2 md:gap-8 md:p-4'}>
        <FarmTransferDisclaimer farm={farm} next={handleNext} />
      </div>
    )
  }

  if (activeStep === steps.length - 1) {
    return (
      <div className={'flex flex-col items-center gap-2 md:gap-8 md:p-4'}>
        <TransferFarmOtpForm onSuccess={onClose} farm={farm} />
      </div>
    )
  }

  return (
    <div className={'flex flex-col items-center gap-8 p-4'}>
      <DestinationFarmer cancel={onClose} next={handleNext} />
    </div>
  )
}
