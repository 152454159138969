import type React from 'react'
import { type FC } from 'react'
import Session from 'supertokens-auth-react/recipe/session'
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles'
import Spinner from '../../../components/ui/spinner'
import { Navigate } from 'react-router-dom'

interface ProtectedRouteProps {
  children: React.ReactNode
}
export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const claimValue = Session.useClaimValue(UserRoleClaim)
  if (claimValue.loading) {
    return (
      <div className='flex items-center justify-center w-screen h-screen'>
        <Spinner />
      </div>
    )
  }
  const roles = claimValue.value
  if (Array.isArray(roles) && roles.includes('FARMER')) {
    return <Navigate to={'/farmer'} />
  } else if (Array.isArray(roles) && roles.includes('EXTENSION_OFFICER')) {
    return <Navigate to={'/officer'} />
  } else if (Array.isArray(roles) && roles.includes('SOIL_SAMPLE_LAB')) {
    return <Navigate to={'/soil-lab'} />
  } else if (Array.isArray(roles) && roles.includes('MECHANISATION_OFFICER')) {
    return <Navigate to={'/inventory'} />
  } else if (Array.isArray(roles) && roles.includes('SEED_DEALER')) {
    return <Navigate to={'/seed-dealer'} />
  } else if (Array.isArray(roles) && roles.includes('SERVICE_PROVIDER')) {
    return <Navigate to={'/service-provider'} />
  } else {
    return children
  }
}
