import { Card, Dialog, DialogContent, Text } from 'components/ui'
import { SSRVerificationStatus } from 'features/farmer/ssr/components/SSRVerficationStatus'
import { ViewSoilSampleAnalysis } from 'features/farmer/ssr/components/ViewSoilSampleAnalysis'
import type React from 'react'
import { useState } from 'react'
import { type SoilSampleAnalysis, type SoilSampleRequest } from 'types'
import { RejectSsr } from 'features/farmer/ssr/components/RejectSsr'
import { UpdateSSR } from './new-ssr/UpdateSSR'
import dayjs from 'dayjs'
import { TestTube } from 'lucide-react'
import { useTranslation } from 'react-i18next'

interface SSRCardProps {
  ssr: SoilSampleRequest
  ssa?: SoilSampleAnalysis | undefined
  isPreviousSeason: boolean
}

export const SSRCard: React.FC<SSRCardProps> = ({ ssa, ssr, isPreviousSeason }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation('landManagement')

  const handleOpenDialog = (): void => {
    setOpen(true)
  }
  const handleCloseDialog = (): void => {
    setOpen(false)
  }

  const handleClick = (): undefined | (() => void) => {
    if (isPreviousSeason) {
      if (ssa != null) {
        return handleOpenDialog
      } else {
        return undefined
      }
    } else {
      return handleOpenDialog
    }
  }

  const handleHoverState = (): string => {
    const cardHover =
      'cursor-pointer hover:shadow-md hover:shadow-input hover:border-primary dark:hover:border-primary'

    if (isPreviousSeason) {
      if (ssa != null) {
        return (
          cardHover +
          'dark:bg-card-secondary w-full p-4 grid gap-4 transition-shadow duration-200 ease-in-out transform shadow-sm shadow-secondary dark:shadow-none'
        )
      } else {
        return 'dark:bg-card-secondary w-full p-4 grid gap-4 transition-shadow duration-200 ease-in-out transform shadow-sm shadow-secondary dark:shadow-none'
      }
    } else {
      return (
        cardHover +
        'dark:bg-card-secondary w-full p-4 grid gap-4 transition-shadow duration-200 ease-in-out transform shadow-sm shadow-secondary dark:shadow-none'
      )
    }
  }

  return (
    <div className='grid'>
      <Card className={handleHoverState()} onClick={handleClick()}>
        <div className={'flex justify-between items-center mx-4'}>
          <Text size={'medium'}>{ssr?.farm_name}</Text>
          <SSRVerificationStatus ssr={ssr} className={'text-md flex items-center gap-2'} />
        </div>
        <div className='rounded-md flex items-start gap-4 bg-background dark:bg-card p-2'>
          <div className={`rounded-md p-2 sm:p-3 flex items-center justify-center bg-secondary`}>
            <TestTube className='w-4 h-4 sm:w-6 sm:h-6 text-primary-foreground dark:text-foreground' />
          </div>
          <div className='grid gap-1'>
            <div className='grid gap-1'>
              <div className='flex items-center gap-2'>
                <Text size='small' variant='muted'>
                  {t('ssr.merchant')}
                </Text>
                <Text className={'font-semibold'}>{ssr.merchant_name}</Text>
              </div>
              <div className='flex items-center gap-2'>
                <Text size='small' variant='muted'>
                  {t('ssr.season')}
                </Text>
                <Text className={'font-semibold'}>{ssr.plough_season}</Text>
              </div>
              <div className='flex items-center gap-2'>
                <Text size='small' variant='muted'>
                  {ssa !== undefined ? `${t('ssr.expiry')}` : `${t('ssr.create')}`}:
                </Text>
                <Text className={'font-semibold'}>
                  {ssa !== undefined
                    ? dayjs(ssa?.expiry_date).format('DD-MM-YYYY')
                    : dayjs(ssr.create_date).format('DD-MM-YYYY')}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </Card>
      <Dialog
        key={'open-ssr'}
        open={open}
        defaultOpen={false}
        modal
        onOpenChange={handleCloseDialog}
      >
        {ssa != null && isPreviousSeason ? (
          <DialogContent className='bg-card max-w-[90vw] md:max-w-[50vw] md:h-[45vh] max-h-[100vh] overflow-y-auto'>
            <ViewSoilSampleAnalysis ssa={ssa} />
          </DialogContent>
        ) : null}
        {!isPreviousSeason ? (
          <DialogContent className='bg-card max-w-[90vw] md:max-w-[50vw] md:h-[45vh] max-h-[100vh] overflow-y-auto'>
            {ssa != null ? (
              <ViewSoilSampleAnalysis ssa={ssa} />
            ) : ssr.status_name === 'REJECTED' ? (
              <RejectSsr ssr={ssr} onClose={handleCloseDialog} />
            ) : (
              <UpdateSSR cancel={handleCloseDialog} ssr={ssr} />
            )}
          </DialogContent>
        ) : null}
      </Dialog>
    </div>
  )
}
