import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon
} from '@radix-ui/react-icons'
import { type Table } from '@tanstack/react-table'

import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui'
import type React from 'react'
import { useTranslation } from 'react-i18next'

interface DataTablePaginationProps<TData> {
  table: Table<TData>
  selectable?: boolean
}

export function DataTablePagination<TData>({
  table,
  selectable = false
}: DataTablePaginationProps<TData>): React.ReactNode {
  const { t } = useTranslation('genericTranslation')

  return (
    <div className='flex flex-col md:flex-row gap-2 md:gap-4 items-center justify-center sm:justify-end px-2'>
      {selectable ? (
        <div className='flex-1 text-center sm:text-start sm:text-sm text-muted-foreground'>
          {table.getFilteredSelectedRowModel().rows.length} of{' '}
          {table.getFilteredRowModel().rows.length} {t('table.sel')}.
        </div>
      ) : null}
      <div className='flex flex-col gap-6 sm:flex-row items-center md:space-x-6 lg:space-x-8'>
        <div className='flex items-center space-x-2'>
          <p className='text-sm font-medium'>{t('table.rows')}</p>
          <Select
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={(value) => {
              table.setPageSize(Number(value))
            }}
          >
            <SelectTrigger className='h-8 w-[70px]'>
              <SelectValue placeholder={table.getState().pagination.pageSize} />
            </SelectTrigger>
            <SelectContent side='top'>
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className={'flex gap-2 sm:hidden'}>
          <div className='flex w-[100px] items-center justify-center text-sm font-medium'>
            {table.getRowCount() === 0
              ? 'O of 0'
              : table.getState().pagination.pageIndex === 0
              ? 1
              : table.getState().pagination.pageSize * table.getState().pagination.pageIndex +
                1}{' '}
            -{' '}
            {table.getState().pagination.pageIndex + 1 === table.getPageCount()
              ? table.getFilteredRowModel().rows.length
              : table.getState().pagination.pageSize *
                (table.getState().pagination.pageIndex + 1)}{' '}
            of {table.getFilteredRowModel().rows.length}
          </div>
          <div className='flex items-center space-x-2'>
            <Button
              variant={'icon'}
              size={'icon'}
              className='hidden h-8 w-8 p-0 lg:flex'
              onClick={() => {
                table.setPageIndex(0)
              }}
              disabled={!table.getCanPreviousPage()}
            >
              <span className='sr-only'>Go to first page</span>
              <DoubleArrowLeftIcon className='h-4 w-4' />
            </Button>
            <Button
              variant={'icon'}
              size={'icon'}
              className='h-8 w-8 p-0'
              onClick={() => {
                table.previousPage()
              }}
              disabled={!table.getCanPreviousPage()}
            >
              <span className='sr-only'>Go to previous page</span>
              <ChevronLeftIcon className='h-4 w-4' />
            </Button>
            <Button
              variant={'icon'}
              size={'icon'}
              className='h-8 w-8 p-0'
              onClick={() => {
                table.nextPage()
              }}
              disabled={!table.getCanNextPage()}
            >
              <span className='sr-only'>Go to next page</span>
              <ChevronRightIcon className='h-4 w-4' />
            </Button>
            <Button
              variant={'icon'}
              size={'icon'}
              className='hidden h-8 w-8 p-0 lg:flex'
              onClick={() => {
                table.setPageIndex(table.getPageCount() - 1)
              }}
              disabled={!table.getCanNextPage()}
            >
              <span className='sr-only'>Go to last page</span>
              <DoubleArrowRightIcon className='h-4 w-4' />
            </Button>
          </div>
        </div>

        <div className='hidden sm:flex w-[100px] items-center justify-center text-sm font-medium'>
          {table.getRowCount() === 0
            ? 'O of 0'
            : table.getState().pagination.pageIndex === 0
            ? 1
            : table.getState().pagination.pageSize * table.getState().pagination.pageIndex + 1}{' '}
          -{' '}
          {table.getState().pagination.pageIndex + 1 === table.getPageCount()
            ? table.getFilteredRowModel().rows.length
            : table.getState().pagination.pageSize *
              (table.getState().pagination.pageIndex + 1)}{' '}
          of {table.getFilteredRowModel().rows.length}
        </div>
        <div className='hidden sm:flex items-center space-x-2'>
          <Button
            variant={'icon'}
            size={'icon'}
            className='hidden h-8 w-8 p-0 lg:flex'
            onClick={() => {
              table.setPageIndex(0)
            }}
            disabled={!table.getCanPreviousPage()}
          >
            <span className='sr-only'>Go to first page</span>
            <DoubleArrowLeftIcon className='h-4 w-4' />
          </Button>
          <Button
            variant={'icon'}
            size={'icon'}
            className='h-8 w-8 p-0'
            onClick={() => {
              table.previousPage()
            }}
            disabled={!table.getCanPreviousPage()}
          >
            <span className='sr-only'>Go to previous page</span>
            <ChevronLeftIcon className='h-4 w-4' />
          </Button>
          <Button
            variant={'icon'}
            size={'icon'}
            className={!table.getCanNextPage() ? 'h-8 w-8 p-0' : 'h-8 w-8 p-0'}
            onClick={() => {
              table.nextPage()
            }}
            disabled={!table.getCanNextPage()}
          >
            <span className={'sr-only'}>Go to next page</span>
            <ChevronRightIcon className={'h-4 w-4'} />
          </Button>
          <Button
            variant={'icon'}
            size={'icon'}
            className='hidden h-8 w-8 p-0 lg:flex'
            onClick={() => {
              table.setPageIndex(table.getPageCount() - 1)
            }}
            disabled={!table.getCanNextPage()}
          >
            <span className='sr-only'>Go to last page</span>
            <DoubleArrowRightIcon className='h-4 w-4' />
          </Button>
        </div>
      </div>
    </div>
  )
}