import { ErrorPage } from 'components/Errors/ErrorPage'
import {
  useGetCroppingPlan,
  useGetIndividualHeader
} from 'features/farmer/cropping-plan/api/header/getHeader'
import { useCroppingPlanStore } from 'features/farmer/cropping-plan/stores/useCroppingPlanStore'
import type React from 'react'
import { Button, Loader, Separator, Text, toast } from 'components/ui'
import { usePDF } from 'react-to-pdf'
import { FaDownload } from 'react-icons/fa6'
import { WeedingReview } from '../steps/review/Weeding'
import { PestControlReview } from '../steps/review/PestControl'
import { useTranslation } from 'react-i18next'
import { TabView } from '../../../../../components/App/Layout'
import { useStaticDataStore } from '../../../../../stores/useStaticDataStore'
import MoaLogo from '../../../../../assets/logos/moa.svg'

interface ReviewApprovedProps {
  onClose: () => void
}

export const ReviewApproved: React.FC<ReviewApprovedProps> = ({ onClose }) => {
  const { header } = useCroppingPlanStore()
  const { seeds } = useStaticDataStore()
  const { toPDF, targetRef } = usePDF({ filename: 'Cropping Plan.pdf' })
  const { t } = useTranslation('croppingPlan')
  const {
    data: apiHeader,
    isInitialLoading: apiIsLoading,
    error
  } = useGetIndividualHeader(header?.cropping_plan_id, header?.farmer_id)
  const {
    data: apiCroppingPlan,
    isLoading,
    error: cpError
  } = useGetCroppingPlan(header?.cropping_plan_id, header?.farmer_id)

  const handleDownloadPdf = async (): Promise<void> => {
    await new Promise<void>((resolve) => {
      toPDF()
      resolve()
    })
    toast({
      variant: 'success',
      title: 'Printing',
      description: 'Save to your device'
    })
  }

  if (isLoading || apiIsLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (error != null || cpError != null) {
    return <ErrorPage />
  }

  const selectedSeed = seeds?.find(
    (seed) => seed?.seed_detail_id === apiCroppingPlan?.data?.seed_detail_id
  )

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'BWP'
  })

  return (
    <TabView className='overflow-x-auto'>
      <div className={'w-full flex flex-col md:flex-row space-y-4 md:space-y-0 items-center justify-between'}>
        <Button variant={'ghost'} className={''} onClick={onClose}>
          <p className='fa-solid fa-arrow-left' />
          Back to Cropping Plans
        </Button>

        <Button color={'warning'} variant={'primary'} onClick={handleDownloadPdf}>
          <FaDownload />
          Download Cropping Plan
        </Button>
      </div>

      <div
        ref={targetRef}
        className='flex flex-col min-w-[1280px] max-w-7xl w-full mx-auto gap-8 p-8 md:p-12 lg:p-16 overflow-x-auto'
      >
        <div className='flex flex-row w-full'>
          <div className='basis-2/4'>
            <img src={MoaLogo} alt='Ministry of Agriculture' />
          </div>
          <div className='basis-2/4'>
            <Text size='mediumRegular' variant='bodyTextLightTwo' className='text-right'>
              Ministry of Agriculture
            </Text>
            <Text size='mediumRegular' variant='bodyTextLightTwo' className='text-right'>
              Temo Letlotlo CMS
            </Text>
            <Text size='mediumRegular' variant='bodyTextLightTwo' className='text-right'>
              Plot 4701, Mmaraka Road, Private Bag 003, Gaborone
            </Text>
            <Text size='mediumRegular' variant='bodyTextLightTwo' className='text-right'>
              Call centre: 17755
            </Text>
            <Text size='mediumRegular' variant='bodyTextLightTwo' className='text-right'>
              support@temoletlotlo.com
            </Text>
          </div>
        </div>

        <div className='basis-full flex flex-col text-left gap-2'>
          <Text size='xLarge' variant='bodyTextLightTwo'>
            Cropping Plan Overview
          </Text>
          <Text size='mediumRegular' variant='bodyTextLightTwo'>
            This is the information detailing your cropping plan made in the Temo Letlotlo CMS.
          </Text>
        </div>
        <hr className='w-full border-gray-300' />

        <div className='grid grid-cols-2 gap-y-8 gap-x-10 w-full'>
          <div>
            <div className='flex flex-col gap-2 w-full'>
              <Text size='large' variant='bodyTextLightTwo'>
                Crop Information
              </Text>
              <div className='grid grid-cols-2 gap-4 mt-4'>
                <div className='w-1/2 flex flex-col'>
                  <Text size='mediumLight' variant='secondary'>
                    {t('review.name')}
                  </Text>
                  <Text size={'medium'} variant='secondary'>
                    {header?.crop_name}
                  </Text>
                </div>

                <div className='flex flex-col'>
                  <Text size='mediumLight' variant='secondary'>
                    {t('review.ha')}
                  </Text>
                  <Text size={'medium'} variant="secondary">
                    {apiHeader?.data[0].hectarage}
                  </Text>
                </div>
              </div>
            </div>
          </div>

          {apiCroppingPlan?.data?.seed_type != null && (
            <div className='grid gap-4 w-full'>
              <div>
                <div className='flex flex-col gap-2 w-full'>
                  <Text size='large' variant='secondary'>
                    {t('review.seed')}
                  </Text>

                  <div className='grid grid-cols-2 gap-4 mt-4'>
                    <div className='flex flex-col'>
                      <Text size='mediumLight' variant='secondary'>

                      {t('review.variety')}
                      </Text>
                      <Text size={'medium'} variant="secondary">
                      {selectedSeed?.variety ?? ''}
                      </Text>
                    </div>

                    <div className='flex flex-col'>
                      <Text size='mediumLight' variant='secondary'>

                      {t('review.type')}
                      </Text>
                      <Text size={'medium'} variant="secondary">

                      {selectedSeed?.seed_type ?? ''}
                      </Text>
                    </div>

                    <div className='flex flex-col'>
                      <Text size='mediumLight' variant='secondary'>
                        {t('review.prod')}
                      </Text>
                      <Text size={'medium'} variant='bodyTextLightTwo'>
                        {apiCroppingPlan?.data?.target_yield}{' '}
                        {apiCroppingPlan?.data?.target_yield == null
                          ? 'tonne(s)'
                          : apiCroppingPlan?.data?.target_yield > 1
                          ? 'tonnes'
                          : 'tonne'}
                      </Text>
                    </div>

                    {apiCroppingPlan?.data?.anticipated_returns !== 0 &&
                      apiCroppingPlan?.data?.anticipated_returns != null && (
                        <div className='flex flex-col'>
                          <Text size='mediumLight' variant='secondary'>
                            {t('review.returns')}
                          </Text>
                          <Text size={'medium'} variant='bodyTextLightTwo'>
                            {currencyFormatter.format(
                              apiCroppingPlan?.data?.anticipated_returns ?? 0
                            )}
                          </Text>
                        </div>
                      )}

                    <div className='flex flex-col col-span-2'>
                      <Text size='mediumLight' variant='secondary'>
                        {t('review.seedCost')}
                      </Text>
                      <Text size={'medium'} variant='bodyTextLightTwo'>
                        {currencyFormatter.format(apiCroppingPlan?.data?.seed_cost ?? 0)}
                      </Text>
                      <Text variant='primary' size={'small'}>
                        {t('review.seedCostPrompt')}
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {apiCroppingPlan?.data?.weed_control_type != null ? (
            <WeedingReview cp={apiCroppingPlan?.data} />
          ) : null}

          {apiCroppingPlan?.data?.pest_control_type != null ? (
            <PestControlReview cp={apiCroppingPlan?.data} />
          ) : null}

          {apiCroppingPlan?.data?.tillage_method != null ? (
            <div className='grid gap-4 w-full'>
              <Text size='large' variant='bodyTextLightTwo'>
                {t('review.land')}
              </Text>

              <div className='grid grid-cols-2 gap-4 mt-4'>
                <div className='flex flex-col'>
                  <Text size='mediumLight' variant='secondary'>
                    {t('review.landMethod')}
                  </Text>
                  <Text size='medium' variant='secondary'>
                    {apiCroppingPlan?.data?.tillage_method}
                  </Text>
                </div>
                <div className='flex flex-col'>
                  <Text size='mediumLight' variant='secondary'>
                    {t('review.harrow')}
                  </Text>
                  <Text size='medium' variant='secondary'>
                    {apiCroppingPlan?.data?.isHarrowing === true ? 'Yes' : 'No'}
                  </Text>
                </div>
              </div>
              <div className='flex flex-col'>
                <Text size='mediumLight' variant='secondary'>
                  {t('review.landCost')}
                </Text>
                <Text size='medium' variant='secondary'>
                  {currencyFormatter.format(apiCroppingPlan?.data?.land_preparation_cost ?? 0)}
                </Text>
              </div>
            </div>
          ) : null}

          {apiCroppingPlan?.data?.harvesting_method != null ? (
            <div className='grid gap-4 w-full'>
              <Text size='large' variant='bodyTextLightTwo'>
                {t('review.harvest')}
              </Text>

              <div className='grid grid-cols-2 gap-4 mt-4'>
                <div className='flex flex-col'>
                  <Text size={'mediumLight'} variant='secondary'>{t('review.harvestMethod')}</Text>
                  <Text size='medium' variant='secondary'>
                    {apiCroppingPlan?.data?.harvesting_method}
                  </Text>
                </div>
                <div className='flex flex-col'>
                  <Text size='mediumLight' variant='secondary'>
                    {t('review.harvestCost')}
                  </Text>
                  <Text size='medium' variant='secondary'>
                    {currencyFormatter.format(apiCroppingPlan?.data?.harvesting_cost ?? 0)}
                  </Text>
                </div>
              </div>
            </div>
          ) : null}

          {apiCroppingPlan?.data?.shelling_threshing_method != null ? (
            <div className='grid gap-4 w-full'>
              <Text size='large' variant='secondary'>
                {t('review.shell')}
              </Text>

              <div className='grid grid-cols-2 gap-4 mt-4'>
                <div className='flex flex-col'>
                  <Text size='mediumRegular' variant='secondary'>
                    {t('review.shellMethod')}
                  </Text>
                  <Text size='medium' variant='secondary'>
                    {apiCroppingPlan?.data?.shelling_threshing_method}
                  </Text>
                </div>
                <div className='flex flex-col'>
                  <Text size='mediumLight' variant='secondary'>
                    {t('review.shellCost')}
                  </Text>
                  <Text size='medium' variant='secondary'>
                    {currencyFormatter.format(apiCroppingPlan?.data?.shelling_threshing_cost ?? 0)}
                  </Text>
                </div>
              </div>
            </div>
          ) : null}

          {apiCroppingPlan?.data?.packaging_method != null ? (
            <div className='grid gap-4 w-full'>
              <Text size='large' variant='secondary'>
                {t('review.pack')}
              </Text>

              <div className='grid grid-cols-2 gap-4 mt-4'>
                <div className='flex flex-col'>
                  <Text size='mediumLight' variant='secondary'>
                    {t('review.packMethod')}
                  </Text>
                  <Text size='medium' variant='secondary'>
                    {apiCroppingPlan?.data?.packaging_method}
                  </Text>
                </div>
                <div className='flex flex-col'>
                  <Text size='mediumLight' variant='secondary'>
                    {t('review.packCost')}
                  </Text>
                  <Text size='medium' variant='secondary'>
                    {currencyFormatter.format(apiCroppingPlan?.data?.packaging_cost ?? 0)}
                  </Text>
                </div>
              </div>
            </div>
          ) : null}

          {apiCroppingPlan?.data?.transportation_cost === null ||
          apiCroppingPlan?.data?.transportation_cost === 0 ? null : (
            <div className='grid gap-4 w-full'>
              <Text size='large' variant='secondary'>
                {t('review.tran')}
              </Text>

              <div className='grid grid-cols-2 gap-4 mt-4'>
                <div className='flex flex-col'>
                  <Text size='mediumLight' variant='secondary'>
                    {t('review.tcTran')}
                  </Text>
                  <Text size='medium' variant='secondary'>
                    {currencyFormatter.format(apiCroppingPlan?.data?.transportation_cost ?? 0)}
                  </Text>
                </div>
              </div>
            </div>
          )}

          <div className='flex flex-col gap-4 col-span-2'>
            <Text size='large' variant='bodyTextLightTwo'>
              {t('review.tCost')}
            </Text>

            <div className='grid grid-cols-2 gap-4 mt-4'>
              <div className='flex space-x-4'>
                <Text size='mediumLight' variant='secondary'>
                  {t('review.tCost')}:
                </Text>
                <Text size='medium' variant='bodyTextLightTwo'>
                  {currencyFormatter.format(
                    Number(apiHeader?.data[0]?.total_cost ?? header?.total_cost ?? 0)
                  )}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </TabView>
  )
}
