import type React from 'react'
import { Separator, Text } from '../../../../../../components/ui'
import { useTranslation } from 'react-i18next'
import { type CroppingPlanDetails } from '../../../../../../types'
import { useStaticDataStore } from '../../../../../../stores/useStaticDataStore'

interface WeedingReviewProps {
  cp: CroppingPlanDetails
}

export const WeedingReview: React.FC<WeedingReviewProps> = ({ cp }) => {
  const { herbicides, adjuvants } = useStaticDataStore()
  const { t } = useTranslation('croppingPlan')

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'BWP'
  })

  return (
    <div className='grid gap-4 w-full'>
      <div>
        <div className='flex flex-col gap-2 w-full'>
          <Text size='large' variant='secondary'>
            {t('review.weed')}
          </Text>

          <div className='grid grid-cols-2 gap-4 mt-4'>
            <div className='flex flex-col'>
              <Text size='mediumLight' variant='secondary'>
                {t('review.weedMethod')}
              </Text>
              <Text size='medium' variant='secondary'>
                {cp.weed_control_type}
              </Text>
            </div>

            <div className='flex flex-col'>
              <Text size='mediumRegular' variant='secondary'>
                {t('review.appMethod')}
              </Text>
              <Text size='medium' variant='secondary'>
                {cp.weed_control_application}
              </Text>
            </div>

            <div className='flex flex-col'>
              <Text size='mediumRegular' variant='secondary'>
                Total Application Cost
              </Text>
              <Text size='medium' variant='secondary'>
                {currencyFormatter.format(cp.weed_control_application_cost ?? 0)}
              </Text>
            </div>

            {cp.weed_control_type === 'Chemical' && (
              <div className='flex flex-col'>
                <Text size='medium' variant='secondary'>
                  Total Chemical Cost
                </Text>
                <Text size='mediumRegular' variant='secondary'>
                  {currencyFormatter.format(cp.weed_control_chemical_cost ?? 0)}
                </Text>
              </div>
            )}
          </div>
        </div>
      </div>

      <div>
        {cp.weed_control_type === 'Chemical' && (
          <div className='flex flex-col gap-2 w-full'>
            <Text size='medium' variant='secondary'>
              Selected Chemicals
            </Text>

            <div className='flex flex-col gap-2 mt-4'>
              {cp.weed_control?.map((item) => (
                <div key={item.chemical_price_detail_id} className='flex items-center gap-2'>
                  <ul className='list-disc'>
                    <li>
                      <Text size='medium' variant='secondary'>
                        {item.chemical_schedule} |{' '}
                        {herbicides?.find(
                          (herbicide) =>
                            herbicide?.herbicide_price_detail_id ===
                              item.chemical_price_detail_id && herbicide?.weed_id === item.weed_id
                        )?.herbicide_name ?? null}{' '}
                        |{' '}
                        {herbicides?.find(
                          (herbicide) =>
                            herbicide?.herbicide_price_detail_id ===
                              item.chemical_price_detail_id && herbicide?.weed_id === item.weed_id
                        )?.active_ingredient ?? null}
                      </Text>
                    </li>
                  </ul>
                </div>
              ))}

              {cp.weed_control?.map((item) => {
                if (item.adjuvant_reg_no == null) {
                  return null
                }
                return (
                  <ul className='list-disc '>
                    <li>
                      <Text size='medium' variant='secondary'>
                        {adjuvants?.find(
                          (adjuvant) =>
                            adjuvant?.adjuvant_price_detail_id === item?.adjuvant_price_detail_id
                        )?.adjuvant_name ?? null}{' '}
                        | (Adjuvant)
                      </Text>
                    </li>
                  </ul>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
