import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponse, type SoilSampleAnalysis } from 'types'
import { type AxiosError } from 'axios'

export const getSSA = async (
  farmId: string | number | null | undefined,
  farmerId: number | null | undefined,
  ssrId: number | null | undefined
): Promise<ApiResponse<SoilSampleAnalysis>> => {
  return axios.get(
    `ssr/analysis?farmer_id=${farmerId ?? ''}&farm_id=${farmId ?? ''}&ssr_id=${ssrId ?? ''}`
  )
}

export const useGetSSA = (
  farmId: string | number | null | undefined,
  farmerId: number | null | undefined,
  ssrId: number | null | undefined
): UseQueryResult<ApiResponse<SoilSampleAnalysis>, AxiosError> => {
  return useQuery({
    queryKey: ['ssa', ssrId, farmerId, farmId],
    queryFn: async () => getSSA(farmId, farmerId, ssrId),
    enabled: farmerId != null && farmId != null && ssrId != null
  })
}
