import type React from 'react'
import { motion } from 'framer-motion'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '../../../../../components/ui'
import { FAQData } from '../About/Data/FAQData'
import { useTranslation } from 'react-i18next'

const FAQAccordion: React.FC = () => {
  const { t } = useTranslation('landing')
  return (
    <div className='flex flex-col py-20 px-10 md:px-40 xl:px-96 bg-blue-50 space-y-8'>
      <div className='container mx-auto flex flex-col  md:justify-center md:items-center space-y-12 mb-12'>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className='text-3xl md:text-4xl lg:text-5xl font-bold text-gray-700 landing-page'>
            {t('faq.faq')}
          </h2>
        </motion.div>
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Accordion type='single' collapsible className='w-full'>
          {FAQData(t).map((section, index) => (
            <AccordionItem
              value={`item-${index}`}
              key={index}
              className='border border-gray-300 mb-6 px-4 rounded-lg bg-white'
            >
              <AccordionTrigger className='text-base md:text-xl lg:text-3xl font-bold text-gray-700 landing-page'>
                {section.title}
              </AccordionTrigger>
              <AccordionContent>
                <p className='text-base md:text-lg lg:text-xl mb-4 font-regular text-gray-600 landing-page'>
                  {section.description}
                </p>
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </motion.div>
    </div>
  )
}

export default FAQAccordion