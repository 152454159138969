import type { Farm, Lease, SoilSampleRequest } from '../../../../types'
import { type FC } from 'react'
import { useGetSoilCorrectionRecommendation } from '../api/getRecommendation'
import { useFarmerStore } from '../../../../stores/useFarmerStore'
import { TabView } from '../../../../components/App/Layout'
import { Button, Loader, Text } from '../../../../components/ui'
import { Error } from '../../../../components/Errors/Error'
import {
  type FarmInfo,
  SoilCorrectionRecommendationTable
} from './table/SoilCorrectionRecommendationTable'
import { SoilRecommendationColumns } from './table/soil-correction-table'
import { CircleOff, FileInput } from 'lucide-react'
import type { SoilCorrection } from '../../../../types/soilCorrection'
import { useTranslation } from 'react-i18next'

interface Base {
  ssr: SoilSampleRequest | undefined
  correction: SoilCorrection | undefined
  onClose: () => void
}

interface WithFarm extends Base {
  farm: Farm
  lease?: never
}

interface WithLease extends Base {
  farm?: never
  lease: Lease
}

type SoilRecommendationProps = WithFarm | WithLease

export const SoilRecommendation: FC<SoilRecommendationProps> = ({
  farm,
  lease,
  ssr,
  correction,
  onClose
}) => {
  const { farmer } = useFarmerStore()
  const { data, isLoading, error } = useGetSoilCorrectionRecommendation(
    farmer?.farmer_id,
    ssr?.ssr_id,
    farm?.farm_id,
    lease?.lease_id
  )
  const { t } = useTranslation('landManagement')

  if (isLoading) {
    return (
      <TabView>
        <div className={'grid place-items-center'}>
          <Loader />
        </div>
      </TabView>
    )
  }

  if (error != null) {
    if (error?.response?.status !== 404) {
      return <Error />
    }
  }

  const recommendations = data?.data.recommendations
  const comments = data?.data.comments
  const farmInfo: FarmInfo = {
    farmId: farm?.farm_id,
    leaseId: lease?.lease_id,
    ssrId: ssr?.ssr_id
  }

  if (recommendations == null) {
    return (
      <div className={'min-h-[300px] flex flex-col gap-4 items-center justify-center'}>
        <Text size={'xLarge'} className={'opacity-40'}>
          <CircleOff />
        </Text>
        <Text className={'text-center max-w-[400px]'} size={'medium'} variant={'bodyTextLight'}>
          {t('soilReco.sorry')}
        </Text>
      </div>
    )
  }

  return (
    <TabView>
      <div className={'flex justify-between items-center'}>
        <Text size={'medium'} variant={'colorText'}>
          {t('soilReco.reco')}
        </Text>
        <Button onClick={onClose} variant={'destructive'} size={'xs'}>
          {t('soilReco.cancel')}
        </Button>
      </div>
      <div className={'flex flex-col gap-2'}>
        <Text size={'small'} variant={'primaryLight'}>
          {t('soilReco.below')}
        </Text>
        <Text size={'body'} variant={'bodyTextLight'}>
          {t('soilReco.based')} {comments}.
        </Text>
      </div>
      <SoilCorrectionRecommendationTable
        data={recommendations}
        farmInfo={farmInfo}
        columns={SoilRecommendationColumns(t)}
        correction={correction}
        onClose={onClose}
      />
    </TabView>
  )
}