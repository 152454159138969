import type React from 'react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '../../../../../components/ui'
import { mmokoRewardPackagesData } from '../About/Data/MmokoRewardsAccordionData'
import { useTranslation } from 'react-i18next'

interface RewardData {
  year: string
  grade1: number
  grade2: number
}

interface TableData {
  categoryName: string
  cultivatedArea: string
  rewards: RewardData[]
}

interface SectionData {
  title: string
  description: string
  tables: TableData[]
}

interface MmokoRewardPackagesData {
  sections: SectionData[]
}

interface DataTableProps {
  data: RewardData[]
  t: (key: string) => string
}

const DataTable: React.FC<DataTableProps> = ({ data, t }) => (
  <table className='w-full border-collapse border border-gray-300 rounded-lg'>
    <thead>
      <tr>
        <th className='border border-gray-300 px-4 py-2 bg-blue-400'>
          <h3 className='text-xs md:text-sm lg:text-base font-semibold mb-2 text-white landing-page'>
            {t('mrp.mrpa1')}
          </h3>
        </th>
        <th className='border border-gray-300 px-4 py-2 bg-blue-400'>
          <h3 className='text-xs md:text-sm lg:text-base font-semibold mb-2 text-white landing-page'>
            {t('mrp.mrpa2')}
          </h3>
        </th>
        <th className='border border-gray-300 px-4 py-2 bg-blue-400'>
          <h3 className='text-xs md:text-sm lg:text-base font-semibold mb-2 text-white landing-page'>
            {t('mrp.mrpa3')}
          </h3>
        </th>
      </tr>
    </thead>
    <tbody>
      {data.map((row, index) => (
        <tr key={index}>
          <td className='border border-gray-300 px-4 py-2 text-center'>
            <p className='text-xs md:text-sm lg:text-base font-regular mb-2 text-gray-600 landing-page'>
              {row.year}
            </p>
          </td>
          <td className='border border-gray-300 px-4 py-2 text-center'>
            <p className='text-xs md:text-sm lg:text-base font-regular mb-2 text-gray-600 landing-page'>
              {row.grade1.toFixed(2)}
            </p>
          </td>
          <td className='border border-gray-300 px-4 py-2 text-center'>
            <p className='text-xs md:text-sm lg:text-base font-regular mb-2 text-gray-600 landing-page'>
              {row.grade2.toFixed(2)}
            </p>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

const MmokoRewardPackagesAccordion: React.FC = () => {
  const { t } = useTranslation('landing')
  const data: MmokoRewardPackagesData = mmokoRewardPackagesData(t)

  return (
    <div className='container mx-auto'>
      <Accordion type='single' collapsible className='w-full'>
        {data.sections.map((section, index) => (
          <AccordionItem
            value={`item-${index}`}
            key={index}
            className='border border-gray-300 mb-6 px-4 rounded-lg'
          >
            <AccordionTrigger className='text-base md:text-xl lg:text-3xl font-bold text-gray-700 landing-page'>
              {section.title}
            </AccordionTrigger>
            <AccordionContent>
              <p className='text-base md:text-lg lg:text-xl mb-4 font-regular text-gray-600 landing-page'>
                {section.description}
              </p>
              {section.tables.map((table, tableIndex) => (
                <div key={tableIndex} className='mb-8'>
                  <h3 className='text-sm md:text-base lg:text-lg font-semibold mb-2 text-gray-600 landing-page'>
                    {table.categoryName}
                  </h3>
                  <p className='text-xs md:text-sm lg:text-base mb-4 font-regular text-gray-600 landing-page'>
                    {t('mrp.ca')}: {table.cultivatedArea}
                  </p>
                  <DataTable data={table.rewards} t={t} />
                </div>
              ))}
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}

export default MmokoRewardPackagesAccordion