'use client'

import type React from 'react'
import { motion } from 'framer-motion'
import { ImageGallery } from 'react-image-grid-gallery'
import { useNavigate } from 'react-router-dom'
import { CTAButton } from '../Generic/CTAButton'
import Tractor from '../../../../../assets/landing/impact_in_action/7.jpeg'
import TractorTwo from '../../../../../assets/landing/impact_in_action/10.jpeg'
import SafflowerPetalTea from '../../../../../assets/landing/impact_in_action/11.jpeg'
import FarmTwo from '../../../../../assets/landing/impact_in_action/12.jpeg'
import { useTranslation } from 'react-i18next'

export const MiniGallery: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation('landing')

  const imagesArray = [
    {
      alt: 'Planter',
      caption: 'Planter',
      src: Tractor
    },
    {
      alt: 'Walk behind tractor demonstration at Voorslag show',
      caption: 'Walk behind tractor demonstration at Voorslag show',
      src: TractorTwo
    },
    {
      alt: 'Safflower by-productsd exhibited at Mookane field day',
      caption: 'Safflower by-productsd exhibited at Mookane field day',
      src: SafflowerPetalTea
    },
    {
      alt: 'Wheat irrigation in Pandamatenga',
      caption: 'Wheat irrigation in Pandamatenga',
      src: FarmTwo
    }
  ]

  return (
    <div className='bg-white w-full  '>
      <div className='container mx-auto py-10 md:py-20 px-10 flex flex-col justify-center w-screen md:w-full space-y-8'>
        <motion.div
          className='flex flex-col items-center mb-12'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className='text-4xl md:text-5xl lg:text-6xl md:justify-center md:items-center font-bold text-gray-700 landing-page'>
            {t('gal.imp')}
          </h2>
        </motion.div>

        <motion.div
          className='container mx-auto flex flex-col justify-center '
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <ImageGallery
            imagesInfoArray={imagesArray}
            columnCount={'auto'}
            columnWidth={300}
            gapSize={24}
          />
        </motion.div>

        <motion.div
          className='container mx-auto flex justify-center items-center'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <CTAButton label={t('gal.more')} to='/gallery' />
        </motion.div>
      </div>
    </div>
  )
}