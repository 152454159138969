import type { Farm, Lease } from 'types'
import type React from 'react'
import { useState, useEffect } from 'react'
import { CroppingPlanHeader } from './steps/header/CroppingPlanHeader'
import { Step, Stepper } from 'components/ui/stepper'
import { SeedSelection } from './steps/seeding/SeedSelection'
import { useMedia } from 'react-use'
import { LandPreparation } from './steps/land/LandPreparation'
import { Harvesting } from './steps/harvesting/Harvesting'
import { Packaging } from './steps/packaging/Packaging'
import { ShellingAndThreshing } from './steps/cleaning/ShellingAndThreshing'
import { CroppingPlanReview } from './steps/review/Review'
import { LastStep } from './steps/LastStep'
import { useTranslation } from 'react-i18next'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { useFarmerStore } from '../../../../stores/useFarmerStore'
import { WeedingPlan } from './steps/weeding/WeedingPlan'
import { PestChemicalSelection } from './steps/pest/chemical/PestChemicalSelection'
import { toast } from '../../../../components/ui'
import { useCroppingPlanStore } from '../stores/useCroppingPlanStore'
import { TabView } from '../../../../components/App/Layout'

interface Base {
  cancel: () => void
}

interface WithFarm extends Base {
  farm: Farm
  lease?: never
}

interface WithLease extends Base {
  farm?: never
  lease: Lease
}

type CreateCroppingPlanProps = WithFarm | WithLease

type StepperSteps = Array<
  { label: string; optional?: undefined } | { label: string; optional: boolean }
>

export const CreateCroppingPlan: React.FC<CreateCroppingPlanProps> = ({ farm, lease, cancel }) => {
  const { farmer } = useFarmerStore()
  const { header } = useCroppingPlanStore()
  const isMicro = farmer?.class_id != null && farmer?.class_id !== 'TAXPAYER'
  const flags = useFlags()
  const ldClient = useLDClient()
  const isDesktop = useMedia('(min-width: 900px)')
  const { t } = useTranslation('croppingPlan')

  const [isApproved, setIsApproved] = useState(false)

  const getSteps = (): StepperSteps => {
    if (
      isMicro &&
      flags.microShelling === false &&
      flags.microHarvesting === false &&
      flags.microPackaging === false
    ) {
      return [
        { label: `${t('stepper.crop')}` },
        { label: `${t('stepper.seeding')}` },
        { label: `${t('stepper.weeding')}`, optional: true },
        { label: `${t('stepper.pest')}`, optional: true },
        { label: `${t('stepper.land')}` },
        { label: `${t('stepper.review')}` }
      ]
    } else if (
      isMicro &&
      flags.microShelling === true &&
      flags.microHarvesting === false &&
      flags.microPackaging === false
    ) {
      return [
        { label: `${t('stepper.crop')}` },
        { label: `${t('stepper.seeding')}` },
        { label: `${t('stepper.weeding')}`, optional: true },
        { label: `${t('stepper.pest')}`, optional: true },
        { label: `${t('stepper.land')}` },
        { label: `${t('stepper.shelling')}`, optional: true },
        { label: `${t('stepper.review')}` }
      ]
    } else if (
      isMicro &&
      flags.microShelling === true &&
      flags.microHarvesting === true &&
      flags.microPackaging === false
    ) {
      return [
        { label: `${t('stepper.crop')}` },
        { label: `${t('stepper.seeding')}` },
        { label: `${t('stepper.weeding')}`, optional: true },
        { label: `${t('stepper.pest')}`, optional: true },
        { label: `${t('stepper.land')}` },
        { label: `${t('stepper.harvesting')}`, optional: true },
        { label: `${t('stepper.shelling')}`, optional: true },
        { label: `${t('stepper.review')}` }
      ]
    } else if (
      isMicro &&
      flags.microShelling === false &&
      flags.microHarvesting === true &&
      flags.microPackaging === false
    ) {
      return [
        { label: `${t('stepper.crop')}` },
        { label: `${t('stepper.seeding')}` },
        { label: `${t('stepper.weeding')}`, optional: true },
        { label: `${t('stepper.pest')}`, optional: true },
        { label: `${t('stepper.land')}` },
        { label: `${t('stepper.harvesting')}`, optional: true },
        { label: `${t('stepper.review')}` }
      ]
    } else if (
      isMicro &&
      flags.microShelling === false &&
      flags.microHarvesting === true &&
      flags.microPackaging === true
    ) {
      return [
        { label: `${t('stepper.crop')}` },
        { label: `${t('stepper.seeding')}` },
        { label: `${t('stepper.weeding')}`, optional: true },
        { label: `${t('stepper.pest')}`, optional: true },
        { label: `${t('stepper.land')}` },
        { label: `${t('stepper.harvesting')}`, optional: true },
        { label: `${t('stepper.packaging')}`, optional: true },
        { label: `${t('stepper.review')}` }
      ]
    } else if (
      isMicro &&
      flags.microShelling === false &&
      flags.microHarvesting === false &&
      flags.microPackaging === true
    ) {
      return [
        { label: `${t('stepper.crop')}` },
        { label: `${t('stepper.seeding')}` },
        { label: `${t('stepper.weeding')}`, optional: true },
        { label: `${t('stepper.pest')}`, optional: true },
        { label: `${t('stepper.land')}` },
        { label: `${t('stepper.packaging')}`, optional: true },
        { label: `${t('stepper.review')}` }
      ]
    } else if (
      isMicro &&
      flags.microShelling === true &&
      flags.microHarvesting === false &&
      flags.microPackaging === true
    ) {
      return [
        { label: `${t('stepper.crop')}` },
        { label: `${t('stepper.seeding')}` },
        { label: `${t('stepper.weeding')}`, optional: true },
        { label: `${t('stepper.pest')}`, optional: true },
        { label: `${t('stepper.land')}` },
        { label: `${t('stepper.shelling')}`, optional: true },
        { label: `${t('stepper.packaging')}`, optional: true },
        { label: `${t('stepper.review')}` }
      ]
    } else {
      return [
        { label: `${t('stepper.crop')}` },
        { label: `${t('stepper.seeding')}` },
        { label: `${t('stepper.weeding')}`, optional: true },
        { label: `${t('stepper.pest')}`, optional: true },
        { label: `${t('stepper.land')}` },
        { label: `${t('stepper.harvesting')}`, optional: true },
        { label: `${t('stepper.shelling')}`, optional: true },
        { label: `${t('stepper.packaging')}`, optional: true },
        { label: `${t('stepper.review')}` }
      ]
    }
  }

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  const handleStepSkip = (step: number, setStep: (step: number) => void): void => {
    if (step > 0 && header == null) {
      toast({
        variant: 'destructive',
        title: 'Please complete the first step',
        description: 'You need to complete the first step before proceeding to the next step'
      })
      return
    }

    setStep(step)
  }

  if (farm != null) {
    if (
      isMicro &&
      flags.microShelling === false &&
      flags.microHarvesting === false &&
      flags.microPackaging === false
    ) {
      return (
        <div className={'w-full flex flex-col gap-4'}>
          <Stepper
            variant={isDesktop ? 'circle-alt' : 'line'}
            size={'sm'}
            initialStep={0}
            mobileBreakpoint={'900px'}
            steps={getSteps()}
            onClickStep={handleStepSkip}
            styles={{
              'step-label': 'text-xs md:text-sm'
            }}
            scrollTracking
          >
            {getSteps().map((stepProps, index) => {
              if (index === 0) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <CroppingPlanHeader cancel={cancel} farm={farm} />
                  </Step>
                )
              } else if (index === 1) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <SeedSelection />
                  </Step>
                )
              } else if (index === 2) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <WeedingPlan />
                  </Step>
                )
              } else if (index === 3) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <PestChemicalSelection />
                  </Step>
                )
              } else if (index === 4) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <LandPreparation />
                  </Step>
                )
              }
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <CroppingPlanReview
                    onClose={() => {
                      setIsApproved(false)
                    }}
                  />
                </Step>
              )
            })}
            <LastStep next={cancel} />
          </Stepper>
        </div>
      )
    } else if (
      isMicro &&
      flags.microShelling === true &&
      flags.microHarvesting === false &&
      flags.microPackaging === false
    ) {
      return (
        <div className=''>
          {/* <div className={'w-full flex flex-col gap-4'}> */}
          <Stepper
            variant={isDesktop ? 'circle-alt' : 'line'}
            size={'sm'}
            initialStep={0}
            mobileBreakpoint={'900px'}
            steps={getSteps()}
            onClickStep={handleStepSkip}
            styles={{
              'step-label': 'text-xs md:text-sm'
            }}
            scrollTracking
          >
            {getSteps().map((stepProps, index) => {
              if (index === 0) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <CroppingPlanHeader cancel={cancel} farm={farm} />
                  </Step>
                )
              } else if (index === 1) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <SeedSelection />
                  </Step>
                )
              } else if (index === 2) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <WeedingPlan />
                  </Step>
                )
              } else if (index === 3) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <PestChemicalSelection />
                  </Step>
                )
              } else if (index === 4) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <LandPreparation />
                  </Step>
                )
              } else if (index === 5) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <ShellingAndThreshing />
                  </Step>
                )
              }
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <CroppingPlanReview
                    onClose={() => {
                      setIsApproved(false)
                    }}
                  />
                </Step>
              )
            })}
            <LastStep next={cancel} />
          </Stepper>
          {/* </div> */}
        </div>
      )
    } else if (
      isMicro &&
      flags.microShelling === true &&
      flags.microHarvesting === true &&
      flags.microPackaging === false
    ) {
      return (
        <div className={'w-full flex flex-col gap-4'}>
          <Stepper
            variant={isDesktop ? 'circle-alt' : 'line'}
            size={'sm'}
            initialStep={0}
            mobileBreakpoint={'900px'}
            steps={getSteps()}
            onClickStep={handleStepSkip}
            styles={{
              'step-label': 'text-xs md:text-sm'
            }}
            scrollTracking
          >
            {getSteps().map((stepProps, index) => {
              if (index === 0) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <CroppingPlanHeader cancel={cancel} farm={farm} />
                  </Step>
                )
              } else if (index === 1) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <SeedSelection />
                  </Step>
                )
              } else if (index === 2) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <WeedingPlan />
                  </Step>
                )
              } else if (index === 3) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <PestChemicalSelection />
                  </Step>
                )
              } else if (index === 4) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <LandPreparation />
                  </Step>
                )
              } else if (index === 5) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <Harvesting />
                  </Step>
                )
              } else if (index === 6) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <ShellingAndThreshing />
                  </Step>
                )
              }
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <CroppingPlanReview
                    onClose={() => {
                      setIsApproved(false)
                    }}
                  />
                </Step>
              )
            })}
            <LastStep next={cancel} />
          </Stepper>
        </div>
      )
    } else if (
      isMicro &&
      flags.microShelling === false &&
      flags.microHarvesting === true &&
      flags.microPackaging === false
    ) {
      return (
        <div className={'w-full flex flex-col gap-4'}>
          <Stepper
            variant={isDesktop ? 'circle-alt' : 'line'}
            size={'sm'}
            initialStep={0}
            mobileBreakpoint={'900px'}
            steps={getSteps()}
            onClickStep={handleStepSkip}
            styles={{
              'step-label': 'text-xs md:text-sm'
            }}
            scrollTracking
          >
            {getSteps().map((stepProps, index) => {
              if (index === 0) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <CroppingPlanHeader cancel={cancel} farm={farm} />
                  </Step>
                )
              } else if (index === 1) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <SeedSelection />
                  </Step>
                )
              } else if (index === 2) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <WeedingPlan />
                  </Step>
                )
              } else if (index === 3) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <PestChemicalSelection />
                  </Step>
                )
              } else if (index === 4) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <LandPreparation />
                  </Step>
                )
              } else if (index === 5) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <Harvesting />
                  </Step>
                )
              }
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <CroppingPlanReview
                    onClose={() => {
                      setIsApproved(false)
                    }}
                  />
                </Step>
              )
            })}
            <LastStep next={cancel} />
          </Stepper>
        </div>
      )
    } else if (
      isMicro &&
      flags.microShelling === false &&
      flags.microHarvesting === true &&
      flags.microPackaging === true
    ) {
      return (
        <div className={'w-full flex flex-col gap-4'}>
          <Stepper
            variant={isDesktop ? 'circle-alt' : 'line'}
            size={'sm'}
            initialStep={0}
            mobileBreakpoint={'900px'}
            steps={getSteps()}
            onClickStep={handleStepSkip}
            styles={{
              'step-label': 'text-xs md:text-sm'
            }}
            scrollTracking
          >
            {getSteps().map((stepProps, index) => {
              if (index === 0) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <CroppingPlanHeader cancel={cancel} farm={farm} />
                  </Step>
                )
              } else if (index === 1) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <SeedSelection />
                  </Step>
                )
              } else if (index === 2) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <WeedingPlan />
                  </Step>
                )
              } else if (index === 3) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <PestChemicalSelection />
                  </Step>
                )
              } else if (index === 4) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <LandPreparation />
                  </Step>
                )
              } else if (index === 5) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <Harvesting />
                  </Step>
                )
              } else if (index === 6) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <Packaging />
                  </Step>
                )
              }
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <CroppingPlanReview
                    onClose={() => {
                      setIsApproved(false)
                    }}
                  />
                </Step>
              )
            })}
            <LastStep next={cancel} />
          </Stepper>
        </div>
      )
    } else if (
      isMicro &&
      flags.microShelling === false &&
      flags.microHarvesting === false &&
      flags.microPackaging === true
    ) {
      return (
        <div className={'w-full flex flex-col gap-4'}>
          <Stepper
            variant={isDesktop ? 'circle-alt' : 'line'}
            size={'sm'}
            initialStep={0}
            mobileBreakpoint={'900px'}
            steps={getSteps()}
            onClickStep={handleStepSkip}
            styles={{
              'step-label': 'text-xs md:text-sm'
            }}
            scrollTracking
          >
            {getSteps().map((stepProps, index) => {
              if (index === 0) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <CroppingPlanHeader cancel={cancel} farm={farm} />
                  </Step>
                )
              } else if (index === 1) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <SeedSelection />
                  </Step>
                )
              } else if (index === 2) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <WeedingPlan />
                  </Step>
                )
              } else if (index === 3) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <PestChemicalSelection />
                  </Step>
                )
              } else if (index === 4) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <LandPreparation />
                  </Step>
                )
              } else if (index === 5) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <Packaging />
                  </Step>
                )
              }
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <CroppingPlanReview
                    onClose={() => {
                      setIsApproved(false)
                    }}
                  />
                </Step>
              )
            })}
            <LastStep next={cancel} />
          </Stepper>
        </div>
      )
    } else if (
      isMicro &&
      flags.microShelling === true &&
      flags.microHarvesting === false &&
      flags.microPackaging === true
    ) {
      return (
        <div className={'w-full flex flex-col gap-4'}>
          <Stepper
            variant={isDesktop ? 'circle-alt' : 'line'}
            size={'sm'}
            initialStep={0}
            mobileBreakpoint={'900px'}
            steps={getSteps()}
            onClickStep={handleStepSkip}
            styles={{
              'step-label': 'text-xs md:text-sm'
            }}
            scrollTracking
          >
            {getSteps().map((stepProps, index) => {
              if (index === 0) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <CroppingPlanHeader cancel={cancel} farm={farm} />
                  </Step>
                )
              } else if (index === 1) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <SeedSelection />
                  </Step>
                )
              } else if (index === 2) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <WeedingPlan />
                  </Step>
                )
              } else if (index === 3) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <PestChemicalSelection />
                  </Step>
                )
              } else if (index === 4) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <LandPreparation />
                  </Step>
                )
              } else if (index === 5) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <ShellingAndThreshing />
                  </Step>
                )
              } else if (index === 6) {
                return (
                  <Step key={stepProps.label} {...stepProps}>
                    <Packaging />
                  </Step>
                )
              }
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <CroppingPlanReview
                    onClose={() => {
                      setIsApproved(false)
                    }}
                  />
                </Step>
              )
            })}
            <LastStep next={cancel} />
          </Stepper>
        </div>
      )
    }
    return (
      <div className={'w-full flex flex-col gap-4'}>
        <Stepper
          variant={isDesktop ? 'circle-alt' : 'line'}
          size={'sm'}
          initialStep={0}
          mobileBreakpoint={'900px'}
          steps={getSteps()}
          onClickStep={handleStepSkip}
          styles={{
            'step-label': 'text-xs md:text-sm'
          }}
          scrollTracking
        >
          {getSteps().map((stepProps, index) => {
            if (index === 0) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <CroppingPlanHeader cancel={cancel} farm={farm} />
                </Step>
              )
            } else if (index === 1) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <SeedSelection />
                </Step>
              )
            } else if (index === 2) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <WeedingPlan />
                </Step>
              )
            } else if (index === 3) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <PestChemicalSelection />
                </Step>
              )
            } else if (index === 4) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <LandPreparation />
                </Step>
              )
            } else if (index === 5) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <Harvesting />
                </Step>
              )
            } else if (index === 6) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <ShellingAndThreshing />
                </Step>
              )
            } else if (index === 7) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <Packaging />
                </Step>
              )
            }
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CroppingPlanReview
                  onClose={() => {
                    setIsApproved(false)
                  }}
                />
              </Step>
            )
          })}
          <LastStep next={cancel} />
        </Stepper>
      </div>
    )
  }

  if (
    isMicro &&
    flags.microShelling === false &&
    flags.microHarvesting === false &&
    flags.microPackaging === false
  ) {
    return (
      <div className={'w-full flex flex-col gap-4'}>
        <Stepper
          variant={isDesktop ? 'circle-alt' : 'line'}
          size={'sm'}
          initialStep={0}
          mobileBreakpoint={'900px'}
          steps={getSteps()}
          onClickStep={handleStepSkip}
          styles={{
            'step-label': 'text-xs md:text-sm'
          }}
          scrollTracking
        >
          {getSteps().map((stepProps, index) => {
            if (index === 0) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <CroppingPlanHeader cancel={cancel} lease={lease} />
                </Step>
              )
            } else if (index === 1) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <SeedSelection />
                </Step>
              )
            } else if (index === 2) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <WeedingPlan />
                </Step>
              )
            } else if (index === 3) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <PestChemicalSelection />
                </Step>
              )
            } else if (index === 4) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <LandPreparation />
                </Step>
              )
            }
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CroppingPlanReview
                  onClose={() => {
                    setIsApproved(false)
                  }}
                />
              </Step>
            )
          })}
          <LastStep next={cancel} />
        </Stepper>
      </div>
    )
  } else if (
    isMicro &&
    flags.microShelling === true &&
    flags.microHarvesting === false &&
    flags.microPackaging === false
  ) {
    return (
      <div className={'w-full flex flex-col gap-4'}>
        <Stepper
          variant={isDesktop ? 'circle-alt' : 'line'}
          size={'sm'}
          initialStep={0}
          mobileBreakpoint={'900px'}
          steps={getSteps()}
          onClickStep={handleStepSkip}
          styles={{
            'step-label': 'text-xs md:text-sm'
          }}
          scrollTracking
        >
          {getSteps().map((stepProps, index) => {
            if (index === 0) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <CroppingPlanHeader cancel={cancel} lease={lease} />
                </Step>
              )
            } else if (index === 1) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <SeedSelection />
                </Step>
              )
            } else if (index === 2) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <WeedingPlan />
                </Step>
              )
            } else if (index === 3) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <PestChemicalSelection />
                </Step>
              )
            } else if (index === 4) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <LandPreparation />
                </Step>
              )
            } else if (index === 5) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <ShellingAndThreshing />
                </Step>
              )
            }
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CroppingPlanReview
                  onClose={() => {
                    setIsApproved(false)
                  }}
                />
              </Step>
            )
          })}
          <LastStep next={cancel} />
        </Stepper>
      </div>
    )
  } else if (
    isMicro &&
    flags.microShelling === true &&
    flags.microHarvesting === true &&
    flags.microPackaging === false
  ) {
    return (
      <div className={'w-full flex flex-col gap-4'}>
        <Stepper
          variant={isDesktop ? 'circle-alt' : 'line'}
          size={'sm'}
          initialStep={0}
          mobileBreakpoint={'900px'}
          steps={getSteps()}
          onClickStep={handleStepSkip}
          styles={{
            'step-label': 'text-xs md:text-sm'
          }}
          scrollTracking
        >
          {getSteps().map((stepProps, index) => {
            if (index === 0) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <CroppingPlanHeader cancel={cancel} lease={lease} />
                </Step>
              )
            } else if (index === 1) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <SeedSelection />
                </Step>
              )
            } else if (index === 2) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <WeedingPlan />
                </Step>
              )
            } else if (index === 3) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <PestChemicalSelection />
                </Step>
              )
            } else if (index === 4) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <LandPreparation />
                </Step>
              )
            } else if (index === 5) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <Harvesting />
                </Step>
              )
            } else if (index === 6) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <ShellingAndThreshing />
                </Step>
              )
            }
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CroppingPlanReview
                  onClose={() => {
                    setIsApproved(false)
                  }}
                />
              </Step>
            )
          })}
          <LastStep next={cancel} />
        </Stepper>
      </div>
    )
  } else if (
    isMicro &&
    flags.microShelling === false &&
    flags.microHarvesting === true &&
    flags.microPackaging === false
  ) {
    return (
      <div className={'w-full flex flex-col gap-4'}>
        <Stepper
          variant={isDesktop ? 'circle-alt' : 'line'}
          size={'sm'}
          initialStep={0}
          mobileBreakpoint={'900px'}
          steps={getSteps()}
          onClickStep={handleStepSkip}
          styles={{
            'step-label': 'text-xs md:text-sm'
          }}
          scrollTracking
        >
          {getSteps().map((stepProps, index) => {
            if (index === 0) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <CroppingPlanHeader cancel={cancel} lease={lease} />
                </Step>
              )
            } else if (index === 1) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <SeedSelection />
                </Step>
              )
            } else if (index === 2) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <WeedingPlan />
                </Step>
              )
            } else if (index === 3) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <PestChemicalSelection />
                </Step>
              )
            } else if (index === 4) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <LandPreparation />
                </Step>
              )
            } else if (index === 5) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <Harvesting />
                </Step>
              )
            }
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CroppingPlanReview
                  onClose={() => {
                    setIsApproved(false)
                  }}
                />
              </Step>
            )
          })}
          <LastStep next={cancel} />
        </Stepper>
      </div>
    )
  } else if (
    isMicro &&
    flags.microShelling === false &&
    flags.microHarvesting === true &&
    flags.microPackaging === true
  ) {
    return (
      <div className={'w-full flex flex-col gap-4'}>
        <Stepper
          variant={isDesktop ? 'circle-alt' : 'line'}
          size={'sm'}
          initialStep={0}
          mobileBreakpoint={'900px'}
          steps={getSteps()}
          onClickStep={handleStepSkip}
          styles={{
            'step-label': 'text-xs md:text-sm'
          }}
          scrollTracking
        >
          {getSteps().map((stepProps, index) => {
            if (index === 0) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <CroppingPlanHeader cancel={cancel} lease={lease} />
                </Step>
              )
            } else if (index === 1) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <SeedSelection />
                </Step>
              )
            } else if (index === 2) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <WeedingPlan />
                </Step>
              )
            } else if (index === 3) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <PestChemicalSelection />
                </Step>
              )
            } else if (index === 4) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <LandPreparation />
                </Step>
              )
            } else if (index === 5) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <Harvesting />
                </Step>
              )
            } else if (index === 6) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <Packaging />
                </Step>
              )
            }
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CroppingPlanReview
                  onClose={() => {
                    setIsApproved(false)
                  }}
                />
              </Step>
            )
          })}
          <LastStep next={cancel} />
        </Stepper>
      </div>
    )
  } else if (
    isMicro &&
    flags.microShelling === false &&
    flags.microHarvesting === false &&
    flags.microPackaging === true
  ) {
    return (
      <div className={'w-full flex flex-col gap-4'}>
        <Stepper
          variant={isDesktop ? 'circle-alt' : 'line'}
          size={'sm'}
          initialStep={0}
          mobileBreakpoint={'900px'}
          steps={getSteps()}
          onClickStep={handleStepSkip}
          styles={{
            'step-label': 'text-xs md:text-sm'
          }}
          scrollTracking
        >
          {getSteps().map((stepProps, index) => {
            if (index === 0) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <CroppingPlanHeader cancel={cancel} lease={lease} />
                </Step>
              )
            } else if (index === 1) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <SeedSelection />
                </Step>
              )
            } else if (index === 2) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <WeedingPlan />
                </Step>
              )
            } else if (index === 3) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <PestChemicalSelection />
                </Step>
              )
            } else if (index === 4) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <LandPreparation />
                </Step>
              )
            } else if (index === 5) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <Packaging />
                </Step>
              )
            }
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CroppingPlanReview
                  onClose={() => {
                    setIsApproved(false)
                  }}
                />
              </Step>
            )
          })}
          <LastStep next={cancel} />
        </Stepper>
      </div>
    )
  } else if (
    isMicro &&
    flags.microShelling === true &&
    flags.microHarvesting === false &&
    flags.microPackaging === true
  ) {
    return (
      <div className={'w-full flex flex-col gap-4'}>
        <Stepper
          variant={isDesktop ? 'circle-alt' : 'line'}
          size={'sm'}
          initialStep={0}
          mobileBreakpoint={'900px'}
          steps={getSteps()}
          onClickStep={handleStepSkip}
          styles={{
            'step-label': 'text-xs md:text-sm'
          }}
          scrollTracking
        >
          {getSteps().map((stepProps, index) => {
            if (index === 0) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <CroppingPlanHeader cancel={cancel} lease={lease} />
                </Step>
              )
            } else if (index === 1) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <SeedSelection />
                </Step>
              )
            } else if (index === 2) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <WeedingPlan />
                </Step>
              )
            } else if (index === 3) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <PestChemicalSelection />
                </Step>
              )
            } else if (index === 4) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <LandPreparation />
                </Step>
              )
            } else if (index === 5) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <ShellingAndThreshing />
                </Step>
              )
            } else if (index === 6) {
              return (
                <Step key={stepProps.label} {...stepProps}>
                  <Packaging />
                </Step>
              )
            }
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CroppingPlanReview
                  onClose={() => {
                    setIsApproved(false)
                  }}
                />
              </Step>
            )
          })}
          <LastStep next={cancel} />
        </Stepper>
      </div>
    )
  }
  return (
    <div className={'w-full flex flex-col gap-4'}>
      <Stepper
        variant={isDesktop ? 'circle-alt' : 'line'}
        size={'sm'}
        initialStep={0}
        mobileBreakpoint={'900px'}
        steps={getSteps()}
        onClickStep={handleStepSkip}
        styles={{
          'step-label': 'text-xs md:text-sm'
        }}
        scrollTracking
      >
        {getSteps().map((stepProps, index) => {
          if (index === 0) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CroppingPlanHeader cancel={cancel} lease={lease} />
              </Step>
            )
          } else if (index === 1) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <SeedSelection />
              </Step>
            )
          } else if (index === 2) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <WeedingPlan />
              </Step>
            )
          } else if (index === 3) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <PestChemicalSelection />
              </Step>
            )
          } else if (index === 4) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <LandPreparation />
              </Step>
            )
          } else if (index === 5) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <Harvesting />
              </Step>
            )
          } else if (index === 6) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <ShellingAndThreshing />
              </Step>
            )
          } else if (index === 7) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <Packaging />
              </Step>
            )
          }
          return (
            <Step key={stepProps.label} {...stepProps}>
              <CroppingPlanReview
                onClose={() => {
                  setIsApproved(false)
                }}
              />
            </Step>
          )
        })}
        <LastStep next={cancel} />
      </Stepper>
    </div>
  )
}
