import { type FC } from 'react'
import { Button, Text } from '../../../../components/ui'
import { CircleOff, FileInput } from 'lucide-react'
import { TabView } from '../../../../components/App/Layout'
import { type SoilCorrection } from '../../../../types/soilCorrection'
import type { SoilSampleRequest } from '../../../../types'
import { SoilCorrectionCard } from './SoilCorrectionCard'
import { useTranslation } from 'react-i18next'

interface SoilCorrectionsProps {
  onNewRecommendation: () => void
  isSSRValid: boolean
  scr: SoilCorrection | undefined
  ssr: SoilSampleRequest | undefined
}

export const SoilCorrections: FC<SoilCorrectionsProps> = ({
  onNewRecommendation,
  isSSRValid,
  scr,
  ssr
}) => {
  const { t } = useTranslation('landManagement')
  // no valid ssr
  if (!isSSRValid || ssr == null) {
    return (
      <TabView>
        <div className={'flex justify-between items-center'}>
          <Text size={'medium'}>{t('soilCorr.soil')}</Text>
          <Button
            onClick={onNewRecommendation}
            variant={'warning'}
            size={'xs'}
            disabled={!isSSRValid}
          >
            {t('soilCorr.new')}
            <FileInput size={'12'} />
          </Button>
        </div>
        <div className={'min-h-[300px] flex flex-col gap-4 items-center justify-center'}>
          <Text size={'xLarge'} className={'opacity-40'}>
            <CircleOff />
          </Text>
          <Text className={'text-center max-w-[400px]'} size={'medium'} variant={'bodyTextLight'}>
            {t('soilCorr.please')}
          </Text>
        </div>
      </TabView>
    )
  }

  if (scr == null) {
    return (
      <TabView>
        <div className={'flex justify-between items-center'}>
          <Text size={'medium'}>Soil Corrections</Text>
          <Button
            onClick={onNewRecommendation}
            variant={'warning'}
            size={'xs'}
            disabled={!isSSRValid}
          >
            {t('soilCorr.new')}
            <FileInput size={'12'} />
          </Button>
        </div>
        <div className={'min-h-[300px] flex flex-col gap-4 items-center justify-center'}>
          <Text size={'xLarge'} className={'opacity-40'}>
            <CircleOff />
          </Text>
          <Text size={'medium'} variant={'bodyTextLight'}>
            {t('soilCorr.none')}
          </Text>
        </div>
      </TabView>
    )
  }

  return (
    <TabView>
      <div className={'flex justify-between items-center'}>
        <Text size={'medium'}>Soil Corrections</Text>
        <Button onClick={onNewRecommendation} variant={'primary'} size={'xs'}>
          {t('soilCorr.update')}
          <FileInput size={'12'} />
        </Button>
      </div>
      <SoilCorrectionCard ssr={ssr} scr={scr} />
    </TabView>
  )
}