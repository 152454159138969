import { type FC } from 'react'
import { useTransferStore } from '../../../../stores/transferStore'
import { Button, Text } from '../../../../../../../components/ui'
import { useTranslation } from 'react-i18next'
import { useOtpVerification } from '../../../../../../../hooks/useOtpVerification'
import { type Farm } from 'types'

interface FarmTransferDisclaimerProps {
  farm: Farm
  next: () => void
}

export const FarmTransferDisclaimer: FC<FarmTransferDisclaimerProps> = ({ next, farm }) => {
  const { destinationDetails } = useTransferStore()
  const { t } = useTranslation('landManagement')

  const { isLoading, sendOtpMessage } = useOtpVerification('', farm.farmer_contact)

  const onSubmit = (): void => {
    sendOtpMessage(next)
  }

  return (
    <div className={'w-full flex flex-col gap-4'}>
      <Text className={'text-center'} size={'large'} variant={'colorText'}>
        {t('ownership.transferFarm')}
      </Text>

      <Text className={'text-center'} size={'medium'} variant={'bodyTextLight'}>
        {t('ownership.sure')} <br />
        <strong>{`${destinationDetails?.firstname ?? ''} ${
          destinationDetails?.surname ?? ''
        }`}</strong>
      </Text>

      <Text variant={'error'} className={'text-center font-bold uppercase'} size={'medium'}>
        {t('ownership.note')}
      </Text>

      <div className={'w-full flex justify-between gap-4 md:gap-8'}>
        <Button disabled={isLoading} variant={'secondary'} className={'w-1/2'} onClick={next}>
          {t('ownership.cancel')}
        </Button>
        <Button
          disabled={isLoading}
          loading={isLoading}
          className={'w-1/2'}
          variant={'success'}
          onClick={onSubmit}
        >
          {t('ownership.transfer')}
        </Button>
      </div>
    </div>
  )
}
