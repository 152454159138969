import type React from 'react'
import { Button, Text } from 'components/ui'
import { useTranslation } from 'react-i18next'

interface RejectOrReturnProps {
  reject: () => void
  returnCp: () => void
}

export const RejectOrReturn: React.FC<RejectOrReturnProps> = ({ reject, returnCp }) => {
  const { t } = useTranslation('extensionOfficer')

  return (
    <div className={'max-w-[400px] w-full flex flex-col items-center gap-8'}>
      <Text size={'medium'}>{t('cp.rrCP')}</Text>
      <div className={'w-full flex items-center justify-between gap-4'}>
        <Button variant={'secondary'} className='w-1/2 max-w-[18rem]' onClick={returnCp}>
          {t('cp.return')}
        </Button>
        <Button variant={'destructive'} className='w-1/2 max-w-[18rem]' onClick={reject}>
          {t('cp.reject')}
        </Button>
      </div>
    </div>
  )
}
