import type React from 'react'
import { type SoilSampleRequest } from 'types'
import { useTranslation } from 'react-i18next'
import { Button, Text } from '../../../../components/ui'
import { IoIosWarning } from 'react-icons/io'

interface RejectSSRProps {
  ssr: SoilSampleRequest
  onClose: () => void
}

export const RejectSsr: React.FC<RejectSSRProps> = ({ ssr, onClose }) => {
  const { t } = useTranslation('landManagement')

  return (
    <div className='w-full flex flex-col p-6'>
      <div className='w-full flex flex-col h-full'>
        <div className='w-full flex flex-col'>
          {/* Heading Message */}
          <Text size='medium'>{t('ssr.viewReject')} </Text>
          <div className='w-full flex flex-col h-full'>
            {/* <Text>{t('ssr.reason')}</Text> */}
            {/* Reject message inside a red container */}
            <div className='bg-red-100 mt-10 p-4 rounded-md flex'>
              <IoIosWarning fontSize='18' className='text-red-600 mr-4' />
              <p className='font-light text-gray-600 text-xs md:text-sm'>{ssr?.comment}</p>
            </div>
          </div>
        </div>

        {/* Buttons pushed to the bottom */}
        <div className='w-full flex flex-col mt-auto'>
          <Button
            variant={'success'}
            onClick={onClose}
            type={'submit'}
            size={'xs'}
            className='w-32 ml-auto'
          >
            <Text variant='colorText'>{t('ssr.okay')}</Text>
          </Button>
        </div>
      </div>
    </div>
  )
}
