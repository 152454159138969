import type React from 'react'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  Button,
  Dialog,
  DialogContent,
  Text,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from 'components/ui'

import { Box, TabView } from '../../../../components/App/Layout'
import { SearchBar } from '../../../../components/Form/SearchBar'
import { useTranslation } from 'react-i18next'
import { FarmOwners } from '../components/tabs/FarmOwners'
import { LeasedOwners } from '../components/tabs/LeasedOwners' // Adjust the path as needed

export const Farmers: React.FC = () => {
  const navigate = useNavigate()
  const [newRequest, setNewRequest] = useState(false)
  const [leaseRequest, setLeaseRequest] = useState(false)
  const [activeTab, setActiveTab] = useState<'FO' | 'LF'>('FO')
  const { t } = useTranslation('extensionOfficer')

  const [searchParams] = useSearchParams()
  const query = searchParams.get('q')
  const [searchQuery, setSearchQuery] = useState(query ?? '')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(e.target.value)
  }

  const handleCloseNewRequest = (): void => {
    setNewRequest(false)
  }

  const handleCloseLeaseRequest = (): void => {
    setLeaseRequest(false)
  }

  const handleOpenLeaseRequest = (): void => {
    setLeaseRequest(true)
  }

  const handleOpenNewRequest = (): void => {
    setNewRequest(true)
  }

  return (
    <Box>
      <TabView>
        <Tabs defaultValue='FO' className='flex flex-col gap-4 w-full'>
          <div className='flex flex-col gap-2 sm:flex-row sm:justify-between sm:items-center w-full'>
            <TabsList className='grid w-full sm:w-2/3 md:w-1/2 lg:w-2/5 grid-cols-2'>
              <TabsTrigger
                value='FO'
                onClick={() => {
                  setActiveTab('FO')
                }}
              >
                {t('farm.farmO')}
              </TabsTrigger>
              <TabsTrigger
                value='LF'
                onClick={() => {
                  setActiveTab('LF')
                }}
              >
                {t('farm.leasedF')}
              </TabsTrigger>
            </TabsList>
            <div className='w-full flex justify-end gap-4'>
              <Button size='xs' variant='warning' onClick={handleOpenLeaseRequest}>
                {t('farm.verL')}
                <p className='fa-solid fa-add' />
              </Button>
              <Button size='xs' variant='success' onClick={handleOpenNewRequest}>
                {t('farm.verF')}
                <p className='fa-solid fa-add' />
              </Button>
            </div>
          </div>

          <div>
            <TabsContent value='FO'>
              <FarmOwners />
            </TabsContent>
            <TabsContent value='LF'>
              <LeasedOwners />
            </TabsContent>
          </div>
        </Tabs>

        <Dialog
          open={leaseRequest || newRequest}
          defaultOpen={false}
          modal
          onOpenChange={leaseRequest ? handleCloseLeaseRequest : handleCloseNewRequest}
        >
          <DialogContent className='bg-card w-full md:w-[50%] lg:w-[35%] max-w-[430px]'>
            <Text size='medium'>{leaseRequest ? t('farm.verL') : t('farm.verF')}</Text>
            <Text size='small'>{leaseRequest ? t('farm.uli') : t('farm.udf')}</Text>
            <SearchBar
              label={t('farm.sbf')}
              className='w-full max-w-[700px] flex gap-8'
              searchQuery={searchQuery}
              disabled={searchQuery.length !== 7}
              onChange={handleChange}
              onSearch={() => {
                if (leaseRequest) {
                  navigate(`/officer/verify-lease/${searchQuery ?? ''} `)
                } else {
                  navigate(`/officer/verify/${searchQuery ?? ''} `)
                }
              }}
              isSearching={false}
            />
          </DialogContent>
        </Dialog>
      </TabView>
    </Box>
  )
}
