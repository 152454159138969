import type React from 'react'
import { useForm } from 'react-hook-form'
import type z from 'zod'
import { coerce, object, string } from 'zod'
import { useNewFarmStore } from '../../../stores/newFarm'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input
} from 'components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'

interface FarmDetailsProps {
  next: () => void
  cancel: () => void
}

const farmSchema = object({
  farmName: string().min(1, 'Farm name is required'),
  size: coerce
    .number({ required_error: 'Size is required', invalid_type_error: 'Size should be a number' })
    .min(1, 'Size should be greater than 1')
    .max(30000, 'The max farm size allowed is 30000 hectares')
    .nonnegative('Size cannot be smaller than 1')
})

export const FarmDetails: React.FC<FarmDetailsProps> = ({ next, cancel }) => {
  const { setFarmDetails, farmDetails } = useNewFarmStore()
  const { t } = useTranslation('landManagement')

  const form = useForm<z.infer<typeof farmSchema>>({
    resolver: zodResolver(farmSchema),
    defaultValues: {
      size: farmDetails?.size ?? 1,
      farmName: farmDetails?.nickname ?? ''
    }
  })

  const onSubmit = (data: z.infer<typeof farmSchema>): void => {
    setFarmDetails({
      size: data.size,
      nickname: data.farmName
    })
    next()
  }

  return (
    <Form {...form}>
      <form
        className={'w-full flex flex-col gap-4 md:gap-8'}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name={'farmName'}
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel className={'ml-2 font-redHat '}>{t('deed.name')}</FormLabel>
              <FormControl>
                <Input
                  type={'text'}
                  placeholder={`${t('deed.name')}`}
                  classLabel={'bg-background-dark sm:bg-card'}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={'size'}
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel className={'ml-2 font-redHat '}>{t('deed.size')}</FormLabel>
              <FormControl>
                <Input
                  classLabel={'bg-background-dark sm:bg-card'}
                  placeholder={`${t('deed.size')}`}
                  type={'text'}
                  inputMode={'numeric'}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className={'w-full flex justify-between gap-4 md:gap-8'}>
          <Button variant={'secondary'} className={'w-1/2'} type={'button'} onClick={cancel}>
            {t('deed.cancel')}
          </Button>
          <Button className={'w-1/2'} type={'submit'}>
            {t('deed.next')}
          </Button>
        </div>
      </form>
    </Form>
  )
}