import type React from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { object, string, type TypeOf } from 'zod'
import { useSearchInventory } from 'features/mechanisationOfficer/inventory/hooks/useSearchInventory'
import { toast } from 'react-hot-toast'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text
} from 'components/ui'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Info } from 'lucide-react'
import { useFlags } from 'launchdarkly-react-client-sdk'

const schema = object({
  idType: string().refine((value) => value.trim() !== '', {
    message: 'Field is required'
  }),
  idInput: string()
}).refine(
  (data) => {
    if (data.idType === 'merchantId') {
      return data.idInput.length > 0
    }

    if (data.idType === 'farmerId') {
      return data.idInput.length === 7
    }
    return false
  },
  {
    message: 'Invalid input length',
    path: ['idInput']
  }
)

type SearchInput = TypeOf<typeof schema>

interface SearchInventoriesProps {
  dialogType?: string
}

export const SearchInventories: React.FC<SearchInventoriesProps> = ({ dialogType }) => {
  const navigate = useNavigate()
  const flags = useFlags()
  const { t } = useTranslation('inventory')
  const { error, isLoading } = useSearchInventory()
  const form = useForm<SearchInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      idType: '',
      idInput: ''
    }
  })

  const onSubmit = (data: SearchInput): void => {
    navigate(
      `/inventory${dialogType === 'Transfer' ? '/transfers' : ''}/${
        data.idType === 'merchantId' ? 'merchant' : 'farmer'
      }/${data.idInput ?? ''}`
    )
  }

  useEffect(() => {
    if (error !== null) {
      toast.error(error, { id: error })
    }
  }, [error])

  return (
    <Form {...form}>
      <form className='w-full' onSubmit={form.handleSubmit(onSubmit)}>
        <div className='flex flex-col items-center gap-4'>
          {dialogType === 'Transfer' && (
            <div className='flex'>
              <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
              <Text variant='secondary' size='small' className=''>
                Enter ID of origin transferrer.
              </Text>
            </div>
          )}

          <FormField
            control={form.control}
            name='idType'
            render={({ field }) => (
              <FormItem className='w-full flex text-start flex-1 flex-col gap-1'>
                <FormControl>
                  <Select
                    onValueChange={(value) => {
                      field.onChange(value)
                    }}
                    value={field.value}
                  >
                    <SelectTrigger className='flex justify-between w-full'>
                      <SelectValue placeholder={t('mech.sut')} />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {flags.merchantInventory === true && (
                          <SelectItem value='merchantId'>{t('mech.merchant')}</SelectItem>
                        )}
                        <SelectItem value='farmerId'>{t('mech.farmer')}</SelectItem>
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='idInput'
            render={({ field }) => (
              <FormItem className='w-full flex flex-1 flex-col gap-2'>
                <FormControl>
                  <Input
                    type='text'
                    inputMode='numeric'
                    label={
                      form.watch('idType') === 'farmerId'
                        ? t('mech.efi')
                        : form.watch('idType') === 'merchantId'
                        ? t('mech.emi')
                        : t('mech.cti')
                    }
                    pattern='[0-9]*'
                    placeholder={
                      form.watch('idType') === 'farmerId'
                        ? t('mech.efi')
                        : form.watch('idType') === 'merchantId'
                        ? t('mech.emi')
                        : t('mech.cit')
                    }
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className='flex justify-end mt-4'>
          <Button
            variant='primary'
            type='submit'
            className={'w-1/2'}
            onClick={form.handleSubmit(onSubmit)}
            loading={isLoading}
            disabled={isLoading}
          >
            {t('mech.search')}
          </Button>
        </div>
      </form>
    </Form>
  )
}
