import { type Lease } from 'types'
import type React from 'react'
import { Text } from 'components/ui'
import { useTranslation } from 'react-i18next'

interface StatusesProps {
  lease: Lease
}

export const Statuses: React.FC<StatusesProps> = ({ lease }) => {
  const { t } = useTranslation('leaseManagement')

  if (lease.status_desc === 'RENEWAL INITIATED BY LESSEE') {
    return <Text variant={'warning'}>{t('leaseForm.pr')}</Text>
  }

  if (
    lease.status_desc === 'TERMINATION INITIATED BY LESSOR' ||
    lease.status_desc === 'TERMINATION INITIATED BY LESSEE'
  ) {
    return <Text variant={'warning'}>{t('leaseForm.pt')}</Text>
  }

  if (lease.status_desc === 'EXPIRED') {
    return <Text variant={'error'}> {t('leaseForm.expired')}</Text>
  }

  if (lease.status_desc === 'REJECTED BY LESSEE') {
    return <Text variant={'error'}>{t('leaseForm.rejected')}</Text>
  }

  if (
    lease.status_desc === 'TERMINATION ACCEPTED BY LESSEE' ||
    lease.status_desc === 'TERMINATION ACCEPTED BY LESSOR'
  ) {
    return <Text variant={'error'}>{t('leaseForm.term')}</Text>
  }

  if (
    lease.status_desc === 'ACCEPTED BY LESSEE' ||
    lease.status_desc === 'VERIFIED BY EXTENSION OFFICER' ||
    lease.status_desc === 'RENEWAL ACCEPTED BY LESSOR'
  ) {
    return <Text variant={'success'}>{t('leaseForm.verified')}</Text>
  }

  return <Text variant={'warning'}>{t('leaseForm.pv')}</Text>
}