import type React from 'react'
import { useState } from 'react'
import { useStaticDataStore } from '../../../../../../../stores/useStaticDataStore'
import { Error } from '../../../../../../../components/Errors/Error'
import { type Fungicides } from '../../../../../../../types/static'
import { AdjuvantsFungicidesTable } from '../tables/AdjuvantFungicidesTable'
import { adjuvantColumns } from '../../weeding/tables/adjuvant-columns'
import { useCroppingPlanStore } from '../../../../stores/useCroppingPlanStore'

interface AdjuvantSelectionProps {
  fungicides: Fungicides[]
  onCancel: () => void
  onNext: () => void
}

export const FungicidesAdjuvantSelection: React.FC<AdjuvantSelectionProps> = ({
  fungicides,
  onCancel,
  onNext
}) => {
  const { adjuvants } = useStaticDataStore()
  const { pest } = useCroppingPlanStore()
  const [currentFungicides, setCurrentFungicides] = useState(0)

  if (adjuvants == null) {
    return <Error />
  }

  const handleNext = (): void => {
    if (currentFungicides === fungicides.length - 1) {
      onNext()
    } else {
      setCurrentFungicides((prevState) => prevState + 1)
    }
  }

  const handleBack = (): void => {
    if (currentFungicides === 0) {
      onCancel()
    } else {
      setCurrentFungicides((prevState) => prevState - 1)
    }
  }

  const returnComponent = (index: number): React.ReactNode => {
    return (
      <AdjuvantsFungicidesTable
        key={index}
        next={handleNext}
        back={handleBack}
        fungicide={fungicides[index]}
        pest={pest}
        data={adjuvants}
        columns={adjuvantColumns}
      />
    )
  }

  return returnComponent(currentFungicides)
}
