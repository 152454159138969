import type React from 'react'
import { motion } from 'framer-motion'
import TestimonialCards from './TestimonialCard'
import { useTranslation } from 'react-i18next'

interface TestimonialsFarmersProps {
  bgColor: string
}

export const TestimonialsFarmers: React.FC<TestimonialsFarmersProps> = ({ bgColor }) => {
  const { t } = useTranslation('landing')
  return (
    <div className={`flex flex-col py-20 px-10 ${bgColor} space-y-8`}>
      <motion.div
        className='container mx-auto flex flex-col items-center space-y-12 md:mb-12'
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className='text-3xl md:text-4xl lg:text-5xl font-bold text-gray-700 landing-page'>
          {t('home.succ')}
        </h2>
      </motion.div>
      <motion.div
        className='container mx-auto flex flex-col justify-center items-center'
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <TestimonialCards />
      </motion.div>
    </div>
  )
}