import type React from 'react'
import { useState } from 'react'
import { type Farmer } from 'types'
import { DeedReview } from './DeedReview'
import { CustomStepper } from 'components/Elements/Stepper/Stepper'
import { FarmName } from './FarmName'
import { OtpForm } from '../OtpForm'
import { FarmDeedNumber } from './FarmDeedNumber'
import { useTranslation } from 'react-i18next'

interface RegWithDeedProps {
  farmer: Farmer
  onBack: () => void
  onClose: () => void
}

export const RegWithDeed: React.FC<RegWithDeedProps> = ({ farmer, onClose, onBack }) => {
  const [activeStep, setActiveStep] = useState(0)
  const { t } = useTranslation('landManagement')
  const steps = [
    `${t('deed.deed')}`,
    `${t('deed.review')}`,
    `${t('deed.name')}`,
    `${t('deed.verification')}`
  ]

  const handleNext = (): void => {
    setActiveStep((prevState) => prevState + 1)
  }

  const handleBack = (): void => {
    setActiveStep((prevState) => prevState - 1)
  }

  if (activeStep === 1) {
    return (
      <div className={'flex flex-col gap-8'}>
        <CustomStepper steps={steps} activeStep={activeStep} />
        <DeedReview onNext={handleNext} onBack={handleBack} className={'flex flex-col gap-6'} />
      </div>
    )
  }

  if (activeStep === 2) {
    return (
      <div className={'flex flex-col gap-8'}>
        <CustomStepper steps={steps} activeStep={activeStep} />
        <FarmName onNext={handleNext} onBack={handleBack} className={'flex flex-col gap-6'} />
      </div>
    )
  }

  if (activeStep === steps.length - 1) {
    return (
      <div className={'flex flex-col gap-8'}>
        <CustomStepper steps={steps} activeStep={activeStep} />
        <OtpForm farmer={farmer} onSuccess={onClose} className={'flex flex-col gap-6'} />
      </div>
    )
  }

  return (
    <div className={'flex flex-col gap-8'}>
      <CustomStepper steps={steps} activeStep={activeStep} />
      <FarmDeedNumber onNext={handleNext} onBack={onBack} className={'flex flex-col gap-6'} />
    </div>
  )
}