import type { Farm, Lease } from '../../../../types'
import { type FC, useState } from 'react'
import { SoilCorrections } from '../components/SoilCorrections'
import { SoilRecommendation } from '../components/SoilRecommendation'
import { useGetSoilCorrection } from '../hooks/useSoilCorrection'
import { TabView } from '../../../../components/App/Layout'
import { Loader } from '../../../../components/ui'
import { Error } from '../../../../components/Errors/Error'

interface Base {
  className?: string
}

interface WithFarm extends Base {
  farm: Farm
  lease?: never
}

interface WithLease extends Base {
  farm?: never
  lease: Lease
}

type SoilCorrectionProps = WithFarm | WithLease

export const SoilCorrection: FC<SoilCorrectionProps> = ({ farm, lease }) => {
  const [isNewRecommendation, setIsNewRecommendation] = useState<boolean>(false)
  const { ssr, error, scr, isLoading, hasValidSSR } = useGetSoilCorrection(
    farm?.farm_id,
    lease?.lease_id
  )

  const handleNewRecommendation = (): void => {
    setIsNewRecommendation(true)
  }

  const handleCloseRecommendation = (): void => {
    setIsNewRecommendation(false)
  }

  if (isLoading) {
    return (
      <TabView>
        <div className={'grid place-items-center'}>
          <Loader />
        </div>
      </TabView>
    )
  }

  if (error) {
    return <Error />
  }

  if (lease != null) {
    if (isNewRecommendation) {
      return (
        <SoilRecommendation
          correction={scr}
          ssr={ssr}
          lease={lease}
          onClose={handleCloseRecommendation}
        />
      )
    }

    return (
      <SoilCorrections
        onNewRecommendation={handleNewRecommendation}
        scr={scr}
        ssr={ssr}
        isSSRValid={hasValidSSR}
      />
    )
  }

  if (isNewRecommendation) {
    return (
      <SoilRecommendation
        correction={scr}
        ssr={ssr}
        farm={farm}
        onClose={handleCloseRecommendation}
      />
    )
  }

  return (
    <SoilCorrections
      onNewRecommendation={handleNewRecommendation}
      scr={scr}
      ssr={ssr}
      isSSRValid={hasValidSSR}
    />
  )
}
