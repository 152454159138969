import { object, z } from 'zod'
import type React from 'react'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  RadioGroup,
  RadioGroupItem,
  useStepper
} from 'components/ui'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCroppingPlanWeeding } from '../../../../hooks/useCroppingPlanWeeding'
import { StepperFooter } from '../../Footer'
import { useCroppingPlanStore } from '../../../../stores/useCroppingPlanStore'

const applicationMethods = [
  {
    label: 'Boom sprayer',
    value: 302,
    headerValue: 66
  },
  {
    label: 'Drone',
    value: 303,
    headerValue: 67
  }
]

export const methodSchema = object({
  methods: z.coerce.number({
    required_error: 'You need to select an application method'
  })
})

interface ApplicationMethodProps {
  back: () => void
}

export const ApplicationMethod: React.FC<ApplicationMethodProps> = ({ back }) => {
  const { nextStep } = useStepper()
  const { weeding } = useCroppingPlanStore()
  const { createChemicalWeeding, updateChemicalWeeding, isLoading } =
    useCroppingPlanWeeding(nextStep)
  const form = useForm<z.infer<typeof methodSchema>>({
    resolver: zodResolver(methodSchema),
    defaultValues: {
      methods: weeding != null ? weeding.control_services[0]?.service_id : undefined
    }
  })

  const onSubmit = (data: z.infer<typeof methodSchema>): void => {
    if (weeding == null) {
      createChemicalWeeding(data)
    } else {
      updateChemicalWeeding(data)
    }
  }

  return (
    <Form {...form}>
      <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
        <div
          className={
            'flex flex-col p-4 gap-4 w-full sm:max-w-[60%] lg:max-w-[60%]' + ' xl:max-w-[40%]'
          }
        >
          <FormField
            control={form.control}
            name='methods'
            render={({ field }) => (
              <FormItem className='space-y-3'>
                <FormLabel>Application Method</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value?.toString()}
                    className='flex flex-col space-y-1'
                  >
                    {applicationMethods.map((method) => (
                      <FormItem
                        key={method.headerValue}
                        className='flex items-center space-x-3 space-y-0'
                      >
                        <FormControl>
                          <RadioGroupItem value={method.value?.toString()} />
                        </FormControl>
                        <FormLabel className='font-normal'>{method.label}</FormLabel>
                      </FormItem>
                    ))}
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <StepperFooter back={back} isLoading={isLoading} />
      </form>
    </Form>
  )
}
