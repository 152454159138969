import { object, z } from 'zod'
import type React from 'react'
import { useEffect } from 'react'
import { useCroppingPlanShellingAndThreshing } from '../../../hooks/useCroppingPlanShellingAndThreshing'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Loader,
  RadioGroup,
  RadioGroupItem,
  Text,
  useStepper
} from 'components/ui'
import { useGetThreshing } from '../../../api/threshing/getThreshing'
import { AxiosError } from 'axios'
import { Error } from 'components/Errors/Error'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { StepperFooter } from '../Footer'
import { useTranslation } from 'react-i18next'

export const shellingAndThreshingSchema = object({
  technique: z.enum(['Machinery', 'Labour'], {
    required_error: 'You need to select a Shelling/Threshing technique'
  })
})

export const ShellingAndThreshing: React.FC = () => {
  const { nextStep } = useStepper()
  const { t } = useTranslation('croppingPlan')

  const {
    header,
    createShellingAndThreshing,
    setShellingAndThreshing,
    updateShellingAndThreshing,
    isLoading
  } = useCroppingPlanShellingAndThreshing(nextStep)

  const {
    data: apiThreshing,
    isInitialLoading: apiIsLoading,
    error: threshingError
  } = useGetThreshing(header?.farmer_id, header?.cropping_plan_id)

  const form = useForm<z.infer<typeof shellingAndThreshingSchema>>({
    resolver: zodResolver(shellingAndThreshingSchema),
    defaultValues: {
      technique:
        apiThreshing?.data?.shelling_threshing_method != null
          ? apiThreshing?.data?.shelling_threshing_method
          : undefined
    }
  })

  const onSubmit = (data: z.infer<typeof shellingAndThreshingSchema>): void => {
    if (apiThreshing?.data == null) {
      createShellingAndThreshing(data)
    } else if (apiThreshing?.data?.shelling_threshing_method !== data.technique) {
      updateShellingAndThreshing(data)
    } else {
      nextStep()
    }
  }

  useEffect(() => {
    if (apiThreshing?.data != null) {
      setShellingAndThreshing(apiThreshing.data)
    }
  }, [apiThreshing])

  if (header == null) {
    return (
      <div className={'grid place-items-center'}>
        <Text variant={'error'} size={'medium'}>
          Crop & Hectarage Are Required!
        </Text>
      </div>
    )
  }

  if (apiIsLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (threshingError != null) {
    if (threshingError instanceof AxiosError) {
      if (threshingError?.response?.status !== 404) {
        return <Error />
      }
    }
  }

  return (
    <Form {...form}>
      <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
        <div
          className={
            'flex flex-col p-4 gap-4 w-full sm:max-w-[60%] lg:max-w-[60%]' + ' xl:max-w-[40%]'
          }
        >
          <FormField
            control={form.control}
            name='technique'
            render={({ field }) => (
              <FormItem className='space-y-3'>
                <FormLabel>{t('thresh.select')}</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className='flex flex-col space-y-1'
                  >
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem value={`Machinery`} />
                      </FormControl>
                      <FormLabel className='font-normal'>{t('thresh.machine')}</FormLabel>
                    </FormItem>
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem value={`Labour`} />
                      </FormControl>
                      <FormLabel className='font-normal'>{t('thresh.labour')}</FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <StepperFooter isLoading={isLoading} />
      </form>
    </Form>
  )
}
