import { axios } from 'lib/axios'
import { type AxiosError } from 'axios'

export interface ChemicalsDTO {
  chemical_id: number | null | undefined
  chemical_schedule: number
  weed_id: number | null | undefined
  chemical: number
  adjuvant: number | null | undefined
}

export interface CreateWeedingDTO {
  cp_id: number | null | undefined
  farmer_id: number | null | undefined
  user_id: number | null | undefined
  type: string | null | undefined
  manual_method?: number | null | undefined
  chemical_method?: number | null
  chemicals?: ChemicalsDTO[] | null
}

export const createWeeding = async (params: CreateWeedingDTO): Promise<AxiosError> => {
  return axios.post('/production/weeding', params)
}
