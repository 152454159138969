import type React from 'react'
import { useState } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Label,
  RadioGroup,
  RadioGroupItem,
  Text
} from 'components/ui'
import { useTranslation } from 'react-i18next'

interface NewInventoryProps {
  onSubmit: (selectedInventory: string) => void
  disabled?: boolean
}

export const NewInventory: React.FC<NewInventoryProps> = ({ onSubmit, disabled = false }) => {
  const [selectedInventory, setSelectedInventory] = useState('')
  const { t } = useTranslation('inventory')

  const handleSubmit = (): void => {
    if (selectedInventory !== '') {
      onSubmit(selectedInventory)
    }
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size='xs' variant='success' disabled={disabled}>
          {t('inventory.add')}
          <p className='fa-solid fa-add' />
        </Button>
      </DialogTrigger>
      <DialogContent className='sm:max-w-md gap-8 w-[90vw]'>
        <DialogHeader>
          <DialogTitle>
            <Text size='medium' className='text-center text-semibold text-[1.2rem]'>
              {t('inventory.select')}
            </Text>
          </DialogTitle>
        </DialogHeader>

        <RadioGroup
          value={selectedInventory}
          onValueChange={setSelectedInventory}
          defaultValue=''
          className='w-full ml-4 gap-[0.7rem]'
        >
          {[
            { id: 'tractor', label: `${t('inventory.tractor')}` },
            { id: 'pwrd-implements', label: `${t('inventory.pwrd')}` },
            { id: 'trd-implements', label: `${t('inventory.nonPwrd')}` }
          ].map((item) => (
            <div className='flex items-center space-x-2' key={item.id}>
              <RadioGroupItem id={item.id} value={item.id} />
              <Label htmlFor={item.id}>
                <Text>{item.label}</Text>
              </Label>
            </div>
          ))}
        </RadioGroup>

        <DialogFooter className='justify-end'>
          <Button
            size='lg'
            variant='primary'
            onClick={handleSubmit}
            disabled={selectedInventory === ''}
          >
            {t('inventory.next')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
