import { CheckListItem } from 'components/Change-Password/CheckListItem'
import type React from 'react'
import { hasLowerCase, hasUpperCase } from 'utils/utilFunctions'
import { useTranslation } from 'react-i18next'

interface PasswordCheckListProps {
  password: string
}

export const PasswordCheckList: React.FC<PasswordCheckListProps> = ({ password }) => {
  const { t } = useTranslation('authenticationTranslation')

  const specialChar = /[~`¿¡!#$%^&*€£@+÷=\-[\]\\';,/{}()|":<>?._]/g

  return (
    <div className={'flex flex-col gap-2'}>
      <h4>{t('pwd.ypm')}:</h4>

      <CheckListItem label={t('pwd.num')} isValid={/\d/g.test(password)} />
      <CheckListItem label={t('pwd.char')} isValid={specialChar.test(password)} />
      <CheckListItem label={t('pwd.low')} isValid={hasLowerCase(password)} />
      <CheckListItem label={t('pwd.up')} isValid={hasUpperCase(password)} />
      <CheckListItem label={t('pwd.min')} isValid={password.length >= 8} />
    </div>
  )
}