import type React from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import type z from 'zod'
import { object, string } from 'zod'
import { LoginSeparator } from '../layout/Separator'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Text
} from 'components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'
import { useSuperTokens } from '../../hooks/useSuperTokens'

const emailSchema = object({
  email: string().email('Email is invalid'),
  password: string()
    .min(8, 'Password must be more than 8 characters')
    .max(32, 'Password must be less than 32 characters')
})

interface EmailLoginFormProps {
  className?: string
  onPhone: () => void
  onRegister: () => void
}

export const EmailLoginForm: React.FC<EmailLoginFormProps> = ({
  className,
  onPhone,
  onRegister
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const { emailSignIn, isLoading } = useSuperTokens()
  const form = useForm<z.infer<typeof emailSchema>>({
    resolver: zodResolver(emailSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  })

  const onSubmitEmail = async (data: z.infer<typeof emailSchema>): Promise<void> => {
    await emailSignIn(data.email, data.password)
  }

  const { t } = useTranslation('authenticationTranslation')

  return (
    <Form {...form}>
      <form className={className} onSubmit={form.handleSubmit(onSubmitEmail)}>
        <div className={'flex flex-col gap-4'}>
          <FormField
            control={form.control}
            name={'email'}
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel>{t('emailLogin.email')}</FormLabel>
                <FormControl>
                  <Input
                    placeholder={t('emailLogin.email')}
                    type='email'
                    classLabel={'bg-background-dark sm:bg-card'}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          ></FormField>

          <FormField
            control={form.control}
            name={'password'}
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel>{t('emailLogin.password')}</FormLabel>
                <FormControl>
                  <Input
                    classLabel={'bg-background-dark sm:bg-card'}
                    placeholder={t('emailLogin.password')}
                    type={showPassword ? 'text' : 'password'}
                    isPassword
                    showPassword={showPassword}
                    onShowPassword={() => {
                      setShowPassword(!showPassword)
                    }}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          ></FormField>
          <Button
            loading={isLoading}
            disabled={isLoading}
            onClick={form.handleSubmit(onSubmitEmail)}
            type={'submit'}
            size={'lg'}
          >
            {t('emailLogin.login')}
          </Button>
        </div>
        <LoginSeparator label={t('emailLogin.or')} />
        <div className={'flex flex-col gap-4'}>
          <Button
            variant={'secondary'}
            onClick={() => {
              form.reset()
              onPhone()
            }}
            disabled={isLoading}
          >
            {t('emailLogin.phone')}
          </Button>
          <div className={'flex items-center sm:justify-center gap-2'} onClick={onRegister}>
            <Text>{t('emailLogin.register')}</Text>
            <Text variant={'primary'}>
              <a className='cursor-pointer font-bold' onClick={onRegister}>
                {t('emailLogin.signUp')}
              </a>
            </Text>
          </div>
        </div>
      </form>
    </Form>
  )
}
