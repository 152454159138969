import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type SortingState,
  useReactTable
} from '@tanstack/react-table'
import type React from 'react'
import { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui'
import { DataTablePagination } from 'components/Data-Table/datatable-pagination'
import { type GetInventoryRes } from 'features/farmer/inventory/types'
import { useNavigate } from 'react-router-dom'
import { getInventoryColumns } from './inventoryColums'
import { useTranslation } from 'react-i18next'

export function InventoryResults<TData extends GetInventoryRes>({
  data
}: {
  data: TData[]
}): React.ReactNode {
  const [accept, setAccept] = useState(false)
  const { t } = useTranslation('inventory')
  const navigate = useNavigate()

  const handleCloseAccept = (): void => {
    setAccept(false)
  }

  const [sorting, setSorting] = useState<SortingState>([])
  const columns = getInventoryColumns(data, t)
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting
    }
  })

  return (
    <div className='flex flex-col gap-4'>
      <p className='text-info '>
        {t('mech.sr')}: {data.length}
      </p>
      <div className='rounded-md border'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className='text-center font-bold'>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length !== 0 ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className='hover:cursor-pointer hover:bg-muted-hover'
                  onClick={() => {
                    const inventoryItem = row.original
                    navigate(`/inventory/assessment/${inventoryItem?.inventory_id ?? ''}`)
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className='text-center font-medium'>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className='h-24 text-center'>
                  {t('mech.nosr')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  )
}