import { type ApiResponse } from 'types'
import { axios } from 'lib/axios'

export interface SubdivideDTO {
  og_farmer_id: number | null | undefined
  farm_id: string | number | null | undefined
  last_updated_by: number | null | undefined
  hectarage: number
  dest_farmer_id: number | null | undefined
  new_farm_name: string
  new_nickname: string
}

export const subdivideFarm = async (params: SubdivideDTO): Promise<ApiResponse<[]>> => {
  return axios.post(`/farm/edit/subdivide`, params)
}
