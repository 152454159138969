import { Herbicides } from 'features/farmer/cropping-plan/utils/herbicides'
import { Insecticides } from 'features/farmer/cropping-plan/utils/insecticides'
import {
  ManualWeedMethods,
  PestChemicalMethods,
  PestManualSchedule,
  WeedAndPestApplicationStage,
  WeedingChemicalMethods,
  type WeedingChemicals
} from 'types'
import { type ChemicalsTable } from '../types'

export const getInsecticide = (chemicalId: number): ChemicalsTable[] => {
  return Insecticides.filter((chemical) => chemical.id === chemicalId)
}

export const getHerbicide = (chemicalId: number): ChemicalsTable[] => {
  return Herbicides.filter((chemical) => chemical.id === chemicalId)
}

export const getChemicalPestMethod = (value: string | null | undefined): PestChemicalMethods => {
  if (value === 'Tractor') {
    return PestChemicalMethods.Tractor
  } else if (value === 'Drone') {
    return PestChemicalMethods.Drone
  } else if (value === 'Plane') {
    return PestChemicalMethods.Plane
  }
  return PestChemicalMethods.Knapsack
}

export const getChemicalWeedMethodValue = (
  value: WeedingChemicalMethods | null | undefined
): string => {
  if (value === WeedingChemicalMethods.Tractor) {
    return 'Tractor'
  } else if (value === WeedingChemicalMethods.Drone) {
    return 'Drone'
  } else if (value === WeedingChemicalMethods.Plane) {
    return 'Plane'
  }
  return 'Knapsack'
}

export const getManualWeedMethodValue = (
  value: ManualWeedMethods | null
): 'Interrow Cultivation' | 'Human Based' => {
  if (value === ManualWeedMethods.HumanBased) return 'Human Based'
  return 'Interrow Cultivation'
}

export const getManualWeedApplicationStageValue = (
  value: WeedAndPestApplicationStage | null | undefined
):
  | 'Pre-Emergence & Post-Emergence'
  | 'Pre-Emergence'
  | 'Post-Emergence'
  | 'Before Planting & Post-Emergence'
  | 'Before Planting' => {
  if (value === WeedAndPestApplicationStage.PreEmergenceAndPostEmergence) {
    return 'Pre-Emergence & Post-Emergence'
  } else if (value === WeedAndPestApplicationStage.PreEmergence) {
    return 'Pre-Emergence'
  } else if (value === WeedAndPestApplicationStage.PostEmergence) {
    return 'Post-Emergence'
  } else if (value === WeedAndPestApplicationStage.BeforePlantingAndPostEmergence) {
    return 'Before Planting & Post-Emergence'
  }
  return 'Before Planting'
}

export const getManualPestStageValue = (
  value: PestManualSchedule | null | undefined
):
  | 'Pre-Emergence & Post-Emergence'
  | 'Pre-Emergence'
  | 'Post-Emergence'
  | 'Before Planting & Post-Emergence'
  | 'Before Planting' => {
  if (value === PestManualSchedule.PreEmergenceAndPostEmergence) {
    return 'Pre-Emergence & Post-Emergence'
  } else if (value === PestManualSchedule.PreEmergence) {
    return 'Pre-Emergence'
  } else if (value === PestManualSchedule.PostEmergence) {
    return 'Post-Emergence'
  } else if (value === PestManualSchedule.BeforePlantingAndPostEmergence) {
    return 'Before Planting & Post-Emergence'
  }
  return 'Before Planting'
}

export const getScheduleId = (schedule: string): number => {
  switch (schedule) {
    case 'Post Emergence':
      return 1
    case 'Pre Planting':
      return 2
    case 'Pre Emergence':
      return 3
    case 'Pre Emergence & Post Emergence':
      return 10
    case 'Pre Planting & Post Emergence':
      return 11
    case 'Pre Planting & Pre Emergence':
      return 12
    default:
      return 0
  }
}

export const getScheduleToApi = (schedule: string | null): string => {
  switch (schedule) {
    case 'Post Emergence':
      return 'Post Emergence'
    case 'Pre Planting':
      return 'Pre Planting'
    case 'Pre Emergence':
      return 'Pre Emergence'
    case 'Pre Emergence & Post Emergence':
      return 'Pre Post Emergence'
    case 'Pre Planting & Post Emergence':
      return 'Pre Planting Post Emergence'
    case 'Pre Planting & Pre Emergence':
      return 'Pre Planting Pre Emergence'
    default:
      return 'Post Emergence'
  }
}

export const getWeedingSchedule = (
  chemicals: WeedingChemicals[] | null
):
  | 'Post Emergence'
  | 'Pre Planting'
  | 'Pre Emergence'
  | 'Pre Emergence & Post Emergence'
  | 'Pre Planting & Post Emergence'
  | 'Pre Planting & Pre Emergence' => {
  if (chemicals?.length === 2) {
    if (
      (chemicals[0]?.chemical_schedule === 'Post Emergence' &&
        chemicals[1]?.chemical_schedule === 'Pre Planting') ||
      (chemicals[0]?.chemical_schedule === 'Pre Planting' &&
        chemicals[1]?.chemical_schedule === 'Post Emergence')
    ) {
      return 'Pre Planting & Post Emergence'
    } else if (
      (chemicals[0]?.chemical_schedule === 'Pre Emergence' &&
        chemicals[1]?.chemical_schedule === 'Post Emergence') ||
      (chemicals[0]?.chemical_schedule === 'Post Emergence' &&
        chemicals[1]?.chemical_schedule === 'Pre Emergence')
    ) {
      return 'Pre Emergence & Post Emergence'
    } else if (
      (chemicals[0]?.chemical_schedule === 'Pre Emergence' &&
        chemicals[1]?.chemical_schedule === 'Post Emergence') ||
      (chemicals[0]?.chemical_schedule === 'Post Emergence' &&
        chemicals[1]?.chemical_schedule === 'Pre Emergence')
    ) {
      return 'Pre Planting & Pre Emergence'
    } else {
      return 'Pre Planting & Pre Emergence'
    }
  } else {
    switch (chemicals != null ? chemicals[0]?.chemical_schedule : '') {
      case 'Post Emergence':
        return 'Post Emergence'
      case 'Pre Planting':
        return 'Pre Planting'
      case 'Pre Emergence':
        return 'Pre Emergence'
      default:
        return 'Post Emergence'
    }
  }
}

export const isDualWeedingSchedule = (schedule: string | null): boolean => {
  return (
    schedule === 'Pre Emergence & Post Emergence' ||
    schedule === 'Pre Planting & Pre Emergence' ||
    schedule === 'Pre Planting & Post Emergence'
  )
}

export const getDualWeedingScheduleToSingle = (schedule: string | null): string[] => {
  if (schedule === 'Pre Emergence & Post Emergence') {
    return ['Pre Emergence', 'Post Emergence']
  } else if (schedule === 'Pre Planting & Post Emergence') {
    return ['Pre Planting', 'Post Emergence']
  } else if (schedule === 'Pre Planting & Post Emergence') {
    return ['Pre Planting', 'Pre Emergence']
  } else {
    return []
  }
}
