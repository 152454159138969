import { useAcceptOrRejectLease } from 'features/officer/lease-management/hooks/useAcceptOrRejectLease'
import type React from 'react'
import { useForm } from 'react-hook-form'
import { useOfficerStore } from 'stores/useOfficerStore'
import { type Lease } from 'types'
import type z from 'zod'
import { array, boolean, object } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Checkbox } from 'components/ui/checkbox'
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  Text
} from 'components/ui'
import { useTranslation } from 'react-i18next'

interface ProvidedInformationProps {
  lease: Lease
  className?: string
  onApprove: () => void
  onCancel: () => void
}

const FormSchema = object({
  declarations: array(boolean().default(false)).refine((value) => value.every((item) => item), {
    message: 'You have to select at least one item.'
  })
})

const items = [
  {
    id: 'omang',
    label: 'Certified copy of OMANGs'
  },
  {
    id: 'affidavit',
    label: 'Affidavit'
  },
  {
    id: 'correctInformation',
    label: 'The information provided is correct'
  }
]

export const ProvidedInformation: React.FC<ProvidedInformationProps> = ({
  className,
  lease,
  onApprove,
  onCancel
}) => {
  const { officer } = useOfficerStore()
  const { acceptLease, isLoading } = useAcceptOrRejectLease(lease, officer?.user_id)
  const { t } = useTranslation('extensionOfficer')
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      declarations: [false, false, false]
    }
  })

  const onSubmit = (data: z.infer<typeof FormSchema>): void => {
    acceptLease(onApprove)
  }

  return (
    <Form {...form}>
      <form className={className} onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name='declarations'
          render={() => (
            <FormItem className='flex flex-col items-start space-x-3 space-y-0 p-4'>
              <div className='flex flex-col gap-2'>
                <FormLabel className={'text-center'}>{t('land.dec')}</FormLabel>
                <FormDescription className={'flex flex-col gap-2 text-center'}>
                  <Text>
                    {t('land.cert')}
                    <br /> <br />
                    {t('land.i')}{' '}
                    <span className={'text-primary text-semibold'}>
                      {officer?.firstname} {officer?.surname}
                    </span>
                    {t('land.ack')}:
                  </Text>
                </FormDescription>
              </div>
              {items.map((item, index) => (
                <FormField
                  key={item.id}
                  control={form.control}
                  name='declarations'
                  render={({ field }) => (
                    <FormItem
                      key={item.id}
                      className='flex flex-row items-start space-x-3 space-y-0 p-2'
                    >
                      <FormControl>
                        <Checkbox
                          checked={field.value[index]}
                          onCheckedChange={(checked) => {
                            const arr = field.value
                            if (checked === true) {
                              arr[index] = true
                              field.onChange(arr)
                            } else {
                              arr[index] = false
                              field.onChange(arr)
                            }
                          }}
                        />
                      </FormControl>
                      <div className='space-y-1 leading-none'>
                        <FormLabel>{item.label}</FormLabel>
                      </div>
                    </FormItem>
                  )}
                />
              ))}
            </FormItem>
          )}
        />

        <div className={'flex flex-row justify-between gap-8'}>
          <Button variant={'destructive'} onClick={onCancel} size={'xs'} disabled={isLoading}>
            {t('land.cancel')}
          </Button>
          <Button
            variant={'success'}
            type={'submit'}
            onClick={form.handleSubmit(onSubmit)}
            size={'xs'}
            disabled={isLoading}
            loading={isLoading}
          >
            {t('land.cont')}
          </Button>
        </div>
      </form>
    </Form>
  )
}