import type React from 'react'
import { Text } from 'components/ui'
import { CircleOff } from 'lucide-react'

export const NoHerbicides: React.FC = () => {
  return (
    <div className={'min-h-[200px] flex flex-col gap-4 items-center justify-center'}>
      <Text size={'xLarge'} className={'opacity-40'}>
        <CircleOff />
      </Text>
      <Text size={'medium'} variant={'bodyTextLight'}>
        No Herbicides Available for selected crop & application stage
      </Text>
    </div>
  )
}
