// Define an interface for the sections
interface Section {
  title: string
  details: string[]
}

// Create the object for Groups and Cluster
const data = (t: (key: string) => string): { groups: Section; cluster: Section } => {
  return {
    groups: {
      title: t('types.group'),
      details: [t('types.group1'), t('types.group2'), t('types.group3')]
    },
    cluster: {
      title: t('types.clus'),
      details: [t('types.clus1'), t('types.clus2'), t('types.clus3')]
    }
  }
}

// Export the data object
export default data