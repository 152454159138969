import { object, z } from 'zod'
import type React from 'react'
import { useEffect } from 'react'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Loader,
  RadioGroup,
  RadioGroupItem,
  Text,
  useStepper
} from 'components/ui'
import { Error } from 'components/Errors/Error'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { StepperFooter } from '../Footer'
import { useCroppingPlanPackaging } from '../../../hooks/useCroppingPlanPackaging'
import { useGetPackaging } from '../../../api/packaging/getPackaging'
import { useTranslation } from 'react-i18next'
import { useStaticDataStore } from '../../../../../../stores/useStaticDataStore'
import { Info } from 'lucide-react'

const getPackaging = (packaging: string | null | undefined): number => {
  if (packaging === 'Packaging - 50 kg bags') {
    return 310
  }
  return 311
}

export const packagingSchema = object({
  method: z.coerce.number({ required_error: 'You need to select a Packaging method' })
})

export const Packaging: React.FC = () => {
  const { nextStep } = useStepper()
  const { t } = useTranslation('croppingPlan')
  const { services } = useStaticDataStore()
  const packagingServices = services?.filter(
    (item) => item.service_type === 'Packaging' && item.is_active
  )
  const { header, setPackaging, updatePackagingPlan, createPackagingPlan, isLoading } =
    useCroppingPlanPackaging(nextStep)

  const {
    data: apiPackaging,
    isInitialLoading: apiIsLoading,
    error: packagingError
  } = useGetPackaging(header?.farmer_id, header?.cropping_plan_id)

  const form = useForm<z.infer<typeof packagingSchema>>({
    resolver: zodResolver(packagingSchema),
    defaultValues: {
      method:
        apiPackaging?.data?.packaging_method != null
          ? getPackaging(apiPackaging?.data?.packaging_method)
          : undefined
    }
  })

  const onSubmit = (data: z.infer<typeof packagingSchema>): void => {
    if (apiPackaging?.data == null) {
      createPackagingPlan(data)
    } else if (getPackaging(apiPackaging?.data?.packaging_method) !== data.method) {
      updatePackagingPlan(data)
    } else {
      nextStep()
    }
  }

  useEffect(() => {
    if (apiPackaging?.data != null) {
      setPackaging(apiPackaging.data)
    }
  }, [apiPackaging])

  if (header == null) {
    return (
      <div className={'grid place-items-center'}>
        <Text variant={'error'} size={'medium'}>
          Crop & Hectarage Are Required!
        </Text>
      </div>
    )
  }

  if (apiIsLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (packagingError != null) {
    if (packagingError?.response?.status !== 404) {
      return <Error />
    }
  }

  return (
    <Form {...form}>
      <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
        <div
          className={
            'flex flex-col p-4 gap-4 w-full sm:max-w-[60%] lg:max-w-[60%]' + ' xl:max-w-[40%]'
          }
        >
          <div className='flex'>
            <Info size='18' color={'red'} className='mr-2.5' />
            <Text variant='error' size='small' className='font-bold'>
              Please ensure that you have completed the seeding selection.
            </Text>
          </div>
          <FormField
            control={form.control}
            name='method'
            render={({ field }) => (
              <FormItem className='space-y-3'>
                <FormLabel>{t('pack.select')}</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field?.value?.toString()}
                    className='flex flex-col space-y-1'
                  >
                    {packagingServices?.map((packagingService) => (
                      <FormItem
                        key={packagingService.service_details_id}
                        className='flex items-center space-x-3 space-y-0'
                      >
                        <FormControl>
                          <RadioGroupItem value={packagingService.service_details_id.toString()} />
                        </FormControl>
                        <FormLabel className='font-normal'>
                          {packagingService.service_name}
                        </FormLabel>
                      </FormItem>
                    ))}
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <StepperFooter isLoading={isLoading} />
      </form>
    </Form>
  )
}
