import type { Dispatch, SetStateAction } from 'react'
import { Chip, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface FilterMenuProps {
  all: number
  verified: number
  rejected: number
  pending: number
  filterBy: 'all' | 'verified' | 'rejected' | 'pending'
  setFilterBy: Dispatch<SetStateAction<'all' | 'verified' | 'rejected' | 'pending'>>
}

export const FilterMenu: React.FC<FilterMenuProps> = ({
  all,
  verified,
  pending,
  rejected,
  setFilterBy,
  filterBy
}) => {
  const handleFilter = (type: 'all' | 'verified' | 'rejected' | 'pending'): void => {
    setFilterBy(type)
  }
  const isSmallScreen = useMediaQuery('(max-width:600px)')

  const { t } = useTranslation('landManagement')

  return (
    <div className={'w-full flex justify-start items-center gap-2'}>
      <Chip
        label={
          isSmallScreen ? `${t('homePage.all_short')} (${all})` : `(${t('homePage.all')}) (${all})`
        }
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'all' ? 'var(--primary)' : 'transparent',
          '&:hover': {
            backgroundColor: filterBy === 'all' ? 'var(--primary-hover)' : 'transparent'
          },
          padding: { xs: '0.4rem 0rem', sm: '0.4rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'all' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color: filterBy === 'all' ? 'var(--primary-foreground)' : 'var(--foreground)',
            '&:hover': {
              color: filterBy === 'all' ? 'var(--primary-foreground)' : 'var(--primary)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '13px',
              md: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('all')
        }}
        clickable={true}
      />

      <Chip
        label={
          isSmallScreen
            ? `${t('homePage.verified_short')} (${verified})`
            : `(${t('homePage.verified')}) (${verified})`
        }
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'verified' ? 'var(--success)' : 'transparent',
          '&:hover': {
            backgroundColor: filterBy === 'verified' ? 'var(--success-hover)' : 'transparent'
          },
          padding: { xs: '0.4rem 0rem', sm: '0.4rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'verified' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color: filterBy === 'verified' ? 'var(--success-foreground)' : 'var(--foreground)',
            '&:hover': {
              color: filterBy === 'verified' ? 'var(--success-foreground)' : 'var(--success)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('verified')
        }}
        clickable={true}
      />

      <Chip
        label={
          isSmallScreen
            ? `${t('homePage.pending_short')} (${pending})`
            : `(${t('homePage.pending')}) (${pending})`
        }
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'pending' ? 'var(--warning)' : 'transparent',
          '&:hover': {
            backgroundColor: filterBy === 'pending' ? 'var(--warning-hover)' : 'transparent'
          },
          padding: { xs: '0.4rem 0rem', sm: '0.4rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'pending' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color: filterBy === 'pending' ? 'var(--warning-foreground)' : 'var(--foreground)',
            '&:hover': {
              color: filterBy === 'pending' ? 'var(--warning-foreground)' : 'var(--warning)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '13px',
              md: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('pending')
        }}
        clickable={true}
      />

      <Chip
        label={
          isSmallScreen
            ? `${t('homePage.rejected_short')} (${rejected})`
            : `(${t('homePage.rejected')}) (${rejected})`
        }
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'rejected' ? 'var(--destructive)' : 'transparent',
          '&:hover': {
            backgroundColor: filterBy === 'rejected' ? 'var(--destructive-hover)' : 'transparent'
          },
          padding: { xs: '0.4rem 0rem', sm: '0.4rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'rejected' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color: filterBy === 'rejected' ? 'var(--destructive-foreground)' : 'var(--foreground)',
            '&:hover': {
              color:
                filterBy === 'rejected' ? 'var(--destructive-foreground)' : 'var(--destructive)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '13px',
              md: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('rejected')
        }}
        clickable={true}
      />
    </div>
  )
}
