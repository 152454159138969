import { useMutation } from '@tanstack/react-query'
import { AxiosError, type AxiosResponse } from 'axios'
import { acceptRequest, type AcceptRequestDTO } from 'features/lab/ssr/api/acceptRequest'
import { searchSSR } from 'features/lab/ssr/api/searchSSR'
import { useState } from 'react'
import { useMerchantStore } from 'stores/useMerchantStore'
import { type ApiResponse, type SoilSampleRequest } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { useToast } from '../../../../components/ui'
import { type RejectSoilSampleDTO, rejectSsr } from '../api/rejectSsr'
import { useNavigate } from 'react-router-dom'
import { usePloughSeason } from '../../../../hooks/usePloughSeason'

interface UseSoilSampleRequest {
  isLoading: boolean
  soilSampleRequest: SoilSampleRequest | null
  searchSoilSampleRequest: (
    userType: string,
    userInput: string,
    landType: string,
    landInput: string
  ) => Promise<void>
  acceptSoilSampleRequest: (ssr: SoilSampleRequest | undefined) => void
  rejectSoilSampleRequest: (ssr: SoilSampleRequest | undefined, reason: string) => void
}

export const useSoilSampleRequest = (): UseSoilSampleRequest => {
  const { toast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [soilSampleRequest, setSoilSampleRequest] = useState<SoilSampleRequest | null>(null)
  const { isCurrentSeason, isLoading: ploughSeasonIsLoading } = usePloughSeason()
  const { merchant } = useMerchantStore()
  const navigate = useNavigate()

  const acceptSSRMutation = useMutation({
    mutationFn: async (params: AcceptRequestDTO) => {
      return acceptRequest(params)
    }
  })

  const rejectSSRMutation = useMutation({
    mutationFn: async (params: RejectSoilSampleDTO) => {
      return rejectSsr(params)
    }
  })

  const acceptSoilSampleRequest = (ssr: SoilSampleRequest | undefined): void => {
    if (ssr != null && isCurrentSeason(ssr.plough_season)) {
      acceptSSRMutation.mutate(
        {
          user_id: merchant?.merchant_id,
          farm_id: ssr.farm_id,
          farmer_id: ssr.farmer_id,
          merchant_id: ssr.merchant_id,
          ssr_id: ssr.ssr_id
        },
        {
          onSuccess: (result) => {
            if (result.status === 200) {
              toast({
                variant: 'success',
                title: 'Soil Sample Request Accepted Successfully.'
              })
              navigate(`/soil-lab/analyses/${ssr.farmer_id}/${ssr.farm_id}/${ssr.ssr_id}`)
            }
          },
          onError: (err) => {
            if (err instanceof AxiosError) {
              const error = err?.response as AxiosResponse<ApiResponse<[]>>
              toast({
                variant: 'destructive',
                title: 'Uh oh! Something went wrong.',
                description: mapCmsErrorToMessage(
                  error.status.toString() ?? err.code ?? error.data.message ?? ''
                )
              })
            } else {
              const error = err as Error
              toast({
                variant: 'destructive',
                title: 'Uh oh! Something went wrong.',
                description: mapCmsErrorToMessage(error.message)
              })
            }
          }
        }
      )
    } else {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.'
      })
    }
  }

  const rejectSoilSampleRequest = (ssr: SoilSampleRequest | undefined, reason: string): void => {
    if (ssr != null && isCurrentSeason(ssr.plough_season)) {
      rejectSSRMutation.mutate(
        {
          comment: reason,
          user_id: merchant?.merchant_id,
          farm_id: ssr.farm_id,
          farmer_id: ssr.farmer_id,
          merchant_id: ssr.merchant_id,
          ssr_id: ssr.ssr_id
        },
        {
          onSuccess: (result) => {
            if (result.status === 200) {
              toast({
                variant: 'success',
                title: 'Soil Sample Request Reject Successfully.'
              })
              navigate('/soil-lab')
            }
          },
          onError: (err) => {
            if (err instanceof AxiosError) {
              const error = err?.response as AxiosResponse<ApiResponse<[]>>
              toast({
                variant: 'destructive',
                title: 'Uh oh! Something went wrong.',
                description: mapCmsErrorToMessage(
                  error.status.toString() ?? err.code ?? error.data.message ?? ''
                )
              })
            } else {
              const error = err as Error
              toast({
                variant: 'destructive',
                title: 'Uh oh! Something went wrong.',
                description: mapCmsErrorToMessage(error.message)
              })
            }
          }
        }
      )
    } else {
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.'
      })
    }
  }
  const searchSoilSampleRequest = async (
    userType: string,
    userInput: string,
    landType: string,
    landInput: string
  ): Promise<void> => {
    setSoilSampleRequest(null)
    setIsLoading(true)

    try {
      const response = await searchSSR(
        userType,
        userInput,
        landType,
        landInput,
        merchant?.merchant_id
      )
      if (response.status === 200) {
        const request = response.data.find((item) => isCurrentSeason(item.plough_season))
        setSoilSampleRequest(request ?? null)
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err?.response as AxiosResponse<ApiResponse<[]>>
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: mapCmsErrorToMessage(
            error.status.toString() ?? err.code ?? error.data.message ?? ''
          )
        })
      } else {
        const error = err as Error
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: mapCmsErrorToMessage(error.message)
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoading:
      isLoading ||
      acceptSSRMutation.isLoading ||
      rejectSSRMutation.isLoading ||
      ploughSeasonIsLoading,
    soilSampleRequest,
    searchSoilSampleRequest,
    acceptSoilSampleRequest,
    rejectSoilSampleRequest
  }
}
