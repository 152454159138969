import type { ApiResponseWithObject } from '../types'
import { axios } from '../lib/axios'

export interface ChangePasswordDTO {
  oldPassword: string
  newPassword: string
  userId: string | number | null | undefined
}

export const changePassword = async (
  params: ChangePasswordDTO
): Promise<ApiResponseWithObject<[]>> => {
  return axios.post('/user/changePassword', params)
}
