import { type Lessee } from 'types'
import { create } from 'zustand'

interface UseTransferStore {
  farmerID: string | null
  setFarmerID: (farmerId: string | null) => void
  destinationDetails: Lessee | null
  setDestinationDetails: (farmerDetails: Lessee | null) => void
}

export const useTransferStore = create<UseTransferStore>()((set) => ({
  farmerID: null,
  destinationDetails: null,
  setFarmerID: (farmerID: string | null) => {
    set((state) => ({ ...state, farmerID }))
  },
  setDestinationDetails: (farmerDetails: Lessee | null) => {
    set((state) => ({ ...state, destinationDetails: farmerDetails }))
  }
}))
