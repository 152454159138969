import type React from 'react'
import { useEffect, useState } from 'react'
import { useStaticDataStore } from '../../../../../../../stores/useStaticDataStore'
import { Error } from '../../../../../../../components/Errors/Error'
import { Button, Loader, Text, useStepper } from '../../../../../../../components/ui'
import { PesticidesTable } from '../tables/PesticidesTable'
import { pesticidesColumn } from '../tables/columns'
import { fungicidesColumn } from '../tables/fungicides-columns'
import { useCroppingPlanPest } from '../../../../hooks/useCroppingPlanPest'
import { useGetPestControl } from '../../../../api/pest-control/getPestControl'
import { FungicidesTable } from '../tables/FungicidesTable'
import collect from 'collect.js'
import { CircleOff, Info } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useFarmerStore } from '../../../../../../../stores/useFarmerStore'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { PestApplicationMethod } from './ApplicationMethod'
import { useMedia } from 'react-use'

export const PestChemicalSelection: React.FC = () => {
  const isDesktop = useMedia('(min-width: 900px)')
  const [currentStep, setCurrentStep] = useState(0)
  const flags = useFlags()
  const ldClient = useLDClient()
  const { farmer } = useFarmerStore()
  const { prevStep, nextStep } = useStepper()
  const { fungicides, pesticides } = useStaticDataStore()
  const { header, setPest, isLoading } = useCroppingPlanPest(() => {})
  const isMicro = farmer?.class_id != null && farmer.class_id !== 'TAXPAYER'
  const {
    data: apiPest,
    isInitialLoading: apiIsLoading,
    error: pestError
  } = useGetPestControl(header?.farmer_id, header?.cropping_plan_id)
  const { t } = useTranslation('croppingPlan')

  useEffect(() => {
    if (apiPest != null) {
      setPest(apiPest.data)
    }

    void ldClient?.identify({ key: 'moa-frontend' })
  }, [apiPest, setPest])

  const handleNext = (): void => {
    setCurrentStep((prev) => prev + 1)
  }

  const handleBack = (): void => {
    if (isMicro && currentStep === 0) {
      prevStep()
    } else {
      setCurrentStep((prev) => prev - 1)
    }
  }

  if (header == null) {
    return (
      <div className={'grid place-items-center'}>
        <Text variant={'error'} size={'medium'}>
          Crop & Hectarage Are Required!
        </Text>
      </div>
    )
  }

  if (apiIsLoading || isLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (pestError != null) {
    if (pestError?.response?.status !== 404) {
      return <Error />
    }
  }

  const fungicidesList = collect(
    fungicides?.filter((fungicide) => fungicide.crop_id === header?.crop_id) ?? []
  )
    .unique()
    .all()
  const pesticidesList = collect(
    pesticides?.filter((pesticide) => pesticide.crop_id === header?.crop_id) ?? []
  )
    .unique()
    .all()

  if (fungicidesList == null || pesticidesList == null) {
    return <Error />
  }

  if (
    (isMicro && flags.enableMicroscaleFungicides === false && currentStep === 0) ||
    (!isMicro && currentStep === 1)
  ) {
    return (
      <div className={'flex flex-col gap-4'}>
        <div className={'flex flex-col gap-4 justify-start items-start'}>
          <Text className={' flex justify-center items-center'} size={'medium'} variant={'primary'}>
            Pesticide Chemical Selection
          </Text>
          <div className='flex'>
            <Info size='18' color={'var(--primary)'} className='mr-2.5' />
            <Text variant='primary' size='small' className='font-bold'>
              Please select one or more pesticide from the table below.
            </Text>
          </div>
        </div>
        {pesticidesList.length === 0 ? (
          <div>
            <div className={'min-h-[200px] flex flex-col gap-4 items-center justify-center'}>
              <Text size={'xLarge'} className={'opacity-40'}>
                <CircleOff />
              </Text>
              <Text size={'medium'} variant={'bodyTextLight'}>
                No Pesticides Available for your Selected Crop
              </Text>
            </div>

            <div className='w-full flex justify-end gap-2'>
              <Button
                onPointerEnter={isDesktop ? undefined : nextStep}
                size={'sm'}
                variant={'outlined'}
                onClick={nextStep}
              >
                {t('footer.skip')}
              </Button>
              <Button onClick={handleBack} size={'sm'} variant={'secondary'}>
                {t('footer.prev')}
              </Button>
              <Button onClick={nextStep} size={'sm'} variant={'primary'}>
                {t('footer.next')}
              </Button>
            </div>
          </div>
        ) : (
          <PesticidesTable
            pest={apiPest?.data}
            onNext={handleNext}
            onBack={handleBack}
            data={pesticidesList}
            columns={pesticidesColumn}
          />
        )}
      </div>
    )
  }

  if ((isMicro && currentStep === 1) || currentStep === 2) {
    return <PestApplicationMethod back={handleBack} />
  }

  return (
    <div className={'flex flex-col gap-4'}>
      <div className={'flex flex-col gap-4 justify-start items-start'}>
        <Text className={' flex justify-center items-center'} size={'medium'} variant={'primary'}>
          Fungicide Chemical Selection
        </Text>
        <div className='flex'>
          <Info size='18' color={'var(--primary)'} className='mr-2.5' />
          <Text variant='primary' size='small' className='font-bold'>
            Please select one or more fungicides from the table below.
          </Text>
        </div>
      </div>

      {fungicidesList.length === 0 ? (
        <div>
          <div className={'min-h-[200px] flex flex-col gap-4 items-center justify-center'}>
            <Text size={'xLarge'} className={'opacity-40'}>
              <CircleOff />
            </Text>
            <Text size={'medium'} variant={'bodyTextLight'}>
              No Fungicides Available for your Selected Crop
            </Text>
          </div>

          <div className='w-full flex justify-end gap-2'>
            <Button
              onPointerEnter={isDesktop ? undefined : nextStep}
              size={'sm'}
              variant={'outlined'}
              onClick={nextStep}
            >
              {t('footer.skip')}
            </Button>
            <Button onClick={prevStep} size={'sm'} variant={'secondary'}>
              {t('footer.prev')}
            </Button>
            <Button onClick={handleNext} size={'sm'} variant={'primary'}>
              {t('footer.next')}
            </Button>
          </div>
        </div>
      ) : (
        <FungicidesTable
          pest={apiPest?.data}
          onNext={handleNext}
          onBack={handleBack}
          data={fungicidesList}
          columns={fungicidesColumn}
        />
      )}
    </div>
  )
}
