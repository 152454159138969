import type React from 'react'
import { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '../../../../../components/ui'
import { temoBenefits, type TemoBenefits } from './Data/BenefitsData'
import TreeIcon from '../../../../../assets/landing/icons/tree.svg'
import { CTAButton } from '../Generic/CTAButton'
import { useTranslation } from 'react-i18next' // Import the tree icon
import { motion } from 'framer-motion'

const SingleBenefit: React.FC = () => {
  const [selectedBenefit, setSelectedBenefit] = useState<TemoBenefits | null>(null)
  const { t } = useTranslation('landing')
  return (
    <div className='grid grid-cols-1 md:grid-cols-2 md:px-8  gap-6'>
      {temoBenefits(t).map((benefit, index) => (
        <div key={benefit.id} className={`max-w-lg mx-auto ${index === 0 ? 'md:col-span-2' : ''}`}>
          <motion.div
            className={`${benefit.backgroundColor} w-full h-full cursor-pointer rounded-xl flex flex-col space-y-16 p-8 lg:p-16 group-hover:bg-gradient-to-r group-hover:from-${benefit.gradientFrom} group-hover:to-${benefit.gradientTo}`}
            onClick={() => {
              setSelectedBenefit(benefit)
            }}
            whileHover={{ scale: 1.02 }}
          >
            <h4
              className={`${benefit.fontColor} text-2xl md:text-3xl lg:text-4xl text-center font-bold landing-page`}
            >
              {benefit.title}
            </h4>
            <div className={`${benefit.fontColor} text-sm font-light text-center`}>
              {t('home.click')}
            </div>
          </motion.div>

          <Dialog
            open={selectedBenefit?.id === benefit.id}
            onOpenChange={() => {
              setSelectedBenefit(null)
            }}
          >
            <DialogContent
              className={`${benefit.backgroundColor} w-full max-w-xs md:max-w-2xl lg:max-w-6xl p-4 md:p-8 lg:p-12 `}
            >
              <DialogHeader className='flex flex-col space-y-12'>
                <DialogTitle>
                  <h4
                    className={`${benefit.fontColor} text-2xl md:text-3xl lg:text-4xl text-center font-bold landing-page`}
                  >
                    {benefit.title}
                  </h4>
                </DialogTitle>
                <DialogDescription className='flex flex-col space-y-12'>
                  <ul className='list-none flex flex-col space-y-8'>
                    <li
                      className={`${benefit.fontColor} text-sm md:text-base lg:text-lg font-regular landing-page flex items-start justify-left space-x-2`}
                    >
                      <img src={TreeIcon} alt='Tree Icon' className='w-6 h-6 mt' />
                      <span>{benefit.benefitOne}</span>
                    </li>
                    <li
                      className={`${benefit.fontColor} text-sm md:text-base lg:text-lg font-regular landing-page flex items-start justify-left space-x-2`}
                    >
                      <img src={TreeIcon} alt='Tree Icon' className='w-6 h-6' />
                      <span>{benefit.benefitTwo}</span>
                    </li>
                    <li
                      className={`${benefit.fontColor} text-sm md:text-base lg:text-lg font-regular landing-page flex items-start justify-left space-x-2`}
                    >
                      <img src={TreeIcon} alt='Tree Icon' className='w-6 h-6' />
                      <span>{benefit.benefiThree}</span>
                    </li>
                  </ul>
                  <div className='flex items-center justify-center'>
                    <CTAButton label={t('home.explore2')} to='about-temo-letlotlo' />
                  </div>
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
        </div>
      ))}
    </div>
  )
}

export default SingleBenefit