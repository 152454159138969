import type { ColumnDef } from '@tanstack/react-table'
import { Checkbox } from 'components/ui/checkbox'
import { DataTableColumnHeader } from 'components/Data-Table/datatable-header'
import { type SoilCorrectionRecommendation } from '../../types'

export const SoilRecommendationColumns = (
  t: (key: string) => string
): Array<ColumnDef<SoilCorrectionRecommendation>> => [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllRowsSelected() || (table.getIsSomeRowsSelected() && 'indeterminate')}
        onCheckedChange={(value) => {
          table.toggleAllRowsSelected(false)
        }}
        type={'button'}
        aria-label='Select row'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => {
          row.toggleSelected(!!value)
        }}
        type={'button'}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false
  },
  {
    id: 'ph',
    accessorFn: (row) => row.ph_correction_recommendation?.fertilizer_name,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('ph') ?? 'N/A'}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Ph Correction' />
  },
  {
    id: 'sodium',
    accessorFn: (row) => row.sodium_correction_recommendation?.fertilizer_name,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('sodium') ?? 'N/A'}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Sodium Correction' />
  },
  {
    id: 'calcium',
    accessorFn: (row) => row.calcium_correction_recommendation?.fertilizer_name,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('calcium') ?? 'N/A'}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Calcium Correction' />
  },
  {
    id: 'magnesium',
    accessorFn: (row) => row.magnesium_correction_recommendation?.fertilizer_name,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('magnesium') ?? 'N/A'}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Magnesium Correction' />
  },
  {
    id: 'cost',
    accessorFn: (row) => row.total_cost,
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue('cost'))
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'BWP'
      }).format(amount)

      return <div className='font-medium uppercase'>{formatted}</div>
    },
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('soilReco.cost')} />
  }
]