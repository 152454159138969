interface MmokoRewardPackage {
  description: string
}

interface ProgramPackages {
  MmokoRewardPackages: MmokoRewardPackage[]
}

const programPackages = (t: (key: string) => string): ProgramPackages => {
  return {
    MmokoRewardPackages: [
      {
        description: t('mrp.mrp11')
      },
      {
        description: t('mrp.mrp12')
      },
      {
        description: t('mrp.mrp13')
      }
    ]
  }
}

export default programPackages