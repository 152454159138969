import {
  type ColumnDef,
  type ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type SortingState,
  useReactTable
} from '@tanstack/react-table'
import type React from 'react'
import { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table'
import { DataTablePagination } from 'components/Data-Table/datatable-pagination'
import { DataTableColumnFilter } from 'components/Data-Table/datatable-filter'
import { type GetInventoryRes } from 'features/farmer/inventory/types'
import { useNavigate } from 'react-router-dom'
import { useAuthenticationStore } from '../../../../../stores/useAuthenticationStore'
import { useTranslation } from 'react-i18next'

interface InventoryResultsProps<TData, TValue> {
  columns: Array<ColumnDef<TData, TValue>>
  data: TData[]
}

export function MOInventoriesTable<TData, TValue>({
  columns,
  data
}: InventoryResultsProps<TData, TValue>): React.ReactNode {
  const { role } = useAuthenticationStore()
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const navigate = useNavigate()
  const { t } = useTranslation('inventory')

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      columnFilters
    }
  })

  return (
    <div className={'flex flex-col gap-4'}>
      <div className={'flex flex-col sm:flex-row gap-4 justify-start'}>
        <DataTableColumnFilter
          columnId={role === 1000 ? 'farmer_name' : 'merchant_name'}
          table={table}
          placeHolder={t('mech.filter')}
        />
      </div>
      <p className={'text-info'}>
        {t('mech.sr')}: {data.length}
      </p>
      <div className='rounded-md border'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id} className='text-center font-bold'>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length !== 0 ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className={'hover:cursor-pointer hover:bg-muted-hover'}
                  onClick={() => {
                    const inventoryItem = row.original as GetInventoryRes

                    navigate(
                      `${role === 1001 ? '/officer' : ''}/inventory/view/${
                        inventoryItem?.merchant_id != null ? 'merchant' : 'farmer'
                      }/${
                        inventoryItem?.merchant_id != null
                          ? inventoryItem.merchant_id
                          : Number(inventoryItem.farmer_id)
                      }`
                    )
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className='text-center font-medium'>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className='h-24 text-center'>
                  {t('mech.nosr')}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  )
}
