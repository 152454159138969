import type React from 'react'
import { motion } from 'framer-motion'
import backgroundImage from '../../../../../assets/landing/impact_in_action/20230119_10.jpg'
import { useTranslation } from 'react-i18next'

/**
 * HeaderImage component
 *
 * This component renders a header section with a background image, an overlay, and text content.
 * It uses Tailwind CSS classes for styling and layout.
 *
 * @returns {JSX.Element} The rendered header section
 */
export const HeaderImage: React.FC = () => {
  const { t } = useTranslation('landing')
  return (
    <div className='bg-blue-50 w-full relative h-fit-content'>
      <div className='container mx-auto flex flex-col md:flex-row py-40 lg:py-60 justify-center w-full'>
        {/* Background Image */}
        <div
          className='absolute inset-0 bg-cover bg-center'
          style={{
            backgroundImage: `url(${backgroundImage})`
          }}
        ></div>

        {/* Overlay */}
        <div className='absolute inset-0 bg-black opacity-60'></div>

        {/* Text */}
        <motion.div
          className='relative flex flex-col justify-center items-center h-full text-white text-center px-4 md:px-8 lg:px-16 space-y-8 max-w-screen-lg mx-auto'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className='text-4xl md:text-5xl lg:text-6xl font-bold mb-4 text-white leading-relaxed tracking-wide landing-page'>
            {t('about.build')}
          </h1>
          <div className='bg-black bg-opacity-35 py-4 px-1 rounded-md'>
            <p className='text-lg md:text-2xl xl:text-3xl text-regular leading-relaxed tracking-wide landing-page'>
              {t('about.support')}
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  )
}