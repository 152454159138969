import type React from 'react'
import programPackages from '../Data/ProgramPackages/GrantPackageData'
import TreeIcon from '../../../../../../assets/landing/icons/tree.svg'
import { useTranslation } from 'react-i18next'

/**
 * Interface representing the structure of a requirement.
 */
interface Requirement {
  title: string
  description: string
}

/**
 * Interface representing the structure of a benefit.
 */
interface Benefit {
  title: string
  description: string
}

/**
 * Interface representing the structure of an obligation.
 */
interface Obligation {
  description: string
}

/**
 * Interface representing the structure of the program packages.
 */
interface ProgramPackages {
  programDescription: string
  eligibilityRequirements: Requirement[]
  keyBenefits: Benefit[]
  obligations: Obligation[]
}

/**
 * React functional component to display the Grants Package details.
 *
 * @returns {JSX.Element} The JSX code to render the Grants Package details.
 */
export const GrantsPackage: React.FC = () => {
  const { t } = useTranslation('landing')
  const { programDescription, eligibilityRequirements, keyBenefits, obligations } = programPackages(
    t
  ) as ProgramPackages

  return (
    <div className='w-full mt-16'>
      {/* Program Description */}
      <div className='flex flex-col md:flex-row justify-center items-center'>
        <p className='text-base md:text-xl lg:text-2xl text-gray-600 my-2 landing-page '>
          {programDescription}
        </p>
      </div>
      {/* Eligibility Requirements */}
      <div className='flex flex-col justify-center items-start my-8'>
        <h2 className='text-base md:text-xl lg:text-3xl font-bold text-gray-700 landing-page '>
          {t('pp.er')}:
        </h2>
        <ul className='mt-4'>
          {eligibilityRequirements.map((requirement: Requirement, index: number) => (
            <li key={index} className='text-base md:text-xl lg:text-2xl text-gray-600 my-6'>
              <div className='flex flex-row items-start'>
                <img src={TreeIcon} alt='Tree Icon' className='w-6 h-6 mr-5' />
                <div className='flex flex-col'>
                  <h4 className='text-base md:text-xl lg:text-2xl font-bold text-gray-700 mb-1 landing-page'>
                    {requirement.title}:
                  </h4>
                  <p className='text-base md:text-xl lg:text-2xl text-gray-600 landing-page'>
                    {requirement.description}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {/* Key Benefits */}
      <div className='flex flex-col justify-center items-start my-8'>
        <h2 className='text-base md:text-xl lg:text-3xl font-bold text-gray-700 landing-page'>
          {t('pp.kb')}
        </h2>
        <ul className='mt-4'>
          {keyBenefits.map((benefit: Benefit, index: number) => (
            <li key={index} className='text-base md:text-xl lg:text-2xl text-gray-600 my-6'>
              <div className='flex flex-row items-start'>
                <img src={TreeIcon} alt='Tree Icon' className='w-6 h-6 mr-5' />
                <div className='flex flex-col'>
                  <h4 className='text-base md:text-xl lg:text-2xl font-bold text-gray-700 mb-1 landing-page'>
                    {benefit.title}:
                  </h4>
                  <p className='text-base md:text-xl lg:text-2xl text-gray-600 landing-page'>
                    {benefit.description}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {/* Obligations */}
      <div className='flex flex-col justify-center items-start my-8'>
        <h2 className='text-base md:text-xl lg:text-3xl font-bold text-gray-700 landing-page'>
          {t('pp.obl')}
        </h2>
        <ul className='mt-4'>
          {obligations.map((obligation: Obligation, index: number) => (
            <li key={index} className='text-base md:text-xl lg:text-2xl text-gray-600 my-6'>
              <div className='flex flex-row items-start'>
                <img src={TreeIcon} alt='Tree Icon' className='w-6 h-6 mr-5' />
                <div className='flex flex-col'>
                  <p className='text-base md:text-xl lg:text-2xl text-gray-600 landing-page'>
                    {obligation.description}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}