import type React from 'react'
import { type Farm } from 'types'
import { OTPInput } from 'components/Otp/InputOTP'
import { useFarmEdits } from '../../../../hooks/useFarmEdits'
import type { SubdivisionInput } from './SubdivisionFarmDetails'

interface SubdivideFarmOtpFormProps {
  farm: Farm
  data: SubdivisionInput
  onSuccess: () => void
}

export const SubdivideFarmOtpForm: React.FC<SubdivideFarmOtpFormProps> = ({
  farm,
  onSuccess,
  data
}) => {
  const { subdivideLand, isLoading } = useFarmEdits()

  const onSubmit = (): void => {
    subdivideLand(farm.farm_id, data, onSuccess)
  }

  return <OTPInput isLoading={isLoading} number={farm.farmer_contact} next={onSubmit} />
}
