import React from 'react'
import { Button } from '../../../../../components/ui'
import { useNavigate } from 'react-router-dom'

interface CTAButtonProps {
  label: string
  to: string
}

export function CTAButton({ label, to }: CTAButtonProps): JSX.Element {
  const navigate = useNavigate()
  return (
    <Button
      variant='primary'
      size='lg'
      className='w-fit p-3 rounded-lg'
      onClick={() => {
        navigate(to)
      }}
    >
      <p className='text-xs md:text-sm lg:text-base font-bold text-white'>{label}</p>
    </Button>
  )
}
