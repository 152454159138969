// src/features/landing/data/eventsData.ts
// import TreeIcon from 'assets/landing/icons/tree.svg'

export interface Event {
  id: number
  title: string
  date: string
  icon: string
}

export const events: Event[] = [
  // {
  //   id: 1,
  //   title: 'Sustainable Farming Workshop',
  //   date: 'Oct 15, 2024',
  //   icon: TreeIcon
  // },
  // {
  //   id: 2,
  //   title: 'Organic Farming Seminar',
  //   date: 'Nov 20, 2024',
  //   icon: TreeIcon
  // },
  // {
  //   id: 3,
  //   title: 'AgriTech Expo',
  //   date: 'Dec 5, 2024',
  //   icon: TreeIcon
  // }
]
