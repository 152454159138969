import type React from 'react'
import { Text } from 'components/ui'
import { CircleOff } from 'lucide-react'
import { TabView } from 'components/App/Layout'
import { useTranslation } from 'react-i18next'

export const NotAvailable: React.FC = () => {
  const { t } = useTranslation('genericTranslation')

  return (
    <TabView className={'min-h-[300px] flex flex-col gap-4 items-center justify-center'}>
      <Text size={'xLarge'} className={'opacity-40'}>
        <CircleOff />
      </Text>
      <Text size={'medium'} variant={'bodyTextLight'}>
        {t('misc.notAvail')}
      </Text>
    </TabView>
  )
}