import type React from 'react'

interface MainProps {
  children: React.ReactNode
  className?: string
}
export const Main: React.FC<MainProps> = ({ children, className }) => {
  return (
    <div
      className={`w-full h-full flex flex-col p-2 sm:p-4 px-2 overflow-y-scroll mx-auto ${
        className ?? ''
      }`}
    >
      {children}
    </div>
  )
}

export const Box: React.FC<MainProps> = ({ children, className }) => {
  return (
    <div
      className={`w-full max-w-[1500px] flex flex-col gap-4 sm:gap-8 mx-auto ${className ?? ''}`}
    >
      {children}
    </div>
  )
}

export const Wrapper: React.FC<MainProps> = ({ children, className }) => {
  return (
    <div
      className={`w-full max-w-[1700px] flex flex-col gap-4 sm:gap-8 mx-auto ${className ?? ''}`}
    >
      {children}
    </div>
  )
}

export const TabView: React.FC<MainProps> = ({ className, children }) => {
  return (
    <div
      className={`bg-card w-full flex flex-col gap-4 sm:gap-6 p-4 sm:p-6 md:p-8 lg:p-12 rounded-md shadow-md ${
        className ?? ''
      }`}
    >
      {children}
    </div>
  )
}
