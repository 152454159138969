import { Chip, useMediaQuery } from '@mui/material'
import type { Dispatch, SetStateAction } from 'react'

interface FilterFarmHistoryMenuProps {
  transfer: number
  changeHactorage: number
  subdivision: number
  filterBy: 'farm transfer' | 'change hactorage' | 'subdivision'
  setFilterBy: Dispatch<SetStateAction<'farm transfer' | 'change hactorage' | 'subdivision'>>
}

export const FilterFarmHistoryMenu: React.FC<FilterFarmHistoryMenuProps> = ({
  transfer,
  changeHactorage,
  subdivision,
  setFilterBy,
  filterBy
}) => {
  const handleFilter = (type: 'farm transfer' | 'change hactorage' | 'subdivision'): void => {
    setFilterBy(type)
  }
  const isSmallScreen = useMediaQuery('(max-width:600px)')

  return (
    <div className={'w-full flex justify-start items-center gap-2'}>
      <Chip
        label={isSmallScreen ? `Transfer(${transfer})` : `Farm Transfer (${transfer})`}
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'farm transfer' ? 'var(--primary)' : 'transparent',
          '&:hover': {
            backgroundColor: filterBy === 'farm transfer' ? 'var(--primary-hover)' : 'transparent'
          },
          padding: { xs: '0.4rem 0rem', sm: '0.4rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'farm transfer' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color: filterBy === 'farm transfer' ? 'var(--primary-foreground)' : 'var(--foreground)',
            '&:hover': {
              color: filterBy === 'farm transfer' ? 'var(--primary-foreground)' : 'var(--primary)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '13px',
              md: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('farm transfer')
        }}
        clickable={true}
      />

      <Chip
        label={
          isSmallScreen ? `Change(${changeHactorage})` : `Change Hactorage (${changeHactorage})`
        }
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'change hactorage' ? 'var(--primary)' : 'transparent',
          '&:hover': {
            backgroundColor:
              filterBy === 'change hactorage' ? 'var(--primary-hover)' : 'transparent'
          },
          padding: { xs: '0.4rem 0rem', sm: '0.4rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'change hactorage' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color:
              filterBy === 'change hactorage' ? 'var(--primary-foreground)' : 'var(--foreground)',
            '&:hover': {
              color:
                filterBy === 'change hactorage' ? 'var(--primary-foreground)' : 'var(--primary)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '13px',
              md: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('change hactorage')
        }}
        clickable={true}
      />

      <Chip
        label={isSmallScreen ? `Subdivision(${subdivision})` : `Subdivision (${subdivision})`}
        size={'small'}
        sx={{
          backgroundColor: filterBy === 'subdivision' ? 'var(--primary)' : 'transparent',
          '&:hover': {
            backgroundColor: filterBy === 'subdivision' ? 'var(--primary-hover)' : 'transparent'
          },
          padding: { xs: '0.4rem 0rem', sm: '0.4rem', md: '0.5rem 1rem' },
          borderRadius: filterBy === 'subdivision' ? '0.4rem' : '0.4rem',
          '& .MuiChip-label': {
            color: filterBy === 'subdivision' ? 'var(--primary-foreground)' : 'var(--foreground)',
            '&:hover': {
              color: filterBy === 'subdivision' ? 'var(--primary-foreground)' : 'var(--primary)'
            },
            fontWeight: '400',
            fontSize: {
              xs: '12px',
              sm: '13px',
              md: '14px'
            },
            fontFamily: 'Red Hat Display'
          }
        }}
        onClick={() => {
          handleFilter('subdivision')
        }}
        clickable={true}
      />
    </div>
  )
}
