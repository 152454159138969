import type React from 'react'
import { useState } from 'react'
import { Button, Text } from 'components/ui'
import type { Lease } from 'types'
import { LeaseRenewal } from './LeaseRenewal'
import { useTranslation } from 'react-i18next'

interface RenewalRequestProps {
  lease: Lease
  next: () => void
}

export const RenewalRequest: React.FC<RenewalRequestProps> = ({ lease, next }) => {
  const [showForm, setShowForm] = useState<boolean>(false)
  const { t } = useTranslation('leaseManagement')

  const handleNext = (): void => {
    setShowForm(true)
  }

  if (showForm) {
    return <LeaseRenewal lease={lease} next={next} />
  }

  return (
    <div className={'w-full flex flex-col gap-4'}>
      <Text className={'text-center'} size={'large'} variant={'colorText'}>
        {t('ren.prompt')}
      </Text>
      <Text className={'text-center'} size={'medium'} variant={'bodyTextLight'}>
        {t('ren.sure')} <strong>{lease.origin_farmer_name}</strong>.
      </Text>

      <Text variant={'error'} className={'text-center font-bold uppercase'} size={'medium'}>
        {t('ren.note')}
      </Text>
      <div className={'w-full flex justify-between gap-4 md:gap-8'}>
        <Button variant={'secondary'} className={'w-1/2'} onClick={next}>
          {t('ren.cancel')}
        </Button>
        <Button className={'w-1/2'} variant={'success'} onClick={handleNext}>
          {t('ren.renew')}
        </Button>
      </div>
    </div>
  )
}