import type React from 'react'
import { motion } from 'framer-motion'
import FarmTwo from '../../../../../assets/landing/dashboard_snippets/my_farms.png'
import { CTAButton } from '../Generic/CTAButton'
import { useTranslation } from 'react-i18next'

/**
 * A React functional component that renders the header section for the Temo CMS.
 * This section includes a title, a description, a registration button, and an image.
 *
 * @returns {JSX.Element} The rendered component.
 */
export const HeaderTemo: React.FC = () => {
  const { t } = useTranslation('landing')
  return (
    <div className='flex flex-col pt-40 pb-20 px-10 bg-white space-y-8'>
      {/* <div className='container mx-auto flex flex-col px-10 items-center space-y-24'> */}

      <motion.div
        className='container mx-auto flex flex-col px-10 items-center space-y-12'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className='text-4xl md:text-5xl lg:text-6xl font-bold text-gray-700 text-center landing-page'>
          {t('cms.empower')}
        </h2>
        <p className='text-base md:text-xl lg:text-2xl font-regular text-center text-gray-600 md:px-[10vw] lg:px-[20vw] xl:px-0 landing-page'>
          {t('cms.manage')}
        </p>
      </motion.div>
      <motion.div
        className='container mx-auto flex justify-center items-center'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <CTAButton label={t('home.reg')} to='/registration' />
      </motion.div>
      <motion.div
        className='flex flex-col justify-center items-center px-80 xl:px-96 w-full'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <img src={FarmTwo} alt='temo-cms-header-image' className='  w-full max-w-[1800px]' />
      </motion.div>
      {/* </div> */}
    </div>
  )
}
