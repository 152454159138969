import type React from 'react'
import { Button, Text } from 'components/ui'
import { useTerminateLease } from 'features/farmer/hooks/useTerminateLease'
import type { Lease } from 'types'
import { useTranslation } from 'react-i18next'

interface TerminationRequestProps {
  lease: Lease
  next: () => void
}

export const TerminationRequest: React.FC<TerminationRequestProps> = ({ lease, next }) => {
  const { lesseeInitiateTermination, isLoading } = useTerminateLease(next)
  const { t } = useTranslation('leaseManagement')

  const onTerminate = (): void => {
    lesseeInitiateTermination(lease)
  }

  return (
    <div className={'w-full flex flex-col gap-4'}>
      <Text className={'text-center'} size={'large'} variant={'colorText'}>
        {t('ren.tl')}
      </Text>
      <Text className={'text-center'} size={'medium'} variant={'bodyTextLight'}>
        {t('ren.tlSure')}
        <strong>{lease.origin_farmer_name}</strong>, {t('ren.and')}
      </Text>

      <Text variant={'error'} className={'text-center font-bold uppercase'} size={'medium'}>
        {t('ren.note')}
      </Text>
      <div className={'w-full flex justify-between gap-4 md:gap-8'}>
        <Button variant={'secondary'} disabled={isLoading} className={'w-1/2'} onClick={next}>
          {t('ren.cancel')}
        </Button>
        <Button
          className={'w-1/2'}
          variant={'destructive'}
          disabled={isLoading}
          loading={isLoading}
          onClick={onTerminate}
        >
          {t('ren.term')}
        </Button>
      </div>
    </div>
  )
}