import React from 'react'
import { Button, Dialog, DialogContent, Text } from 'components/ui'
import { type Lease } from 'types'
import { Separator } from 'components/ui/separator'
import { RenewalRequest } from '../renewal-and-termination/RenewalRequest'

interface RenewLeaseProps {
  lease: Lease
}
export const RenewLease: React.FC<RenewLeaseProps> = ({ lease }) => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false)

  const handleOpenDialog = (): void => {
    setOpenDialog(true)
  }

  const handleCloseDialog = (): void => {
    setOpenDialog(false)
  }

  const isRenewRequest = lease.status_desc === 'RENEWAL INITIATED BY LESSEE'
  const cannotRenew =
    lease.status_desc !== 'ACCEPTED BY LESSEE' &&
    lease.status_desc !== 'VERIFIED BY EXTENSION OFFICER' &&
    lease.status_desc !== 'RENEWAL ACCEPTED BY LESSOR'

  return (
    <>
      <div className={'flex flex-col items-start gap-4 md:gap-6 lg:gap-8'}>
        <Text size={'large'}>Renew Farm Lease</Text>
        <Separator />
        <Text variant={'bodyTextLight'} size={'body'}>
          By clicking "Renew" below, I acknowledge and accept the Terms And Conditions for renewal
          set by the owner of the farm{' '}
          <span className={'text-primary'}>
            {lease.origin_farmer_type === 2
              ? lease.origin_farmer_company_name
              : lease.origin_farmer_name}
          </span>
          .
        </Text>
        {isRenewRequest ? (
          <Text variant={'bodyTextLight'} size={'body'}>
            {' '}
            Lease renewal request awaiting approval from:{' '}
            <span className={'text-primary'}>
              {lease.origin_farmer_type === 2
                ? lease.origin_farmer_company_name
                : lease.origin_farmer_name}
            </span>
          </Text>
        ) : null}
        <div className={'flex flex-col items-start'}>
          <Button
            disabled={isRenewRequest || cannotRenew}
            variant={'success'}
            size={'sm'}
            onClick={handleOpenDialog}
          >
            Renew
          </Button>
        </div>
      </div>

      <Dialog
        key={'renew-lease'}
        open={openDialog}
        defaultOpen={false}
        modal
        onOpenChange={handleCloseDialog}
      >
        <DialogContent className={'max-w-[80%] md:max-w-[50%] lg:max-w-[35%]'}>
          <RenewalRequest lease={lease} next={handleCloseDialog} />
        </DialogContent>
      </Dialog>
    </>
  )
}
