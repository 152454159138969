import { OtpField } from 'components/Form/OtpField'
import { ResendOtp } from 'components/Generic/ResendOtp'
import { getTimerTime } from 'features/authentication/utils/otp'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useTimer } from 'react-timer-hook'
import { useOtpStore } from 'stores/useOtpStore'
import { useTranslation } from 'react-i18next'
import { useSuperTokens } from '../../hooks/useSuperTokens'
import { z } from 'zod'
import {
  Button,
  Text,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from 'components/ui'
import { InputOTP, InputOTPGroup, InputOTPSlot } from 'components/ui/input-otp'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

interface RegistrationOtpFormProps {
  className?: string
  number: string
  onBack: () => void
  onNext: () => void
}

const FormSchema = z.object({
  otp: z.string().min(6, {
    message: 'Your one-time password must be 6 characters.'
  })
})
export const RegistrationOtpForm: React.FC<RegistrationOtpFormProps> = ({
  className,
  onBack,
  number,
  onNext
}) => {
  const {
    setExpiryTimeOfResend,
    resendCounter,
    expiryTimeOfResend,
    setTimeOfFirstResend,
    setResendCounter
  } = useOtpStore()
  const { registerFarmer, resendOtp, isLoading } = useSuperTokens()
  const { hours, minutes, seconds, isRunning, restart } = useTimer({
    expiryTimestamp:
      expiryTimeOfResend !== null ? new Date(expiryTimeOfResend) : new Date(Date.now())
  })
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      otp: ''
    }
  })

  const hiddenNumber = String(number).slice(-3)

  const onSubmit = async (data: z.infer<typeof FormSchema>): Promise<void> => {
    await registerFarmer(data.otp, onNext)
  }

  useEffect(() => {
    if (
      resendCounter > 1 &&
      expiryTimeOfResend !== null &&
      new Date(Date.now()) < expiryTimeOfResend
    ) {
      restart(expiryTimeOfResend)
    }
  }, [expiryTimeOfResend, resendCounter])

  const handleResendOtp = async (): Promise<void> => {
    if (resendCounter === 0) {
      setTimeOfFirstResend(new Date(Date.now()))
    }

    const counter = resendCounter + 1
    setResendCounter(resendCounter + 1)

    setExpiryTimeOfResend(getTimerTime(new Date(Date.now()), counter))
    restart(getTimerTime(new Date(Date.now()), counter))

    await resendOtp()
    form.resetField('otp')
  }

  const { t } = useTranslation('genericTranslation')

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={'flex flex-col gap-4 md:gap-6 lg:gap-8 items-center'}
      >
        <FormField
          control={form.control}
          name='otp'
          render={({ field }) => (
            <FormItem className='flex flex-col gap-2 items-center'>
              <FormLabel className={'text-center text-lg font-bold'}>{t('otp.otp')}</FormLabel>
              <FormDescription className={'text-center'}>
                {t('otp.prompt')}
                <span className={'text-primary'}>*****{hiddenNumber}</span>
              </FormDescription>
              <FormControl>
                <InputOTP maxLength={6} {...field}>
                  <InputOTPGroup>
                    <InputOTPSlot index={0} />
                    <InputOTPSlot index={1} />
                    <InputOTPSlot index={2} />
                    <InputOTPSlot index={3} />
                    <InputOTPSlot index={4} />
                    <InputOTPSlot index={5} />
                  </InputOTPGroup>
                </InputOTP>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <p className={'text-center'}>{t('otp.request')}</p>
        <ResendOtp
          seconds={seconds}
          minutes={minutes}
          hours={hours}
          isDisabled={(isRunning && seconds + minutes + hours > 0) || isLoading}
          onResend={handleResendOtp}
        />
        <div className={'px-2'}>
          <Text size={'small'}>
            {t('otp.back')}
            <Button variant={'link'} onClick={onBack} size={'sm'}>
              Number
            </Button>
          </Text>
        </div>
        <div className='w-full flex justify-center'>
          <Button
            variant={'success'}
            type={'submit'}
            onClick={form.handleSubmit(onSubmit)}
            size={'xs'}
            disabled={isLoading}
            loading={isLoading}
          >
            {t('otp.verify')}
          </Button>
        </div>
      </form>
    </Form>
  )
}
