import React, { type FC, useEffect } from 'react'
import { TabView } from '../../../../../../components/App/Layout'
import { Loader, Text } from '../../../../../../components/ui'
import { CircleOff } from 'lucide-react'
import { useGetOfficerSoilCorrection } from '../api/getCorrection'
import { useOfficerStore } from '../../../../../../stores/useOfficerStore'
import { useParams } from 'react-router-dom'
import { Error } from '../../../../../../components/Errors/Error'
import { OfficerSoilCorrectionCard } from '../components/OfficerSoilCorrectionCard'
import { useTranslation } from 'react-i18next'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { ComingSoon } from '../../../../../../components/Layout/ComingSoon'

export const OfficerSoilCorrection: FC = () => {
  const flags = useFlags()
  const ldClient = useLDClient()
  const { officer } = useOfficerStore()
  const { t } = useTranslation('extensionOfficer')
  const { farmId } = useParams<{ farmId: string }>()
  const { farmerId } = useParams<{ farmerId: string }>()
  const {
    data: scr,
    isLoading,
    error
  } = useGetOfficerSoilCorrection(farmerId, officer?.ext_area_id, farmId)

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  if (flags.soilCorrection === false) {
    return <ComingSoon />
  }

  if (isLoading) {
    return (
      <TabView>
        <div className={'grid place-items-center'}>
          <Loader />
        </div>
      </TabView>
    )
  }

  if (error != null) {
    if (error?.response?.status === 404) {
      return (
        <TabView>
          <div className={'min-h-[300px] flex flex-col gap-4 items-center justify-center'}>
            <Text size={'xLarge'} className={'opacity-40'}>
              <CircleOff />
            </Text>
            <Text className={'text-center max-w-[400px]'} size={'medium'} variant={'bodyTextLight'}>
              {t('farm.nosc')}
            </Text>
          </div>
        </TabView>
      )
    }
    return <Error />
  }

  if (scr?.data == null) {
    return (
      <TabView>
        <div className={'min-h-[300px] flex flex-col gap-4 items-center justify-center'}>
          <Text size={'xLarge'} className={'opacity-40'}>
            <CircleOff />
          </Text>
          <Text className={'text-center max-w-[400px]'} size={'medium'} variant={'bodyTextLight'}>
            {t('farm.nosc')}
          </Text>
        </div>
      </TabView>
    )
  }

  return (
    <TabView>
      <div className={'flex justify-between items-center'}>
        <Text size={'medium'}>{t('farm.sc')}</Text>
      </div>
      <OfficerSoilCorrectionCard scr={scr.data[0]} />
    </TabView>
  )
}
