import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'
import { type InventoryTransfer } from '../../../farmer/inventory/types'

export const getMOTranferRequests = async (
  idType: string | null | undefined,
  idInput: string | null | undefined,
  districtId: number | null | undefined
): Promise<ApiResponse<InventoryTransfer>> => {
  const queryParams =
    idType === 'merchantId'
      ? `origin_merchant_id=${idInput ?? ''}`
      : `origin_farmer_id=${idInput ?? ''}`

  return axios.get(
    `inventory/transfer?${queryParams ?? ''}&district_id=${districtId ?? ''}&is_active=true`
  )
}
