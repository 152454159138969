import { Button, Card, DropdownMenu, DropdownMenuTrigger, Text } from '../../../../../components/ui'
import type React from 'react'
import { useEffect, useState } from 'react'
import { type InventoryTransfer } from '../../types'
import { ArrowRightLeft, MoreVertical } from 'lucide-react'
import { AcceptRejectTransfer } from './AcceptRejectTransfer'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useAuthenticationStore } from '../../../../../stores/useAuthenticationStore'

interface TransferRequestCardProps {
  transferRequest: InventoryTransfer
}

export const TransferRequestCard: React.FC<TransferRequestCardProps> = ({ transferRequest }) => {
  const [originName, setOriginName] = useState<string>('')
  const [destinationName, setDestinationName] = useState<string>('')
  const { t } = useTranslation('inventory')
  const { role } = useAuthenticationStore()

  useEffect(() => {
    if (transferRequest.origin_merchant_id != null) {
      setOriginName(`${transferRequest?.origin_merchant_name?.trim() ?? ''}`.toUpperCase())
    } else {
      setOriginName(
        transferRequest.origin_farmer_type === 2
          ? transferRequest.origin_farmer_company_name
          : `${transferRequest.origin_farmer_firstname?.trim().toUpperCase() ?? ''} ${
              transferRequest.origin_farmer_midname?.trim().toUpperCase() ?? ''
            } ${transferRequest.origin_farmer_surname?.trim().toUpperCase() ?? ''}`.trim()
      )
    }

    if (transferRequest.dest_merchant_id != null) {
      setDestinationName(`${transferRequest?.dest_merchant_name?.trim().toUpperCase() ?? ''}`)
    } else {
      setDestinationName(
        transferRequest.dest_farmer_type === 2
          ? transferRequest.dest_farmer_company_name
          : `${transferRequest.dest_farmer_firstname?.trim().toUpperCase() ?? ''} ${
              transferRequest.dest_farmer_midname?.trim().toUpperCase() ?? ''
            } ${transferRequest.dest_farmer_surname?.trim().toUpperCase() ?? ''}`.trim()
      )
    }
  }, [transferRequest])

  const getStatusMessage = (status: InventoryTransfer): string => {
    switch (status.transaction_status) {
      case 1:
        return 'Awaiting MO Verification'
      case 4:
        return 'Awaiting Your Approval'
      case 3:
      case 5:
        return status.message
      default:
        return 'Unknown Status'
    }
  }
  return (
    <Card className='relative grid gap-4 p-4'>
      {((role === 1010 && transferRequest.transaction_status === 1) ||
        transferRequest.transaction_status === 4) && (
        <div className='absolute top-2 right-2'>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size='icon' variant='ghost' className='h-8 w-8'>
                <span className='sr-only'>Open menu</span>
                <MoreVertical className='h-5 w-5' />
              </Button>
            </DropdownMenuTrigger>
            <AcceptRejectTransfer transferRequest={transferRequest} />
          </DropdownMenu>
        </div>
      )}

      <div className='flex items-center gap-4'>
        <ArrowRightLeft className='w-8 h-8 sm:w-8 sm:h-8 text-success' />
        <Text className='text-center' size='small'>
          Transfer Date
          <Text className={'text-center'} size={'bodyBold'}>
            {`${dayjs(transferRequest.last_update_date).locale('en').format('YYYY/MM/DD hh:mm A')}`}
          </Text>
        </Text>
      </div>
      <div className='grid gap-1 rounded-md bg-background p-2'>
        <div className='grid gap-1'>
          <div className='flex items-center gap-2'>
            <Text size='small' variant='muted'>
              {t('transfer.from')}
            </Text>
            <Text className={'font-semibold'}>{originName}</Text>
          </div>

          {role === 1010 && (
            <div className='flex items-center gap-2'>
              <Text size='small' variant='muted'>
                To:
              </Text>
              <Text className={'font-semibold'}>{destinationName}</Text>
            </div>
          )}
          <div className='flex items-center gap-2'>
            <Text size='small' variant='muted'>
              {t('transfer.reg')}
            </Text>
            <Text className={'font-semibold'}>{transferRequest.reg_number}</Text>
          </div>
        </div>
      </div>
      <div className='flex items-center gap-2'>
        <Text className={'font-semibold'}>{getStatusMessage(transferRequest)}</Text>
      </div>
    </Card>
  )
}
