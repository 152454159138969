import { Button, useStepper, useToast, Text } from 'components/ui'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { useCroppingPlanStore } from '../../stores/useCroppingPlanStore'
import type React from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateHeaderStatus, type UpdateHeaderStatusDTO } from '../../api/header/updateHeaderStatus'
import { mapCmsErrorToMessage } from '../../../../../utils/apiErrors'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'

interface LastStepProps {
  next: () => void
}
export const LastStep: React.FC<LastStepProps> = ({ next }) => {
  const { toast } = useToast()
  const { farmer } = useFarmerStore()
  const { header } = useCroppingPlanStore()
  const { hasCompletedAllSteps } = useStepper()
  const queryClient = useQueryClient()
  const { t } = useTranslation('croppingPlan')

  const updateCroppingPlanStatusMutation = useMutation({
    mutationFn: async (cpDto: UpdateHeaderStatusDTO) => {
      return updateHeaderStatus(cpDto)
    }
  })

  const onSubmit = (): void => {
    updateCroppingPlanStatusMutation.mutate(
      {
        farmer_id: farmer?.farmer_id,
        cp_id: header?.cropping_plan_id,
        user_id: farmer?.user_id,
        status: 'Submitted CP Verification'
      },
      {
        onSuccess: (result) => {
          if (result.status === 201 && result.success) {
            next()
            toast({
              variant: 'success',
              title: 'Successfully submitted for review.'
            })
            void queryClient.invalidateQueries({ queryKey: ['cropping-plans'] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  if (!hasCompletedAllSteps) {
    return null
  }

  return (
    <div className={'flex flex-col gap-4 justify-center items-center'}>
      <Text variant={'success'} size={'xLarge'}>
        {t('last.complete')}
      </Text>
      <Text className={'text-center'} variant={'bodyText'}>
        {t('last.prompt')}
      </Text>

      <div className='w-full flex justify-end gap-2'>
        <Button
          disabled={updateCroppingPlanStatusMutation.isLoading}
          onClick={next}
          size={'sm'}
          variant={'secondary'}
        >
          {t('last.close')}
        </Button>
        <Button
          disabled={updateCroppingPlanStatusMutation.isLoading}
          onClick={onSubmit}
          size={'sm'}
          variant={'primary'}
        >
          {t('last.submit')}
        </Button>
      </div>
    </div>
  )
}