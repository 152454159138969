// Define an interface for the timeline items
interface TypesOfFarmers {
  title: string
  description: string[]
}

// Create the array of timeline items
const timeline = (t: (key: string) => string): TypesOfFarmers[] => [
  {
    title: t('types.micro'),
    description: [
      t('types.micro1'),
      t('types.micro2'),
      t('types.micro3'),
      t('types.micro4'),
      t('types.micro5')
    ]
  },
  {
    title: t('types.small'),
    description: [
      t('types.small1'),
      t('types.small2'),
      t('types.small3'),
      t('types.small4'),
      t('types.micro5')
    ]
  },
  {
    title: t('types.med'),
    description: [t('types.med1'), t('types.med2'), t('types.med3'), t('types.med4')]
  },
  {
    title: t('types.large'),
    description: [t('types.large1'), t('types.large2'), t('types.large3'), t('types.large4')]
  }
]

// Export the timeline array
export default timeline