import { useMutation, useQueryClient } from '@tanstack/react-query'
import { sendOtp, type SendOtpDTO } from 'api/sendOtp'
import { verifyOtp, type VerifyOtpDTO } from 'api/verifyOtp'
import { AxiosError, type AxiosResponse } from 'axios'
import { BasicButton } from 'components/Elements/Button'
import { OtpField } from 'components/Form/OtpField'
import { useAtom } from 'jotai'
import type React from 'react'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { type ApiResponse, type Farmer } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { createFarm, type CreateFarmDTO } from '../../api/createLand'
import { newFarmAtom } from '../../stores/newFarm'
import { useTranslation } from 'react-i18next'

interface OtpFormProps {
  className?: string
  farmer: Farmer
  onSuccess: () => void
}

export const OtpForm: React.FC<OtpFormProps> = ({ className, farmer, onSuccess }) => {
  const [otp, setOtp] = useState<string>('')
  const [newFarmDetails] = useAtom(newFarmAtom)
  const queryClient = useQueryClient()
  const { t } = useTranslation('genericTranslation')

  const resendOtpMutation = useMutation({
    mutationFn: async (otpDTO: SendOtpDTO) => {
      return sendOtp(otpDTO)
    }
  })

  const verifyOtpMutation = useMutation({
    mutationFn: async (otpDTO: VerifyOtpDTO) => {
      return verifyOtp(otpDTO)
    }
  })

  const createLandMutation = useMutation({
    mutationFn: async (farm: CreateFarmDTO) => {
      return createFarm(farm)
    }
  })

  const hiddenNumber = String(farmer.contact).slice(-3)

  const handleResendOtp = (): void => {
    setOtp('')
    resendOtpMutation.mutate(
      {
        number: farmer.contact
      },
      {
        onSuccess: (result) => {
          if (result.status === 201 && result.success) {
            toast.success('Otp Successfully sent')
          } else {
            const error = result.message
            toast.error(mapCmsErrorToMessage(error))
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err?.response as AxiosResponse<ApiResponse<[]>>
            toast.error(mapCmsErrorToMessage(error.data.message))
          } else {
            const error = err as Error
            toast.error(mapCmsErrorToMessage(error.message))
          }
        }
      }
    )
  }

  const handleCreateFarm = (): void => {
    verifyOtpMutation.mutate(
      {
        otp,
        number: farmer.contact
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            createLandMutation.mutate(
              {
                FARM_NAME: newFarmDetails.farmName,
                VILLAGE_ID: newFarmDetails.villageId as number,
                DISTRICT_ID: newFarmDetails.districtId as number,
                EXT_AREA_ID: newFarmDetails.extensionAreaId as number,
                FARMER_ID: farmer.farmer_id,
                LAST_UPDATED_BY: farmer.user_id,
                NICKNAME: newFarmDetails.nickname,
                PLOT_NO: newFarmDetails.plotNo,
                SIZE: parseFloat(newFarmDetails.size.toFixed(4)),
                SUB_DISTRICT_ID: newFarmDetails.subDistrictId as number,
                UNIT_OF_MEASURE: 1
              },
              {
                onSuccess: (resp) => {
                  if (result.status === 201 && result.success) {
                    onSuccess()
                    toast.success('Farm successfully created.')
                    void queryClient.invalidateQueries({ queryKey: ['farms'] })
                  }
                },
                onError: (err: unknown) => {
                  if (err instanceof AxiosError) {
                    const error = err.response as AxiosResponse<ApiResponse<[]>>
                    toast.error(
                      mapCmsErrorToMessage(
                        error.status.toString() ?? err.code ?? error.data.message ?? ''
                      )
                    )
                  }
                }
              }
            )
          } else {
            const error = result.message
            toast.error(mapCmsErrorToMessage(error))
          }
        },
        onError: (err: unknown) => {
          if (err instanceof AxiosError) {
            const error = err.response as AxiosResponse<ApiResponse<[]>>
            toast.error(
              mapCmsErrorToMessage(error.status.toString() ?? err.code ?? error.data.message ?? '')
            )
          }
        }
      }
    )
  }

  const handleChange = (newValue: string): void => {
    setOtp(newValue)
  }

  return (
    <form
      className={className}
      onSubmit={(e) => {
        e.preventDefault()
        handleCreateFarm()
      }}
    >
      <p className={'text-center self-center max-w-[18rem]'}>
        {t('otp.prompt')} <span>*****{hiddenNumber}</span>
      </p>
      <OtpField value={otp} onChange={handleChange} />
      <p className={'text-center'}>{t('otp.request')}</p>
      <div className='flex justify-between'>
        <BasicButton
          label={`${t('otp.resend')}`}
          variant={'secondary'}
          onClick={handleResendOtp}
          isDisabled={
            createLandMutation.isLoading ||
            resendOtpMutation.isLoading ||
            verifyOtpMutation.isLoading
          }
        />
        <BasicButton
          label={`${t('otp.finish')}`}
          type={'submit'}
          isLoading={
            createLandMutation.isLoading ||
            resendOtpMutation.isLoading ||
            verifyOtpMutation.isLoading
          }
          isDisabled={
            otp.length !== 6 ||
            createLandMutation.isLoading ||
            resendOtpMutation.isLoading ||
            verifyOtpMutation.isLoading
          }
        />
      </div>
    </form>
  )
}