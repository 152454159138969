import { Grow, Tab } from '@mui/material'
import { ErrorPage } from 'components/Errors/ErrorPage'
import { NotFound } from 'components/Errors/NotFound'
import { CustomTab } from 'components/Layout/Tab'
import { TabPanel } from 'components/Layout/TabsPanel'
import { useFarmers } from 'features/officer/farmers/hooks/useFarmers'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useOfficerStore } from 'stores/useOfficerStore'
import { useGetLease } from '../api/getLeased'
import { LeaseCroppingPlans } from '../components/cropping-plan/LeaseCroppingPlan'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Loader
} from '../../../../components/ui'
import { Box } from '../../../../components/App/Layout'
import { FarmInformation } from '../../../farmer/land-management/components/farm-details/FarmInformation'
import { FarmerInformation } from '../components/FarmViewTabs/FarmerInformation'
import { LeasedFarmerInformation } from '../components/leased/LeasedFarmerInformation'
import { useTranslation } from 'react-i18next'

export const LeasedFarmView: React.FC = () => {
  const { officer } = useOfficerStore()
  const { t } = useTranslation('extensionOfficer')
  const [value, setValue] = React.useState(0)
  const { leaseId } = useParams<{ leaseId: string }>()
  const { destinationFarmerId: farmerId } = useParams<{ destinationFarmerId: string }>()
  const {
    isLoading: leaseIsLoading,
    data: lease,
    error
  } = useGetLease(farmerId, officer?.ext_area_id, leaseId)

  const {
    farm,
    farmer: Lessor,
    isLoading
  } = useFarmers(officer?.ext_area_id, lease?.data[0].origin_farmer_id, lease?.data[0].farm_id)

  const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
    setValue(newValue)
  }

  if (isLoading || leaseIsLoading) {
    return (
      <div className={'w-full grid place-items-center h-full flex-1'}>
        <Loader size={36} />
      </div>
    )
  }

  if (error != null) {
    return <NotFound />
  }

  return (
    <Box>
      <Breadcrumb className={'border-b border-border p-4'}>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to={'/officer'}>{t('farm.farmers')}</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink asChild>
              <Link to={`/officer`}>{farmerId}</Link>
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{lease?.data[0].farm_name}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      {farm !== undefined && lease !== undefined && Lessor !== undefined ? (
        <Grow in>
          <div className={'flex flex-col gap-4'}>
            <CustomTab value={value} onChange={handleChange} className={'w-full'}>
              <Tab
                sx={{
                  fontFamily: 'Red Hat Display',
                  textTransform: 'Capitalize',
                  color: 'var(--foreground)'
                }}
                label={t('farmView.info')}
              />
              <Tab
                label={t('farmView.cp')}
                sx={{
                  fontFamily: 'Red Hat Display',
                  textTransform: 'Capitalize',
                  color: 'var(--foreground)'
                }}
              />
            </CustomTab>
            <div className={'w-full flex flex-col justify-center'}>
              <TabPanel index={0} value={value}>
                <FarmInformation farm={farm} />
                <FarmerInformation farmer={Lessor} />
                <LeasedFarmerInformation lease={lease?.data[0]} />
              </TabPanel>
              <TabPanel index={1} value={value}>
                <LeaseCroppingPlans lease={lease?.data[0]} />
              </TabPanel>
            </div>
          </div>
        </Grow>
      ) : (
        <ErrorPage />
      )}
    </Box>
  )
}
