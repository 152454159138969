import type React from 'react'
import { type Lease } from 'types'
import { TabView } from 'components/App/Layout'
import { Input, Text } from 'components/ui'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

interface LeaseInformationProps {
  lease: Lease
}

export const LeaseInformation: React.FC<LeaseInformationProps> = ({ lease }) => {
  const { t } = useTranslation('leaseManagement')
  return (
    <TabView>
      <Text size={'medium'}>{t('leaseForm.lInfo')}</Text>
      <div
        className={'grid grid-cols-[repeat(auto-fit,minmax(240px,1fr))] gap-1 sm:gap-4 list-none'}
      >
        <Input
          withLabel
          label={t('leaseForm.leaseId')}
          value={lease.lease_id}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('leaseForm.start')}
          value={dayjs(lease.commencement_date).format('DD-MM-YYYY')}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('leaseForm.expiry')}
          value={dayjs(
            lease.status_desc === 'RENEWAL ACCEPTED BY LESSOR'
              ? lease.new_termination_date
              : lease.expiry_date
          ).format('DD-MM-YYYY')}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('leaseForm.size')}
          value={lease.leased_size}
          classLabel={'bg-card'}
          disabled
        />
      </div>
    </TabView>
  )
}