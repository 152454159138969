import { object, z } from 'zod'
import type React from 'react'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  RadioGroup,
  RadioGroupItem,
  useStepper
} from 'components/ui'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCroppingPlanStore } from '../../../../stores/useCroppingPlanStore'
import { useCroppingPlanPest } from '../../../../hooks/useCroppingPlanPest'
import { useStaticDataStore } from '../../../../../../../stores/useStaticDataStore'
import { Error } from '../../../../../../../components/Errors/Error'
import { useTranslation } from 'react-i18next'

const getServiceName = (name: string): string => {
  if (name.toLowerCase().includes('drone')) {
    return 'Drone'
  } else if (name.toLowerCase().includes('aeroplane')) {
    return 'Plane'
  } else if (name.toLowerCase().includes('plane')) {
    return 'Plane'
  } else if (name.toLowerCase().includes('boom sprayer')) {
    return 'Boom sprayer'
  } else {
    return 'Boom sprayer'
  }
}

export const pestMethodSchema = object({
  methods: z.coerce.number({
    required_error: 'You need to select an application method'
  })
})

interface ApplicationMethodProps {
  back: () => void
}

export const PestApplicationMethod: React.FC<ApplicationMethodProps> = ({ back }) => {
  const { nextStep } = useStepper()
  const { pest } = useCroppingPlanStore()
  const { services } = useStaticDataStore()
  const { createChemicalPest, updateChemicalPest, isLoading } = useCroppingPlanPest(nextStep)
  const form = useForm<z.infer<typeof pestMethodSchema>>({
    resolver: zodResolver(pestMethodSchema),
    defaultValues: {
      methods: pest != null ? pest.control_services[0]?.service_id : undefined
    }
  })

  const { t } = useTranslation('croppingPlan')

  const onSubmit = (data: z.infer<typeof pestMethodSchema>): void => {
    if (pest == null) {
      createChemicalPest(undefined, data)
    } else {
      updateChemicalPest(undefined, data)
    }
  }

  const pestServices = services?.filter(
    (item) =>
      item.service_type === 'Pest Control -Application Method' &&
      item.is_active &&
      item.service_name !== 'Pest control- Manual'
  )

  const applicationMethods = pestServices?.map((service) => {
    return {
      label: getServiceName(service.service_name),
      value: service.service_details_id,
      headerValue: service.service_header_id
    }
  })

  if (applicationMethods == null || applicationMethods?.length < 1) {
    return <Error />
  }

  return (
    <Form {...form}>
      <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
        <div
          className={
            'flex flex-col p-4 gap-4 w-full sm:max-w-[60%] lg:max-w-[60%]' + ' xl:max-w-[40%]'
          }
        >
          <FormField
            control={form.control}
            name='methods'
            render={({ field }) => (
              <FormItem className='space-y-3'>
                <FormLabel>Application Method</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value?.toString()}
                    className='flex flex-col space-y-1'
                  >
                    {applicationMethods.map((method) => (
                      <FormItem
                        key={method.headerValue}
                        className='flex items-center space-x-3 space-y-0'
                      >
                        <FormControl>
                          <RadioGroupItem value={method.value?.toString()} />
                        </FormControl>
                        <FormLabel className='font-normal'>{method.label}</FormLabel>
                      </FormItem>
                    ))}
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className='w-full flex justify-end gap-2'>
          <Button disabled={isLoading} onClick={back} size={'sm'} variant={'secondary'}>
            {t('footer.prev')}
          </Button>
          <Button
            loading={isLoading}
            disabled={isLoading}
            onClick={form.handleSubmit(onSubmit)}
            size={'sm'}
            variant={'primary'}
          >
            {t('footer.next')}
          </Button>
        </div>
      </form>
    </Form>
  )
}
