import type { ApiResponseWithObject } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import type { AxiosError } from 'axios'
import { type SoilCorrectionRecommendations } from '../types'

export const getSoilCorrectionRecommendation = async (
  farmerId: number | null | undefined,
  ssrId: number | null | undefined,
  farmId?: number | string | null | undefined,
  leaseId?: number | string | null | undefined
): Promise<ApiResponseWithObject<SoilCorrectionRecommendations>> => {
  if (leaseId != null) {
    return axios.get(
      `soilcorrection/recommendation?ssr_id=${ssrId ?? ''}&farmer_id=${farmerId ?? ''}&lease_id=${
        leaseId ?? ''
      }`
    )
  }
  return axios.get(
    `soilcorrection/recommendation?ssr_id=${ssrId ?? ''}&farmer_id=${farmerId ?? ''}&farm_id=${
      farmId ?? ''
    }`
  )
}

export const useGetSoilCorrectionRecommendation = (
  farmerId: number | null | undefined,
  ssrId: number | null | undefined,
  farmId?: number | string | null | undefined,
  leaseId?: number | string | null | undefined
): UseQueryResult<ApiResponseWithObject<SoilCorrectionRecommendations>, AxiosError> => {
  return useQuery({
    queryKey: ['soil-recommendation', ssrId],
    queryFn: async () => getSoilCorrectionRecommendation(farmerId, ssrId, farmId, leaseId),
    enabled:
      (farmerId != null && farmId != null && ssrId != null) ||
      (farmerId != null && leaseId != null && ssrId != null)
  })
}
