/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type React from 'react'
import { useState } from 'react'
import { coerce, discriminatedUnion, literal, object, string, type TypeOf, z } from 'zod'

import { useForm } from 'react-hook-form'
import { useAtom } from 'jotai'
import { individualLoanItem } from '../stores/individualLoanItem'
import dayjs from 'dayjs'
import { PhoneNumberUtil } from 'google-libphonenumber'
import {
  Button,
  Calendar,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Phone,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text,
  useStepper
} from '../../../../../components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '../../../../../components/App/Layout'
import type { LoanStepperProps } from '../../../types'
import { LoanStepperFooter } from '../../generic/LoanStepperFooter'
import { cn } from '../../../../../lib/utils'
import { format } from 'date-fns'
import { CalendarIcon, Info } from 'lucide-react'
import { useTranslation } from 'react-i18next'

const phoneUtil = PhoneNumberUtil.getInstance()

const usePhoneValidation = (phone: string): boolean => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
  } catch (error) {
    return false
  }
}

const base = object({
  firstName: string().min(1, 'Field is required'),
  middleName: string().optional().default(''),
  lastName: string().min(1, 'Field is required'),
  spouse_id: string()
    .min(9, 'Field is required')
    .regex(/[0-9]/, 'Omang should only contain numbers'),
  physicalAddress: string().min(1, 'Field is required'),
  monthlyIncome: string().min(1, 'Field is required'),
  shareholderCompanies: string().min(1, 'Field is required'),
  email: string().min(1, 'Field is required'),
  spouse_employer: string().min(1, 'Field is required'),
  spouse_employment_status: string().min(1, 'Field is required'),
  spouse_job_title: string().min(1, 'Field is required'),
  spouse_employment_start_date: coerce.date({
    required_error: 'Field is required',
    invalid_type_error: 'Invalid Date'
  })
})

const schema = discriminatedUnion('marital_status', [
  object({
    marital_status: z.enum(['single', 'divorced', 'widowed'])
  }).merge(
    object({
      firstName: string().default(''),
      middleName: string().default(''),
      lastName: string().default(''),
      spouse_employment_status: string().default('Permanent'),
      spouse_id: string().default(''),
      physicalAddress: string().default(''),
      monthlyIncome: string().default(''),
      shareholderCompanies: string().default(''),
      email: string().default('tear@moa.bw'),
      spouse_employer: string().default(''),
      spouse_job_title: string().default(''),
      spouse_employment_start_date: coerce
        .date({
          required_error: 'Field is required',
          invalid_type_error: 'Invalid Date'
        })
        .optional()
    })
  ),
  object({
    marital_status: literal('married')
  }).merge(base)
])

type MaritalInformationDetailsInput = TypeOf<typeof schema>

export const MaritalInformation: React.FC<LoanStepperProps> = ({ onComplete }) => {
  const { nextStep } = useStepper()
  const [, setLoanData] = useAtom(individualLoanItem)
  const [loanData] = useAtom(individualLoanItem)
  const [defaultPhoneNumber] = useState<string>(
    loanData?.loan_spouse?.spouse_phone !== undefined ? loanData?.loan_spouse?.spouse_phone : ''
  )
  const [numbers, setNumbers] = useState<string>(defaultPhoneNumber)
  const isPhoneValid = usePhoneValidation(numbers)
  const { t } = useTranslation('loanManagement')

  const form = useForm<MaritalInformationDetailsInput>({
    resolver: zodResolver(schema),
    defaultValues: {
      marital_status: loanData?.loan_spouse?.marital_status as
        | 'single'
        | 'divorced'
        | 'widowed'
        | 'married'
        | undefined,
      middleName: loanData?.loan_spouse?.spouse_mid_name,
      spouse_id: loanData?.loan_spouse?.spouse_id?.toString(),
      firstName: loanData?.loan_spouse?.spouse_first_name,
      lastName: loanData?.loan_spouse?.spouse_last_name,
      physicalAddress: loanData?.loan_spouse?.spouse_physical_address,
      spouse_employer: loanData?.loan_spouse?.spouse_employer,
      spouse_employment_status: loanData?.loan_spouse?.spouse_employment_status,
      shareholderCompanies: loanData?.loan_spouse?.spouse_shareholding_value,
      email: loanData?.loan_spouse?.spouse_email,
      spouse_job_title: loanData?.loan_spouse?.spouse_job_title,
      monthlyIncome: loanData?.loan_spouse?.spouse_income.toString(),
      spouse_employment_start_date:
        dayjs(loanData?.loan_spouse?.spouse_employment_start_date).toDate() ?? undefined
    }
  })

  const onSubmit = (data: MaritalInformationDetailsInput): void => {
    setLoanData((prev) => ({
      ...prev!,
      loan_spouse: {
        marital_status: data.marital_status,
        spouse_mid_name: data.middleName ?? '',
        spouse_id: data.spouse_id as unknown as number,
        spouse_first_name: data.firstName,
        spouse_last_name: data.lastName,
        spouse_physical_address: data.physicalAddress,
        spouse_employment_status: data.spouse_employment_status,
        spouse_shareholding_value: data.shareholderCompanies,
        spouse_employer: data.spouse_employer,
        spouse_job_title: data.spouse_job_title,
        spouse_income: data.monthlyIncome as unknown as number,
        spouse_phone: numbers,
        spouse_email: data.email,
        spouse_employment_start_date: data.spouse_employment_start_date
      }
    }))
    nextStep()
    if (onComplete != null) {
      onComplete(true)
    }
  }

  return (
    <Box>
      <Form {...form}>
        <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
          <div className='flex flex-col space-y-8 my-6 w-full'>
            <FormLabel className={'font-redHat'}>
              <FormLabel className={'font-redHat'}>
                <div className='flex flex-col gap-3'>
                  <Text size='medium' className=''>
                    {t('marital.info')}
                  </Text>
                  <div className='flex items-center'>
                    <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
                    <Text variant='secondary' size='small' className=''>
                      Pick a status and if you are married kindly provide the full details of your
                      marital status, include your spouse's name, address, and contact information.
                    </Text>
                  </div>
                </div>
              </FormLabel>
            </FormLabel>

            <div className='grid grid-cols-1 gap-4 lg:grid-cols-3 mb-3'>
              <FormField
                control={form.control}
                name='marital_status'
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel className='font-redHat'>
                      <Text size='body' className=''>
                        {t('marital.status')}
                      </Text>
                    </FormLabel>
                    <Select
                      onValueChange={(val) => {
                        field.onChange(val)
                      }}
                      {...field}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder={`${t('marital.status')}`} />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value={'married'}>
                          {t('marital.married')} &nbsp;
                          <Text size='small' variant='primaryLight'>
                            ({t('marital.more')})
                          </Text>
                        </SelectItem>
                        <SelectItem value={'single'}>{t('marital.single')}</SelectItem>
                        <SelectItem value={'divorced'}>{t('marital.div')}</SelectItem>
                        <SelectItem value={'widowed'}>{t('marital.wid')}</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {form.watch('marital_status') === 'married' && (
              <>
                <Text size='bodyBold' className=''>
                  {t('marital.part')}
                </Text>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
                  <FormField
                    control={form.control}
                    name={'firstName'}
                    render={({ field }) => (
                      <FormItem className='flex flex-col'>
                        <FormLabel className={'font-redHat'}>
                          <Text size='body' className=''>
                            {t('marital.sFirst')}
                          </Text>
                        </FormLabel>
                        <FormControl>
                          <Input
                            classLabel={'bg-background-dark sm:bg-card'}
                            placeholder={`${t('marital.sFirstPrompt')}`}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={'middleName'}
                    render={({ field }) => (
                      <FormItem className='flex flex-col'>
                        <FormLabel className={'font-redHat'}>
                          <Text size='body' className=''>
                            {t('marital.sMiddle')}
                          </Text>
                        </FormLabel>
                        <FormControl>
                          <Input
                            classLabel={'bg-background-dark sm:bg-card'}
                            placeholder={`${t('marital.sMiddlePrompt')}`}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={'lastName'}
                    render={({ field }) => (
                      <FormItem className='flex flex-col'>
                        <FormLabel className={'font-redHat'}>
                          <Text size='body' className=''>
                            {t('marital.sLast')}
                          </Text>
                        </FormLabel>
                        <FormControl>
                          <Input
                            classLabel={'bg-background-dark sm:bg-card'}
                            placeholder={`${t('marital.sLast')}`}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <div className='pt-4'>
                    <FormField
                      control={form.control}
                      name={'spouse_id'}
                      render={({ field }) => (
                        <FormItem className='flex flex-col'>
                          <FormLabel className={'font-redHat'}>
                            <Text size='body' className=''>
                              {t('marital.sOmang')}
                            </Text>
                          </FormLabel>
                          <FormControl>
                            <Input
                              classLabel={'bg-background-dark sm:bg-card'}
                              placeholder={`${t('marital.sOmangPrompt')}`}
                              {...field}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div className='pt-4'>
                    <FormField
                      control={form.control}
                      name={'email'}
                      render={({ field }) => (
                        <FormItem className='flex flex-col'>
                          <FormLabel className={'font-redHat'}>
                            <Text size='body' className=''>
                              {t('marital.sEmail')}
                            </Text>
                          </FormLabel>
                          <FormControl>
                            <Input
                              classLabel={'bg-background-dark sm:bg-card'}
                              placeholder={`${t('marital.sEmailPrompt')}`}
                              {...field}
                              type={'email'}
                              defaultValue={loanData?.loan_spouse?.spouse_email}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className='pt-2'>
                    <FormItem className='flex flex-col'>
                      <FormLabel className='font-redHat'>
                        <Text size='body' className=''>
                          {t('marital.sPhone')}
                        </Text>
                      </FormLabel>
                      <Phone
                        onChange={(num) => {
                          setNumbers(num)
                        }}
                        value={numbers}
                      />
                      <FormMessage />
                    </FormItem>
                  </div>
                  <FormField
                    control={form.control}
                    name='spouse_employment_status'
                    render={({ field }) => (
                      <FormItem className='flex flex-col'>
                        <FormLabel className={'font-redHat'}>
                          <Text size='body' className=''>
                            {t('marital.sStatus')}
                          </Text>
                        </FormLabel>
                        <Select
                          onValueChange={(val) => {
                            field.onChange(val)
                          }}
                          {...field}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder={`${t('marital.sStatusPrompt')}`} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectItem value='Contract'>{t('marital.contract')}</SelectItem>
                            <SelectItem value='Permanent'>{t('marital.perm')}</SelectItem>
                            <SelectItem value='Self Employed'>{t('marital.self')}</SelectItem>
                            <SelectItem value='Pensioner'>{t('marital.pension')}</SelectItem>
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={'spouse_employer'}
                    render={({ field }) => (
                      <FormItem className='flex flex-col'>
                        <FormLabel className={'font-redHat'}>
                          <Text size='body' className=''>
                            {t('marital.sEmp')}
                          </Text>
                        </FormLabel>
                        <FormControl>
                          <Input
                            classLabel={'bg-background-dark sm:bg-card'}
                            placeholder={`${t('marital.sEmpPrompt')}`}
                            {...field}
                            defaultValue={loanData?.loan_spouse?.spouse_employer}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name={'spouse_job_title'}
                    render={({ field }) => (
                      <FormItem className='flex flex-col'>
                        <FormLabel className={'font-redHat'}>
                          <Text size='body' className=''>
                            {t('marital.job')}
                          </Text>
                        </FormLabel>
                        <FormControl>
                          <Input
                            classLabel={'bg-background-dark sm:bg-card'}
                            placeholder={`${t('marital.jobPrompt')}`}
                            {...field}
                            defaultValue={loanData?.loan_spouse?.spouse_job_title}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className='pt-4'>
                    <FormField
                      control={form.control}
                      name='spouse_employment_start_date'
                      render={({ field }) => (
                        <FormItem className='flex flex-col'>
                          <FormLabel>{t('marital.start')}</FormLabel>
                          <Popover>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant={'outlined'}
                                  className={cn(
                                    'text-left font-normal',
                                    field.value != null && 'text-muted-foreground'
                                  )}
                                >
                                  {field.value != null ? (
                                    format(field.value, 'PPP')
                                  ) : (
                                    <span>{t('marital.date')}</span>
                                  )}
                                  <CalendarIcon className='ml-auto h-4 w-4 opacity-50' />
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent className='w-auto p-0' align='start'>
                              <Calendar
                                mode='single'
                                captionLayout={'dropdown-buttons'}
                                toYear={dayjs(new Date(Date.now())).toDate().getFullYear() + 1}
                                fromYear={dayjs(new Date(Date.now())).toDate().getFullYear() - 120}
                                selected={field.value}
                                onSelect={field.onChange}
                                disabled={(date) => new Date(Date.now()) <= date}
                                initialFocus
                              />
                            </PopoverContent>
                          </Popover>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className='pt-4'>
                    <FormField
                      control={form.control}
                      name={'monthlyIncome'}
                      render={({ field }) => (
                        <FormItem className='flex flex-col'>
                          <FormLabel className='font-redHat'>
                            <Text size='body' className=''>
                              {t('marital.monthly')} (BWP)
                            </Text>
                          </FormLabel>
                          <FormControl>
                            {/* <Input */}
                            {/*   classLabel={'bg-background-dark sm:bg-card'} */}
                            {/*   placeholder={'Monthly Income (BWP)'} */}
                            {/*   {...field} */}
                            {/*   type={'number'} */}
                            {/*   defaultValue={loanData?.loan_spouse?.spouse_income.toString()} */}
                            {/* /> */}

                            <div className='relative'>
                              <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                <Text size='bodyBold' className=''>
                                  BWP
                                </Text>
                              </div>
                              <Input
                                id='currency'
                                inputMode='decimal'
                                type='number'
                                min={0}
                                step={0.01}
                                placeholder='0.00'
                                className='pl-14'
                                {...field}
                                defaultValue={loanData?.loan_spouse?.spouse_income.toString()}
                              />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <div className='pt-4'>
                    <FormField
                      control={form.control}
                      name={'shareholderCompanies'}
                      render={({ field }) => (
                        <FormItem className='flex flex-col'>
                          <FormLabel className='font-redHat'>
                            <Text size='body' className=''>
                              {t('marital.share')}(BWP)
                            </Text>
                          </FormLabel>
                          <FormControl>
                            <div className='relative'>
                              <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                                <Text size='bodyBold' className=''>
                                  BWP
                                </Text>
                              </div>
                              <Input
                                id='currency'
                                inputMode='decimal'
                                type='number'
                                min={0}
                                step={0.01}
                                placeholder='0.00'
                                className='pl-14'
                                {...field}
                                defaultValue={loanData?.loan_spouse?.spouse_shareholding_value}
                              />
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>

                  <FormField
                    control={form.control}
                    name={'physicalAddress'}
                    render={({ field }) => (
                      <FormItem className='flex flex-col'>
                        <FormLabel className='font-redHat'>
                          <Text size='body' className=''>
                            {t('marital.physical')}
                          </Text>
                        </FormLabel>
                        <FormControl>
                          <Input
                            classLabel={'bg-background-dark sm:bg-card'}
                            placeholder={`${t('marital.physPrompt')}`}
                            {...field}
                            defaultValue={loanData?.loan_spouse?.spouse_physical_address}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </>
            )}
          </div>
          {/* </div> */}
          <LoanStepperFooter />
        </form>
      </Form>
    </Box>
  )
}