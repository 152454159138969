import { Tag } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { AxiosError, type AxiosResponse } from 'axios'
import { getLISDetails } from 'features/farmer/land-management/api/getLISDetails'
import { getVillageArea } from 'features/farmer/land-management/api/getVillageArea'
import { apiLocationAtom } from 'features/farmer/land-management/stores/apiLocationInfo'
import { newFarmAtom } from 'features/farmer/land-management/stores/newFarm'
import { useSetAtom } from 'jotai'
import type React from 'react'
import { type SubmitHandler } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useFarmerStore } from 'stores/useFarmerStore'
import { type ApiResponse } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { object, string, type TypeOf } from 'zod'
import { BasicButton } from '../../../../../../components/Elements/Button'
import { Form, InputField } from '../../../../../../components/Form'
import { useTranslation } from 'react-i18next'

interface FarmDeedNumberProps {
  className?: string
  onNext: () => void
  onBack: () => void
}

const schema = object({
  DEED_NUMBER: string()
    .nonempty('Title Deed is required')
    .refine((value) => /^DR\w{9}$/.test(value), {
      message: 'DEED_NUMBER should start with DR and have 9 following characters'
    }),
  ID: string().nonempty('ID is required').length(9, 'ID must be exactly 9 characters')
})

type LISInput = TypeOf<typeof schema>

interface LISParams {
  deedNumber: string
  id: string
}

interface LocationArea {
  village: string
}

// TODO: Include omang from Farmer prop
export const FarmDeedNumber: React.FC<FarmDeedNumberProps> = ({ className, onNext, onBack }) => {
  const setFarmDetails = useSetAtom(newFarmAtom)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { farmer } = useFarmerStore()
  const farmerOmang = farmer!.omang
  const setLocationDetails = useSetAtom(apiLocationAtom)
  const { t } = useTranslation('landManagement')

  const mutation = useMutation({
    mutationFn: async (params: LISParams) => {
      return getLISDetails(params.deedNumber, params.id)
    }
  })

  const locationMutation = useMutation({
    mutationFn: async (params: LocationArea) => {
      return getVillageArea(params.village)
    }
  })
  const onSubmit: SubmitHandler<LISInput> = (data: LISInput): void => {
    mutation.mutate(
      {
        deedNumber: data.DEED_NUMBER,
        id: data.ID
      },
      {
        onSuccess: (r) => {
          if (r.status === 200 && r.success) {
            setFarmDetails((prev) => {
              return {
                ...prev,
                plotNo: r.data[0]?.PLOT_NUMBER,
                districtId: r.data[0]?.DISTRICT_ID,
                subDistrictId: r.data[0]?.SUB_DISTRICT_ID,
                extensionAreaId: r.data[0]?.EXT_AREA_ID,
                villageId: r.data[0]?.VILLAGE_ID,
                size: r.data[0]?.FARM_SIZE
              }
            })
            locationMutation.mutate(
              {
                village: r.data[0]?.VILLAGE_NAME
              },
              {
                onSuccess: (v) => {
                  if (v.status === 200 && v.success) {
                    // console.log(v.data)
                    setLocationDetails((prev) => {
                      const updatedLocationDetails = v.data?.map((area) => ({
                        villageId: area.village_id,
                        villageName: area.village_name,
                        extensionAreaId: area.extension_area_id,
                        extensionAreaName: area.extension_area_name,
                        subDistrictId: area.sub_district_id,
                        subDistrictName: area.sub_district_name,
                        districtId: area.district_id,
                        districtName: area.district_name
                      }))

                      return updatedLocationDetails ?? prev
                    })

                    onNext()
                  }
                },

                onError: (err) => {
                  if (err instanceof AxiosError) {
                    const error = err?.response as AxiosResponse<ApiResponse<[]>>
                    toast.error(mapCmsErrorToMessage(error.data.message))
                  } else {
                    const error = err as Error
                    toast.error(mapCmsErrorToMessage(error.message))
                  }
                }
              }
            )
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err?.response as AxiosResponse<ApiResponse<[]>>
            toast.error(mapCmsErrorToMessage(error.data.message))
          } else {
            const error = err as Error
            toast.error(mapCmsErrorToMessage(error.message))
          }
        }
      }
    )
  }
  return (
    <Form<LISInput, typeof schema> className={className} onSubmit={onSubmit} schema={schema}>
      {({ register, formState: { errors }, handleSubmit }) => (
        <>
          <div className={'flex flex-col gap-3'}>
            <Typography>{t('deed.enter')} </Typography>
            <InputField
              label={`${t('deed.deed')}`}
              register={register('DEED_NUMBER')}
              icon={<Tag color={'primary'} />}
              error={!(errors.DEED_NUMBER == null)}
              helperText={errors?.DEED_NUMBER?.message ?? ''}
            />
            <InputField
              label={`${t('deed.omang')}`}
              register={register('ID', { value: farmerOmang })}
              icon={<Tag color={'primary'} />}
              error={!(errors.ID == null)}
              helperText={errors?.ID?.message ?? ''}
              disabled
            />
          </div>
          <div className={'flex flex-row justify-between gap-8'}>
            <BasicButton
              label={`${t('deed.back')}`}
              variant={'secondary'}
              onClick={onBack}
              isDisabled={mutation.isLoading}
              size={'xs'}
            />
            <BasicButton
              label={`${t('deed.next')}`}
              variant={'primary'}
              onClick={handleSubmit(onSubmit)} // handleSubmit(onSubmit)
              size={'xs'}
              isDisabled={mutation.isLoading}
              isLoading={mutation.isLoading}
            />
          </div>
        </>
      )}
    </Form>
  )
}