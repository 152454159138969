interface MmokoInfoPoint {
  title: string
  description: string
}

interface MmokoInfo {
  MmokoInfoPoints: MmokoInfoPoint[]
}

const mmokoInfo = (t: (key: string) => string): MmokoInfo => {
  return {
    MmokoInfoPoints: [
      {
        title: t('mrp.mrp21t'),
        description: t('mrp.mrp21d')
      },
      {
        title: t('mrp.mrp22t'),
        description: t('mrp.mrp22d')
      }
    ]
  }
}

export default mmokoInfo