import { locations } from 'features/farmer/land-management/utils/locations'
import type React from 'react'
import { type Farm } from 'types'
import { Button, Input, Text } from 'components/ui'
import { useTranslation } from 'react-i18next'

interface FarmInformationProps {
  farm: Farm
  onReject: () => void
  onApprove: () => void
}

export const FarmInformation: React.FC<FarmInformationProps> = ({ farm, onApprove, onReject }) => {
  const { t } = useTranslation('extensionOfficer')
  const location = locations.filter((loc) => {
    return (
      loc.extension_area_id === farm.ext_area_id &&
      loc.village_id === farm.village_id &&
      loc.district_id === farm.district_id &&
      loc.sub_district_id === farm.sub_district_id
    )
  })

  return (
    <div className={'w-full flex flex-col gap-4 md:gap-8'}>
      <Text size={'medium'}>{t('land.rev')}</Text>
      <div className={'grid grid-cols-1 md:grid-cols-2 gap-4'}>
        <Input
          withLabel
          label={t('land.name')}
          value={farm.farm_name}
          classLabel={'bg-primary'}
          disabled
        />
        <Input
          withLabel
          label={t('land.ha')}
          value={farm.size.toFixed(4)}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('land.dis')}
          value={location[0].district_name}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('land.sub')}
          value={location[0].sub_district_name}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('land.ext')}
          value={farm.extension_area_name}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('land.vil')}
          value={farm.village_name}
          classLabel={'bg-card'}
          disabled
        />
      </div>
      {/* Buttons change direction based on device type */}
      <div className={'flex flex-col sm:flex-row justify-between gap-8'}>
        <Button variant={'destructive'} onClick={onReject} size={'lg'} color={'error'}>
          {t('land.rej')}
        </Button>
        <Button variant={'success'} onClick={onApprove} size={'lg'} color={'success'}>
          {t('land.app')}
        </Button>
      </div>
    </div>
  )
}