import type React from 'react'
import { useEffect, useState } from 'react'
import { Button, Text } from 'components/ui'
import { type GetInventoryRes } from '../../types'
import { useTransferInventory } from '../../hooks/useTransferInventory'
import { useTransferStore } from '../../stores/transferStore'
import { type Farmer, type Merchant } from '../../../../../types'
import { useMerchantStore } from '../../../../../stores/useMerchantStore'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'

interface TransferConfirmationProps {
  inventoryData: GetInventoryRes
  next: () => void
  back: () => void
}

export const TransferConfirmation: React.FC<TransferConfirmationProps> = ({
  inventoryData,
  next,
  back
}) => {
  const { userType, userDetails } = useTransferStore()
  const [fullname, setFullname] = useState<string>()

  const { merchant } = useMerchantStore()
  const { farmer } = useFarmerStore()

  const { initiateNewTransfer, isLoading } = useTransferInventory()
  const destMerchant = userDetails as Merchant
  const destFarmer = userDetails as Farmer

  useEffect(() => {
    if (userType === 'merchant' && userDetails != null) {
      setFullname(`${destMerchant?.merchant_name?.trim().toUpperCase()}`)
    } else if (userType === 'farmer' && userDetails != null) {
      setFullname(
        destFarmer.farmer_type === 2
          ? destFarmer?.company_name ?? ''
          : `${destFarmer?.firstname?.trim().toUpperCase()} ${destFarmer?.surname
              ?.trim()
              .toUpperCase()}`
      )
    }
  }, [userType, userDetails, destMerchant, destFarmer])

  const onSubmit = (): void => {
    const transferData = {
      inventory_id: Number(inventoryData.inventory_id),
      origin_farmer_id: userType === 'farmer' && farmer != null ? farmer?.farmer_id : undefined,
      dest_farmer_id: userType === 'farmer' ? destFarmer?.farmer_id : undefined,
      origin_merchant_id:
        userType === 'merchant' && merchant != null ? merchant?.merchant_id : undefined,
      dest_merchant_id: userType === 'merchant' ? destMerchant?.merchant_id : undefined,
      transaction_status: 1,
      last_update_by: userType === 'merchant' ? merchant?.user_id : farmer?.user_id,
      message: 'Transfer Initiated'
    }

    initiateNewTransfer(transferData, next)
  }

  return (
    <div className='mt-4 w-full flex flex-col gap-4 md:gap-8'>
      <div className='flex flex-col gap-4 items-center justify-center text-center'>
        <Text>Is this the person you want to transfer your inventory to?</Text>
        <Text size={'medium'} variant={'primary'}>
          {fullname}
        </Text>
      </div>
      <div className='w-full flex justify-between gap-4'>
        <Button variant='secondary' onClick={back} className='w-1/2'>
          Back
        </Button>
        <Button
          type='button'
          variant='primary'
          onClick={onSubmit}
          className='w-1/2'
          loading={isLoading}
          disabled={isLoading}
        >
          Transfer
        </Button>
      </div>
    </div>
  )
}
