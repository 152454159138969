import type React from 'react'
import { type Lease } from 'types'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from 'components/ui'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

interface LeaseTableProps {
  leases: Lease[]
  onClick: (item: Lease) => void
}

export const LeaseTable: React.FC<LeaseTableProps> = ({ leases, onClick }) => {
  const { t } = useTranslation('extensionOfficer')

  return (
    <Table>
      <TableCaption>{t('land.lolp')}</TableCaption>
      <TableHeader>
        <TableRow>
          <TableHead className='font-bold text-color-text'>{t('land.lid')}</TableHead>
          <TableHead className='font-bold text-color-text'>{t('land.leas')}</TableHead>
          <TableHead className='font-bold text-color-text'>{t('land.less')}</TableHead>
          <TableHead className='font-bold text-color-text'>{t('land.vil')}</TableHead>
          <TableHead className='font-bold text-color-text'>{t('land.ha')}</TableHead>
          <TableHead className='font-bold text-color-text'>{t('land.lsa')}</TableHead>
          <TableHead className='font-bold text-color-text'>{t('land.led')}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {leases.map((lease) => (
          <TableRow
            onClick={() => {
              onClick(lease)
            }}
            key={lease.lease_id}
            role={'listitem'}
            className={'hover:cursor-pointer hover:bg-muted-hover'}
          >
            <TableCell className='font-medium'>{lease.dest_farmer_id}</TableCell>
            <TableCell className='font-medium'>
              {lease.dest_farmer_type === 2
                ? lease.dest_farmer_company_name
                : lease.dest_farmer_name}
            </TableCell>
            <TableCell className='font-medium'>
              {lease.origin_farmer_type === 2
                ? lease.origin_farmer_company_name
                : lease.origin_farmer_name}
            </TableCell>
            <TableCell className='font-medium'>{lease.village}</TableCell>
            <TableCell className='font-medium'>{lease.leased_size}</TableCell>
            <TableCell className='font-medium'>
              {dayjs(lease.commencement_date).format('DD-MM-YYYY')}
            </TableCell>
            <TableCell className='font-medium'>
              {lease.new_termination_date != null
                ? dayjs(lease.new_termination_date).format('DD-MM-YYYY')
                : dayjs(lease.expiry_date).format('DD-MM-YYYY')}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
