import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'
import { type GetInventoryRes } from '../../../farmer/inventory/types'

export const getLabInventoryItem = async (
  idType: string | null | undefined,
  idInput: string | null | undefined,
  districtId: number | null | undefined
): Promise<ApiResponse<GetInventoryRes>> => {
  const queryParams =
    idType === 'merchantId' ? `merchant_id=${idInput ?? ''}` : `farmer_id=${idInput ?? ''}`

  return axios.get(`inventory?${queryParams ?? ''}&district_id=${districtId ?? ''}`)
}
