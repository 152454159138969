import type React from 'react'
import { useState } from 'react'
import { type Farm } from 'types'
import { AcceptFarm } from './AcceptFarm'
import { FarmTable } from './results/FarmTable'
import { Dialog, DialogContent, Text } from 'components/ui'
import { useTranslation } from 'react-i18next'

interface FarmResultsProps {
  farms: Farm[]
  className: string
  invalidateSearch: () => void
}

export const FarmResults: React.FC<FarmResultsProps> = ({ farms, className, invalidateSearch }) => {
  const [open, setOpen] = useState(false)
  const [clickedData, setClickedData] = useState<Farm[]>([])
  const { t } = useTranslation('extensionOfficer')

  const openDialog = (): void => {
    setOpen(true)
  }

  const closeDialog = (): void => {
    setOpen(false)
  }

  const onClickFarm = (item: Farm): void => {
    setClickedData((prevClickedData) => [...prevClickedData, item])
    openDialog()
  }

  const handleAcceptFarm = (farmId: number): void => {
    invalidateSearch()
  }

  return (
    <div className={className}>
      <Text size={'medium'} variant={'primary'} className={'ml-4'}>
        {t('cp.sr')}: {farms.length}
      </Text>
      <div className={'bg-background w-full flex flex-col gap-2 sm:gap-4 p-4'}>
        <FarmTable farms={farms} onClick={onClickFarm} />
      </div>

      <Dialog open={open} defaultOpen={false} modal onOpenChange={closeDialog}>
        <DialogContent className='bg-card w-11/12 sm:max-w-md'>
          <AcceptFarm
            farm={clickedData?.[0]}
            className={'flex flex-col gap-4'}
            onClose={closeDialog}
            onStatusChange={handleAcceptFarm}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}