import { type FC } from 'react'
import { type Farm } from 'types'
import { FarmInformation } from '../farm-details/FarmInformation'
import { TabView } from '../../../../../components/App/Layout'
import { Text } from '../../../../../components/ui'
import { TransferFarm } from '../farm-edits/farm-edits-actions/TransferFarm'
import { SubdivideFarm } from '../farm-edits/farm-edits-actions/SubdivideFarm'
import { UpdateHectarage } from '../farm-edits/farm-edits-actions/UpdateHectarage'
import { useTranslation } from 'react-i18next'

interface InformationProps {
  farm: Farm
}

// eslint-disable-next-line @typescript-eslint/no-shadow
export const Information: FC<InformationProps> = ({ farm }) => {
  const { t } = useTranslation('landManagement')
  return (
    <div className={'w-full flex flex-col gap-4 md:gap-8'}>
      <FarmInformation farm={farm} />
      <TabView className='mt-4'>
        <div className='flex justify-between items-center'>
          <Text size='medium'>{t('ownership.changes')}</Text>
        </div>
        <TransferFarm farm={farm} />
        <SubdivideFarm farm={farm} />
        <UpdateHectarage farm={farm} />
      </TabView>
    </div>
  )
}
