import type React from 'react'
import TreeIcon from '../../../../../../assets/landing/icons/tree.svg'
import tractorandImplementsEnhancer from '../Data/Enhancers/Tractorand ImplementsData'

interface Detail {
  title: string
  description: string
}

interface EligibilityCriteria {
  description: string
}

interface TractorandImplementsEnhancer {
  detail: Detail[]
  eligibilityCriteria: EligibilityCriteria[]
}

export const TractorandImplements: React.FC = () => {
  const { detail, eligibilityCriteria } =
    tractorandImplementsEnhancer as TractorandImplementsEnhancer

  return (
    <div className='w-full mt-16'>
      {/* Details */}
      <div className='flex flex-col justify-center items-start my-8'>
        <h2 className='text-base md:text-xl lg:text-3xl font-bold text-gray-700 landing-page '>
          Subsidy Details
        </h2>
        <ul className='mt-4'>
          {detail.map((item: Detail, index: number) => (
            <li key={index} className='text-base md:text-xl lg:text-2xl text-gray-600 my-6'>
              <div className='flex flex-row items-start'>
                <img src={TreeIcon} alt='Tree Icon' className='w-6 h-6 mr-5' />
                <div className='flex flex-col'>
                  <h4 className='text-base md:text-xl lg:text-2xl font-bold text-gray-700 mb-1 landing-page'>
                    {item.title}
                  </h4>
                  <p className='text-base md:text-xl lg:text-2xl text-gray-600'>
                    {item.description}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {/* Eligibility Criteria */}
      <div className='flex flex-col justify-center items-start my-8'>
        <h2 className='text-base md:text-xl lg:text-3xl font-bold text-gray-700 landing-page '>
          Eligibility Criteria
        </h2>
        <ul className='mt-4'>
          {eligibilityCriteria.map((item: EligibilityCriteria, index: number) => (
            <li key={index} className='text-base md:text-xl lg:text-2xl text-gray-600 my-6'>
              <div className='flex flex-row items-start'>
                <img src={TreeIcon} alt='Tree Icon' className='w-6 h-6 mr-5' />
                <div className='flex flex-col'>
                  <p className='text-base md:text-xl lg:text-2xl text-gray-600'>
                    {item.description}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
