import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'
import { type GetInventoryRes } from '../../../farmer/inventory/types'

export const getEOInventories = async (
  extId: number | null | undefined
): Promise<ApiResponse<GetInventoryRes>> => {
  return axios.get(`/inventory?ext_area_id=${extId ?? ''}`)
}

export const useGetEOInventories = (
  extId: number | null | undefined
): UseQueryResult<ApiResponse<GetInventoryRes>, AxiosError<[]>> => {
  return useQuery({
    queryKey: ['eo-inventories'],
    queryFn: async () => getEOInventories(extId),
    enabled: extId != null
  })
}
