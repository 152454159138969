import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface CreatePackagingDTO {
  cp_id: number | null | undefined
  method: number
  user_id: number | null | undefined
  farmer_id: number | null | undefined
}

export const createPackaging = async (params: CreatePackagingDTO): Promise<ApiResponse<[]>> => {
  return axios.post('/production/packaging', params)
}
