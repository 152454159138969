import { cn } from "lib/utils";
import { LoaderIcon } from "lucide-react";

const Spinner = ({ className }: { className?: string }) => {
  return (
    <LoaderIcon
      className={cn('my-28 h-16 w-16 text-primary animate-[spin_2s_linear_infinite]', className)}
    />
  );
};

export default Spinner;

