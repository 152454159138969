import type React from 'react'
import { useState } from 'react'
import { type CropData, crops } from './Data/Yields/TargetYield'
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '../../../../../components/ui'
import { useTranslation } from 'react-i18next'

const CropCards: React.FC = () => {
  const { t } = useTranslation('landing')
  const [selectedCrop, setSelectedCrop] = useState<CropData | null>(null)

  return (
    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
      {crops(t).map((crop) => (
        <Card
          key={crop.name}
          className='cursor-pointer hover:shadow-lg transition-shadow relative overflow-hidden w-80 rounded-xl'
          onClick={() => {
            setSelectedCrop(crop)
          }}
        >
          <CardContent className='p-0 aspect-video'>
            <div
              className='absolute inset-0 bg-cover bg-center w-80'
              style={{ backgroundImage: `url(${crop.image})` }}
            />
            <div className='absolute inset-0 bg-black bg-opacity-50 flex space-y-12 flex-col justify-center items-center text-white p-4'>
              <h3 className='text-2xl md:text-3xl lg:text-4xl text-white font-bold landing-page'>
                {crop.name}
              </h3>
              <p className='text-xs lg:text-sm text-white font-light landing-page'>
                {t('home.click')}
              </p>
            </div>
          </CardContent>
        </Card>
      ))}

      <Dialog
        open={selectedCrop !== null}
        onOpenChange={() => {
          setSelectedCrop(null)
        }}
      >
        <DialogContent className='w-full max-w-xs md:max-w-2xl lg:max-w-6xl p-4 md:p-8 lg:p-12 bg-white'>
          <DialogHeader className='flex items-center text-center'>
            <DialogTitle>
              <h3 className='text-xl md:text-3xl lg:text-4xl font-bold text-gray-600 landing-page'>
                {selectedCrop?.name}
              </h3>
            </DialogTitle>
          </DialogHeader>
          {selectedCrop && (
            <Table className='border border-gray-300 p-4 landing-page rounded-lg'>
              <TableHeader>
                <TableRow>
                  {selectedCrop.tableData.headers.map((header) => (
                    <TableHead key={header} className='bg-blue-400'>
                      <h4 className='text-sm md:text-lg lg:text-xl font-semibold text-white landing-page'>
                        {header}
                      </h4>
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {selectedCrop.tableData.rows.map((row, index) => (
                  <TableRow key={index} className='hover:bg-gray-300 cursor-pointer'>
                    {row.map((cell, cellIndex) => (
                      <TableCell key={cellIndex}>
                        <p className='text-sm md:text-base lg:text-base font-regular text-gray-600 landing-page'>
                          {cell}
                        </p>
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default CropCards