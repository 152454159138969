interface StepsLabelProps {
  label: string
  value: string
  variant?: 'default' | 'secondary'
  isCurrency?: boolean
}

export const FieldValue: React.FC<StepsLabelProps> = ({ label, value, isCurrency }) => {
  const formattedValue =
    isCurrency === true
      ? parseFloat(value).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })
      : value

  return (
    <div className='flex flex-col space-y-2 m-4'>
      <p className='text-[color:var(--color-secondary-light)] text-xs font-medium capitalize'>
        {label}
      </p>
      <p className='text-[color:var(--color-grey)] text-md font-medium capitalize'>
        {isCurrency ?? false ? `BWP ${formattedValue}` : formattedValue}
      </p>
    </div>
  )
}
