import { type ChemicalDealerCroppingPlanData } from '../../../types'
import {
  type Adjuvants,
  type Fungicides,
  type Herbicides,
  type Pesticides
} from '../../../types/static'
import { type FilteredChemicals } from '../chemical-dealer/types'

export const filteredChemicals = (
  crops: ChemicalDealerCroppingPlanData,
  pesticides: Pesticides[] | null,
  fungicides: Fungicides[] | null,
  herbicides: Herbicides[] | null,
  adjuvants: Adjuvants[] | null
): FilteredChemicals => {
  const filteredPesticides = pesticides?.filter(
    (pesticide) =>
      pesticide.crop_id === crops.crop_id &&
      crops.chemicals.some((chemical) => chemical.pest_id === pesticide.pest_id)
  )
  // const filteredFungicides = fungicides?.filter(
  //   (fungicide) => fungicide.crop_id === crops.crop_id
  // )
  const filteredHerbicides = herbicides?.filter(
    (herbicide) =>
      herbicide.crop_id === crops.crop_id &&
      crops.chemicals.some(
        (chemical) =>
          chemical.chemical_schedule === herbicide.application_stage &&
          chemical.weed_id === herbicide.weed_id
      )
  )

  const filteredAdjuvants = adjuvants?.filter((adjuvant) =>
    crops.chemicals.some(
      (chemical) => chemical.adjuvant_price_detail_id === adjuvant.adjuvant_price_detail_id
    )
  )

  return {
    pesticides: filteredPesticides,
    // fungicides: filteredFungicides,
    herbicides: filteredHerbicides,
    adjuvants: filteredAdjuvants
  }
}
