import type React from 'react'
import { motion } from 'framer-motion'
import TreeIcon from '../../../../../assets/landing/icons/tree.svg'
import programPackages from './Data/MmokoRewardDataOne'
import mmokoInfo from './Data/MmokoRewardDataTwo'
import programPackagesThree from './Data/MmokoRewardDataThree'
import MmokoRewardPackagesAccordion from '../Generic/MmokoRewardPackagesComponent'
import ImageOne from 'assets/landing/target-yields/millet.jpg'
import { useTranslation } from 'react-i18next'

export const MmokoRewardPackage: React.FC = () => {
  const { t } = useTranslation('landing')
  const { MmokoRewardPackages } = programPackages(t)
  const { MmokoInfoPoints } = mmokoInfo(t)
  const { MmokoRewardPackagesThree } = programPackagesThree(t)

  return (
    <div className='bg-white w-full '>
      <div className='container mx-auto py-10 md:py-20 px-10 flex flex-col justify-center w-screen md:w-full space-y-8'>
        <motion.div
          className='flex flex-col md:text-5xl lg:text-6xl md:justify-center md:items-center md:mb-12'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className='text-4xl md:text-5xl lg:text-6xl font-bold text-gray-700 landing-page'>
            {t('mrp.mrp')}
          </h2>
        </motion.div>

        {/* Container with Picture and Mmoko Description */}
        <div className='flex flex-col-reverse space-y-6 md:flex-row'>
          <div className='w-full md:w-1/2 flex justify-center items-center'>
            <motion.img
              src={ImageOne}
              alt='Mmoko Reward Package'
              className='w-5/6 h-5/6 object-cover rounded-lg'
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            />
          </div>
          <div className='w-full md:w-1/2'>
            {MmokoRewardPackages.map((packageItem, index) => (
              <motion.div
                key={index}
                className='flex flex-row items-start mb-16'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <img src={TreeIcon} alt='Tree Icon' className='w-6 h-6 mr-5' />
                <div className='flex flex-col'>
                  <p className='text-base md:text-xl lg:text-2xl text-gray-600 landing-page'>
                    {packageItem.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/*   Container with Reward Vouchers and Service Level Agreement */}
        <div className='flex flex-col space-y-8'>
          {MmokoInfoPoints.map((infoPoint, index) => (
            <motion.div
              key={index}
              className='flex flex-row items-start'
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <img src={TreeIcon} alt='Tree Icon' className='w-6 h-6 mr-5' />
              <div className='flex flex-col'>
                <h4 className='text-base md:text-xl lg:text-2xl font-bold text-gray-700 mb-1 landing-page'>
                  {infoPoint.title}
                </h4>
                <p className='text-base md:text-xl lg:text-2xl text-gray-600 landing-page'>
                  {infoPoint.description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>

        {/*   Container With Eligibility Criteria */}
        <div className='flex flex-col justify-center items-start my-8'>
          <motion.h2
            className='text-base md:text-xl lg:text-3xl font-bold text-gray-700 landing-page'
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            {t('tle.crit')}          </motion.h2>
          <ul className='mt-4'>
            {MmokoRewardPackagesThree.map((packageItem, index) => (
              <motion.li
                key={index}
                className='text-base md:text-xl lg:text-2xl text-gray-600 my-6'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <div className='flex flex-row items-start'>
                  <img src={TreeIcon} alt='Tree Icon' className='w-6 h-6 mr-5' />
                  <div className='flex flex-col'>
                    <p className='text-base md:text-xl lg:text-2xl text-gray-600 landing-page'>
                      {packageItem.description}
                    </p>
                  </div>
                </div>
              </motion.li>
            ))}
          </ul>
        </div>

        {/*   Container with Accordion showing Mmoko Reward Packages types */}
        <motion.div
          className='flex flex-col justify-center items-start'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <MmokoRewardPackagesAccordion />
        </motion.div>
      </div>
    </div>
  )
}