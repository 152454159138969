import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface UpdateChemicalsDTO {
  chemical_id: number | null | undefined
  chemical_schedule: number
  weed_id: number | null | undefined
  chemical: number
  adjuvant: number | null | undefined
}

export interface UpdateWeedingDTO {
  cp_id: number | null | undefined
  farmer_id: number | null | undefined
  cp_weed_control_id: number | null | undefined
  user_id: number | null | undefined
  type: string | null | undefined
  manual_method?: number | null | undefined
  chemical_method?: number | null
  chemicals?: UpdateChemicalsDTO[] | null
}

export const updateWeeding = async (params: UpdateWeedingDTO): Promise<ApiResponse<[]>> => {
  return axios.put(`/production/weeding`, params)
}
