import type React from 'react'
import { Outlet } from 'react-router-dom'
import Session from 'supertokens-auth-react/recipe/session'
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles'
import Spinner from '../../../components/ui/spinner'
import { clearLoginAttemptInfo } from 'supertokens-web-js/lib/build/recipe/passwordless'
import { Footer, NavLandingPage } from '../components'
import { ContactCards } from '../components/SubComponents/Contact/ContactCards'
import { FooterCTA } from '../components/SubComponents/About/FooterCTA'
import FooterImage from 'assets/landing/impact_in_action/14.jpeg'
import FAQAccordion from '../components/SubComponents/Generic/FAQ'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

export const ContactUs: React.FC = () => {
  const { t } = useTranslation('landing')
  const claimValue = Session.useClaimValue(UserRoleClaim)

  if (claimValue.loading) {
    return (
      <div className='flex items-center justify-center w-screen h-screen'>
        <Spinner />
      </div>
    )
  }

  const roles = claimValue.value

  const handleNavigateToDashboard = (): string => {
    if (Array.isArray(roles) && roles.includes('FARMER')) {
      return '/farmer'
    } else if (Array.isArray(roles) && roles.includes('EXTENSION_OFFICER')) {
      return '/officer'
    } else if (Array.isArray(roles) && roles.includes('SOIL_SAMPLE_LAB')) {
      return '/soil-lab'
    } else if (Array.isArray(roles) && roles.includes('MECHANISATION_OFFICER')) {
      return '/inventory'
    } else if (Array.isArray(roles) && roles.includes('SEED_DEALER')) {
      return '/seed-dealer'
    } else if (Array.isArray(roles) && roles.includes('SERVICE_PROVIDER')) {
      return '/service-provider'
    } else {
      void clearLoginAttemptInfo()
      return '/login/phone'
    }
  }

  return (
    <main className='landing-page'>
      {/* SEO Optimization */}
      <Helmet>
        <title>{t('con.con')}</title>
        <meta
          name='description'
          content='Contact Temo Letlotlo for inquiries about our services, farmer support programs, and more. We’re here to help.'
        />
        <meta
          name='keywords'
          content='Temo Letlotlo contact, contact Temo Letlotlo, Botswana farming support contact'
        />

        {/* Open Graph / Facebook */}
        <meta property='og:type' content='website' />
        <meta property='og:title' content='Contact Temo Letlotlo - Get In Touch with Us' />
        <meta
          property='og:description'
          content='Reach out to Temo Letlotlo for more information about our agricultural support and services for Botswana’s farmers.'
        />
        <meta property='og:image' content='https://www.temoletlotlo.com/contact-image.jpg' />
        <meta property='og:url' content='https://www.temoletlotlo.com/contact-us' />

        {/* Twitter */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content='Contact Temo Letlotlo - Get In Touch with Us' />
        <meta
          name='twitter:description'
          content="Get in touch with Temo Letlotlo for inquiries about how we support Botswana's farmers."
        />
        <meta
          name='twitter:image'
          content='https://www.temoletlotlo.com/contact-twitter-image.jpg'
        />
      </Helmet>

      {/* Page Components */}
      <NavLandingPage
        goToDashboard={handleNavigateToDashboard}
        isAuthenticated={claimValue.value != null}
      />
      <ContactCards />
      <FAQAccordion />
      <FooterCTA
        backgroundImage={FooterImage}
        headerText={t('about.join')}
        buttonText={t('home.reg')}
        navigationLink='/registration'
      />
      <Outlet />
      <Footer />
    </main>
  )
}