import type React from 'react'
import { testimonials } from './Data/TestimonialsData'
import { Avatar, AvatarFallback, Card, CardContent, CardTitle } from '../../../../../components/ui'

const TestimonialCards: React.FC = () => {
  return (
    <div className='w-full overflow-x-auto pb-6 px-4 flex '>
      <div className='flex  space-x-4 px-4 '>
        {testimonials.map((testimonial) => (
          <Card
            key={testimonial.id}
            className='w-[450px] flex-shrink-0 border-transparent bg-inherit '
          >
            <CardContent className='flex flex-col space-y-8'>
              <p className='text-xs md:text-sm lg:text-base text-gray-600 font-regular'>
                {testimonial.content}
              </p>
              <div className='flex items-center space-x-4'>
                <Avatar>
                  <AvatarFallback>{testimonial.name[0]}</AvatarFallback>
                </Avatar>
                <div>
                  <CardTitle className=''>
                    <p className='text-xs text-gray-600 font-bold'>{testimonial.name}</p>
                  </CardTitle>
                  <p className='text-xs text-gray-600 font-light'>{testimonial.role}</p>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  )
}

export default TestimonialCards
