import type React from 'react'
import { useNavigate } from 'react-router-dom'
import { EmailLogin } from '../components/email/EmailLogin'
import { AuthLayout } from '../components/layout/AuthLayout'

export const EmailLoginPage: React.FC = () => {
  const navigate = useNavigate()

  const onRegister = (): void => {
    navigate('/registration')
  }

  const handleLoginType = (): void => {
    navigate('/login/phone', { replace: true })
  }

  return (
    <AuthLayout
      className={'w-full sm:max-w-[450px] flex flex-col justify-center items-center p-4 py-8'}
    >
      <EmailLogin onRegister={onRegister} onPhone={handleLoginType} />
    </AuthLayout>
  )
}
