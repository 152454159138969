import { type FC } from 'react'
import { CroppingPlanHeaderStatus, type Farm, type Lease } from 'types'
import { useGetCroppingPlans } from '../../../api/getCroppingPlans'
import { useFarmerStore } from 'stores/useFarmerStore'
import { Loader } from 'components/ui/loader'
import { Error } from 'components/Errors/Error'
import { Text } from 'components/ui'
import { GrantForm } from './GrantForm'
import { useTranslation } from 'react-i18next'
import { usePloughSeason } from '../../../../../hooks/usePloughSeason'

interface Base {
  cancel: () => void
}

interface WithFarm extends Base {
  farm: Farm
  lease?: never
}

interface WithLease extends Base {
  farm?: never
  lease: Lease
}

type NewGrantProps = WithLease | WithFarm

export const NewGrant: FC<NewGrantProps> = ({ cancel, lease, farm }) => {
  const { isCurrentSeason, isLoading: ploughSeasonIsLoading } = usePloughSeason()
  const { farmer } = useFarmerStore()
  const {
    data: croppingPlans,
    isLoading,
    error
  } = useGetCroppingPlans(farmer?.farmer_id, lease === undefined ? farm?.farm_id : lease?.farm_id)

  const { t } = useTranslation('grants')

  if (isLoading || ploughSeasonIsLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  const isError = error != null && error.response?.status !== 404

  if (isError) {
    return <Error />
  }

  // no cropping plans
  if (croppingPlans == null) {
    return (
      <div className={'flex flex-col items-center gap-4'}>
        <Text className={'text-center'} size={'large'} variant={'error'}>
          {t('cp.none')}
        </Text>
        <Text className={'text-center'} size={'medium'} variant={'bodyText'}>
          {t('cp.make')}
        </Text>
      </div>
    )
  }

  const verifiedCroppingPlans = croppingPlans.data.filter((item): boolean => {
    return (
      item.is_verified === CroppingPlanHeaderStatus.Approved && isCurrentSeason(item.plough_season)
    )
  })

  // no verified cropping plans
  if (verifiedCroppingPlans.length === 0) {
    return (
      <div className={'flex flex-col items-center gap-4'}>
        <Text className={'text-center'} size={'large'} variant={'error'}>
          {t('cp.none')}
        </Text>
        <Text className={'text-center'} size={'medium'} variant={'bodyText'}>
          {t('cp.approval')}
        </Text>
      </div>
    )
  }

  const validCroppingPlans = verifiedCroppingPlans.filter((item) => item.hectarage <= 4)

  // no cropping plans <= 4 ha
  if (validCroppingPlans.length === 0) {
    return (
      <div className={'flex flex-col items-center gap-4'}>
        <Text className={'text-center'} size={'large'} variant={'error'}>
          {t('cp.none')}
        </Text>
        <Text className={'text-center'} size={'medium'} variant={'bodyText'}>
          {t('cp.requirements')}
        </Text>
      </div>
    )
  }

  if (lease != null) {
    return <GrantForm lease={lease} cancel={cancel} croppingPlans={validCroppingPlans} />
  }

  return <GrantForm farm={farm} cancel={cancel} croppingPlans={validCroppingPlans} />
}
