import { Grow } from '@mui/material'
import { Text } from 'components/ui'
import { useMyLeases } from 'features/farmer/lease-management/hooks/useMyLeases'
import type React from 'react'
import { useState } from 'react'
import { LeaseFilterMenu } from '../components/filterMenu'
import { LeaseCard } from '../components/LeaseCard'
import { Box, TabView } from 'components/App/Layout'
import { CircleOff } from 'lucide-react'
import { Skeleton } from 'components/ui/skeleton'
import { SkeletonCard } from 'components/SkeletonCardLoader'
import { LeaseOfferCard } from '../components/lease-offers/LeaseOfferCard'
import { Error } from 'components/Errors/Error'
import { useTranslation } from 'react-i18next'

export const LeaseManagement: React.FC = () => {
  const { rejected, leases, isLoading, verified, error, offers, terminated, eoVerification } =
    useMyLeases()
  const [filter, setFilter] = useState<
    | 'all'
    | 'REJECTED BY LESSEE'
    | 'verified'
    | 'INITIATED BY LESSOR'
    | 'RENEWAL INITIATED BY LESSEE'
    | 'VERIFIED BY EXTENSION OFFICER'
    | 'ACCEPTED BY LESSEE'
  >('all')

  const { t } = useTranslation('leaseManagement')

  // when loading show loading state
  if (isLoading) {
    return (
      <div className={'w-full flex flex-col gap-4 md:gap-8 lg:gap-12'}>
        <Skeleton className='w-[75px] md:w-[150px] h-[20px] rounded-full' />
        <div className={'flex flex-col gap-4 w-full'}>
          <Skeleton className='w-[75px] md:w-[150px] h-[20px] rounded-full' />
          <SkeletonCard />
        </div>

        <Skeleton className='w-[175px] md:w-[450px] lg:w-[600px] h-[20px] rounded-full' />
        <div
          className={
            'w-full grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))] gap-4 list-none py-4'
          }
        >
          {Array(16)
            .fill(1)
            .map((_, index) => {
              return <SkeletonCard key={index} />
            })}
        </div>
      </div>
    )
  }

  if (error != null && error?.response?.status !== 404) {
    return (
      <Box>
        <Error />
      </Box>
    )
  }

  const offerList = [...(offers ?? []), ...(terminated ?? [])]

  // when done loading
  // show the following
  return (
    <Box>
      <div className={'flex  items-center'}>
        <Text size={'large'}>{t('lease.farms')}</Text>
      </div>

      {offerList.length > 0 ? (
        <div
          className={
            'w-full grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))] gap-4 list-none py-4'
          }
        >
          {offerList.map((offer) => {
            return <LeaseOfferCard key={offer.lease_id} lease={offer} />
          })}
        </div>
      ) : null}

      {/* Show this when no leased farms available */}
      {leases?.length === 0 ? (
        <TabView>
          <Text size={'medium'}>{t('lease.farms')}</Text>
          <div className={'min-h-[200px] flex flex-col gap-4 items-center justify-center'}>
            <Text size={'xLarge'} className={'opacity-40'}>
              <CircleOff />
            </Text>
            <Text size={'medium'} variant={'bodyTextLight'}>
              {t('lease.noFarms')}
            </Text>
          </div>
        </TabView>
      ) : (
        // Show this when a leased farm exists
        <div className={'w-full flex flex-col gap-4'}>
          <LeaseFilterMenu
            all={leases?.length ?? 0}
            verified={verified?.length ?? 0}
            rejected={rejected?.length ?? 0}
            offers={offers?.length ?? 0}
            eoVerification={eoVerification?.length ?? 0}
            filterBy={filter}
            setFilterBy={setFilter}
          />

          <Grow in={leases !== undefined}>
            <div
              className={
                'w-full grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))] gap-4 list-none py-4'
              }
            >
              {leases
                ?.filter((lease) => {
                  if (filter === 'all') return lease
                  return lease.status_desc === filter
                })
                .map((lease) => {
                  return <LeaseCard lease={lease} key={lease.lease_id} />
                })}
            </div>
          </Grow>
        </div>
      )}
    </Box>
  )
}
