// SupervisedCreditPackageData.ts

/**
 * Interface representing the structure of a subsidy.
 */
interface Subsidy {
  name: string
  description: string
  eligibility: string
  covered: string
  maximumValue: string
}

/**
 * Interface representing the structure of Enhanced Technical Assistance visits.
 */
interface ExtensionVisit {
  visitNumber: string
  timing: string
}

/**
 * Interface representing the structure of the Supervised Credit Program.
 */
interface SupervisedCredit {
  name: string
  description: string
  loanFeatures: {
    maxAmount: string
    guarantee: string
    purpose: string
    restrictions: string
    progression: string
  }
  technicalAssistance: {
    description: string
    components: {
      extensionOfficer: {
        role: string
        visitRequirements: string
        visits: ExtensionVisit[]
      }
      creditSupervisor: {
        role: string
        responsibilities: string[]
      }
    }
  }
}

/**
 * Interface representing the structure of the program packages.
 */
interface ProgramPackages {
  programName: string
  programDescription: string
  supervisedCredit: SupervisedCredit
}

/**
 * Constant containing the details of the program packages.
 */
const programPackages = (t: (key: string) => string): ProgramPackages => {
  return {
    programName: t('credit.name'),
    programDescription: t('credit.desc'),

    supervisedCredit: {
      name: t('credit.scName'),
      description: t('credit.scDesc'),
      loanFeatures: {
        maxAmount: t('credit.lfMax'),
        guarantee: t('credit.gua'),
        purpose: t('credit.purp'),
        restrictions: t('credit.res'),
        progression: t('credit.prog')
      },
      technicalAssistance: {
        description: t('credit.taDesc'),
        components: {
          extensionOfficer: {
            role: t('credit.eoRole'),
            visitRequirements: t('credit.eoVisit'),
            visits: [
              { visitNumber: t('credit.vn1'), timing: t('credit.vn1t') },
              { visitNumber: t('credit.vn2'), timing: t('credit.vn2t') },
              { visitNumber: t('credit.vn3'), timing: t('credit.vn3t') },
              { visitNumber: t('credit.vn4'), timing: t('credit.vn4t') },
              { visitNumber: t('credit.vn5'), timing: t('credit.vn5t') }
            ]
          },
          creditSupervisor: {
            role: t('credit.csRole'),
            responsibilities: [
              t('credit.csR1'),
              t('credit.csR2'),
              t('credit.csR3'),
              t('credit.csR4'),
              t('credit.csR5'),
              t('credit.csR6'),
              t('credit.csR7')
            ]
          }
        }
      }
    }
  }
}

export default programPackages