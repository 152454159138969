import i18next, { type i18n } from 'i18next'
import { initReactI18next } from 'react-i18next'
import { resources } from '../utils/resources'

const instance = (): i18n => {
  const i18nInstance = i18next.createInstance()
  void i18nInstance.use(initReactI18next).init({
    fallbackLng: 'en',
    debug: false, // Set to true for debugging
    lng: 'en',
    interpolation: { escapeValue: false },
    resources
  })

  return i18nInstance
}
export default instance