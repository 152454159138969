import { useGetFarms } from '../../land-management/api/getFarms'
import { useGetLeases } from '../../lease-management/api/getLeases'
import { type Farm, type Lease } from '../../../../types'
import { useFarmerStore } from '../../../../stores/useFarmerStore'

export type VerifiedFarm = Farm | Lease

interface VerifiedFarmsResult {
  verifiedFarms: VerifiedFarm[]
  isLoading: boolean
}

export const useGetVerifiedFarms = (farmerId?: number): VerifiedFarmsResult => {
  const { farmer } = useFarmerStore()

  const { data: farms, isLoading: isLoadingFarms } = useGetFarms(
    farmerId ?? Number(farmer?.farmer_id)
  )
  const { data: leasedFarms, isLoading: isLoadingLeases } = useGetLeases(
    farmerId ?? Number(farmer?.farmer_id)
  )

  const isLoading = isLoadingFarms || isLoadingLeases

  const verifiedFarms = [
    ...(farms?.data.filter((item) => item.status === 'verified') ?? []),
    ...(leasedFarms?.data.filter(
      (lease) =>
        lease.status_desc === 'VERIFIED BY EXTENSION OFFICER' ||
        lease.status_desc === 'RENEWAL ACCEPTED BY LESSOR'
    ) ?? [])
  ]

  return { verifiedFarms, isLoading }
}
