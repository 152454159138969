import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'
import { type GetInventoryRes } from '../../../farmer/inventory/types'

export const getAllInventories = async (
  extId: number | null | undefined,
  userType: string | null | undefined,
  userId: string | null | undefined
): Promise<ApiResponse<GetInventoryRes>> => {
  const queryParams = new URLSearchParams()
  if (extId != null) {
    queryParams.append('ext_area_id', extId.toString())
  }
  if (userType === 'farmer' && userId != null) {
    queryParams.append('farmer_id', userId)
  } else if (userType === 'merchant' && userId != null) {
    queryParams.append('merchant_id', userId)
  }

  return axios.get(`/inventory?${queryParams.toString()}`)
}

export const useGetAllInventories = (
  extId: number | null | undefined,
  userType: string | null | undefined,
  userId: string | null | undefined
): UseQueryResult<ApiResponse<GetInventoryRes>, AxiosError<[]>> => {
  return useQuery({
    queryKey: ['eo-inventories', extId, userType, userId],
    queryFn: async () => getAllInventories(extId, userType, userId),
    enabled: extId != null && userType != null && userId != null
  })
}
