import { type ApiResponse } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type GetInventoryRes } from '../types'

export const getInventoryItem = async (
  inventory_id: number
): Promise<ApiResponse<GetInventoryRes>> => {
  return axios.get(`/inventory?inventory_id=${inventory_id}`)
}

export const useGetInventoryItem = (
  inventory_id: number
): UseQueryResult<ApiResponse<GetInventoryRes>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: [`inventories`, inventory_id],
    queryFn: async () => getInventoryItem(inventory_id),
    enabled: inventory_id != null
  })
}
