import { axios } from '../../../../lib/axios'
import { type ApiResponse } from 'types'
import { type InitiateTransferDTO } from '../types'

export const initateTransfer = async (params: InitiateTransferDTO): Promise<ApiResponse<[]>> => {
  return axios.post('/inventory/transfer', params)
}

export const acceptTransfer = async (params: InitiateTransferDTO): Promise<ApiResponse<[]>> => {
  return axios.put('/inventory/transfer/accept', params)
}

export const verifyTransfer = async (params: InitiateTransferDTO): Promise<ApiResponse<[]>> => {
  return axios.put('/inventory/transfer/verify', params)
}
