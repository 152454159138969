import type React from 'react'
import { ScrollArea, Text } from 'components/ui'
import { Separator } from 'components/ui/separator'
import { ChevronDown } from 'lucide-react'
import type { SoilCorrectionRecommendation } from '../types'
import { FertilizerDetails } from './FertilizerDetails'
import { useTranslation } from 'react-i18next'

interface SoilRecommendationDetailsProps {
  recommendation: SoilCorrectionRecommendation
}

export const SoilRecommendationDetails: React.FC<SoilRecommendationDetailsProps> = ({
  recommendation
}) => {
  const { t } = useTranslation('landManagement')

  return (
    <div className={'relative w-full h-[600px] flex flex-col items-center gap-2'}>
      <Text size={'medium'}>{t('soilReco.reco')}</Text>
      <Separator />
      <ScrollArea className='h-[560px] w-full border-b p-4'>
        {recommendation?.ph_correction_recommendation != null ? (
          <div className={'flex flex-col gap-4 mt-6'}>
            <Text size={'medium'} variant={'secondary'}>
              {t('soilReco.ph')}
            </Text>
            <FertilizerDetails fertilizer={recommendation?.ph_correction_recommendation} />
          </div>
        ) : null}

        {recommendation?.sodium_correction_recommendation != null ? (
          <div className={'flex flex-col gap-4 mt-6'}>
            <Text size={'medium'} variant={'secondary'}>
              {t('soilReco.sod')}
            </Text>
            <FertilizerDetails fertilizer={recommendation?.sodium_correction_recommendation} />
          </div>
        ) : null}

        {recommendation?.calcium_correction_recommendation != null ? (
          <div className={'flex flex-col gap-4 mt-6'}>
            <Text size={'medium'} variant={'secondary'}>
              {t('soilReco.cal')}
            </Text>
            <FertilizerDetails fertilizer={recommendation?.calcium_correction_recommendation} />
          </div>
        ) : null}

        {recommendation?.magnesium_correction_recommendation != null ? (
          <div className={'flex flex-col gap-4 mt-6'}>
            <Text size={'medium'} variant={'secondary'}>
              {t('soilReco.mag')}
            </Text>
            <FertilizerDetails fertilizer={recommendation?.magnesium_correction_recommendation} />
          </div>
        ) : null}
      </ScrollArea>
      <ChevronDown className={'absolute bottom-[-25px]'} />
    </div>
  )
}