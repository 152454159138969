import { useMutation } from '@tanstack/react-query'
import { sendOtp, type SendOtpDTO } from 'api/sendOtp'
import { verifyOtp, type VerifyOtpDTO } from 'api/verifyOtp'
import { AxiosError, type AxiosResponse } from 'axios'
import { useState } from 'react'
import { type ApiResponse } from 'types'
import { type StatusPOST } from '../types/status'
import { useToast } from '../components/ui/use-toast'

interface UseOTP {
  // success status used only when verifying otp
  status: StatusPOST | null
  isOtpSent: boolean | null
  loading: boolean
  sendOtpMessage: (number: string) => void
  resendOtpMessage: (number: string) => void
  verifyOtpMessage: (otp: string, number: string) => void
}

export const useOTP = (): UseOTP => {
  const { toast } = useToast()
  const [status, setStatus] = useState<StatusPOST | null>(null)
  const [isOtpSent, setIsOtpSent] = useState<boolean | null>(null)

  const sendOtpMutation = useMutation({
    mutationFn: async (otpDTO: SendOtpDTO) => {
      return sendOtp(otpDTO)
    }
  })

  const resendOtpMutation = useMutation({
    mutationFn: async (otpDTO: SendOtpDTO) => {
      return sendOtp(otpDTO)
    }
  })

  const verifyOtpMutation = useMutation({
    mutationFn: async (otpDTO: VerifyOtpDTO) => {
      return verifyOtp(otpDTO)
    }
  })

  const sendOtpMessage = (number: string): void => {
    setStatus(null)
    setIsOtpSent(null)

    sendOtpMutation.mutate(
      { number },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            setIsOtpSent(true)
            toast({
              variant: 'success',
              description: 'Otp successfully sent.'
            })
          } else {
            setStatus('error')
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: result.message
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const errMsg = err?.response as AxiosResponse<ApiResponse<[]>>
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: errMsg.data.message
            })
          } else {
            const errMsg = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: errMsg.message
            })
          }
        }
      }
    )
  }

  const verifyOtpMessage = (otp: string, number: string): void => {
    setStatus(null)

    verifyOtpMutation.mutate(
      {
        otp,
        number
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            setStatus('success')
          } else {
            setStatus('error')
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: result.message
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const errMsg = err?.response as AxiosResponse<ApiResponse<[]>>
            setStatus('error')
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: errMsg.data.message
            })
          } else {
            const errMsg = err as Error
            setStatus('error')
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: errMsg.message
            })
          }
        }
      }
    )
  }
  const resendOtpMessage = (number: string): void => {
    setStatus(null)

    resendOtpMutation.mutate(
      {
        number
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            toast({
              variant: 'success',
              description: 'Otp successfully sent.'
            })
          } else {
            setStatus('error')
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: result.message
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const errMsg = err?.response as AxiosResponse<ApiResponse<[]>>
            setStatus('error')
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: errMsg.data.message
            })
          } else {
            const errMsg = err as Error
            setStatus('error')
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: errMsg.message
            })
          }
        }
      }
    )
  }

  return {
    status,
    isOtpSent,
    sendOtpMessage,
    verifyOtpMessage,
    resendOtpMessage,
    loading: sendOtpMutation.isLoading || verifyOtpMutation.isLoading || resendOtpMutation.isLoading
  }
}
