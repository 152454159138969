import React from 'react'
import { Button, Dialog, DialogContent, Text } from '../../../../../../components/ui'
import { Separator } from '../../../../../../components/ui/separator'
import { LandSubdivision } from './subdivide-farm/LandSubdivision'
import type { Farm } from '../../../../../../types'
import { useTranslation } from 'react-i18next'

interface FarmSubdivisionProps {
  farm: Farm
}

export const SubdivideFarm: React.FC<FarmSubdivisionProps> = ({ farm }) => {
  const [openDialog, setOpenDialog] = React.useState<boolean>(false)
  const { t } = useTranslation('landManagement')

  const handleOpenDialog = (): void => {
    setOpenDialog(true)
  }

  const handleCloseDialog = (): void => {
    setOpenDialog(false)
  }

  return (
    <>
      <div className={'w-full flex flex-col gap-4'}>
        <Text size='medium'>{t('sub.subFarm')}</Text>
        <Separator />
        <Text variant='bodyTextLight' size='body'>
          {t('sub.subPrompt')}
        </Text>
        <Button variant='warning' size='sm' className='w-24' onClick={handleOpenDialog}>
          {t('sub.sub')}
        </Button>
      </div>

      <Dialog
        key={'renew-lease'}
        open={openDialog}
        defaultOpen={false}
        modal
        onOpenChange={handleCloseDialog}
      >
        <DialogContent className={'max-w-[80%] md:max-w-[50%] lg:max-w-[35%]'}>
          <LandSubdivision farm={farm} onClose={handleCloseDialog} />
        </DialogContent>
      </Dialog>
    </>
  )
}