// programPackages.ts

interface Detail {
  title: string
  description: string
}

interface FencingType {
  title: string
  description: string
}

interface Obligation {
  description: string
}

interface FencingEnhancer {
  detail: Detail[]
  fencingType: FencingType[]
}

const fencingEnhancer = (t: (key: string) => string): FencingEnhancer => {
  return {
    detail: [
      {
        title: t('tle.fenTitle1'),
        description: t('tle.subDesc1')
      },
      {
        title: t('tle.fenTitle2'),
        description: t('tle.subDesc2')
      },
      {
        title: t('tle.fenTitle3'),
        description: t('tle.subDesc3')
      }
    ],

    fencingType: [
      {
        title: t('tle.fenTitle4'),
        description: t('tle.fenDesc4')
      },
      {
        title: t('tle.fenTitle5'),
        description: t('tle.fenDesc5')
      }
    ]
  }
}

export default fencingEnhancer