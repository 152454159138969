import type { ColumnDef } from '@tanstack/react-table'
import type { CroppingPlan } from '../../../../../types'
import { DataTableColumnHeader } from '../../../../../components/Data-Table/datatable-header'

export const croppingPlanColumns = (t: (key: string) => string): Array<ColumnDef<CroppingPlan>> => [
  {
    accessorKey: 'farmer_id',
    filterFn: 'includesString',
    enableHiding: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('cp.fid')} />,
    cell: ({ row }) => <div className='lowercase'>{row.getValue('farmer_id')}</div>
  },
  {
    accessorKey: 'plough_season',
    enableHiding: false,
    header: t('cp.plough')
  },
  {
    accessorKey: 'crop_name',
    filterFn: 'includesString',
    enableHiding: false,
    header: t('cp.crop')
  },
  {
    accessorKey: 'family_name',
    filterFn: 'includesString',
    enableHiding: true,
    header: t('cp.family')
  },
  {
    accessorKey: 'hectarage',
    enableHiding: true,
    header: t('cp.ha')
  },
  {
    accessorKey: 'total_cost',
    enableHiding: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('cp.tCost')} />,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue('total_cost'))
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'BWP'
      }).format(amount)

      return <div className='font-medium'>{formatted}</div>
    }
  }
]