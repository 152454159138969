import moment from 'moment'
import { type LoanHeader } from '../../../../types'
import { useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai/index'
import { applicationHeader } from '../stepper/stores/applicationHeader'
import { LoanApplicationStatus } from './LoanApplicationStatus'
import { Card, Table, TableBody, TableCell, TableHeader, TableRow } from '../../../../components/ui'
import Spinner from '../../../../components/ui/spinner'
import { useTranslation } from 'react-i18next'

interface LoanCardProps {
  loanHeader: LoanHeader
  isLoading: boolean
}

export const LoanCard: React.FC<LoanCardProps> = ({ loanHeader, isLoading }) => {
  const [, setApplicationHead] = useAtom(applicationHeader)
  const navigate = useNavigate()
  const { t } = useTranslation('loanManagement')

  const onClick = (): void => {
    if (loanHeader.status_id === 0) {
      setApplicationHead((prev) => ({
        ...prev,
        loan_application_id: Number(loanHeader.loan_application_id)
      }))
      navigate('/farmer/loans/completeApplication')
    }
  }

  return (
    <div
      className={
        'flex flex-col sm:flex-row items-end sm:items-start justify-between gap-2 sm:gap-8'
      }
      onClick={onClick}
      style={{ cursor: 'pointer', width: '100%' }}
    >
      <Card className='w-full'>
        <Table className='w-full text-left '>
          <TableHeader>
            <TableRow>
              <TableCell>{t('card.lr')}</TableCell>
              <TableCell>{t('card.dc')}</TableCell>
              <TableCell>{t('card.la')} (BWP)</TableCell>
              <TableCell>{t('card.ls')}</TableCell>
              <TableCell>{t('card.sud')}</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <div className='grid place-items-center h-full flex-1'>
                <Spinner />
              </div>
            ) : (
              <TableRow className='transition-transform duration-300 ease-in-out transform hover:cursor-pointer'>
                <TableCell>{loanHeader?.loan_application_id}</TableCell>
                <TableCell>{moment(loanHeader?.create_date).format('DD-MM-YYYY')}</TableCell>
                <TableCell>{loanHeader?.loan_requirement1}</TableCell>
                <TableCell>
                  <LoanApplicationStatus
                    loanHeader={loanHeader}
                    className={'flex justify-start items-center gap-2 w-full'}
                  />
                </TableCell>
                <TableCell>{moment(loanHeader?.last_update_date).format('DD-MM-YYYY')}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Card>
    </div>
  )
}