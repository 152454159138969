import type React from 'react'
import SupervisedCreditPackageData from '../Data/ProgramPackages/SupervisedCreditPackageData'
import { useTranslation } from 'react-i18next'

/**
 * React functional component to display the Supervised Credit Program details.
 *
 * @returns {JSX.Element} The JSX code to render the Supervised Credit Program details.
 */
export const SupervisedCredit: React.FC = () => {
  const { t } = useTranslation('landing')
  const { programDescription, supervisedCredit } = SupervisedCreditPackageData(t)

  return (
    <div className='w-full mt-16'>
      {/* Program Description */}
      <div className='flex flex-col md:flex-row justify-center items-center'>
        <p className='text-base md:text-xl lg:text-2xl text-gray-600 my-2 landing-page '>
          {programDescription}
        </p>
      </div>
      {/* Supervised Credit Program */}
      <div className='flex flex-col justify-center items-start my-8'>
        <h2 className='text-base md:text-xl lg:text-3xl font-bold text-gray-700 landing-page '>
          {supervisedCredit.name}
        </h2>
        <p className='text-base md:text-xl lg:text-2xl text-gray-600 my-2 landing-page'>
          {supervisedCredit.description}
        </p>
        <h3 className='text-base md:text-xl lg:text-2xl font-bold text-gray-700 landing-page '>
          {t('credit.lf')}:
        </h3>
        <ul className='mt-4'>
          <li className='text-base md:text-xl lg:text-2xl text-gray-600 my-2'>
            <strong>{t('credit.max')}:</strong> {supervisedCredit.loanFeatures.maxAmount}
          </li>
          <li className='text-base md:text-xl lg:text-2xl text-gray-600 my-2'>
            <strong>{t('credit.guar')}:</strong> {supervisedCredit.loanFeatures.guarantee}
          </li>
          <li className='text-base md:text-xl lg:text-2xl text-gray-600 my-2'>
            <strong>{t('credit.pur')}:</strong> {supervisedCredit.loanFeatures.purpose}
          </li>
          <li className='text-base md:text-xl lg:text-2xl text-gray-600 my-2'>
            <strong>{t('credit.rest')}:</strong> {supervisedCredit.loanFeatures.restrictions}
          </li>
          <li className='text-base md:text-xl lg:text-2xl text-gray-600 my-2'>
            <strong>{t('credit.pro')}:</strong> {supervisedCredit.loanFeatures.progression}
          </li>
        </ul>
        <h3 className='text-base md:text-xl lg:text-2xl font-bold text-gray-700 landing-page '>
          {t('credit.ta')}:
        </h3>
        <p className='text-base md:text-xl lg:text-2xl text-gray-600 my-2 landing-page'>
          {supervisedCredit.technicalAssistance.description}
        </p>
        <h4 className='text-base md:text-xl lg:text-2xl font-bold text-gray-700 landing-page '>
          {t('credit.eo')}:
        </h4>
        <p className='text-base md:text-xl lg:text-2xl text-gray-600 my-2 landing-page'>
          <strong>{t('credit.role')}:</strong>{' '}
          {supervisedCredit.technicalAssistance.components.extensionOfficer.role}
        </p>
        <p className='text-base md:text-xl lg:text-2xl text-gray-600 my-2 landing-page'>
          <strong>{t('credit.vr')}:</strong>{' '}
          {supervisedCredit.technicalAssistance.components.extensionOfficer.visitRequirements}
        </p>
        <ul className='mt-4'>
          {supervisedCredit.technicalAssistance.components.extensionOfficer.visits.map(
            (visit, index) => (
              <li key={index} className='text-base md:text-xl lg:text-2xl text-gray-600 my-2'>
                <strong>{visit.visitNumber}:</strong> {visit.timing}
              </li>
            )
          )}
        </ul>
        <h4 className='text-base md:text-xl lg:text-2xl font-bold text-gray-700 landing-page '>
          {t('credit.cs')}:
        </h4>
        <p className='text-base md:text-xl lg:text-2xl text-gray-600 my-2 landing-page'>
          <strong>{t('credit.role')}:</strong>{' '}
          {supervisedCredit.technicalAssistance.components.creditSupervisor.role}
        </p>
        <ul className='mt-4'>
          {supervisedCredit.technicalAssistance.components.creditSupervisor.responsibilities.map(
            (responsibility, index) => (
              <li key={index} className='text-base md:text-xl lg:text-2xl text-gray-600 my-2'>
                {responsibility}
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  )
}