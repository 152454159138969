import type { ApiResponse } from '../types'
import { axios } from '../lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { type PloughSeason } from '../types/static'

export const getCurrentPloughSeason = async (): Promise<ApiResponse<PloughSeason>> => {
  return axios.get(`/staticdata/current-ploughing-season`)
}

export const useGetCurrentPloughSeason = (): UseQueryResult<
  ApiResponse<PloughSeason>,
  AxiosError
> => {
  return useQuery({
    queryKey: ['current-plough-season'],
    queryFn: async () => getCurrentPloughSeason(),
    retry: (failureCount, error) => {
      return failureCount <= 2
    }
  })
}
