import type React from 'react'
import { useForm } from 'react-hook-form'
import type z from 'zod'
import { coerce, object, string } from 'zod'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input
} from 'components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import type { Farm } from 'types'
import { useTranslation } from 'react-i18next'
import { useOtpVerification } from '../../../../../../../hooks/useOtpVerification'

interface SubdivisionFarmDetailsProps {
  farm: Farm
  className?: string
  next: () => void
  back: () => void
  setFarmData: React.Dispatch<React.SetStateAction<SubdivisionInput>>
}

const farmSchema = object({
  newFarmName: string().min(1, 'New Farm name is required'),
  size: coerce
    .number({ required_error: 'Size is required', invalid_type_error: 'Size should be a number' })
    .min(1, 'Size should be greater than 1')
    .max(30000, 'The max farm size allowed is 30000 hectares')
    .nonnegative('Size cannot be smaller than 1')
})

export type SubdivisionInput = z.infer<typeof farmSchema>

export const SubdivisionFarmDetails: React.FC<SubdivisionFarmDetailsProps> = ({
  next,
  back,
  farm,
  setFarmData
}) => {
  const form = useForm<z.infer<typeof farmSchema>>({
    resolver: zodResolver(farmSchema),
    defaultValues: {
      size: 1,
      newFarmName: ''
    }
  })
  const { t } = useTranslation('landManagement')
  const { isLoading, sendOtpMessage } = useOtpVerification('', farm.farmer_contact)

  const onSubmit = (data: z.infer<typeof farmSchema>): void => {
    setFarmData(data)
    sendOtpMessage(next)
  }

  return (
    <Form {...form}>
      <form
        className={'w-full flex flex-col gap-4 md:gap-8'}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <FormField
          control={form.control}
          name={'newFarmName'}
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel className={'ml-2 font-redHat '}>{t('sub.new')}</FormLabel>
              <FormControl>
                <Input
                  type={'text'}
                  placeholder={`${t('sub.name')}`}
                  classLabel={'bg-background-dark sm:bg-card'}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name={'size'}
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel className={'ml-2 font-redHat '}>{t('sub.size')}</FormLabel>
              <FormControl>
                <Input
                  classLabel={'bg-background-dark sm:bg-card'}
                  placeholder={`${t('sub.farmSize')}`}
                  type={'text'}
                  inputMode={'numeric'}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className={'w-full flex justify-between gap-4 md:gap-8'}>
          <Button
            disabled={isLoading}
            variant={'secondary'}
            className={'w-1/2'}
            type={'button'}
            onClick={back}
          >
            {t('sub.back')}
          </Button>
          <Button disabled={isLoading} loading={isLoading} className={'w-1/2'} type={'submit'}>
            {t('sub.sub')}
          </Button>
        </div>
      </form>
    </Form>
  )
}
