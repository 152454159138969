import { ErrorPage } from 'components/Errors/ErrorPage'
import {
  useGetCroppingPlan,
  useGetIndividualHeader
} from 'features/farmer/cropping-plan/api/header/getHeader'
import { useCroppingPlanStore } from 'features/farmer/cropping-plan/stores/useCroppingPlanStore'
import type React from 'react'
import { Button, Loader, Separator, Text, useToast } from 'components/ui'
import { WeedingReview } from '../steps/review/Weeding'
import { PestControlReview } from '../steps/review/PestControl'
import { useTranslation } from 'react-i18next'
import { TabView } from '../../../../../components/App/Layout'
import { useStaticDataStore } from '../../../../../stores/useStaticDataStore'
import { AxiosError } from 'axios'
import { mapCmsErrorToMessage } from '../../../../../utils/apiErrors'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateHeaderStatus, type UpdateHeaderStatusDTO } from '../../api/header/updateHeaderStatus'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'

interface ReturnedCroppingPlanProps {
  onClose: () => void
}

export const ReturnedCroppingPlan: React.FC<ReturnedCroppingPlanProps> = ({ onClose }) => {
  const { toast } = useToast()
  const { farmer } = useFarmerStore()
  const { header } = useCroppingPlanStore()
  const { seeds } = useStaticDataStore()
  const queryClient = useQueryClient()
  const { t } = useTranslation('croppingPlan')

  const updateCroppingPlanHeaderStatusMutation = useMutation({
    mutationFn: async (cpDto: UpdateHeaderStatusDTO) => {
      return updateHeaderStatus(cpDto)
    }
  })

  const updateCroppingPlanHeaderStatus = (): void => {
    updateCroppingPlanHeaderStatusMutation.mutate(
      {
        farmer_id: farmer?.farmer_id,
        cp_id: header?.cropping_plan_id,
        user_id: farmer?.user_id,
        status: 'Awaiting Farm Assessment'
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            void queryClient.invalidateQueries({ queryKey: ['cropping-plans'] })
            onClose()
            toast({
              variant: 'success',
              title: 'Successfully submitted for review.'
            })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  const onSubmit = (): void => {
    updateCroppingPlanHeaderStatus()
  }

  const {
    data: apiHeader,
    isInitialLoading: apiIsLoading,
    error
  } = useGetIndividualHeader(header?.cropping_plan_id, header?.farmer_id)
  const {
    data: apiCroppingPlan,
    isLoading,
    error: cpError
  } = useGetCroppingPlan(header?.cropping_plan_id, header?.farmer_id)

  if (isLoading || apiIsLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (error != null || cpError != null) {
    return <ErrorPage />
  }

  const selectedSeed = seeds?.find(
    (seed) => seed?.seed_detail_id === apiCroppingPlan?.data?.seed_detail_id
  )

  return (
    <TabView>
      <div className={'w-full flex items-center justify-end'}>
        <Button
          color={'warning'}
          variant={'primary'}
          onClick={onSubmit}
          loading={updateCroppingPlanHeaderStatusMutation.isLoading}
          disabled={updateCroppingPlanHeaderStatusMutation.isLoading}
        >
          Submit for Re-Evaluation
        </Button>
      </div>
      <div className={'m-10'}>
        <div className={'w-full flex flex-col gap-2 md:gap-4'}>
          {/* Cropping Plan Header */}
          {apiCroppingPlan?.data?.hectarage != null ? (
            <section className='flex flex-col gap-2'>
              <Text variant='primary'>{t('review.crop')}</Text>
              <div className='flex items-center gap-4 px-4'>
                <div className='w-1/2 flex flex-col'>
                  <Text size='small' variant='secondary'>
                    {t('review.name')}
                  </Text>
                  <Text size={'medium'}>{header?.crop_name}</Text>
                </div>
                <div className='w-1/2 flex flex-col'>
                  <Text size='small' variant='secondary'>
                    {t('review.ha')}
                  </Text>
                  <Text className={'font-semibold'}>{apiHeader?.data[0].hectarage}</Text>
                </div>
              </div>
              <Separator />
            </section>
          ) : null}

          {/*  Seeding */}
          {apiCroppingPlan?.data?.seed_type != null ? (
            <section className='flex flex-col gap-2'>
              <Text variant='primary'>{t('review.seed')}</Text>
              <div className='flex items-center gap-4 px-4'>
                <div className='w-1/2 flex flex-col'>
                  <Text size='small' variant='secondary'>
                    {t('review.variety')}
                  </Text>
                  <Text className={'font-semibold'}>{selectedSeed?.variety ?? ''}</Text>
                </div>
                <div className='w-1/2 flex flex-col'>
                  <Text size='small' variant='secondary'>
                    {t('review.type')}
                  </Text>
                  <Text className={'font-semibold'}>{selectedSeed?.seed_type ?? ''}</Text>
                </div>
              </div>

              <div className='flex flex-col gap-4 px-4'>
                <div className={'flex items-center gap-4'}>
                  <div className='w-1/2 flex flex-col'>
                    <Text size='small' variant='secondary'>
                      {t('review.prod')}
                    </Text>
                    <Text className={'font-semibold'}>
                      {apiCroppingPlan?.data?.target_yield}{' '}
                      {apiCroppingPlan?.data?.target_yield == null
                        ? 'tonne(s)'
                        : apiCroppingPlan?.data?.target_yield > 1
                        ? 'tonnes'
                        : 'tonne'}
                    </Text>
                  </div>
                  {apiCroppingPlan?.data?.anticipated_returns === 0 ||
                  apiCroppingPlan?.data?.anticipated_returns == null ? null : (
                    <div className='w-1/2 flex flex-col'>
                      <Text size='small' variant='secondary'>
                        {t('review.returns')}
                      </Text>
                      <Text className={'font-semibold'}>
                        BWP {apiCroppingPlan?.data?.anticipated_returns}
                      </Text>
                    </div>
                  )}
                </div>
                {apiCroppingPlan?.data?.anticipated_returns === 0 ||
                apiCroppingPlan?.data?.anticipated_returns == null ? null : (
                  <Text variant='primary' size={'small'}>
                    {t('review.bamb')}
                  </Text>
                )}
              </div>

              <div className='flex items-center gap-4 mt-2 px-4'>
                <div className='flex flex-col'>
                  <Text size='small' variant='secondary'>
                    {t('review.seedCost')}
                  </Text>
                  <Text className={'font-semibold'}>BWP {apiCroppingPlan?.data?.seed_cost}</Text>
                  <Text variant='primary' size={'small'}>
                    {t('review.seedCostPrompt')}
                  </Text>
                </div>
              </div>
              <Separator />
            </section>
          ) : null}

          {/*  Weeding */}
          {apiCroppingPlan?.data?.weed_control_type != null ? (
            <WeedingReview cp={apiCroppingPlan?.data} />
          ) : null}

          {/*  Pest Control */}
          {apiCroppingPlan?.data?.pest_control_type != null ? (
            <PestControlReview cp={apiCroppingPlan?.data} />
          ) : null}

          {/*  Land Preparation */}
          {apiCroppingPlan?.data?.tillage_method != null ? (
            <section className='flex flex-col gap-4'>
              <Text variant='primary' className={'font-semibold'}>
                {t('review.land')}
              </Text>

              <div className='flex items-center gap-4 px-4'>
                <div className='w-1/2 flex flex-col'>
                  <Text size='small' variant='secondary'>
                    {t('review.landMethod')}
                  </Text>
                  <Text className={'font-semibold'}>{apiCroppingPlan?.data?.tillage_method}</Text>
                </div>
                <div className='w-1/2 flex flex-col'>
                  <Text size='small' variant='secondary'>
                    {t('review.harrow')}
                  </Text>
                  <Text className={'font-semibold'}>
                    {apiCroppingPlan?.data?.isHarrowing === true ? 'Yes' : 'No'}
                  </Text>
                </div>
              </div>
              <div className='flex items-center gap-4 px-4'>
                <div className='w-1/2 flex flex-col'>
                  <Text size='small' variant='secondary'>
                    {t('review.landCost')}
                  </Text>
                  <Text className={'font-semibold'}>
                    BWP {apiCroppingPlan?.data?.land_preparation_cost}
                  </Text>
                </div>
              </div>
              <Separator />
            </section>
          ) : null}

          {/*  Harvesting */}
          {apiCroppingPlan?.data?.harvesting_method != null ? (
            <section className='flex flex-col gap-4'>
              <Text variant='primary' className={'font-semibold'}>
                {t('review.harvest')}
              </Text>

              <div className='flex items-center gap-4 px-4'>
                <div className='w-1/2 flex flex-col'>
                  <Text size={'small'}>{t('review.harvestMethod')}</Text>
                  <Text className={'font-semibold'}>
                    {apiCroppingPlan?.data?.harvesting_method}
                  </Text>
                </div>
                <div className='w-1/2 flex flex-col'>
                  <Text size='small' variant='secondary'>
                    {t('review.harvestCost')}
                  </Text>
                  <Text className={'font-semibold'}>
                    BWP {apiCroppingPlan?.data?.harvesting_cost}
                  </Text>
                </div>
              </div>
              <Separator />
            </section>
          ) : null}

          {/*  Shelling & Threshing */}
          {apiCroppingPlan?.data?.shelling_threshing_method != null ? (
            <section className='flex flex-col gap-4'>
              <Text variant='primary' className={'font-semibold'}>
                {t('review.shell')}
              </Text>

              <div className='flex items-center gap-4 px-4'>
                <div className='w-1/2 flex flex-col'>
                  <Text size='small' variant='secondary'>
                    {t('review.shellMethod')}
                  </Text>
                  <Text className={'font-semibold'}>
                    {apiCroppingPlan?.data?.shelling_threshing_method}
                  </Text>
                </div>
                <div className='w-1/2 flex flex-col'>
                  <Text size='small' variant='secondary'>
                    {t('review.shellCost')}
                  </Text>
                  <Text className={'font-semibold'}>
                    BWP {apiCroppingPlan?.data?.shelling_threshing_cost}
                  </Text>
                </div>
              </div>
              <Separator />
            </section>
          ) : null}

          {/*  Packaging */}
          {apiCroppingPlan?.data?.packaging_method != null ? (
            <section className='flex flex-col gap-4'>
              <Text variant='primary' className={'font-semibold'}>
                {t('review.pack')}
              </Text>

              <div className='flex items-center gap-4 px-4'>
                <div className='w-1/2 flex flex-col'>
                  <Text size='small' variant='secondary'>
                    {t('review.packMethod')}
                  </Text>
                  <Text className={'font-semibold'}>{apiCroppingPlan?.data?.packaging_method}</Text>
                </div>
                <div className='w-1/2 flex flex-col'>
                  <Text size='small' variant='secondary'>
                    {t('review.packCost')}
                  </Text>
                  <Text className={'font-semibold'}>
                    BWP {apiCroppingPlan?.data?.packaging_cost}
                  </Text>
                </div>
              </div>
              <Separator />
            </section>
          ) : null}

          {/* Transportation */}
          {apiCroppingPlan?.data?.transportation_cost === null ||
          apiCroppingPlan?.data?.transportation_cost === 0 ? null : (
            <section className='flex flex-col gap-4'>
              <Text variant='primary' className={'font-semibold'}>
                {t('review.tran')}
              </Text>

              <div className='flex items-center gap-4 px-4'>
                <div className='w-1/2 flex flex-col'>
                  <Text size='small' variant='secondary'>
                    {t('review.tcTran')}
                  </Text>
                  <Text className={'font-semibold'}>
                    BWP {apiCroppingPlan?.data?.transportation_cost}
                  </Text>
                </div>
              </div>
              <Separator />
            </section>
          )}

          {/* Total Cost */}
          <section className='flex flex-col gap-4'>
            <Text variant='primary' className={'font-semibold'}>
              {t('review.tCost')}
            </Text>

            <div className='flex items-center gap-4 px-4'>
              <div className='w-1/2 flex flex-col'>
                <Text size={'large'} variant={'success'} className={'font-semibold'}>
                  BWP {apiHeader?.data[0]?.total_cost ?? header?.total_cost}
                </Text>
              </div>
            </div>
            <Separator />
          </section>
        </div>
      </div>
      <div className={'w-full flex items-center justify-center'}>
        <Button variant={'primary'} className={'w-[8rem]'} onClick={onClose}>
          Close
        </Button>
      </div>
    </TabView>
  )
}
