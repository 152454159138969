import FinanceLogo from '../../../../../assets/landing/partner_logos/finance.png'
import MOELogo from '../../../../../assets/landing/partner_logos/moe.png'
import TransportLogo from '../../../../../assets/landing/partner_logos/transport_public_works.png'
import LandsLogo from '../../../../../assets/landing/partner_logos/lands_water_affairs.jpg'
import Tourism from '../../../../../assets/landing/partner_logos/tourism_environment.png'
import Local from '../../../../../assets/landing/partner_logos/local_governement_rural_development.png'
import Trade from '../../../../../assets/landing/partner_logos/trade_industry.png'
import Labour from '../../../../../assets/landing/partner_logos/labour_home_affairs.png'
import NDB from '../../../../../assets/landing/partner_logos/ndb-removebg-preview.png'
import BIUST from '../../../../../assets/landing/partner_logos/biust.png'
import BAMB from '../../../../../assets/landing/partner_logos/bamb.png'
import MOA from '../../../../../assets/landing/partner_logos/moa.png'
import Phoenix from '../../../../../assets/landing/partner_logos/phoenix.png'
import { LogoCard } from '../Generic/LogoCard'
import { motion } from 'framer-motion'

interface PartnersProps {
  header: string
}

export const Partners: React.FC<PartnersProps> = ({ header }) => {
  return (
    <div className='bg-blue-50 w-full '>
      <div className='container mx-auto py-10 md:py-20 flex flex-col px-10 justify-center w-screen md:w-full space-y-8'>
        <div className='flex flex-col items-center mb-12'>
          <h2 className='text-4xl md:text-5xl lg:text-6xl font-bold text-gray-700 landing-page'>
            {header}
          </h2>
        </div>
        <div className='grid grid-col-1 md:grid-cols-4 gap-8'>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            whileHover={{ scale: 1.05 }}
          >
            <LogoCard
              src={MOA}
              alt='Logo 1'
              link='https://www.gov.bw/ministries/ministry-agriculture'
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            whileHover={{ scale: 1.05 }}
          >
            <LogoCard
              src={FinanceLogo}
              alt='Logo 1'
              link='https://www.finance.gov.bw/index.php?Itemid=114&option=com_content&view=category&id=37'
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            whileHover={{ scale: 1.05 }}
          >
            <LogoCard src={BIUST} alt='Logo 1' link='https://biust.ac.bw' />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            whileHover={{ scale: 1.05 }}
          >
            <LogoCard src={NDB} alt='Logo 1' link='https://www.ndb.bw/' />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            whileHover={{ scale: 1.05 }}
          >
            <LogoCard src={BAMB} alt='Logo 1' link='https://bamb.co.bw/' />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            whileHover={{ scale: 1.05 }}
          >
            <LogoCard src={Phoenix} alt='Logo 1' link='https://www.phoenixbotswana.com/' />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            whileHover={{ scale: 1.05 }}
          >
            <LogoCard
              src={MOELogo}
              alt='Logo 1'
              link='https://www.facebook.com/p/Ministry-of-Entrepreneurship-100081742416146/'
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            whileHover={{ scale: 1.05 }}
          >
            <LogoCard
              src={Trade}
              alt='Logo 1'
              link='https://www.gov.bw/ministries/ministry-trade-and-industry'
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            whileHover={{ scale: 1.05 }}
          >
            <LogoCard
              src={Labour}
              alt='Logo 1'
              link='https://www.facebook.com/p/Ministry-of-Labour-and-Home-Affairs-100069163912022/'
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            whileHover={{ scale: 1.05 }}
          >
            <LogoCard
              src={TransportLogo}
              alt='Logo 1'
              link='https://www.gov.bw/ministries/ministry-transport-and-public-works'
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            whileHover={{ scale: 1.05 }}
          >
            <LogoCard
              src={LandsLogo}
              alt='Logo 1'
              link='https://www.gov.bw/ministries/ministry-lands-and-water-affairs'
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            whileHover={{ scale: 1.05 }}
          >
            <LogoCard
              src={Tourism}
              alt='Logo 1'
              link='https://www.gov.bw/ministries/ministry-environment-and-tourism'
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            whileHover={{ scale: 1.05 }}
          >
            <LogoCard
              src={Local}
              alt='Logo 1'
              link='https://www.gov.bw/ministries/ministry-local-government-and-rural-development'
            />
          </motion.div>
        </div>
      </div>
    </div>
  )
}
