// types/districtContacts.ts

export interface DistrictContact {
  district: string
  directLine: string
}

export const districtContactsData: DistrictContact[] = [
  { district: 'Serowe', directLine: '4631058' },
  { district: 'Mahalapye', directLine: '4710676/ 4710257' },
  { district: 'Tlokweng', directLine: '3925550' },
  { district: 'Masunga', directLine: '2489981' },
  { district: 'Francistown', directLine: '2419310' },
  { district: 'Kweneng', directLine: '5921856/591526' },
  { district: 'Letlhakeng', directLine: '5921856' },
  { district: 'Mogoditshane-Thamaga', directLine: '5921051/ 5920477' },
  { district: 'Moshupa', directLine: '5449112/5449094' },
  { district: 'Kanye', directLine: '5440365' },
  { district: 'Bobirwa', directLine: '2619218' },
  { district: 'Boteti', directLine: '2978325' },
  { district: 'Charleshill', directLine: '6592051' },
  { district: 'Chobe', directLine: '6252792' },
  { district: 'Gaborone', directLine: '3699307' },
  { district: 'Ghanzi', directLine: '6596217' },
  { district: 'Goodhope', directLine: '5404223' },
  { district: 'Hukuntsi', directLine: '6510242' },
  { district: 'Kgatleng', directLine: '5774644' },
  { district: 'Mabutsane', directLine: '5880948' },
  { district: 'Northwest', directLine: '6863020' },
  { district: 'Okavango', directLine: '6875051' },
  { district: 'Palapye', directLine: '4923657' },
  { district: 'Ramotswa', directLine: '5391910' },
  { district: 'Selebi Phikwe', directLine: '261 3834' },
  { district: 'Tonota', directLine: '2485554' },
  { district: 'Tsabong', directLine: '6540757' },
  { district: 'Tutume', directLine: '2987310' }
]
