import { IoIosWarning } from 'react-icons/io'
import { FaCheck } from 'react-icons/fa6'
import { useTranslation } from 'react-i18next'
import { Text } from '../../../../../components/ui'

interface LoanElligibilityProps {
  isEligible: boolean
  notEligibleReasons?: string[]
}

export const LoanElligibility: React.FC<LoanElligibilityProps> = ({
  isEligible,
  notEligibleReasons
}) => {
  const { t } = useTranslation('loanManagement')

  return (
    <div className='w-full flex flex-col mb-4'>
      <Text size='medium'>{t('home.new')} </Text>

      {isEligible ? (
        <>
          <div className='bg-green-100 mt-10 p-4 rounded-md'>
            <div className='flex '>
              <FaCheck fontSize='18' className='text-green-600 mr-4' />

              <div>
                <p className='text-xs md:text-sm font-semibold text-gray-600 mb-4'>
                  {t('home.eligible')}
                </p>
              </div>
            </div>

            <div className='w-full flex flex-col gap-2 mt-6'>
              <p className='text-xs md:text-sm font-semibold text-red-500'>
                {t('home.disclaimer')}:
              </p>
              <p className='text-xs md:text-sm font-light text-red-500'>{t('home.ensure')}</p>
            </div>
          </div>
        </>
      ) : (
        <div className='bg-red-100 mt-10 p-4 rounded-md'>
          <div className='flex'>
            <IoIosWarning fontSize='18' className='text-red-600 mr-4' />

            <div>
              <p className='text-xs md:text-sm font-semibold text-gray-600 mb-4'>
                {t('home.notEligible')}:
              </p>

              {notEligibleReasons?.map((reason, index) => (
                <p className='font-light text-gray-600 text-xs md:text-sm'>
                  <li key={index}> {reason} </li>
                </p>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
