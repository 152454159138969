import { type ApiResponse } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type GetInventoryRes } from '../../../../farmer/inventory/types'

export const getInventory = async (
  merchantId: number | null | undefined
): Promise<ApiResponse<GetInventoryRes>> => {
  return axios.get(`/inventory?merchant_id=${merchantId ?? ''}`)
}

export const useGetSPInventory = (
  merchantId: number | null | undefined
): UseQueryResult<ApiResponse<GetInventoryRes>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: [`inventories-${merchantId ?? ''}`],
    queryFn: async () => getInventory(merchantId),
    enabled: merchantId != null
  })
}
