import type React from 'react'
import { ScrollArea, Text } from 'components/ui'
import { Separator } from 'components/ui/separator'
import { ChevronDown } from 'lucide-react'
import { useStaticDataStore } from 'stores/useStaticDataStore'
import { type SoilCorrection } from 'types/soilCorrection'
import { useTranslation } from 'react-i18next'

interface OfficerSoilCorrectionDetailsProps {
  scr: SoilCorrection
}

export const OfficerSoilCorrectionDetails: React.FC<OfficerSoilCorrectionDetailsProps> = ({
  scr
}) => {
  const { t } = useTranslation('extensionOfficer')
  const { chemicals } = useStaticDataStore()

  return (
    <div className={'relative w-full h-[600px] flex flex-col items-center gap-2'}>
      <Text size={'medium'}>{t('soil.scr')}</Text>
      <Separator />
      <ScrollArea className='h-[560px] w-full border-b p-4'>
        <div className={'flex flex-col gap-4'}>
          <Text size={'medium'} variant={'secondary'}>
            {t('soil.gi')}
          </Text>
          <div className={'flex'}>
            <div className={'flex flex-1 flex-col'}>
              <Text size={'small'}>{t('soil.tc')}</Text>
              <Text className={'font-semibold'}>BWP {scr.total_cost}</Text>
            </div>
          </div>
          <div className={'flex flex-1 flex-col'}>
            <Text size={'small'}>{t('soil.ac')}</Text>
            <Text className={'font-semibold'}>BWP {scr.application_cost[0]}</Text>
          </div>
        </div>

        <div className={'flex flex-col gap-4 mt-6'}>
          <Text size={'medium'} variant={'secondary'}>
            {t('soil.ph')}
          </Text>
          {scr.ph_correction_recommendation == null ? (
            <Text size={'small'}>N/A</Text>
          ) : (
            <div className={'flex flex-wrap gap-8'}>
              {chemicals != null ? (
                <div className={'flex flex-[1_0_130px] flex-col'}>
                  <Text size={'small'}>{t('soil.fn')}</Text>
                  <Text className={'font-semibold'}>
                    {chemicals?.find(
                      (chemical) =>
                        scr.ph_correction_recommendation?.reg_no.trim() === chemical.code.trim()
                    )?.name ?? 'N/A'}
                  </Text>
                </div>
              ) : null}

              {chemicals != null ? (
                <div className={'flex flex-[1_0_130px] flex-col'}>
                  <Text size={'small'}>{t('soil.ki')}</Text>
                  <Text className={'font-semibold'}>
                    {chemicals?.find(
                      (chemical) =>
                        scr.ph_correction_recommendation?.reg_no.trim() === chemical.code.trim()
                    )?.key_ingredients ?? 'N/A'}
                  </Text>
                </div>
              ) : null}

              <div className={'flex flex-[1_0_130px] flex-col'}>
                <Text size={'small'}>{t('soil.nob')}</Text>
                <Text className={'font-semibold'}>
                  {scr.ph_correction_recommendation?.no_of_bags}
                </Text>
              </div>
              <div className={'flex flex-[1_0_130px] flex-col'}>
                <Text size={'small'}>{t('soil.app')}</Text>
                <Text className={'font-semibold'}>
                  {scr.ph_correction_recommendation?.application_rate_per_ha}
                </Text>
              </div>

              <div className={'flex flex-[1_0_130px] flex-col'}>
                <Text size={'small'}>Cost</Text>
                <Text className={'font-semibold'}>
                  BWP {scr.ph_correction_recommendation?.cost}
                </Text>
              </div>
            </div>
          )}
        </div>

        <div className={'flex flex-col gap-4 mt-6'}>
          <Text size={'medium'} variant={'secondary'}>
            {t('soil.sod')}
          </Text>
          {scr.sodium_correction_recommendation == null ? (
            <Text size={'small'}>N/A</Text>
          ) : (
            <div className={'flex flex-wrap gap-8'}>
              {chemicals != null ? (
                <div className={'flex flex-[1_0_130px] flex-col'}>
                  <Text size={'small'}>{t('soil.fn')}</Text>
                  <Text className={'font-semibold'}>
                    {chemicals?.find(
                      (chemical) =>
                        scr.sodium_correction_recommendation?.reg_no.trim() === chemical.code.trim()
                    )?.name ?? 'N/A'}
                  </Text>
                </div>
              ) : null}

              {chemicals != null ? (
                <div className={'flex flex-[1_0_130px] flex-col'}>
                  <Text size={'small'}>{t('soil.ki')}</Text>
                  <Text className={'font-semibold'}>
                    {chemicals?.find(
                      (chemical) =>
                        scr.sodium_correction_recommendation?.reg_no.trim() === chemical.code.trim()
                    )?.key_ingredients ?? 'N/A'}
                  </Text>
                </div>
              ) : null}

              <div className={'flex flex-[1_0_130px] flex-col'}>
                <Text size={'small'}>{t('soil.nob')}</Text>
                <Text className={'font-semibold'}>
                  {scr.sodium_correction_recommendation?.no_of_bags}
                </Text>
              </div>
              <div className={'flex flex-[1_0_130px] flex-col'}>
                <Text size={'small'}>{t('soil.app')}</Text>
                <Text className={'font-semibold'}>
                  {scr.sodium_correction_recommendation?.application_rate_per_ha}
                </Text>
              </div>

              <div className={'flex flex-[1_0_130px] flex-col'}>
                <Text size={'small'}>{t('soil.tc')}</Text>
                <Text className={'font-semibold'}>
                  BWP {scr.sodium_correction_recommendation?.cost}
                </Text>
              </div>
            </div>
          )}
        </div>

        <div className={'flex flex-col gap-4 mt-6'}>
          <Text size={'medium'} variant={'secondary'}>
            {t('soil.cal')}
          </Text>
          {scr.calcium_correction_recommendation == null ? (
            <Text size={'small'}>N/A</Text>
          ) : (
            <div className={'flex flex-wrap gap-8'}>
              {chemicals != null ? (
                <div className={'flex flex-[1_0_130px] flex-col'}>
                  <Text size={'small'}>{t('soil.fn')}</Text>
                  <Text className={'font-semibold'}>
                    {chemicals?.find(
                      (chemical) =>
                        scr.calcium_correction_recommendation?.reg_no.trim() ===
                        chemical.code.trim()
                    )?.name ?? 'N/A'}
                  </Text>
                </div>
              ) : null}

              {chemicals != null ? (
                <div className={'flex flex-[1_0_130px] flex-col'}>
                  <Text size={'small'}>{t('soil.ki')}</Text>
                  <Text className={'font-semibold'}>
                    {chemicals?.find(
                      (chemical) =>
                        scr.calcium_correction_recommendation?.reg_no.trim() ===
                        chemical.code.trim()
                    )?.key_ingredients ?? 'N/A'}
                  </Text>
                </div>
              ) : null}

              <div className={'flex flex-[1_0_130px] flex-col'}>
                <Text size={'small'}>{t('soil.nob')}</Text>
                <Text className={'font-semibold'}>
                  {scr.calcium_correction_recommendation?.no_of_bags}
                </Text>
              </div>
              <div className={'flex flex-[1_0_130px] flex-col'}>
                <Text size={'small'}>{t('soil.app')}</Text>
                <Text className={'font-semibold'}>
                  {scr.calcium_correction_recommendation?.application_rate_per_ha}
                </Text>
              </div>

              <div className={'flex flex-[1_0_130px] flex-col'}>
                <Text size={'small'}>{t('soil.tc')}</Text>
                <Text className={'font-semibold'}>
                  BWP {scr.calcium_correction_recommendation?.cost}
                </Text>
              </div>
            </div>
          )}
        </div>

        <div className={'flex flex-col gap-4 mt-6'}>
          <Text size={'medium'} variant={'secondary'}>
            {t('soil.mag')}
          </Text>
          {scr.magnesium_correction_recommendation == null ? (
            <Text size={'small'}>N/A</Text>
          ) : (
            <div className={'flex flex-wrap gap-8'}>
              {chemicals != null ? (
                <div className={'flex flex-[1_0_130px] flex-col'}>
                  <Text size={'small'}>{t('soil.fn')}</Text>
                  <Text className={'font-semibold'}>
                    {chemicals?.find(
                      (chemical) =>
                        scr.magnesium_correction_recommendation?.reg_no.trim() ===
                        chemical.code.trim()
                    )?.name ?? 'N/A'}
                  </Text>
                </div>
              ) : null}

              {chemicals != null ? (
                <div className={'flex flex-[1_0_130px] flex-col'}>
                  <Text size={'small'}>{t('soil.fn')}</Text>
                  <Text className={'font-semibold'}>
                    {chemicals?.find(
                      (chemical) =>
                        scr.magnesium_correction_recommendation?.reg_no.trim() ===
                        chemical.code.trim()
                    )?.key_ingredients ?? 'N/A'}
                  </Text>
                </div>
              ) : null}

              <div className={'flex flex-[1_0_130px] flex-col'}>
                <Text size={'small'}>{t('soil.nob')}</Text>
                <Text className={'font-semibold'}>
                  {scr.magnesium_correction_recommendation?.no_of_bags}
                </Text>
              </div>
              <div className={'flex flex-[1_0_130px] flex-col'}>
                <Text size={'small'}>{t('soil.app')}</Text>
                <Text className={'font-semibold'}>
                  {scr.magnesium_correction_recommendation?.application_rate_per_ha}
                </Text>
              </div>

              <div className={'flex flex-[1_0_130px] flex-col'}>
                <Text size={'small'}>{t('soil.tc')}</Text>
                <Text className={'font-semibold'}>
                  BWP {scr.magnesium_correction_recommendation?.cost}
                </Text>
              </div>
            </div>
          )}
        </div>
      </ScrollArea>
      <ChevronDown className={'absolute bottom-[-25px]'} />
    </div>
  )
}
