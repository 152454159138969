import { type Farm, type Lease } from 'types'

export const isFarm = (item: any): item is Farm => {
  return Boolean(item) && (item as Farm).farmer_id !== undefined
}
export const isLease = (item: any): item is Lease => {
  return Boolean(item) && (item as Lease).lease_id !== undefined
}

export const genderToText = (gender: string | null | unknown): string => {
  if (gender === 'M') {
    return 'Male'
  } else if (gender === 'F') {
    return 'Female'
  } else if (gender === null) {
    return 'company'
  } else {
    return 'N/A'
  }
}
