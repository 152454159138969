import type React from 'react'
import { motion } from 'framer-motion'
import { CTAButton } from '../Generic/CTAButton' // Adjust path accordingly
import { useTranslation } from 'react-i18next' // Adjust path accordingly

interface FooterCTAProps {
  backgroundImage: string
  headerText: string
  buttonText: string
  navigationLink: string
}

export const FooterCTA: React.FC<FooterCTAProps> = ({
  backgroundImage,
  headerText,
  buttonText,
  navigationLink
}) => {
  const { t } = useTranslation('landing')
  return (
    <div className='bg-blue-50 w-full relative h-fit-content'>
      <div className='container mx-auto flex flex-col md:flex-row py-20 md:py-40 lg:py-60 px-10 justify-center w-full'>
        <motion.div
          className='absolute inset-0 bg-cover bg-center'
          style={{ backgroundImage: `url(${backgroundImage})` }}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        ></motion.div>

        <div className='absolute inset-0 bg-black opacity-80'></div>

        <motion.div
          className='relative flex flex-col justify-center items-center h-full text-white text-center md:px-40'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className='text-4xl md:text-5xl lg:text-6xl font-bold mb-4 text-white leading-relaxed tracking-wide landing-page'>
            {headerText}
          </h1>

          <div className='container mx-auto flex justify-center items-center mt-12'>
            <CTAButton label={t('home.reg')} to='/registration' />
          </div>
        </motion.div>
      </div>
    </div>
  )
}