import type React from 'react'
import { useState } from 'react'
import { useStaticDataStore } from '../../../../../../../stores/useStaticDataStore'
import { Error } from '../../../../../../../components/Errors/Error'
import { type Pesticides } from '../../../../../../../types/static'
import { AdjuvantsPesticidesTable } from '../tables/AdjuvantPesticidesTable'
import { adjuvantColumns } from '../../weeding/tables/adjuvant-columns'
import { useCroppingPlanStore } from '../../../../stores/useCroppingPlanStore'

interface AdjuvantSelectionProps {
  pesticides: Pesticides[]
  onNext: () => void
  onCancel: () => void
}

export const PestAdjuvantSelection: React.FC<AdjuvantSelectionProps> = ({
  pesticides,
  onCancel,
  onNext
}) => {
  const { adjuvants } = useStaticDataStore()
  const [currentPesticide, setCurrentPesticide] = useState(0)
  const { pest } = useCroppingPlanStore()

  if (adjuvants == null) {
    return <Error />
  }

  const handleNext = (): void => {
    setCurrentPesticide((prevState) => prevState + 1)
  }

  const handleSubmit = (): (() => void) | undefined => {
    if (currentPesticide === pesticides.length - 1) {
      if (pest == null) {
        return onNext
      } else {
        return onNext
      }
    } else return undefined
  }

  const handleBack = (): void => {
    if (currentPesticide === 0) {
      onCancel()
    } else {
      setCurrentPesticide((prevState) => prevState - 1)
    }
  }

  const returnComponent = (index: number): React.ReactNode => {
    return (
      <AdjuvantsPesticidesTable
        key={index}
        next={handleNext}
        back={handleBack}
        submit={handleSubmit()}
        pesticide={pesticides[index]}
        pest={pest}
        data={adjuvants}
        columns={adjuvantColumns}
      />
    )
  }

  return returnComponent(currentPesticide)
}
