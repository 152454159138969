import { ThemeProvider } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import logo from 'assets/logos/moa.svg'
import { Error as ErrorPage } from 'components/Errors/Error'
import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Toaster } from 'react-hot-toast'
import { RouterProvider } from 'react-router-dom'
import { routes } from 'routes'
import { useOtpStore } from 'stores/useOtpStore'
import { theme } from 'styles/Theme'
import { ThemeModeProvider } from 'components/ui/themeProvider'
import { I18nextProvider } from 'react-i18next'
import instance from '../config/i18n.config'
import { isAxiosError } from 'axios'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import * as Sentry from '@sentry/react'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (isAxiosError(error) && failureCount <= 1) {
          if (error?.response?.status === 404) {
            return false
          } else if (error?.response?.status === 500) {
            return false
          } else if (error?.response?.status === 503) {
            return false
          } else if (error?.response?.status === 501) {
            return false
          } else if (error?.response?.status === 504) {
            return false
          } else if (error?.response?.status === 401) {
            return true
          } else if (error?.response?.status === 403) {
            return true
          }
          return false
        } else if (failureCount > 1) {
          return true
        }
        return true
      }
    },
    mutations: {
      retry: false
    }
  }
})

export const AppProvider: React.FC = () => {
  const { timeOfFirstResend, setExpiryTimeOfResend, setTimeOfFirstResend, setResendCounter } =
    useOtpStore()

  // reset the otp timer after day has passed since the first resend
  useEffect(() => {
    const currentTime = Date.now()
    if (timeOfFirstResend === null) {
      return
    }
    const timerDatePlusOneDay = new Date(timeOfFirstResend).getTime() + 24 * 60 * 60 * 1000

    if (currentTime > timerDatePlusOneDay) {
      setTimeOfFirstResend(null)
      setExpiryTimeOfResend(null)
      setResendCounter(0)
    }
  }, [timeOfFirstResend])

  const i18n = instance()

  const logError = (error: Error): void => {
    Sentry.captureException(error)
  }

  return (
    <React.Suspense
      fallback={
        <div className='flex items-center justify-center w-screen h-screen'>
          <img
            role={'presentation'}
            className='h-[4rem] md:h-[7rem] xl:h-[7.5rem] sm:h-[clamp(3rem,10vw,4rem)] hover:transform hover:scale-[1.005] cursor-pointer hover:duration-500 animate-bounce transition-transform'
            src={logo}
            alt={logo}
          />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorPage} onError={logError}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <ThemeModeProvider
              attribute='class'
              defaultTheme='dark'
              enableSystem
              disableTransitionOnChange
            >
              <I18nextProvider i18n={i18n}>
                <RouterProvider router={routes} />
                <Toaster
                  toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                      background: '#363636',
                      color: '#fff'
                    },

                    // Default options for specific types
                    success: {
                      duration: 3000,
                      style: { color: '#fff' }
                    }
                  }}
                />
              </I18nextProvider>
            </ThemeModeProvider>
          </ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ErrorBoundary>
    </React.Suspense>
  )
}
