import type React from 'react'
import { type Lease } from 'types'
import { Button, Input } from 'components/ui'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

interface FarmInformationProps {
  lease: Lease
  // onReject: () => void
  onApprove: () => void
}

export const LeaseInformation: React.FC<FarmInformationProps> = ({ lease, onApprove }) => {
  const { t } = useTranslation('extensionOfficer')
  return (
    <div className={'flex flex-col gap-4 md:gap6 lg:gap-8'}>
      <h3>Review Lease details</h3>
      <div className={'grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 lg:gap-8'}>
        <Input
          withLabel
          label={t('land.ha')}
          value={lease?.leased_size.toString()}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('land.lsa')}
          value={dayjs(lease?.commencement_date).format('YYYY-MM-DD')}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('land.led')}
          value={
            lease.new_termination_date != null
              ? dayjs(lease.new_termination_date).format('DD-MM-YYYY')
              : dayjs(lease.expiry_date).format('DD-MM-YYYY')
          }
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('land.ext')}
          value={lease?.extension_area}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('land.vil')}
          value={lease?.village}
          classLabel={'bg-card'}
          disabled
        />
      </div>

      <div className={'flex flex-row justify-end gap-8'}>
        {/* <Button variant={'destructive'} onClick={onReject} size={'xs'} color={'error'}> */}
        {/*  Reject */}
        {/* </Button> */}
        <Button variant={'success'} onClick={onApprove} size={'xs'} color={'success'}>
          {t('land.accept')}
        </Button>
      </div>
    </div>
  )
}