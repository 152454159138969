import type * as React from 'react'
import {
  Input,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
  Text
} from 'components/ui'
import { defaultCountries, parseCountry, FlagEmoji, usePhoneInput } from 'react-international-phone'

import { type PhoneInputFieldProps } from '../Form'
export const Phone: React.FC<PhoneInputFieldProps> = ({
  value,
  disabled = false,
  onChange,
  ...restProps
}) => {
  const { phone, handlePhoneValueChange, country, setCountry } = usePhoneInput({
    defaultCountry: 'bw',
    forceDialCode: true,
    value,
    countries: defaultCountries,
    onChange: (data) => {
      onChange(data.phone)
    }
  })

  return (
    <div className={'w-full flex gap-2 items-center'}>
      <Select value={country} onValueChange={setCountry} disabled={disabled}>
        <SelectTrigger className='w-[100px]' disabled>
          <SelectValue className={'text-foreground'} defaultValue={country} />
        </SelectTrigger>
        <SelectContent className={'bg-card'}>
          <SelectGroup className={'bg-card'}>
            <SelectLabel>Countries</SelectLabel>
            {defaultCountries.map((c) => {
              const selectedCountry = parseCountry(c)
              return (
                <SelectItem
                  className={'w-[200px]'}
                  key={selectedCountry.iso2}
                  value={selectedCountry.iso2}
                  defaultValue={'bw'}
                  disabled
                >
                  <div className={'w-[50px] flex items-center gap-[5px]'}>
                    <FlagEmoji iso2={selectedCountry.iso2} style={{ marginRight: '' }} />
                    <Text className={'text-foreground capitalize'}>{selectedCountry.iso2}</Text>
                  </div>
                </SelectItem>
              )
            })}
          </SelectGroup>
        </SelectContent>
      </Select>
      <Input
        type={'tel'}
        placeholder={'Phone Number'}
        value={phone}
        disabled={disabled}
        onChange={handlePhoneValueChange}
      />
    </div>
  )
}
