import type { ColumnDef } from '@tanstack/react-table'
import type { CroppingPlan } from 'types'
import { DataTableColumnHeader } from '../../../../../components/Data-Table/datatable-header'

export const labCroppingPlansColumns = (
  t: (key: string) => string
): Array<ColumnDef<CroppingPlan>> => [
  {
    id: 'plough season',
    accessorFn: (row) => row.plough_season,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('plough season')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Plough Season' />
  },
  {
    id: 'cropping plan id',
    accessorFn: (row) => row.cropping_plan_id,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('cropping plan id')}</div>,
    header: t('cp.cpid')
  },
  {
    id: 'farmer id',
    accessorFn: (row) => row.farmer_id,
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('farmer id')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('cp.fid')} />
  },
  {
    id: 'farm id',
    accessorFn: (row) => row.farm_id,
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('farm id')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('cp.farmID')} />
  },
  {
    id: 'crop',
    accessorFn: (row) => row.crop_name,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('crop')}</div>,
    header: t('cp.crop')
  },

  {
    id: 'hectarage',
    accessorFn: (row) => row.hectarage,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('hectarage')}</div>,
    header: t('cp.ha')
  },
  {
    accessorKey: 'total costs',
    accessorFn: (row) => row.total_cost,
    enableHiding: false,
    header: t('cp.tCost'),
    cell: ({ row }) => {
      const amount = parseFloat(String(row.getValue('total costs')).replace(',', ''))
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'BWP'
      }).format(amount)

      return <div className='font-medium'>{formatted}</div>
    }
  }
]