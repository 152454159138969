import { type SoilSampleAnalysis, type SoilSampleRequest } from 'types'
import type { SoilCorrection } from '../../../../types/soilCorrection'
import { useGetSSR } from '../../ssr/api/getSSR'
import { useGetSSA } from '../../ssr/api/getSSA'
import { useFarmerStore } from '../../../../stores/useFarmerStore'
import { useGetSoilCorrections } from '../api/getSoilCorrection'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  type SaveCorrectionDTO,
  type SaveRecommendationFarmDTO,
  type SoilCorrectionRecommendation,
  type UpdateCorrectionDTO,
  type UpdateRecommendationFarmDTO
} from '../types'
import { saveSoilCorrection } from '../api/saveCorrection'
import { updateSoilCorrection } from '../api/updateCorrection'
import { AxiosError } from 'axios'
import { mapCmsErrorToMessage } from '../../../../utils/apiErrors'
import { useToast } from '../../../../components/ui'

interface UseSoilCorrection {
  ssr: SoilSampleRequest | undefined
  ssa: SoilSampleAnalysis | undefined
  scr: SoilCorrection | undefined
  updateSavedSoilCorrection: (
    correction: SoilCorrection,
    recommendation: SoilCorrectionRecommendation,
    farm: UpdateRecommendationFarmDTO,
    next: () => void
  ) => void
  createSoilCorrection: (
    recommendation: SoilCorrectionRecommendation,
    farm: SaveRecommendationFarmDTO,
    next: () => void
  ) => void
  isLoading: boolean
  hasValidSSR: boolean
  error: boolean
}

export const useGetSoilCorrection = (
  farmId?: string | number | null | undefined,
  leaseId?: string | number | null | undefined
): UseSoilCorrection => {
  const { farmer } = useFarmerStore()
  const queryClient = useQueryClient()
  const { toast } = useToast()

  const createSoilCorrectionMutation = useMutation({
    mutationFn: async (scDto: SaveCorrectionDTO) => {
      return saveSoilCorrection(scDto)
    }
  })

  const updateSoilCorrectionMutation = useMutation({
    mutationFn: async (scDto: UpdateCorrectionDTO) => {
      return updateSoilCorrection(scDto)
    }
  })

  const createSoilCorrection = (
    recommendation: SoilCorrectionRecommendation,
    farm: SaveRecommendationFarmDTO,
    next: () => void
  ): void => {
    createSoilCorrectionMutation.mutate(
      {
        farmer_id: farmer?.farmer_id,
        ca_correction:
          recommendation.calcium_correction_recommendation == null
            ? null
            : {
                fertilizer_id: recommendation.calcium_correction_recommendation?.reg_no,
                application_rate_per_ha:
                  recommendation.calcium_correction_recommendation?.application_rate_per_ha,
                no_of_bags: recommendation.calcium_correction_recommendation?.no_bags
              },
        mg_correction:
          recommendation.magnesium_correction_recommendation == null
            ? null
            : {
                fertilizer_id: recommendation.magnesium_correction_recommendation?.reg_no,
                application_rate_per_ha:
                  recommendation.magnesium_correction_recommendation?.application_rate_per_ha,
                no_of_bags: recommendation.magnesium_correction_recommendation?.no_bags
              },
        na_correction:
          recommendation.sodium_correction_recommendation == null
            ? null
            : {
                fertilizer_id: recommendation.sodium_correction_recommendation?.reg_no,
                application_rate_per_ha:
                  recommendation.sodium_correction_recommendation?.application_rate_per_ha,
                no_of_bags: recommendation.sodium_correction_recommendation?.no_bags
              },
        ph_correction:
          recommendation.ph_correction_recommendation == null
            ? null
            : {
                fertilizer_id: recommendation.ph_correction_recommendation?.reg_no,
                application_rate_per_ha:
                  recommendation.ph_correction_recommendation?.application_rate_per_ha,
                no_of_bags: recommendation.ph_correction_recommendation?.no_bags
              },
        ssr_id: farm.ssrId,
        application_method: 'A1',
        lease_id: farm.leaseId,
        farm_id: farm.farmId,
        last_updated_by: farmer?.user_id
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Soil Correction Created Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['soil-correction', farm.ssrId] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  const updateSavedSoilCorrection = (
    correction: SoilCorrection,
    recommendation: SoilCorrectionRecommendation,
    farm: UpdateRecommendationFarmDTO,
    next: () => void
  ): void => {
    updateSoilCorrectionMutation.mutate(
      {
        farmer_id: farmer?.farmer_id,
        ca_correction:
          recommendation.calcium_correction_recommendation == null
            ? null
            : {
                scrd_id: correction.calcium_correction_recommendation?.scrd_id,
                fertilizer_id: recommendation.calcium_correction_recommendation?.reg_no,
                application_rate_per_ha:
                  recommendation.calcium_correction_recommendation?.application_rate_per_ha,
                no_of_bags: recommendation.calcium_correction_recommendation?.no_bags
              },
        mg_correction:
          recommendation.magnesium_correction_recommendation == null
            ? null
            : {
                scrd_id: correction.magnesium_correction_recommendation?.scrd_id,
                fertilizer_id: recommendation.magnesium_correction_recommendation?.reg_no,
                application_rate_per_ha:
                  recommendation.magnesium_correction_recommendation?.application_rate_per_ha,
                no_of_bags: recommendation.magnesium_correction_recommendation?.no_bags
              },
        na_correction:
          recommendation.sodium_correction_recommendation == null
            ? null
            : {
                scrd_id: correction.sodium_correction_recommendation?.scrd_id,
                fertilizer_id: recommendation.sodium_correction_recommendation?.reg_no,
                application_rate_per_ha:
                  recommendation.sodium_correction_recommendation?.application_rate_per_ha,
                no_of_bags: recommendation.sodium_correction_recommendation?.no_bags
              },
        ph_correction:
          recommendation.ph_correction_recommendation == null
            ? null
            : {
                scrd_id: correction.ph_correction_recommendation?.scrd_id,
                fertilizer_id: recommendation.ph_correction_recommendation?.reg_no,
                application_rate_per_ha:
                  recommendation.ph_correction_recommendation?.application_rate_per_ha,
                no_of_bags: recommendation.ph_correction_recommendation?.no_bags
              },
        scr_id: farm?.scrId,
        ssr_id: farm?.ssrId,
        application_method: 'A1',
        lease_id: farm?.leaseId,
        farm_id: farm?.farmId,
        last_updated_by: farmer?.user_id
      },
      {
        onSuccess: (result) => {
          if (result.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Soil Correction Updated Successfully.'
            })
            void queryClient.invalidateQueries({ queryKey: ['soil-correction', farm.ssrId] })
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err?.response?.status.toString() ?? '')
            })
          } else {
            const err1 = err as Error
            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(err1.message)
            })
          }
        }
      }
    )
  }

  const {
    isLoading: isLoadingSSR,
    isInitialLoading,
    data: ssr,
    error: ssrError
  } = useGetSSR(farmId, farmer?.farmer_id, leaseId)

  const {
    isInitialLoading: isLoadingSSA,
    data: ssa,
    error: ssaError
  } = useGetSSA(farmId, farmer?.farmer_id, ssr?.data[ssr?.data.length - 1].ssr_id)

  const {
    isInitialLoading: isLoadingSCR,
    data: scr,
    error: scrError
  } = useGetSoilCorrections(
    farmer?.farmer_id,
    ssr?.data[ssr?.data.length - 1].ssr_id,
    farmId,
    leaseId
  )

  return {
    ssr: ssr?.data[ssr?.data.length - 1],
    ssa: ssa?.data[ssa?.data.length - 1],
    scr: scr?.data[scr?.data.length - 1],
    hasValidSSR: ssr?.data[ssr?.data.length - 1].status_name === 'COMPLETED',
    isLoading:
      isLoadingSSR ||
      isLoadingSCR ||
      isInitialLoading ||
      isLoadingSSA ||
      updateSoilCorrectionMutation.isLoading ||
      createSoilCorrectionMutation.isLoading,
    createSoilCorrection,
    updateSavedSoilCorrection,
    error:
      (ssrError != null && ssrError?.response?.status !== 404) ||
      (ssaError != null && ssaError?.response?.status !== 404) ||
      (scrError != null && scrError?.response?.status !== 404)
  }
}
