import type React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Text } from '../../../../../../../components/ui'

interface AdjuvantsConfirmationProps {
  onClickNo: () => void
  onClickYes: () => void
}

export const AdjuvantsConfirmation: React.FC<AdjuvantsConfirmationProps> = ({
  onClickNo,
  onClickYes
}) => {
  const { t } = useTranslation('landManagement')

  return (
    <div className={'w-full flex flex-col gap-8 py-8 px-4 justify-center items-center'}>
      <Text className={'text-center'} size={'medium'}>
        Are you going to use any Adjuvants?
      </Text>
      <div className={'w-full flex justify-between gap-4'}>
        <Button size={'lg'} variant={'secondary'} onClick={onClickNo} className={'w-full'}>
          {t('deed.no')}
        </Button>
        <Button size={'lg'} onClick={onClickYes} className={'w-full'}>
          {t('deed.yes')}
        </Button>
      </div>
    </div>
  )
}
