import { ResendOtp } from 'components/Generic/ResendOtp'
import { getTimerTime } from 'features/authentication/utils/otp'
import type React from 'react'
import { useEffect } from 'react'
import { useTimer } from 'react-timer-hook'
import { useOtpStore } from 'stores/useOtpStore'
import {
  Button,
  Text,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from 'components/ui'
import { useSuperTokens } from '../../hooks/useSuperTokens'
import { clearLoginAttemptInfo } from 'supertokens-auth-react/recipe/passwordless'

import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { InputOTP, InputOTPGroup, InputOTPSlot } from 'components/ui/input-otp'
import { useToast } from 'components/ui/use-toast'
import { useTranslation } from 'react-i18next'

interface LoginOtpFormProps {
  className?: string
  number: string
  clearLogin: React.Dispatch<React.SetStateAction<boolean>>
}

const FormSchema = z.object({
  otp: z.string().min(6, {
    message: 'Your one-time password must be 6 characters.'
  })
})

export const LoginOtpForm: React.FC<LoginOtpFormProps> = ({ className, number, clearLogin }) => {
  const {
    setExpiryTimeOfResend,
    resendCounter,
    expiryTimeOfResend,
    setTimeOfFirstResend,
    setResendCounter
  } = useOtpStore()
  const { handleOTPInput, resendOtp, isLoading } = useSuperTokens()
  const { hours, minutes, seconds, isRunning, restart } = useTimer({
    expiryTimestamp:
      expiryTimeOfResend !== null ? new Date(expiryTimeOfResend) : new Date(Date.now())
  })
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      otp: ''
    }
  })

  const hiddenNumber = String(number).slice(-3)

  const handleGoToLogin = async (): Promise<void> => {
    // clear login info
    await clearLoginAttemptInfo().then(() => {
      clearLogin(false)
    })
  }
  const onSubmit = async (data: z.infer<typeof FormSchema>): Promise<void> => {
    await handleOTPInput(data.otp)
  }

  useEffect(() => {
    if (
      resendCounter > 1 &&
      expiryTimeOfResend !== null &&
      new Date(Date.now()) < expiryTimeOfResend
    ) {
      restart(expiryTimeOfResend)
    }
  }, [expiryTimeOfResend, resendCounter])

  const handleResendOtp = async (): Promise<void> => {
    if (resendCounter === 0) {
      setTimeOfFirstResend(new Date(Date.now()))
    }

    const counter = resendCounter + 1
    setResendCounter(resendCounter + 1)

    setExpiryTimeOfResend(getTimerTime(new Date(Date.now()), counter))
    restart(getTimerTime(new Date(Date.now()), counter))

    await resendOtp()
    form.resetField('otp')
  }

  const { t } = useTranslation('genericTranslation')

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className={'flex flex-col gap-4 sm:gap-8 items-center'}
      >
        <Text size={'large'}>{t('otp.otp')}</Text>
        <FormField
          control={form.control}
          name='otp'
          render={({ field }) => (
            <FormItem className='flex flex-col gap-2 items-center'>
              <Text className={'max-w-[60%] text-center'}>
                {t('otp.prompt')}
                <span className={'text-primary'}>*****{hiddenNumber}</span>
              </Text>
              <FormControl>
                <InputOTP maxLength={6} {...field}>
                  <InputOTPGroup>
                    <InputOTPSlot index={0} />
                    <InputOTPSlot index={1} />
                    <InputOTPSlot index={2} />
                    <InputOTPSlot index={3} />
                    <InputOTPSlot index={4} />
                    <InputOTPSlot index={5} />
                  </InputOTPGroup>
                </InputOTP>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className={'flex items-center text-center flex-col gap-2'}>
          <p className={'text-center'}>{t('otp.request')}</p>
          <ResendOtp
            seconds={seconds}
            minutes={minutes}
            hours={hours}
            isDisabled={(isRunning && seconds + minutes + hours > 0) || isLoading}
            onResend={handleResendOtp}
          />
        </div>

        <div className='w-full flex justify-center'>
          <Button
            variant={'success'}
            type={'submit'}
            onClick={form.handleSubmit(onSubmit)}
            size={'lg'}
            disabled={isLoading}
            loading={isLoading}
          >
            {t('otp.verify')}
          </Button>
        </div>
        <div className={'w-full flex justify-center'}>
          <Button type={'button'} variant={'link'} onClick={handleGoToLogin} size={'sm'}>
            {t('otp.login')}
          </Button>
        </div>
      </form>
    </Form>
  )
}