import { Lab } from 'components/Auth/Lab'
import { SoilSampleAnalysis } from 'features/lab/ssr/routes/SoilSampleAnalysis'
import { SoilSampleRequest } from 'features/lab/ssr/routes/SoilSampleRequest'
import { SoilSampleRequests } from 'features/lab/ssr/routes/SoilSampleRequests'
import { Navigate } from 'react-router-dom'
import { ServiceProviderProfile } from '../features/profile/routes/ServiceProvider'
import { ChangePassword } from '../components/Change-Password/ChangePassword'
import { PasswordProtectedRoute } from 'components/Change-Password/PasswordProtectedRoute'

export const labRoutes = {
  path: '/soil-lab',
  element: <Lab />,
  children: [
    { index: true, element: <PasswordProtectedRoute children={<SoilSampleRequests />} /> },
    { path: '/soil-lab/change-password', element: <ChangePassword /> },
    {
      path: '/soil-lab/request/:farmerId/:farmId/:ssrId',
      element: <PasswordProtectedRoute children={<SoilSampleRequest />} />
    },
    {
      path: '/soil-lab/analyses',
      element: <PasswordProtectedRoute children={<SoilSampleRequests />} />
    },
    {
      path: '/soil-lab/analyses/:farmerId/:farmId/:ssrId',
      element: <PasswordProtectedRoute children={<SoilSampleAnalysis />} />
    },
    {
      path: '/soil-lab/profile',
      element: <PasswordProtectedRoute children={<ServiceProviderProfile />} />
    },
    { path: '*', element: <PasswordProtectedRoute children={<Navigate to={'.'} />} /> }
  ]
}
