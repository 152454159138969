import type React from 'react'
import { Card, Loader, Text } from '../../../../components/ui'
import { useSearchParams } from 'react-router-dom'
import { NotEligible } from '../../components/NotEligible'
import { NotFound } from '../../../../components/Errors/NotFound'
import { ErrorPage } from '../../../../components/Errors/ErrorPage'
import { useGetVoucherEligibility } from '../../api/getVoucherEligibility'
import { useStaticDataStore } from '../../../../stores/useStaticDataStore'
import { type ChemicalDealerCroppingPlanData } from '../../../../types'
import { useTranslation } from 'react-i18next'
import { ChemicalsTable } from '../components/ChemicalsTable'
import { chemicalsColumns } from '../components/chemicals-columns'
import { filteredChemicals } from '../../utils/filteredchemicals'
import { usePloughSeason } from '../../../../hooks/usePloughSeason'

export const ChemicalsVoucherRedemption: React.FC = () => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation('merchant')
  const query = searchParams.get('omang')
  const { data, isLoading, error } = useGetVoucherEligibility(query)
  const { adjuvants, pesticides, fungicides, herbicides } = useStaticDataStore()
  const { isCurrentSeason, isLoading: isLoadingSeason } = usePloughSeason()
  if (query == null) {
    return <NotFound />
  }

  if (isLoading || isLoadingSeason) {
    return (
      <div className={'grid place-items-center min-h-[30%] h-full flex-1'}>
        <Loader />
      </div>
    )
  }

  if (error != null) {
    return <ErrorPage />
  }

  if (data?.data.exists === false) {
    return <NotEligible />
  }
  const chemicalsToShow: ChemicalDealerCroppingPlanData[] = (
    data?.data.grant_cropping_plans_data as ChemicalDealerCroppingPlanData[]
  ).filter((crops) => ({
    crop_id: crops.crop_id,
    crop_type: crops.crop_type,
    chemicals: crops.chemicals
  }))

  const filteredChemicalData = chemicalsToShow.map((crops) => ({
    ...crops,
    ...filteredChemicals(crops, pesticides, fungicides, herbicides, adjuvants)
  }))

  const uniqueChemicals = new Set<string>()
  const combinedChemicals = filteredChemicalData.flatMap((cropData) => [
    ...(cropData.pesticides
      ?.filter(
        (pesticide) =>
          isCurrentSeason(pesticide.plough_season) &&
          !uniqueChemicals.has(pesticide.registration_no) &&
          uniqueChemicals.add(pesticide.registration_no)
      )
      .map((pesticide) => ({ ...pesticide, type: 'pesticide' })) ?? []),
    ...(cropData.herbicides
      ?.filter(
        (herbicide) =>
          isCurrentSeason(herbicide.plough_season) &&
          !uniqueChemicals.has(herbicide.registration_no) &&
          uniqueChemicals.add(herbicide.registration_no)
      )
      .map((herbicide) => ({ ...herbicide, type: 'herbicide' })) ?? []),
    ...(cropData.adjuvants
      ?.filter(
        (adjuvant) =>
          isCurrentSeason(adjuvant.plough_season) &&
          !uniqueChemicals.has(adjuvant.registration_no) &&
          uniqueChemicals.add(adjuvant.registration_no)
      )
      .map((adjuvant) => ({ ...adjuvant, type: 'adjuvant' })) ?? [])
  ])

  return (
    <div
      className={
        'md:max-w-[800px] lg:max-w-[1200px] xl:max-w-[1700px] w-full flex-col gap-4  flex justify-center mx-auto px-0 py-4'
      }
    >
      <Text size={'large'}>Chemical Voucher Redemption</Text>
      <Card
        className={
          'w-full flex flex-col bg-card gap-4 md:gap-6 lg:gap-8 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
        }
      >
        <Text size={'medium'} variant={'bodyTextLight'}>
          Below are a list of chemicals eligible for redemption.
        </Text>
        <Text size={'body'} variant={'bodyTextLight'}>
          Please select all applicable chemicals to be bought from the table below.
        </Text>
        <ChemicalsTable columns={chemicalsColumns()} data={combinedChemicals} />
      </Card>
    </div>
  )
}
