import { AxiosError, type AxiosResponse } from 'axios'
import { getLease } from 'features/officer/lease-management/api/getLease'
import { useState } from 'react'
import { type ApiResponse, type Lease } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'

interface UseSearchLease {
  isLoading: boolean
  error: string | null
  searchLease: (query: string) => Promise<void>
  data: Lease[] | null
}

export const useSearchLease = (officerId: number | null | undefined): UseSearchLease => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<Lease[] | null>(null)

  const searchLease = async (query: string): Promise<void> => {
    setData(null)
    setError(null)
    setIsLoading(true)
    try {
      const response = await getLease(query, officerId)
      if (response.status === 200) {
        const leases = response.data.filter((lease) => {
          return lease.status_desc === 'ACCEPTED BY LESSEE'
        })
        if (leases.length === 0) {
          setData([])
        } else {
          setData(leases)
        }
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const errorMsg = err?.response as AxiosResponse<ApiResponse<[]>>
        setError(
          mapCmsErrorToMessage(
            errorMsg.status.toString() ?? err.code ?? errorMsg.data.message ?? ''
          )
        )
      } else {
        const errorMsg = err as Error
        setError(mapCmsErrorToMessage(errorMsg.message))
      }
    } finally {
      setIsLoading(false)
    }
  }

  return { error, isLoading, searchLease, data }
}
