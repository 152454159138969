import type { ColumnDef } from '@tanstack/react-table'
import { Checkbox } from 'components/ui/checkbox'
import { DataTableColumnHeader } from 'components/Data-Table/datatable-header'
import { type Fungicides } from '../../../../../../../types/static'

export const fungicidesColumn: Array<ColumnDef<Fungicides>> = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllRowsSelected() || (table.getIsSomeRowsSelected() && 'indeterminate')}
        onCheckedChange={(value) => {
          table.toggleAllRowsSelected(!!value)
        }}
        aria-label='Select all'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => {
          row.toggleSelected(!!value)
        }}
        aria-label='Select row'
        disabled={!row.getCanSelect()}
      />
    ),
    enableSorting: false,
    enableHiding: false
  },
  {
    id: 'chemical name',
    accessorFn: (row) => row.fungicide_name,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('chemical name') ?? 'N/A'}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Name' />
  },
  {
    id: 'active ingredient name',
    accessorFn: (row) => row.active_ingredient,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => (
      <div className='uppercase'>{row.getValue('active ingredient name') ?? 'N/A'}</div>
    ),
    header: ({ column }) => <DataTableColumnHeader column={column} title='Active Ingredient' />
  },
  {
    id: 'fungicide name',
    accessorFn: (row) => row.fungicide_name,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('fungicide name') ?? 'N/A'}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Fungicide Name' />
  },
  {
    id: 'disease name',
    accessorFn: (row) => row.disease_name,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('disease name') ?? 'N/A'}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Disease Name' />
  },
  {
    id: 'application rate',
    accessorFn: (row) => row.application_rate,
    enableHiding: true,

    cell: ({ row }) => <div className='uppercase'>{row.getValue('application rate') ?? 'N/A'}</div>,
    header: 'Application Rate'
  },
  {
    id: 'price',
    accessorFn: (row) => row.price,
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue('price'))
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'BWP'
      }).format(amount)

      return <div className='font-medium uppercase'>{formatted}</div>
    },
    header: ({ column }) => <DataTableColumnHeader column={column} title='price' />
  }
]
