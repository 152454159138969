import { Navigate } from 'react-router-dom'
import { ServiceProviderProfile } from '../features/profile/routes/ServiceProvider'
import { SearchEligibility } from '../features/merchant/components/SearchEligibility'
import { ChangePassword } from '../components/Change-Password/ChangePassword'
import { PasswordProtectedRoute } from 'components/Change-Password/PasswordProtectedRoute'
import { ChemicalSeeds } from '../components/Auth/AgroChemicalSeedDealers'
import { ChemicalSeedsVoucherRedemption } from '../features/merchant/chemical-seed-dealer/routes/ChemicalSeedsVoucherRedepmetion'
import { ChemicalSeedReport } from '../features/merchant/chemical-seed-dealer/routes/ChemicalSeedReport'

export const chemicalseeddealerRoutes = {
  path: '/chemical-seed-dealer',
  element: <ChemicalSeeds />,
  children: [
    {
      index: true,
      element: (
        <PasswordProtectedRoute
          children={<SearchEligibility route={'/chemical-seed-dealer/redeem'} />}
        />
      )
    },
    {
      path: '/chemical-seed-dealer/profile',
      element: <PasswordProtectedRoute children={<ServiceProviderProfile />} />
    },
    { path: '/chemical-seed-dealer/change-password', element: <ChangePassword /> },
    {
      path: '/chemical-seed-dealer/redeem',
      element: <PasswordProtectedRoute children={<ChemicalSeedsVoucherRedemption />} />
    },
    {
      path: '/chemical-seed-dealer/reports',
      element: <PasswordProtectedRoute children={<ChemicalSeedReport />} />
    },
    {
      path: '/chemical-seed-dealer/*',
      element: <PasswordProtectedRoute children={<Navigate to={'/chemical-seed-dealer'} />} />
    },
    { path: '*', element: <PasswordProtectedRoute children={<Navigate to={'.'} />} /> }
  ]
}
