import z, { string, coerce, array, boolean, object } from 'zod'
import { type CroppingPlan, type Farm, type Lease } from 'types'
import React, { useEffect } from 'react'
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Text
} from 'components/ui'
import { useFieldArray, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Checkbox } from 'components/ui/checkbox'
import { useOtpVerification } from 'hooks/useOtpVerification'
import { toast } from 'react-hot-toast'
import { type SelectedCroppingPlans } from '../../types'
import { useTranslation } from 'react-i18next'

interface Base {
  croppingPlans: CroppingPlan[]
  setCroppingPlans: React.Dispatch<React.SetStateAction<SelectedCroppingPlans[]>>
  cancel: () => void
  next: () => void
}

interface WithFarm extends Base {
  farm: Farm
  lease?: never
}

interface WithLease extends Base {
  farm?: never
  lease: Lease
}

type CroppingPlanProps = WithLease | WithFarm

const CroppingPlanSchema = object({
  hectarage: coerce.number(),
  cropping_plan_id: coerce.number(),
  crop_name: string(),
  family_name: string()
}).readonly()

const CroppingPlansSchema = object({
  croppingPlan: boolean(),
  data: CroppingPlanSchema
})

const FormSchema = object({
  croppingPlans: array(CroppingPlansSchema).min(1, 'At least on cropping plan should be selected.')
}).superRefine((data, ctx) => {
  if (!data.croppingPlans.some((item) => item.croppingPlan)) {
    ctx.addIssue({
      code: z.ZodIssueCode.too_small,
      minimum: 1,
      type: 'array',
      inclusive: true,
      fatal: true,
      message: 'At least on cropping plan should be selected.',
      path: ['croppingPlans']
    })
    return z.NEVER
  }

  const totalHa = data.croppingPlans.reduce((prev, curr) => {
    if (curr.croppingPlan) {
      return prev + curr.data.hectarage
    } else {
      return prev
    }
  }, 0)
  if (totalHa < 1 || totalHa > 4) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: 'Total hectarage should be less than or equal 4 ha.',
      path: ['croppingPlans']
    })
  }
})

export const CroppingPlans: React.FC<CroppingPlanProps> = ({
  cancel,
  next,
  lease,
  farm,
  croppingPlans,
  setCroppingPlans
}) => {
  const { isLoading, sendOtpMessage } = useOtpVerification(
    '',
    lease != null ? lease.destination_farmer_contact : farm?.farmer_contact
  )
  const { t } = useTranslation('grants')

  const defaultCroppingPlanValues = croppingPlans.map((cp) => {
    return {
      hectarage: cp.hectarage,
      cropping_plan_id: cp.cropping_plan_id,
      crop_name: cp.crop_name,
      family_name: cp.family_name
    }
  })

  const defaultFormValues = defaultCroppingPlanValues.map((item) => {
    return {
      croppingPlan: false,
      data: item
    }
  })

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    mode: 'onChange',
    defaultValues: {
      croppingPlans: defaultFormValues
    }
  })

  const { fields } = useFieldArray({
    control: form.control,
    name: 'croppingPlans'
  })

  const onSubmit = (data: z.infer<typeof FormSchema>): void => {
    const selectedCroppingPlans: SelectedCroppingPlans[] = data.croppingPlans
      .filter((cp) => cp.croppingPlan)
      .map((selected) => {
        return {
          cp_id: selected.data.cropping_plan_id
        }
      })
    setCroppingPlans(selectedCroppingPlans)
    sendOtpMessage(next)
  }

  return (
    <Form {...form}>
      <form className={'flex flex-col gap-4'} onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name='croppingPlans'
          render={({ field }) => (
            <FormItem className='flex flex-col justify-center gap-4 p-4'>
              <div className='flex flex-col gap-4'>
                <FormLabel className={'text-center'}>{t('cp.selection')}</FormLabel>
                <FormDescription className={'flex flex-col gap-2 text-center'}>
                  {t('cp.select')}
                  <Text
                    variant={'error'}
                    className={'text-center font-bold uppercase'}
                    size={'small'}
                  >
                    {t('cp.note')}
                  </Text>
                </FormDescription>
                <FormMessage className={'text-center text-lg'} />
              </div>
              {fields.map((arr, index) => (
                <FormField
                  key={arr.id}
                  control={form.control}
                  name={`croppingPlans.${index}`}
                  render={({ field: cpField }) => (
                    <FormField
                      key={React.useId()}
                      control={form.control}
                      name={`croppingPlans.${index}.croppingPlan`}
                      render={({ field: checkboxField }) => (
                        <div className={'flex flex-col items-center gap-4'}>
                          <FormLabel className={'flex items-center gap-4'}>
                            <Text size={'body'}>{arr.data.crop_name}</Text>
                            <Text size={'medium'}>-</Text>
                            <Text size={'body'}> {arr.data.hectarage} ha</Text>
                          </FormLabel>
                          <FormItem className='flex flex-row items-start space-x-3 space-y-0'>
                            <FormControl>
                              <Checkbox
                                checked={checkboxField.value}
                                onCheckedChange={checkboxField.onChange}
                              />
                            </FormControl>
                            <div className='space-y-1 leading-none'>
                              <FormLabel>Cropping Plan Id: {arr.data.cropping_plan_id}</FormLabel>
                            </div>
                          </FormItem>
                        </div>
                      )}
                    />
                  )}
                />
              ))}
            </FormItem>
          )}
        />

        <div className={'flex flex-row justify-between gap-8'}>
          <Button variant={'destructive'} onClick={cancel} size={'xs'} disabled={isLoading}>
            {t('cp.cancel')}
          </Button>
          <Button
            variant={'success'}
            type={'submit'}
            onClick={form.handleSubmit(onSubmit)}
            size={'xs'}
            disabled={isLoading}
            loading={isLoading}
          >
            {t('cp.next')}
          </Button>
        </div>
      </form>
    </Form>
  )
}
