import { Navigate } from 'react-router-dom'
import { ServiceProviderProfile } from '../features/profile/routes/ServiceProvider'
import { SearchEligibility } from '../features/merchant/components/SearchEligibility'
import { ChangePassword } from '../components/Change-Password/ChangePassword'
import { PasswordProtectedRoute } from 'components/Change-Password/PasswordProtectedRoute'
import { AgroChemicalDealers } from '../components/Auth/AgroChemicalDealers'
import { ChemicalsVoucherRedemption } from '../features/merchant/chemical-dealer/routes/ChemicalsVoucherRedemption'
import { ChemicalsReports } from '../features/merchant/chemical-dealer/routes/ChemicalReports'

export const chemicaldealerRoutes = {
  path: '/agrochemicals',
  element: <AgroChemicalDealers />,
  children: [
    {
      index: true,
      element: (
        <PasswordProtectedRoute children={<SearchEligibility route={'/agrochemicals/redeem'} />} />
      )
    },
    {
      path: '/agrochemicals/profile',
      element: <PasswordProtectedRoute children={<ServiceProviderProfile />} />
    },
    { path: '/agrochemicals/change-password', element: <ChangePassword /> },
    {
      path: '/agrochemicals/redeem',
      element: <PasswordProtectedRoute children={<ChemicalsVoucherRedemption />} />
    },
    {
      path: '/agrochemicals/reports',
      element: <PasswordProtectedRoute children={<ChemicalsReports />} />
    },
    {
      path: '/agrochemicals/*',
      element: <PasswordProtectedRoute children={<Navigate to={'/agrochemicals'} />} />
    },
    { path: '*', element: <PasswordProtectedRoute children={<Navigate to={'.'} />} /> }
  ]
}
