import type React from 'react'
import { useState } from 'react'
import type { Table } from '@tanstack/react-table'
import { type SelectedServices } from '../types'
import { RedeemVoucherOtpForm } from '../../components/RedeemVoucherOtpForm'
import { VoucherRedeemed } from '../../components/VoucherRedeemed'
import { useRedeemServicesVoucher } from '../hooks/useRedeemServiceVoucher'
import { ServicesVoucherForm } from './ServicesVoucherForm'

interface ServicesVoucherRedemptionProps<TData> {
  table: Table<TData>
}

export function ServicesVoucherRedemption<TData>({
  table
}: ServicesVoucherRedemptionProps<TData>): React.ReactNode {
  const { isLoading, getContact, contact, isOtpSent, isVoucherRedeemed, voucher, redeemVoucher } =
    useRedeemServicesVoucher()
  const [selectedServices, setSelectedServices] = useState<SelectedServices[]>([])

  const redeem = (): void => {
    redeemVoucher(selectedServices)
  }

  if (isVoucherRedeemed) {
    return <VoucherRedeemed voucher={voucher} />
  }

  if (isOtpSent) {
    return <RedeemVoucherOtpForm contact={contact ?? ''} redeem={redeem} />
  }

  return (
    <ServicesVoucherForm
      loading={isLoading}
      getContact={getContact}
      setSelectedData={setSelectedServices}
      className={'flex flex-col gap-4'}
      table={table}
    />
  )
}