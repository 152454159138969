import { Input, Text } from 'components/ui'
import { TabView } from 'components/App/Layout'
import type React from 'react'
import { type Lease } from 'types'
import { useTranslation } from 'react-i18next'

interface OwnerInformationProps {
  lease: Lease
}

export const OwnerInformation: React.FC<OwnerInformationProps> = ({ lease }) => {
  const { t } = useTranslation('leaseManagement')

  if (
    lease.origin_farmer_type != null &&
    lease.origin_farmer_type !== 1 &&
    lease.origin_farmer_type !== 3
  ) {
    return (
      <TabView>
        <Text size={'medium'}>{t('leaseForm.owner')}</Text>
        <div
          className={'grid grid-cols-[repeat(auto-fit,minmax(240px,1fr))] gap-1 sm:gap-4 list-none'}
        >
          <Input
            withLabel
            label={t('leaseForm.farmerId')}
            value={lease.origin_farmer_id ?? 'N/A'}
            disabled
          />
          <Input
            withLabel
            label={'Company Name'}
            value={lease.origin_farmer_company_name ?? 'N/A'}
            disabled
          />
          <Input withLabel label={'Email'} value={lease.origin_farmer_email ?? 'N/A'} disabled />
          <Input
            withLabel
            label={t('leaseForm.contact')}
            value={lease.origin_farmer_contact ?? 'N/A'}
            disabled
          />
        </div>
      </TabView>
    )
  }

  return (
    <TabView>
      <Text size={'medium'}>{t('leaseForm.owner')}</Text>
      <div
        className={'grid grid-cols-[repeat(auto-fit,minmax(240px,1fr))] gap-1 sm:gap-4 list-none'}
      >
        <Input
          withLabel
          label={t('leaseForm.farmerId')}
          value={lease.origin_farmer_id ?? 'N/A'}
          disabled
        />
        <Input
          withLabel
          label={t('leaseForm.fName')}
          value={lease.origin_farmer_firstname ?? 'N/A'}
          disabled
        />
        <Input
          withLabel
          label={t('leaseForm.sName')}
          value={lease.origin_farmer_surname ?? 'N/A'}
          disabled
        />
        <Input
          withLabel
          label={t('leaseForm.contact')}
          value={lease.origin_farmer_contact ?? 'N/A'}
          disabled
        />
      </div>
    </TabView>
  )
}
