import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponse, type Merchant } from 'types'

export const getMerchant = async (
  userId: number | string | null | undefined,
  userRole: number | null | undefined
): Promise<ApiResponse<Merchant>> => {
  return axios.get(`/merchant?user_id=${userId ?? ''}&user_role=${userRole ?? ''}`)
}

export const useGetMerchant = (
  userId: number | string | null | undefined,
  role: number | null | undefined
): UseQueryResult<ApiResponse<Merchant>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['merchant', userId, role],
    queryFn: async () => getMerchant(userId, role),
    enabled:
      userId !== null &&
      role !== null &&
      (role === 2000 || role === 2001 || role === 2002 || role === 2003 || role === 2004)
  })
}
