import { Services } from 'components/Auth/ServicesProviders'
import { Navigate } from 'react-router-dom'
import { SearchEligibility } from '../features/merchant/components/SearchEligibility'
import { ServiceProviderProfile } from 'features/profile/routes/ServiceProvider'
import { ServiceProviderReports } from 'features/merchant/service-provider/routes/Reports'
import { ServicesVoucherRedemption } from '../features/merchant/service-provider/routes/Redemption'
import { Inventories } from '../features/merchant/service-provider/inventory/routes/Inventories'
import { ModifyInventoryMerchant } from '../features/merchant/service-provider/inventory/routes/ModifyInventoryMerchant'
import { InventoryForm } from '../features/merchant/service-provider/inventory/routes/InventoryForm'
import { Checklist } from '../features/mechanisationOfficer/inventory/components/checklist/Checklist'
import { ViewHistories } from '../features/farmer/inventory/components/History/ViewHistories'
import { ChangePassword } from '../components/Change-Password/ChangePassword'
import { PasswordProtectedRoute } from '../components/Change-Password/PasswordProtectedRoute'

export const servicesprovidersRoutes = {
  path: '/service-provider',
  element: <Services />,
  children: [
    {
      index: true,
      element: (
        <PasswordProtectedRoute
          children={<SearchEligibility route={'/service-provider/redeem'} />}
        />
      )
    },
    {
      path: '/service-provider/profile',
      element: <PasswordProtectedRoute children={<ServiceProviderProfile />} />
    },
    { path: '/service-provider/change-password', element: <ChangePassword /> },
    {
      path: '/service-provider/redeem',
      element: <PasswordProtectedRoute children={<ServicesVoucherRedemption />} />
    },
    {
      path: '/service-provider/reports',
      element: <PasswordProtectedRoute children={<ServiceProviderReports />} />
    },
    {
      path: '/service-provider/*',
      element: <PasswordProtectedRoute children={<Navigate to={'/service-provider'} />} />
    },
    {
      path: '/service-provider/inventory',
      element: <PasswordProtectedRoute children={<Inventories />} />
    },
    {
      path: '/service-provider/inventory/register-new/:inventoryType',
      element: <PasswordProtectedRoute children={<InventoryForm />} />
    },
    {
      path: '/service-provider/inventory/assessment/:inventoryId',
      element: <PasswordProtectedRoute children={<Checklist />} />
    },

    {
      path: '/service-provider/inventory/:inventoryId',
      element: <PasswordProtectedRoute children={<ModifyInventoryMerchant />} />
    },
    {
      path: '/service-provider/inventory/history/:inventoryId',
      element: <PasswordProtectedRoute children={<ViewHistories />} />
    },
    {
      path: '/service-provider/inventory/register-new/*',
      element: <PasswordProtectedRoute children={<InventoryForm />} />
    },
    {
      path: '/service-provider/inventory/assessment/:inventoryId',
      element: <PasswordProtectedRoute children={<Checklist />} />
    },
    { path: '*', element: <PasswordProtectedRoute children={<Navigate to={'.'} />} /> }
  ]
}
