import { type ApiResponse } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type GetInventoryRes } from '../types'

export const getInventory = async (
  farmerId: number | null | undefined
): Promise<ApiResponse<GetInventoryRes>> => {
  return axios.get(`/inventory?farmer_id=${farmerId ?? ''}`)
}

export const useGetInventory = (
  farmerId: number | null | undefined
): UseQueryResult<ApiResponse<GetInventoryRes>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: [`inventories-${farmerId ?? ''}`],
    queryFn: async () => getInventory(farmerId),
    enabled: farmerId != null
  })
}
