import { NotFound } from 'components/Errors/NotFound'
import { useGetSoilAnalysis } from 'features/lab/ssr/api/getSoilAnalysisResult'
import { useGetSSR } from 'features/lab/ssr/api/getSSR'
import { useSoilSampleAnalysis } from 'features/lab/ssr/hooks/useSoilSampleAnalysis'
import type React from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useMerchantStore } from 'stores/useMerchantStore'
import type z from 'zod'
import { coerce, object } from 'zod'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Loader,
  Text
} from 'components/ui'
import { TabView } from 'components/App/Layout'
import { zodResolver } from '@hookform/resolvers/zod'
import { Error } from '../../../../components/Errors/Error'
import { usePloughSeason } from '../../../../hooks/usePloughSeason'

const soilSchema = object({
  ec: coerce.string().min(1, 'Field is required'),
  c: coerce.string().min(1, 'Field is required'),
  fe: coerce.string().min(1, 'Field is required'),
  Mn: coerce.string().min(1, 'Field is required'),
  Mg: coerce.string().min(1, 'Field is required'),
  Cu: coerce.string().min(1, 'Field is required'),
  pH: coerce
    .string()
    .min(1, 'Field is required')
    .refine(
      (value) => {
        const numericValue = parseFloat(value)
        return numericValue >= 1 && numericValue <= 14
      },
      {
        message: 'pH must be a number between 1 and 14'
      }
    ),
  Ca: coerce.string().min(1, 'Field is required'),
  Zn: coerce.string().min(1, 'Field is required'),
  S: coerce.string().min(1, 'Field is required'),
  Na: coerce.string().min(1, 'Field is required'),
  K: coerce.string().min(1, 'Field is required'),
  P: coerce.string().min(1, 'Field is required'),
  bk: coerce.string().min(1, 'Field is required'),
  CEC: coerce.string().min(1, 'Field is required')
})

export const SoilSampleAnalysis: React.FC = () => {
  const { merchant } = useMerchantStore()
  const { farmerId } = useParams<{ farmerId: string }>()
  const { ssrId } = useParams<{ ssrId: string }>()
  const { farmId } = useParams<{ farmId: string }>()
  const navigate = useNavigate()
  const {
    data: SSR,
    isLoading: ssrIsLoading,
    error: ssrError
  } = useGetSSR(farmerId, ssrId, farmId, merchant?.merchant_id)
  const {
    data: SSA,
    isLoading: ssaIsLoading,
    error: ssaError
  } = useGetSoilAnalysis(farmId, farmerId, ssrId, merchant?.merchant_id)
  const { createSSA, isSSACreated, isLoading } = useSoilSampleAnalysis()
  const { isCurrentSeason, isLoading: ploughSeasonIsLoading } = usePloughSeason()

  const onSave = (): void => {
    navigate('/soil-lab/analyses', { replace: true })
  }

  const form = useForm<z.infer<typeof soilSchema>>({
    resolver: zodResolver(soilSchema),
    defaultValues: {
      CEC: SSA?.data[0].cec_comp ?? '',
      bk: SSA?.data[0].bulk_density,
      Mn: SSA?.data[0].mn_comp ?? '',
      c: SSA?.data[0].c_comp ?? '',
      Ca: SSA?.data[0].ca_comp ?? '',
      Cu: SSA?.data[0].cu_comp ?? '',
      ec: SSA?.data[0].ec_comp ?? '',
      K: SSA?.data[0].k_comp ?? '',
      S: SSA?.data[0].s_comp ?? '',
      Zn: SSA?.data[0].zn_comp ?? '',
      pH: SSA?.data[0].ph_comp ?? '',
      P: SSA?.data[0].p_comp ?? '',
      Na: SSA?.data[0].na_comp ?? '',
      Mg: SSA?.data[0].mg_comp ?? '',
      fe: SSA?.data[0].fe_comp ?? ''
    }
  })
  const onSubmit = (data: z.infer<typeof soilSchema>): void => {
    createSSA({
      ssr_id: parseInt(ssrId ?? ''),
      ec_comp: data.ec,
      fe_comp: data.fe,
      mn_comp: data.Mn,
      cu_comp: data.Cu,
      ph_comp: data.pH,
      ca_comp: data.Ca,
      zn_comp: data.Zn,
      s_comp: data.S,
      mg_comp: data.Mg,
      na_comp: data.Na,
      k_comp: data.K,
      p_comp: data.P,
      c_comp: data.c,
      cec_comp: data.CEC,
      bulk_density: data.bk,
      user_id: merchant?.user_id
    })
  }

  useEffect(() => {
    if (isSSACreated) {
      onSave()
    }

    if (SSA != null) {
      form.reset()
    }
  }, [isSSACreated, ssaIsLoading])

  const disabled = SSR?.data[0].status_name === 'COMPLETED'

  if (ssaIsLoading || ssrIsLoading || ploughSeasonIsLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (ssaError !== null && ssaError?.response?.status !== 404) {
    return <Error />
  }

  if (ssrError != null || SSR?.data[0].merchant_id !== merchant?.merchant_id) {
    return <NotFound />
  }

  const isPreviousSeasonSSR = !isCurrentSeason(SSR?.data[0].plough_season)

  if (isPreviousSeasonSSR) {
    return (
      <TabView>
        <Text size={'medium'}>Previous Season Soil Sample Results</Text>
        <div
          className={
            'w-full grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))] gap-4 list-none md:gap-8'
          }
        >
          <div className={'flex flex-[1_0_130px] flex-col'}>
            <Text size={'small'}>Electrical Conductivity, EC (mSm-1)</Text>
            <Text className={'font-semibold'}>{SSA?.data[0].ec_comp ?? 0}</Text>
          </div>

          <div className={'flex  flex-col'}>
            <Text size={'small'}>Magnesium, Mg (mg/kg)</Text>
            <Text className={'font-semibold'}>{SSA?.data[0].mg_comp ?? 0}</Text>
          </div>
          <div className={'flex  flex-col'}>
            <Text size={'small'}>Manganese,Mn (mg/kg)</Text>
            <Text className={'font-semibold'}>{SSA?.data[0].mn_comp ?? 0}</Text>
          </div>

          <div className={'flex  flex-col'}>
            <Text size={'small'}>Sodium, Na (mg/kg)</Text>
            <Text className={'font-semibold'}>{SSA?.data[0].na_comp ?? 0}</Text>
          </div>
          <div className={'flex  flex-col'}>
            <Text size={'small'}>Copper, Cu (mg/kg)</Text>
            <Text className={'font-semibold'}>{SSA?.data[0].cu_comp ?? 0}</Text>
          </div>

          <div className={'flex  flex-col'}>
            <Text size={'small'}>Potassium, K (mg/kg)</Text>
            <Text className={'font-semibold'}>{SSA?.data[0].k_comp ?? 0}</Text>
          </div>

          <div className={'flex  flex-col'}>
            <Text size={'small'}>Calcium, Ca (mg/kg)</Text>
            <Text className={'font-semibold'}>{SSA?.data[0].ca_comp ?? 0}</Text>
          </div>
          <div className={'flex flex-col'}>
            <Text size={'small'}>Iron, Fe (mg/kg)</Text>
            <Text className={'font-semibold'}>{SSA?.data[0].fe_comp ?? 0}</Text>
          </div>
          <div className={'flex flex-col'}>
            <Text size={'small'}>pH</Text>
            <Text className={'font-semibold'}>{SSA?.data[0].ph_comp ?? 0}</Text>
          </div>
          <div className={'flex  flex-col'}>
            <Text size={'small'}>Zinc, Zn (mg/kg)</Text>
            <Text className={'font-semibold'}>{SSA?.data[0].zn_comp ?? 0}</Text>
          </div>
          <div className={'flex  flex-col'}>
            <Text size={'small'}>Phosphorus, P (mg/kg)</Text>
            <Text className={'font-semibold'}>{SSA?.data[0].p_comp ?? 0}</Text>
          </div>
          <div className={'flex flex-col'}>
            <Text size={'small'}>Sulphur, S (mg/kg)</Text>
            <Text className={'font-semibold'}>{SSA?.data[0].s_comp ?? 0}</Text>
          </div>

          <div className={'flex flex-col'}>
            <Text size={'small'}>Organic Carbon (%)</Text>
            <Text className={'font-semibold'}>{SSA?.data[0].c_comp ?? 0}</Text>
          </div>
          <div className={'flex flex-col'}>
            <Text size={'small'}>Cation Exchange Capacity, CEC (mol/kg)</Text>
            <Text className={'font-semibold'}>{SSA?.data[0].cec_comp ?? 0}</Text>
          </div>
          <div className={'flex flex-col'}>
            <Text size={'small'}>Bulk Density, (g/cm3)</Text>
            <Text className={'font-semibold'}>{SSA?.data[0].bulk_density ?? 0}</Text>
          </div>
        </div>

        <div className={'flex justify-between'}>
          <Button
            variant={'secondary'}
            onClick={onSave}
            disabled={isLoading}
            className={'min-w-24'}
          >
            Back
          </Button>
        </div>
      </TabView>
    )
  }

  return (
    <TabView>
      {SSA?.data != null ? (
        <Text size={'medium'}>Update Soil Sample Results</Text>
      ) : (
        <Text size={'medium'}>Add Soil Sample Results</Text>
      )}
      <Form {...form}>
        <form
          className={'w-full flex flex-col gap-4 md:gap-8'}
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div
            className={
              'w-full grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))] gap-4 list-none md:gap-8'
            }
          >
            <FormField
              control={form.control}
              name={'bk'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Bulk Density, (g/cm3)</FormLabel>
                  <FormControl>
                    <Input
                      type={'number'}
                      inputMode={'numeric'}
                      placeholder={'Enter bulk density'}
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                      value={SSA?.data[0].bulk_density}
                      disabled={disabled}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'CEC'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>
                    Cation Exchange Capacity, CEC (mol/kg)
                  </FormLabel>
                  <FormControl>
                    <Input
                      type={'number'}
                      inputMode={'numeric'}
                      placeholder={'Add value'}
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                      value={SSA?.data[0].cec_comp}
                      disabled={disabled}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'ec'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>
                    Electrical Conductivity, ec (mSm-1)
                  </FormLabel>
                  <FormControl>
                    <Input
                      type={'number'}
                      inputMode={'numeric'}
                      placeholder={'Add value'}
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                      value={SSA?.data[0].ec_comp}
                      disabled={disabled}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'c'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Organic Carbon %</FormLabel>
                  <FormControl>
                    <Input
                      type={'number'}
                      inputMode={'numeric'}
                      placeholder={'Add value'}
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                      value={SSA?.data[0].c_comp}
                      disabled={disabled}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name={'fe'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Iron, Fe (mg/kg)</FormLabel>
                  <FormControl>
                    <Input
                      type={'number'}
                      inputMode={'numeric'}
                      placeholder={'Add value'}
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                      value={SSA?.data[0].fe_comp}
                      disabled={disabled}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'Mn'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Manganese, Mn (mg/kg)</FormLabel>
                  <FormControl>
                    <Input
                      type={'number'}
                      inputMode={'numeric'}
                      placeholder={'Add value'}
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                      value={SSA?.data[0].mn_comp}
                      disabled={disabled}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name={'Cu'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Copper, Cu (mg/kg)</FormLabel>
                  <FormControl>
                    <Input
                      type={'number'}
                      inputMode={'numeric'}
                      placeholder={'Add value'}
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                      value={SSA?.data[0].cu_comp}
                      disabled={disabled}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'pH'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Soil pH</FormLabel>
                  <FormControl>
                    <Input
                      type={'number'}
                      inputMode={'numeric'}
                      placeholder={'Add value'}
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                      value={SSA?.data[0].ph_comp}
                      disabled={disabled}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'Ca'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Calcium, Ca (mg/kg)</FormLabel>
                  <FormControl>
                    <Input
                      type={'number'}
                      inputMode={'numeric'}
                      placeholder={'Add value'}
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                      value={SSA?.data[0].ca_comp}
                      disabled={disabled}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'Zn'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Zinc , Zn (mg/kg)</FormLabel>
                  <FormControl>
                    <Input
                      type={'number'}
                      inputMode={'numeric'}
                      placeholder={'Add value'}
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                      value={SSA?.data[0].zn_comp}
                      disabled={disabled}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'S'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Sulphur, S (mg/kg)</FormLabel>
                  <FormControl>
                    <Input
                      type={'number'}
                      inputMode={'numeric'}
                      placeholder={'Add value'}
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                      value={SSA?.data[0].s_comp}
                      disabled={disabled}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'Na'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Sodium, Na (mg/kg)</FormLabel>
                  <FormControl>
                    <Input
                      type={'number'}
                      inputMode={'numeric'}
                      placeholder={'Add value'}
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                      value={SSA?.data[0].na_comp}
                      disabled={disabled}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'K'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Potassium, K (mg/kg)</FormLabel>
                  <FormControl>
                    <Input
                      type={'number'}
                      inputMode={'numeric'}
                      placeholder={'Add value'}
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                      value={SSA?.data[0].k_comp}
                      disabled={disabled}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'P'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Phosphorus, P (mg/kg)</FormLabel>
                  <FormControl>
                    <Input
                      type={'number'}
                      inputMode={'numeric'}
                      placeholder={'Add value'}
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                      value={SSA?.data[0].p_comp}
                      disabled={disabled}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'Mg'}
              render={({ field }) => (
                <FormItem className='flex flex-col'>
                  <FormLabel className={'font-redHat '}>Magnesium, Mg (mg/kg)</FormLabel>
                  <FormControl>
                    <Input
                      type={'number'}
                      inputMode={'numeric'}
                      placeholder={'Add value'}
                      classLabel={'bg-background-dark sm:bg-card'}
                      {...field}
                      value={SSA?.data[0].mg_comp}
                      disabled={disabled}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className={'flex justify-between'}>
            <Button
              variant={'secondary'}
              onClick={onSave}
              disabled={isLoading}
              className={'min-w-24'}
            >
              Back
            </Button>
            {SSA?.data != null ? (
              <Button
                onClick={form.handleSubmit(onSubmit)}
                loading={isLoading}
                disabled={isLoading || disabled}
                className={'min-w-24'}
              >
                Update
              </Button>
            ) : (
              <Button
                type={'submit'}
                loading={isLoading}
                disabled={isLoading}
                className={'min-w-24'}
              >
                Save
              </Button>
            )}
          </div>
        </form>
      </Form>
    </TabView>
  )
}
