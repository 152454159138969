import type React from 'react'
import { motion } from 'framer-motion'
import PowerfulFeaturesCard from './PowerfulFeaturesCard'
import { useTranslation } from 'react-i18next'
import { BiBulb } from 'react-icons/bi'
import { PiLightbulbFill } from 'react-icons/pi'
import { TbBulbFilled } from 'react-icons/tb'

/**
 * A React functional component that renders the "Why Choose Temo" section.
 * This section includes a title, a description, and a card component that displays powerful features.
 *
 * @returns {JSX.Element} The rendered component.
 */
export const PowerfulFeatures: React.FC = () => {
  const { t } = useTranslation('landing')
  return (
    <div className='flex flex-col py-20 bg-white'>
      <motion.div
        className='container mx-auto flex flex-col items-center md:justify-center md:items-center px-10 space-y-12'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className='text-3xl md:text-4xl lg:text-5xl font-bold text-gray-700 landing-page'>
          {t('cms.power')}
        </h2>
        <p className='text-base md:text-xl lg:text-2xl font-regular text-center text-gray-600 md:px-[10vw] lg:px-[20vw] xl:px-0 landing-page'>
          {t('cms.explore')}
        </p>
        <div className='flex justify-center items-center'>
          <motion.div
            animate={{ opacity: [1, 0.5, 1] }}
            transition={{ duration: 0.5, repeat: Infinity, repeatType: 'reverse' }}
          >
            <TbBulbFilled fill='orange' className='w-10 h-10 mr-4' />
          </motion.div>
          <p className='text-sm md:text-base lg:text-xl font-regular text-center text-gray-600 md:px-[10vw] lg:px-[20vw] xl:px-0 landing-page'>
            Click on each card to view a snapshot of the feature below.
          </p>
        </div>
      </motion.div>
      <motion.div
        className='flex flex-col justify-center items-center'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <PowerfulFeaturesCard />
      </motion.div>
    </div>
  )
}
