import { useState } from 'react'
import { useFarmerStore } from '../../../../stores/useFarmerStore'
import { AxiosError, type AxiosResponse } from 'axios'
import type { ApiResponse, Lessee } from '../../../../types'
import { mapCmsErrorToMessage } from '../../../../utils/apiErrors'
import { getFarmer } from '../api/getFarmer'
import { useToast } from 'components/ui'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTransferStore } from '../stores/transferStore'
import { useNavigate } from 'react-router-dom'
import { updateHectarage, type UpdateHectarageDTO } from '../api/History/updateHectarage'
import { type SubdivideDTO, subdivideFarm } from '../api/History/subdivideFarm'
import { type SubdivisionInput } from '../components/farm-edits/farm-edits-actions/subdivide-farm/SubdivisionFarmDetails'
import { transferFarm, type TransferLandDTO } from '../api/History/transferLand'

interface UseFarmEdits {
  isLoading: boolean
  destinationFarmer: Lessee | null
  searchDestinationFarmer: (farmerId: string, next: () => void) => Promise<void>
  transferLand: (farm_id: string | number | null | undefined, next: () => void) => void
  updateLandHectarage: (
    farm_id: string | number | null | undefined,
    hectarage: number,
    next: () => void
  ) => void
  subdivideLand: (
    farm_id: string | number | null | undefined,
    farm_details_input: SubdivisionInput,
    next: () => void
  ) => void
}

export const useFarmEdits = (): UseFarmEdits => {
  const navigate = useNavigate()
  const { toast } = useToast()
  const { farmer } = useFarmerStore()
  const { setDestinationDetails, farmerID } = useTransferStore()
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState(false)
  const [destinationFarmer, setdestinationFarmer] = useState<Lessee | null>(null)

  const transferLandMutation = useMutation({
    mutationFn: async (land: TransferLandDTO) => {
      return transferFarm(land)
    }
  })
  const updateHectarageMutation = useMutation({
    mutationFn: async (params: UpdateHectarageDTO) => {
      return updateHectarage(params)
    }
  })
  const subdivideLandMutation = useMutation({
    mutationFn: async (params: SubdivideDTO) => {
      return subdivideFarm(params)
    }
  })

  const searchDestinationFarmer = async (farmerId: string, next: () => void): Promise<void> => {
    setdestinationFarmer(null)
    setIsLoading(true)

    try {
      const response = await getFarmer(farmerId)

      if (response.status === 200) {
        const lesseeData = response.data[0]
        setdestinationFarmer(lesseeData)
        setDestinationDetails(lesseeData)

        next()
      } else {
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: response.message
        })
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const errorMsg = err?.response as AxiosResponse<ApiResponse<[]>>
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: mapCmsErrorToMessage(
            errorMsg.status.toString() ?? err.code ?? errorMsg.data.message ?? ''
          )
        })
      } else {
        const errorMsg = err as Error
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: mapCmsErrorToMessage(errorMsg.message)
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const transferLand = (farm_id: string | number | null | undefined, next: () => void): void => {
    transferLandMutation.mutate(
      {
        farm_id,
        og_farmer_id: farmer?.farmer_id,
        dest_farmer_id: parseInt(farmerID ?? '0'),
        last_updated_by: farmer?.user_id
      },
      {
        onSuccess: (resp) => {
          if (resp.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Your farm has been successfully transferred.'
            })
            void queryClient.invalidateQueries({ queryKey: ['farms'] })
            navigate('/farmer')
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response as AxiosResponse<ApiResponse<[]>>

            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          }
        }
      }
    )
  }

  const updateLandHectarage = (
    farm_id: string | number | null | undefined,
    hectarage: number,
    next: () => void
  ): void => {
    updateHectarageMutation.mutate(
      {
        farm_id,
        farmer_id: farmer?.farmer_id,
        hectarage,
        last_updated_by: farmer?.user_id
      },
      {
        onSuccess: (resp) => {
          if (resp.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Your farm size has been successfully changed.'
            })
            void queryClient.invalidateQueries({ queryKey: ['farms'] })
            navigate('/farmer')
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response as AxiosResponse<ApiResponse<[]>>

            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          }
        }
      }
    )
  }
  const subdivideLand = (
    farm_id: string | number | null | undefined,
    farm_details_input: SubdivisionInput,
    next: () => void
  ): void => {
    subdivideLandMutation.mutate(
      {
        farm_id,
        og_farmer_id: farmer?.farmer_id,
        hectarage: farm_details_input.size,
        last_updated_by: farmer?.user_id,
        dest_farmer_id: parseInt(farmerID ?? '0'),
        new_farm_name: farm_details_input.newFarmName,
        new_nickname: farm_details_input.newFarmName
      },
      {
        onSuccess: (resp) => {
          if (resp.status === 201) {
            next()
            toast({
              variant: 'success',
              title: 'Your farm has been successfully subdivided.'
            })
            void queryClient.invalidateQueries({ queryKey: ['farms'] })
            navigate('/farmer')
          }
        },
        onError: (err) => {
          if (err instanceof AxiosError) {
            const error = err.response as AxiosResponse<ApiResponse<[]>>

            toast({
              variant: 'destructive',
              title: 'Uh oh! Something went wrong.',
              description: mapCmsErrorToMessage(
                error.status.toString() ?? err.code ?? error.data.message ?? ''
              )
            })
          }
        }
      }
    )
  }

  return {
    isLoading:
      isLoading ||
      transferLandMutation.isLoading ||
      updateHectarageMutation.isLoading ||
      subdivideLandMutation.isLoading,
    destinationFarmer,
    searchDestinationFarmer,
    transferLand,
    updateLandHectarage,
    subdivideLand
  }
}
