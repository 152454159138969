import type React from 'react'
import { useEffect, useState } from 'react'
import { Weeding } from './Weeding'
import { ChemicalSelection } from './chemical/ChemicalSelection'
import { useWeedingStore } from '../../../stores/useWeedingStore'
import { ApplicationMethod } from './chemical/ApplicationMethod'

interface ChemicalWeedingProps {
  back: () => void
}

export const ChemicalWeeding: React.FC<ChemicalWeedingProps> = ({ back }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const { setSelectedHerbicides, setSchedule } = useWeedingStore()

  const handleNext = (): void => {
    setCurrentStep((prev) => prev + 1)
  }

  const handleBack = (): void => {
    setCurrentStep((prev) => prev - 1)
  }

  useEffect(() => {
    setSelectedHerbicides(null)
    setSchedule(null)
  }, [])

  if (currentStep === 1) {
    return <ChemicalSelection back={handleBack} next={handleNext} />
  }

  if (currentStep === 2) {
    return <ApplicationMethod back={handleBack} />
  }

  return <Weeding onBackStep={back} onNextStep={handleNext} />
}
