import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'
import { type AxiosError } from 'axios'
import { type InventoryTransfer } from '../types'
import { useFarmerStore } from '../../../../stores/useFarmerStore'

export const getTransferRequest = async (
  userId: number | null | undefined,
  isFarmer: boolean
): Promise<ApiResponse<InventoryTransfer>> => {
  return axios.get(
    `/inventory/transfer?${isFarmer ? 'farmer_id' : 'merchant_id'}=${userId ?? ''}&is_active=true`
  )
}

export const useGetTransferRequest = (
  userId: number | null | undefined
): UseQueryResult<ApiResponse<InventoryTransfer>, AxiosError> => {
  const { farmer } = useFarmerStore()

  return useQuery({
    queryKey: ['transfer-request', userId],
    queryFn: async () => getTransferRequest(userId, farmer != null),
    enabled: userId != null
  })
}
