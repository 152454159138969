import type React from 'react'
import { useState } from 'react'
import type z from 'zod'
import { object, string, type TypeOf } from 'zod'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useAtom } from 'jotai'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { individualLoanItem } from '../stores/individualLoanItem'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Phone,
  Text,
  useStepper
} from '../../../../../components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { Box } from '../../../../../components/App/Layout'
import type { LoanStepperProps } from '../../../types'
import { LoanStepperFooter } from '../../generic/LoanStepperFooter'
import { useTranslation } from 'react-i18next'
import { Info } from 'lucide-react'

const schema = object({
  kin_first_name: string().min(1, 'Field is required'),
  kin_last_name: string().min(1, 'Field is required'),
  kin_postal_address: string().min(1, 'Field is required'),
  kin_physical_address: string().min(1, 'Field is required'),
  kin_email: string().email('Invalid Email')
})

type NextOfKinDetailsInput = TypeOf<typeof schema>

const phoneUtil = PhoneNumberUtil.getInstance()

const usePhoneValidation = (phone: string): boolean => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
  } catch (error) {
    return false
  }
}

export const NextOfKin: React.FC<LoanStepperProps> = ({ onComplete }) => {
  const { farmer } = useFarmerStore()
  const [loanData, setLoanData] = useAtom(individualLoanItem)
  const { nextStep } = useStepper()
  const { t } = useTranslation('loanManagement')

  const [defaultPhoneNumber, setDefaultPhoneNumber] = useState<string>(
    loanData?.particulars_of_next_of_kin?.kin_phone !== undefined
      ? loanData?.particulars_of_next_of_kin?.kin_phone
      : ''
  )

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      kin_email: loanData?.particulars_of_next_of_kin?.kin_email,
      kin_first_name: loanData?.particulars_of_next_of_kin?.kin_first_name,
      kin_last_name: loanData?.particulars_of_next_of_kin?.kin_last_name,
      kin_physical_address: loanData?.particulars_of_next_of_kin?.kin_physical_address,
      kin_postal_address: loanData?.particulars_of_next_of_kin?.kin_postal_address
    }
  })
  const [number, setNumber] = useState<string>(defaultPhoneNumber)
  const isPhoneValid = usePhoneValidation(number)
  const onSubmit: SubmitHandler<NextOfKinDetailsInput> = (data: NextOfKinDetailsInput) => {
    setLoanData((prev) => ({
      ...prev!,
      particulars_of_next_of_kin: {
        kin_first_name: data.kin_first_name,
        kin_mid_name: '',
        kin_last_name: data.kin_last_name,
        kin_physical_address: data.kin_physical_address,
        kin_postal_address: data.kin_postal_address,
        kin_email: data.kin_email,
        kin_phone: number,
        last_update_by: farmer?.user_id as number
      }
    }))
    nextStep()
    if (onComplete != null) {
      onComplete(true)
    }
  }

  return (
    <Box>
      <Form {...form}>
        <form className='flex flex-col w-full' onSubmit={form.handleSubmit(onSubmit)}>
          <div className='space-y-8 my-6'>
            <div className='flex flex-col gap-3'>
              <Text size='medium' className=''>
                {t('kin.kin')}
              </Text>
              <div className='flex items-center'>
                <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
                <Text variant='secondary' size='small' className=''>
                  Please provide the full details of your next of kin, including their name,
                  address, and contact information.
                </Text>
              </div>
            </div>
            <div className='grid grid-cols-1 gap-4 lg:grid-cols-3 mb-3'>
              <FormField
                control={form.control}
                name='kin_first_name'
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel className='font-redHat'>
                      <Text size='body' className=''>
                        {t('kin.first')}
                      </Text>
                    </FormLabel>
                    <FormControl>
                      <Input
                        classLabel={'bg-background-dark sm:bg-card'}
                        placeholder={`${t('kin.first')}`}
                        type='text'
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name='kin_last_name'
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel className='font-redHat'>
                      <Text size='body' className=''>
                        {t('kin.last')}
                      </Text>
                    </FormLabel>
                    <FormControl>
                      <Input
                        classLabel={'bg-background-dark sm:bg-card'}
                        placeholder={`${t('kin.last')}`}
                        type='text'
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {/* </div> */}
              {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-4"> */}
              <FormField
                control={form.control}
                name='kin_postal_address'
                render={({ field }) => (
                  <FormItem className='flex flex-col'>
                    <FormLabel className='font-redHat'>
                      <Text size='body' className=''>
                        {t('kin.postal')}
                      </Text>
                    </FormLabel>
                    <FormControl>
                      <Input
                        classLabel={'bg-background-dark sm:bg-card'}
                        placeholder={`${t('kin.postal')}`}
                        type='text'
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className=' pt-0 md:pt-2'>
                <FormField
                  control={form.control}
                  name='kin_physical_address'
                  render={({ field }) => (
                    <FormItem className='flex flex-col'>
                      <FormLabel className='font-redHat'>
                        <Text size='body' className=''>
                          {t('kin.physical')}
                        </Text>
                      </FormLabel>
                      <FormControl>
                        <Input
                          classLabel={'bg-background-dark sm:bg-card'}
                          placeholder={`${t('kin.physical')}`}
                          type='text'
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              {/* <div className='grid grid-cols-1 sm:grid-cols-2 gap-4'> */}
              <div className=' pt-0 md:pt-2'>
                <FormField
                  control={form.control}
                  name='kin_email'
                  render={({ field }) => (
                    <FormItem className='flex flex-col'>
                      <FormLabel className='font-redHat'>
                        <Text size='body' className=''>
                          {t('kin.email')}
                        </Text>
                      </FormLabel>
                      <FormControl>
                        <Input
                          classLabel={'bg-background-dark sm:bg-card'}
                          placeholder={`${t('kin.email')}`}
                          type='email'
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              {/* <div className='pt-1'> */}
              <FormItem className='flex flex-col'>
                <FormLabel className='font-redHat'>
                  <Text size='body' className=''>
                    {t('kin.phone')}
                  </Text>
                </FormLabel>
                <Phone
                  onChange={(num) => {
                    setNumber(num)
                  }}
                  value={number}
                />
                <FormMessage />
              </FormItem>
              {/* </div> */}
            </div>
          </div>

          <LoanStepperFooter />
        </form>
      </Form>
    </Box>
  )
}
