import { motion } from 'framer-motion'
import { farmFeatures } from './Data/FarmFeaturesData'
import { useTranslation } from 'react-i18next'

interface FarmFeature {
  title: string
  description: string
  icon: string
}

const FeatureItem: React.FC<FarmFeature> = ({ title, description, icon }) => (
  <motion.div
    className='flex flex-col items-center space-y-8 w-full'
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
    whileHover={{ scale: 1.05 }}
  >
    <img src={icon} alt={title} className='w-28 h-28' />

    <h3 className='text-xl md:text-2xl lg:text-3xl text-gray-700 font-bold landing-page'>
      {title}
    </h3>
    <p className='text-base md:text-xl lg:text-2xl text-gray-600 font-regular text-center px-4 landing-page'>
      {description}
    </p>
  </motion.div>
)

const FarmManagementCard: React.FC = () => {
  const { t } = useTranslation('landing')

  return (
    <div className='bg-white-50 p-12'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-16'>
        {farmFeatures(t).map((feature, index) => (
          <FeatureItem key={index} {...feature} />
        ))}
      </div>
    </div>
  )
}

export default FarmManagementCard