import { Dialog, DialogContent, Text } from 'components/ui'
import type React from 'react'
import { useState } from 'react'
import { type SoilCorrection } from '../../../../types/soilCorrection'
import type { SoilSampleRequest } from '../../../../types'
import { SoilCorrectionDetails } from './SoilCorrectionDetails'
import { useTranslation } from 'react-i18next'

interface SoilCorrectionCardProps {
  scr: SoilCorrection
  ssr: SoilSampleRequest
}

export const SoilCorrectionCard: React.FC<SoilCorrectionCardProps> = ({ scr, ssr }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation('landManagement')

  const handleOpenDialog = (): void => {
    setOpen(true)
  }
  const handleCloseDialog = (): void => {
    setOpen(false)
  }

  return (
    <>
      <div
        className={
          'bg-card-secondary flex flex-col cursor-pointer gap-4 p-2 sm:p-4 md:px-8 rounded-sm transition-shadow duration-200 ease-in-out transform shadow-sm shadow-secondary dark:shadow-none hover:shadow-md hover:shadow-input hover:border-primary dark:hover:border-primary'
        }
        onClick={handleOpenDialog}
      >
        <div className='hidden md:flex justify-between py-2 md:py-4 w-full border-t border-border dark:border-divider'>
          <div className={'flex flex-col'}>
            <Text size={'small'}>{t('soilCorr.name')}</Text>
            <Text>
              <strong>{ssr?.farm_name}</strong>
            </Text>
          </div>
          <div className={'flex flex-col'}>
            <Text size={'small'}>{t('soilCorr.season')}</Text>
            <Text>
              <strong>{ssr.plough_season}</strong>
            </Text>
          </div>
          <div className={'flex flex-col'}>
            <Text size={'small'}>{t('soilCorr.cost')}</Text>
            <Text>
              <strong>BWP {scr.total_cost}</strong>
            </Text>
          </div>
        </div>

        <div className='bg-[#F3F6FD] md:hidden w-full flex flex-col gap-4  p-2 px-4  rounded-md'>
          <div className={'flex flex-col'}>
            <Text size={'small'}>{t('soilCorr.name')}</Text>
            <Text size={'medium'}>
              <strong>{ssr?.farm_name}</strong>
            </Text>
          </div>
          <div className={'flex justify-between'}>
            <div className={'flex flex-col'}>
              <Text size={'small'}>{t('soilCorr.season')}</Text>
              <Text size={'medium'}>
                <strong> {ssr.plough_season}</strong>
              </Text>
            </div>
            <div className={'flex flex-col'}>
              <Text size={'small'}>{t('soilCorr.cost')}</Text>
              <Text>
                <strong>BWP {scr.total_cost}</strong>
              </Text>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        key={'soil-recommendation'}
        open={open}
        defaultOpen={false}
        modal
        onOpenChange={handleCloseDialog}
      >
        <DialogContent className={'max-w-[80%] md:max-w-[50%] lg:max-w-[35%]'}>
          <SoilCorrectionDetails scr={scr} ssr={ssr} />
        </DialogContent>
      </Dialog>
    </>
  )
}