import { Grow, useMediaQuery } from '@mui/material'
import { NotFound } from 'components/Errors/NotFound'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import type React from 'react'
import { useEffect, useState } from 'react'
import { Box } from 'components/App/Layout'
import { useNavigate } from 'react-router-dom'
import { Loader, Tabs, TabsContent, TabsList, TabsTrigger, Text } from 'components/ui'
import { FolderX } from 'lucide-react'
import { type GetInventoryRes, type InventoryTransfer } from '../../../../farmer/inventory/types'
import { InventoryFilterMenu } from '../../../../farmer/inventory/components/generic/InventoryFilterMenu'
import { useMerchantStore } from '../../../../../stores/useMerchantStore'
import { useGetSPInventory } from '../api/getSPInventory'
import { TransferRequestCard } from '../../../../farmer/inventory/components/AcceptRejectTransfer/TransferRequestCard'
import { useGetTransferRequest } from '../../../../farmer/inventory/api/getInventoryTransfer'
import { NewInventory } from '../../../../farmer/inventory/components/generic/NewInventory'
import { InventoryCard } from '../components/InventoryCard'

export const Inventories: React.FC = () => {
  const navigate = useNavigate()
  const { merchant } = useMerchantStore()
  const [verified, setVerified] = useState<GetInventoryRes[] | null>(null)
  const [pending, setPending] = useState<GetInventoryRes[] | null>(null)
  const [rejected, setRejected] = useState<GetInventoryRes[] | null>(null)
  const [onTransfer, setOnTransfer] = useState<InventoryTransfer[] | null>(null)
  const [reassessment, setReassesment] = useState<GetInventoryRes[] | null>(null)

  const [filter, setFilter] = useState<
    | 'all'
    | 'VERIFIED'
    | 'PENDING_VERIFICATION'
    | 'REJECTED'
    | 'Transfer initiated'
    | 'TO BE REASSESSED'
  >('all')
  const flags = useFlags()
  const ldClient = useLDClient()

  const { data: transferRequest, isLoading: transferLoading } = useGetTransferRequest(
    merchant?.merchant_id
  )

  const { data, isLoading } = useGetSPInventory(merchant?.merchant_id as number)

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })

    if (transferRequest?.status === 200) {
      setOnTransfer(
        transferRequest?.data.filter(
          (res) =>
            (res.transaction_status === 1 || res.transaction_status === 4) &&
            res.origin_merchant_id === merchant?.merchant_id
        )
      )
    }

    if (data?.status === 200) {
      setVerified(data?.data.filter((res) => res.item_verification_status === 'VERIFIED'))
      setPending(
        data?.data.filter((res) => res.item_verification_status === 'PENDING_VERIFICATION')
      )
      setRejected(data?.data.filter((res) => res.item_verification_status === 'REJECTED'))
      setReassesment(
        data?.data.filter((res) => res.item_verification_status === 'TO BE REASSESSED')
      )
    }
  }, [merchant, data, ldClient, transferRequest])

  const isSmallScreen = useMediaQuery('(max-width:945px)')

  const isTransfer = (inventoryId: string): boolean => {
    return transferRequest?.data.some(
      (transfer) =>
        transfer.inventory_id.toString() === inventoryId.toString() &&
        (transfer.transaction_status === 1 || transfer.transaction_status === 4)
    ) as boolean
  }

  if (flags.merchantInventory === false || flags.inventory === false) {
    return <NotFound />
  }

  const handleInventorySubmit = (selectedInventory: string): void => {
    navigate(`/service-provider/inventory/register-new/${selectedInventory}`)
  }

  if (isLoading || transferLoading) {
    return (
      <div className={'h-full grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  return (
    <Box>
      <div className={'flex justify-between items-center'}>
        <Text size={'large'}>My Inventories</Text>
        <NewInventory onSubmit={handleInventorySubmit} />
      </div>
      <Tabs defaultValue='Inventories' className='w-full'>
        <TabsList className='grid max-w-[400px] w-full grid-cols-2'>
          <TabsTrigger value='Inventories'>Inventories</TabsTrigger>
          <TabsTrigger value='Tranfers'>Transfers</TabsTrigger>
        </TabsList>
        <TabsContent value='Tranfers' className={'w-full'}>
          <div className={'mt-4 w-full flex flex-col gap-4'}>
            {transferRequest != null && transferRequest?.data?.length > 0 ? (
              <div
                className={`grid gap-6 lg:grid-cols-3 ${
                  isSmallScreen ? 'grid-cols-1' : 'grid-cols-2'
                }`}
              >
                {transferRequest?.data
                  .filter(
                    (item) =>
                      (item.transaction_status === 1 || item.transaction_status === 4) &&
                      item.dest_merchant_id === merchant?.merchant_id
                  )
                  .map((offer) => (
                    <TransferRequestCard key={offer.transfer_id} transferRequest={offer} />
                  ))}
              </div>
            ) : (
              <div className={'flex flex-col gap-4 items-center justify-center mt-20'}>
                <Text size={'xLarge'} className={'opacity-40'}>
                  <FolderX />
                </Text>
                <Text size={'medium'} variant={'bodyTextLight'}>
                  You do no have any Transfer Requests
                </Text>
              </div>
            )}
          </div>
        </TabsContent>
        <TabsContent value='Inventories' className={'w-full'}>
          <div className={'mt-4 w-full flex flex-col gap-4'}>
            {data?.data === undefined ? (
              <div className={'flex flex-col gap-4 items-center justify-center mt-20'}>
                <Text size={'xLarge'} className={'opacity-40'}>
                  <FolderX />
                </Text>

                <Text size={'medium'} variant={'bodyTextLight'}>
                  You do not have Inventories
                </Text>
              </div>
            ) : (
              <>
                <InventoryFilterMenu
                  all={data.data.length}
                  verified={verified?.length ?? 0}
                  pending={pending?.length ?? 0}
                  filterBy={filter}
                  setFilterBy={setFilter}
                  rejected={rejected?.length ?? 0}
                  onTransfer={onTransfer?.length ?? 0}
                  reassesment={reassessment?.length ?? 0}
                />

                <Grow in={data !== undefined}>
                  <div
                    className={`grid gap-6 lg:grid-cols-3 ${
                      isSmallScreen ? 'grid-cols-1' : 'grid-cols-2'
                    }`}
                  >
                    {!isLoading &&
                      !transferLoading &&
                      data?.data
                        .filter((i) => {
                          if (filter === 'all') return true
                          if (filter === 'Transfer initiated') {
                            return onTransfer?.some(
                              (transfer) => transfer.inventory_id === i.inventory_id
                            )
                          }
                          return i.item_verification_status === filter
                        })
                        .map((item) => {
                          return (
                            <InventoryCard
                              key={item.inventory_id}
                              inventoryData={item}
                              isTransfer={isTransfer(item.inventory_id.toString())}
                            />
                          )
                        })}
                  </div>
                </Grow>
              </>
            )}
          </div>
        </TabsContent>
      </Tabs>
    </Box>
  )
}
