import moment from 'moment'
import type React from 'react'
import { type Farmer } from 'types'
import { Input, Text } from '../../../../../components/ui'
import { TabView } from '../../../../../components/App/Layout'
import { useTranslation } from 'react-i18next'

interface FarmerInformationProps {
  farmer: Farmer
}

export const FarmerInformation: React.FC<FarmerInformationProps> = ({ farmer }) => {
  const { t } = useTranslation('extensionOfficer')
  if (farmer.farmer_type === 2) {
    return (
      <TabView>
        <Text size={'medium'}>{t('info.fInfo')}</Text>
        <div
          className={'grid grid-cols-[repeat(auto-fit,minmax(240px,1fr))] gap-1 sm:gap-4 list-none'}
        >
          <Input
            withLabel
            label={'Company Name'}
            value={farmer.company_name ?? 'N/A'}
            classLabel={'bg-card'}
            disabled
          />
          <Input
            withLabel
            label={'UIN'}
            value={farmer.company_reg_no ?? 'N/A'}
            classLabel={'bg-card'}
            disabled
          />
          <Input
            withLabel
            label={'Company Registration Date'}
            value={moment(farmer?.company_reg_date).format('DD-MM-YYYY')}
            classLabel={'bg-card'}
            disabled
          />
          <Input
            withLabel
            label={t('info.physical')}
            value={farmer.physical_addr ?? ''}
            classLabel={'bg-card'}
            disabled
          />
          <Input
            withLabel
            label={t('info.postal')}
            value={farmer.postal_addr ?? ''}
            classLabel={'bg-card'}
            disabled
          />
          <Input
            withLabel
            label={t('info.email')}
            value={farmer.email}
            classLabel={'bg-card'}
            disabled
          />
          <Input
            withLabel
            label={t('info.phone')}
            value={farmer.contact}
            classLabel={'bg-card'}
            disabled
          />
        </div>
      </TabView>
    )
  }

  return (
    <TabView>
      <Text size={'medium'}>{t('info.fInfo')}</Text>
      <div
        className={'grid grid-cols-[repeat(auto-fit,minmax(240px,1fr))] gap-1 sm:gap-4 list-none'}
      >
        <Input
          withLabel
          label={t('info.forename')}
          value={farmer.firstname}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('info.surname')}
          value={farmer.surname}
          classLabel={'bg-card'}
          disabled
        />
        <Input withLabel label={'Omang'} value={farmer.omang} classLabel={'bg-card'} disabled />
        <Input
          withLabel
          label={t('info.pob')}
          value={farmer.individual_pob}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('info.dob')}
          value={moment(farmer.individual_dob).format('DD-MM-YYYY')}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('info.gender')}
          value={farmer.individual_gender}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('info.physical')}
          value={farmer.physical_addr ?? ''}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('info.postal')}
          value={farmer.postal_addr ?? ''}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('info.email')}
          value={farmer.email}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('info.phone')}
          value={farmer.contact}
          classLabel={'bg-card'}
          disabled
        />
      </div>
    </TabView>
  )
}
