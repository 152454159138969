import { type ApiResponse, type ApiResponseWithObject } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type CompanyLoanResponse } from '../types'

export const getCompanyLoan = async (
  farmerId: number,
  loan_application_id: number
): Promise<ApiResponse<CompanyLoanResponse>> => {
  return axios.get(
    `/loan/individual?farmer_id=${farmerId}&loan_application_id=${loan_application_id}`
  )
}

export const useGetCompanyLoan = (
  farmerId: number,
  loan_application_id: number
): UseQueryResult<ApiResponseWithObject<CompanyLoanResponse>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['loan_application_id', loan_application_id],
    queryFn: async () => getCompanyLoan(farmerId, loan_application_id)
  })
}
