import type { SoilSampleRequest } from '../../../../../types'
import type React from 'react'
import type z from 'zod'
import { coerce, object } from 'zod'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSSR } from '../../hooks/useSSR'
import {
  Button,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Loader,
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
  Text
} from '../../../../../components/ui'
import { useGetMerchants } from '../../api/getMerchants'
import { Error } from '../../../../../components/Errors/Error'
import { Check, Info } from 'lucide-react'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from 'components/ui/command'
import { cn } from '../../../../../lib/utils'
import { useTranslation } from 'react-i18next'

interface UpdateSSRProps {
  cancel: () => void
  ssr: SoilSampleRequest
}

const schema = object({
  merchantID: coerce.number({
    required_error: 'Lab is required.',
    invalid_type_error: 'Lab is required.'
  })
})

export const UpdateSSR: React.FC<UpdateSSRProps> = ({ cancel, ssr }) => {
  const { farmer } = useFarmerStore()
  const { updateSSR, deleteSSR, isLoading } = useSSR(farmer, cancel)
  const {
    data: merchants,
    isLoading: isLoadingMerchants,
    error: merchantsError
  } = useGetMerchants()
  const { t } = useTranslation('landManagement')

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      merchantID: ssr?.merchant_id
    }
  })

  const onSubmit = (data: z.infer<typeof schema>): void => {
    updateSSR(data, ssr)
  }

  const onDelete = (data: z.infer<typeof schema>): void => {
    deleteSSR(ssr)
  }

  if (isLoadingMerchants) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (merchantsError != null) {
    if (merchantsError?.response?.status !== 404) {
      return <Error />
    }
  }

  if (merchants?.data == null) {
    return <Error />
  }

  const soilLabs = merchants.data

  return (
    <div className='w-full flex flex-col p-6'>
      <Form {...form}>
        <form className={'w-full flex flex-col h-full'} onSubmit={form.handleSubmit(onSubmit)}>
          <div className='w-full flex flex-col'>
            <FormField
              control={form.control}
              name='merchantID'
              render={({ field }) => (
                <FormItem className='w-full flex flex-col gap-4'>
                  <FormLabel>
                    {' '}
                    <Text size='medium'>{t('ssr.reqSSR')} </Text>
                  </FormLabel>
                  <FormDescription className={'text-center'}>
                    <div className='flex items-center'>
                      <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
                      <Text variant='secondary' size='small' className=''>
                        {t('ssr.make')}
                      </Text>
                    </div>
                  </FormDescription>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant='outlined'
                          role='combobox'
                          className={cn(
                            'justify-between',
                            field.value != null && 'text-muted-foreground'
                          )}
                        >
                          {field.value != null
                            ? soilLabs.find((lab) => lab.merchant_id === field.value)?.merchant_name
                            : `${t('ssr.select')}`}
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className='p-0'>
                      <Command>
                        <CommandInput placeholder={`${t('ssr.search')}`} />
                        <PopoverClose asChild>
                          <CommandList>
                            <CommandEmpty>{t('ssr.noLab')}</CommandEmpty>
                            <CommandGroup itemType={'button'}>
                              {soilLabs.map((lab) => (
                                <CommandItem
                                  value={lab.merchant_name}
                                  key={lab.merchant_id}
                                  onSelect={(value) => {
                                    form.setValue('merchantID', lab.merchant_id)
                                  }}
                                >
                                  <Check
                                    className={cn(
                                      'mr-2 h-4 w-4',
                                      lab.merchant_id === field.value ? 'opacity-100' : 'opacity-0'
                                    )}
                                  />
                                  {lab.merchant_name}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </CommandList>
                        </PopoverClose>
                      </Command>
                    </PopoverContent>
                  </Popover>

                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          {/* Buttons pushed to the bottom */}
          <div className='w-full flex justify-between mt-auto'>
            <Button
              variant={'destructive'}
              size={'xs'}
              className='w-32'
              onClick={form.handleSubmit(onDelete)}
              loading={isLoading}
              disabled={isLoading}
            >
              <Text variant='colorText'>{t('ssr.delete')}</Text>
            </Button>
            <Button
              variant={'success'}
              size={'xs'}
              className='w-32'
              type={'submit'}
              loading={isLoading}
              disabled={isLoading || ssr.status_name === 'IN PROCESS'}
            >
              <Text variant='colorText'>{t('ssr.update')}</Text>
            </Button>
          </div>
        </form>
      </Form>
    </div>
  )
}
