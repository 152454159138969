import { type InventoryTransfer } from '../../types'
import type React from 'react'
import { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DropdownMenuContent,
  DropdownMenuItem,
  Text
} from '../../../../../components/ui'
import { Check, Trash2 } from 'lucide-react'
import { LoadingDialog } from '../../../../../components/ui/loading-dialog'
import { LocationConfirmation } from './LocationConfirmation'
import { RejectTransfer } from './RejectTransfer'
import { useTranslation } from 'react-i18next'

interface AcceptRejectTransferProps {
  transferRequest?: InventoryTransfer
}

export const AcceptRejectTransfer: React.FC<AcceptRejectTransferProps> = ({ transferRequest }) => {
  const [open, setOpen] = useState(false)
  const [action, setAction] = useState<'accept' | 'reject' | null>(null) // State to track the current action

  const { t } = useTranslation('inventory')

  const handleCloseOpen = (): void => {
    setOpen(false)
    setAction(null)
  }

  const handleAccept = (): void => {
    setAction('accept')
    setOpen(true)
  }

  const handleReject = (): void => {
    setAction('reject')
    setOpen(true)
  }

  return (
    <>
      <DropdownMenuContent align='end'>
        <DropdownMenuItem onClick={handleAccept}>
          <Text>
            <Check className={'h-[1rem] text-success'} />
          </Text>
          <Text>{t('transfer.accept')}</Text>
        </DropdownMenuItem>

        <DropdownMenuItem onClick={handleReject}>
          <Text>
            <Trash2 className={'h-[1rem] text-destructive'} />
          </Text>
          <Text>{t('transfer.reject')}</Text>
        </DropdownMenuItem>
      </DropdownMenuContent>

      <LoadingDialog key={'inventory'} modal defaultOpen={false} />

      <Dialog open={open} defaultOpen={false} modal onOpenChange={handleCloseOpen}>
        <DialogContent className={'bg-card w-full md:w-[50%] lg:w-[35%] max-w-[430px]'}>
          {action === 'accept' && (
            <LocationConfirmation transferRequest={transferRequest} onClose={handleCloseOpen} />
          )}
          {action === 'reject' && (
            <RejectTransfer transferRequest={transferRequest} onClose={handleCloseOpen} />
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}
