import { type FC } from 'react'
import { type Farm } from 'types'
import { TabView } from 'components/App/Layout'
import { Text } from '../../../../../components/ui'
import { CircleOff } from 'lucide-react'
import { ManageLease } from '../farm-details/ManageLease'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { useGetFarmLeases } from '../../api/getLeases'
import { Loader } from 'components/ui/loader'
import { Error } from '../../../../../components/Errors/Error'
import { LeaseHistory } from '../leases/LeaseHistory'
import { leaseHistoryColumns } from '../leases/columns'
import { useTranslation } from 'react-i18next'

interface LeasesProps {
  farm: Farm
}

export const Leases: FC<LeasesProps> = ({ farm }) => {
  const { t } = useTranslation('landManagement')
  const { farmer } = useFarmerStore()
  const { data: leases, isLoading, error } = useGetFarmLeases(farmer?.farmer_id, farm.farm_id)

  if (isLoading) {
    return (
      <TabView className={'grid place-items-center h-full flex-1'}>
        <Loader />
      </TabView>
    )
  }

  if (error != null && error.response?.status !== 404) {
    return (
      <TabView className={'grid place-items-center'}>
        <Error />
      </TabView>
    )
  }

  if (leases?.data === undefined) {
    return (
      <TabView>
        <div className={'flex justify-between items-center'}>
          <Text size={'medium'}>{t('lease.history')}</Text>
          <ManageLease farm={farm} />
        </div>
        <div className={'min-h-[300px] flex flex-col gap-4 items-center justify-center'}>
          <Text size={'xLarge'} className={'opacity-40'}>
            <CircleOff />
          </Text>
          <Text size={'medium'} variant={'bodyTextLight'}>
            {t('lease.none')}
          </Text>
        </div>
      </TabView>
    )
  }

  const leaseHistory = leases?.data

  return (
    <TabView>
      <div className={'flex justify-between items-center'}>
        <Text size={'medium'}>{t('lease.history')}</Text>
        <ManageLease farm={farm} />
      </div>
      <LeaseHistory columns={leaseHistoryColumns(t)} data={leaseHistory} />
    </TabView>
  )
}