import type React from 'react'
import { useForm } from 'react-hook-form'
import { type Farm } from 'types'
import type z from 'zod'
import { coerce, object, string } from 'zod'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Text
} from 'components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNewFarm } from '../../hooks/useNewFarm'
import { useTranslation } from 'react-i18next'

const schema = object({
  farmName: string().min(1, 'Farm name is required'),
  size: coerce
    .number({ required_error: 'Size is required', invalid_type_error: 'Size should be a number' })
    .min(1, 'Size should be greater than 1')
    .nonnegative('Size cannot be smaller than 1')
})

interface EditFarmFormProps {
  className?: string
  farm: Farm
  next: () => void
}

export const EditFarm: React.FC<EditFarmFormProps> = ({ farm, next }) => {
  const { loading, editNewFarm } = useNewFarm(farm)
  const { t } = useTranslation('landManagement')

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      size: farm.size,
      farmName: farm.farm_name
    }
  })

  const onSubmit = (data: z.infer<typeof schema>): void => {
    editNewFarm(farm, { farmName: data.farmName, size: data.size }, next)
  }

  return (
    <Form {...form}>
      <form
        className={'w-full flex flex-col gap-4 md:gap-8'}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Text className={'text-center'} size={'large'} variant={'colorText'}>
          {t('edit.edit')}
        </Text>

        <FormField
          control={form.control}
          name={'farmName'}
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel>{t('edit.name')}</FormLabel>
              <FormControl>
                <Input
                  placeholder='Farm name'
                  classLabel={'bg-background-dark sm:bg-card'}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        ></FormField>

        <FormField
          control={form.control}
          name={'size'}
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel>{t('edit.size')}</FormLabel>
              <FormControl>
                <Input
                  classLabel={'bg-background-dark sm:bg-card'}
                  placeholder={'Farm size'}
                  type={'text'}
                  inputMode={'numeric'}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        ></FormField>
        <div className={'w-full flex justify-between gap-4 md:gap-8'}>
          <Button variant={'secondary'} className={'w-1/2'} onClick={next}>
            {t('edit.cancel')}
          </Button>
          <Button
            disabled={loading}
            loading={loading}
            className={'w-1/2'}
            onClick={form.handleSubmit(onSubmit)}
          >
            {t('edit.update')}
          </Button>
        </div>
      </form>
    </Form>
  )
}