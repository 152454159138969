import type React from 'react'
import { Checkbox, FormControlLabel } from '@mui/material'
import { Text } from '../../../../../components/ui'

interface CustomCheckBoxGroupProps {
  labels: string[]
  className?: string
  checkedCheckboxes?: boolean[]
  onCheckboxChange?: (index: number) => void
}

export const CustomCheckBoxGroup: React.FC<CustomCheckBoxGroupProps> = ({
  labels,
  className,
  checkedCheckboxes,
  onCheckboxChange
}) => {
  const handleCheckboxChange = (index: number): void => {
    const updatedCheckboxes: boolean[] = checkedCheckboxes != null ? [...checkedCheckboxes] : []

    if (updatedCheckboxes[index] !== undefined) {
      updatedCheckboxes[index] = !updatedCheckboxes[index]
      if (onCheckboxChange != null) {
        onCheckboxChange(index)
      }
    }
  }

  return (
    <div className={className}>
      {labels.map((label, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              checked={checkedCheckboxes != null ? checkedCheckboxes[index] : false}
              onChange={() => {
                handleCheckboxChange(index)
              }}
            />
          }
          label={
            <Text size='body' className=''>
              {label}
            </Text>
          }
        />
      ))}
    </div>
  )
}
