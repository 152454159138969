import type React from 'react'
import { motion } from 'framer-motion'
import WhyCard from './WhyCard'
import { useTranslation } from 'react-i18next'

/**
 * A React functional component that renders the "Why Choose Temo Letlotlo CMS?" section.
 * This section includes a title, a description, and a card component that displays reasons to choose Temo Letlotlo CMS.
 *
 * @returns {JSX.Element} The rendered component.
 */
export const WhyChooseTemo: React.FC = () => {
  const { t } = useTranslation('landing')
  return (
    <div className='flex flex-col py-20 px-10 bg-blue-50 space-y-8'>
      <motion.div
        className='container mx-auto flex flex-col md:justify-center md:items-center space-y-12 mb-12'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className='text-3xl md:text-4xl lg:text-5xl font-bold text-gray-700 landing-page'>
          {t('cms.why')}
        </h2>
        <p className='text-base md:text-xl lg:text-2xl font-regular text-center text-gray-600 md:px-[10vw] lg:px-[20vw] xl:px-0 landing-page'>
          {t('cms.temo')}
        </p>
      </motion.div>
      <motion.div
        className='flex flex-col justify-center items-center'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <WhyCard />
      </motion.div>
    </div>
  )
}