import type React from 'react'
import { useState } from 'react'
import { Button, Text } from 'components/ui'
import moalogo from 'assets/landing/logos/moalogo.svg'
import repair from 'assets/repair.png'
import { useTheme } from 'next-themes'
import { LanguageToggle } from '../Translations/LanguageToggle'
import { Translations } from '../Translations/translations'
export const UnderMaintenance: React.FC = () => {
  const [language, setLanguage] = useState<'en' | 'tswana'>('en')
  const { theme, setTheme } = useTheme()

  const toggleTheme = (): void => {
    setTheme(theme === 'dark' ? 'light' : 'dark')
  }
  const toggleLanguage = (): void => {
    setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'tswana' : 'en'))
  }
  const year = new Date().getFullYear()
  return (
    <div
      className={
        'bg-background max-w-[1300px] mx-auto h-screen w-full flex flex-col justify-between text-center py-4 md:py-8 lg:py-12 px-4'
      }
    >
      <div className={'w-full h-[5rem] flex items-center justify-between'}>
        <div className='flex gap-4 items-center'>
          <img className='w-[clamp(3rem,8vw,4rem)]' src={moalogo} alt={moalogo} />
          <Text className='font-semibold hidden sm:flex'>Ministry Of Agriculture</Text>
        </div>
        <div className={'flex items-center gap-2 sm:gap-4 md:gap-8'}>
          <Button size={'icon'} variant={'ghost'} onClick={toggleTheme}>
            <span
              className={
                theme === 'dark'
                  ? 'text-foreground fa-solid fa-moon'
                  : 'text-foreground fa-solid fa-sun'
              }
            ></span>
          </Button>
          <LanguageToggle language={language} toggleLanguage={toggleLanguage} />
        </div>
      </div>
      <div
        className={'flex flex-col gap-4 max-h-[80vh] mb-12 justify-center items-center'}
        role='alert'
      >
        <img src={repair} alt='maintenance icon' className='w-[clamp(150px,40vw,300px)]' />
        <Text size={'xLarge'} variant={'primaryLight'}>
          {Translations[language].title}
        </Text>
        <Text className={'text-center'} size={'medium'}>
          {Translations[language].message}
        </Text>
      </div>
      <Text>Temo Crop Management System @{year}</Text>
    </div>
  )
}
