import type React from 'react'
import { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { Card, Loader, Text } from 'components/ui'
import { useNavigate, useParams } from 'react-router-dom'
import { useSearchTransfer } from '../hooks/useSearchTransfer'
import { TransferRequestCard } from '../../../farmer/inventory/components/AcceptRejectTransfer/TransferRequestCard'
import { useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const TransferRequests: React.FC = () => {
  const { idType } = useParams<{ idType: string }>()
  const { t } = useTranslation('inventory')
  const { idInput } = useParams<{ idInput: string }>()
  const { searchInventoryTransfer, error, data: transfers, isLoading } = useSearchTransfer()
  const navigate = useNavigate()
  const isSmallScreen = useMediaQuery('(max-width:945px)')
  useEffect(() => {
    if (idType != null && idInput != null) {
      void searchInventoryTransfer(idType === 'merchant' ? 'merchantId' : 'farmerId', idInput)
    }
    if (error !== null) {
      toast.error(error, { id: error })
    }
  }, [])

  const handleBack = (): void => {
    navigate('/inventory', { replace: true })
  }

  return (
    <div className={'max-w-[1700px] w-full flex-col gap-4  flex justify-center mx-auto px-0 py-4'}>
      <button onClick={handleBack} className={'flex items-center gap-4 cursor-pointer group'}>
        <Text variant={'bodyTextLight'} size={'large'} className={'group-hover:text-primary'}>
          <p className='fa-solid fa-arrow-left' />
        </Text>
        <div className={'flex gap-1 items-baseline'}>
          <Text size={'large'} className={'group-hover:text-secondary capitalize'}>
            {t('mech.mtr')}
          </Text>
        </div>
      </button>
      <Card
        className={
          'w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
        }
      >
        {isLoading ? (
          <div className={'mt-8 grid place-items-center h-full flex-1'}>
            <Loader />
          </div>
        ) : null}

        {error !== null ? (
          <Text size={'medium'} variant={'error'} className={'ml-4'}>
            {error}
          </Text>
        ) : null}

        {error === null && !isLoading && transfers?.length === 0 ? (
          <Text className={'text-center'} variant={'primary'} size={'medium'}>
            {t('mech.nosr')}
          </Text>
        ) : null}

        {transfers !== null ? (
          <div
            className={`grid gap-6 lg:grid-cols-3 ${isSmallScreen ? 'grid-cols-1' : 'grid-cols-2'}`}
          >
            {transfers?.map((offer) => (
              <TransferRequestCard key={offer.transfer_id} transferRequest={offer} />
            ))}
          </div>
        ) : null}
      </Card>
    </div>
  )
}