import type React from 'react'
import { useOfficerStore } from '../../../../stores/useOfficerStore'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Loader
} from '../../../../components/ui'
import { ErrorPage } from '../../../../components/Errors/ErrorPage'
import { Link, useParams } from 'react-router-dom'
import { Grow, useMediaQuery } from '@mui/material'
import { useGetAllInventories } from '../api/getAllInventories'
import { MOInventoryCard } from '../../../mechanisationOfficer/inventory/components/MOInventoryCard'
import { useAuthenticationStore } from '../../../../stores/useAuthenticationStore'
import { useTranslation } from 'react-i18next'

export const ViewAllInventories: React.FC = () => {
  const { officer } = useOfficerStore()
  const { role } = useAuthenticationStore()
  const { t } = useTranslation('extensionOfficer')
  const { userType, Id } = useParams<{ userType: string; Id: string }>()
  const {
    data: inventories,
    isLoading,
    error
  } = useGetAllInventories(officer?.ext_area_id, userType, Id)

  const isSmallScreen = useMediaQuery('(max-width:945px)')
  if (isLoading) {
    return (
      <div className={'grid place-items-center min-h-[30%] h-full flex-1'}>
        <Loader />
      </div>
    )
  }

  if (error != null && error.response?.status !== 404) {
    return <ErrorPage />
  }

  const filteredInventories =
    inventories?.data?.filter(
      (inventory) => inventory.item_verification_status !== 'PENDING_VERIFICATION'
    ) ?? []
  return (
    <div className={'w-full flex flex-col gap-4'}>
      <div className={'flex justify-between items-center border-b border-border p-4 mb-10'}>
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link className={'text-lg'} to={`${role === 1001 ? '/officer' : ''}/inventory`}>
                  {t('inv.inv')}
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <Grow in={true}>
        <div
          className={`grid gap-6 lg:grid-cols-2 ${isSmallScreen ? 'grid-cols-1' : 'grid-cols-2'}`}
        >
          {filteredInventories.map((inventory) => {
            return <MOInventoryCard key={inventory.inventory_id} inventoryData={inventory} />
          })}
        </div>
      </Grow>
    </div>
  )
}
