import { Button, buttonVariants } from 'components/ui/button'
import { cn } from 'lib/utils'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import * as React from 'react'
import { DayPicker } from 'react-day-picker'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './select'
import { PopoverClose } from './popover'

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps & { onChange?: React.ChangeEventHandler<HTMLSelectElement> }) {
  const handleCalendarChange = (
    _value: string | number,
    _e: React.ChangeEventHandler<HTMLSelectElement>
  ) => {
    const _event = {
      target: {
        value: String(_value)
      }
    } as React.ChangeEvent<HTMLSelectElement>
    _e(_event)
  }

  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 justify-center',
        month: 'space-y-4',
        caption_start: 'is-start',
        caption_between: 'is-between',
        caption_end: 'is-end',
        caption: 'flex justify-center pt-1 relative items-center gap-1',
        caption_label:
          'flex h-7 text-sm font-medium justify-center items-center grow [.is-multiple_&]:flex',
        caption_dropdowns: 'flex justify-center gap-1 grow dropdowns pl-8 pr-9',
        multiple_months: 'is-multiple',
        vhidden: 'hidden [.is-between_&]:flex [.is-end_&]:flex [.is-start.is-end_&]:hidden',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          buttonVariants({ variant: 'outlined' }),
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100'
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: cn(
          'h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
          props.mode === 'range'
            ? '[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md'
            : '[&:has([aria-selected])]:rounded-md'
        ),
        day: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-9 w-9 p-0 font-normal aria-selected:opacity-100'
        ),
        day_range_start: 'day-range-start',
        day_range_end: 'day-range-end',
        day_selected:
          'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
        day_today: 'bg-accent text-accent-foreground',
        day_outside:
          'day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
        day_disabled: 'text-muted-foreground opacity-50',
        day_range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
        day_hidden: 'invisible'
      }}
      components={{
        IconLeft: ({ ...props }) => <ChevronLeft className='h-4 w-4' />,
        IconRight: ({ ...props }) => <ChevronRight className='h-4 w-4' />,
        DayContent: (props) => {
          return <PopoverClose className='h-full w-full'>{props.date.getDate()}</PopoverClose>
        },
        Dropdown: ({ ...props }) => (
          <div className='inline-flex gap-2'>
            <Select
              {...props}
              onValueChange={(value) => {
                if (props.onChange != null) {
                  handleCalendarChange(value, props.onChange)
                }
              }}
              value={props.value as string}
            >
              <SelectTrigger className='w-fit border-none p-0 focus:bg-accent focus:text-accent-foreground'>
                <SelectValue placeholder={props?.caption}>{props?.caption}</SelectValue>
              </SelectTrigger>
              <SelectContent>
                {props.children &&
                  React.Children.map(props.children, (child) => (
                    <SelectItem value={(child as React.ReactElement<any>)?.props?.value}>
                      {(child as React.ReactElement<any>)?.props?.children}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
          </div>
        )
      }}
      {...props}
    />
  )
}

Calendar.displayName = 'Calendar'

export { Calendar }
