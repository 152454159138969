import type React from 'react'
import { type CroppingPlan, CroppingPlanHeaderStatus } from 'types'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from 'components/ui'
import { useTranslation } from 'react-i18next'

interface CroppingPlanTableProps {
  croppingPlans: CroppingPlan[]
  onClick: (item: CroppingPlan) => void
}

export const CroppingPlanTable: React.FC<CroppingPlanTableProps> = ({ croppingPlans, onClick }) => {
  const { t } = useTranslation('extensionOfficer')
  return (
    <Table>
      <TableCaption>{t('cp.list')}</TableCaption>
      <TableHeader>
        <TableRow>
          <TableHead className='font-bold text-color-text'>{t('cp.fid')}</TableHead>
          <TableHead className='font-bold text-color-text'>{t('cp.ps')}</TableHead>
          <TableHead className='font-bold text-color-text'>{t('cp.crop')}</TableHead>
          <TableHead className='font-bold text-color-text'>{t('cp.fn')}</TableHead>
          <TableHead className='font-bold text-color-text'>{t('cp.ha')}</TableHead>
          <TableHead className='font-bold text-color-text'>{t('cp.cost')}</TableHead>
          <TableHead className='font-bold text-color-text'>{t('cp.status')}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {croppingPlans.map((cp) => (
          <TableRow
            onClick={() => {
              onClick(cp)
            }}
            key={cp.cropping_plan_id}
            role={'listitem'}
            className={'hover:cursor-pointer hover:bg-muted-hover'}
          >
            <TableCell className='font-medium'>{cp.farmer_id}</TableCell>
            <TableCell className='font-medium'>{cp.plough_season}</TableCell>
            <TableCell className='font-medium'>{cp.crop_name}</TableCell>
            <TableCell className='font-medium'>{cp.family_name}</TableCell>
            <TableCell className='font-medium'>{cp.hectarage}</TableCell>
            <TableCell className='font-medium'>{cp.total_cost}</TableCell>
            <TableCell className='font-medium'>
              {cp.is_verified === CroppingPlanHeaderStatus.Awaiting_Farm_Assessment
                ? t('cp.fa')
                : t('cp.sfr')}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}