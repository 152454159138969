import type { Adjuvants, Fungicides, Pesticides } from '../../../../types/static'
import { create } from 'zustand'

export interface SelectedFungicides {
  chemical_type: 'fungicide'
  chemical: Fungicides
  adjuvant: Adjuvants | null
}

export interface SelectedPesticides {
  chemical_type: 'insecticide'
  chemical: Pesticides
  adjuvant: Adjuvants | null
}

interface UsePestAndDiseaseStore {
  selectedPesticides: SelectedPesticides[] | null
  setSelectedPesticides: (selectedPesticides: SelectedPesticides[] | null) => void
  selectedFungicides: SelectedFungicides[] | null
  setSelectedFungicides: (selectedFungicides: SelectedFungicides[] | null) => void
}

export const usePestAndDiseaseStore = create<UsePestAndDiseaseStore>()((set) => ({
  selectedPesticides: null,
  selectedFungicides: null,
  setSelectedPesticides: (selectedPesticides: SelectedPesticides[] | null) => {
    set((state) => ({ ...state, selectedPesticides }))
  },
  setSelectedFungicides: (selectedFungicides: SelectedFungicides[] | null) => {
    set((state) => ({ ...state, selectedFungicides }))
  }
}))
