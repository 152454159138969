import type React from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import type z from 'zod'
import { object, string, type TypeOf } from 'zod'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input
} from '../../../../../components/ui'
import { Text } from '../../../../../components/Elements/Text'

interface RejectCroppingPlanProps {
  label: string
  onReject: (data: RejectReasonInput) => void
  onCancel: () => void
  isLoading: boolean
}

const schema = object({
  reason: string().min(1, 'Reason for return is required')
})

export type RejectReasonInput = TypeOf<typeof schema>

export const RejectCroppingPlan: React.FC<RejectCroppingPlanProps> = ({
  label,
  onReject,
  isLoading,
  onCancel
}) => {
  const { t } = useTranslation('extensionOfficer')

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      reason: ''
    }
  })

  const onSubmit: SubmitHandler<RejectReasonInput> = (data: z.infer<typeof schema>) => {
    onReject(data)
  }

  return (
    <Form {...form}>
      <form
        className={'w-full flex flex-col gap-4 md:gap-8'}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Text size={'large'} variant={'primaryLight'} className={'mb-2 mx-3'}>
          {t('cp.reject')} {label}
        </Text>
        <Text size={'body'} variant={'colorText'} className={'mb-2 mx-3'}>
          {t('cp.etr1')}
          {label}
          {t('cp.etr2')}
        </Text>

        <FormField
          control={form.control}
          name={'reason'}
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel className={'ml-2 font-redHat '}>{t('cp.reason')}</FormLabel>
              <FormControl>
                <Input
                  type={'text'}
                  placeholder={`${t('cp.reason')}`}
                  classLabel={'bg-background-dark sm:bg-card'}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className={'w-full flex justify-between gap-4 md:gap-8'}>
          <Button
            variant={'secondary'}
            className={'w-1/2'}
            type={'button'}
            disabled={isLoading}
            onClick={onCancel}
          >
            {t('cp.cancel')}
          </Button>
          <Button className={'w-1/2'} type={'submit'} disabled={isLoading} loading={isLoading}>
            {t('cp.confirm')}
          </Button>
        </div>
      </form>
    </Form>
  )
}
