import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'
import { type GetInventoryRes } from '../../../farmer/inventory/types'

export const getAllInventories = async (
  merchantDistrict: number | null | undefined,
  userType: string | null | undefined,
  userId: string | null | undefined
): Promise<ApiResponse<GetInventoryRes>> => {
  const queryParams = new URLSearchParams()
  if (merchantDistrict != null) {
    queryParams.append('district_id', merchantDistrict.toString())
  }
  if (userType === 'farmer' && userId != null) {
    queryParams.append('farmer_id', userId)
  } else if (userType === 'merchant' && userId != null) {
    queryParams.append('merchant_id', userId)
  }

  return axios.get(`/inventory?${queryParams.toString()}`)
}

export const useGetAllInventories = (
  merchantDistrict: number | null | undefined,
  userType: string | null | undefined,
  userId: string | null | undefined
): UseQueryResult<ApiResponse<GetInventoryRes>, AxiosError<[]>> => {
  return useQuery({
    queryKey: ['mo-inventories', merchantDistrict, userType, userId],
    queryFn: async () => getAllInventories(merchantDistrict, userType, userId),
    enabled: merchantDistrict != null && userType != null && userId != null
  })
}
