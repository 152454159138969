// programPackages.ts

/**
 * Interface representing the structure of a requirement.
 */
interface Requirement {
  title: string
  description: string
}

/**
 * Interface representing the structure of a benefit.
 */
interface Benefit {
  title: string
  description: string
}

/**
 * Interface representing the structure of an obligation.
 */
interface Obligation {
  description: string
}

/**
 * Interface representing the structure of the program packages.
 */
interface ProgramPackages {
  programDescription: string
  eligibilityRequirements: Requirement[]
  keyBenefits: Benefit[]
  obligations: Obligation[]
}

/**
 * Constant containing the details of the program packages.
 */
const programPackages = (t: (key: string) => string): ProgramPackages => {
  return {
    programDescription: t('grant.desc'),

    eligibilityRequirements: [
      {
        title: t('grant.et1'),
        description: t('grant.ed1')
      },
      {
        title: t('grant.et2'),
        description: t('grant.ed2')
      },
      {
        title: t('grant.et3'),
        description: t('grant.ed3')
      },
      {
        title: t('grant.et4'),
        description: t('grant.ed4')
      }
    ],

    keyBenefits: [
      {
        title: t('grant.kt1'),
        description: t('grant.kd1')
      },
      {
        title: t('grant.kt2'),
        description: t('grant.kd2')
      },
      {
        title: t('grant.kt3'),
        description: t('grant.kd3')
      }
    ],

    obligations: [
      {
        description: t('grant.o1')
      },
      {
        description: t('grant.o2')
      },
      {
        description: t('grant.o3')
      }
    ]
  }
}

export default programPackages