// features.ts
import Puzzle from 'assets/landing/icons/puzzle_platform.svg'
import Financials from 'assets/landing/icons/finance_support.svg'
import ProductivityTools from 'assets/landing/icons/productivity.svg'

interface Why {
  icon: string
  heading: string
  description: string
}

export const features = (t: (key: string) => string): Why[] => {
  return [
    {
      icon: Puzzle,
      heading: t('cms.wh1'),
      description: t('cms.wd1')
    },
    {
      icon: Financials,
      heading: t('cms.wh2'),
      description: t('cms.wd2')
    },
    {
      icon: ProductivityTools,
      heading: t('cms.wh3'),
      description: t('cms.wd3')
    }
  ]
}
