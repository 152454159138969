import { create } from 'zustand'
import { type Adjuvants, type Herbicides } from '../../../../types/static'

export interface SelectedHerbicide {
  schedule: string
  herbicide: Herbicides
  adjuvant: Adjuvants | null
}

export interface AvailableSchedules {
  PE: SelectedHerbicide[] | null | undefined
  PrP: SelectedHerbicide[] | null | undefined
  PrE: SelectedHerbicide[] | null | undefined
}

interface UseWeedingStore {
  schedule: string | null
  setSchedule: (schedule: string | null) => void
  selectedHerbicides: AvailableSchedules | null
  setSelectedHerbicides: (selectedHerbicides: AvailableSchedules | null) => void
}

export const useWeedingStore = create<UseWeedingStore>()((set) => ({
  schedule: null,
  selectedHerbicides: null,
  setSchedule: (schedule: string | null) => {
    set((state) => ({ ...state, schedule }))
  },
  setSelectedHerbicides: (selectedHerbicides: AvailableSchedules | null) => {
    set((state) => ({ ...state, selectedHerbicides }))
  }
}))
