import type React from 'react'
import { useState } from 'react'
import {
  clearLoginAttemptInfo,
  consumeCode,
  createCode,
  doesPhoneNumberExist,
  resendCode
} from 'supertokens-web-js/lib/build/recipe/passwordless'
import { doesEmailExist, signIn } from 'supertokens-web-js/recipe/emailpassword'
import { useNavigate } from 'react-router-dom'
import { useToast } from 'components/ui/use-toast'
import STGeneralError from 'supertokens-web-js/lib/build/error'
import { AxiosError } from 'axios'
import Session from 'supertokens-auth-react/recipe/session'
import { useIndividualRegistrationStore } from '../stores/useIndividualRegistrationStore'
import dayjs from 'dayjs'
import type { RegisterIndividualFarmerDTO } from '../api/createFarmer'
import { useOtpStore } from '../../../stores/useOtpStore'
import { useTranslation } from 'react-i18next'
import { useAuthenticationStore } from '../../../stores/useAuthenticationStore'

interface UseAuth {
  isLoading: boolean
  sendOtp: (number: string) => Promise<void>
  sendOtpRegistration: (
    number: string,
    showOTP: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>
  resendOtp: () => Promise<void>
  emailSignIn: (email: string, password: string) => Promise<void>
  logOut: () => Promise<void>
  handleOTPInput: (otp: string) => Promise<void>
  registerFarmer: (otp: string, next: () => void) => Promise<void>
}

interface SessionRole {
  role: number
}

interface ConsumeCodeInitialBody {
  userInputCode: string
  deviceId: string
  preAuthSessionId: string
}

interface ConsumeCodeFinalBody {
  userInputCode: string
  deviceId: string
  preAuthSessionId: string
  farmerData: RegisterIndividualFarmerDTO
}

export const useSuperTokens = (): UseAuth => {
  const { toast } = useToast()
  const { setNumber } = useOtpStore()
  const { t } = useTranslation('genericTranslation')
  const { setIsLogout } = useAuthenticationStore()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { contactDetails, omangDetails, setContactDetails } = useIndividualRegistrationStore()

  const getNavRoute = (role: number | unknown): void => {
    if (role === 1001) {
      navigate('/officer', { replace: true })
    } else if (role === 1000) {
      navigate('/farmer', { replace: true })
    } else if (role === 2000) {
      navigate('/soil-lab', { replace: true })
    } else if (role === 1010) {
      navigate('/inventory', { replace: true })
    } else if (role === 2001) {
      navigate('/service-provider', { replace: true })
    } else if (role === 2004) {
      navigate('/seed-dealer', { replace: true })
    } else if (role === 2003) {
      navigate('/agrochemicals', { replace: true })
    } else if (role === 2002) {
      navigate('/chemical-seed-dealer', { replace: true })
    } else {
      navigate('/officer', { replace: true })
    }
  }

  // first step for phone login, sending an otp
  async function sendOtp(number: string): Promise<void> {
    // set error to null when sending otp
    // set loading state to true
    setIsLoading(true)
    setNumber(number)

    try {
      const checkNumberExists = await doesPhoneNumberExist({
        phoneNumber: number
      })

      // check if a number exists before sending otp for login and does not exist for registration
      if (checkNumberExists.doesExist) {
        // send otp to number
        const response = await createCode({
          phoneNumber: number
        })

        // response from sending otp
        // ok - for when otp successfully sent
        // SIGN_IN_UP_NOT_ALLOWED - when an error occurred
        if (response.status === 'SIGN_IN_UP_NOT_ALLOWED') {
          toast({
            variant: 'destructive',
            title: t('errors.uhOh'),
            description: t('errors.tryAgain')
          })
        } else {
          toast({
            variant: 'success',
            description: t('errors.otp')
          })
        }
      } else {
        toast({
          variant: 'destructive',
          title: t('errors.uhOh'),
          description: t('errors.userDNE')
        })
      }
    } catch (err: unknown) {
      // check if error is STGeneralError for login related errors or AxiosError for api related
      // errors
      if (err instanceof STGeneralError && err.isSuperTokensGeneralError) {
        // this may be a custom error message sent from the API by you,
        // or if the input email / phone number is not valid.
        toast({
          variant: 'destructive',
          title: t('errors.uhOh'),
          description: `${err.message}!`
        })
      } else if (err instanceof AxiosError) {
        toast({
          variant: 'destructive',
          title: t('errors.uhOh'),
          description: t('errors.userDNE')
        })
      } else {
        toast({
          variant: 'destructive',
          title: t('errors.uhOh'),
          description: t('errors.tryAgain')
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  async function sendOtpRegistration(
    number: string,
    showOTP: React.Dispatch<React.SetStateAction<boolean>>
  ): Promise<void> {
    // set error to null when sending otp
    // set loading state to true
    setIsLoading(true)

    try {
      const checkNumberExists = await doesPhoneNumberExist({
        phoneNumber: number
      })

      // check if a number exists before sending otp for login and does not exist for registration
      if (!checkNumberExists.doesExist) {
        setContactDetails(
          contactDetails === null
            ? {
                number: number.replace(/\s/g, ''),
                email: null,
                physicalAddress: null,
                postalAddress: null
              }
            : {
                ...contactDetails,
                number: number.replace(/\s/g, '')
              }
        )
        // send otp to number
        const response = await createCode({
          phoneNumber: number
        })

        // response from sending otp
        // ok - for when otp successfully sent
        // SIGN_IN_UP_NOT_ALLOWED - when an error occurred
        if (response.status === 'SIGN_IN_UP_NOT_ALLOWED') {
          toast({
            variant: 'destructive',
            title: t('errors.uhOh'),
            description: t('errors.tryAgain')
          })
        } else {
          showOTP(true)
          toast({
            variant: 'success',
            description: t('errors.otp')
          })
        }
      } else {
        toast({
          variant: 'destructive',
          title: t('errors.uhOh'),
          description: t('errors.userAE')
        })
      }
    } catch (err: unknown) {
      // check if error is STGeneralError for login related errors or AxiosError for api related
      // errors
      if (err instanceof STGeneralError && err.isSuperTokensGeneralError) {
        // this may be a custom error message sent from the API by you,
        // or if the input email / phone number is not valid.
        toast({
          variant: 'destructive',
          title: t('errors.uhOh'),
          description: `${err.message}!`
        })
      } else {
        toast({
          variant: 'destructive',
          title: t('errors.uhOh'),
          description: t('errors.tryAgain')
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  // called when resending otp during login process
  async function resendOtp(): Promise<void> {
    setIsLoading(true)

    try {
      const response = await resendCode()

      if (response.status === 'RESTART_FLOW_ERROR') {
        // this can happen if the user has already successfully logged in into
        // another device whilst also trying to login to this one.

        // we clear the login attempt info that was added when the createCode function
        // was called - so that if the user does a page reload, they will now see the
        // enter email / phone UI again.
        await clearLoginAttemptInfo()
        toast({
          variant: 'destructive',
          title: t('errors.uhOh'),
          description: t('errors.loginFailed')
        })
      } else {
        // OTP resent successfully.
        toast({
          variant: 'success',
          description: t('errors.otp')
        })
      }
    } catch (err: unknown) {
      if (err instanceof STGeneralError && err.isSuperTokensGeneralError) {
        // this may be a custom error message sent from the API by you.
        toast({
          variant: 'destructive',
          title: t('errors.uhOh'),
          description: `${err.message}!`
        })
      } else {
        toast({
          variant: 'destructive',
          title: t('errors.uhOh'),
          description: t('errors.tryAgain')
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  // verify otp and login user if successful
  async function handleOTPInput(otp: string): Promise<void> {
    setIsLoading(true)
    setIsLogout(false)
    try {
      const response = await consumeCode({
        userInputCode: otp
      })

      if (response.status === 'OK') {
        // we clear the login attempt info that was added when the createCode function
        // was called since the login was successful.
        await clearLoginAttemptInfo()
        navigate('/farmer', { replace: true })
      } else if (response.status === 'INCORRECT_USER_INPUT_CODE_ERROR') {
        // the user entered an invalid OTP
        toast({
          variant: 'destructive',
          title: 'Wrong OTP!',
          description:
            'Number of attempts left: ' +
            (response.maximumCodeInputAttempts - response.failedCodeInputAttemptCount).toString()
        })
      } else if (response.status === 'EXPIRED_USER_INPUT_CODE_ERROR') {
        // it can come here if the entered OTP was correct, but has expired because
        // it was generated too long ago.
        toast({
          variant: 'destructive',
          title: 'Invalid OTP Entered!',
          description: 'Old OTP entered. Please regenerate a new one and try again'
        })
      } else {
        // this can happen if the user tried an incorrect OTP too many times.
        // or if it was denied due to security reasons in case of automatic account linking

        // we clear the login attempt info that was added when the createCode function
        // was called - so that if the user does a page reload, they will now see the
        // enter email / phone UI again.
        await clearLoginAttemptInfo()
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: 'Login failed. Please try again later.'
        })
        navigate('/login/phone', { replace: true })
      }
    } catch (err: unknown) {
      if (err instanceof STGeneralError && err.isSuperTokensGeneralError) {
        // this may be a custom error message sent from the API by you.
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: `${err.message}!`
        })
      } else {
        toast({
          variant: 'destructive',
          title: t('errors.uhOh'),
          description: t('errors.tryAgain')
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  async function registerFarmer(otp: string, next: () => void): Promise<void> {
    setIsLoading(true)
    setIsLogout(false)
    try {
      const response = await consumeCode({
        userInputCode: otp,
        options: {
          // eslint-disable-next-line @typescript-eslint/require-await
          preAPIHook: async (input) => {
            let requestInit = input.requestInit
            const farmerData: RegisterIndividualFarmerDTO = {
              CONTACT: contactDetails!.number,
              EMAIL: contactDetails?.email ?? '',
              IND_DOB: dayjs(omangDetails?.dateOfBirth).format('YYYY-MM-DD'),
              IND_EXP_DATE: dayjs(omangDetails?.expiryDate).format('YYYY-MM-DD'),
              POSTAL_ADDRESS: contactDetails?.postalAddress ?? '',
              PHYSICAL_ADDRESS: contactDetails?.physicalAddress ?? '',
              IND_FIRST_NAME: omangDetails!.forenames,
              IND_SURNAME: omangDetails!.surname,
              IND_GENDER: omangDetails!.gender,
              FARMER_TYPE: 1,
              IND_POB: omangDetails!.placeOfBirth,
              ID: omangDetails!.omang,
              USER_ROLE: 1000,
              VILLAGE_ID: 1
            }

            const initialBody: ConsumeCodeInitialBody = JSON.parse(
              requestInit.body as string
            ) as ConsumeCodeInitialBody
            const newBodyJSON: ConsumeCodeFinalBody = {
              ...initialBody,
              farmerData: { ...farmerData }
            }
            const newBody = JSON.stringify(newBodyJSON)

            requestInit = {
              ...requestInit,
              body: newBody
            }

            return { url: input.url, requestInit }
          }
        }
      })

      if (response.status === 'OK') {
        // we clear the login attempt info that was added when the createCode function
        // was called since the login was successful.
        await clearLoginAttemptInfo()
        next()
      } else if (response.status === 'INCORRECT_USER_INPUT_CODE_ERROR') {
        // the user entered an invalid OTP
        toast({
          variant: 'destructive',
          title: t('errors.wrongOTP'),
          description:
            t('errors.number') +
            (response.maximumCodeInputAttempts - response.failedCodeInputAttemptCount).toString()
        })
      } else if (response.status === 'EXPIRED_USER_INPUT_CODE_ERROR') {
        // it can come here if the entered OTP was correct, but has expired because
        // it was generated too long ago.
        toast({
          variant: 'destructive',
          title: t('errors.invaliedOTP'),
          description: t('errors.oldOTP')
        })
      } else if (response.status === 'SIGN_IN_UP_NOT_ALLOWED') {
        toast({
          variant: 'destructive',
          title: t('errors.reg'),
          description: t('errors.ensure')
        })
      } else {
        // this can happen if the user tried an incorrect OTP too many times.
        // or if it was denied due to security reasons in case of automatic account linking

        // we clear the login attempt info that was added when the createCode function
        // was called - so that if the user does a page reload, they will now see the
        // enter email / phone UI again.
        await clearLoginAttemptInfo()
        toast({
          variant: 'destructive',
          title: t('errors.uhOh'),
          description: t('errors.regFailed')
        })
        navigate('/login/phone', { replace: true })
      }
    } catch (err: unknown) {
      if (err instanceof STGeneralError && err.isSuperTokensGeneralError) {
        // this may be a custom error message sent from the API by you.
        toast({
          variant: 'destructive',
          title: t('errors.uhOh'),
          description: `${err.message}!`
        })
      } else {
        toast({
          variant: 'destructive',
          title: t('errors.uhOh'),
          description: t('errors.tryAgain')
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  async function emailSignIn(email: string, password: string): Promise<void> {
    setIsLoading(true)
    setIsLogout(false)

    try {
      const checkEmailExists = await doesEmailExist({
        email
      })

      if (!checkEmailExists.doesExist) {
        toast({
          variant: 'destructive',
          description: t('errors.userDNE')
        })
      } else {
        const response = await signIn({
          formFields: [
            {
              id: 'email',
              value: email
            },
            {
              id: 'password',
              value: password
            }
          ]
        })

        if (response.status === 'WRONG_CREDENTIALS_ERROR') {
          toast({
            variant: 'destructive',
            description: t('errors.email')
          })
        } else if (response.status === 'SIGN_IN_NOT_ALLOWED') {
          // this can happen due to automatic account linking. Tell the user that their
          // input credentials is wrong (so that they do through the password reset flow)
          toast({
            variant: 'destructive',
            title: t('errors.uhOh'),
            description: t('errors.loginFailed')
          })
        } else if (response.status === 'FIELD_ERROR') {
          toast({
            variant: 'destructive',
            description: t('errors.email')
          })
        } else {
          // sign in successful. The session tokens are automatically handled by
          // the frontend SDK.
          const res = (await Session.getAccessTokenPayloadSecurely()) as SessionRole
          getNavRoute(res.role)
        }
      }
    } catch (err: unknown) {
      if (err instanceof STGeneralError && err.isSuperTokensGeneralError) {
        // this may be a custom error message sent from the API by you.
        toast({
          variant: 'destructive',
          title: t('errors.uhOh'),
          description: `${err.message}!`
        })
      } else {
        toast({
          variant: 'destructive',
          title: t('errors.uhOh'),
          description: t('errors.tryAgain')
        })
      }
    } finally {
      setIsLoading(false)
    }
  }
  async function logOut(): Promise<void> {
    setIsLogout(true)
    await Session.signOut().then(() => {
      navigate('/login/phone', { replace: true })
    })
  }

  return {
    isLoading,
    handleOTPInput,
    sendOtp,
    resendOtp,
    emailSignIn,
    logOut,
    registerFarmer,
    sendOtpRegistration
  }
}