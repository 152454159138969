import { PasswordCheckList } from 'components/Change-Password/PasswordCheckList'
import { useChangePassword } from 'hooks/useChangePassword'
import type React from 'react'
import { useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import type z from 'zod'
import { object, string, type TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input
} from '../ui'
import { useTranslation } from 'react-i18next'

const schema = object({
  oldPassword: string().min(1, 'old password is required'),
  newPassword: string()
    .min(8, 'The new password must be at least 8 characters long')
    .max(32, 'The new password must be a maximum of 32 characters')
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&*-])[A-Za-z\d!@#$%&*-]{8,}$/),
  confirmPassword: string().min(1, 'confirm password is required')
})
  .refine((fields) => fields.newPassword !== fields.oldPassword, {
    path: ['newPassword'],
    message: 'New Password should be different from old password'
  })
  .refine((fields) => fields.newPassword === fields.confirmPassword, {
    path: ['confirmPassword'],
    message: "Passwords don't match"
  })

type ChangePasswordInput = TypeOf<typeof schema>

export const ChangePasswordForm: React.FC = () => {
  const { t } = useTranslation('authenticationTranslation')
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const { isLoading, updatePassword } = useChangePassword()
  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
      oldPassword: ''
    }
  })

  const onSubmit: SubmitHandler<ChangePasswordInput> = (data: ChangePasswordInput) => {
    updatePassword(data.oldPassword, data.newPassword)
  }

  return (
    <Form {...form}>
      <form
        className={
          'flex flex-col sm:flex-row w-full justify-start sm:justify-around items-center' +
          ' sm:items-start' +
          ' gap-4' +
          ' sm:gap-8'
        }
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <PasswordCheckList password={form.watch('newPassword') ?? ''} />
        <div className={'flex flex-col gap-4'}>
          <FormField
            control={form.control}
            name={'oldPassword'}
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel>{t('pwd.old')}</FormLabel>
                <FormControl>
                  <Input
                    classLabel={'bg-background-dark sm:bg-card'}
                    placeholder={t('pwd.old')}
                    type={showOldPassword ? 'text' : 'password'}
                    isPassword
                    showPassword={showOldPassword}
                    onShowPassword={() => {
                      setShowOldPassword(!showOldPassword)
                    }}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          ></FormField>

          <FormField
            control={form.control}
            name={'newPassword'}
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel>{t('pwd.new')}</FormLabel>
                <FormControl>
                  <Input
                    classLabel={'bg-background-dark sm:bg-card'}
                    placeholder={t('pwd.new')}
                    type={showNewPassword ? 'text' : 'password'}
                    isPassword
                    showPassword={showNewPassword}
                    onShowPassword={() => {
                      setShowNewPassword(!showNewPassword)
                    }}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          ></FormField>

          <FormField
            control={form.control}
            name={'confirmPassword'}
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel>{t('pwd.confirm')}</FormLabel>
                <FormControl>
                  <Input
                    classLabel={'bg-background-dark sm:bg-card'}
                    placeholder={t('pwd.confirm')}
                    type={showConfirmPassword ? 'text' : 'password'}
                    isPassword
                    showPassword={showConfirmPassword}
                    onShowPassword={() => {
                      setShowConfirmPassword(!showConfirmPassword)
                    }}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          ></FormField>
          <Button
            loading={isLoading}
            disabled={isLoading}
            onClick={form.handleSubmit(onSubmit)}
            type={'submit'}
            size={'lg'}
          >
            {t('pwd.reset')}
          </Button>
        </div>
      </form>
    </Form>
  )
}
