import { useGetLeases } from 'features/farmer/lease-management/api/getLeases'
import { useEffect, useState } from 'react'
import { useFarmerStore } from 'stores/useFarmerStore'
import { type Lease } from 'types'
import { type AxiosError } from 'axios'

interface UseMyLeases {
  isLoading: boolean
  leases: Lease[] | undefined
  verified: Lease[] | null
  rejected: Lease[] | null
  terminated: Lease[] | null
  offers: Lease[] | null
  error: AxiosError | null
  eoVerification: Lease[] | null
}

export const useMyLeases = (): UseMyLeases => {
  const { farmer } = useFarmerStore()
  const { data: leases, isLoading, isInitialLoading, error } = useGetLeases(farmer?.farmer_id)
  const [verified, setVerified] = useState<Lease[] | null>(null)
  const [farmerTerminated, setFarmerTerminated] = useState<Lease[] | null>(null)
  const [leaseOffers, setLeaseOffers] = useState<Lease[] | null>(null)
  const [rejected, setRejected] = useState<Lease[] | null>(null)
  const [eoVerification, setEoVerification] = useState<Lease[] | null>(null)

  useEffect(() => {
    if (leases?.status === 200) {
      setVerified(
        leases?.data.filter((res) => {
          return (
            res.status_desc === 'RENEWAL ACCEPTED BY LESSOR' ||
            res.status_desc === 'VERIFIED BY EXTENSION OFFICER'
          )
        })
      )

      setEoVerification(
        leases?.data.filter((res) => {
          return res.status_desc === 'ACCEPTED BY LESSEE'
        })
      )
      setFarmerTerminated(
        leases?.data.filter((res) => {
          return res.status_desc === 'TERMINATION INITIATED BY LESSOR'
        })
      )
      setLeaseOffers(
        leases?.data.filter((res) => {
          return res.status_desc === 'INITIATED BY LESSOR'
        })
      )
      setRejected(
        leases?.data.filter((res) => {
          return res.status_desc === 'REJECTED BY LESSEE'
        })
      )
    }
  }, [leases])

  return {
    leases: leases?.data,
    offers: leaseOffers,
    verified,
    rejected,
    eoVerification,
    terminated: farmerTerminated,
    error,
    isLoading: isLoading || isInitialLoading
  }
}
