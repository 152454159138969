import { type ApiResponse, type Farmer } from '../../../../types'
import { axios } from '../../../../lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export const getFarmer = async (
  farmerId: string | number | null | undefined
): Promise<ApiResponse<Farmer>> => {
  return axios.get(`/farmer?FARMER_ID=${farmerId ?? ''}`)
}

export const useGetFarmer = (
  farmerId: string | number | null | undefined
): UseQueryResult<ApiResponse<Farmer>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['getFarmer', farmerId],
    queryFn: async () => getFarmer(farmerId),
    enabled: farmerId != null
  })
}
