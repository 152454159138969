import { Text } from 'components/ui'
import type React from 'react'
import { type CroppingPlan, type CroppingPlanDetails } from 'types'
import { useTranslation } from 'react-i18next'
import { useStaticDataStore } from '../../../../stores/useStaticDataStore'

interface CroppingPlanInfoProps {
  header: CroppingPlan
  croppingPlan: CroppingPlanDetails
}

export const CroppingPlanInfo: React.FC<CroppingPlanInfoProps> = ({ croppingPlan, header }) => {
  const { herbicides, adjuvants, pesticides, fungicides, seeds } = useStaticDataStore()
  const { t } = useTranslation('extensionOfficer')

  const selectedSeed = seeds?.find((seed) => seed?.seed_detail_id === croppingPlan?.seed_detail_id)

  return (
    <div className={'w-full flex flex-col gap-4'}>
      <div className={'w-full flex items-center border-b border-border pb-4'}>
        <div className={'w-1/2 flex flex-col'}>
          <Text size={'small'}>{t('cp.crop')}</Text>
          <Text variant={'primary'} size={'medium'}>
            {header?.crop_name}
          </Text>
        </div>

        <div className={'w-1/2 flex flex-col mr-8'}>
          <Text size={'small'}>{t('cp.ha')}</Text>
          <Text size={'medium'} variant={'primary'}>
            {croppingPlan?.hectarage}
          </Text>
        </div>
      </div>
      {/* seeding */}
      <div className='flex flex-col gap-4 border-b border-border pb-4'>
        <Text size={'medium'} variant={'primary'}>
          {t('cp.seeding')}
        </Text>
        <div className={'w-full flex items-center'}>
          {/* Seeding */}
          <div className={'w-1/2 flex flex-col'}>
            <Text size={'small'} variant={'secondary'}>
              {t('cp.seedV')}
            </Text>
            <Text size={'medium'}>{selectedSeed?.variety ?? ''}</Text>
          </div>
          {croppingPlan?.anticipated_returns === 0 ||
          croppingPlan?.anticipated_returns == null ? null : (
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.ar')}
              </Text>
              <Text size={'medium'}>BWP {croppingPlan?.anticipated_returns}</Text>
            </div>
          )}
        </div>
        <div className={'w-1/2 flex flex-col'}>
          <Text size={'small'} variant={'secondary'}>
            {t('cp.tcSeed')}
          </Text>
          <Text size={'medium'} variant={'success'}>
            BWP {croppingPlan?.seed_cost}{' '}
          </Text>
        </div>
      </div>

      {/* Weeding */}
      {croppingPlan?.packaging_method === null ? null : (
        <div className='flex flex-col gap-4 border-b border-border pb-4'>
          <Text size={'medium'} variant={'primary'}>
            {t('cp.weed')}
          </Text>
          <div className={'w-full flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.weedM')}
              </Text>
              <Text size={'medium'}>{croppingPlan?.weed_control_type}</Text>
            </div>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.weedAs')}
              </Text>
              <Text className={'font-semibold'}>{croppingPlan?.weed_control_application}</Text>
            </div>
          </div>

          <div className='w-full flex items-center'>
            <div className='w-1/2 flex flex-col'>
              <Text size='small' variant='secondary'>
                Total Application Cost
              </Text>
              <Text variant={'success'} className={'font-semibold'}>
                BWP {croppingPlan?.weed_control_application_cost}
              </Text>
            </div>
            {croppingPlan?.weed_control_type === 'Chemical' ? (
              <div className='w-1/2 flex flex-col'>
                <Text size='small' variant='secondary'>
                  Total Chemical Cost
                </Text>
                <Text variant={'success'} className={'font-semibold'}>
                  BWP {croppingPlan?.weed_control_chemical_cost}
                </Text>
              </div>
            ) : null}
          </div>

          {croppingPlan?.weed_control_type === 'Chemical' ? (
            <>
              <Text variant='primary'>Selected Chemicals</Text>
              <div className='flex flex-col gap-4'>
                {croppingPlan?.weed_control?.map((item) => {
                  return (
                    <div key={item.chemical_price_detail_id} className={'flex gap-4'}>
                      <p>*</p>
                      <Text className={'font-semibold'}>
                        {item.chemical_schedule} |{' '}
                        {herbicides?.find(
                          (herbicide) =>
                            herbicide?.herbicide_price_detail_id ===
                              item.chemical_price_detail_id && herbicide?.weed_id === item.weed_id
                        )?.herbicide_name ?? null}{' '}
                        |{' '}
                        {herbicides?.find(
                          (herbicide) =>
                            herbicide?.herbicide_price_detail_id ===
                              item.chemical_price_detail_id && herbicide?.weed_id === item.weed_id
                        )?.active_ingredient ?? null}
                      </Text>
                    </div>
                  )
                })}

                {croppingPlan?.weed_control?.map((item) => {
                  if (item.adjuvant_reg_no == null) {
                    return null
                  }
                  return (
                    <div key={item.adjuvant_price_detail_id} className={'flex gap-4'}>
                      <p>*</p>
                      <Text className={'font-semibold'}>
                        {adjuvants?.find(
                          (adjuvant) =>
                            adjuvant?.adjuvant_price_detail_id === item?.adjuvant_price_detail_id
                        )?.adjuvant_name ?? null}{' '}
                        | (Adjuvant)
                      </Text>
                    </div>
                  )
                })}
              </div>
            </>
          ) : null}
        </div>
      )}

      {/* Pest and disease control */}
      {croppingPlan?.pest_control_type === null ? null : (
        <div className='flex flex-col gap-4 border-b border-border pb-4'>
          <Text size={'medium'} variant={'primary'}>
            {t('cp.pest')}
          </Text>

          <div className={'w-full flex items-center'}>
            <div className='w-1/2 flex flex-col'>
              <Text size='small' variant='secondary'>
                {t('cp.pestM')}
              </Text>
              <Text className={'font-semibold'}>{croppingPlan?.pest_control_type}</Text>
            </div>
            <div className='w-1/2 flex flex-col'>
              <Text size='small' variant='secondary'>
                {t('cp.pestAm')}
              </Text>
              <Text className={'font-semibold'}>{croppingPlan?.pest_control_application}</Text>
            </div>
          </div>

          <div className={'w-full flex items-center'}>
            <div className='w-1/2 flex flex-col'>
              <Text size='small' variant='secondary'>
                Total Application Cost
              </Text>
              <Text variant={'success'} className={'font-semibold'}>
                BWP {croppingPlan?.pest_disease_application_cost}
              </Text>
            </div>
            <div className='w-1/2 flex flex-col'>
              <Text size='small' variant='secondary'>
                Total Chemical Cost
              </Text>
              <Text variant={'success'} className={'font-semibold'}>
                BWP {croppingPlan?.pest_disease_control_chemical_cost}
              </Text>
            </div>
          </div>

          <Text variant='primary'>Selected Chemicals</Text>
          <div className='flex flex-col gap-4'>
            {croppingPlan?.pest_disease_control?.map((item) => {
              return (
                <div key={item.chemical_price_detail_id} className={'flex gap-4'}>
                  <p>*</p>
                  <Text className={'font-semibold'}>
                    {item.chemical_type === 'pesticide'
                      ? pesticides?.find(
                          (pesticide) =>
                            pesticide?.pesticides_price_detail_id ===
                              item.chemical_price_detail_id && pesticide?.pest_id === item.pest_id
                        )?.pesticide_name ?? null
                      : fungicides?.find(
                          (fungicide) =>
                            fungicide?.fungicide_price_detail_id ===
                              item.chemical_price_detail_id &&
                            fungicide?.disease_id === item.pest_id
                        )?.fungicide_name ?? null}{' '}
                    |{' '}
                    {item.chemical_type === 'pesticide'
                      ? pesticides?.find(
                          (pesticide) =>
                            pesticide?.pesticides_price_detail_id ===
                              item.chemical_price_detail_id && pesticide?.pest_id === item.pest_id
                        )?.active_ingredient ?? null
                      : fungicides?.find(
                          (fungicide) =>
                            fungicide?.fungicide_price_detail_id ===
                              item.chemical_price_detail_id &&
                            fungicide?.disease_id === item.pest_id
                        )?.active_ingredient ?? null}
                  </Text>
                </div>
              )
            })}

            {croppingPlan?.pest_disease_control?.map((item) => {
              if (item.adjuvant_reg_no == null) {
                return null
              }
              return (
                <div key={item.adjuvant_price_detail_id} className={'flex gap-4'}>
                  <p>*</p>
                  <Text className={'font-semibold'}>
                    {adjuvants?.find(
                      (adjuvant) =>
                        adjuvant?.adjuvant_price_detail_id === item?.adjuvant_price_detail_id
                    )?.adjuvant_name ?? null}{' '}
                    | (Adjuvant)
                  </Text>
                </div>
              )
            })}
          </div>
        </div>
      )}

      <div className='flex flex-col gap-4 border-b border-border pb-4'>
        <Text size={'medium'} variant={'primary'}>
          {t('cp.land')}
        </Text>

        <div className={'w-full flex flex-wrap items-center'}>
          <div className={'w-1/2 flex flex-col'}>
            <Text size={'small'} variant={'secondary'}>
              {t('cp.landM')}
            </Text>
            <Text size={'medium'}>{croppingPlan?.tillage_method}</Text>
          </div>
          <div className={'w-1/2 flex flex-col'}>
            <Text size={'small'} variant={'secondary'}>
              {t('cp.harr')}
            </Text>
            <Text size={'medium'}>
              {croppingPlan?.isHarrowing === true ? t('cp.yes') : t('cp.no')}
            </Text>
          </div>
        </div>
        <div className={'w-1/2 flex flex-col'}>
          <Text size={'small'} variant={'secondary'}>
            {t('cp.tcLand')}
          </Text>
          <Text variant={'success'} size={'medium'}>
            BWP {croppingPlan?.land_preparation_cost}
          </Text>
        </div>
      </div>

      {croppingPlan?.harvesting_method === null ? null : (
        <div className='flex flex-col gap-4 border-b border-border pb-4'>
          <Text size={'medium'} variant={'primary'}>
            {t('cp.harv')}
          </Text>
          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.harvM')}
              </Text>
              <Text size={'medium'}>{croppingPlan?.harvesting_method}</Text>
            </div>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.tcHarv')}
              </Text>
              <Text variant={'success'} size={'medium'}>
                BWP {croppingPlan?.harvesting_cost}
              </Text>
            </div>
          </div>
        </div>
      )}

      {croppingPlan?.shelling_threshing_method === null ? null : (
        <div className='flex flex-col gap-4 border-b border-border pb-4'>
          <Text size={'medium'} variant={'primary'}>
            {t('cp.st')}
          </Text>

          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.stm')}
              </Text>
              <Text size={'medium'}>{croppingPlan?.shelling_threshing_method}</Text>
            </div>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.tcst')}
              </Text>
              <Text variant={'success'} size={'medium'}>
                {' '}
                BWP {croppingPlan?.shelling_threshing_cost}
              </Text>
            </div>
          </div>
        </div>
      )}
      {croppingPlan?.packaging_method === null ? null : (
        <div className='flex flex-col gap-4 border-b border-border pb-4'>
          <Text size={'medium'} variant={'primary'}>
            {t('cp.pack')}
          </Text>

          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.packM')}
              </Text>
              <Text size={'medium'}>{croppingPlan?.packaging_method}</Text>
            </div>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.tcPack')}
              </Text>
              <Text variant={'success'} size={'medium'}>
                BWP {croppingPlan?.packaging_cost}
              </Text>
            </div>
          </div>
        </div>
      )}
      {croppingPlan?.transportation_cost === null ? null : (
        <div className='flex flex-col gap-4 border-b border-border pb-4'>
          <Text size={'medium'} variant={'primary'}>
            {t('cp.tran')}
          </Text>
          <div className={'flex items-center'}>
            <div className={'w-1/2 flex flex-col'}>
              <Text size={'small'} variant={'secondary'}>
                {t('cp.tcTran')}
              </Text>
              <Text variant={'success'} size={'medium'}>
                BWP {croppingPlan?.transportation_cost}
              </Text>
            </div>
          </div>
        </div>
      )}

      <div className='flex flex-col gap-4 border-b border-border pb-4'>
        <Text size={'medium'} variant={'primary'}>
          Total Cost
        </Text>
        <div className={'w-1/2 flex flex-col'}>
          <Text variant={'success'} size={'medium'}>
            BWP {header.total_cost}
          </Text>
        </div>
      </div>
    </div>
  )
}
