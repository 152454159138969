import { ChangePasswordForm } from 'components/Change-Password/ChangePasswordForm'
import { Text } from 'components/ui'
import type React from 'react'
import { Box } from '../App/Layout'
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles'
import { SessionAuth } from 'supertokens-auth-react/recipe/session'
import { useNavigate } from 'react-router-dom'
import { ChangePasswordNotFound } from './ChangePasswordNotFound'
import { useMerchantStore } from '../../stores/useMerchantStore'
import { Kyc } from '../../features/merchant/kyc/routes/Kyc'

export const ChangePassword: React.FC = () => {
  const navigate = useNavigate()
  const { merchant } = useMerchantStore()

  if (merchant != null) {
    return (
      <SessionAuth
        accessDeniedScreen={ChangePasswordNotFound}
        onSessionExpired={() => {
          navigate('/session')
        }}
        overrideGlobalClaimValidators={(globalValidators) => [
          ...globalValidators,
          UserRoleClaim.validators.includes('FIRST_LOGIN')
        ]}
      >
        {merchant?.active === 'Inactive' && merchant?.user_role === 2001 ? (
          <Box className={'h-full w-full max-w-[700px] grid gap-8 flex-col place-items-center'}>
            <Kyc />
          </Box>
        ) : (
          <Box>
            <Text size={'large'}>Change Password</Text>
            <ChangePasswordForm />
          </Box>
        )}
      </SessionAuth>
    )
  }

  return (
    <SessionAuth
      accessDeniedScreen={ChangePasswordNotFound}
      onSessionExpired={() => {
        navigate('/session')
      }}
      overrideGlobalClaimValidators={(globalValidators) => [
        ...globalValidators,
        UserRoleClaim.validators.includes('FIRST_LOGIN')
      ]}
    >
      <Box>
        <Text size={'large'}>Change Password</Text>
        <ChangePasswordForm />
      </Box>
    </SessionAuth>
  )
}
