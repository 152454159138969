import { Text } from '../../../../components/ui'
import { type FC } from 'react'
import { type FertilizerRecommendation } from '../types'
import { useTranslation } from 'react-i18next'

interface FertilizerDetailsProps {
  fertilizer: FertilizerRecommendation | null
}
export const FertilizerDetails: FC<FertilizerDetailsProps> = ({ fertilizer }) => {
  const { t } = useTranslation('landManagement')

  return (
    <div className={'flex flex-wrap gap-8'}>
      <div className={'flex flex-[1_0_130px] flex-col'}>
        <Text size={'small'}>{t('soilCorr.fName')}</Text>
        <Text className={'font-semibold'}>{fertilizer?.fertilizer_name ?? 'N/A'}</Text>
      </div>

      <div className={'flex flex-[1_0_130px] flex-col'}>
        <Text size={'small'}>Magnesium, Mg (mg/kg)</Text>
        <Text className={'font-semibold'}>{fertilizer?.mg_composition ?? 'N/A'}</Text>
      </div>
      <div className={'flex flex-[1_0_130px] flex-col'}>
        <Text size={'small'}>Manganese,Mn (mg/kg)</Text>
        <Text className={'font-semibold'}>{fertilizer?.mn_composition ?? 'N/A'}</Text>
      </div>

      <div className={'flex flex-[1_0_130px] flex-col'}>
        <Text size={'small'}>Sodium, Na (mg/kg)</Text>
        <Text className={'font-semibold'}>{fertilizer?.na_composition ?? 'N/A'}</Text>
      </div>
      <div className={'flex flex-[1_0_130px] flex-col'}>
        <Text size={'small'}>Copper, Cu (mg/kg)</Text>
        <Text className={'font-semibold'}>{fertilizer?.cu_composition ?? 'N/A'}</Text>
      </div>

      <div className={'flex flex-[1_0_130px] flex-col'}>
        <Text size={'small'}>Potassium, K (mg/kg)</Text>
        <Text className={'font-semibold'}>{fertilizer?.k_composition ?? 'N/A'}</Text>
      </div>

      <div className={'flex flex-[1_0_130px] flex-col'}>
        <Text size={'small'}>Calcium, Ca (mg/kg)</Text>
        <Text className={'font-semibold'}>{fertilizer?.ca_composition ?? 'N/A'}</Text>
      </div>
      <div className={'flex flex-[1_0_130px] flex-col'}>
        <Text size={'small'}>Iron, Fe (mg/kg)</Text>
        <Text className={'font-semibold'}>{fertilizer?.fe_composition ?? 'N/A'}</Text>
      </div>
      <div className={'flex flex-[1_0_130px] flex-col'}>
        <Text size={'small'}>pH</Text>
        <Text className={'font-semibold'}>{fertilizer?.ph_composition ?? 'N/A'}</Text>
      </div>
      <div className={'flex flex-[1_0_130px] flex-col'}>
        <Text size={'small'}>Zinc, Zn (mg/kg)</Text>
        <Text className={'font-semibold'}>{fertilizer?.zn_composition ?? 'N/A'}</Text>
      </div>
      <div className={'flex flex-[1_0_130px] flex-col'}>
        <Text size={'small'}>Phosphorus, P (mg/kg)</Text>
        <Text className={'font-semibold'}>{fertilizer?.p_composition ?? 'N/A'}</Text>
      </div>
      <div className={'flex flex-[1_0_130px] flex-col'}>
        <Text size={'small'}>Phosphorus, P (mg/kg)</Text>
        <Text className={'font-semibold'}>{fertilizer?.p_composition ?? 'N/A'}</Text>
      </div>
      <div className={'flex flex-[1_0_130px] flex-col'}>
        <Text size={'small'}>Phosphorus, P (mg/kg)</Text>
        <Text className={'font-semibold'}>{fertilizer?.p_composition ?? 'N/A'}</Text>
      </div>
      <div className={'flex flex-[1_0_130px] flex-col'}>
        <Text size={'small'}>Sulphur, S (mg/kg)</Text>
        <Text className={'font-semibold'}>{fertilizer?.s_composition ?? 'N/A'}</Text>
      </div>
      <div className={'flex flex-[1_0_130px] flex-col'}>
        <Text size={'small'}>{t('soilCorr.dos')}</Text>
        <Text className={'font-semibold'}>{fertilizer?.dosage ?? 'N/A'}</Text>
      </div>
      <div className={'flex flex-[1_0_130px] flex-col'}>
        <Text size={'small'}>Sulphur, S (mg/kg)</Text>
        <Text className={'font-semibold'}>{fertilizer?.s_composition ?? 'N/A'}</Text>
      </div>
      <div className={'flex flex-[1_0_130px] flex-col'}>
        <Text size={'small'}>{t('soilCorr.noBag')}</Text>
        <Text className={'font-semibold'}>{fertilizer?.no_bags}</Text>
      </div>
      <div className={'flex flex-[1_0_130px] flex-col'}>
        <Text size={'small'}>{t('soilCorr.app')}/ha</Text>
        <Text className={'font-semibold'}>{fertilizer?.application_rate_per_ha}</Text>
      </div>
      <div className={'flex flex-[1_0_130px] flex-col'}>
        <Text size={'small'}>{t('soilCorr.costs')}</Text>
        <Text className={'font-semibold'}>BWP {fertilizer?.cost}</Text>
      </div>
    </div>
  )
}