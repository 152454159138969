import { Seeds } from 'components/Auth/seedDealer'
import { Navigate } from 'react-router-dom'
import { ServiceProviderProfile } from '../features/profile/routes/ServiceProvider'
import { SeedsVoucherRedemption } from '../features/merchant/seed-dealer/routes/Redemption'
import { SearchEligibility } from '../features/merchant/components/SearchEligibility'
import { SeedsReports } from '../features/merchant/seed-dealer/routes/Reports'
import { ChangePassword } from '../components/Change-Password/ChangePassword'
import { PasswordProtectedRoute } from 'components/Change-Password/PasswordProtectedRoute'

export const seeddealerRoutes = {
  path: '/seed-dealer',
  element: <Seeds />,
  children: [
    {
      index: true,
      element: (
        <PasswordProtectedRoute children={<SearchEligibility route={'/seed-dealer/redeem'} />} />
      )
    },
    {
      path: '/seed-dealer/profile',
      element: <PasswordProtectedRoute children={<ServiceProviderProfile />} />
    },
    { path: '/seed-dealer/change-password', element: <ChangePassword /> },
    {
      path: '/seed-dealer/redeem',
      element: <PasswordProtectedRoute children={<SeedsVoucherRedemption />} />
    },
    {
      path: '/seed-dealer/reports',
      element: <PasswordProtectedRoute children={<SeedsReports />} />
    },
    {
      path: '/seed-dealer/*',
      element: <PasswordProtectedRoute children={<Navigate to={'/seed-dealer'} />} />
    },
    { path: '*', element: <PasswordProtectedRoute children={<Navigate to={'.'} />} /> }
  ]
}
