import type React from 'react'
import { motion } from 'framer-motion'
import data from './Data/GroupsandClustersData'
import TreeIcon from '../../../../../assets/landing/icons/tree.svg'
import { useTranslation } from 'react-i18next' // Import the data

export const GroupsAndClusters: React.FC = () => {
  const { t } = useTranslation('landing')

  return (
    <div className='bg-blue-50 w-full '>
      <div className='container mx-auto flex flex-col  md:flex-row py-20 px-10  space-y-8  justify-center w-full '>
        <div className='flex flex-col md:flex-row justify-center items-center'>
          {Object.values(data(t)).map((section, index) => (
            <motion.div
              key={index}
              className='flex flex-col md:justify-center md:items-center md:w-1/2 mb-auto'
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className='text-4xl md:text-5xl lg:text-6xl font-bold text-gray-700 landing-page'>
                {section.title}
              </h2>
              <div>
                <ul className='mt-4'>
                  {section.details.map((detail, detailIndex) => (
                    <li
                      key={detailIndex}
                      className='flex text-base md:text-xl lg:text-2xl text-gray-600 my-8 landing-page'
                    >
                      <img src={TreeIcon} alt='Tree Icon' className='w-6 h-6 mr-5' />
                      {detail}
                    </li>
                  ))}
                </ul>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  )
}