import {
  type ColumnDef,
  type ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type RowSelectionState,
  type SortingState,
  useReactTable
} from '@tanstack/react-table'

import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'components/ui/table'
import { DataTablePagination } from 'components/Data-Table/datatable-pagination'
import type React from 'react'
import { useState } from 'react'
import { Button, Dialog, DialogContent, useStepper } from '../../../../../../../components/ui'
import { type Pesticides } from '../../../../../../../types/static'
import { useTranslation } from 'react-i18next'
import { AdjuvantsConfirmation } from '../../weeding/chemical/AdjuvantsConfirmation'
import {
  type SelectedPesticides,
  usePestAndDiseaseStore
} from '../../../../stores/usePestAndDiseaseStore'
import { PestAdjuvantSelection } from '../chemical/PestAdjuvantSelection'
import type { PestChemicals, PestControl } from '../../../../../../../types'
import collect from 'collect.js'
import { useMedia } from 'react-use'

interface DataTableProps<TData, TValue> {
  columns: Array<ColumnDef<TData, TValue>>
  data: TData[]
  onNext: () => void
  onBack: () => void
  pest: PestControl | undefined
}

export function PesticidesTable<TData, TValue>({
  columns,
  data,
  onNext,
  onBack,
  pest
}: DataTableProps<TData, TValue>): React.ReactNode {
  const collection = collect(
    pest?.chemicals?.filter((chemical) => chemical.chemical_type === 'pesticide') ?? []
  )
  const selectedPesticideApi = collection
    .mapWithKeys((item: PestChemicals) => [
      [`${item.chemical_price_detail_id}-${item?.pest_id ?? ''}`],
      true
    ])
    .all() as unknown as Record<string, boolean>
  const isDesktop = useMedia('(min-width: 900px)')
  const { nextStep } = useStepper()
  const [open, setOpen] = useState(false)
  const [showAdjuvants, setShowAdjuvants] = useState<boolean | null>(null)
  const { setSelectedPesticides, selectedFungicides } = usePestAndDiseaseStore()
  const [sorting, setSorting] = useState<SortingState>([])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    pest != null ? { ...selectedPesticideApi } : {}
  )
  const table = useReactTable({
    data,
    columns,
    enableRowSelection: (row) => {
      const selectedKeys = Object.keys(rowSelection)
      const selectedPesticideIds = selectedKeys.map((item) => item.split('-')[0])

      if (selectedKeys.find((item) => item === row.id) != null) {
        return true
      } else {
        return (
          selectedPesticideIds.find(
            (item) => item === (row.original as Pesticides).pesticides_price_detail_id.toString()
          ) == null
        )
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) =>
      `${(row as Pesticides).pesticides_price_detail_id}-${(row as Pesticides).pest_id}`,
    state: {
      sorting,
      columnFilters,
      rowSelection
    }
  })

  const handleOpenDialog = (): void => {
    if (showAdjuvants != null) {
      setShowAdjuvants(null)
    }
    setOpen(true)
  }

  const handleCloseDialog = (): void => {
    setOpen(false)
  }

  const { t } = useTranslation('croppingPlan')

  const onSubmit = (): void => {
    if (
      table.getRowCount() === 1
        ? table.getIsAllRowsSelected()
        : table.getIsSomeRowsSelected() || table.getIsSomePageRowsSelected()
    ) {
      const selectedPesticidesFromTable = table
        .getSelectedRowModel()
        .rows.map((row) => row.original as Pesticides)

      const selectedPesticidesFromTableList: SelectedPesticides[] = selectedPesticidesFromTable.map(
        (item) => {
          return {
            chemical_type: 'insecticide',
            adjuvant: null,
            chemical: item
          }
        }
      )

      setSelectedPesticides(selectedPesticidesFromTableList)
      handleOpenDialog()
    } else {
      if (pest == null) {
        if (selectedFungicides == null) {
          nextStep()
        } else {
          onNext()
        }
      } else {
        if (selectedFungicides == null) {
          nextStep()
        } else {
          onNext()
        }
      }
    }
  }

  return (
    <div className={'flex flex-col gap-4'}>
      <div className='rounded-md border'>
        <Table className={'relative'}>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length !== 0 ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  className={'hover:cursor-pointer hover:bg-muted-hover'}
                  onClick={() => {
                    row.toggleSelected()
                  }}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className='h-24 text-center'>
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} selectable />
      <div className='w-full flex justify-end gap-2'>
        <Button
          onPointerEnter={isDesktop ? undefined : nextStep}
          size={'sm'}
          variant={'outlined'}
          onClick={nextStep}
        >
          {t('footer.skip')}
        </Button>
        <Button onClick={onBack} size={'sm'} variant={'secondary'}>
          {t('footer.prev')}
        </Button>
        <Button onClick={onSubmit} size={'sm'} variant={'primary'}>
          {t('footer.next')}
        </Button>
      </div>

      <Dialog
        key={'add-pesticides-adjuvants'}
        open={open}
        defaultOpen={false}
        modal
        onOpenChange={handleCloseDialog}
      >
        {showAdjuvants == null ? (
          <DialogContent
            key={'pesticide-adjuvant-confirmation'}
            className={'w-[90vw] md:w-[50%] lg:w-[35%] max-w-[430px] max-h-[80vh] overflow-y-auto'}
          >
            <AdjuvantsConfirmation
              onClickNo={() => {
                if (pest == null) {
                  onNext()
                  setShowAdjuvants(false)
                  handleCloseDialog()
                } else {
                  onNext()
                  setShowAdjuvants(false)
                  handleCloseDialog()
                }
              }}
              onClickYes={() => {
                setShowAdjuvants(true)
              }}
            />
          </DialogContent>
        ) : showAdjuvants ? (
          <DialogContent
            key={'pesticide-adjuvant-table'}
            className={
              'flex flex-col w-[90vw] md:w-[80%] lg:w-[65%] max-w-[430px] md:max-w-[90%] lg:max-w-[80%] max-h-[80vh] overflow-y-auto'
            }
          >
            <PestAdjuvantSelection
              pesticides={table.getSelectedRowModel().rows.map((row) => row.original as Pesticides)}
              onNext={onNext}
              onCancel={onBack}
            />
          </DialogContent>
        ) : null}
      </Dialog>
    </div>
  )
}
