import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { axios } from 'lib/axios'
import { type ApiResponse, type SoilSampleRequest } from 'types'

export const getSSR = async (
  farmId: string | number | null | undefined,
  farmerId: number | null | undefined,
  leaseId?: string | number | null | undefined
): Promise<ApiResponse<SoilSampleRequest>> => {
  if (leaseId !== null && leaseId !== undefined) {
    return axios.get(`ssr?farmer_id=${farmerId ?? ''}&lease_id=${leaseId}&farm_id=${farmId ?? ''}`)
  }
  return axios.get(`ssr?farmer_id=${farmerId ?? ''}&farm_id=${farmId ?? ''}`)
}

export const useGetSSR = (
  farmId: string | number | null | undefined,
  farmerId: number | null | undefined,
  leaseId?: string | number | null | undefined
): UseQueryResult<ApiResponse<SoilSampleRequest>, AxiosError> => {
  return useQuery({
    queryKey: ['ssr'],
    queryFn: async () => getSSR(farmId, farmerId, leaseId),
    enabled:
      (farmerId != null && farmId != null) ||
      (farmerId != null && farmId != null && leaseId != null)
  })
}
