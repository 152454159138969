import type React from 'react'
import { useTranslation } from 'react-i18next'

export const NoTransactions: React.FC = () => {
  const { t } = useTranslation('merchant')
  return (
    <main className={'grid place-items-center min-h-[30%] h-full flex-1'}>
      <div className={'flex flex-col gap-4 justify-center items-center'}>
        <h1 className='text-destructive text-2xl font-semibold'>{t('merchant.tnf')}</h1>
        <p className={'text-md md:text-lg text-center'}>{t('merchant.noTr')}</p>
      </div>
    </main>
  )
}