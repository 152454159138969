import {
  CompanyAttachments,
  CompanyBalanceSheet,
  CompanyCommercialBank,
  CompanyOtherLoans,
  CompanySecurity,
  FinancialParticularsCompany,
  ProductionScheduleCompany,
  SummarizedBusinessInformationCompany
} from 'features/loan-management/components/stepper/company'
import type React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CompanyInformation } from './CompanyInformation'
import { CompanyApplicantEmploymentInfo } from './CompanyApplicantEmployment'
import { Step, Stepper, toast } from '../../../../../components/ui'
import { useMedia } from 'react-use'
import { SelectCompanyPlans } from './SelectCompanyPlans'
import { AttachmentRepo } from '../individual'
import { CompanyAccountants } from './CompanyAccountants'
import { CompanyAuditors } from './CompanyAuditors'

export const LoanApplicationFormCompany: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = (): void => {
    setActiveStep((prevState: number) => prevState + 1)
  }

  const navigate = useNavigate()

  const handleBack = (): void => {
    setActiveStep((prevState: number) => prevState - 1)
  }
  const handleCancel = (): void => {
    navigate('/farmer/loans')
  }

  const isDesktop = useMedia('(min-width: 900px)')

  const [completedSteps, setCompletedSteps] = useState<Record<number, boolean>>({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
    7: false,
    8: false,
    9: false,
    10: false,
    11: false,
    12: false,
    13: false,
    14: false
  })
  const handleStepCompletion = (stepIndex: number, status: boolean): void => {
    setCompletedSteps((prev) => ({ ...prev, [stepIndex]: status }))
  }
  const steps = [
    { label: 'Cropping Plan' },
    { label: 'Attachments' },
    { label: 'Company Information' },
    { label: 'Employee Information' },
    { label: 'Accountants' },
    { label: 'Auditors' },
    { label: 'Banks' },
    { label: 'Loans' },
    { label: 'Financial Particulars' },
    { label: 'Production Schedule' },
    { label: 'Security' },
    { label: 'Business Information' },
    { label: 'Balance Sheet' },
    { label: 'Confirm Details' },
    { label: 'Attachment Repo' }
  ]

  const handleStepClick = (index: number, setStep: (step: number) => void): void => {
    if (index > 0 && !completedSteps[0]) {
      toast({
        variant: 'destructive',
        title: 'Please complete the first step',
        description: 'You need to complete the first step before proceeding to the next step'
      })
      return
    }

    if (
      (index === 13 || index === 14) &&
      !(Object.values(completedSteps).slice(0, 12).every(Boolean) && completedSteps[12])
    ) {
      const firstIncompleteStep = Object.keys(completedSteps)
        .map(Number)
        .slice(0, 13)
        .find((stepIndex) => !completedSteps[stepIndex])
      toast({
        variant: 'destructive',
        title: `Please complete step(s): ${
          firstIncompleteStep != null ? firstIncompleteStep + 1 : ''
        }`,
        description: 'You need to complete all previous steps before proceeding to the next step'
      })
      return
    }

    if (completedSteps[12] && index < 13) {
      toast({
        variant: 'destructive',
        title: 'Cannot go back to previous steps',
        description: 'You cannot access previous steps once you reach step 14.'
      })
      return
    }

    setStep(index)
  }

  return (
    <div className={'w-full flex flex-col gap-4'}>
      <Stepper
        variant={isDesktop ? 'circle-alt' : 'line'}
        size={'sm'}
        initialStep={0}
        mobileBreakpoint={'900px'}
        steps={steps}
        onClickStep={(step, setStep) => {
          handleStepClick(step, setStep)
        }}
        styles={{
          'step-label': 'text-xs'
        }}
        scrollTracking
      >
        {steps.map((stepProps, index) => {
          if (index === 0) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <SelectCompanyPlans
                  cancel={handleCancel}
                  onComplete={(status: boolean) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 1) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CompanyAttachments
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 2) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CompanyInformation
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 3) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CompanyApplicantEmploymentInfo
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 4) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CompanyAccountants
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 5) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CompanyAuditors
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 6) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CompanyCommercialBank
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 7) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CompanyOtherLoans
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 8) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <FinancialParticularsCompany
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 9) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <ProductionScheduleCompany
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 10) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CompanySecurity
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 11) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <SummarizedBusinessInformationCompany
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
          } else if (index === 12) {
            return (
              <Step key={stepProps.label} {...stepProps}>
                <CompanyBalanceSheet
                  onComplete={(status) => {
                    handleStepCompletion(index, status)
                  }}
                />
              </Step>
            )
            // } else if (index === 13) {
            //   return (
            //     //   TODO COMPLETE CONFIRM DETAILS
            //     <Step key={stepProps.label} {...stepProps}>
            //       <ConfirmCompanyDetails
            //         activeStep={activeStep}
            //         steps={steps}
            //         onBack={handleBack}
            //         onNext={handleNext}
            //       />
            //     </Step>
            //   )
          }
          return (
            //   TODO CHANGE ATTACHMENT REPO
            <Step key={stepProps.label} {...stepProps}>
              <AttachmentRepo />
            </Step>
          )
        })}
      </Stepper>
    </div>
  )
}
