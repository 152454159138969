import type React from 'react'
import { useNavigate } from 'react-router-dom'
import { type Farm } from 'types'
import { VerifiedStatus } from '../../../farmer/land-management/components/generic/VerifiedStatus'
import { Badge, Card, Text } from 'components/ui'
import { ArrowUpRightFromSquare } from 'lucide-react'
import { useTranslation } from 'react-i18next'

interface FarmCardResultsProps {
  farm: Farm
}

export const FarmCardResults: React.FC<FarmCardResultsProps> = ({ farm }) => {
  const { t } = useTranslation('extensionOfficer')
  const navigate = useNavigate()

  const handleClick = (): void => {
    navigate(`/officer/farmers/${farm.farmer_id}/${farm.farm_id}`)
  }

  const hoverStyles =
    farm.status === 'verified'
      ? 'transition-shadow duration-200 ease-in-out transform hover:shadow-lg hover:shadow-md hover:shadow-input hover:border-second dark:hover:border-second-foreground'
      : ''
  const cursorStyle =
    farm.status === 'verified' ? 'cursor-pointer' : 'shadow-none border-muted cursor-default'

  return (
    <Card
      className={`md:max-w-[500px] relative flex flex-col gap-2 sm:gap-4 md:px-6 lg:px-8 hover:${hoverStyles} ${cursorStyle}`}
      onClick={handleClick}
    >
      <div className={'flex items-center justify-between'}>
        <div className={'flex gap-4 items-center justify-between'}>
          <div className={'flex gap-1 items-baseline'}>
            <Text size={'large'}>{farm.size.toFixed(4)}</Text>
            <Text>ha</Text>
          </div>
        </div>
        <Badge className={farm.status === 'verified' ? 'pointer' : 'default'}>{`${
          farm.leased_size
        } Ha ${t('farm.leased')}`}</Badge>
        {farm.status === 'verified' && <ArrowUpRightFromSquare />}
      </div>

      <div className={'w-full flex flex-col'}>
        <Text size={'medium'} className={'capitalize'}>
          {farm.farm_name.toLowerCase()}
        </Text>
        <Text
          className={'capitalize'}
          variant={'bodyTextLight'}
        >{`${farm.village_name.toLowerCase()}, ${farm.extension_area_name.toLowerCase()}`}</Text>
      </div>

      <VerifiedStatus status={farm.status} />
    </Card>
  )
}
