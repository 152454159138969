import { object, z } from 'zod'
import type React from 'react'
import { useEffect } from 'react'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Loader,
  RadioGroup,
  RadioGroupItem,
  Text
} from 'components/ui'
import { Error } from 'components/Errors/Error'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'
import { useWeedingStore } from '../../../stores/useWeedingStore'
import { useCroppingPlanWeeding } from '../../../hooks/useCroppingPlanWeeding'
import collect from 'collect.js'
import { getWeedingSchedule } from '../../../utils/functions'
import { useFarmerStore } from '../../../../../../stores/useFarmerStore'
import { useGetLandPreparation } from '../../../api/land-preparation/getLandPreparation'
import { Info } from 'lucide-react'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import { useCroppingPlanStore } from '../../../stores/useCroppingPlanStore'

export const scheduleSchema = object({
  schedule: z.enum(
    [
      'Post Emergence',
      'Pre Planting',
      'Pre Emergence',
      'Pre Emergence & Post Emergence',
      'Pre Planting & Post Emergence',
      'Pre Planting & Pre Emergence'
    ],
    {
      required_error: 'You need to select a Weeding Schedule'
    }
  )
})

interface WeedingProps {
  onNextStep: () => void
  onBackStep: () => void
}

export const Weeding: React.FC<WeedingProps> = ({ onNextStep, onBackStep }) => {
  const { setSchedule } = useWeedingStore()
  const { weeding } = useCroppingPlanStore()
  const { farmer } = useFarmerStore()
  const flags = useFlags()
  const ldClient = useLDClient()
  const { t } = useTranslation('croppingPlan')
  const { header, isLoading } = useCroppingPlanWeeding(() => {})

  const {
    data: apiLandPreparation,
    isInitialLoading: apiLandIsLoading,
    error: landPreparationError
  } = useGetLandPreparation(header?.farmer_id, header?.cropping_plan_id)

  const weedingSchedules = collect(weeding?.chemicals != null ? weeding.chemicals : [])
    .unique('chemical_schedule')
    .all()

  const form = useForm<z.infer<typeof scheduleSchema>>({
    resolver: zodResolver(scheduleSchema),
    mode: 'all',
    defaultValues: {
      schedule:
        weeding != null && weeding.type === 'Chemical'
          ? getWeedingSchedule(weedingSchedules)
          : undefined
    }
  })

  const onSubmit = (data: z.infer<typeof scheduleSchema>): void => {
    setSchedule(data.schedule)
    onNextStep()
  }

  useEffect(() => {
    void ldClient?.identify({ key: 'moa-frontend' })
  }, [])

  if (header == null) {
    return (
      <div className={'grid place-items-center'}>
        <Text variant={'error'} size={'medium'}>
          Crop & Hectarage Are Required!
        </Text>
      </div>
    )
  }

  if (isLoading || apiLandIsLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (landPreparationError != null) {
    if (landPreparationError?.response?.status !== 404) {
      return <Error />
    }
  }

  const isMicro = farmer?.class_id != null && farmer.class_id !== 'TAXPAYER'

  const hasLandPreparationPlan =
    apiLandPreparation?.data != null
      ? apiLandPreparation.data.tillage_method === 'Conventional'
      : false

  return (
    <Form {...form}>
      <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
        <div
          className={
            'flex flex-col p-4 gap-4 w-full sm:max-w-[60%] lg:max-w-[60%]' + ' xl:max-w-[40%]'
          }
        >
          <div className='flex'>
            <Info size='18' color={'red'} className='mr-2.5' />
            <Text variant='error' size='small' className='font-bold'>
              N.B. Once a weeding technique has been selected and submitted it cannot be changed.
            </Text>
          </div>

          <FormField
            control={form.control}
            name='schedule'
            render={({ field }) => (
              <FormItem className='space-y-3'>
                <FormLabel>{t('weed.technique')}</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    disabled={weeding != null && weeding.type === 'Chemical'}
                    defaultValue={field.value}
                    className='flex flex-col space-y-1'
                  >
                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem value={'Post Emergence'} />
                      </FormControl>
                      <FormLabel className='font-normal'>Post Emergence</FormLabel>
                    </FormItem>

                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem disabled={hasLandPreparationPlan} value={'Pre Planting'} />
                      </FormControl>
                      <FormLabel className='font-normal'>Pre Planting</FormLabel>
                    </FormItem>

                    <FormItem className='flex items-center space-x-3 space-y-0'>
                      <FormControl>
                        <RadioGroupItem value={'Pre Emergence'} />
                      </FormControl>
                      <FormLabel className='font-normal'>Pre Emergence</FormLabel>
                    </FormItem>

                    {isMicro && flags.disableMicroscaleAccessToSubprocesses === false ? null : (
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem
                            disabled={
                              isMicro && flags.disableMicroscaleAccessToSubprocesses === false
                            }
                            value={'Pre Emergence & Post Emergence'}
                          />
                        </FormControl>
                        <FormLabel className='font-normal'>
                          Pre Emergence & Post Emergence
                        </FormLabel>
                      </FormItem>
                    )}

                    {isMicro && flags.disableMicroscaleAccessToSubprocesses === false ? null : (
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem
                            disabled={
                              isMicro && flags.disableMicroscaleAccessToSubprocesses === false
                            }
                            value={'Pre Planting & Post Emergence'}
                          />
                        </FormControl>
                        <FormLabel className='font-normal'>Pre Planting & Post Emergence</FormLabel>
                      </FormItem>
                    )}

                    {isMicro && flags.disableMicroscaleAccessToSubprocesses === false ? null : (
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem
                            disabled={
                              isMicro && flags.disableMicroscaleAccessToSubprocesses === false
                            }
                            value={'Pre Planting & Pre Emergence'}
                          />
                        </FormControl>
                        <FormLabel className='font-normal'>Pre Planting & Pre Emergence</FormLabel>
                      </FormItem>
                    )}
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className='w-full flex justify-end gap-2'>
          <Button onClick={onBackStep} size={'sm'} variant={'secondary'}>
            {t('footer.prev')}
          </Button>
          <Button onClick={form.handleSubmit(onSubmit)} size={'sm'} variant={'primary'}>
            {t('footer.next')}
          </Button>
        </div>
      </form>
    </Form>
  )
}
