import type z from 'zod'
import { coerce, object, type TypeOf } from 'zod'
import type { Farm, Lease } from 'types'
import type React from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCroppingPlanHeader } from '../../../hooks/useCroppingPlanHeader'
import { useGetIndividualHeader } from '../../../api/header/getHeader'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Loader,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text
} from 'components/ui'
import { Error } from 'components/Errors/Error'
import { StepperFooter } from '../Footer'
import { useStepper } from 'components/ui/stepper'
import { useTranslation } from 'react-i18next'
import { useStaticDataStore } from '../../../../../../stores/useStaticDataStore'
import { canFloor } from '../../../../../../utils/utilFunctions'
import { Info } from 'lucide-react'

interface Base {
  cancel: () => void
}

interface WithFarm extends Base {
  farm: Farm
  lease?: never
}

interface WithLease extends Base {
  farm?: never
  lease: Lease
}

type CroppingPlanHeaderProps = WithFarm | WithLease

const generalSchema = object({
  crop: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  }),
  hectarage: coerce.number({
    required_error: 'field is required',
    invalid_type_error: 'field is required'
  })
}).refine((dt) => canFloor(dt.hectarage), {
  message: `Hectarage should have a decimal place greater than or equal 0.5`,
  path: ['hectarage']
})

export type HeaderInput = TypeOf<typeof generalSchema>

export const CroppingPlanHeader: React.FC<CroppingPlanHeaderProps> = ({ cancel, farm, lease }) => {
  const { nextStep } = useStepper()
  const { crops } = useStaticDataStore()
  const { header, setHeader, isLoading, createCroppingPlanHeader, updateCroppingPlanHeader } =
    useCroppingPlanHeader(farm, nextStep, lease)
  const {
    data: apiHeader,
    isInitialLoading: apiIsLoading,
    error
  } = useGetIndividualHeader(header?.cropping_plan_id, header?.farmer_id)
  const { t } = useTranslation('croppingPlan')

  const schema = object({
    crop: coerce.number({
      required_error: 'field is required',
      invalid_type_error: 'field is required'
    }),
    hectarage: coerce
      .number({
        required_error: 'field is required',
        invalid_type_error: 'field is required'
      })
      .min(0.5, 'minimum size is 0.5Ha')
      .max(farm != null ? farm.size - farm.leased_size : lease?.leased_size)
  }).refine((dt) => canFloor(dt.hectarage), {
    message: `Hectarage should have a decimal place greater than or equal .5`,
    path: ['hectarage']
  })

  const cropsList = crops?.filter((item) => item.active === 1)

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      crop:
        header !== null
          ? cropsList?.find((crop) => crop.crop_id === header?.crop_id)?.crop_id
          : undefined,
      hectarage: header !== null ? header?.hectarage : apiHeader?.data[0].hectarage
    }
  })

  const onSubmit = (data: z.infer<typeof schema>): void => {
    if (apiHeader == null) {
      createCroppingPlanHeader(data)
    } else if (
      data.crop !== apiHeader.data[0].crop_id ||
      data.hectarage !== apiHeader.data[0].hectarage
    ) {
      updateCroppingPlanHeader(data)
    } else {
      nextStep()
    }
  }

  useEffect(() => {
    if (apiHeader?.data != null) {
      setHeader(apiHeader.data[0])
      form.reset({
        crop: cropsList?.find((crop) => crop.crop_id === apiHeader?.data[0]?.crop_id)?.crop_id,
        hectarage: apiHeader?.data[0].hectarage
      })
    }
  }, [apiHeader, form.reset])

  if (apiIsLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (error != null) {
    if (error?.response?.status !== 404) {
      return <Error />
    }
  }

  if (cropsList?.length === 0) {
    return <Error />
  }

  return (
    <Form {...form}>
      <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
        <div
          className={
            'flex flex-col p-4 gap-4 w-full sm:max-w-[60%] lg:max-w-[60%]' + ' xl:max-w-[40%]'
          }
        >
          <div className='flex'>
            <Info size='18' color='red' className='mr-2.5' />
            <Text variant='error' size='small' className=''>
              N.B. Once a crop has been selected and submitted, It cannot be changed.
            </Text>
          </div>
          <FormField
            control={form.control}
            name='crop'
            render={({ field }) => (
              <FormItem>
                <FormLabel className={'font-redHat'}>{t('crop.crop')}</FormLabel>
                <Select
                  onValueChange={(val) => {
                    field.onChange(val)
                  }}
                  defaultValue={
                    apiHeader?.data != null
                      ? cropsList
                          ?.find((crop) => crop.crop_id === apiHeader?.data[0]?.crop_id)
                          ?.crop_id?.toString()
                      : undefined
                  }
                  disabled={header != null || apiHeader?.data != null}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder={`${t('crop.crop_placeholder')}`} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {cropsList?.map((crop) => (
                      <SelectItem key={crop.crop_id} value={crop.crop_id.toString()}>
                        {crop.crop_name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name={'hectarage'}
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel className={'font-redHat'}>{t('crop.ha')}</FormLabel>
                <FormDescription className={'px-4'}>
                  {t('crop.max')}:{' '}
                  {farm !== undefined ? farm.size - farm.leased_size : lease?.leased_size} Ha
                </FormDescription>
                <FormControl>
                  <Input
                    classLabel={'bg-background-dark sm:bg-card'}
                    placeholder={`${t('crop.ha_placeholder')}`}
                    type={'text'}
                    inputMode={'numeric'}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <StepperFooter isFirstStep cancel={cancel} isLoading={isLoading} />
      </form>
    </Form>
  )
}
