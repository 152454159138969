import type React from 'react'
import { motion } from 'framer-motion'
import Session from 'supertokens-auth-react/recipe/session'
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles'
import { clearLoginAttemptInfo } from 'supertokens-web-js/lib/build/recipe/passwordless'
import Spinner from '../../../../../components/ui/spinner'
import CropCards from './SingleYieldCard'
import { useTranslation } from 'react-i18next' // Adjust path accordingly

export const TargetYields: React.FC = () => {
  const claimValue = Session.useClaimValue(UserRoleClaim)
  const { t } = useTranslation('landing')
  if (claimValue.loading) {
    return (
      <div className='flex items-center justify-center w-screen h-screen'>
        <Spinner />
      </div>
    )
  }

  const roles = claimValue.value

  const handleNavigateToDashboard = (): string => {
    if (Array.isArray(roles) && roles.includes('FARMER')) {
      return '/farmer'
    } else if (Array.isArray(roles) && roles.includes('EXTENSION_OFFICER')) {
      return '/officer'
    } else if (Array.isArray(roles) && roles.includes('SOIL_SAMPLE_LAB')) {
      return '/soil-lab'
    } else if (Array.isArray(roles) && roles.includes('MECHANISATION_OFFICER')) {
      return '/inventory'
    } else if (Array.isArray(roles) && roles.includes('SEED_DEALER')) {
      return '/seed-dealer'
    } else if (Array.isArray(roles) && roles.includes('SERVICE_PROVIDER')) {
      return '/service-provider'
    } else {
      void clearLoginAttemptInfo()
      return '/login/phone'
    }
  }

  return (
    <div className='flex flex-col py-20 px-10 bg-white space-y-8'>
      <motion.div
        className='flex flex-col md:justify-center md:items-center space-y-12'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <h2 className='text-4xl md:text-5xl lg:text-6xl font-bold text-gray-700 landing-page'>
          {t('about.yield')}
        </h2>
        <p className='text-base md:text-xl lg:text-2xl font-regular text-center text-gray-600 md:px-[10vw] lg:px-[20vw] landing-page'>
          {t('about.targets')}
        </p>
      </motion.div>
      <motion.div
        className='flex flex-col justify-center items-center'
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <CropCards />
      </motion.div>
    </div>
  )
}