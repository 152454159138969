import type React from 'react'
import { Button, Text } from 'components/ui'
import { useNavigate } from 'react-router-dom'
import { type SuccessfulVoucherRedemption } from '../types'
import { getReportsRoute } from 'utils/getMerchantRoutes'
import { useAuthenticationStore } from 'stores/useAuthenticationStore'
import { useTranslation } from 'react-i18next'

interface VoucherRedeemedProps {
  voucher: SuccessfulVoucherRedemption | null
}

export const VoucherRedeemed: React.FC<VoucherRedeemedProps> = ({ voucher }) => {
  const navigate = useNavigate()
  const { t } = useTranslation('merchant')
  const { role } = useAuthenticationStore()

  return (
    <div className={'flex flex-col justify-center items-center gap-4'}>
      <Text className={'text-center'} size={'large'} variant={'success'}>
        {t('merchant.vrs')}
      </Text>
      <Text className={'text-center'} size={'body'} variant={'bodyTextLight'}>
        {t('merchant.view')}
      </Text>
      <Text className={'text-center'} size={'medium'} variant={'bodyTextLight'}>
        {t('report.tid')}: {voucher?.transaction_id ?? ''}
      </Text>
      <Button
        variant={'success'}
        onClick={() => {
          navigate(getReportsRoute(role))
        }}
        size={'xs'}
        color={'success'}
      >
        {t('merchant.comp')}
      </Button>
    </div>
  )
}