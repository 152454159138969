import type React from 'react'
import { Button } from 'components/ui'
import { Languages } from 'lucide-react'

export const LanguageToggle: React.FC<{
  toggleLanguage: () => void
  language: 'en' | 'tswana'
}> = ({ toggleLanguage, language }) => {
  return (
    <Button variant={'ghost'} size={'xs'} onClick={toggleLanguage}>
      <Languages />
      {language === 'en' ? (
        <>
          <span className={'flex sm:hidden'}>TS</span>
          <span className={'sm:flex hidden'}>Setswana</span>
        </>
      ) : (
        <>
          <span className={'flex sm:hidden'}>EN</span>
          <span className={'sm:flex hidden'}>english</span>
        </>
      )}
    </Button>
  )
}
