import { AxiosError, type AxiosResponse } from 'axios'
import { getLabInventoryItem } from 'features/mechanisationOfficer/inventory/api/getInventoryItem'
import { useState } from 'react'
import { type ApiResponse } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { type GetInventoryRes } from '../../../farmer/inventory/types'
import { useOfficerStore } from '../../../../stores/useOfficerStore'

interface UseSearchInventory {
  isLoading: boolean
  error: string | null
  searchInventoryItem: (isType: string, idInput: string) => Promise<void>
  data: GetInventoryRes[] | null
}

export const useSearchInventory = (): UseSearchInventory => {
  const { officer } = useOfficerStore()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<GetInventoryRes[] | null>(null)

  const searchInventoryItem = async (idType: string, idInput: string): Promise<void> => {
    setError(null)
    setIsLoading(true)
    setData(null)

    try {
      const response = await getLabInventoryItem(idType, idInput, officer?.district_id)
      if (response.status === 200) {
        setData(response.data)
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const errorMsg = err?.response as AxiosResponse<ApiResponse<[]>>
        setError(
          mapCmsErrorToMessage(
            errorMsg.status.toString() ?? err.code ?? errorMsg.data.message ?? ''
          )
        )
      } else {
        const errorMsg = err as Error
        setError(mapCmsErrorToMessage(errorMsg.message))
      }
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading, data, searchInventoryItem, error }
}
