import type React from 'react'
import { motion } from 'framer-motion'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../../../components/ui'
import { GrantsPackage } from './packages_content/Grants'
import GrantTwo from '../../../../../assets/landing/icons/grant.svg'
import { SeasonalLoansPackage } from './packages_content/SeasonalLoans'
import { SupervisedCredit } from './packages_content/SupervisedCredit'
import { CropInsurance } from './packages_content/CropInsurance'
import { useTranslation } from 'react-i18next'

/**
 * React functional component to display the Program Packages section.
 *
 * @returns {JSX.Element} The JSX code to render the Program Packages section.
 */
export const ProgramPackages: React.FC = () => {
  const { t } = useTranslation('landing')
  return (
    <div className='bg-blue-50 w-full '>
      <div className='container mx-auto py-10 md:py-20 px-10 flex flex-col justify-center w-screen md:w-full bg-blue-50 space-y-8'>
        <motion.div
          className='flex flex-col items-center mb-12'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className='text-4xl md:text-5xl lg:text-6xl font-bold text-gray-700 landing-page'>
            {t('pp.pp')}
          </h2>
        </motion.div>
        {/* Tabs Showing the program packages */}
        <Tabs defaultValue='grants' className=''>
          <TabsList className='flex justify-center bg-blue-50 overflow-x-auto h-12'>
            <TabsTrigger
              value='grants'
              className='data-[state=active]:bg-blue-100 rounded-2xl min-w-[300px] md:w-1/2 lg:w-1/4'
            >
              <motion.div
                className='p-2 bg-blue-100 rounded-lg flex items-center mr-5'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <img src={GrantTwo} alt='Tree Icon' className='w-6 h-6' />
              </motion.div>
              <h3 className='sm:text-base md:text-lg lg:text-xl font-medium text-gray-600 landing-page'>
                {t('pp.grants')}
              </h3>
            </TabsTrigger>
            <TabsTrigger
              value='seasonal_loans'
              className='data-[state=active]:bg-blue-100 rounded-2xl min-w-[300px] md:w-1/2 lg:w-1/4'
            >
              <motion.div
                className='p-2 bg-blue-100 rounded-lg flex items-center mr-5'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <img src={GrantTwo} alt='Tree Icon' className='w-6 h-6' />
              </motion.div>
              <h3 className='sm:text-base md:text-lg lg:text-xl text-gray-600 font-semibold landing-page'>
                {t('pp.sl')}
              </h3>
            </TabsTrigger>
            <TabsTrigger
              value='supervised_credit'
              className='data-[state=active]:bg-blue-100 rounded-2xl min-w-[300px] md:w-1/2 lg:w-1/4'
            >
              <motion.div
                className='p-2 bg-blue-100 rounded-lg flex items-center mr-5'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <img src={GrantTwo} alt='Tree Icon' className='w-6 h-6' />
              </motion.div>
              <h3 className='sm:text-base md:text-lg lg:text-xl text-gray-600 font-semibold landing-page'>
                {t('pp.sc')}
              </h3>
            </TabsTrigger>
            <TabsTrigger
              value='crop_insurance'
              className='data-[state=active]:bg-blue-100 rounded-2xl min-w-[300px] md:w-1/2 lg:w-1/4'
            >
              <motion.div
                className='p-2 bg-blue-100 rounded-lg flex items-center mr-5'
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <img src={GrantTwo} alt='Tree Icon' className='w-6 h-6' />
              </motion.div>
              <h3 className='sm:text-base md:text-lg lg:text-xl text-gray-600 font-semibold landing-page'>
                {t('pp.ci')}
              </h3>
            </TabsTrigger>
          </TabsList>
          <hr className='w-full border-gray-300 mt-4' />
          <TabsContent value='grants'>
            <GrantsPackage />
          </TabsContent>
          <TabsContent value='seasonal_loans'>
            <SeasonalLoansPackage />
          </TabsContent>
          <TabsContent value='supervised_credit'>
            <SupervisedCredit />
          </TabsContent>
          <TabsContent value='crop_insurance'>
            <CropInsurance />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  )
}