import React from 'react'
// import { Text } from '../../../../components/Elements/Text'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Text
} from '../../../../components/ui'

interface AccordionSection {
  label: string
  children: React.ReactNode
  fields?: string[]
  fieldErrors?: Record<string, any>
}

interface CustomAccordionProps {
  sections: AccordionSection[]
}

export const CustomAccordion: React.FC<CustomAccordionProps> = ({ sections }) => {
  const [expanded, setExpanded] = React.useState<string | null>(null)

  const handleChange = (panel: string): void => {
    setExpanded((prev) => (prev === panel ? null : panel))
  }

  const hasErrorInSection = (
    sectionFields: string[] | undefined,
    errors: Record<string, any>
  ): boolean => {
    return sectionFields?.some((field) => Boolean(errors[field])) as boolean
  }

  return (
    <Accordion type='single' collapsible>
      {sections.map((section, index) => (
        <AccordionItem
          key={index}
          value={`panel${index}`}
          className='transition-transform transform hover:scale-[1.01]'
        >
          <AccordionTrigger
            onClick={() => {
              handleChange(`panel${index}`)
            }}
          >
            <Text
              size='body'
              variant={
                hasErrorInSection(section?.fields, section.fieldErrors ?? {}) ? 'error' : undefined
              }
              className='capitalize'
            >
              {section.label}
            </Text>
          </AccordionTrigger>
          <AccordionContent>
            <div className='w-full'>{section.children}</div>
          </AccordionContent>
        </AccordionItem>
      ))}
    </Accordion>
  )
}
