import { type FC } from 'react'
import type { Lease } from '../../../../../types'
import { Button, Text } from '../../../../../components/ui'
import { useRenewLease } from '../../../hooks/useRenewLease'
import { useTranslation } from 'react-i18next'

interface LeaseRenewalProps {
  lease: Lease
  state: 'accept' | 'reject'
  next: () => void
}

export const LeaseRenewal: FC<LeaseRenewalProps> = ({ next, lease, state }) => {
  const { acceptLeaseRenewal, isLoading } = useRenewLease(next)
  const { t } = useTranslation('leaseManagement')

  const onSubmit = (): void => {
    acceptLeaseRenewal(lease, {
      LAST_UPDATED_BY: undefined,
      LEASE_ID: undefined,
      FARMER_ID: undefined,
      ACCEPTANCE: state === 'accept'
    })
  }

  return (
    <div className={'w-full flex flex-col gap-4'}>
      <Text className={'text-center'} size={'large'} variant={'colorText'}>
        {state === 'accept' ? t('ren.accept') : t('ren.rej')} {t('ren.prompt')}
      </Text>

      {state === 'accept' ? (
        <Text className={'text-center'} size={'medium'} variant={'bodyTextLight'}>
          {t('ren.ren1')} <strong>{t('ren.accept')}</strong> {t('ren.ren2')}
          <strong>
            {lease.dest_farmer_type === 2 ? lease.dest_farmer_company_name : lease.dest_farmer_name}
          </strong>{' '}
          {t('ren.ren3')}
        </Text>
      ) : (
        <Text className={'text-center'} size={'medium'} variant={'bodyTextLight'}>
          {t('ren.ren1')} <strong>{t('ren.rej')}</strong> {t('ren.ren2')}
          <strong>
            {lease.dest_farmer_type === 2 ? lease.dest_farmer_company_name : lease.dest_farmer_name}
          </strong>{' '}
          {t('ren.ren3')}
        </Text>
      )}

      {state === 'accept' ? (
        <Text variant={'error'} className={'text-center font-bold uppercase'} size={'medium'}>
          {t('ren.note')}
        </Text>
      ) : null}

      <div className={'w-full flex justify-between gap-4 md:gap-8'}>
        <Button disabled={isLoading} variant={'secondary'} className={'w-1/2'} onClick={next}>
          {t('ren.cancel')}
        </Button>
        <Button
          disabled={isLoading}
          loading={isLoading}
          className={'w-1/2'}
          variant={'success'}
          onClick={onSubmit}
        >
          {state === 'accept' ? t('ren.accept') : t('ren.rej')}
        </Button>
      </div>
    </div>
  )
}
