interface Sections {
  title: string
  description: string
}

export const FAQData = (t: (key: string) => string): Sections[] => {
  return [
    {
      title: t('faq.que1'),
      description: t('faq.ans1')
    },
    {
      title: t('faq.que2'),
      description: t('faq.ans2')
    },
    {
      title: t('faq.que3'),
      description: t('faq.ans3')
    },
    {
      title: t('faq.que4'),
      description: t('faq.ans4')
    },
    {
      title: t('faq.que5'),
      description: t('faq.ans5')
    },
    {
      title: t('faq.que6'),
      description: t('faq.ans6')
    },
    {
      title: t('faq.que7'),
      description: t('faq.ans7')
    },
    {
      title: t('faq.que8'),
      description: t('faq.ans8')
    }
  ]
}