import { axios } from 'lib/axios'
import { type ApiResponseWithObject } from 'types'
import { type SuccessfulVoucherRedemption } from 'features/merchant/types'
import { type SelectedChemicals } from '../types'

export interface ChemicalVoucherDTO {
  merchant_id: number | null | undefined
  omang: string | null | undefined
  chemicals: SelectedChemicals[]
}

export const redeemChemicalVoucher = async (
  payload: ChemicalVoucherDTO
): Promise<ApiResponseWithObject<SuccessfulVoucherRedemption>> => {
  return axios.post('/voucher/redemption/chemicals ', payload)
}
