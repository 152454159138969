import type React from 'react'
import { type ReactElement, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { RegisterPoweredImplements } from '../components/RegisterPoweredImplements'
import { RegisterNonPoweredImplements } from '../components/RegisterNonPoweredImplements'
import { RegisterTractors } from '../components/RegisterTractors'
import { useGetVerifiedFarms } from '../util'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator
} from '../../../../components/ui'
import { useFarmerStore } from '../../../../stores/useFarmerStore'
import { useTranslation } from 'react-i18next'

export const InventoryForm: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { farmer } = useFarmerStore()
  const { verifiedFarms, isLoading: isFarmsLoading } = useGetVerifiedFarms()
  useEffect(() => {
    if (
      params.inventoryType == null ||
      !['pwrd-implements', 'trd-implements', 'tractor'].includes(params.inventoryType)
    ) {
      navigate('/farmer/inventory')
    }
  }, [params.inventoryType, navigate])
  const { t } = useTranslation('inventory')

  const renderContent = (): ReactElement | null => {
    if (isFarmsLoading) return null

    switch (params.inventoryType) {
      case 'pwrd-implements':
        return (
          <RegisterPoweredImplements isView={false} isMerchant={false} farmsList={verifiedFarms} />
        )
      case 'trd-implements':
        return (
          <RegisterNonPoweredImplements
            isView={false}
            isMerchant={false}
            farmsList={verifiedFarms}
          />
        )

      default:
        return <RegisterTractors isView={false} isMerchant={false} farmsList={verifiedFarms} />
    }
  }

  return (
    <div className={'flex flex-col gap-4 md:gap-8'}>
      <div className={'flex justify-between items-center border-b border-border p-4'}>
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link
                  className={'text-lg'}
                  to={`${farmer != null ? '/farmer/inventory' : 'inventory'}`}
                >
                  {t('inventory.inventory')}
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage className={'text-lg'}>
                {` ${
                  params.inventoryType === 'pwrd-implements'
                    ? `${t('inventory.newPwrd')}`
                    : params.inventoryType === 'trd-implements'
                    ? `${t('inventory.newNonPwrd')}`
                    : `${t('inventory.newTractor')}`
                }`}
              </BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      {renderContent()}
    </div>
  )
}