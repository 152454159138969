import type React from 'react'
import { type CroppingPlan, CroppingPlanHeaderStatus } from 'types'
import { Text } from '../../../../components/Elements/Text'
import { useTranslation } from 'react-i18next'

interface CroppingPlanStatusesProps {
  croppingPlan: CroppingPlan
  className?: string
}

export const CroppingPlanStatuses: React.FC<CroppingPlanStatusesProps> = ({
  croppingPlan,
  className
}) => {
  const { t } = useTranslation('extensionOfficer')

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.Rejected_CP_Verification) {
    return (
      <div className={className}>
        <Text size={'medium'} variant={'error'} className={'flex gap-2 items-center'}>
          <p className='fa-solid fa-circle-xmark' /> {t('cp.rej')}
        </Text>
      </div>
    )
  }
  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.Rejected_Farm_Assessment_Verification) {
    return (
      <div className={className}>
        <Text variant={'error'} className={'flex gap-2 items-center'}>
          <p className='fa-solid fa-circle-xmark' /> {t('cp.faRej')}
        </Text>
      </div>
    )
  }

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.Awaiting_Farm_Assessment) {
    return (
      <div className={className}>
        <Text variant={'warning'} className={'flex gap-2 items-center'}>
          <p className='material-symbols-outlined'>pending_actions</p> {t('cp.aFa')}
        </Text>
      </div>
    )
  }

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.RAI_Farm_Assessment) {
    return (
      <div className={className}>
        <Text variant={'warning'} className={'flex gap-2 items-center'}>
          <p className='material-symbols-outlined'>pending_actions</p> {t('cp.rafir')}
        </Text>
      </div>
    )
  }

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.RAI_CP_Verification) {
    return (
      <div className={className}>
        <Text variant={'primaryLight'} className={'flex gap-2 items-center'}>
          <p className='material-symbols-outlined'>pending_actions</p> {t('cp.rair')}
        </Text>
      </div>
    )
  }

  if (croppingPlan.is_verified === CroppingPlanHeaderStatus.Approved) {
    return (
      <div className={className}>
        <Text variant={'success'} className={'flex gap-2 items-center'}>
          <p className='fa-solid fa-circle-check' /> {t('cp.app')}
        </Text>
      </div>
    )
  }

  return (
    <div className={className}>
      <Text variant={'primaryLight'} className={'flex gap-2 items-center'}>
        <p className='material-symbols-outlined'>pending_actions</p> {t('cp.sfr')}
      </Text>
    </div>
  )
}