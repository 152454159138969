import type React from 'react'
import { useEffect, useState } from 'react'
import { Loader, useStepper } from '../../../../../../components/ui'
import { ChemicalWeeding } from './ChemicalWeeding'
import { useCroppingPlanStore } from '../../../stores/useCroppingPlanStore'
import { useGetWeeding } from '../../../api/weeding/getWeeding'
import { Error } from '../../../../../../components/Errors/Error'
import { ManualWeedingPlan } from './ManualWeeding'

// TODO manual weeding implementation
export const WeedingPlan: React.FC = () => {
  const { setWeeding, header } = useCroppingPlanStore()
  const [isChemical, setIsChemical] = useState<boolean>(false)
  const { prevStep } = useStepper()
  const {
    data: apiWeeding,
    isInitialLoading: apiIsLoading,
    error: weedingError
  } = useGetWeeding(header?.farmer_id, header?.cropping_plan_id)

  const handleBack = (): void => {
    setIsChemical(false)
  }

  useEffect(() => {
    if (apiWeeding != null) {
      setWeeding(apiWeeding.data)
    }
  }, [apiIsLoading])

  if (apiIsLoading) {
    return (
      <div className={'grid place-items-center'}>
        <Loader />
      </div>
    )
  }

  if (weedingError != null) {
    if (weedingError?.response?.status !== 404) {
      return <Error />
    }
  }

  if (!isChemical) {
    return <ManualWeedingPlan back={prevStep} onChemical={setIsChemical} />
  }

  return <ChemicalWeeding back={handleBack} />
}
