import type React from 'react'
import { useParams } from 'react-router-dom'
import { useGetLeased } from '../../api/getLeased'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from 'components/ui'
import { Loader } from 'components/ui/loader'
import { NotFound } from 'components/Errors/NotFound'
import { TabView } from 'components/App/Layout'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'

export const LeaseHistory: React.FC = () => {
  const { farmerId } = useParams()
  const { data: leases, isLoading, error } = useGetLeased(farmerId)
  const { t } = useTranslation('extensionOfficer')

  if (isLoading) {
    return (
      <TabView>
        <div className={'grid place-items-center'}>
          <Loader />
        </div>
      </TabView>
    )
  }

  if (error != null || leases == null) {
    return <NotFound />
  }

  return (
    <TabView>
      <div className={'bg-background w-full flex flex-col gap-2 sm:gap-4 p-4'}>
        <Table>
          <TableCaption>{t('lease.history')}</TableCaption>
          <TableHeader>
            <TableRow>
              <TableHead className='font-bold text-color-text'>{t('lease.leaseId')}</TableHead>
              <TableHead className='font-bold text-color-text'>{t('lease.leaseeId')}</TableHead>
              <TableHead className='font-bold text-color-text'>{t('lease.lname')}</TableHead>
              <TableHead className='font-bold text-color-text'>{t('lease.fname')}</TableHead>
              <TableHead className='font-bold text-color-text'>{t('lease.size')}</TableHead>
              <TableHead className='font-bold text-color-text'>{t('lease.start')}</TableHead>
              <TableHead className='font-bold text-color-text'>{t('info.expiry')}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {leases.data.map((lease) => (
              <TableRow key={lease.lease_id} role={'listitem'} className={'hover:bg-muted-hover'}>
                <TableCell className='font-medium'>{lease.lease_id}</TableCell>
                <TableCell className='font-medium'>{lease.dest_farmer_id}</TableCell>
                <TableCell className='font-medium'>{lease.dest_farmer_firstname}</TableCell>
                <TableCell className='font-medium'>{lease.farm_name}</TableCell>
                <TableCell className='font-medium'>{lease.leased_size}</TableCell>
                <TableCell className='font-medium'>
                  {dayjs(lease.commencement_date).format('DD/MM/YYYY')}
                </TableCell>
                <TableCell className='font-medium'>
                  {lease.new_termination_date != null
                    ? dayjs(lease.new_termination_date).format('DD-MM-YYYY')
                    : dayjs(lease.expiry_date).format('DD-MM-YYYY')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </TabView>
  )
}
