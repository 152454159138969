import type React from 'react'
import { Text } from 'components/Elements/Text/Text'
import { Button, Input } from 'components/ui'
import { Loader } from '../../../../../components/ui/loader'
import { useNewFarm } from '../../hooks/useNewFarm'
import { useNewFarmStore } from '../../stores/newFarm'
import { useTranslation } from 'react-i18next'

interface LandReviewProps {
  onBack: () => void
  onNext: () => void
}

export const LandReview: React.FC<LandReviewProps> = ({ onBack, onNext }) => {
  const { farmDetails } = useNewFarmStore()
  const { isLoading, location, loading, officer, createNewFarm } = useNewFarm()
  const { t } = useTranslation('landManagement')

  if (isLoading) {
    return (
      <div className={'grid place-items-center h-full'}>
        <Loader />
      </div>
    )
  }

  const officerName = `${officer?.firstname ?? ''} ${officer?.surname ?? ''}`

  const onSubmit = (): void => {
    createNewFarm(onNext)
  }

  return (
    <div className={'flex flex-col gap-1 w-full'}>
      <Text size={'medium'} variant={'primaryLight'} className={'mb-2 mx-3'}>
        {t('deed.correct')}
      </Text>
      <Input withLabel label={`${t('deed.name')}`} disabled={true} value={farmDetails?.nickname} />
      <Input
        withLabel
        label={`${t('deed.size')}`}
        disabled={true}
        value={farmDetails?.size.toFixed(4)}
      />
      <Input
        withLabel
        label={`${t('deed.district')}`}
        disabled={true}
        value={location?.district_name}
      />
      <Input
        withLabel
        label={`${t('deed.sub')}`}
        disabled={true}
        value={location?.sub_district_name}
      />
      <Input
        withLabel
        label={`${t('deed.extension')}`}
        disabled={true}
        value={location?.extension_area_name}
      />
      <Input
        withLabel
        label={`${t('deed.village')}`}
        disabled={true}
        value={location?.village_name}
      />
      <Input
        withLabel
        label={`${t('deed.officer')}`}
        disabled={true}
        value={officerName === ' ' ? 'N/A' : officerName}
      />
      <div className={'w-full flex justify-between gap-4 mt-2'}>
        <Button variant={'secondary'} disabled={loading} className={'w-1/2'} onClick={onBack}>
          {t('deed.back')}
        </Button>
        <Button className={'w-1/2'} onClick={onSubmit} disabled={loading} loading={loading}>
          {t('deed.complete')}
        </Button>
      </div>
    </div>
  )
}