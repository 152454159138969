import { axios } from 'lib/axios'
import { type ApiResponseWithObject } from 'types'

export interface UpdateMerchantKycDTO {
  user_role: number | null | undefined
  uin: string | null | undefined
  merchant_name: string | null | undefined
  trade_name: string | null | undefined
  email: string | null | undefined
  contact: string | null | undefined
  postal_addr: string
  physical_addr: string
  last_updated_by: number | null | undefined
  merchant_pob: string
  merchant_expiry_date: string
  merchant_firstname: string
  merchant_midname: string | null | undefined
  merchant_surname: string
  merchant_omang: string
  merchant_dob: string
  merchant_gender: string
  merchant_id: number | null | undefined
}

export const updateMerchantKyc = async (
  params: UpdateMerchantKycDTO
): Promise<ApiResponseWithObject<[]>> => {
  return axios.put('/merchant', params)
}
