import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface ValidateOmangDTO {
  OMANG: string
  FORENAMES: string
  SURNAME: string
  DOB: string
  GENDER: string
  EXPIRY_DATE: string
  PLACE_OF_BIRTH: string
}

export const verifyMerchantOmang = async (params: ValidateOmangDTO): Promise<ApiResponse<[]>> => {
  return axios.post('/validate/omang', params)
}
