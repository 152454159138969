import { useSearchFarm } from 'features/officer/land-management/hooks/useSearchFarm'
import type React from 'react'
import { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { useOfficerStore } from 'stores/useOfficerStore'
import { FarmResults } from '../components/FarmResults'
import { Card, Loader, Text } from 'components/ui'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const SearchFarms: React.FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const query = searchParams.get('q')
  const { farmerId } = useParams<{ farmerId: string }>()
  const { t } = useTranslation('extensionOfficer')
  const { officer } = useOfficerStore()
  const { searchFarm, data, error, isLoading } = useSearchFarm(officer?.ext_area_id)

  useEffect(() => {
    if (farmerId != null) {
      void searchFarm(farmerId)
    }
    if (error !== null) {
      toast.error(error, { id: error })
    }

    // on page refresh search based on query param
    if (farmerId !== '' && query != null && !isLoading) {
      void searchFarm(farmerId ?? '')
    }
  }, [])
  const handleBack = (): void => {
    navigate('/officer', { replace: true })
  }
  return (
    <div className={'max-w-[1700px] w-full flex-col gap-4  flex justify-center mx-auto px-0 py-4'}>
      <button onClick={handleBack} className={'flex items-center gap-4 cursor-pointer group'}>
        <Text variant={'bodyTextLight'} size={'large'} className={'group-hover:text-primary'}>
          <p className='fa-solid fa-arrow-left' />
        </Text>
        <div className={'flex gap-1 items-baseline'}>
          <Text
            size={'large'}
            variant={'bodyTextLight'}
            className={'group-hover:text-secondary capitalize'}
          >
            {t('land.farms')}
          </Text>
        </div>
      </button>
      <Card
        className={
          'w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
        }
      >
        {isLoading ? (
          <div className={'mt-8 grid place-items-center h-full flex-1'}>
            <Loader />
          </div>
        ) : null}

        {error !== null ? (
          <Text size={'medium'} variant={'error'} className={'ml-4 text-center'}>
            {error}
          </Text>
        ) : null}

        {error === null && !isLoading && data?.length === 0 ? (
          <Text className={'text-center'} variant={'primary'} size={'medium'}>
            {t('cp.nosr')}
          </Text>
        ) : null}

        {data !== null && data.length > 0 ? (
          <FarmResults
            farms={data}
            className={'w-full flex flex-col gap-2'}
            invalidateSearch={() => {
              void searchFarm(farmerId ?? '')
            }}
          />
        ) : null}
      </Card>
    </div>
  )
}