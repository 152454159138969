import { useGetInventoryItem } from '../../../../farmer/inventory/api/getInventoryItem'
import { findMatchingItem } from '../../../../farmer/inventory/util/findMatchingItem'
import { TractorChecklist } from './TractorChecklist'
import { TilageChecklist } from './TilageChecklist'
import { HarvestingProcessingChecklist } from './HarvestingProcessingChecklist'
import { Applicators } from './SprayersChecklist'
import { SeederChecklist } from './SeedersChecklist'
import { SelfPropelledSprayers } from './PropelledSprayersChecklist'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
  Button,
  Loader,
  toast
} from '../../../../../components/ui'
import { Link, useNavigate, useParams } from 'react-router-dom'
import type React from 'react'
import { type ReactElement, useEffect, useState } from 'react'
import {
  type Implement,
  type PoweredImplement,
  type Tractor
} from '../../../../farmer/inventory/types'
import { useOfficerStore } from '../../../../../stores/useOfficerStore'
import { NotFound } from '../../../../../components/Errors/NotFound'
import { useAuthenticationStore } from '../../../../../stores/useAuthenticationStore'
import { usePDF } from 'react-to-pdf'
import { FaDownload } from 'react-icons/fa6'
import { useTranslation } from 'react-i18next'
import { useStaticDataStore } from '../../../../../stores/useStaticDataStore'

export const Checklist: React.FC = () => {
  const { toPDF, targetRef } = usePDF({ filename: 'Assessment.pdf' })
  const { officer } = useOfficerStore()
  const { t } = useTranslation('inventory')
  const { inventoryId } = useParams<{ inventoryId: string }>()
  const { data: invItem, isLoading: isLoadingInvItem } = useGetInventoryItem(Number(inventoryId))
  const [inventoryItem, setInventoryItem] = useState<Tractor | PoweredImplement | Implement>()
  const inventoryData = invItem?.data[0]
  const [checklistName, setChecklistName] = useState<string>('')
  const navigate = useNavigate()
  const { role } = useAuthenticationStore()
  const { tractors, nonPoweredImplements, poweredImplements } = useStaticDataStore()

  const handleDownloadPdf = async (): Promise<void> => {
    await new Promise<void>((resolve) => {
      toPDF()
      resolve()
    })
    toast({
      variant: 'success',
      title: 'Printing',
      description: 'Save to your device'
    })
  }

  useEffect(() => {
    if (
      tractors != null &&
      nonPoweredImplements != null &&
      poweredImplements != null &&
      inventoryData != null
    ) {
      setInventoryItem(
        findMatchingItem(inventoryData, tractors, nonPoweredImplements, poweredImplements)
      )
    }
  }, [tractors, nonPoweredImplements, poweredImplements, inventoryData])

  useEffect(() => {
    if (inventoryItem != null && inventoryData != null) {
      let name = ''
      if (inventoryData.inventory_type_id === 1003) {
        name = 'Tractor'
      } else {
        const checklistType = (
          inventoryData.inventory_type_id === 1001 ? poweredImplements : nonPoweredImplements
        )?.find(
          (item) =>
            item.implement_class ===
            (inventoryItem as PoweredImplement | Implement)?.implement_class
        )

        if (checklistType != null) {
          switch (checklistType.implement_class) {
            case 'SECONDARY':
            case 'PRIMARY':
              name = 'Tillage'
              break
            case 'PROCESSOR':
            case 'HARVESTER':
              name = 'Harvesting & Processing'
              break
            case 'APPLICATOR':
              name =
                checklistType.implement_sub_type === 'SELF PROPELLED'
                  ? 'Self Propelled Sprayer'
                  : 'Sprayer'
              break
            case 'SEEDER':
              name = 'Seeder'
              break
            default:
              name = ''
          }
        }
      }
      setChecklistName(name)
    }
  }, [inventoryItem, inventoryData, tractors, nonPoweredImplements, poweredImplements])

  const isView =
    inventoryData?.item_verification_status === 'VERIFIED' ||
    inventoryData?.item_verification_status === 'REJECTED' ||
    inventoryData?.item_verification_status === 'TO BE REASSESSED'
  const isEdit = inventoryData?.item_verification_status !== 'PENDING_VERIFICATION'

  if (isLoadingInvItem) {
    return (
      <div className='mt-8 grid place-items-center h-full flex-1'>
        <Loader />
      </div>
    )
  }

  if (
    inventoryData?.item_verification_status === 'PENDING_VERIFICATION' &&
    officer === null &&
    role === 1001
  ) {
    return <NotFound />
  }

  const renderChecklist = (): null | ReactElement => {
    if (isLoadingInvItem || inventoryItem == null) {
      return null
    }

    if (inventoryData?.inventory_type_id === 1003) {
      return (
        <TractorChecklist
          inventoryData={inventoryData}
          inventoryItem={inventoryItem}
          isEdit={isEdit}
          isView={isView}
        />
      )
    }

    const checklistType = (
      inventoryData?.inventory_type_id === 1001 ? poweredImplements : nonPoweredImplements
    )?.find(
      (item) =>
        item.implement_class === (inventoryItem as PoweredImplement | Implement)?.implement_class
    )

    if (checklistType == null) return null

    switch (checklistType.implement_class) {
      case 'SECONDARY':
      case 'PRIMARY':
        return (
          <TilageChecklist
            inventoryData={inventoryData}
            inventoryItem={inventoryItem}
            isEdit={isEdit}
            isView={isView}
          />
        )
      case 'PROCESSOR':
      case 'HARVESTER':
        return (
          <HarvestingProcessingChecklist
            inventoryData={inventoryData}
            inventoryItem={inventoryItem}
            isEdit={isEdit}
            isView={isView}
          />
        )
      case 'APPLICATOR':
        if (checklistType.implement_sub_type === 'SELF PROPELLED') {
          return (
            <SelfPropelledSprayers
              inventoryData={inventoryData}
              inventoryItem={inventoryItem}
              isEdit={isEdit}
              isView={isView}
            />
          )
        } else {
          return (
            <Applicators
              inventoryData={inventoryData}
              inventoryItem={inventoryItem}
              isEdit={isEdit}
              isView={isView}
            />
          )
        }
      case 'SEEDER':
        return (
          <SeederChecklist
            inventoryData={inventoryData}
            inventoryItem={inventoryItem}
            isEdit={isEdit}
            isView={isView}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <div className='border-b border-border flex justify-between items-center mb-5'>
        <Breadcrumb className={'p-4'}>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link
                  className={'text-lg'}
                  to={`${
                    role === 1000
                      ? '/farmer/inventory'
                      : officer != null
                      ? `${role === 1001 ? '/officer' : ''}/inventory`
                      : '/service-provider/inventory'
                  }`}
                >
                  {t('mech.inventory')}
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink asChild>
                <Link
                  className={'text-lg'}
                  to={``}
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  {t('mech.ii')}
                </Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage className={'text-lg'}>{`${checklistName} ${t(
                'mech.check'
              )}`}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <Button variant={'icon'} onClick={handleDownloadPdf}>
          {t('mech.down')} <FaDownload />
        </Button>
      </div>
      <div ref={targetRef}>{renderChecklist()}</div>
    </>
  )
}
