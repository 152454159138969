import type React from 'react'
import { type Lease } from 'types'
import { LeaseVerificationStatus } from './LeaseVerificationStatus'
import { useNavigate } from 'react-router-dom'
import { Card, Text } from 'components/ui'

interface LeaseCardProps {
  lease: Lease
}

export const LeaseCard: React.FC<LeaseCardProps> = ({ lease }) => {
  const navigate = useNavigate()

  const handleClick = (): void => {
    if (
      lease.status_desc === 'VERIFIED BY EXTENSION OFFICER' ||
      lease.status_desc === 'RENEWAL ACCEPTED BY LESSOR'
    ) {
      navigate(`/farmer/lease/${lease.lease_id}`)
    }
  }
  const hoverStyles =
    lease.status_desc === 'VERIFIED BY EXTENSION OFFICER' ||
    lease.status_desc === 'RENEWAL ACCEPTED BY LESSOR'
      ? 'transition-shadow duration-200 ease-in-out transform hover:shadow-lg hover:shadow-md hover:shadow-input hover:border-second dark:hover:border-second-foreground'
      : ''
  const cursorStyle =
    lease.status_desc === 'VERIFIED BY EXTENSION OFFICER' ||
    lease.status_desc === 'RENEWAL ACCEPTED BY LESSOR'
      ? 'cursor-pointer'
      : 'shadow-none border-muted cursor-default'

  return (
    <Card
      onClick={handleClick}
      className={`sm:max-w-[500px] relative flex flex-col gap-2 sm:gap-4 md:px-6 lg:px-8 hover:${hoverStyles} ${cursorStyle}`}
    >
      <div className={'flex gap-4 items-center justify-between'}>
        <div className={'flex gap-1 items-baseline'}>
          <Text size={'large'}>{lease.leased_size.toFixed(4)}</Text>
          <Text>ha</Text>
        </div>
        {(lease.status_desc === 'VERIFIED BY EXTENSION OFFICER' ||
          lease.status_desc === 'RENEWAL ACCEPTED BY LESSOR') && (
          <Text size={'medium'}>
            <p className='fa-solid fa-up-right-from-square' />
          </Text>
        )}
      </div>
      <div className={'w-full flex flex-col'}>
        <Text size={'medium'} className={'capitalize'}>
          {lease.farm_name.toLowerCase()}
        </Text>
        <Text className={'capitalize'} variant={'bodyTextLight'}>
          {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
          {`${lease.village.toLowerCase()}, ${lease.extension_area.toLowerCase()}`}
        </Text>
      </div>
      <LeaseVerificationStatus status_desc={lease.status_desc} className={'flex gap-2'} />
    </Card>
  )
}
