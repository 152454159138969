import type { ApiResponse } from 'types'
import { axios } from 'lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type SoilCorrection } from '../../../../types/soilCorrection'
import { type AxiosError } from 'axios'

export const getSoilCorrections = async (
  farmer_id: number | null | undefined,
  ssrId: string | number | null | undefined,
  farmId?: string | number | null | undefined,
  leaseId?: string | number | null | undefined
): Promise<ApiResponse<SoilCorrection>> => {
  if (leaseId != null) {
    return axios.get(
      `soilcorrection?ssr_id=${ssrId ?? ''}&farmer_id=${farmer_id ?? ''}&lease_id=${leaseId ?? ''}`
    )
  }
  return axios.get(
    `soilcorrection?ssr_id=${ssrId ?? ''}&farmer_id=${farmer_id ?? ''}&farm_id=${farmId ?? ''}`
  )
}

export const useGetSoilCorrections = (
  farmer_id: number | null | undefined,
  ssrId: string | number | null | undefined,
  farmId?: string | number | null | undefined,
  leaseId?: string | number | null | undefined
): UseQueryResult<ApiResponse<SoilCorrection>, AxiosError> => {
  return useQuery({
    queryKey: ['soil-correction', ssrId],
    queryFn: async () => getSoilCorrections(farmer_id, ssrId, farmId, leaseId),
    enabled:
      (farmer_id != null && farmId != null && ssrId != null) ||
      (farmer_id != null && leaseId != null && ssrId != null)
  })
}
