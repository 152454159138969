import { ApproveCroppingPlan } from 'features/officer/cropping-plan/components/approve/ApproveCroppingPlan'
import type React from 'react'
import { type CroppingPlan, CroppingPlanHeaderStatus } from 'types'
import { useTranslation } from 'react-i18next'

interface AcceptOrRejectCroppingPlanProps {
  header: CroppingPlan
  onClose: () => void
  acceptCroppingPlan: () => void
  approveCroppingPlan: () => void
  isLoading: boolean
  className: string
}

export const AcceptCroppingPlan: React.FC<AcceptOrRejectCroppingPlanProps> = ({
  header,
  onClose,
  acceptCroppingPlan,
  approveCroppingPlan,
  isLoading,
  className
}) => {
  const { t } = useTranslation('extensionOfficer')
  if (header.is_verified === CroppingPlanHeaderStatus.Awaiting_Farm_Assessment) {
    return (
      <div className={className}>
        <ApproveCroppingPlan
          label={t('cp.fa')}
          onApprove={approveCroppingPlan}
          onCancel={onClose}
          isLoading={isLoading}
        />
      </div>
    )
  }

  return (
    <div className={className}>
      <ApproveCroppingPlan
        label={t('cp.cp')}
        onApprove={acceptCroppingPlan}
        onCancel={onClose}
        isLoading={isLoading}
      />
    </div>
  )
}