// programPackages.ts

interface Detail {
  title: string
  description: string
}

interface EligibilityCriteria {
  description: string
}

interface TractorandImplementsEnhancer {
  detail: Detail[]
  eligibilityCriteria: EligibilityCriteria[]
}

const tractorandImplementsEnhancer: TractorandImplementsEnhancer = {
  detail: [
    {
      title: '50% Subsidy on Tractor and Implements:',
      description:
        'Farmers can access a 50% subsidy on the purchase of new tractors and farming implements, allowing them to modernize their operations and significantly improve efficiency. This subsidy is particularly valuable for farmers managing medium to large plots of land.'
    },
    {
      title: 'Maximum Contribution:',
      description:
        'The government can provide up to P250,000 for tractors and related implements, meaning farmers can access machinery worth up to P500,000.'
    },
    {
      title: 'Special Provisions:',
      description:
        'The scheme covers a wide range of implements that are essential for various farming tasks, including:\n' +
        'Plows: For soil preparation.\n' +
        'Seeders/Planters: For efficient and precise crop planting.\n' +
        'Harrows: For soil levelling and preparation before planting.\n' +
        'Trailers: For transporting goods and materials within the farm.'
    }
  ],

  eligibilityCriteria: [
    {
      description: 'Farmers must be registered with the Temo Crop Management System.'
    },
    {
      description:
        'Farmers are required to provide proof of land ownership or a long-term lease agreement.'
    },
    {
      description: 'The farm must be fenced to qualify for this subsidy.'
    }
  ]
}

export default tractorandImplementsEnhancer
