import { ReloadIcon } from '@radix-ui/react-icons'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from 'lib/utils'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

const buttonVariants = cva(
  'font-redHat capitalize inline-flex items-center gap-4 justify-center whitespace-nowrap rounded-md text-md font-semibold ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none',
  {
    variants: {
      variant: {
        default:
          'bg-primary text-primary-foreground hover:bg-primary-hover disabled:bg-primary-disabled disabled:text-primary-disabled-foreground',
        primary:
          'bg-primary text-primary-foreground hover:bg-primary-hover disabled:bg-primary-disabled disabled:text-primary-disabled-foreground',
        outlined:
          'border bg-primary-foreground text-primary border-primary hover:text-primary-hover hover:border-primary-hover hover:bg-primary-background disabled:bg-primary-disabled disabled:text-primary-disabled-foreground disabled:border-none',
        destructive:
          'bg-destructive hover:bg-destructive-hover disabled:bg-destructive-disabled text-destructive-foreground',
        destructiveOutlined:
          'border bg-white text-destructive border-destructive hover:text-destructive-hover hover:border-destructive-hover hover:bg-destructive-background disabled:bg-primary-disabled disabled:text-primary-disabled-foreground disabled:border-none',
        success:
          'bg-success hover:bg-success-hover text-success-foreground disabled:bg-success-disabled disabled:text-success-disabled-foreground',
        warning: 'bg-warning hover:bg-warning-hover text-warning-foreground',
        secondary:
          'bg-transparent border border-second-outline text-foreground hover:bg-second-background disabled:border-second-disabled disabled:text-second-disabled',
        tertiary:
          'bg-second hover:bg-second-hover text-second-foreground disabled:bg-second-disabled',
        ghost:
          'bg-muted hover:bg-muted-hover text-foreground disabled:bg-second-disabled disabled:opacity-40',
        link: 'text-primary underline-offset-4 hover:underline',
        icon: 'dark:hover:bg-second-background text-primary hover:text-primary-hover hover:bg-primary-background dark:disabled:bg-inherit disabled:text-primary-disabled-foreground'
      },
      size: {
        default: 'md:h-10 p-2',
        xs: 'px-4 py-2 gap-2 text-[0.8rem] md:text-[0.9rem] lg:h-10',
        sm: 'md:h-10 px-4 py-2 w-min-content',
        lg: 'w-full p-2 rounded',
        icon: 'md:h-10 w-10 p-2'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
  loading?: boolean
  disabled?: boolean
  startIcon?: React.ReactElement
  endIcon?: React.ReactElement
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      loading = false,
      disabled = false,
      startIcon: StartIcon,
      endIcon: EndIcon,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button'
    const { t } = useTranslation('genericTranslation')

    if (loading) {
      return (
        <Comp
          disabled={loading || disabled}
          className={cn(buttonVariants({ variant, size, className }))}
          ref={ref}
          {...props}
        >
          <ReloadIcon className='mr-2 h-4 w-4 animate-spin' />
          {t('btn.wait')}
        </Comp>
      )
    }

    if (StartIcon != null || EndIcon != null) {
      return (
        <Comp
          disabled={disabled}
          className={cn(buttonVariants({ variant, size, className }))}
          ref={ref}
          type={'button'}
          {...props}
        >
          {StartIcon}
          {props.children}
          {EndIcon}
        </Comp>
      )
    }

    return (
      <Comp
        disabled={disabled}
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        type={'button'}
        {...props}
      />
    )
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }