import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'
import { type AxiosError } from 'axios'
import { type FarmHistory } from '../../types'

export const getFarmHistory = async (
  farmerId: number | null | undefined,
  farmId: string | number | null | undefined
): Promise<ApiResponse<FarmHistory>> => {
  return axios.get(`/farm/edit?og_farmer_id=${farmerId ?? ''}&farm_id=${farmId ?? ''}`)
}

export const useGetFarmHistory = (
  farmerId: number | null | undefined,
  farmId: string | number | null | undefined
): UseQueryResult<ApiResponse<FarmHistory>, AxiosError> => {
  return useQuery({
    queryKey: ['farmHistory', farmId],
    queryFn: async () => getFarmHistory(farmerId, farmId),
    enabled: farmerId != null && farmId != null
  })
}
