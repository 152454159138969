import type React from 'react'
import { useState } from 'react'
import { HerbicidesTable } from '../tables/HerbicidesTable'
import { useWeedingStore } from '../../../../stores/useWeedingStore'
import { useStaticDataStore } from '../../../../../../../stores/useStaticDataStore'
import { useCroppingPlanStore } from '../../../../stores/useCroppingPlanStore'
import {
  getDualWeedingScheduleToSingle,
  getScheduleToApi,
  isDualWeedingSchedule
} from '../../../../utils/functions'
import { herbicidesColumn } from '../tables/columns'
import { Error } from '../../../../../../../components/Errors/Error'
import { Text } from '../../../../../../../components/ui'
import { Info } from 'lucide-react'

interface ChemicalSelectionProps {
  next: () => void
  back: () => void
}

export const ChemicalSelection: React.FC<ChemicalSelectionProps> = ({ next, back }) => {
  const { header, weeding } = useCroppingPlanStore()
  const { herbicides } = useStaticDataStore()
  const { schedule } = useWeedingStore()
  const isDualWeeding = isDualWeedingSchedule(schedule)

  const [dualStep, setDualStep] = useState(0)
  const dualWeeding = isDualWeeding ? getDualWeedingScheduleToSingle(schedule) : []

  const herbicidesList = herbicides?.filter(
    (herbicide) =>
      herbicide.crop_id === header?.crop_id &&
      herbicide?.application_stage === getScheduleToApi(schedule)
  )

  if (herbicidesList == null) {
    return <Error />
  }

  const handleDualNext = (): void => {
    setDualStep(dualStep + 1)
  }

  const handleDualBack = (): void => {
    setDualStep(dualStep - 1)
  }

  if (isDualWeeding) {
    if (dualStep === 0) {
      const list = herbicides?.filter(
        (herbicide) =>
          herbicide.crop_id === header?.crop_id &&
          herbicide?.application_stage === getScheduleToApi(dualWeeding[0])
      )

      if (list == null) {
        return <Error />
      }

      return (
        <div className={'flex flex-col gap-4'}>
          <div className={'flex flex-col sm:flex-row gap-4 justify-start sm:items-center'}>
            <Text
              className={' flex justify-center items-center'}
              size={'medium'}
              variant={'primary'}
            >
              {dualWeeding[0]} Weeding Plan
            </Text>
          </div>

          <HerbicidesTable
            key={dualWeeding[0]}
            onNext={handleDualNext}
            data={list}
            onBack={back}
            columns={herbicidesColumn}
            weeding={weeding ?? undefined}
          />
        </div>
      )
    }

    const list = herbicides?.filter(
      (herbicide) =>
        herbicide.crop_id === header?.crop_id &&
        herbicide?.application_stage === getScheduleToApi(dualWeeding[dualWeeding.length - 1])
    )

    if (list == null) {
      return <Error />
    }

    return (
      <div className={'flex flex-col gap-4'}>
        <div className={'flex flex-col sm:flex-row gap-4 justify-start sm:items-center'}>
          <Text className={' flex justify-center items-center'} size={'medium'} variant={'primary'}>
            {dualWeeding[dualWeeding.length - 1]} Herbicide Selection
          </Text>
        </div>

        <HerbicidesTable
          key={dualWeeding[dualWeeding.length - 1]}
          onNext={next}
          data={list}
          onBack={handleDualBack}
          columns={herbicidesColumn}
          weeding={weeding ?? undefined}
        />
      </div>
    )
  }

  return (
    <div className={'flex flex-col gap-4'}>
      <div className={'flex flex-col gap-4 justify-start items-start'}>
        <Text className={' flex justify-center items-center'} size={'medium'} variant={'primary'}>
          {schedule} Herbicide Selection
        </Text>
        <div className='flex'>
          <Info size='18' color={'var(--primary)'} className='mr-2.5' />
          <Text variant='primary' size='small' className='font-bold'>
            Please select one or more herbicides from the table below.
          </Text>
        </div>
      </div>

      <HerbicidesTable
        onNext={next}
        data={herbicidesList}
        columns={herbicidesColumn}
        onBack={back}
        weeding={weeding ?? undefined}
      />
    </div>
  )
}
