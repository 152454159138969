import type React from 'react'
import { useFilePicker } from 'use-file-picker'
import type z from 'zod'
import { coerce, object, string, type TypeOf } from 'zod'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { useAtom } from 'jotai'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { Text } from '../../../../../components/Elements/Text'
import { companyLoanStore } from '../stores'
import { type LoanStepperProps } from '../../../types'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  RadioGroup,
  RadioGroupItem,
  useStepper
} from '../../../../../components/ui'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { Info } from 'lucide-react'
import { LoanStepperFooter } from '../../generic/LoanStepperFooter'
import { Box } from '../../../../../components/App/Layout'

const schema = object({
  fp_checklist1: string().min(1, 'Field is required'),
  fp_checklist2: string().min(1, 'Field is required'),
  fp_checklist3: string().min(1, 'Field is required'),
  fp_checklist4: coerce
    .number({
      required_error: 'field is required',
      invalid_type_error: 'field is required'
    })
    .default(0),
  fp_checklist5: string().min(1, 'Field is required').default('')
})

type FinancialParticularsCompanyDetailsInput = TypeOf<typeof schema>
export const FinancialParticularsCompany: React.FC<LoanStepperProps> = ({ onComplete }) => {
  const { farmer } = useFarmerStore()
  const [loanData, setLoanData] = useAtom(companyLoanStore)
  const { nextStep } = useStepper()
  const { t } = useTranslation('loanManagement')

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      fp_checklist1: loanData?.financial_particulars?.fp_checklist1,
      fp_checklist2: loanData?.financial_particulars?.fp_checklist2,
      fp_checklist3: loanData?.financial_particulars?.fp_checklist3,
      fp_checklist4: loanData?.financial_particulars?.fp_checklist4,
      fp_checklist5: loanData?.financial_particulars?.fp_checklist5
    }
  })

  const onSubmit: SubmitHandler<FinancialParticularsCompanyDetailsInput> = (
    data: FinancialParticularsCompanyDetailsInput
  ): void => {
    setLoanData((prev) => {
      return {
        ...prev!,
        financial_particulars: {
          fp_checklist1: data.fp_checklist1,
          fp_checklist2: data.fp_checklist2,
          fp_checklist3: data.fp_checklist3,
          fp_checklist4: data.fp_checklist3 === 'no' ? 0 : Number(data.fp_checklist4),
          fp_checklist5: data.fp_checklist5,
          fp_checklist6: 'no',
          last_update_by: farmer?.user_id as number
        }
      }
    })
    nextStep()
    if (onComplete != null) {
      onComplete(true)
    }
  }

  const [openFileSelector] = useFilePicker({
    readAs: 'DataURL',
    accept: 'pdf/*',
    multiple: true,
    limitFilesConfig: { max: 2 },
    // minFileSize: 1,
    maxFileSize: 50 // in megabytes
  })

  return (
    <Box>
      <Form {...form}>
        <form className={'flex flex-col w-full'} onSubmit={form.handleSubmit(onSubmit)}>
          <div className='flex flex-col space-y-8 my-6 w-full'>
            <FormLabel className={'font-redHat'}>
              <div className='flex flex-col gap-3'>
                <Text size='medium' className=''>
                  {t('part.fin')}
                </Text>
                <div className='flex items-center'>
                  <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
                  <Text variant='secondary' size='small' className=''>
                    Provide information regarding your financial history and status.
                  </Text>
                </div>
              </div>
            </FormLabel>

            <FormField
              control={form.control}
              name='fp_checklist1'
              render={({ field }) => (
                <FormItem className='space-y-3'>
                  <FormLabel className='font-redHat'>
                    <Text size='body' className=''>
                      {t('part.crim')}
                    </Text>
                  </FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      {...field}
                      className='flex flex-col space-y-1'
                    >
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value={'yes'} />
                        </FormControl>
                        <FormLabel className='font-redHat'>
                          <Text size='body' className=''>
                            {t('part.yes')}
                          </Text>
                        </FormLabel>
                      </FormItem>
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value={'no'} />
                        </FormControl>
                        <FormLabel className='font-redHat'>
                          <Text size='body' className=''>
                            {t('part.no')}
                          </Text>
                        </FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name='fp_checklist2'
              render={({ field }) => (
                <FormItem className='space-y-3'>
                  <FormLabel className='font-redHat'>
                    <Text size='body' className=''>
                      {t('part.pending')}
                    </Text>
                  </FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      {...field}
                      className='flex flex-col space-y-1'
                    >
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value={'yes'} />
                        </FormControl>
                        <FormLabel className='font-redHat'>
                          <Text size='body' className=''>
                            {t('part.yes')}
                          </Text>
                        </FormLabel>
                      </FormItem>
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value={'no'} />
                        </FormControl>
                        <FormLabel className='font-redHat'>
                          <Text size='body' className=''>
                            {t('part.no')}
                          </Text>
                        </FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name='fp_checklist3'
              render={({ field }) => (
                <FormItem className='space-y-3'>
                  <FormLabel className='font-redHat'>
                    <Text size='body' className=''>
                      {t('part.black')}
                    </Text>
                  </FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      {...field}
                      className='flex flex-col space-y-1'
                    >
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value={'yes'} />
                        </FormControl>
                        <FormLabel className='font-redHat'>
                          <Text size='body' className=''>
                            {t('part.yes')}
                          </Text>
                        </FormLabel>
                      </FormItem>
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value={'no'} />
                        </FormControl>
                        <FormLabel className='font-redHat'>
                          <Text size='body' className=''>
                            {t('part.no')}
                          </Text>
                        </FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {(form.watch('fp_checklist3') === 'yes' ||
              loanData?.financial_particulars?.fp_checklist3 === 'yes') && (
              <FormField
                control={form.control}
                name={`fp_checklist4`}
                render={({ field }) => (
                  <div className='flex'>
                    <FormItem className='flex flex-col'>
                      <FormLabel className='font-redHat'>
                        <div className='flex flex-col gap-2'>
                          <Text size='body' className=''>
                            {t('part.amount')} (BWP)
                          </Text>

                          <div className='flex items-center'>
                            <Info size='18' color='rgb(250 204 21)' className='mr-2.5' />
                            <Text variant='secondary' size='small' className=''>
                              Enter the amount that you were blacklisted for.
                            </Text>
                          </div>
                        </div>
                      </FormLabel>
                      <FormControl>
                        <div className='relative'>
                          <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                            <Text size='bodyBold' className=''>
                              BWP
                            </Text>
                          </div>
                          <Input
                            id='currency'
                            inputMode='decimal'
                            type='number'
                            min={0}
                            step={0.01}
                            placeholder='0.00'
                            className='pl-14'
                            {...field}
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  </div>
                )}
              />
            )}

            <FormField
              control={form.control}
              name='fp_checklist5'
              render={({ field }) => (
                <FormItem className='space-y-3'>
                  <FormLabel className='font-redHat'>
                    <Text size='body' className=''>
                      {t('part.entity')}
                    </Text>
                  </FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      {...field}
                      className='flex flex-col space-y-1'
                    >
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value={'yes'} />
                        </FormControl>
                        <FormLabel className='font-redHat'>
                          <Text size='body' className=''>
                            {t('part.yes')}
                          </Text>
                        </FormLabel>
                      </FormItem>
                      <FormItem className='flex items-center space-x-3 space-y-0'>
                        <FormControl>
                          <RadioGroupItem value={'no'} />
                        </FormControl>
                        <FormLabel className='font-redHat'>
                          <Text size='body' className=''>
                            {t('part.no')}
                          </Text>
                        </FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* {form.watch('fp_checklist5') &&  /!*  <p *!/ */}
            {/*    className='flex-[1_0_30px] text-[var(--color-primary)] flex gap-1 items-center capitalize cursor-pointer' */}
            {/*    onClick={() => { */}
            {/*      openFileSelector() */}
            {/*    }} */}
            {/*  > */}
            {/*    <AttachFile /> attachment */}
            {/*  </p> */}

            <LoanStepperFooter />
          </div>
        </form>
      </Form>
    </Box>
  )
}
