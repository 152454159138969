import type React from 'react'
import { useForm } from 'react-hook-form'
import { useOfficerStore } from 'stores/useOfficerStore'
import type z from 'zod'
import { object, string, type TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input
} from 'components/ui'
import type { InventoryTransfer } from '../../types'
import { useTransferInventory } from '../../hooks/useTransferInventory'
import { useAuthenticationStore } from '../../../../../stores/useAuthenticationStore'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { useMerchantStore } from '../../../../../stores/useMerchantStore'

const rejectSchema = object({
  reason: string().min(1, 'Reason for rejection is required')
})

type RejectInput = TypeOf<typeof rejectSchema>

interface RejectTransferProps {
  transferRequest?: InventoryTransfer
  onClose: () => void
}

export const RejectTransfer: React.FC<RejectTransferProps> = ({ transferRequest, onClose }) => {
  const form = useForm<z.infer<typeof rejectSchema>>({
    resolver: zodResolver(rejectSchema),
    defaultValues: {
      reason: ''
    }
  })
  const { role } = useAuthenticationStore()
  const { farmer } = useFarmerStore()
  const { merchant } = useMerchantStore()
  const { rejectTransferRequest, verifyTransferRequest, isLoading } =
    useTransferInventory(transferRequest)
  const { officer } = useOfficerStore()
  const onSubmit = (data: RejectInput): void => {
    const payload = {
      inventory_id: Number(transferRequest?.inventory_id),

      ...(transferRequest?.origin_merchant_id != null && {
        origin_merchant_id: Number(transferRequest.origin_merchant_id)
      }),
      ...(transferRequest?.dest_merchant_id != null && {
        dest_merchant_id: Number(transferRequest.dest_merchant_id)
      }),
      ...(transferRequest?.dest_farmer_id != null && {
        dest_farmer_id: Number(transferRequest.dest_farmer_id)
      }),
      ...(transferRequest?.origin_farmer_id != null && {
        origin_farmer_id: Number(transferRequest.origin_farmer_id)
      })
    }
    if (role === 1010) {
      verifyTransferRequest(
        {
          ...payload,
          last_update_by: Number(officer?.user_id),
          transaction_status: 5,
          message: 'Transfer Rejected by MO'
        },

        onClose
      )
    } else {
      rejectTransferRequest(
        {
          ...payload,
          last_update_by: merchant != null ? merchant.user_id : farmer?.user_id,
          transaction_status: 3,
          message: data.reason
        },
        onClose
      )
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name='reason'
          render={({ field }) => (
            <FormItem className='flex flex-col items-start space-x-3 space-y-0 p-4 gap-4'>
              <FormLabel>Rejection Reason</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className={'flex flex-row justify-between gap-4'}>
          <Button variant={'destructive'} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant={'success'}
            type={'submit'}
            onClick={form.handleSubmit(onSubmit)}
            size={'xs'}
            disabled={isLoading}
            loading={isLoading}
          >
            Reject
          </Button>
        </div>
      </form>
    </Form>
  )
}
