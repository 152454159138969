import type { ApiResponseWithObject } from '../types'
import { axios } from '../lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { type Agrochemicals, type CroppingPlanStaticData } from '../types/static'
import type { StaticInvData } from '../features/farmer/inventory/types'

export const getCroppingPlanData = async (): Promise<
  ApiResponseWithObject<CroppingPlanStaticData>
> => {
  return axios.get(`/staticdata/`)
}

export const getAgrochemicalData = async (): Promise<ApiResponseWithObject<Agrochemicals>> => {
  return axios.get(`/staticdata/agrochemicals`)
}

export const getStaticInventory = async (): Promise<ApiResponseWithObject<StaticInvData>> => {
  return axios.get(`/staticdata/inventory`)
}

export const useGetStaticInventory = (
  enabled: boolean
): UseQueryResult<ApiResponseWithObject<StaticInvData>, AxiosError> => {
  return useQuery({
    queryKey: ['staticInventoryData'],
    queryFn: async () => getStaticInventory(),
    enabled
  })
}

export const useAgrochemicalData = (
  enabled: boolean
): UseQueryResult<ApiResponseWithObject<Agrochemicals>, AxiosError> => {
  return useQuery({
    queryKey: ['agrochemical-static-data'],
    queryFn: async () => getAgrochemicalData(),
    enabled
  })
}

export const useGetCroppingPlanData = (
  enabled: boolean
): UseQueryResult<ApiResponseWithObject<CroppingPlanStaticData>, AxiosError> => {
  return useQuery({
    queryKey: ['cropping-plan-static-data'],
    queryFn: async () => getCroppingPlanData(),
    enabled
  })
}
