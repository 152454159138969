import { useStepper } from 'components/ui/stepper'
import { Button } from 'components/ui'
import type React from 'react'
import { useTranslation } from 'react-i18next'
import { useMedia } from 'react-use'

interface StepperFooterProps {
  isLoading?: boolean
  form?: string
  isFirstStep?: boolean
  notComplete?: boolean
  disabled?: boolean
  cancel?: () => void
  back?: () => void
  submit?: () => void
}
export const StepperFooter: React.FC<StepperFooterProps> = ({
  isLoading = false,
  form,
  isFirstStep = false,
  notComplete = false,
  disabled = false,
  cancel,
  back,
  submit
}) => {
  const isDesktop = useMedia('(min-width: 900px)')
  const { prevStep, nextStep, isLastStep, isDisabledStep, isOptionalStep, currentStep } =
    useStepper()
  const { t } = useTranslation('croppingPlan')

  const handleClickSkip = (): void => {
    nextStep()
  }

  const handleClickPrev = (): void => {
    if (isFirstStep) {
      if (cancel != null) {
        cancel()
      }
    } else if (back != null) {
      back()
    } else {
      prevStep()
    }
  }

  return (
    <div className='w-full flex justify-end gap-2'>
      {isOptionalStep ? (
        <Button
          onPointerEnter={isDesktop ? undefined : handleClickSkip}
          disabled={isLoading}
          size={'sm'}
          variant={'outlined'}
          onClick={handleClickSkip}
        >
          {t('footer.skip')}
        </Button>
      ) : null}
      <Button
        disabled={isLoading || (isDisabledStep && !isFirstStep)}
        onClick={handleClickPrev}
        size={'sm'}
        variant={'secondary'}
      >
        {isFirstStep ? `${t('footer.cancel')}` : `${t('footer.prev')}`}
      </Button>
      <Button
        disabled={isLoading || notComplete || disabled}
        loading={isLoading}
        onClick={isLastStep ? nextStep : submit != null ? submit : undefined}
        size={'sm'}
        variant={'primary'}
        type={'submit'}
        form={form}
      >
        {isLastStep ? `${t('footer.finish')}` : `${t('footer.next')}`}
      </Button>
    </div>
  )
}
