import type React from 'react'
import TreeIcon from '../../../../../../assets/landing/icons/tree.svg'
import CropInsurancePackageData from '../Data/ProgramPackages/CropInsurancePackageData'
import { useTranslation } from 'react-i18next'

/**
 * Interface representing the coverage details.
 */
interface Coverage {
  title: string
  description: string
}

/**
 * Interface representing the benefit details.
 */
interface Benefit {
  title: string
  description: string
}

/**
 * Interface representing the structure of the Crop Insurance Program Packages.
 */
interface ProgramPackages {
  programName: string
  coverage: Coverage[]
  farmEquipmentInsurance: string
  benefits: Benefit[]
  eligibility: string
}

/**
 * React functional component to display the Crop Insurance Program details.
 *
 * @returns {JSX.Element} The JSX code to render the Crop Insurance Program details.
 */
export const CropInsurance: React.FC = () => {
  const { t } = useTranslation('landing')
  const { programName, coverage, farmEquipmentInsurance, benefits, eligibility } =
    CropInsurancePackageData(t) as ProgramPackages

  return (
    <div className='w-full mt-16'>
      {/* Coverage */}
      <div className='flex flex-col justify-center items-start my-8'>
        <h2 className='text-base md:text-xl lg:text-3xl font-bold text-gray-700 landing-page '>
          {t('ci.cov')}:
        </h2>
        <ul className='mt-4'>
          {coverage.map((item: Coverage, index: number) => (
            <li key={index} className='text-base md:text-xl lg:text-2xl text-gray-600 my-6'>
              <div className='flex flex-row items-start'>
                <img src={TreeIcon} alt='Tree Icon' className='w-6 h-6 mr-5' />
                <div className='flex flex-col'>
                  <h4 className='text-base md:text-xl lg:text-2xl font-bold text-gray-700 mb-1 landing-page'>
                    {item.title}:
                  </h4>
                  <p className='text-base md:text-xl lg:text-2xl text-gray-600 landing-page'>
                    {item.description}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {/* Farm Equipment Insurance */}
      <div className='flex flex-col justify-center items-start my-8'>
        <h2 className='text-base md:text-xl lg:text-3xl font-bold text-gray-700 landing-page '>
          {t('ci.fei')}:
        </h2>
        <p className='text-base md:text-xl lg:text-2xl text-gray-600 landing-page'>
          {farmEquipmentInsurance}
        </p>
      </div>
      {/* Benefits */}
      <div className='flex flex-col justify-center items-start my-8'>
        <h2 className='text-base md:text-xl lg:text-3xl font-bold text-gray-700 landing-page '>
          {t('ci.ben')}:
        </h2>
        <ul className='mt-4'>
          {benefits.map((benefit: Benefit, index: number) => (
            <li key={index} className='text-base md:text-xl lg:text-2xl text-gray-600 my-6'>
              <div className='flex flex-row items-start'>
                <img src={TreeIcon} alt='Tree Icon' className='w-6 h-6 mr-5' />
                <div className='flex flex-col'>
                  <h4 className='text-base md:text-xl lg:text-2xl font-bold text-gray-700 mb-1 landing-page'>
                    {benefit.title}:
                  </h4>
                  <p className='text-base md:text-xl lg:text-2xl text-gray-600 landing-page'>
                    {benefit.description}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      {/* Eligibility */}
      <div className='flex flex-col justify-center items-start my-8'>
        <h2 className='text-base md:text-xl lg:text-3xl font-bold text-gray-700 landing-page '>
          {t('ci.elig')}:
        </h2>
        <p className='text-base md:text-xl lg:text-2xl text-gray-600 landing-page'>{eligibility}</p>
      </div>
    </div>
  )
}