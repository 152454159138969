import { Mechanization } from 'components/Auth/Mechanization'
import { Navigate, useParams } from 'react-router-dom'
import { OfficerProfile } from '../features/profile/routes/Officer'
import { InventoriesMO } from '../features/mechanisationOfficer/inventory/routes/InventoriesMO'
import { MOPendingInventories } from '../features/mechanisationOfficer/inventory/routes/MOPendingInventories'
import { Checklist } from '../features/mechanisationOfficer/inventory/components/checklist/Checklist'
import { ModifyInventoryMerchant } from '../features/merchant/service-provider/inventory/routes/ModifyInventoryMerchant'
import { ModifyInventory } from '../features/farmer/inventory/components/ModifyInventory'
import { type ReactElement } from 'react'
import { ViewHistories } from '../features/farmer/inventory/components/History/ViewHistories'
import { ViewInventories } from '../features/mechanisationOfficer/inventory/routes/ViewInventories'
import { TransferRequests } from '../features/mechanisationOfficer/inventory/routes/TransferRequests'
import { ChangePassword } from '../components/Change-Password/ChangePassword'
import { PasswordProtectedRoute } from 'components/Change-Password/PasswordProtectedRoute'

export const ModifyInventoryWrapper = (): ReactElement => {
  const { userType } = useParams<{ userType: string }>()

  if (userType === 'farmer') {
    return <ModifyInventory />
  } else if (userType === 'merchant') {
    return <ModifyInventoryMerchant />
  } else {
    return <Navigate to='/inventory' />
  }
}

export const inventoryRoutes = {
  path: '/inventory',
  element: <Mechanization />,
  children: [
    {
      path: '/inventory/view-inventory/:userType/:inventoryId',
      element: <ModifyInventoryWrapper />
    },
    { path: '/inventory', element: <PasswordProtectedRoute children={<InventoriesMO />} /> },
    {
      path: '/inventory/view/:userType/:Id',
      element: <PasswordProtectedRoute children={<ViewInventories />} />
    },
    {
      path: '/inventory/:idType/:idInput',
      element: <PasswordProtectedRoute children={<MOPendingInventories />} />
    },
    {
      path: '/inventory/transfers/:idType/:idInput',
      element: <PasswordProtectedRoute children={<TransferRequests />} />
    },
    {
      path: '/inventory/view-inventory/:userType/:inventoryId',
      element: <PasswordProtectedRoute children={<ModifyInventoryWrapper />} />
    },
    {
      path: '/inventory/assessment/:inventoryId',
      element: <PasswordProtectedRoute children={<Checklist />} />
    },
    { path: '/inventory/change-password', element: <ChangePassword /> },
    {
      path: '/inventory/history/:inventoryId',
      element: <PasswordProtectedRoute children={<ViewHistories />} />
    },
    {
      path: '/inventory/profile',
      element: <PasswordProtectedRoute children={<OfficerProfile />} />
    },
    { path: '*', element: <PasswordProtectedRoute children={<Navigate to={'.'} />} /> }
  ]
}
