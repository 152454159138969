interface MmokoRewardPackageThree {
  description: string
}

interface ProgramPackagesThree {
  MmokoRewardPackagesThree: MmokoRewardPackageThree[]
}

const programPackagesThree = (t: (key: string) => string): ProgramPackagesThree => {
  return {
    MmokoRewardPackagesThree: [
      {
        description: t('mrp.mrp31')
      },
      {
        description: t('mrp.mrp32')
      },
      {
        description: t('mrp.mrp33')
      },
      {
        description: t('mrp.mrp34')
      },
      {
        description: t('mrp.mrp35')
      }
    ]
  }
}

export default programPackagesThree