import { CustomCheckBoxGroup } from 'features/loan-management/components/generic/form'
import type React from 'react'
import { useState } from 'react'
import { Box } from '../../../../../components/App/Layout'
import { Separator, Text, useStepper } from '../../../../../components/ui'
import { Info } from 'lucide-react'
import { type LoanStepperProps } from '../../../types'
import { LoanStepperFooter } from '../../generic/LoanStepperFooter'
import { useTranslation } from 'react-i18next'

export const Attachments: React.FC<LoanStepperProps> = ({ onComplete }) => {
  const { t } = useTranslation('loanManagement')
  const { nextStep } = useStepper()
  const listOfAttachments = [
    `${t('att.grain')}`,
    `${t('att.omang')}`,
    `${t('att.permit')}`,
    `${t('att.marriage')}`,
    `${t('att.tax')}`,
    `${t('att.soil')}`,
    `${t('att.moa')}`,
    `${t('att.loan')}`,
    `${t('att.address')}`,
    `${t('att.passport')}`,
    `${t('att.regime')}`,
    `${t('att.spouse')}`,
    `${t('att.syn')}`,
    `${t('att.shares')}`
  ]

  const [checkedCheckboxes, setCheckedCheckboxes] = useState<boolean[]>(
    new Array(listOfAttachments.length).fill(false)
  )
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false)

  const handleCheckboxChange = (index: number): void => {
    const updatedCheckboxes = [...checkedCheckboxes]
    updatedCheckboxes[index] = !updatedCheckboxes[index]
    setCheckedCheckboxes(updatedCheckboxes)

    setIsSelectAllChecked(updatedCheckboxes.every((isChecked) => isChecked))
  }

  const handleSelectAllChange = (): void => {
    const newState = !isSelectAllChecked
    setIsSelectAllChecked(newState)
    setCheckedCheckboxes(new Array(listOfAttachments.length).fill(newState))
  }

  const isContinueButtonDisabled = !checkedCheckboxes.every((isChecked: boolean) => isChecked)

  const onSubmit = (): void => {
    nextStep()
    if (onComplete != null) {
      onComplete(true)
    }
  }

  return (
    <Box>
      <div className='my-6'>
        <Text size='medium' className=''>
          {t('att.follow')}:
        </Text>

        <div className='flex my-2'>
          <Info size='18' color='orange' className='mr-2.5' />
          <Text size='small' variant='secondary' className=''>
            {t('att.tick')}
          </Text>
        </div>

        <div className={'w-full flex flex-col gap-2 mt-8'}>
          <CustomCheckBoxGroup
            labels={listOfAttachments}
            checkedCheckboxes={checkedCheckboxes}
            onCheckboxChange={handleCheckboxChange}
          />
          <Separator />
          <CustomCheckBoxGroup
            labels={[t('att.selectAll')]}
            checkedCheckboxes={[isSelectAllChecked]}
            onCheckboxChange={handleSelectAllChange}
          />
        </div>
        <LoanStepperFooter disabled={isContinueButtonDisabled} submit={onSubmit} />
      </div>
    </Box>
  )
}
