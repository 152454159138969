import { type ColumnDef } from '@tanstack/react-table'
import { type Grant } from '../../../../../types'
import { DataTableColumnHeader } from 'components/Data-Table/datatable-header'

export const columns = (t: (key: string) => string): Array<ColumnDef<Grant>> => [
  {
    accessorKey: 'farmer_id',
    filterFn: 'includesString',
    enableHiding: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('cp.fid')} />,
    cell: ({ row }) => <div className='lowercase'>{row.getValue('farmer_id')}</div>
  },
  {
    id: 'firstname',
    accessorFn: (row) => {
      const originFarmerFirstName = row.firstname ?? ''
      const originFarmerSurname = row?.surname ?? ''

      return `${originFarmerFirstName} ${originFarmerSurname}`.trim()
    },
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => {
      const displayName = row.getValue<string>('firstname')
      return <div className='uppercase'>{displayName}</div>
    },
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('grant.from')} />
  },
  {
    accessorKey: 'contact',
    header: t('grant.contact')
  },
  {
    accessorKey: 'available_size',
    enableHiding: false,
    header: t('cp.ha')
  },
  {
    accessorKey: 'total_costs',
    enableHiding: false,
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('cp.tCost')} />,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue('total_costs'))
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'BWP'
      }).format(amount)

      return <div className='font-medium'>{formatted}</div>
    }
  }
]