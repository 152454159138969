// programPackages.ts

interface Subsidy {
  title: string
  description: string
}

interface EligibilityCriteria {
  description: string
}

interface SupplementaryIrrigation {
  subsidy: Subsidy[]
  eligibilityCriteria: EligibilityCriteria[]
}

const supplementaryIrrigationEnhancer = (t: (key: string) => string): SupplementaryIrrigation => {
  return {
    subsidy: [
      {
        title: t('tle.subTitle1'),
        description: t('tle.subDesc1')
      },
      {
        title: t('tle.subTitle2'),
        description: t('tle.subDesc2')
      },
      {
        title: t('tle.subTitle3'),
        description: t('tle.subDesc3')
      }
    ],

    eligibilityCriteria: [
      {
        description: t('tle.subCrit1')
      },
      {
        description: t('tle.subCrit2')
      },
      {
        description: t('tle.subCrit3')
      }
    ]
  }
}

export default supplementaryIrrigationEnhancer