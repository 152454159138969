import { type ColumnDef } from '@tanstack/react-table'
import { type GetInventoryRes } from 'features/farmer/inventory/types'
import { Text } from '../../../../../components/ui'
import { groupBy } from 'lodash'

export const viewInventoryColumns = (
  data: GetInventoryRes[],
  t: (key: string) => string
): Array<ColumnDef<GetInventoryRes>> => {
  const inventoryCounts: Record<string | number, GetInventoryRes[]> = groupBy(
    data.filter((item) => item.item_verification_status !== 'PENDING_VERIFICATION'),
    (row) => (row.farmer_id != null ? row.farmer_id : row.merchant_id ?? 'unknown')
  )

  return [
    {
      id: 'farmer_name',
      accessorFn: (row) => {
        if (row?.merchant_id != null) {
          return row.merchant_name
        } else if (row.farmer_type === 2) {
          return row.company_name
        } else {
          return row.farmer_name
        }
      },
      cell: ({ row }) => <Text variant={'colorText'}>{row.getValue('farmer_name')}</Text>,
      header: t('mech.on')
    },
    {
      id: 'farmer_contact',
      enableHiding: true,
      accessorFn: (row) =>
        row?.farmer_id != null ? row.farmer_contact ?? 'N/A' : row.merchant_contact ?? 'N/A',
      cell: ({ row }) => <Text variant={'colorText'}>{row.getValue('farmer_contact')}</Text>,
      header: t('mech.cn')
    },
    {
      id: 'inventory_count',
      accessorFn: (row) => {
        const id = row.farmer_id != null ? row.farmer_id : row.merchant_id
        if (id == null) {
          return 0
        }
        const inventory = inventoryCounts[id] ?? []
        return Array.isArray(inventory) ? inventory.length : 0
      },
      cell: ({ row }) => <Text variant={'colorText'}>{row.getValue('inventory_count')}</Text>,
      header: t('mech.ti')
    }
  ]
}
