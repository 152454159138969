// farmServicesData.ts

export interface TemoBenefits {
  id: string
  title: string
  benefitOne: string
  benefitTwo: string
  benefiThree: string
  backgroundColor: string
  fontColor: string
  gradientFrom: string
  gradientTo: string
}

export const temoBenefits = (t: (key: string) => string): TemoBenefits[] => [
  {
    id: 'government-backed-financial-support',
    title: t('home.govt'),
    benefitOne: t('ben.govt1'),
    benefitTwo: t('ben.govt2'),
    benefiThree: t('ben.govt3'),
    backgroundColor: 'bg-green-100',
    fontColor: 'text-gray-600',
    gradientFrom: 'green-100',
    gradientTo: 'green-200'
  },
  {
    id: 'inclusive-and-easy-to-use-platform',
    title: t('home.easy'),
    benefitOne: t('ben.easy1'),
    benefitTwo: t('ben.easy2'),
    benefiThree: t('ben.easy3'),
    backgroundColor: 'bg-green-200',
    fontColor: 'text-gray-600',
    gradientFrom: 'green-200',
    gradientTo: 'green-300'
  },
  {
    id: 'sustainable-growth-and-innovation',
    title: t('home.sus'),
    benefitOne: t('ben.sus1'),
    benefitTwo: t('ben.sus2'),
    benefiThree: t('ben.sus3'),
    backgroundColor: 'bg-green-300',
    fontColor: 'text-gray-600',
    gradientFrom: 'green-300',
    gradientTo: 'green-400'
  }
]