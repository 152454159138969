import type React from 'react'
import { Card, Text } from 'components/ui'
import { servicesColumns } from '../components/columns'
import { useSearchParams } from 'react-router-dom'
import { Loader } from 'components/ui/loader'
import { NotEligible } from '../../components/NotEligible'
import { NotFound } from 'components/Errors/NotFound'
import { ErrorPage } from 'components/Errors/ErrorPage'
import { useGetVoucherEligibility } from '../../api/getVoucherEligibility'
import { ServicesTable } from '../components/ServicesTable'
import { useStaticDataStore } from '../../../../stores/useStaticDataStore'
import { useTranslation } from 'react-i18next'
import { usePloughSeason } from '../../../../hooks/usePloughSeason'
import {
  type ServiceProviderFilter,
  type ServiceProviderGrantCroppingPlanData
} from '../../../../types'

export const ServicesVoucherRedemption: React.FC = () => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation('merchant')
  const query = searchParams.get('omang')
  const { data, isLoading, error } = useGetVoucherEligibility(query)
  const { services } = useStaticDataStore()
  const { isCurrentSeason, isLoading: isPloughLoading } = usePloughSeason()

  if (query == null) {
    return <NotFound />
  }

  if (isLoading || isPloughLoading) {
    return (
      <div className={'grid place-items-center min-h-[30%] h-full flex-1'}>
        <Loader />
      </div>
    )
  }

  if (error != null) {
    return <ErrorPage />
  }

  if (data?.data.exists === false) {
    return <NotEligible />
  }

  const servicesToShow: ServiceProviderFilter[] = (
    data?.data.grant_cropping_plans_data as ServiceProviderGrantCroppingPlanData[]
  ).filter((service) => {
    return {
      tillage_method: service.tillage_method,
      is_harrowing: service.is_harrowing,
      chemical_weed_control: service.chemical_weed_control,
      manual_weed_control: service.manual_weed_control,
      pest_control: service.pest_control
    }
  })

  const currentServices =
    services?.filter((service) => isCurrentSeason(service.plough_season) && service.is_active) ?? []

  const filteredCurrentServices = currentServices.filter((service) => {
    const matchingCriteria = servicesToShow.find((criteria) => {
      switch (service.service_type) {
        case 'Land Preparation':
          return (
            (criteria.is_harrowing && service.service_name === 'Harrowing') ||
            (criteria.tillage_method === 'Conventional' && service.service_name === 'Ploughing') ||
            (criteria.tillage_method === 'No Till' && service.service_name === 'No Till') ||
            (criteria.tillage_method === 'Minimum' && service.service_name === 'Minimum Tillage') ||
            service.service_name === 'Planting'
          )

        case 'Weed Control-Application Method':
          return (
            (criteria.chemical_weed_control &&
              ['Weed control - Boom sprayer', 'Weed control - Drone'].includes(
                service.service_name
              )) ||
            (criteria.manual_weed_control && service.service_name === 'Weed control - Machinery')
          )

        case 'Pest Control -Application Method':
          return criteria.pest_control && service.service_name !== 'Pest control- Manual'

        default:
          return false
      }
    })

    return Boolean(matchingCriteria)
  })

  return (
    <div
      className={
        'md:max-w-[800px] lg:max-w-[1200px] xl:max-w-[1700px] w-full flex-col gap-4  flex justify-center mx-auto px-0 py-4'
      }
    >
      <Text size={'large'}>{t('services.svr')}</Text>
      <Card
        className={
          'w-full flex flex-col bg-card gap-4 md:gap-6 lg:gap-8 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
        }
      >
        <Text size={'medium'} variant={'bodyTextLight'}>
          {t('services.sList')}
        </Text>
        <Text size={'body'} variant={'bodyTextLight'}>
          {t('services.app')} {data?.data.total_hectarage} hectares.
        </Text>
        <ServicesTable columns={servicesColumns(t)} data={filteredCurrentServices} />
      </Card>
    </div>
  )
}
