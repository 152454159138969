import type React from 'react'
import { Separator, Text } from '../../../../../../components/ui'
import { useTranslation } from 'react-i18next'
import { type CroppingPlanDetails } from '../../../../../../types'
import { useStaticDataStore } from '../../../../../../stores/useStaticDataStore'

interface PestControlReviewProps {
  cp: CroppingPlanDetails
}

export const PestControlReview: React.FC<PestControlReviewProps> = ({ cp }) => {
  const { pesticides, fungicides, adjuvants } = useStaticDataStore()
  const { t } = useTranslation('croppingPlan')

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'BWP'
  })

  return (
    <div className='grid gap-4 w-full'>
      <div>
        <div className='flex flex-col gap-2 w-full'>
          <Text size='large' variant='secondary'>
            {t('review.pest')}
          </Text>

          <div className='grid grid-cols-2 gap-4 mt-4'>
            <div className='flex flex-col'>
              <Text size='mediumLight' variant='secondary'>
                {t('review.pestMethod')}
              </Text>
              <Text size='medium' variant="secondary">
                {cp.pest_control_type}</Text>
            </div>

            <div className='flex flex-col'>
              <Text size='mediumLight' variant='secondary'>
                {t('review.appMethod')}
              </Text>
              <Text size='medium' variant="secondary">
                {cp.pest_control_application}s</Text>
            </div>

            <div className='flex flex-col'>
              <Text size='mediumLight' variant='secondary'>
                Total Application Cost
              </Text>
              <Text size='medium' variant="secondary">
              {currencyFormatter.format(cp.pest_disease_application_cost)}
              </Text>
            </div>

            <div className='flex flex-col'>
              <Text size='mediumLight' variant='secondary'>
                Total Chemical Cost
              </Text>
              <Text size='medium' variant="secondary">
              {currencyFormatter.format(cp.pest_disease_control_chemical_cost)}
              </Text>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className='flex flex-col gap-2 w-full'>
          <Text size='medium' variant="secondary">
          Selected Chemicals
          </Text>

          <div className='flex flex-col gap-2 mt-4'>
            {cp.pest_disease_control?.map((item) => (
              <ul key={item.chemical_price_detail_id} className='list-disc flex items-center gap-2'>
                <li>
                  <Text size='medium' variant='secondary'>
                    {item.chemical_type === 'pesticide'
                      ? pesticides?.find(
                          (pesticide) =>
                            pesticide?.pesticides_price_detail_id ===
                              item.chemical_price_detail_id && pesticide?.pest_id === item.pest_id
                        )?.pesticide_name ?? null
                      : fungicides?.find(
                          (fungicide) =>
                            fungicide?.fungicide_price_detail_id ===
                              item.chemical_price_detail_id &&
                            fungicide?.disease_id === item.pest_id
                        )?.fungicide_name ?? null}{' '}
                    |{' '}
                    {item.chemical_type === 'pesticide'
                      ? pesticides?.find(
                          (pesticide) =>
                            pesticide?.pesticides_price_detail_id ===
                              item.chemical_price_detail_id && pesticide?.pest_id === item.pest_id
                        )?.active_ingredient ?? null
                      : fungicides?.find(
                          (fungicide) =>
                            fungicide?.fungicide_price_detail_id ===
                              item.chemical_price_detail_id &&
                            fungicide?.disease_id === item.pest_id
                        )?.active_ingredient ?? null}
                  </Text>
                </li>
              </ul>
            ))}

            {cp.pest_disease_control?.map((item) => {
              if (item.adjuvant_reg_no == null) {
                return null
              }
              return (
                <ul
                  key={item.adjuvant_price_detail_id}
                  className='list-disc flex items-center gap-2'
                >
                  <li>
                    <Text size='medium' variant='secondary'>
                      {adjuvants?.find(
                        (adjuvant) =>
                          adjuvant?.adjuvant_price_detail_id === item?.adjuvant_price_detail_id
                      )?.adjuvant_name ?? null}{' '}
                      | (Adjuvant)
                    </Text>
                  </li>
                </ul>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
