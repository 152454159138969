import type React from 'react'
import { type Lease } from 'types'
import { Button, Text } from 'components/ui'
import { useTerminateLease } from 'features/farmer/hooks/useTerminateLease'
import { useTranslation } from 'react-i18next'

interface DeleteLeaseConfirmationProps {
  lease: Lease
  isLessor?: boolean
  next: () => void
}

export const AcceptTermination: React.FC<DeleteLeaseConfirmationProps> = ({
  lease,
  next,
  isLessor = false
}) => {
  const { lesseeAcceptTermination, lessorAcceptTermination, isLoading } = useTerminateLease(next)
  const { t } = useTranslation('leaseManagement')

  const onAcceptTermination = (): void => {
    if (isLessor) {
      lessorAcceptTermination(lease)
    } else {
      lesseeAcceptTermination(lease)
    }
  }

  return (
    <div className={'w-full flex flex-col gap-4'}>
      <Text className={'text-center'} size={'large'} variant={'colorText'}>
        {t('ren.acc')}
      </Text>

      <Text className={'text-center'} size={'medium'} variant={'bodyTextLight'}>
        {t('ren.endWith')}{' '}
        <strong>
          {isLessor
            ? lease.dest_farmer_type === 2
              ? lease.dest_farmer_company_name
              : lease.dest_farmer_name
            : lease.origin_farmer_type === 2
            ? lease.origin_farmer_company_name
            : lease.origin_farmer_name}
        </strong>
        ? {t('ren.means')}
      </Text>
      <Text variant={'error'} className={'text-center font-bold uppercase'} size={'medium'}>
        {t('ren.note')}
      </Text>
      <div className={'w-full flex justify-between gap-4 md:gap-8'}>
        <Button variant={'secondary'} disabled={isLoading} className={'w-1/2'} onClick={next}>
          {t('ren.cancel')}
        </Button>
        <Button
          className={'w-1/2'}
          variant={'destructive'}
          disabled={isLoading}
          loading={isLoading}
          onClick={onAcceptTermination}
        >
          {t('ren.term')}
        </Button>
      </div>
    </div>
  )
}
