import { ErrorPage } from 'components/Errors/ErrorPage'
import { useGetRequests } from 'features/lab/ssr/api/getRequests'
import type React from 'react'
import { useState } from 'react'
import { useMerchantStore } from 'stores/useMerchantStore'
import { Button, Card, Dialog, DialogContent, Loader, Text } from 'components/ui'
import { SearchRequests } from './SearchRequests'
import { ssrColumns } from '../components/table/column'
import { Box, TabView } from 'components/App/Layout'
import { SSRTable } from '../components/table/ssrTable'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs'
import { uniqBy } from 'lodash'

export const SoilSampleRequests: React.FC = () => {
  const [newRequest, setNewRequest] = useState(false)
  const { merchant } = useMerchantStore()
  const { data: requests, isLoading, error } = useGetRequests(merchant?.merchant_id)

  const handleCloseNewRequest = (): void => {
    setNewRequest(false)
  }

  const handleOpenNewRequest = (): void => {
    setNewRequest(true)
  }

  if (isLoading) {
    return (
      <div className={'grid place-items-center min-h-[30%] h-full flex-1'}>
        <Loader />
      </div>
    )
  }

  if (error != null && error.response?.status !== 404) {
    return <ErrorPage />
  }

  if (requests?.data?.length == null) {
    return (
      <Box>
        <Text size={'large'} className={'ml-8'}>
          My Soil Sample Analyses
        </Text>
        <div
          className={
            'w-full flex flex-col justify-center items-center bg-white gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
          }
        >
          <Text size={'medium'} variant={'bodyTextLight'}>
            No Soil Sample Analyses found
          </Text>
        </div>
      </Box>
    )
  }

  return (
    <Box>
      <TabView>
        <Tabs defaultValue='SSR' className='flex flex-col gap-4 w-full'>
          <div
            className={'flex flex-col gap-2 sm:flex-row sm:justify-between sm:items-center w-full'}
          >
            <TabsList className={'grid w-full sm:w-2/3 md:w-1/2 lg:w-2/5 grid-cols-2'}>
              <TabsTrigger value='SSR'>Soil Sample Requests</TabsTrigger>
              <TabsTrigger value='SSA'>Soil Sample Analysis</TabsTrigger>
            </TabsList>
            <Button size={'xs'} variant={'success'} onClick={handleOpenNewRequest}>
              Get SSR
              <p className={'fa-solid fa-add'} />
            </Button>
          </div>

          <div>
            <TabsContent value='SSR'>
              <Card
                className={
                  'w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
                }
              >
                <SSRTable
                  columns={ssrColumns}
                  data={uniqBy(requests.data, 'ssr_id').filter(
                    (item) => item.status_name === 'IN PROCESS' || item.status_name === 'REJECTED'
                  )}
                  SSA={false}
                />
              </Card>
            </TabsContent>
            <TabsContent value='SSA'>
              <Card
                className={
                  'w-full flex flex-col bg-card gap-4 md:gap-8 lg:gap-12 rounded-md lg:rounded-xl p-4 py-8 sm:px-4 md:px-8'
                }
              >
                <SSRTable
                  columns={ssrColumns}
                  data={uniqBy(requests.data, 'ssr_id').filter(
                    (item) => item.status_name === 'COMPLETED'
                  )}
                  SSA={true}
                />
              </Card>
            </TabsContent>
          </div>
        </Tabs>

        <Dialog open={newRequest} defaultOpen={false} modal onOpenChange={handleCloseNewRequest}>
          <DialogContent className={'bg-card w-full md:w-[50%] lg:w-[35%] max-w-[430px]'}>
            <Text>Search for New Soil Sample Requests</Text>
            <SearchRequests />
          </DialogContent>
        </Dialog>
      </TabView>
    </Box>
  )
}
