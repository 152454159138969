import type { ColumnDef } from '@tanstack/react-table'
import { DataTableColumnHeader } from '../../../../components/Data-Table/datatable-header'
import { Checkbox } from '../../../../components/ui/checkbox'
import { type Services } from '../../../../types/static'
import { convertApplicationMethod } from 'utils/utilFunctions'

export const servicesColumns = (t: (key: string) => string): Array<ColumnDef<Services>> => [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate')
        }
        onCheckedChange={(value) => {
          table.toggleAllPageRowsSelected(!!value)
        }}
        aria-label='Select all'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => {
          row.toggleSelected(!!value)
        }}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false
  },
  {
    id: 'service name',
    accessorFn: (row) => convertApplicationMethod(row.service_name),
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('service name')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('services.sName')} />
  },
  {
    id: 'service type',
    accessorFn: (row) => row.service_type,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('service type')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('services.sType')} />
  },
  {
    id: 'season',
    accessorFn: (row) => row.plough_season,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('season')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title={t('services.plough')} />
  },
  {
    id: 'unit',
    accessorFn: (row) => row.unit_of_measure,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div>{row.getValue('unit')}</div>,
    header: t('services.unit')
  },
  {
    id: 'price',
    accessorFn: (row) => row.price,
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => {
      const amountString = String(row.getValue('price')).replace(/,/g, '')
      const amount = parseFloat(amountString)
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'BWP'
      }).format(amount)

      return <div className='font-medium uppercase'>{formatted}</div>
    },
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={`${t('services.price2')} (BWP)/${t('services.unit2')}`}
      />
    )
  }
]
