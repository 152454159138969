import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { axios } from 'lib/axios'
import { type ApiResponse, type Officer } from 'types'

export const getOfficer = async (
  userId: number | string | null | undefined,
  userRole: number | null | undefined
): Promise<ApiResponse<Officer>> => {
  return axios.get(`/officer?USER_ROLE=${userRole ?? ''}&USER_ID=${userId ?? ''}`)
}

export const useGetOfficer = (
  userId: number | string | null | undefined,
  role: number | null | undefined
): UseQueryResult<ApiResponse<Officer>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['officer', userId, role],
    queryFn: async () => getOfficer(userId, role),
    enabled: userId !== null && role !== null && (role === 1001 || role === 1010)
  })
}
