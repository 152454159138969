import React from 'react'
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from 'components/ui'
import { type SeedTransactionDetails } from '../../types'

import { useTranslation } from 'react-i18next'
import { useStaticDataStore } from '../../../../../stores/useStaticDataStore'

interface SeedsTableProps {
  seeds: SeedTransactionDetails[]
}

export const SeedsTable: React.FC<SeedsTableProps> = ({ seeds }) => {
  const { t } = useTranslation('merchant')
  const { seeds: SeedsList, crops: CropsList } = useStaticDataStore()

  const getCropName = (seedId: number): string => {
    const findSeed = SeedsList?.find((seed) => seed.seed_id === seedId)
    const cropId = findSeed != null ? findSeed.crop_id : 0
    const cropName = CropsList?.find((crop) => crop.crop_id === cropId)
    return cropName != null ? cropName.crop_name : ''
  }
  const getSeedVariety = (seedId: number): string => {
    const findSeed = SeedsList?.find((seed) => seed.seed_id === seedId)
    return findSeed != null ? findSeed.variety : ''
  }
  return (
    <Table>
      <TableCaption>{t('seed.td')}</TableCaption>
      <TableHeader>
        <TableRow>
          <TableHead className='font-bold text-color-text'>{t('seed.crop')}</TableHead>
          <TableHead className='font-bold text-color-text'>{t('seed.variety')}</TableHead>
          <TableHead className='font-bold text-color-text'>{t('seed.quan')}</TableHead>
          <TableHead className='font-bold text-color-text'>{t('seed.price')}</TableHead>
          <TableHead className='font-bold text-color-text'>{t('seed.cost')}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {seeds.map((seed) => (
          <TableRow key={React.useId()} role={'listitem'}>
            <TableCell className='font-medium'>{getCropName(seed.id)}</TableCell>
            <TableCell className='font-medium'>{getSeedVariety(seed.id)}</TableCell>
            <TableCell className='font-medium'>{seed.qty}</TableCell>
            <TableCell className='font-medium'>BWP {seed.price.toFixed(2)}</TableCell>
            <TableCell className='font-medium'>BWP {seed.total.toFixed(2)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
