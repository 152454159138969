import moment from 'moment'
import type React from 'react'
import { type Lease } from 'types'
import { Input, Text } from 'components/ui'
import { TabView } from 'components/App/Layout'
import { useTranslation } from 'react-i18next'

interface FarmerInformationProps {
  lease: Lease
}

export const LeasedFarmerInformation: React.FC<FarmerInformationProps> = ({ lease }) => {
  const { t } = useTranslation('extensionOfficer')
  return (
    <TabView>
      <Text size={'medium'}>{t('lease.leasee')}</Text>
      <div
        className={'grid grid-cols-[repeat(auto-fit,minmax(240px,1fr))] gap-1 sm:gap-4 list-none'}
      >
        <Input
          withLabel
          label={t('info.forename')}
          value={lease.dest_farmer_firstname}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('info.surname')}
          value={lease.dest_farmer_surname}
          classLabel={'bg-card'}
          disabled
        />
        <Input withLabel label={'Omang'} value={'N/A'} classLabel={'bg-card'} disabled />
        <Input withLabel label={t('info.pob')} value={'N/A'} classLabel={'bg-card'} disabled />
        <Input
          withLabel
          label={t('info.dob')}
          value={moment(lease.dest_farmer_dob).format('DD-MM-YYYY')}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('info.gender')}
          value={lease.dest_farmer_gender}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('info.physical')}
          value={lease.destination_farmer_physical_address ?? ''}
          classLabel={'bg-card'}
          disabled
        />
        <Input
          withLabel
          label={t('info.postal')}
          value={lease.destination_farmer_postal_address ?? ''}
          classLabel={'bg-card'}
          disabled
        />
        <Input withLabel label={t('info.email')} value={'N/A'} classLabel={'bg-card'} disabled />
        <Input
          withLabel
          label={t('info.phone')}
          value={lease.destination_farmer_contact}
          classLabel={'bg-card'}
          disabled
        />
      </div>
    </TabView>
  )
}