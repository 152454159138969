import { AxiosError, type AxiosResponse } from 'axios'
import { useState } from 'react'
import { type ApiResponse } from 'types'
import { mapCmsErrorToMessage } from 'utils/apiErrors'
import { type InventoryTransfer } from '../../../farmer/inventory/types'
import { useOfficerStore } from '../../../../stores/useOfficerStore'
import { getMOTranferRequests } from '../api/getMOTranferRequests'

interface UseSearchTransfer {
  isLoading: boolean
  error: string | null
  searchInventoryTransfer: (isType: string, idInput: string) => Promise<void>
  data: InventoryTransfer[] | null
}

export const useSearchTransfer = (): UseSearchTransfer => {
  const { officer } = useOfficerStore()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<InventoryTransfer[] | null>(null)

  const searchInventoryTransfer = async (idType: string, idInput: string): Promise<void> => {
    setError(null)
    setIsLoading(true)
    setData(null)

    try {
      const response = await getMOTranferRequests(idType, idInput, officer?.district_id)
      if (response.status === 200) {
        setData(response.data.filter((item) => item.transaction_status === 1))
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const errorMsg = err?.response as AxiosResponse<ApiResponse<[]>>
        setError(
          mapCmsErrorToMessage(
            errorMsg.status.toString() ?? err.code ?? errorMsg.data.message ?? ''
          )
        )
      } else {
        const errorMsg = err as Error
        setError(mapCmsErrorToMessage(errorMsg.message))
      }
    } finally {
      setIsLoading(false)
    }
  }

  return { isLoading, data, searchInventoryTransfer, error }
}
