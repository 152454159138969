import type React from 'react'
import { type Farm } from 'types'
import type z from 'zod'
import { coerce, object } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Text
} from '../../../../../../../components/ui'
import { useFarmEdits } from '../../../../hooks/useFarmEdits'
import { useTranslation } from 'react-i18next'

const steps = ['Destination Farmer', 'Confirm Destination Farmer', 'Transfer Confirmation']

interface UpdateHectarageFormProps {
  farm: Farm
  onClose: () => void
}

const schema = object({
  size: coerce
    .number({ required_error: 'Size is required', invalid_type_error: 'Size should be a number' })
    .min(1, 'Size should be greater than 1')
    .nonnegative('Size cannot be smaller than 1')
})

export const UpdateHectarageForm: React.FC<UpdateHectarageFormProps> = ({ farm, onClose }) => {
  const { updateLandHectarage, isLoading } = useFarmEdits()
  const { t } = useTranslation('landManagement')

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      size: farm.size
    }
  })

  const onSubmit = (data: z.infer<typeof schema>): void => {
    updateLandHectarage(farm?.farm_id, data.size, onClose)
  }

  return (
    <Form {...form}>
      <form
        className={'w-full flex flex-col gap-4 md:gap-8'}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <Text className={'text-center'} size={'large'} variant={'colorText'}>
          {t('ownership.updateHa')}
        </Text>

        <Input
          withLabel
          label={`${t('ownership.current')}`}
          value={farm.size}
          classLabel={'bg-card'}
          disabled
        />

        <FormField
          control={form.control}
          name={'size'}
          render={({ field }) => (
            <FormItem className='flex flex-col'>
              <FormLabel>{t('ownership.new')}</FormLabel>
              <FormControl>
                <Input
                  classLabel={'bg-background-dark sm:bg-card'}
                  placeholder={`${t('ownership.farm')}`}
                  type={'text'}
                  inputMode={'numeric'}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        ></FormField>
        <div className={'w-full flex justify-between gap-4 md:gap-8'}>
          <Button variant={'secondary'} className={'w-1/2'} onClick={onClose}>
            {t('ownership.cancel')}
          </Button>
          <Button
            disabled={isLoading}
            loading={isLoading}
            className={'w-1/2'}
            onClick={form.handleSubmit(onSubmit)}
          >
            {t('ownership.update')}
          </Button>
        </div>
      </form>
    </Form>
  )
}