import Axios, { type AxiosResponse, type InternalAxiosRequestConfig } from 'axios'

import { API_URL } from 'config'
import { appCheck } from 'config/firebase'
import Session from 'supertokens-auth-react/recipe/session'
import { appToken, clearAppToken, setAppToken } from '../stores/useTokenStore'
import { jwtDecode } from 'jwt-decode'
import { getToken } from 'firebase/app-check'

async function getAppToken(): Promise<string> {
  const tkn = appToken()

  try {
    if (tkn != null) {
      const token = jwtDecode(tkn)
      const currentDate = new Date()

      if (token.exp != null && token.exp * 1000 < currentDate.getTime()) {
        // token has expired
        const getNewToken = await getToken(appCheck, true)

        // clear current local storage and set new token
        clearAppToken()()
        setAppToken()(getNewToken.token)

        return getNewToken.token
      } else {
        // token hasn't expired use the one from localstorage
        return tkn
      }
    }

    const getNewToken = await getToken(appCheck, true)
    clearAppToken()()
    setAppToken()(getNewToken.token)

    return getNewToken.token
  } catch (err) {
    // if error return empty string
    return ''
  }
}

async function authRequestInterceptor(
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> {
  let authToken: string | undefined

  try {
    if (await Session.doesSessionExist()) {
      await Session.getAccessToken().then((token) => {
        authToken = token
      })
    }

    if (config?.headers?.authToken == null && config?.headers?.['app-token'] == null) {
      config.headers['app-token'] = await getAppToken()
      config.headers.authToken = authToken
    }

    return config
  } catch (e) {
    return config
  }
}

export const axios = Axios.create({
  baseURL: API_URL,
  withCredentials: true
})

axios.interceptors.request.use(authRequestInterceptor)
axios.interceptors.response.use(
  async (response) => {
    return Promise.resolve(response.data)
  },
  async (error: AxiosResponse) => {
    if (error.status === 401) {
      let authToken: string | undefined

      try {
        if (await Session.doesSessionExist()) {
          await Session.getAccessToken().then((token) => {
            authToken = token
          })
        }

        error.config.headers['app-token'] = await getAppToken()
        error.config.headers.authToken = authToken

        return await axios(error.config)
      } catch (refreshError) {
        return Promise.reject(error)
      }
    }
    return Promise.reject(error)
  }
)
