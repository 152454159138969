import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'

export interface UpdatePestChemicalsDTO {
  chemical_id: number | null | undefined
  chemical_type: 'fungicide' | 'insecticide'
  chemical: number
  adjuvant: number | null | undefined
  pest_id: number | null | undefined
  disease_id: number | null | undefined
}

export interface UpdatePestControlDTO {
  cp_id: number | null | undefined
  user_id: number | null | undefined
  farmer_id: number | null | undefined
  type: string | null | undefined
  chemical_method?: number | null
  cp_pest_disease_control_id: number | null | undefined
  chemicals: UpdatePestChemicalsDTO[] | null
}

export const updatePestControl = async (params: UpdatePestControlDTO): Promise<ApiResponse<[]>> => {
  return axios.put(`/production/pest`, params)
}
