import { type ApiResponse } from 'types'
import { axios } from 'lib/axios'

export interface UpdateHectarageDTO {
  farmer_id: number | null | undefined
  farm_id: string | number | null | undefined
  last_updated_by: number | null | undefined
  hectarage: number
}

export const updateHectarage = async (params: UpdateHectarageDTO): Promise<ApiResponse<[]>> => {
  return axios.post(`/farm/edit/hectarage`, params)
}
