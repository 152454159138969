import { useOtpVerification } from 'hooks/useOtpVerification'
import type React from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { type Farm } from 'types'
import type z from 'zod'
import { object, string, type TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Text
} from 'components/ui'
import { useTranslation } from 'react-i18next'

const rejectSchema = object({
  reason: string().min(1, 'Reason for rejection is required')
})

type RejectInput = TypeOf<typeof rejectSchema>

interface RejectReasonFormProps {
  className?: string
  farm: Farm
  onReject: () => void
  onCancel: () => void
  rejectReason: React.Dispatch<React.SetStateAction<string>>
}

export const RejectReasonForm: React.FC<RejectReasonFormProps> = ({
  className,
  farm,
  onReject,
  rejectReason,
  onCancel
}) => {
  const { isLoading, sendOtpMessage } = useOtpVerification('', farm.farmer_contact)
  const { t } = useTranslation('extensionOfficer')
  const form = useForm<z.infer<typeof rejectSchema>>({
    resolver: zodResolver(rejectSchema),
    defaultValues: {
      reason: ''
    }
  })

  const onSubmit = (data: RejectInput): void => {
    rejectReason(data.reason)
    sendOtpMessage(onReject)
  }

  return (
    <Form {...form}>
      <form className={`w-full flex-col ${className ?? ''}`} onSubmit={form.handleSubmit(onSubmit)}>
        <div className={'flex flex-col justify-center items-center gap-8'}>
          <Text size={'medium'}>{t('land.rfr')}</Text>
        </div>
        <FormField
          control={form.control}
          name='reason'
          render={({ field }) => (
            <FormItem className='w-full flex flex-col items-start space-x-3 space-y-0 gap-4'>
              <FormControl>
                <Input
                  label={t('cp.reason')}
                  className={'w-full'}
                  placeholder={t('land.irtf')}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className={'flex items-cnter justify-between gap-4'}>
          <Button variant={'destructive'} onClick={onCancel} size={'lg'} disabled={isLoading}>
            {t('land.cancel')}
          </Button>
          <Button
            variant={'success'}
            type={'submit'}
            onClick={form.handleSubmit(onSubmit)}
            size={'lg'}
            disabled={isLoading}
            loading={isLoading}
          >
            {t('land.cont')}
          </Button>
        </div>
      </form>
    </Form>
  )
}