import type React from 'react'
import { Outlet } from 'react-router-dom'
import Session from 'supertokens-auth-react/recipe/session'
import { UserRoleClaim } from 'supertokens-auth-react/recipe/userroles'
import Spinner from '../../../components/ui/spinner'
import { clearLoginAttemptInfo } from 'supertokens-web-js/lib/build/recipe/passwordless'
import { Footer, NavLandingPage } from '../components'
import { HeaderImage } from '../components/SubComponents/About/HeaderImage'
import { OurMission } from '../components/SubComponents/About/OurMission'
import { FarmerTypes } from '../components/SubComponents/About/FarmerTypes'
import { GroupsAndClusters } from '../components/SubComponents/About/GroupsAndClusters'
import { TargetYields } from '../components/SubComponents/About/TargetYields'
import { ProgramPackages } from '../components/SubComponents/About/ProgramPackages'
import { MmokoRewardPackage } from '../components/SubComponents/About/MmokoRewardPackage'
import { TemoLetlotloEnhancers } from '../components/SubComponents/About/TemoLetlotloEnhancers'
import { MiniGallery } from '../components/SubComponents/About/OurImpact'
import { Partners } from '../components/SubComponents/About/Partners'
import { FooterCTA } from '../components/SubComponents/About/FooterCTA'
import FooterImage from 'assets/landing/swiper/20230119_6/20230119_9.jpg'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

/**
 * React functional component to display the About Temo Letlotlo page.
 *
 * @returns {JSX.Element} The JSX code to render the About Temo Letlotlo page.
 */
export const AboutTemoLetlotlo: React.FC = () => {
  const claimValue = Session.useClaimValue(UserRoleClaim)
  const { t } = useTranslation('landing')
  if (claimValue.loading) {
    return (
      <div className='flex items-center justify-center w-screen h-screen'>
        <Spinner />
      </div>
    )
  }

  const roles = claimValue.value

  /**
   * Function to handle navigation to the appropriate dashboard based on user roles.
   *
   * @returns {string} The path to navigate to the appropriate dashboard.
   */
  const handleNavigateToDashboard = (): string => {
    if (Array.isArray(roles) && roles.includes('FARMER')) {
      return '/farmer'
    } else if (Array.isArray(roles) && roles.includes('EXTENSION_OFFICER')) {
      return '/officer'
    } else if (Array.isArray(roles) && roles.includes('SOIL_SAMPLE_LAB')) {
      return '/soil-lab'
    } else if (Array.isArray(roles) && roles.includes('MECHANISATION_OFFICER')) {
      return '/inventory'
    } else if (Array.isArray(roles) && roles.includes('SEED_DEALER')) {
      return '/seed-dealer'
    } else if (Array.isArray(roles) && roles.includes('SERVICE_PROVIDER')) {
      return '/service-provider'
    } else {
      void clearLoginAttemptInfo()
      return '/login/phone'
    }
  }

  return (
    <main className='landing-page'>
      {/* SEO OPTIMIZATION */}
      <Helmet>
        <title>About Temo Letlotlo - Supporting Botswana's Farmers</title>
        <meta
          name='description'
          content='Learn about Temo Letlotlo, a government-backed initiative to support farmers in Botswana through tools, resources, and financial solutions.'
        />
        <meta
          name='keywords'
          content='About Temo Letlotlo, Botswana farming support, agricultural development, government agriculture initiative'
        />

        {/* Open Graph / Facebook */}
        <meta property='og:type' content='website' />
        <meta property='og:title' content="About Temo Letlotlo - Supporting Botswana's Farmers" />
        <meta
          property='og:description'
          content='Temo Letlotlo is dedicated to empowering farmers with the resources needed to drive sustainable agriculture in Botswana.'
        />
        <meta property='og:image' content='https://www.temoletlotlo.com/about-image.jpg' />
        <meta property='og:url' content='https://www.temoletlotlo.com/about-temo-letlotlo/' />

        {/* Twitter */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content="About Temo Letlotlo - Supporting Botswana's Farmers" />
        <meta
          name='twitter:description'
          content='Learn how Temo Letlotlo is boosting agriculture in Botswana through support and financial aid for farmers.'
        />
        <meta name='twitter:image' content='https://www.temoletlotlo.com/about-twitter-image.jpg' />
      </Helmet>

      {/* Page Components */}
      <NavLandingPage
        goToDashboard={handleNavigateToDashboard}
        isAuthenticated={claimValue.value != null}
      />
      <HeaderImage />
      <OurMission />
      <FarmerTypes />
      <GroupsAndClusters />
      <TargetYields />
      <TemoLetlotloEnhancers />
      <MmokoRewardPackage />
      <ProgramPackages />
      <MiniGallery />
      <Partners header={t('home.supp')} />
      <FooterCTA
        backgroundImage={FooterImage}
        headerText={t('about.join')}
        buttonText={t('home.reg')}
        navigationLink='/registraion'
      />
      <Outlet />
      <Footer />
    </main>
  )
}