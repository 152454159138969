import type { ColumnDef } from '@tanstack/react-table'
import { DataTableColumnHeader } from '../../../../components/Data-Table/datatable-header'
import { Checkbox } from '../../../../components/ui/checkbox'
import { type ChemicalData } from '../types'

export const chemicalsColumns = (): Array<ColumnDef<ChemicalData>> => [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllRowsSelected() || (table.getIsSomeRowsSelected() && 'indeterminate')}
        onCheckedChange={(value) => {
          table.toggleAllRowsSelected(!!value)
        }}
        aria-label='Select all'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => {
          row.toggleSelected(!!value)
        }}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false
  },
  {
    id: 'name',
    accessorFn: (row) =>
      row.pesticide_name ?? row.fungicide_name ?? row.herbicide_name ?? row.adjuvant_name,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('name')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title={'Name'} />
  },
  {
    id: 'registration_no',
    accessorFn: (row) => row.registration_no,
    filterFn: 'includesString',
    enableHiding: false,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('registration_no')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title={'Registration #'} />
  },
  {
    id: 'type',
    accessorFn: (row) => row.type,
    filterFn: 'includesString',
    enableHiding: false,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('type')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title={'Type'} />
  },
  {
    id: 'dosage',
    accessorFn: (row) => row.dosage,
    filterFn: 'includesString',
    enableHiding: false,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('dosage')}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title={'Dosage'} />
  },
  {
    id: 'price',
    accessorFn: (row) => row.price,
    filterFn: 'includesString',
    enableHiding: false,
    enableColumnFilter: true,
    cell: ({ row }) => {
      const amountString = String(row.getValue('price')).replace(/,/g, '')
      const amount = parseFloat(amountString)
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'BWP'
      }).format(amount)

      return <div className='font-medium uppercase'>{formatted}</div>
    },
    header: ({ column }) => <DataTableColumnHeader column={column} title={`Price (BWP)`} />
  }
]
