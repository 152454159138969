import type React from 'react'
import { useSuperTokens } from '../../features/authentication/hooks/useSuperTokens'
import { Button, Text } from '../ui'
import type { FeatureBaseProps, UserContext } from 'supertokens-auth-react/lib/build/types'

export const Unauthorized = (
  prop?: FeatureBaseProps<{
    userContext?: UserContext
  }>
): React.ReactElement => {
  const { logOut } = useSuperTokens()

  return (
    <main className={'h-screen w-full flex flex-col items-center justify-center gap-8'}>
      <div className={'text-center'}>
        <Text size={'large'} variant={'error'} className={'leading-[200%]'}>
          Oops!! Unauthorized Access
        </Text>
        <Text size={'medium'}>Sorry you do not have access to this page.</Text>
      </div>
      <Button onClick={logOut} size={'sm'}>
        Go to Login
      </Button>
    </main>
  )
}
