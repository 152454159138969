import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosError } from 'axios'
import { axios } from 'lib/axios'
import { type ApiResponse } from 'types'
import { type GetInventoryRes } from '../../../farmer/inventory/types'

export const getMOInventories = async (
  merchantDistrict: number | null | undefined
): Promise<ApiResponse<GetInventoryRes>> => {
  return axios.get(`/inventory?district_id=${merchantDistrict ?? ''}`)
}

export const useGetMOInventories = (
  merchantDistrict: number | null | undefined
): UseQueryResult<ApiResponse<GetInventoryRes>, AxiosError<[]>> => {
  return useQuery({
    queryKey: ['mo-inventories', merchantDistrict],
    queryFn: async () => getMOInventories(merchantDistrict),
    enabled: merchantDistrict != null
  })
}
