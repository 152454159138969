import type { ColumnDef } from '@tanstack/react-table'
import type { CroppingPlan } from 'types'
import { DataTableColumnHeader } from '../../../../../components/Data-Table/datatable-header'
import { CroppingPlanStatuses } from './CroppingPlanStatuses'
import { CroppingPlanActions } from './cropping-plan-actions'

export const croppingPlansColumns = (
  t: (key: string) => string
): Array<ColumnDef<CroppingPlan>> => {
  return [
    {
      id: 'plough season',
      accessorFn: (row) => row.plough_season,
      filterFn: 'includesString',
      enableHiding: false,
      cell: ({ row }) => <div className='uppercase'>{row.getValue('plough season')}</div>,
      header: ({ column }) => <DataTableColumnHeader column={column} title={t('cp.plough')} />
    },
    {
      id: 'crop',
      accessorFn: (row) => row.crop_name,
      cell: ({ row }) => <div className='uppercase'>{row.getValue('crop')}</div>,
      header: t('cp.crop')
    },
    {
      id: 'family name',
      accessorFn: (row) => row.family_name,
      cell: ({ row }) => <div className='uppercase'>{row.getValue('family name')}</div>,
      header: t('cp.family')
    },
    {
      id: 'hectarage',
      accessorFn: (row) => row.hectarage,
      cell: ({ row }) => <div className='uppercase'>{row.getValue('hectarage')}</div>,
      header: t('cp.ha')
    },
    {
      accessorKey: 'total costs',
      accessorFn: (row) => row.total_cost,
      enableHiding: false,
      header: t('cp.cost'),
      cell: ({ row }) => {
        const amount = parseFloat(String(row.getValue('total costs')).replace(',', ''))
        const formatted = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'BWP'
        }).format(amount)

        return <div className='font-medium'>{formatted}</div>
      }
    },
    {
      id: 'status',
      accessorFn: (row) => row.is_verified,
      cell: ({ row }) => <CroppingPlanStatuses croppingPlan={row.original} />,
      header: t('cp.status')
    },
    {
      id: 'actions',
      enableHiding: false,
      cell: ({ row }) => {
        return <CroppingPlanActions croppingPlan={row.original} />
      }
    }
  ]
}