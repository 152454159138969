import { useEffect, useState } from 'react'
import { useGetCurrentPloughSeason } from '../api/getCurrentPloughSeason'
import { usePloughSeasonStore } from '../stores/usePloughSeasonStore'
import { useStaticDataStore } from '../stores/useStaticDataStore'
import {
  useAgrochemicalData,
  useGetCroppingPlanData,
  useGetStaticInventory
} from '../api/getStaticData'
import type { AxiosError } from 'axios'
import type { Agrochemicals, CroppingPlanStaticData } from '../types/static'
import { type StaticInvData } from '../features/farmer/inventory/types'

interface UsePloughSeason {
  currentSeason: string
  isLoading: boolean
  staticInvData: StaticInvData | undefined
  isCurrentSeason: (season: string) => boolean
  staticData: CroppingPlanStaticData | undefined
  agroData: Agrochemicals | undefined
  error: AxiosError | null | undefined
}

export const usePloughSeason = (userRole?: number | null): UsePloughSeason => {
  const { setPloughSeason, ploughSeason } = usePloughSeasonStore()
  const { data, isLoading } = useGetCurrentPloughSeason()
  const {
    pesticides,
    fungicides,
    adjuvants,
    services,
    crops,
    seeds,
    chemicals,
    herbicides,
    applicationStages,
    controlMethods,
    fertilizers,
    tractors,
    poweredImplements,
    nonPoweredImplements
  } = useStaticDataStore()

  const {
    data: staticData,
    isInitialLoading: loadingStaticData,
    error: staticDataError
  } = userRole !== 1000 &&
  userRole !== 1001 &&
  userRole !== 2001 &&
  userRole !== 2004 &&
  userRole !== 2002
    ? { data: undefined, isInitialLoading: false, error: null }
    : useGetCroppingPlanData(
        services == null &&
          crops == null &&
          chemicals == null &&
          fertilizers == null &&
          seeds == null
      )

  const {
    data: staticAgroData,
    isLoading: isLoadingStaticAgroData,
    error: errorAgro
  } = userRole !== 1000 &&
  userRole !== 1001 &&
  userRole !== 2001 &&
  userRole !== 2003 &&
  userRole !== 2002
    ? { data: undefined, isLoading: false, error: null }
    : useAgrochemicalData(
        pesticides == null &&
          fungicides == null &&
          adjuvants == null &&
          controlMethods == null &&
          applicationStages == null &&
          herbicides == null
      )

  const {
    data: staticInvData,
    isLoading: isLoadingStaticInvData,
    error: errorInvData
  } = userRole !== 1000 && userRole !== 1001 && userRole !== 1010
    ? { data: undefined, isLoading: false, error: null }
    : useGetStaticInventory(
        tractors == null && poweredImplements == null && nonPoweredImplements == null
      )

  const [currentSeason, setCurrentSeason] = useState<string>('')

  useEffect(() => {
    if (data != null) {
      setCurrentSeason(data?.data[0].plough_season)

      if (ploughSeason === '' || ploughSeason !== data?.data[0].plough_season) {
        setPloughSeason(data?.data[0].plough_season)
      }
    }
  }, [isLoading])

  const isCurrentSeason = (season: string): boolean => currentSeason === season

  return {
    currentSeason,
    isLoading: isLoading || loadingStaticData || isLoadingStaticAgroData || isLoadingStaticInvData,
    isCurrentSeason,
    staticData: userRole === 1010 ? undefined : staticData?.data,
    agroData: userRole === 1010 ? undefined : staticAgroData?.data,
    staticInvData: userRole === 2003 || userRole === 2002 ? undefined : staticInvData?.data,
    error:
      staticDataError != null
        ? staticDataError
        : errorAgro != null
        ? errorAgro
        : errorInvData != null
        ? errorInvData
        : null
  }
}
