import { type ApiResponse, type Merchant } from '../../../../types'
import { axios } from '../../../../lib/axios'
import { useQuery, type UseQueryResult } from '@tanstack/react-query'

export const getMerchant = async (
  merchantId: number | string | null | undefined
): Promise<ApiResponse<Merchant>> => {
  return axios.get(`/merchant?merchant_id=${merchantId ?? ''}`)
}

export const useGetMerchant = (
  merchantId: number | string | null | undefined
): UseQueryResult<ApiResponse<Merchant>, ApiResponse<[]>> => {
  return useQuery({
    queryKey: ['merchant', merchantId],
    queryFn: async () => getMerchant(merchantId),
    enabled: merchantId !== null
  })
}
