import { Box } from '../../../../../components/App/Layout'
import z, { type ZodEffects, type ZodObject } from 'zod'
import { useFarmerStore } from '../../../../../stores/useFarmerStore'
import { useMerchantStore } from '../../../../../stores/useMerchantStore'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Text
} from 'components/ui'
import type React from 'react'
import { useState } from 'react'
import { useGetVerifiedFarms } from '../../util'
import { Districts, ExtensionAreas, locations, SubDistricts } from '../../../land-management/utils'
import { type InventoryTransfer } from '../../types'
import { useTransferInventory } from '../../hooks/useTransferInventory'
import { isLease } from '../../../../officer/farmers/utils/funcitons'
import { useTranslation } from 'react-i18next'
import { useAuthenticationStore } from '../../../../../stores/useAuthenticationStore'
import { useOfficerStore } from '../../../../../stores/useOfficerStore'
import { type Farmer } from '../../../../../types'

const locationSchema = (
  farmer: Farmer | null,
  role: number
): ZodEffects<
  ZodObject<{
    farm: z.ZodOptional<z.ZodNumber>
    district: z.ZodOptional<z.ZodNumber>
    subDistrict: z.ZodOptional<z.ZodNumber>
    extension: z.ZodOptional<z.ZodNumber>
    village: z.ZodOptional<z.ZodNumber>
  }>
> => {
  return z
    .object({
      farm: z.coerce.number().min(1, 'Farm is required').optional(),
      district: z.coerce.number().min(1, 'District is required').optional(),
      subDistrict: z.coerce.number().min(1, 'Sub District is required').optional(),
      extension: z.coerce.number().min(1, 'Extension is required').optional(),
      village: z.coerce.number().min(1, 'Village is required').optional()
    })
    .superRefine((data, ctx) => {
      if (role !== 1010) {
        if (farmer !== null) {
          if (data.farm == null) {
            ctx.addIssue({
              path: ['farm'],
              message: 'Farm is required',
              code: z.ZodIssueCode.custom
            })
          }
        } else {
          ;['district', 'subDistrict', 'extension', 'village'].forEach((field) => {
            if (data[field as keyof typeof data] == null) {
              ctx.addIssue({
                path: [field],
                message: `${field.charAt(0).toUpperCase() + field.slice(1)} is required`,
                code: z.ZodIssueCode.custom
              })
            }
          })
        }
      }
    })
}

interface LocationConfirmationProps {
  transferRequest?: InventoryTransfer
  onClose: () => void
}

export const LocationConfirmation: React.FC<LocationConfirmationProps> = ({
  transferRequest,
  onClose
}) => {
  const { officer } = useOfficerStore()
  const { farmer } = useFarmerStore()
  const { merchant } = useMerchantStore()
  const { role } = useAuthenticationStore()
  const [selectedFarm, setSelectedFarm] = useState<string | number>('')
  const [village, setVillage] = useState<number | string>('')
  const { acceptTransferRequest, isLoading, verifyTransferRequest } =
    useTransferInventory(transferRequest)
  const schema = locationSchema(farmer, Number(role))
  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(locationSchema(farmer, Number(role)))
  })
  const { verifiedFarms: farmsList, isLoading: isFarmsLoading } =
    role === 1000
      ? useGetVerifiedFarms(Number(transferRequest?.dest_farmer_id))
      : { verifiedFarms: [], isLoading: false }

  const [district, setDistrict] = useState<number | string>('')
  const [subDistrict, setSubDistrict] = useState<number | string>('')
  const [extensionArea, setExtensionArea] = useState<number | string>('')

  const { t } = useTranslation('inventory')

  const onSubmit = (): void => {
    const payload = {
      inventory_id: Number(transferRequest?.inventory_id),

      ...(transferRequest?.origin_merchant_id != null && {
        origin_merchant_id: Number(transferRequest.origin_merchant_id)
      }),
      ...(transferRequest?.dest_merchant_id != null && {
        dest_merchant_id: Number(transferRequest.dest_merchant_id)
      }),
      ...(transferRequest?.dest_farmer_id != null && {
        dest_farmer_id: Number(transferRequest.dest_farmer_id)
      }),
      ...(transferRequest?.origin_farmer_id != null && {
        origin_farmer_id: Number(transferRequest.origin_farmer_id)
      })
    }
    if (role === 1010) {
      verifyTransferRequest(
        {
          ...payload,
          last_update_by: Number(officer?.user_id),
          transaction_status: 4,
          message: 'Transfer Verified by MO'
        },

        onClose
      )
    } else {
      acceptTransferRequest(
        {
          ...payload,
          ...(transferRequest?.dest_farmer_id != null && {
            ...(isLease(selectedFarm)
              ? { lease_id: Number(selectedFarm) }
              : { farm_id: Number(selectedFarm) })
          }),
          last_update_by: merchant != null ? merchant.user_id : farmer?.user_id,
          message: `Transfer accepted by ${
            transferRequest?.dest_merchant_id != null ? 'merchant' : 'farmer'
          }`,
          transaction_status: 2,
          ...(transferRequest?.dest_merchant_id != null && {
            village_id: Number(village)
          })
        },
        onClose
      )
    }
  }

  const districts = Districts.slice().sort((a, b) => {
    return a.district_name.localeCompare(b.district_name)
  })
  const subDistricts = SubDistricts.filter(
    (sub) => sub.district_id.toString() === district.toString()
  ).sort((a, b) => a.district_name.localeCompare(b.district_name))
  const extensions = ExtensionAreas.filter(
    (ext) => ext.sub_district_id.toString() === subDistrict.toString()
  ).sort((a, b) => a.extension_area_name.localeCompare(b.extension_area_name))
  return (
    <Box>
      {role === 1010 ? (
        <div className={'flex flex-col items-center justify-center gap-4'}>
          <Text size={'medium'}>Declaration By Mechanization Officer</Text>
          <Text>{`I verify the transfer for this inventory item`}</Text>
        </div>
      ) : (
        <>
          <Text size={'large'}>
            {farmer != null ? `${t('transfer.chooseFarm')}` : `${t('transfer.chooseVillage')}`}
          </Text>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              {farmer != null && role === 1000 ? (
                <FormField
                  control={form.control}
                  name='farm'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('transfer.farm')}</FormLabel>

                      <Select
                        onValueChange={(val) => {
                          field.onChange(val)
                          setSelectedFarm(val)
                        }}
                        value={selectedFarm?.toString()}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder={`${t('transfer.selectFarm')}`} />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {farmsList?.map((disc) => (
                            <SelectItem key={disc.farm_id} value={disc.farm_id.toString()}>
                              {disc.farm_name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              ) : (
                <div className={'flex flex-col gap-4'}>
                  <FormField
                    control={form.control}
                    name='district'
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className={'ml-2 font-redHat'}>District</FormLabel>
                        <Select
                          onValueChange={(val) => {
                            setDistrict(val)
                            setSubDistrict('')
                            setExtensionArea('')
                            setVillage('')
                            field.onChange(val)
                          }}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder={`Select district`} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {districts.map((disc) => (
                              <SelectItem
                                key={disc.district_id}
                                value={disc.district_id.toString()}
                              >
                                {disc.district_name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name='subDistrict'
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className={'ml-2 font-redHat'}>Sub-District</FormLabel>
                        <Select
                          disabled={district === ''}
                          onValueChange={(val) => {
                            setSubDistrict(val)
                            setExtensionArea('')
                            setVillage('')
                            field.onChange(val)
                          }}
                          defaultValue={subDistrict.toString()}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder={`Select sub-district`} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {subDistricts.map((sub) => (
                              <SelectItem
                                key={sub.sub_district_id}
                                value={sub.sub_district_id.toString()}
                              >
                                {sub.sub_district_name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name='extension'
                    disabled={subDistrict === ''}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className={'ml-2 font-redHat'}>Extension</FormLabel>
                        <Select
                          disabled={subDistrict === ''}
                          onValueChange={(val) => {
                            setExtensionArea(val)
                            setVillage('')
                            field.onChange(val)
                          }}
                          defaultValue={extensionArea.toString()}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder={`Select extension`} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {extensions.map((ext) => (
                              <SelectItem
                                key={ext.extension_area_id}
                                value={ext.extension_area_id.toString()}
                              >
                                {ext.extension_area_name}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name='village'
                    disabled={extensionArea === ''}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className={'ml-2 font-redHat'}>Village</FormLabel>
                        <Select
                          disabled={extensionArea === ''}
                          onValueChange={(val) => {
                            setVillage(val)
                            field.onChange(val)
                          }}
                          defaultValue={village.toString()}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder={`Select Village`} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {locations
                              .filter((area) => {
                                return (
                                  area.extension_area_id.toString() === extensionArea.toString() &&
                                  area.district_id.toString() === district.toString() &&
                                  area.sub_district_id.toString() === subDistrict.toString()
                                )
                              })
                              .map((vil) => (
                                <SelectItem key={vil.village_id} value={vil.village_id.toString()}>
                                  {vil.village_name}
                                </SelectItem>
                              ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              )}
            </form>
          </Form>
        </>
      )}

      <div className='flex w-full justify-between space-x-4 mt-5'>
        <Button size={'lg'} variant='secondary' onClick={onClose}>
          {t('transfer.cancel')}
        </Button>
        <Button onClick={onSubmit} size={'lg'} disabled={isLoading} loading={isLoading}>
          {t('transfer.accept')}
        </Button>
      </div>
    </Box>
  )
}
