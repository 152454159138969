import type { ColumnDef } from '@tanstack/react-table'
import { Checkbox } from 'components/ui/checkbox'
import { DataTableColumnHeader } from 'components/Data-Table/datatable-header'
import { type Adjuvants } from '../../../../../../../types/static'

export const adjuvantColumns: Array<ColumnDef<Adjuvants>> = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllRowsSelected() || (table.getIsSomeRowsSelected() && 'indeterminate')}
        onCheckedChange={(value) => {
          table.toggleAllRowsSelected(!!value)
        }}
        type={'button'}
        aria-label='Select row'
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => {
          row.toggleSelected(!!value)
        }}
        type={'button'}
        aria-label='Select row'
      />
    ),
    enableSorting: false,
    enableHiding: false
  },
  {
    id: 'adjuvant name',
    accessorFn: (row) => row.adjuvant_name,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => <div className='uppercase'>{row.getValue('adjuvant name') ?? 'N/A'}</div>,
    header: ({ column }) => <DataTableColumnHeader column={column} title='Name' />
  },
  {
    id: 'active ingredient',
    accessorFn: (row) => row.active_ingredient_name,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => (
      <div className='uppercase'>{row.getValue('active ingredient') ?? 'N/A'}</div>
    ),
    header: ({ column }) => <DataTableColumnHeader column={column} title='Active Ingredient' />
  },
  {
    id: 'application method',
    accessorFn: (row) => row.application_method,
    filterFn: 'includesString',
    enableHiding: true,
    enableColumnFilter: true,
    cell: ({ row }) => (
      <div className='uppercase'>{row.getValue('application method') ?? 'N/A'}</div>
    ),
    header: 'Application Method'
  },
  {
    id: 'application rate',
    accessorFn: (row) => row.application_rate,
    enableHiding: true,

    cell: ({ row }) => <div className='uppercase'>{row.getValue('application rate') ?? 'N/A'}</div>,
    header: 'Application Rate'
  },
  {
    id: 'price',
    accessorFn: (row) => row.price,
    filterFn: 'includesString',
    enableHiding: false,
    cell: ({ row }) => {
      const amount = parseFloat(row.getValue('price'))
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'BWP'
      }).format(amount)

      return <div className='font-medium uppercase'>{formatted}</div>
    },
    header: ({ column }) => <DataTableColumnHeader column={column} title='price' />
  }
]
